import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Classes from '../pages/home/customers/css/customerProfile.module.css';
import moment from 'moment';
import axiosInstance from '../utility/axios-instance';


const CustomerOrderDetails = (props) => {
    const [productDetails,setProductDetails] = useState([]);
    const {
        showCustomerProfile,
        orders,
        totalAmount,
        totalOrders,
        avgAmount,
    } = props;

    const handleTotal = (total) => {
		let fetchDecimal= countPlaces(total)
		return fetchDecimal === 1 || fetchDecimal === 0 || fetchDecimal === 2 ? total :  Math.trunc(total*100)/100
	}

	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    useEffect(() => {
        if (orders && orders?.length) {
            const salesOrderCalculationServiceEndpoint = '/product/fetchProductDetails/bulk';
            const timeoutId = setTimeout(() => {
                axiosInstance
                    .post(salesOrderCalculationServiceEndpoint, {
                        productDetails: [
                            {
                                variantId: orders[0]?.lineItems[0]?.variantId,
                                quantity: orders[0]?.lineItems[0]?.quantity,
                                PCS: 0
                            }
                        ]
                    })
                    .then((res) => {
                        setProductDetails([res?.data?.product?.lineItems[0]?.basicCollectionDataBrandData])
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }, 500)
            return () => clearTimeout(timeoutId);
        }
    },[orders])

	return (
		<Fragment>
            <div>
                {orders?.length !== 0 ?  (
                    <div>
                        <div style={{ display:'flex',padding:'15px 20px'}}  className={Classes.Boxes}>
                            <div className={Classes.ChildDiv}>
                                <p className={Classes.ChildDivP}>Amount spent</p>

                                {totalAmount && ( <h3>&#8377;{handleTotal(totalAmount)}</h3> )}
                            </div>

                            <div className={Classes.ChildDiv} style={{ paddingLeft:10 }}>
                                <p className={Classes.ChildDivP}>Orders</p>
                                <h3 className={Classes.TotalOrders}>{totalOrders}</h3>
                            </div>

                            <div style={{ paddingLeft:10 }}>
                                <p className={Classes.ChildDivP}>Average order value</p>

                                {avgAmount  && ( <h3>&#8377;{handleTotal(avgAmount)}</h3> )}
                            </div>
                        </div>
                        
                        {orders?.length !== 0  ? 
                            <div style={{ marginTop:15 }} className={Classes.Boxes}>
                                <p style={{ fontWeight:600,padding:20 }}>Last order placed</p>

                                <div style={{padding:'0px 20px'}}>
                                    <div className={Classes.box}>
                                        <div className={Classes.boxHeader}>
                                            <p className={Classes.OrderName}>{orders[0]?.orderName}</p>
                                        
                                            <div 
                                                className={Classes.HighlightColor} 
                                                style={{backgroundColor:orders[0]?.orderStatus === 'FULFILLED'? '#e4e5e7': '#FFEA8A'}}
                                            >
                                                <p 
                                                    className={Classes.BorderRadius} 
                                                    style={{
                                                        backgroundColor: orders[0]?.orderStatus === 'FULFILLED'? '#5C5F62': '',
                                                        border: orders[0]?.orderStatus === 'FULFILLED' ? '0px': ''
                                                    }}
                                                ></p>
                                                <p>{orders[0]?.orderStatus}</p>
                                            </div>

                                            <div  
                                                className={Classes.HighlightColor} 
                                                style={{backgroundColor:orders[0]?.paymentStatus === 'PAID'? '#e4e5e7': '#ffd79d' }}
                                            >
                                                <p 
                                                    className={Classes.BorderRadius} 
                                                    style={{
                                                        backgroundColor: orders[0]?.paymentStatus === 'PAID'? '#5C5F62':'',
                                                        border: orders[0]?.paymentStatus === 'PAID' ? '0px': ''
                                                    }}
                                                ></p>
                                                <p>{orders[0]?.paymentStatus}</p>
                                            </div>
                                        </div>
                                        {/* <div style={{ marginTop:15 }}>
                                            <p style={{ fontWeight:600 }}>&#8377;{orders[0]?.draftOrder?.totalPrice}</p>
                                        </div> */}
                                    </div>

                                    <div style={{ marginTop:5,fontSize:14 }}>
                                        <p style={{color:'#6D7175'}}>
                                            {moment(orders[0]?.createdAt).format('MMM DD, YYYY h:mm:ss A')} 
                                        </p>
                                    </div>
                                </div>

                                {orders[0]?.lineItems?.map((orders) => (
                                    <div className={Classes.OrderBoxes} key={orders?.variantId}>
                                        <div style={{display:'flex'}}>
                                            <div style={{marginRight:20}}>
                                                <div style={{position:'relative'}}>
                                                    <p className={Classes.Qty}>{orders?.quantity}</p>
                                                </div>
                                                <div>
                                                    <img
                                                        src={
                                                            orders?.image ? 
                                                            orders?.node?.image?.url :
                                                            "https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png"
                                                        }
                                                        alt='50px'
                                                        className={Classes.ProductImages}
                                                    />
                                                </div>
                                            </div>
                                            {productDetails?.length !== 0 && (
                                                <div style={{ fontSize:14 }}>
                                                    <p>{productDetails[0]?.groupName}</p>
                                                    <p style={{ color:'#6D7175' }}>{productDetails[0]?.sku}</p>
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginTop:15 }}>
                                            <p>&#8377;{orders?.subTotal}</p>
                                        </div>
                                    </div>
                                ))}

                                <div className={Classes.createOrderBtn}>
                                    <Link to={`/ordersNew/user/${showCustomerProfile?.phone?.slice(3)}`}>
                                        <p style={{ fontSize:14,marginTop:8,marginRight:15 }}>
                                            View all orders
                                        </p>
                                    </Link>

                                    <Link to='/createOrder'>
                                        <button className={Classes.createOrderButton}>
                                            Create order
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        :null}
                    </div> 
                ) : (
                    <div className={Classes.Boxes}>
    
                        <p style={{ fontWeight:600,padding:20 }}>Last order placed</p>

                        <div>
                            <div className={Classes.CartIcon}>
                                <img 
                                    src="https://cdn.shopify.com/shopifycloud/web/assets/v1/0ecc04f85cc74cb401e71254d8a8b83dc03bdade26037cdda6a034831d940dcd.svg" 
                                    alt="cartIcon" 
                                />
                            </div>
                            
                            <div className={Classes.NotExistOrder}>
                                <h5>This customer hasn’t placed any orders.</h5>
                            </div>

                            <div 
                                className={Classes.createOrderBtn}
                                style={{ display:'flex',justifyContent:'center' }}
                            >
                                <Link to='/createOrder'>
                                    <button 
                                        className={Classes.createOrderButton}
                                        style={{ marginBottom:30 }}
                                    >
                                        Create order
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}   
            </div>
		</Fragment>
	)				
};

export default CustomerOrderDetails;
