import React, { Fragment } from "react";
import Classes from '../draftPurchaseOrder/css/draftOrderDetailPage.module.css';


const CustomerDetails = (props) => {
    const { draftOrder } = props;

    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2;
        }
    }

    return (
        <Fragment>
            <div
                className={[
                    Classes.Box, 'box', 'customerVertical', Classes.customerVertical
                ].join(' ')}
                style={{ marginTop: 45 }}
            >
                {draftOrder?.OCustomerDetails && draftOrder?.OCustomerDetails?.length !== 0 ? (
                    <div style={{ display: 'flex' }}>
                        <div className={[Classes.containerBox, Classes.customerBox].join(' ')} >
                            <div >
                                <div className='mb-2'>
                                    <p className={Classes.contactTitle} style={{ fontWeight:600 }}>
                                        CUSTOMER
                                    </p>
                                </div>

                                <div style={{ fontSize: 14 }}>
                                    <div style={{ color: '#2c6ecb', display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ marginRight: 5 }}>
                                            {draftOrder?.OCustomerDetails[0]?.firstName}
                                        </p>
                                    </div>

                                </div>
                            </div>

                            {draftOrder?.OCustomerDetails?.length ? (
                                <div>
                                    <div>
                                        <div className='mb-2' style={{ marginTop: 8 }}>
                                            <p className={Classes.contactTitle} style={{ fontWeight:600 }}>
                                                CONTACT INFORMATION
                                            </p>
                                        </div>

                                        <div style={{ fontSize: 14, color: '#2c6ecb' }}>
                                            <div>
                                                <p>{draftOrder?.OCustomerDetails[0]?.customerCode}</p>
                                            </div>
                                            <div>
                                                <p>{draftOrder?.OCustomerDetails[0]?.email}</p>
                                            </div>
                                            <div>
                                                <p>{draftOrder?.OCustomerDetails[0]?.phoneNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {draftOrder?.OCustomerDetails?.length && draftOrder?.OCustomerDetails[0]?.gstNumber.length ? (
                                <div>
                                    <div>
                                        <div className='mb-2' style={{ marginTop: 8 }}>
                                            <p className={Classes.contactTitle} style={{ fontWeight:600 }}>
                                                GST NUMBER
                                            </p>
                                        </div>

                                        <div style={{ fontSize: 14, color: '#2c6ecb' }}>
                                            {draftOrder?.OCustomerDetails[0]?.gstNumber[0]}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '66%' }}>
                            {draftOrder?.OCustomerAddresses && draftOrder?.OCustomerAddresses?.length !== 0 ? (
                                <>
                                    {draftOrder?.OCustomerAddresses?.map((location, index) => (
                                        <div
                                            className={location?.addressType !== 'Billing' && Classes.ShippingAddressBox}
                                            key={index}
                                            style={{ width: '60%' }}
                                        >
                                            <div className={Classes.containerBox}>
                                                <div className='mb-2'>
                                                    <p className={Classes.AddressTitle}>
                                                        {location?.addressType === 'Billing' ?
                                                            `${location?.addressType?.toUpperCase()} ADDRESS` :
                                                            `${location?.addressType?.toUpperCase()} ADDRESS`
                                                        }
                                                    </p>
                                                </div>

                                                <div style={{ fontSize: '14px' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ marginRight: '5px' }}>
                                                            {location?.firstName}
                                                        </p>
                                                        <p>{location?.lastName}</p>
                                                    </div>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <p style={{ marginRight: '5px' }}>
                                                            {location?.addressLine1}
                                                        </p>
                                                        {location?.addressLine2 !== null
                                                            ? <p>{location?.addressLine2}</p>
                                                            : ''
                                                        }
                                                    </div>

                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ marginRight: '5px' }}>
                                                            {location?.pincode}
                                                        </p>
                                                        <p>{location?.city},</p>
                                                    </div>

                                                    <div>
                                                        <p>{capitalizeState(location?.state)},</p>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>

                                                        <p>{location?.country}</p>
                                                    </div>
                                                    <div>
                                                        <p>{location?.phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
}


export default CustomerDetails;