import React from "react";
import axiosInstance from "../../../utility/axios-instance";
import OthersFormSections from "./OthersFormSection";
import Accordion from "./accordion";
import "./uploadImages.css";
import { ProgressBar, Alert } from "react-bootstrap";

export default function UploadImages() {
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [corrections, setCorrections] = React.useState([]);
  const [uploadedImagesUrls, setUploadedImagesUrls] = React.useState(["https://processedimageswebp.s3.ap-south-1.amazonaws.com/test1/1200x1200/3800901102.42/01.webp"]);
  const [progress,setProgress] = React.useState();
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState(null);

  const handleSuccess=(msg)=>{
    setSuccess(msg);
  }
  const handleError=(msg)=>{
    setError(msg);
  }

  const generateAlert = (msg, time = 4000) => {
    setMessage(msg);
    document.querySelector("[data-file-upload-alert]").style.display = "block";
    setTimeout(() => {
      document.querySelector("[data-file-upload-alert]").style.display = "none";
    }, time);
  };

  const modifyForAccordion = (obj) => {
    let result = [];
    for (let key in obj) {
      if (obj[key].length !== 0) {
        result.push({
          title: key,
          content: obj[key],
        });
      }
    }
    return result;
  };


  const uploadedImages = uploadedImagesUrls.map((url, index) => {
    return (
      <div key={index} className="grid-image-item">
        <img src={url} alt="" className="uploadedImages" />
      </div>
    )
  })

  return (
    <>
      <div
        className="alert alert-primary p-2"
        data-file-upload-alert
        role="alert"
        style={{ display: "none" }}
      >
        {message}
      </div>

      {error && <Alert variant="danger">{error}</Alert>}
      {!error && progress && (
        <>
          <ProgressBar now={progress} label={`${progress}%`} />
        </>
      )}

      <div className="container h-100">
        <div>
          <div style={{width:'95%',margin:'auto'}}>
            {loading ? <div>processing...</div> : null}
            {!loading && step === 1 ? (
              <>
              <div>
                <h6 style={{margin:'20px 0px',textAlign:'center'}}>
                  Import Images Folder In Zipped File Format{" "}
                </h6>
                
                <OthersFormSections 
                  setLoading={setLoading}
                  setProgress={setProgress}
                  generateAlert={generateAlert}
                  setCorrections={setCorrections}
                  modifyForAccordion={modifyForAccordion}
                  setStep={setStep}
                  setUploadedImagesUrls={setUploadedImagesUrls}
                  setError={setError}
                  formName="productImages"
                  fileName="productImages"
                  title='Upload Product Images'
                  endpoint={`/uploadAndProcessImagesRoutes/analyzeFolder`}
                  templateDownloadEndpoint={`https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/folderStructure.png`}
                  exportEndpoint={`/exports/productImagesCsv`}
                  handleSuccess={handleSuccess}
                  handleError={handleError}
                  isWaterMarkControl={true}
                />

                <OthersFormSections 
                  setLoading={setLoading}
                  setProgress={setProgress}
                  generateAlert={generateAlert}
                  setCorrections={setCorrections}
                  modifyForAccordion={modifyForAccordion}
                  setStep={setStep}
                  setUploadedImagesUrls={setUploadedImagesUrls}
                  setError={setError}
                  title='Upload Brand Images'
                  formName="BrandImages"
                  fileName="BrandImages"
                  endpoint={`/uploadAndProcessImagesRoutes/analyzeBrandFolder`}
                  templateDownloadEndpoint={`https://depo24-images.s3.ap-south-1.amazonaws.com/assets/brands-logo/Brands%20Images%20FolderStructure.webp`}
                  exportEndpoint={`/exports/brandsImagesCsv`}
                  handleSuccess={handleSuccess}
                  handleError={handleError}
                />

                <OthersFormSections 
                  setLoading={setLoading}
                  setProgress={setProgress}
                  generateAlert={generateAlert}
                  setCorrections={setCorrections}
                  modifyForAccordion={modifyForAccordion}
                  setStep={setStep}
                  setUploadedImagesUrls={setUploadedImagesUrls}
                  setError={setError}
                  title='Upload L1 Images'
                  formName="L1Images"
                  fileName="L1Images"
                  endpoint={`/uploadAndProcessImagesRoutes/analyzeL1Folder`}
                  templateDownloadEndpoint={`https://depo24-images.s3.ap-south-1.amazonaws.com/assets/category-images/l1-images/L1+Images+FolderStructure.webp`}
                  exportEndpoint={`/exports/l1ImagesCsv`}
                  handleSuccess={handleSuccess}
                  handleError={handleError}
                />

                <OthersFormSections 
                  setLoading={setLoading}
                  setProgress={setProgress}
                  generateAlert={generateAlert}
                  setCorrections={setCorrections}
                  modifyForAccordion={modifyForAccordion}
                  setStep={setStep}
                  setUploadedImagesUrls={setUploadedImagesUrls}
                  setError={setError}
                  title='Upload L2 Images'
                  formName="L2Images"
                  fileName="L2Images"
                  endpoint={`/uploadAndProcessImagesRoutes/analyzeL2Folder`}
                  templateDownloadEndpoint={`https://depo24-images.s3.ap-south-1.amazonaws.com/assets/category-images/l2-images/L2+Images+FolderStructure.webp`}
                  exportEndpoint={`/exports/l2ImagesCsv`}
                  handleSuccess={handleSuccess}
                  handleError={handleError}
                />

                <OthersFormSections 
                  setLoading={setLoading}
                  setProgress={setProgress}
                  generateAlert={generateAlert}
                  setCorrections={setCorrections}
                  modifyForAccordion={modifyForAccordion}
                  setStep={setStep}
                  setUploadedImagesUrls={setUploadedImagesUrls}
                  setError={setError}
                  title='Upload L3 Images'
                  formName="L3Images"
                  fileName="L3Images"
                  endpoint={`/uploadAndProcessImagesRoutes/analyzeL3Folder`}
                  templateDownloadEndpoint={`https://depo24-images.s3.ap-south-1.amazonaws.com/assets/category-images/l3-images/L3+Images+FolderStructure.webp`}
                  exportEndpoint={`/exports/l3ImagesCsv`}
                  handleSuccess={handleSuccess}
                  handleError={handleError}
                />
              </div>
              </>
            ) : null}
            {!loading && step === 2 ? (
              <div>
                <button
                  className="custom-btn"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Re Upload
                </button>
                <Accordion accordionData={corrections} />
                {corrections.length === 0 ? setStep(3) : ""}
              </div>
            ) : null}
            {(!loading && step === 3) ? (
              <div className="success-page">
                <div className="success-message">Hurray! You Completed all steps.</div>
                <div className="image-grid">
                  {uploadedImages}
                </div>
              </div>
            ) : null}

            <div className="alerts-container" style={{marginLeft:'17%',width:'80%'}}>
              {error ? (
                <Alert variant="danger" onClose={() => setError(false)} dismissible>
                  <Alert.Heading>{error}</Alert.Heading>
                  <p>Try reloading!</p>
                </Alert>
              ) : null}
              {success ? (
                <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
                  <Alert.Heading>{success}</Alert.Heading>
                  <p>Try reloading!</p>
                </Alert>
              ) : null}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
