import { Fragment, useState, forwardRef } from 'react';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import NewCustomerModal from '../fieldSalesDashboard/newCustomerModal';
import Button from '@mui/material/Button';
import CustomerUpdateModal from './customerUpdate';
import * as React from 'react';
import Spinner from '../../../components/spinner';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadCSV } from "../../../utility/downloadCSV";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { setMyCustomers } from '../../../store/myCustomersSlice';
import Table from "../../../components/table/table";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
const month = new Date().getDate() <= 5 ? monthNames[new Date().getMonth() -1] : monthNames[new Date().getMonth()];

const MyCustomers = (props) => {
    const [loading,setLoading] = useState(false);
    const [gstNO, setGstNO] = useState();
    const [showModal, setShowModal] = useState(false);
    const [num,setNum] = useState('');
    const [errorMessage,setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [salesUser,setSalesUser] = useState('');
    const [preSalesUser,setPreSalesUser] = useState('');
    const [modeOfCommunication,setModeOfCommunication] = useState('');
    const [email,setEmail] = useState('');

    const [updateCustomerModal,setUpdateCustomerModal] = useState(false);
    const [editCustomer,setEditCustomer] = useState([]);
    const [phoneNo,setPhoneNo] = useState('');
    const [states,setStates] = useState([]);
    const [cities,setCities] = useState([]);
    const [areas,setAreas] = useState([]);
    const [selectedState,setSelectedState] = useState('');
    const [selectedCity,setSelectedCity] = useState('');

    // customerUpdate
    const [selectedArea,setSelectedArea] = useState('');
    const [selectedCityName,setSelectedCityName] = useState('');
    const [selectedStateName,setSelectedStateName] = useState('');

    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [reRender,setReRender] = useState(false);
    const myCustomers = useSelector((state) => state.myCustomers);

    const columns = [
        {
            field: 'displayName',
            headerName: 'Customer Name',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            filterable: false
        },
        {
            field: 'area',
            headerName: 'Area',
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            // type: 'enum',
            // tableName: 'users',
            // columnName: 'customer_role',
            renderCell: (params) => (
                <div>{params?.row?.area ? params?.row?.area : 'N/A'}</div>
            )
        },
        {
            field: 'lastVisitDate',
            headerName: 'Last Visit Date',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.lastVisitDate ? getRelativeTime(params?.row?.lastVisitDate) : "N/A" }</div>
            )
        },
        {
            field: 'lastOrderDate',
            headerName: 'Last Order Date',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.lastOrderDate ? getRelativeTime(params?.row?.lastOrderDate) : "N/A" }</div>
            )
        },
        {
            field: 'avgMonthlyOrder',
            headerName: 'Avg Monthly Sale',
            flex: 0.8,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.avgMonthlyOrder ? params?.row?.avgMonthlyOrder : "N/A" }</div>
            )
        },
        {
            field: 'monthOrders',
            headerName: `${month} Month Sale`,
            flex: 0.8,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.monthOrders ? params?.row?.monthOrders : "N/A" }</div>
            )
        },
        {
            field: 'visit',
            headerName: 'Visit',
            flex: 0.4,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>
                    {params?.row?.visitedCompleted === false ? (
                        <>
                            {params?.row?.modeOfCommunication === "Field Visit" ? (
                                <Button 
                                    variant="contained" 
                                    sx={{textTransform: "capitalize"}}
                                    style={{cursor:'default',padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                                >
                                    Added
                                </Button>
                            ): (
                                "N/A"
                            )}
                        </>
                    ) : (
                        <Button 
                            variant="contained" 
                            sx={{textTransform: "capitalize",padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                            onClick={(event) => visitCustomer(event,params?.row?.id)}
                        >
                            Visit
                        </Button>
                    )}
                </div>
            )
        },
        {
            field: 'call',
            headerName: 'Call',
            flex: 0.4,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>
                    {params?.row?.visitedCompleted === false ? (
                        <>
                            {params?.row?.modeOfCommunication === "On-Call" ? (
                                <Button 
                                    variant="contained" 
                                    sx={{textTransform: "capitalize"}}
                                    style={{cursor:'default',padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                                >
                                    Called
                                </Button>
                            ) : (
                                "N/A"
                            )}
                        </>
                    ) : (
                        <Button 
                            variant="contained" 
                            sx={{textTransform: "capitalize",padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                            onClick={(event) => visitCustomer(event,params?.row?.id)}
                        >
                            Call
                        </Button>
                    )}
                </div>
            )
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            filterable: false,
            flex: 0.4,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Button 
                    variant="contained"
                    sx={{
                        textTransform: "capitalize",
                        padding:'3px 10px',
                        minWidth:'unset',
                        fontSize:'12px'
                    }}
                    onClick={(event) => showContactPop(event,params?.row?.doc_id)}
                >
                    Edit
                </Button>
            )
        }
    ];

    const visitCustomer = (event,customer,mode) => {
        event.stopPropagation();
        let communicationMode;
        if (event.target.innerText === 'Visit') {
            event.target.innerText = 'Adding..';
            communicationMode = 'Field Visit';
        } else {
            event.target.innerText = 'Calling..';
            communicationMode = 'On-Call';
        }
        
        axiosInstance
            .post('/fieldSalesVisit/newCreateFieldVisit',{
                customer: customer,
                modeOfCommunication: communicationMode
            })
            .then((res) =>{
                if (event.target.innerText === 'Visit Adding..') {
                    event.target.innerText = 'Visit Added'
                } else {
                    event.target.innerText = 'On-Call';
                }
                setErrorMessage('');
                setReRender(!reRender);
            }).catch((err) => {
                console.log(err)
            })
    };

    const getModal = () => {
        setShowModal(true);
    };

    const hideModal = () => {
        setNum('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setBusinessName('');
        setGstNO('');
        setErrorMessage('');
        setSalesUser('');
        setPreSalesUser('');
        setEmail('');
        setCity('');
        setSelectedState('');
        setArea('');
        setShowModal(false);
    };

    const showContactPop = (event,customer) => {
        event.stopPropagation();
        setEditCustomer(customer);
		setUpdateCustomerModal(true);
	}

	const hideContactPop = () => {
        setArea('');
        setSelectedState('');
        setSelectedCity('');
        setSelectedArea('');
        setSelectedCityName('');
        setSelectedStateName('');
		setUpdateCustomerModal(false)
	}

    const getRelativeTime = (oldTime) => {
        //this old time have total mili second 
        const difference = Math.floor((new Date() - new Date(`${oldTime}`))/1000);
        let output = ``;
        if (difference < 60) {
            // Less than a minute has passed:
            output = `${difference} seconds ago`;
        } else if (difference < 3600) {
            // Less than an hour has passed:
            output = `${Math.floor(difference / 60)} minutes ago`;
        } else if (difference < 86400) {
            // Less than a day has passed:
            output = `${Math.floor(difference / 3600)} hours ago`;
        } else if (difference < 2620800) {
            // Less than a month has passed:
            output = `${Math.floor(difference / 86400)} days ago`;
        } else if (difference < 31449600) {
            // Less than a year has passed:
            output = `${Math.floor(difference / 2620800)} months ago`;
        }
        return output;
    }


    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };
    
	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>

                <div className="d-flex" style={{justifyContent:'flex-end',marginTop:-25}}>
                    <div style={{ display:'flex',justifyContent:'space-around' }}>

                        <Button  
                            startIcon={<RefreshIcon />} 
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize:'14px',
                                textTransform:'capitalize',
                                marginRight:'5px',
                                backgroundColor:'#EE5C55',
                                ":hover": {backgroundColor:'#EE5C55'},
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                            }}
                            // onClick={handleRefreshCustomers}
                        >
                            Refresh
                        </Button>

                        <Button  
                            startIcon={<PersonAddIcon />} 
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize:'14px',
                                textTransform:'capitalize',
                                marginRight:'5px', 
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                            }}
                            onClick={getModal}
                        >
                            Create
                        </Button>
                    </div>
                </div>

                <div className={Classes.Container}>
                    <Table
                        columns={columns}
                        dataPosition={"users"}
                        endpoint={`/fieldSalesVisit/userSpecificCustomers`}
                        rowId={'doc_id'}
                        checkBoxSelection={false}
                        version="version-1"
                        navigateOnRowClickEndpoint={`/myCustomers`}
                        reRender={reRender}
                        service={'fieldSales'}
                        tabName={'My Customers'}
                        setData={setMyCustomers}
                        data={myCustomers}
                        mobileResponsive={true}
                        exportEndpoint={'/exports/myCustomers'}
                        exportFileName={`Customers - ${new Date()}`}
                    />
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewCustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    // fetchCustomers={fetchCustomers}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    preSalesUser={preSalesUser}
                    setPreSalesUser={setPreSalesUser}
                    area={area}
                    setArea={setArea}
                    city={city}
                    setCity={setCity}
                    email={email}
                    setEmail={setEmail}
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    modeOfCommunication={modeOfCommunication}
                    setModeOfCommunication={setModeOfCommunication}
                    setReRender={setReRender}
                    reRender={reRender}
                    popUpType='customerDashboard'
                    endpoint={`/user/admin/affiliates`}
                />
            </div>

            <div className={updateCustomerModal ? Classes.UpdateSalesDialog : undefined}>
                <CustomerUpdateModal 
                    updateCustomerModal={updateCustomerModal}
                    hideContactPop={hideContactPop}
                    editCustomer={editCustomer}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    lastName={lastName}
                    setDisplayName={setDisplayName}
                    displayName={displayName}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    setEmail={setEmail}
                    email={email}
                    setBusinessName={setBusinessName}
                    businessName={businessName}
                    // fetchCustomers={fetchCustomers}
                    states={states}
                    setStates={setStates}
                    cities={cities}
                    setCities={setCities}
                    setArea={setArea}
                    area={area}
                    areas={areas}
                    setAreas={setAreas}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                    selectedCityName={selectedCityName}
                    setSelectedCityName={setSelectedCityName}
                    selectedStateName={selectedStateName}
                    setSelectedStateName={setSelectedStateName}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            {loading ? <Spinner /> : null}

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default MyCustomers;
