/**
 * Handles the concept of signed zero by returning 0 for both positive and negative zero values.
 * For non-zero numbers, the original number is returned.
 *
 * @param {number} num - The number to be handled.
 * @returns {number} - The handled number.
 */
function handleNegativeZero(num) {
  if (num === 0 || num === -0) {
    // Handle concept of signed zero
    return 0;
  } else {
    // Handle non-zero numbers
    return num;
  }
}


/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} [precision=2] - The number of decimal places to round to. Defaults to 2.
 * @returns {number} The rounded number.
 */
const roundOfUptoXDecimal = (value, precision = 2) => {
  return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
};

export { handleNegativeZero, roundOfUptoXDecimal};
