import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedPicklists: [],
};

const qcSlice = createSlice({
	name: 'qcOrders',
	initialState: initialState,
	reducers: {
		setQcOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setQcOrders, reset } = qcSlice.actions;

export default qcSlice.reducer;