import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { setReturns } from '../../../store/returnsSlice';
import Chip from '@mui/material/Chip';
import Table from "../../../components/table/table";

let columns = [
    {
        field: 'returnNumber',
        headerName: 'Return No.',
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'orderName',
        headerName: 'Order No.',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 0.7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'returnStatus',
        headerName: 'Status',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
            <Chip style={{ backgroundColor: '#ffea88' }} label={params?.row?.status} />
        )
    },
    {
        field: 'totalAmount',
        headerName: 'Total Amount',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'reason',
        headerName: 'Reason',
        flex: 0.7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'locationCode',
        headerName: 'Location Code',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 1.7,
        headerClassName: 'super-app-theme--header'
    }
];

const ReturnsNew = (props) => {
    const returns = useSelector((state) => state.returns);

    return (
        <Fragment>
            <Table
				columns={columns}
				dataPosition={"fetchedReturns"}
				endpoint={`/pgOrder/admin/returnsNew`}
				rowId={'returnNumber'}
				checkBoxSelection={false}
				version="version-1"
				service={'returns'}
				tabName={'Returns'}
				navigateOnRowClickEndpoint={`/returnsNew`}
				setData={setReturns}
				data={returns}
				mobileResponsive={true}
				exportEndpoint={'/exports/returns'}
				exportFileName={`Returns - ${new Date()}`}
			/>
        </Fragment>
    );
};

export default ReturnsNew;