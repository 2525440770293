import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from '../draftPurchaseOrder/css/draftOrderDetailPage.module.css';
import VendorDetails from './sendPIVendorDetails';
import Aside from './aside/aside';
import axiosInstance from '../../../utility/axios-instance';
import { BiArrowBack } from 'react-icons/bi';
import TaxRatesPopup from '../inboundPurchaseOrders/TaxRates_popup';
import Chip from '@mui/material/Chip';
import CapsuleChip from '../../../components/CapsuleChip';
import moment from 'moment';
import Permission from '../../auth/permissions';

const PurchaseOrderDetail = (props) => {
    const [error, setError] = useState(null);
    const [showTaxRate, setTaxRates] = useState(false);
    const [draftOrder, setDraftOrder] = useState([]);
    const [totalItems, setTotalItems] = useState(0)

    // store deliveryRemark & notes in State
    const [notes, setNotes] = useState('');
    const [deliveryRemark, setDeliveryRemark] = useState('');

    // sendExistOrder
    const [updateToggle, setUpdateToggle] = useState(true);
    const [completeExistOrder, setCompleteExistOrder] = useState([]);



    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }

    const years = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dn = new Date();

    const createdAt = (createdAt) => {
        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

    const navigate = useNavigate();

    const { id } = useParams();


    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    // Open Window Print
    const handleSendPI = (event) => {
        const hideHeader = document.getElementById('hideHeader');
        const hideProductHeader = document.getElementById('DOProductHeading');
        const qtyHeader = document.getElementById('quantity');
        const totalHeader = document.getElementById('total');
        const paymentBox = document.getElementById('paymentBox');
        document.title = draftOrder?.draftPurchaseOrderName;
        const discountHeader = document.getElementById('discount');
        const subtotalHeader = document.getElementById('subtotal');
        const total = document.getElementsByClassName('total');
        const discount = document.getElementsByClassName('percent');
        const netPrice = document.getElementsByClassName('rateExcl');
        const aside = document.getElementById('DraftPOAsideContainer');

        // const check = draftOrder?.PoLineItems.findI
        const array = draftOrder?.PoLineItems;

        const isKeyInArray = (arr, key) => {
            return arr.some(obj => obj[key]);
        }

        const isKeyInArray1 = (arr, key) => {
            return arr.some(obj => obj[key] !== '100.00');
        }

        const exist = isKeyInArray(array, "discountValue");
        const exist1 = isKeyInArray1(array, "discountValue");
        if (!exist || !exist1) {
            hideProductHeader.style.width = '88%';
            discountHeader.style.display = 'none';
            subtotalHeader.style.display = 'none';
            totalHeader.style.display = 'none';
            for (let ele of total) {
                ele.style.display = 'none';
            }

            for (let ele of discount) {
                ele.style.display = 'none';
            }

            for (let ele of netPrice) {
                ele.style.display = 'none';
            }
        } else {
            hideProductHeader.style.width = '64%';
        }

        qtyHeader.style.width = '9%';
        // hideProductHeader.style.width = '64%';
        hideHeader.style.display = 'none';
        totalHeader.style.width = '15%';
        paymentBox.style.display = 'none';
        aside.style.display = 'none';

        window.print();
        onafterprint = document.title = 'DEPO24 PORTAL';
        onafterprint = paymentBox.style.display = 'block';
        onafterprint = qtyHeader.style.width = '11%';
        onafterprint = hideHeader.style.display = 'flex';
        onafterprint = hideProductHeader.style.width = '68%';
        onafterprint = totalHeader.style.width = '13%';
        onafterprint = discountHeader.style.display = 'block';
        onafterprint = subtotalHeader.style.display = 'block';
        onafterprint = totalHeader.style.display = 'block';
        aside.style.display = 'block';

        const handleShowData = () => {
            for (let ele of total) {
                ele.style.display = 'flex';
            }

            for (let ele of discount) {
                ele.style.display = 'block';
            }

            for (let ele of netPrice) {
                ele.style.display = 'flex';
            }
            return;
        }

        onafterprint = function () {
            handleShowData()
        }
    }


    const handleTaxRates = () => {
        setTaxRates(true);
    };

    const hideTaxPopup = () => {
        setTaxRates(false);
    }


    useEffect(() => {
        if (id) {
            axiosInstance
                .get(`/purchaseOrders/purchaseOrderDetails/${id}`)
                .then((response) => {
                    let ti = 0;
                    for (
                        let i = 0;
                        i < response?.data?.data?.PoLineItems?.length;
                        i++
                    ) {
                        ti += response?.data?.data?.PoLineItems[i].quantity;
                    }
                    setTotalItems(ti);
                    setDraftOrder(response.data.data)
                    setDeliveryRemark(response.data.data.deliveryRemark);
                    setNotes(response.data.data.notes)
                    setCompleteExistOrder([response.data.data.purchaseOrderName]);
                });
        }
    }, [id, updateToggle])



    return (
        <Fragment>
            <div className={Classes.container} id="color">
                <div style={{ display:'flex',justifyContent:'space-between' }} id='hideHeader'>
                    <div className="d-flex align-items-center" style={{ gap:'1rem' }}>
                        <div>
                            <button type="button" className="btn btn-light" onClick={() => { navigate(-1) }}>
                                <BiArrowBack />
                            </button>
                        </div>

                        <div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <h6 className={Classes.draftOrderDetail}>
                                    {draftOrder?.purchaseOrderName}
                                </h6>
                                <div style={{ display:'flex',justifyContent:'center',alignItems: 'center' }}>
                                    <p 
                                        style={{ 
                                            padding:'2px 10px',
                                            borderRadius:10,
                                            backgroundColor: draftOrder.status === 'Completed' ? '#d4edda' : '#ffd79d'
                                        }}
                                    >
                                        {draftOrder.status}
                                    </p>
                                </div>
                                <div style={{ display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <p style={{ padding:'2px 10px',borderRadius:10,backgroundColor:'#FFEA8A'}}>
                                        {draftOrder?.PoPaymentDetails?.paymentStatus}
                                    </p>
                                </div>
                            </div>

                            <h6 className={Classes.draftOrderDetail}>
                                {moment(draftOrder.createdAt).format('MMM DD, YYYY h:mm:ss A')}
                            </h6>
                        </div>
                    </div>
                    <div style={{ display:'flex',justifyContent:'center',alignItems:'center',marginRight:10,gap:12}}>
                        {draftOrder?.status !== 'Closed' && (
                            <Permission 
                                service="editPurchaseOrders"
                                permission="update"
                            >
                                <button
                                    className={[Classes.Btn, Classes.sendPIButton].join(' ')}
                                    id='submitBtn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/editPurchaseOrder/${id}`);
                                    }}
                                >
                                    Edit PO
                                </button>
                            </Permission>
                        )}
                        <button
                            className={[Classes.Btn, Classes.sendPIButton].join(' ')}
                            id='submitBtn'
                            onClick={(e) => handleSendPI(e)}
                        >
                            Send PO
                        </button>
                    </div>
                </div>

                {error ? (
                    <Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}


                {draftOrder && (
                    <div className={Classes.draftOrderName} id='orderNamePrint'>
                        <p style={{ textAlign: 'center' }}>Purchase Order</p>
                        <div style={{ display: 'flex' }}>
                            <h6 style={{ marginRight: '5px' }}>Po Name:-</h6>
                            <p>{draftOrder?.purchaseOrderName}</p>
                        </div>
                    </div>
                )}

                <VendorDetails draftOrder={draftOrder}/>

                <div className={Classes.mainContainer} style={{ marginTop:30 }}>
                    <div className={Classes.containBoxes} id='productContainer'>
                        <div className={[Classes.Box,'box'].join(' ')} id='productBox'>
                            {draftOrder ? (
                                <div style={{ fontSize:14,padding:0 }}>
                                    <div className={Classes.Thead}>
                                        <div 
                                            style={{ padding:'10px 20px',fontWeight: 600}}
                                            className={Classes.ProductHeading}
                                            id='DOProductHeading'
                                        >
                                            <p>Product</p>
                                        </div>

                                        <div
                                            id='discount'
                                            className={[Classes.hideResponsive, 'discount'].join(' ')}
                                            style={{ width: '12%', padding: '10px 0px' }}
                                        >
                                            <p style={{ fontWeight:600 }}>Discount</p>
                                            <p style={{ fontSize:13 }} id='hideInclGst'>
                                                (Incl. GST)
                                            </p>
                                        </div>

                                        <div
                                            id='quantity'
                                            className={Classes.hideResponsive}
                                            style={{ width:'11%', padding:'10px 0px' }}
                                        >
                                            <p style={{ fontWeight:600 }}>Quantity</p>
                                        </div>

                                        <div
                                            id='subtotal'
                                            className={Classes.hideResponsive}
                                            style={{ padding:'10px 0px 10px 10px',width:'14%' }}
                                        >
                                            <p style={{ fontWeight:600,paddingLeft:5 }}>
                                                Subtotal
                                            </p>
                                            <p>(Excl. GST)</p>
                                        </div>

                                        <div
                                            id='total'
                                            className={Classes.hideResponsive}
                                            style={{ padding:'10px 0px 10px 10px',width:'13%' }}
                                        >
                                            <p style={{ fontWeight:600,paddingLeft:5 }}>Total</p>
                                            <p>(Incl. GST)</p>
                                        </div>
                                    </div>

                                    <div id='productContainer'>
                                        {draftOrder?.PoLineItems?.map((item, index) => (
                                            <div
                                                className={[Classes.containProduct, 'variantProduct'].join(' ')}
                                                id={item?.lineItemId}
                                                key={index}
                                            >
                                                <div className={[ Classes.productDetail,'products'].join(' ')} id={index}>
                                                    <div>
                                                        <img
                                                            src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                            alt="icon"
                                                            className={Classes.productImage}
                                                        />
                                                    </div>

                                                    <div className={Classes.VariantDetail}>
                                                        <div>
                                                            <p style={{ color:'#0d6efd' }}>{item?.variantTitle || item?.productTitle}</p>
                                                        </div>

                                                        <div style={{ color: '#6d7175' }}>
                                                            <p>{item?.sku}</p>
                                                        </div>

                                                        <div className={[Classes.rateFontSize, 'rateExcl'].join(' ')}>
                                                            <p className={Classes.newRateExclGst}>Net Price</p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight:2 }}>&#8377;</p>
                                                                <p>{item?.discountedUnitPrice ? item?.discountedUnitPrice : item?.originalUnitPrice}</p>
                                                            </div>
                                                        </div>

                                                        <div style={{ display:'flex' }} className={Classes.rateFontSize}>
                                                            <p className={Classes.newRateExclGst}>MRP</p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                <p>{item?.mrp}</p>
                                                            </div>
                                                        </div>

                                                        <div style={{ marginTop:'5px' }}>
                                                            <Chip label={item?.status} size="small" variant="outlined" />
                                                        </div>

                                                        <div className='priceLocation'>
                                                            {item?.districtForPrices && <CapsuleChip capsulePart1="District For Prices" capsulePart2={`${item?.districtForPrices}`} chipColor="#243750" />}
                                                        </div>
                                                        {/* In screen is smaller than 650px then show this box */}
                                                        <div className={Classes.ShowResponsive}>
                                                            <div className='percent' style={{ fontSize:14,display:'flex' }}>
                                                                <div>
                                                                    <p className={Classes.Text}>Discount Incl. GST:</p>
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    {item.discountPercentage}
                                                                    {/* {item.discountType === 'PERCENTAGE' ? (
                                                                        <p>
                                                                            {item.discountValue}
                                                                        </p>
                                                                    ) : (
                                                                        <p>
                                                                            {handleDiscount(
                                                                                item.mrp,
                                                                                item.discountedUnitPrice ? item.discountedUnitPrice : item.originalUnitPrice,
                                                                                item.gstPercentage
                                                                            )}

                                                                        </p>
                                                                    )} */}
                                                                    <p>%</p>
                                                                </div>

                                                                {item.appliedDiscount !== null && (
                                                                    <div className={[Classes.hideDiscountTitle,'hideDiscountTitle' ].join(' ')}>
                                                                        {item.appliedDiscount}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className='qty' style={{ display:'flex' }}>
                                                                <p className={Classes.Text}>Quantity:</p>
                                                                <p>{item?.quantity}</p>
                                                            </div>

                                                            <div className='total' id='discTotalPrint' style={{ display:'flex' }}>
                                                                <p className={Classes.Text}>Subtotal:</p>
                                                                &#8377; {item?.discountedSubTotal}
                                                            </div>

                                                            <div className='total' id='discTotalPrint' style={{ display:'flex' }}>
                                                                <p className={Classes.Text}>Total:</p>
                                                                &#8377; {item?.discountedTotal ? item?.discountedTotal : item?.originalTotal}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')}>
                                                    <p style={{textAlign:'center'}}>{item.discountPercentage}%</p>

                                                    {item.discountTitle === "MANUAL" && item.discountValue && (
                                                        <div className={[Classes.hideDiscountNewTitle,'hideDiscountTitle'].join(' ')}>
                                                            {item.discountTitle}
                                                        </div>
                                                    )}

                                                    {item.node?.appliedDiscount !== null && (
                                                        <div className='exclGSTPrint' style={{ fontSize:12,display:'none' }}>
                                                            <p>(Incl. GST)</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div
                                                    id='quantityCount'
                                                    className={[Classes.percent, Classes.Quantity, 'draftPoQty'].join(' ')}
                                                    style={{textAlign:'start'}}
                                                >
                                                    <p style={{ fontWeight: '500' }}>Total:{item?.quantity}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Open: {item?.openQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Received: {item?.receivedQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Qc Pass: {item?.qcPassQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Qc Fail: {item?.qcFailQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Returned: {item?.returnedQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Closed: {item?.closedQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Frc Closed: {item?.forceClosedQty || 0}</p>
                                                </div>

                                                <div
                                                    className={[Classes.percent, 'total'].join(' ')}
                                                    style={{ padding: '10px 0px 10px 5px', width: '80px' }}
                                                    id='discTotalPrint'
                                                >
                                                    &#8377;{item?.discountedSubTotal}
                                                </div>

                                                <div
                                                    className={[Classes.percent, 'total'].join(' ')}
                                                    style={{ padding: '10px 0px 10px 5px', width: '80px' }}
                                                    id='discTotalPrint'
                                                >
                                                    &#8377;
                                                    {item?.discountedTotal ? item?.discountedTotal :item?.originalTotal}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className={[Classes.Box,'box'].join(' ')} id='paymentBox'>
                            <div className={Classes.containerBox}>
                                <div className="d-flex justify-content-between mb-3">
                                    <h6 style={{ margin:'auto 0px' }}>Payment</h6>
                                </div>

                                <div className={Classes.fontSize}>
                                    {draftOrder?.PoPaymentDetails?.invoiceNumber && (
                                        <div style={{ marginBottom:'1rem',width:'100%',display:'flex' }}>
                                            <div style={{ width: '25%' }}>
                                                <p>Invoice Number</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {draftOrder?.PoPaymentDetails?.invoiceNumber}
                                            </p>
                                        </div>
                                    )}

                                    {draftOrder?.PoPaymentDetails?.invoiceDate && (
                                        <div style={{ marginBottom:'1rem',width:'100%',display:'flex'}}>
                                            <div style={{ width: '25%' }}>
                                                <p>Invoice Date</p>
                                            </div>

                                            <p style={{ width:'75%',textAlign:'end' }}>
                                                {formatDate(draftOrder?.PoPaymentDetails?.invoiceDate)}
                                            </p>
                                        </div>
                                    )}

                                    {draftOrder?.PoPaymentDetails?.paymentMode && (
                                        <div style={{ marginBottom:'1rem',width:'100%',display:'flex' }}>
                                            <div style={{ width:'25%' }}>
                                                <p>Payment Mode</p>
                                            </div>

                                            <p style={{ width:'75%',textAlign:'end' }}>
                                                {draftOrder?.PoPaymentDetails?.paymentMode?.toUpperCase()}
                                            </p>
                                        </div>
                                    )}

                                    <div style={{ marginBottom:'1rem',width:'100%',display:'flex' }}>
                                        <div style={{ width: '25%' }}>
                                            <p>Subtotal</p>
                                        </div>

                                        <div style={{ width:'50%',display:'flex' }}>
                                            <p style={{ marginRight:3 }}>{totalItems}</p>
                                            <p>Items</p>
                                        </div>

                                        {draftOrder && (
                                            <p style={{ width:'25%',textAlign:'end' }}>
                                                &#8377;{draftOrder?.PoPaymentDetails?.subTotalAmount}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <div style={{ marginBottom: '1rem' }}>
                                            {draftOrder && (
                                                <div>
                                                    {draftOrder?.PoShipmentDetails?.freightCharges && (
                                                        <div style={{ width:'100%',display:'flex' }}>
                                                            <p style={{ width:'25%' }}>Shipping</p>

                                                            <p style={{ width:'50%' }}>
                                                                {draftOrder?.PoShipmentDetails?.transporterName}
                                                            </p>

                                                            <p style={{ width:'25%',textAlign:'end' }}>
                                                                &#8377;{draftOrder?.PoShipmentDetails?.freightCharges}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        {draftOrder?.length !== 0 && (
                                            <div style={{ marginBottom:'1rem' }}>
                                                {draftOrder?.PoPaymentDetails?.overallDiscountValue && (
                                                    <div style={{ display:'flex',width:'100%' }}>
                                                        <p style={{ width:'25%' }}>Discount</p>

                                                        <div style={{ width:'50%',display:'flex' }}>
                                                            <p style={{ marginRight:3 }}>
                                                                {draftOrder.PoPaymentDetails?.overallDiscountTitle}
                                                            </p>
                                                        </div>
                                                        { draftOrder?.PoPaymentDetails?.overallDiscountType === "PERCENTAGE" ? <p style={{ width:'25%',textAlign:'end' }}>
                                                            -{draftOrder.PoPaymentDetails?.overallDiscountValue}%
                                                        </p> : <p style={{ width:'25%',textAlign:'end' }}>
                                                            -&#8377;{draftOrder.PoPaymentDetails?.overallDiscountValue}
                                                        </p>}
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div style={{ marginBottom: '1rem', display: 'flex' }}>
                                            <p style={{ width:'25%' }} id='tax'>Tax</p>
                                            {draftOrder.PoTaxLines ? (
                                                <p
                                                    id='taxRate'
                                                    style={{ color:'#0d6efd',cursor: 'pointer',width:'50%' }}
                                                    onClick={handleTaxRates}
                                                >
                                                    Show tax rates
                                                </p>
                                            ) : (
                                                <p style={{ width:'50%' }}></p>
                                            )}


                                            {draftOrder && (
                                                <p
                                                    id='taxRateAmount'
                                                    style={{ textAlign:'end',color: '#000',
                                                        width: '25%'
                                                    }}
                                                >
                                                    &#8377;{draftOrder?.PoPaymentDetails?.totalTaxAmount
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{ fontSize:14,fontWeight: '600' }}
                                    >
                                        <p>Total</p>
                                        <div>
                                            {draftOrder ? (
                                                <p>&#8377;{Math.round(draftOrder.PoPaymentDetails?.totalAmount)}</p>
                                            ) : (
                                                <p>&#8377;0.00</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {draftOrder.length !== 0 && (
                            <div id='TaxRatesPrint'>
                                {draftOrder.PoTaxLines && (
                                    <div className={showTaxRate ? Classes.UpdateSalesDialog: undefined}>
                                        <TaxRatesPopup
                                            showTaxRate={showTaxRate}
                                            setTaxRates={setTaxRates}
                                            handleTaxRates={handleTaxRates}
                                            hideTaxPopup={hideTaxPopup}
                                            draftOrder={draftOrder}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className={Classes.asideContainer} id='DraftPOAsideContainer'>
                        <Aside
                            draftOrder={draftOrder}
                            deliveryRemark={deliveryRemark}
                            notes={notes}
                            setUpdateToggle={setUpdateToggle}
                        />
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

export default PurchaseOrderDetail;