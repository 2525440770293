import React, { useState } from 'react'
import CapsuleChip from '../../../components/CapsuleChip'
import {List, ListItem, ListItemText, Typography, Divider, ListItemIcon} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export default function ChildProductsList(props) {
    const {capsulePart1, capsulePart2, colorChip, childProducts} = props
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    return (
        <div>
            <div style={{ cursor: 'pointer'}}
                onClick={() => setShow(!show)}>
                <CapsuleChip capsulePart1={capsulePart1} capsulePart2={capsulePart2} chipColor={colorChip} />
            </div>
            {
                show && <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <p style={{fontSize: '14px',fontWeight:500, color: 'grey'}}>Included Child Products</p>
                    <Divider variant='fullWidth' component={'li'}/>
                    {childProducts.map(childProduct => (
                        <>
                        <ListItem sx={{
                            borderRadius: '5px',
                            boxShadow: '1px 1px 1px 1xp black'
                        }}>
                        <ListItemIcon>
                            <AddShoppingCartIcon sx={{color: colorChip}}/>
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography
                                sx={{ display: 'inline', cursor: "pointer" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                onClick={() => navigate(`/products/${childProduct?.variantId}`)}
                            >
                                <span style={{color:'#0d6efd', fontWeight: '600'}}>{childProduct?.childSku}</span>
                                {`          x           ${childProduct?.childQtyPerBundle}`}
                            </Typography>
                        } 
                        secondary={
                            <Typography
                                sx={{ display: 'block', fontSize: '13px',fontWeight: '300' }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                            >
                                {`Ordered Qty : ${childProduct?.totalChildQuantityInParent}`}
                            </Typography>
                        }
                        />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    </>
                    ))

                    }
                </List>
            }
        </div>
    )
}
