import * as React from "react";
import Table from "../../../components/table/table";
import { Fragment, useState } from "react";
import ExportDatas from "../../../components/exportDataDialog";
import Button from "@mui/material/Button";
import MobileResponsive from "../orderAllocation/mobileResponsive";
import Classes from "../customers/css/customer.module.css";
import { useSelector } from "react-redux";
import { setProductTransactions } from "../../../store/productTransactionSlice";
import moment from "moment";

const columnVisibilityModel = {
};

const columns = [
  {
    field: `sku`,
    headerName: `SKU`,
    flex: 1.2,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `column_altered`,
    headerName: `Field Updated`,
    flex: 1.1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `district`,
    headerName: `District`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `franchise_name`,
    headerName: `Franshise`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `previous_value`,
    headerName: `Previous Value`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `current_value`,
    headerName: `Updated Value`,
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `group_id`,
    type : 'number',
    headerName: `Group ID`,
    flex: 0.5,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `alter_by`,
    headerName: `Updated By`,
    flex: 0.8,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: false,
    filterable: true,
  },
  {
    field: `alter_at`,
    headerName: `Updated At`,
    flex: 1.2,
    headerClassName: "super-app-theme--header",
    headerAlign: "left",
    align: "left",
    editable: false,
    sortable: true,
    filterable: true,
    renderCell: (params) =>
      moment(params?.row?.alter_at).format("MMM DD, YYYY h:mm:ss A"),
  },
];

const ProductTransactions = () => {
  const [open, setOpen] = useState(false);
  const [isMovementWithinVisible, setIsMovementWithinVisible] = useState(true);
  const handleExportDialog = () => {
    setOpen(true);
  };
  
  const productTransactions = useSelector((state) => state.productTransactions);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: -25,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: 14,
            textTransform: "capitalize",
            padding: "4px 14px",
            letterSpacing: "0.5px",
          }}
          onClick={handleExportDialog}
        >
          Export
        </Button>
      </div>

      <div className={Classes.MuiTable}>
        <Table
          rowId={"id"}
          columns={columns}
          dataPosition={"fetchedProductTransactions"}
          endpoint={`/product/fetchProductsHistory`}
          checkBoxSelection={false}
          service={"productTransactions"}
          columnVisibilityModel={columnVisibilityModel}
          tabName={"Product Transactions"}
          setData={setProductTransactions}
          data={productTransactions}
          version="version-3"
        />
      </div>

      <div className={Classes.MobileResponsive}>
        <MobileResponsive
          columns={columns}
          dataPosition={"fetchedProductTransactions"}
          endpoint={`/product/fetchProductsHistory`}
          rowId={"id"}
        />
      </div>

      <ExportDatas
        open={open}
        handleClose={handleClose}
        endPoint={"/exports/productTransactions"}
        dataName="Product Transactions"
      />
    </Fragment>
  );
};

export default ProductTransactions;
