import { Fragment } from "react";
import Table from '../../../components/table/table';
import { setInterWarehouseMoves } from '../../../store/interWarehouseMove';
import { useSelector } from 'react-redux';

const InterWarehouseInventoryMoves = () => {
  const interWarehouseMoves = useSelector((state) => state.interWarehouseMoves)

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1.9,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "created_by",
      headerName: "CREATED BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "draft_inter_warehouse_inventory_move_id",
      headerName: "DRAFT INTER WAREHOUSE INVENTORY MOVE ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "order_id",
      headerName: "ORDER ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "purchase_order_id",
      headerName: "PURCHASE ORDER ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  return (
    <Fragment>
      <div className='' style={{ width: "100%" }}>
        <Table
          columns={columns}
          dataPosition={"interWarehouseMove"}
          endpoint={`/interWarehouseInvMove/fetchInterWarehouseInventoryMoves`}
          rowId={'id'}
          checkBoxSelection={false}
          service={'franchise'}
          tabName={'Franchise'}
          setData={setInterWarehouseMoves}
          data={interWarehouseMoves}
        />
      </div>
    </Fragment>
  )
}

export default InterWarehouseInventoryMoves;