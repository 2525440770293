import { Fragment, useState, useEffect } from "react";
import Classes from "../../ProductDetailPage/css/addProduct.module.css";
import axiosInstance from "../../../../../utility/axios-instance";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ProductHistory = (props) => {
  const {
    isOpen,
    onClose,
    payloadToUpload,
  } = props;
  const [history, setHistory] = useState([]);

  const handleClose = () => {
    onClose();
  };

  // function to get the date from the date string we get from DB in (22nd Arpil 2024) form.
  function formatDateString(dateString) {
    const date = new Date(dateString);

    const options = { day: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);
    const month = formattedDate.split(" ")[0];
    const year = date.getFullYear();

    const textualDate = `${ordinalSuffix} ${month} ${year}`;

    return textualDate;
  }
  // Helper function to get the ordinal suffix
  function getOrdinalSuffix(day) {
    const lastDigit = day % 10;
    const secondLastDigit = Math.floor(day / 10) % 10;

    if (secondLastDigit === 1) {
      return `${day}th`;
    }

    switch (lastDigit) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }

  // to get the time in IST format.
  function getISTTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);

    const istDateObj = new Date(
      dateObj.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );

    const hours = istDateObj.getHours();
    const minutes = istDateObj.getMinutes();
    const seconds = istDateObj.getSeconds();

    let ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    const istTime = `${formattedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;

    return `${istTime}`;
  }

  const groupedHistory = history.reduce((acc, item) => {
    const groupId = item.group_id;
    if (!acc[groupId]) {
      acc[groupId] = [];
    }
    acc[groupId].push(item);
    return acc;
  }, {});

  const [expandedGroups, setExpandedGroups] = useState({});

  const toggleGroupExpansion = (groupId) => {
    setExpandedGroups((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };
  


  useEffect(() => {
    const fetchHistory = () => {
      try {
        if (payloadToUpload?.pgDbProductId) {
          axiosInstance.get(`product/history/${payloadToUpload?.pgDbProductId}`).then((res) => {
            setHistory(res.data);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchHistory();
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
          "& .MuiDialog-paper": {
            borderRadius: "15px",
          },
        }}
      >
        <DialogTitle>Product History</DialogTitle>
        {history.length !== 0 ? (
          <DialogContent
            sx={{ minHeight: "70vh", zIndex: "10", background: "#F9FAFB",'&::-webkit-scrollbar-thumb': {
              background: '#D3D3D3',
              borderRadius: '3px',
            }, }}
          >
            <img
              src="/assets/plus.png"
              alt=""
              style={{
                width: "20px",
                margin: "2% 7.6%",
                display: "inline",
              }}
            />
            <div style={{ marginTop: "-60px",width:"80%",'&::-webkit-scrollbar': {
            width: '5px',
          },
           }}>
              {Object.entries(groupedHistory)
                .sort(([groupIdA], [groupIdB]) => groupIdB - groupIdA)
                .map(([groupId, historyItems]) => (
                  <div key={groupId}>
                    <ul className={Classes.tMain}>
                      <div className={Classes.timeLine}>
                        {historyItems.length > 0 && (
                          <li
                            style={{ marginBottom: "10px", listStyle: "none" }}
                          >
                            <div
                              className={Classes.container}
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "15px",
                                maxHeight: "110px",
                                left: "9%",
                                marginBottom: "15px",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              }}
                            >
                              <img
                                className={Classes.iconC}
                                src="/assets/update2.png"
                                alt=""
                              />
                              <div className={Classes.textBox} style={{}}>
                                {historyItems.length !== 1 ? (
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#615e61",
                                    }}
                                  >
                                    {historyItems.length} Changes by{" "}
                                    {historyItems[0].alter_by} on{" "}
                                    {formatDateString(historyItems[0].alter_at)}{" "}
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontWeight: "600",
                                      color: "#615e61",
                                    }}
                                  >
                                    {historyItems.length} Change by{" "}
                                    {historyItems[0].alter_by} on{" "}
                                    {formatDateString(historyItems[0].alter_at)}{" "}
                                  </p>
                                )}
                              </div>
                            </div>
                            <button
                              onClick={() => toggleGroupExpansion(groupId)}
                              style={{
                                marginLeft: "140px",
                                border: "none",
                                fontWeight: "600",
                                backgroundColor: "transparent",
                                color: "#615e61",
                              }}
                            >
                              View All Changes{" "}
                              {expandedGroups[groupId] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </button>
                          </li>
                        )}
                        {expandedGroups[groupId] &&
                          historyItems.map((item) => (
                            <li
                              key={item.id}
                              style={{
                                marginBottom: "10px",
                                listStyle: "none",
                              }}
                            >
                              <div
                                className={Classes.container}
                                style={{
                                  // backgroundColor: "white",
                                  marginTop: "-10px",
                                  maxHeight: "110px",
                                  borderRadius: "10px",
                                  left: "9%",
                                }}
                              >
                                <img
                                  className={Classes.iconC}
                                  src="/assets/arrow.png"
                                  alt=""
                                  style={{
                                    top: "25px",
                                    width: "16px",
                                  }}
                                />
                                <div className={Classes.textBox} style={{}}>
                                  <small>
                                    {formatDateString(item.alter_at)}{" "}
                                    {getISTTime(item.alter_at)}
                                  </small>
                                  <p
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "500",
                                      color: "#615e61",
                                    }}
                                  >
                                    {item.column_altered} value is changed from{" "}
                                    <i>{item.previous_value.length > 100
                                      ? item.previous_value.slice(0, 100) + "..."
                                      : item.previous_value}</i>
                                      {" "}to{" "}
                                    <i><b>{item.current_value.length > 100
                                      ? item.current_value.slice(0, 100) + "..."
                                      : item.current_value}</b></i>
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                      </div>
                    </ul>
                  </div>
                ))}
            </div>
          </DialogContent>
        ) : (
          <DialogContent
            style={{
              minHeight: "70vh",
              zIndex: "10",
              background: "#F9FAFB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#615e61",
            }}
          >
            <h1>No Product History Available</h1>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleClose} variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductHistory;
