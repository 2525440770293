import { Fragment, useState, forwardRef } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBar from '../../../components/SnackBar';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditMinMaxQtyProduct = (props) => {
    const { open, handleClose, editMinMaxQtyProducts, reRender, setReRender } = props;
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const data = {
            minQty: parseFloat(event.target.minQty.value),
            maxQty: parseFloat(event.target.maxQty.value)
        }
        await axiosInstance
            .put(`/pos/productsTrack/${editMinMaxQtyProducts?.productId}`, data)
            .then((res) => {
                setLoading(false);
                const successMessage = 'Product Updated Successfully'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleClose();
                setReRender(!reRender);
            })
            .catch((error) => {
                setLoading(false);
                // alert('Error in QC');
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{`Update Min and Max Qty Of this SKU: ${editMinMaxQtyProducts?.sku}`}</DialogTitle>
                <div style={{ margin: '20px 0px 5px 0px'}}>
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex',padding:"0px 20px",justifyContent:'space-between',marginBottom:40  }}>
                            <div style={{width:'49%'}}>
                                <TextField
                                    size='small'
                                    label="Min Qty"
                                    name='minQty'
                                    required
                                    type="number"
                                    defaultValue={editMinMaxQtyProducts?.minQty}
                                    key={editMinMaxQtyProducts?.minQty}
                                    min={1}
                                    sx={{width:'100%'}}
                                />
                            </div>

                            <div style={{width:'49%'}}>
                                <TextField
                                    size='small'
                                    label="Max Qty"
                                    name='maxQty'
                                    required
                                    type="number"
                                    defaultValue={editMinMaxQtyProducts?.maxQty}
                                    key={editMinMaxQtyProducts?.maxQty}
                                    min={1}
                                    sx={{width:'100%'}}
                                />
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>CLOSE</Button>
                            <Button type="submit" id='moveInventory'>SUBMIT</Button>
                        </DialogActions>
                    </form>
                </div>
            </Dialog>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}

export default EditMinMaxQtyProduct;