import { Fragment } from "react";
import Classes from "../../pages/home/createPurchaseOrder/css/create.module.css";

const ShipmentDetails = (props) => {
  const {
    shippingDetails,
    handleShippingChange,
    handleShippingPrice,
    inputRef,
  } = props;

  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-IN");
    }
  };


  return (
    <Fragment>
      <div className={Classes.Box} style={{ padding: "1.1rem" }}>
        <h6 style={{ marginBottom: 15 }}>Shipment Details</h6>
        {shippingDetails?.transporterName && (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #DBDDE0",
              margin: "12px 0px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                marginRight: "7px",
                fontSize: "14px",
                width: "50%",
              }}
            >
              Transporter Name:
            </p>
            <p
              style={{
                color: "#2c6ecb",
                fontSize: "14px",
                width: "50%",
              }}
            >
              {shippingDetails?.transporterName}
            </p>
          </div>
        )}
        {shippingDetails?.transporterType && (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #DBDDE0",
              margin: "12px 0px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                marginRight: "7px",
                fontSize: "14px",
                width: "50%",
              }}
            >
              Transporter Type:
            </p>
            <p
              style={{
                color: "#2c6ecb",
                fontSize: "14px",
                width: "50%",
              }}
            >
              {shippingDetails?.transporterType}
            </p>
          </div>
        )}
        {shippingDetails?.estimatedDispatchDate && (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #DBDDE0",
              margin: "12px 0px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                marginRight: "7px",
                fontSize: "14px",
                width: "50%",
              }}
            >
              Estimated Dispatch Date:
            </p>
            <p
              style={{
                color: "#2c6ecb",
                fontSize: "14px",
                width: "50%",
              }}
            >
              {formatDate(shippingDetails?.estimatedDispatchDate)}
            </p>
          </div>
        )}
        {shippingDetails?.estimatedReceivingDate && (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #DBDDE0",
              margin: "12px 0px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                marginRight: "7px",
                fontSize: "14px",
                width: "50%",
              }}
            >
              Estimated Delivery Date:
            </p>
            <p
              style={{
                color: "#2c6ecb",
                fontSize: "14px",
                width: "50%",
              }}
            >
              {formatDate(shippingDetails?.estimatedReceivingDate)}
            </p>
          </div>
        )}
        <div style={{ marginBottom: 10 }}>
          <p style={{ margin: "auto 0px 8px", fontSize: 13, fontWeight: 600 }}>
            Freight Charges
          </p>
          <input
            type="number"
            step="any"
            className="form-control"
            id="freightCharges"
            onKeyDown={preventNegativeValues}
            value={shippingDetails?.freightCharges || ""}
            onChange={(e) => handleShippingChange("freightCharges", e)}
            onBlur={(e) => handleShippingPrice(e)}
            ref={inputRef}
            name="freightCharges"
            placeholder="Freight Charges (if any)"
          />
        </div>
        {shippingDetails?.biltyNumber && (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #DBDDE0",
              margin: "12px 0px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                marginRight: "7px",
                fontSize: "14px",
                width: "50%",
              }}
            >
              Bilty Number:
            </p>
            <p
              style={{
                color: "#2c6ecb",
                fontSize: "14px",
                width: "50%",
              }}
            >
              {shippingDetails?.biltyNumber}
            </p>
          </div>
        )}
        {shippingDetails?.biltyDate && (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #DBDDE0",
              margin: "12px 0px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                marginRight: "7px",
                fontSize: "14px",
                width: "50%",
              }}
            >
              Bilty Date:
            </p>
            <p
              style={{
                color: "#2c6ecb",
                fontSize: "14px",
                width: "50%",
              }}
            >
              {formatDate(shippingDetails?.biltyDate)}
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ShipmentDetails;
