import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	storesImages: [],
};

const storesImageSettingsSlice = createSlice({
	name: 'storesImages',
	initialState: initialState,
	reducers: {
		setStoresImage: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setStoresImage, reset } = storesImageSettingsSlice.actions;

export default storesImageSettingsSlice.reducer;