import { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = (props) => {
    const { snackBar, setSnackBar } = props;
    const navigate = useNavigate();

    const handleSnackBarClose = () => {
		setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: false }
		});
	};

    const handleAlertClick = () => {
        if (snackBar?.redirectUrl) {
            navigate(snackBar?.redirectUrl);
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: false }
            });
        }
    };

    return (
        <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
            <Alert 
                onClose={handleSnackBarClose} 
                severity={snackBar?.type} 
                sx={{ width: '100%' }} 
                onClick={handleAlertClick}
            >
                {snackBar?.message}
            </Alert>
        </Snackbar>
    );
}

export default SnackBar;