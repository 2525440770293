import { Outlet, Navigate } from "react-router-dom";
import PermissionError from "../components/auth/PermissionError";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CheckRoleBasedPermission } from "./checkRoleBasedPermission";
import Spinner from "../components/spinner";

const PrivateRoutes = ({ service, permission }) => {
  const [permissible, setPermissible] = useState(false);
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state?.auth);
  const user = auth?.user;

  useEffect(() => {
    if (auth?.authenticated) {
      if (CheckRoleBasedPermission(user, service, permission)) {
        setPermissible(true);
      } else {
        setPermissible(false);
      }
    } else {
      return <Navigate to="/sign-in" />;
    }
  }, [auth?.authenticated]);

  useEffect(() => {
    if (auth?.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [auth?.loading]);

  return loading ? (
    <Spinner />
  ) : permissible ? (
    <Outlet />
  ) : (
    <PermissionError />
  );
};

export default PrivateRoutes;
