import React from "react";
import "./accordion.css";
import AccordionItem from "./accordionItem";

const Accordion = ({accordionData}) => {

  return (
    <React.Fragment>
      <div className="custom-accordion">
      {accordionData.map(({ title, content }, index) => (
          <AccordionItem title={title} content={content} index={index} key={index}/>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Accordion;
