import React, { Fragment,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import { useNavigate } from 'react-router-dom';
import Spinner from "../../../../components/spinner";

const ConfirmPopup = (props)=> {
    const [loading,setLoading] = useState(false);
    const { showConfirmPopup, hideConfirmPopup, productId } = props;

    const navigate = useNavigate();

    const handleDeleteProduct = () => {
        setLoading(true);

        axiosInstance
			.post(`/product/delete?Id=${productId}`)
			.then((res) => {
                setLoading(false);
				navigate('/productsCreated')
			})
    }

    return (
        <Fragment>
            {showConfirmPopup && (
                <div className={Classes.confirmModal} style={{ bottom:'32%',top:'14%' }}>
                    <div style={{ textAlign:'center',margin:'30px 0px' }}>
                        <FontAwesomeIcon 
                            icon={faCircleXmark} 
                            style={{ fontSize:75,color: 'rgb(211, 47, 47)',cursor:"pointer" }}
                        />
                    </div>

                    <div style={{ color:'#000',textAlign: 'center',fontSize:30 }}>
                        <p>Are you sure?</p>
                    </div>

                    <div style={{ color:'#000',display:'flex',justifyContent:'center',padding:'10px 0px' }}>
                        <p style={{ marginRight:5,marginLeft:2 }}>
                            Do you really want to delete this Product ?
                        </p>
                    </div>

                    <div className={Classes.Button}>
                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => handleDeleteProduct(e)}
                                style={{backgroundColor:'rgb(225, 45, 57)',color:'white',border:0,borderRadius:20 }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => hideConfirmPopup(e)}
                                style={{border:0,backgroundColor:'rgb(98, 125, 152)',borderRadius:20,color:'#fff'}}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default ConfirmPopup;