
import React from 'react';

const CapsuleChip = (props) => {
    let { capsulePart1, capsulePart2, chipColor } = props;
    if (!chipColor) {
        chipColor = "#243750"
    }
    return (
        <div className={"capsule-chip"} style={{
            "fontSize": "10px",
            "display": "flex",
            "marginTop": "10px",
        }}>
            <span style={{
                "padding": "4px 10px",
                "borderRadius": "10px 0px 0px 10px",
                "background": `${chipColor}`,
                "color": "white",
                "fontWeight": "500",
            }}>
                {capsulePart1}
            </span>
            <span style={{
                "border": `2px solid ${chipColor}`,
                "borderRadius": "0px 10px 10px 0",
                "padding": "2px 10px 2px 10px",
                "fontWeight": "500",
            }}>
                {capsulePart2}
            </span>
        </div>
    );
};

export default CapsuleChip;
