import React, { useState } from "react";

const AccordionItem = ({ title, content, index }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <React.Fragment>
      <div className="custom-accordion-item">
        <div
          className="custom-accordion-title"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="custom-title-content">{title}</div>
          <div className="custom-accordion-button">{isActive ? "-" : "+"}</div>
        </div>
        {isActive && <div className="custom-accordion-content">{
            Array.isArray(content)?
            content.map((unit,index)=>{
                return <div key={index}>{unit}</div>
            })
            :content
        }</div>}
      </div>
    </React.Fragment>
  );
};

export default AccordionItem;
