import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setReturn } from '../../../store/returnsSlice';
import resetState from '../../../store/actions';
import axiosInstance from '../../../utility/axios-instance';
import { BsCheck } from 'react-icons/bs';

const years = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

// const days = [
// 	'Sunday',
// 	'Monday',
// 	'Tuesday',
// 	'Wednesday',
// 	'Thursday',
// 	'Friday',
// 	'Saturday',
// ];

const Return = (props) => {
	const [hover, setHover] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { returnn } = props;

	const dn = new Date();
	const createdAt = new Date(returnn.createdAt);

	let ra = createdAt
		? createdAt.getFullYear() === dn.getFullYear()
			? createdAt.getMonth() === dn.getMonth()
				? createdAt.getDate() === dn.getDate()
					? `Today at ${createdAt.toLocaleTimeString()}`
					: createdAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${createdAt.toLocaleTimeString()}`
					: years[createdAt.getMonth()] +
					  ' ' +
					  createdAt.getDate() +
					  ' at ' +
					  createdAt.toLocaleTimeString()
				: years[createdAt.getMonth()] +
				  ' ' +
				  createdAt.getDate() +
				  ' at ' +
				  createdAt.toLocaleTimeString()
			: years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
		: null;

	const sendToViniculum = async () => {
		setLoading(!loading);
		setError(null);

		axiosInstance
			.post(`/returns/updateReturn/${returnn?._id}`)
			.then((response) => {
				dispatch(setReturn(response?.data?.data));
			})
			.catch((error) => {
				setError(error?.response?.data?.message);
				setTimeout(() => {
					setError(null);
				}, 500);
				if (error?.response?.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateReturn = async () => {
		setLoading(true);
		axiosInstance
			.post(`/returns/updateReturn2/${returnn?._id}`)
			.then((response) => {
				dispatch(setReturn(response?.data?.data));
			})
			.catch((error) => {
				setError(error?.response?.data?.message);
				setTimeout(() => {
					setError(null);
				}, 500);
				if (error?.response?.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<tr
			key={returnn.id}
			className={hover ? 'bg-light' : ''}
			style={{
				cursor: 'pointer',
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			onClick={() => {
				navigate(`/orders/${returnn?.order?._id}`);
			}}
		>
			<th scope="row">{returnn?.order?.draftOrder?.order?.name}</th>
			<td>{returnn?.outputKey}</td>
			<td>{returnn?.sku}</td>
			<td>{returnn?.quantity}</td>
			<td>{ra}</td>
			<td>
				<div
					className="d-flex align-items-center"
					style={{
						gap: '4px',
						position: 'relative',
						overflow: 'visible',
						zIndex: '2',
					}}
				>
					{returnn?.status === 'Complete' ? (
						<BsCheck color="green" size={24} />
					) : (
						<div
							className="border rounded justify-content-center align-items-center"
							style={{
								height: '16px',
								width: '16px',
							}}
							onClick={(e) => {
								e.stopPropagation();

								if (returnn?.status === 'Pending') {
									sendToViniculum();
								} else {
									updateReturn();
								}
							}}
						>
							{loading ? (
								<div
									className="spinner-border"
									role="status"
									style={{
										height: '16px',
										width: '16px',
									}}
								>
									<span className="sr-only"></span>
								</div>
							) : null}
						</div>
					)}
					{returnn?.status}
					{error ? (
						<div
							className="p-1 rounded bg-danger text-light"
							style={{
								position: 'absolute',
								top: '10%',
								left: '10%',
								width: '10rem',
								zIndex: '4',
							}}
						>
							{error}
						</div>
					) : null}
				</div>
			</td>
			<td>{returnn?.type}</td>
			<td>{returnn?.reason}</td>
			<td>{returnn?.refundAmount}</td>
			<td>
				{returnn?.refund ? (
					<div
						className="d-flex align-items-center"
						style={{
							gap: '4px',
							position: 'relative',
							overflow: 'visible',
							zIndex: '2',
						}}
					>
						{returnn?.refund?.status === 'complete' ? (
							<BsCheck color="green" size={24} />
						) : null}
						{returnn?.refund?.status}
					</div>
				) : null}
			</td>
		</tr>
	);
};

export default Return;
