import { useEffect, useState, Fragment } from 'react';
import Alert from 'react-bootstrap/Alert';
import NewPagination from '../../../components/newPagination';
import axiosInstance from '../../../utility/axios-instance';
import Classes from '../orders/css/order.module.css';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import { Paper, InputAdornment, TextField, Button, } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from "@mui/icons-material/Search";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import RefundClosePopup from '../refundsNew/closeRefundPopup';
import DeliveryRider from './deliveryPopup';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Spinner from '../../../components/spinner';
import Permission from '../../auth/permissions';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import { services } from '../../../utility/checkRoleBasedPermission';
import moment from 'moment';




const Dispatch = (props) => {
    const [orders, setOrders] = useState([]);
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState({})
    const [closePopup, setClosePopup] = useState(false);
    const [dispatchId, setDispatchId] = useState(null);
    const [deliveryPopup, setDeliveryPopup] = useState(false);
    const [deliveryDetails, setDeliveryDetails] = useState(null);


    const showPopup = (e, id, amt) => {
        setDispatchId(id);
        setDeliveryDetails(amt)
        setDeliveryPopup(true);
    }

    const hidePopup = () => {
        setDispatchId(null);
        setDeliveryDetails(null)
        setDeliveryPopup(false);
    }

    const showClosePopup = (e, id) => {
        setDispatchId(id);
        setClosePopup(true);
    }

    const hideClosePopup = () => {
        setDispatchId(null);
        setClosePopup(false);
    }

    const handleRefundClose = async (e) => {
        e.preventDefault();
        const file = e?.target?.file?.files[0];
        const formData = new FormData()
        formData.append("documentAttached", file);
        await axiosInstance
            .post(`/orderDispatches/attachDocumentDispatch/${dispatchId}`, formData)
            .then((res) => {
                fetchOrders();
                hideClosePopup();
            })
            .catch((err) => {
                setError(err?.response?.data?.message);
                hideClosePopup();
            });
    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: "9px 16px"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const fetchOrders = async (type) => {
        setLoading(true);
        setError(null);

        const page = type ? type : 1;

        await axiosInstance
            .get(`/orderDispatches/orderDispatches?page=${page}&searchText=${text}`)
            .then((res) => {
                setTotalPage(res.data.data)
                setOrders(res.data.data.dispatches);
            });
        setLoading(false);
    };

    const handleDeliveryRider = async (e) => {
        e.preventDefault();
        const data = {
            "deliveryRider": deliveryDetails
        }
        await axiosInstance
            .put(`/orderDispatches/updateDispatch/${dispatchId}`, data)
            .then((res) => {
                fetchOrders();
                hidePopup();
            })
            .catch((err) => {
                setError(err?.response?.data?.message);
                hidePopup();
            });
    }

    const handleSearch = (e) => {
        setText(e.target.value);
    }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
        fetchOrders(page ? page : 1);
    }, [text]);


    return (
        <Fragment >
            <div className="d-flex justify-content-between mb-3" style={{ width: "98%", margin: '0px auto' }}>
                <div>
                    {/* <p className="mb-0">
                        <strong style={{ fontSize: '1.2rem' }}>Dispatches</strong>
                    </p> */}
                </div>
            </div>
            {error ? (
                <Alert variant="danger" onClose={() => setError(false)} dismissible>
                    <Alert.Heading>{error}</Alert.Heading>
                    <p>Try reloading!</p>
                </Alert>
            ) : null}

            <div className={Classes.Container} >
                <form style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '15px' }}>
                    <div style={{ width: '100%' }}>
                        <TextField
                            id="search"
                            type="search"
                            label="Search"
                            value={text}
                            onChange={(e) => handleSearch(e)}
                            autoComplete='off'
                            sx={{ width: '100%', "& label": { top: text ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </form>

                <div className="d-none d-sm-block" style={{ margin: '12px 0px' }}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead onSelectAllClick>
                                <TableRow>
                                    <StyledTableCell>
                                        ID
                                    </StyledTableCell>
                                    <StyledTableCell>F. NAME</StyledTableCell>
                                    <StyledTableCell>ORDER</StyledTableCell>
                                    <StyledTableCell>QUANTITY</StyledTableCell>
                                    <StyledTableCell>STATUS</StyledTableCell>
                                    <StyledTableCell>CREATED AT</StyledTableCell>
                                    <StyledTableCell>DOCUMENT</StyledTableCell>
                                    <StyledTableCell>DELIVERY RIDER</StyledTableCell>
                                    <StyledTableCell>UPDATED BY</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders?.length !== 0 && orders?.map((dispatch) => (
                                    <StyledTableRow key={dispatch?.dispatchId}>
                                        <StyledTableCell component="th" scope="row">
                                            {dispatch?.dispatchId}
                                        </StyledTableCell>
                                        <StyledTableCell>{dispatch?.fulfillmentName}</StyledTableCell>
                                        <StyledTableCell>
                                            {dispatch?.orderName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {dispatch?.quantity}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {dispatch?.status === "Closed" ? (
                                                <Chip size="small" label={dispatch?.status} />
                                            ) : (
                                                <Chip size="small" style={{ backgroundColor: '#ffea88' }} label={dispatch?.status} />
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {dispatch?.createdAt ? moment(dispatch?.createdAt).format('MMM DD, YYYY, h:mm:ss A') : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {dispatch?.documentsAttached?.length > 0 ? (
                                                <div style={{ display: 'flex', gap: "5px" }}>
                                                    {dispatch?.documentsAttached?.slice(0, 2)?.map((doc) => (
                                                        <Chip size="small" icon={<FilePresentIcon />} clickable label={decodeURIComponent(doc.substring(doc.lastIndexOf('/') + 1, doc.lastIndexOf('?')))?.substring(0, 6) + "..."} component="a" href={doc} />
                                                    ))}
                                                    {dispatch?.documentsAttached?.length > 2 ? (
                                                        <Tooltip title={
                                                            <div style={{ gap: "20px" }}>
                                                                {dispatch?.documentsAttached?.slice(2)?.map((doc, index) => (
                                                                    <Link color="inherit" href={doc}>{decodeURIComponent(doc.substring(doc.lastIndexOf('/') + 1, doc.lastIndexOf('?')))?.substring(0, 8)} , </Link>
                                                                ))}
                                                            </div>
                                                        } arrow>
                                                            <Chip size="small" label={`+ ${dispatch?.documentsAttached?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                                                        </Tooltip>
                                                    ) : null}
                                                    <Permission service={services?.dispatch} permission="update">
                                                        {dispatch?.documentsAttached?.length < 5 ? (
                                                            <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showClosePopup(e, dispatch?.dispatchId)}>
                                                                Upload
                                                            </Button>
                                                        ) : null}
                                                    </Permission>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Permission service={services?.dispatch} permission="update">
                                                        <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showClosePopup(e, dispatch?.dispatchId)}>
                                                            Upload
                                                        </Button>
                                                    </Permission>
                                                </div>
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {dispatch?.deliveryRider ? (
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p>{dispatch?.deliveryRider}</p>
                                                    <Permission service={services?.dispatch} permission="update">
                                                        <BorderColorIcon sx={{ cursor: 'pointer', color: 'gray' }} fontSize="small" onClick={(e) => showPopup(e, dispatch?.dispatchId, dispatch?.deliveryRider)} />
                                                    </Permission>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Permission service={services?.dispatch} permission="update">
                                                        <Button size="small" variant="contained" onClick={(e) => showPopup(e, dispatch?.dispatchId, dispatch?.deliveryRider)}>
                                                            Add
                                                        </Button>
                                                    </Permission>
                                                </div>
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell component="th" scope="row">
                                            {dispatch?.updatedBy}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div className="d-block d-sm-none" style={{ margin: '15px 0px' }}>
                    <div className={Classes.ordersHeader}>
                        <p className={Classes.OrderName}>
                            Dispatch
                        </p>
                        <p style={{ marginRight: '5px' }}>
                            Document
                        </p>
                    </div>

                    {orders.length > 0 && orders.map((dispatch) => (
                        <div
                            key={dispatch?.dispatchId}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                borderBottom: '1px solid lightgray',
                                fontSize: 13,
                                padding: '10px  10px',
                            }}
                        >
                            <div className={Classes.orderName}>
                                <h4
                                    style={{
                                        fontSize: 14,
                                        margin: 'auto 0px'
                                    }}
                                >
                                    {dispatch?.dispatchId}
                                </h4>
                                <h5
                                    style={{
                                        fontSize: 14,
                                        margin: 'auto 0px'
                                    }}
                                >
                                    {dispatch?.fulfillmentName}
                                </h5>
                                <h6
                                    style={{
                                        fontSize: 14,
                                        margin: 'auto 0px'
                                    }}
                                >
                                    {dispatch?.orderName}
                                </h6>
                                <div className={Classes.ResponsiveCreatedAt}>
                                    {moment(dispatch?.createdAt).format('MMM DD, YYYY, h:mm:ss A')}
                                </div>

                                <div className={Classes.ResponsiveOrderStatus}>
                                    <p style={{ marginRight: 5, fontWeight: 600, color: '#72767d' }}>
                                        Quantity:
                                    </p>
                                    <p
                                    >
                                        {dispatch?.quantity}
                                    </p>
                                </div>
                                <div>
                                    <div className={Classes.ResponsiveOrderStatus}>
                                        <p style={{ marginRight: 5, fontWeight: 600, color: '#72767d' }}>
                                            Status:
                                        </p>
                                        <p
                                            className="px-2"
                                            style={{
                                                backgroundColor:
                                                    dispatch?.status === 'Closed' ?
                                                        '#e4e5e7' :
                                                        '#ffea88',
                                                borderRadius: '1rem',
                                                width: 'fit-content',
                                            }}
                                        >
                                            {dispatch?.status}
                                        </p>
                                    </div>
                                    <div
                                        className={Classes.ResponsivePaymentStatus}
                                        style={{ marginTop: "8px" }}
                                    >
                                        <p style={{ marginRight: 5, fontWeight: 600, color: '#72767d' }}>
                                            Rider:
                                        </p>
                                        {dispatch?.deliveryRider ? (
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>{dispatch?.deliveryRider}</p>
                                                <Permission service={services?.dispatch} permission="update">
                                                    <BorderColorIcon sx={{ cursor: 'pointer', color: 'gray' }} fontSize="small" onClick={(e) => showPopup(e, dispatch?.dispatchId, dispatch?.deliveryRider)} />
                                                </Permission>
                                            </div>
                                        ) : (
                                            <div>
                                                <Permission service={services?.dispatch} permission="update">
                                                    <Button size="small" variant="contained" onClick={(e) => showPopup(e, dispatch?.dispatchId, dispatch?.deliveryRider)}>
                                                        Add
                                                    </Button>
                                                </Permission>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {dispatch?.documentsAttached?.length > 0 ? (
                                <div style={{ display: 'flex', flexDirection:'column',gap :'5px' }}>
                                    {dispatch?.documentsAttached?.slice(0, 2)?.map((doc) => (
                                        <Chip size="small" icon={<FilePresentIcon />} clickable label={decodeURIComponent(doc.substring(doc.lastIndexOf('/') + 1, doc.lastIndexOf('?')))?.substring(0, 6) + "..."} component="a" href={doc} />
                                    ))}
                                    {dispatch?.documentsAttached?.length > 2 ? (
                                        <Tooltip title={
                                            <div style={{ gap: "20px" }}>
                                                {dispatch?.documentsAttached?.slice(2)?.map((doc, index) => (
                                                    <Link color="inherit" href={doc}>{decodeURIComponent(doc.substring(doc.lastIndexOf('/') + 1, doc.lastIndexOf('?')))?.substring(0, 8)} , </Link>
                                                ))}
                                            </div>
                                        } arrow>
                                            <Chip size="small" label={`+ ${dispatch?.documentsAttached?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                                        </Tooltip>
                                    ) : null}
                                    <Permission service={services?.dispatch} permission="update">
                                        {dispatch?.documentsAttached?.length < 5 ? (
                                            <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showClosePopup(e, dispatch?.dispatchId)}>
                                                Upload
                                            </Button>
                                        ) : null}
                                    </Permission>
                                </div>
                            ) : (
                                <div>
                                    <Permission service={services?.dispatch} permission="update">
                                        <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showClosePopup(e, dispatch?.dispatchId)}>
                                            Upload
                                        </Button>
                                    </Permission>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <RefundClosePopup
                    showPopup={closePopup}
                    hidePopup={hideClosePopup}
                    handleRefundClose={handleRefundClose}
                />
                <DeliveryRider
                    showPopup={deliveryPopup}
                    hidePopup={hidePopup}
                    handleDeliveryDetails={handleDeliveryRider}
                    deliveryDetails={deliveryDetails}
                    setDeliveryDetails={setDeliveryDetails}
                />

                {orders.length > 0 ? (
                    <div>
                        <NewPagination
                            totalPage={totalPage}
                            fetchData={fetchOrders}
                        />
                    </div>
                ) : null}

            </div>
            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default Dispatch;