import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	commissions: [],
};

const commissionsSlice = createSlice({
	name: 'commissions',
	initialState: initialState,
	reducers: {
		setCommissions: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setCommissions, reset } = commissionsSlice.actions;

export default commissionsSlice.reducer;