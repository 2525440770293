import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	ids: [],
	function: null,
};

const selectedCheckBoxSlice = createSlice({
	name: 'selectedCheckBox',
	initialState: initialState,
	reducers: {
		setSelectedCheckBox: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setSelectedCheckBox, reset } = selectedCheckBoxSlice.actions;

export default selectedCheckBoxSlice.reducer;