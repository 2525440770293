import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  CircularProgress,
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  Slide,
  Divider,
  Typography,
  Switch,
} from "@mui/material";
import axiosInstance from "../../../../utility/axios-instance";
import { CustomerPreview } from "..";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MediaPreview from "../../../../components/mediaPreview";
import { styled } from "@mui/system";
import SnackBar from "../../../../components/SnackBar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const EditCustomerVideo = ({
  open,
  handleClose,
  setReRender,
  detail,
  mediaId,
}) => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [mediaInputType, setMediaInputType] = useState("URL");
  const [mediaURL, setMediaURL] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const [titleColor, setTitleColor] = useState("#000000");
  const [isActive, setIsActive] = useState(true);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(detail);
  const [mediaType, setMediaType] = useState("");
  const MediaPreviewData = useRef({ title: "", media: "" });
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const [mediaPreview, setMediaPreview] = useState("");
  const [objectURL, setObjectURL] = useState("");
  useEffect(() => {
    if (data) {
      setTitle(data?.title || "");
      setTitleColor(data?.titleColor || "");
      setLink(data?.link || "");
      setMediaURL(data?.mediaURL || "");
      setIsActive(data?.active);
      setMediaType(data?.mediaType || "");
      setMediaPreview(data?.mediaURL || "");
    }
  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    let data = {
      title,
      link,
      titleColor,
      active: isActive,
      mediaType,
    };
    if (mediaInputType === "URL") {
      data = { ...data, mediaURL };
    } else {
      formData.append("mediaFile", mediaFile);
    }
    formData.append("data", JSON.stringify(data));
    setLoading(true);
    axiosInstance
      .post(`/adminRoutes/customers/${mediaId}`, formData) //change routes
      .then((res) => {
        handleClose();
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
        setReRender((prev) => !prev);
      })
      .catch((err) => {
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFile = (e) => {
    if (!e.target.files[0].type.includes(mediaType.toLowerCase())) {
      setSnackBar({
        display: true,
        type: "error",
        message: `Invalid file type. Please upload file of type ${mediaType}`,
      });
    } else {
      setMediaFile(e.target.files[0]);
    }
  };
  const handlePreviewURL = () => {
    if (mediaInputType === "URL") {
      return mediaURL;
    } else {
      if (mediaFile) {
        const url = URL.createObjectURL(mediaFile);
        setObjectURL(url);
        return url;
      }
    }
  };
  const handlePreviewClose = () => {
    if (objectURL) {
      URL.revokeObjectURL(objectURL);
    }

    setPreviewToggle(false);
  };
  return (
    <>
      {data ? (
        <StyledDialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="upload-dialog-title"
        >
          <Grid container>
            <Grid item xs={6}>
              <DialogTitle id="upload-dialog-title">Update Media</DialogTitle>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              marginLeft={-1}
            >
              <Button
                variant="contained"
                disabled={!mediaPreview}
                onClick={() => setPreviewToggle(true)}
                startIcon={<VisibilityIcon />}
              >
                Preview{" "}
              </Button>
            </Grid>
          </Grid>
          <StyledForm onSubmit={handleSubmit}>
            <StyledDialogContent>
              <Divider
                component="div"
                role="presentation"
                style={{ marginBottom: "1rem" }}
              >
                <Typography variant="subtitle1">Edit Media Details</Typography>
              </Divider>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    required
                    label="Title"
                    type="text"
                    variant="outlined"
                    value={title}
                    autoComplete="off"
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} marginBottom={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TextField
                        margin="dense"
                        required
                        label="Title Color (Hex)"
                        type="text"
                        variant="outlined"
                        value={titleColor}
                        autoComplete="off"
                        onChange={(e) => setTitleColor(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        required
                        label="Title Color (Picker)"
                        type="color"
                        variant="outlined"
                        value={titleColor}
                        autoComplete="off"
                        onChange={(e) => setTitleColor(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <FormControl fullWidth marginBottom={2}>
                <InputLabel id="media-type-label">Select Media Type</InputLabel>
                <Select
                  labelId="media-type-label"
                  id="media-type-select"
                  value={mediaType}
                  label="Select Media Type"
                  onChange={(e) => setMediaType(e.target.value)}
                  required
                >
                  <MenuItem value="Image">Image</MenuItem>
                  <MenuItem value="Video">Video</MenuItem>
                </Select>
              </FormControl>
              {mediaType ? (
                <>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={mediaInputType}
                      onChange={(e) => setMediaInputType(e.target.value)}
                    >
                      <FormControlLabel
                        value="URL"
                        control={<Radio />}
                        label="URL"
                      />
                      <FormControlLabel
                        value="UPLOAD"
                        control={<Radio />}
                        label="File Upload"
                      />
                    </RadioGroup>
                  </FormControl>
                  {mediaInputType === "URL" ? (
                    <TextField
                      required
                      label={`${mediaType} URL`}
                      type="url"
                      value={mediaURL}
                      onChange={(e) => {
                        setMediaURL(e.target.value);
                      }}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      required
                      type="file"
                      onChange={(e) => handleFile(e)}
                      inputProps={{ accept: `${mediaType.toLowerCase()}/*` }}
                      fullWidth
                    />
                  )}
                  <FormControlLabel
                    label={`Enable ${mediaType}`}
                    control={
                      <Switch
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                      />
                    }
                    labelPlacement="start"
                  />
                </>
              ) : null}
            </StyledDialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={6} display="flex" justifyContent="center">
                  {/* <Button
                    variant="contained"
                    onClick={() => setPreviewToggle(true)}
                  >
                    Preview
                  </Button> */}
                </Grid>
              </Grid>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </DialogActions>
          </StyledForm>
        </StyledDialog>
      ) : null}
      {/* {previewToggle && (
        <CustomerPreview
          media={MediaPreviewData.current}
          previewState={previewToggle}
          handlePreviewState={setPreviewToggle}
        />
      )} */}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type={mediaType === "Image"?"img":"video"}
          handleUrl={handlePreviewURL}
        />
      )}
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default EditCustomerVideo;
