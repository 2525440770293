import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  page: 1,
  maxPage: 1,
  bannerDetails: [],
};

const bannerSettingsSlice = createSlice({
  name: "bannerImages",
  initialState: initialState,
  reducers: {
    setBanners: (state, action) => action.payload,
    reset: (state, action) => initialState,
  },
});

export const { setBanners, reset } = bannerSettingsSlice.actions;

export default bannerSettingsSlice.reducer;
