import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedPushNotifications: [],
};

const pushNotificationsSlice = createSlice({
	name: 'pushNotifications',
	initialState: initialState,
	reducers: {
		setPushNotifications: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setPushNotifications, reset } = pushNotificationsSlice.actions;

export default pushNotificationsSlice.reducer;