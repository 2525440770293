import { useState, Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  FormControl, Radio, RadioGroup
} from "@mui/material";

import axiosInstance from "../../../utility/axios-instance";

import Classes from './createDynamicBanner.module.scss';

import Spinner from '../../../components/spinner';

const DynamicBanner = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading1, setLoading1] = useState(false);
  const [data, setData] = useState(null);

  const customerRoles = [
    'architect',
    'interior',
    'end_user',
    'retailer',
  ];

  const [locationCodesSelect, setLocationCodesSelect] = useState(['']);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
    const [titleColor, setTitleColor] = useState('#000000');
  const [link, setLink] = useState('');
  const [imageType, setImageType] = useState('url');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [active, setActive] = useState(true);
  const [forLoggedIn, setForLoggedIn] = useState(true);
  const [roles, setRoles] = useState([]);
  const [locationCodes, setLocationCodes] = useState([]);

  useEffect(() => {
    if (data) {
        setTitle(data?.title || '');
        setTitleColor(data?.titleColor || '');
        setLink(data?.link || '');
        setImage(data?.image || '');
        setActive(!!data?.active);
        setForLoggedIn(!!data?.forLoggedIn);
        setRoles(data?.forRoles || []);
        setLocationCodes(data?.forLocationCodes || []);
    }
  }, [data]);

  const fetchData = async () => {
    setError(null);
    setLoading1(true);

    axiosInstance.get(`/adminRoutes/dynamicBanners/${id}`).then(res => {
      setData(res.data.data);
    }).catch(err => {setError(err.response.data.message)}).finally(() => {setLoading1(false)});
  }

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (e) => {
    e.preventDefault();

    // const data = {
    //   title,
    //   link,
    //   image,
    //   active,
    //   forLoggedIn,
    //   forRoles: roles,
    //   forLocationCodes: locationCodes
    // }

    const data = new FormData();

    data.append('title', title);
    data.append('titleColor', titleColor);
    data.append('link', link);
    data.append('active', active);
    data.append('forLoggedIn', forLoggedIn);
    data.append('forRoles', JSON.stringify(roles));
    data.append('forLocationCodes', JSON.stringify(locationCodes));

    if (imageType === 'url') {
      data.append('image', image);
    } else {
      data.append('imageFile', imageFile);
    }

    setError(null);
    setLoading(true);

    axiosInstance.post(`/adminRoutes/dynamicBanners/${id}`, data).then(res => {
      navigate(-1)
    }).catch(err => {setError(err.response.data.message)}).finally(() => {setLoading(false)});
  }

  const fetchLocationCodes = async () => {
    axiosInstance.get(`location/fetchAllLocations`, {
      params: {
        sort: {"field":"createdAt","sort":"desc"},
        limit: 1000
      }
    }).then(res => {
      // console.log(res.data.data.fetchedLocations);
      setLocationCodesSelect([...res.data.data.fetchedLocations]);
    });
  }

  useEffect(() => {
    fetchLocationCodes();
  }, []);

  useEffect(() => {
    if (!forLoggedIn) {
      setRoles([]);
    }
  }, [forLoggedIn]);

  const [loading2, setLoading2] = useState(false);
  const deleteDB = async () => {
    setLoading2(true);
    setError(null);

    axiosInstance.delete(`/adminRoutes/dynamicBanners/${id}`).then(res => {
      navigate(-1)
    }).catch(err => {setError(err.response.data.message)}).finally(() => {setLoading2(false)});
  }

  return (
    <Fragment>
      {error ? (
        <Alert severity='error' className='' onClose={() => {
          setError(null);
        }}>
          <AlertTitle>{error}</AlertTitle>
          Try Reloading !
        </Alert>
      ) : null}

      {data ? <form onSubmit={submit} className={`mt-4 ${Classes.Grid}`}>
        <TextField
              id='title'
              required
              name='title'
              type='text'
              label='Title'
              placeholder='Enter Title'
              autoComplete='off'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <div style={{
          display: 'flex',
          alignItems: 'start',
        }}>
          <TextField
              style={{
                flexGrow: '1'
              }}
              id='titleColor'
              required
              name='titleColor'
              type='text'
              label='Title Color'
              placeholder='Enter Title Color'
              autoComplete='off'
              value={titleColor}
              onChange={(e) => setTitleColor(e.target.value)}
              helperText='Enter hex (ex. #000000) or rgba (ex. rgba(0, 0, 0, 1)) color code.'
            />
          <div style={{
            height: '56px',
            aspectRatio: '1 / 1',
            backgroundColor: titleColor,
            border: '1px solid grey',
            borderRadius: '4px'
          }}></div>
        </div>

        <TextField
              id='link'
              required
              name='link'
              type='text'
              label='Link'
              placeholder='Enter Link'
              autoComplete='off'
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />

            <div></div>

            <div>
          <FormControl>
            <RadioGroup
              row
              name="image-radio-buttons-group"
              onChange={(e, v) => setImageType(v)}
            >
              <FormControlLabel value="url" control={<Radio checked={imageType === 'url'} />} label="URL" />
              <FormControlLabel value="image-upload" control={<Radio checked={imageType === 'image-upload'} />} label="Image Upload" />
            </RadioGroup>
          </FormControl>

          {imageType === 'url' ? <TextField
              style={{
                display: 'block'
              }}
              id='image'
              required
              name='image'
              type='text'
              label='Image URL'
              fullWidth
              placeholder='Enter Image Url'
              autoComplete='off'
              value={image}
              onChange={(e) => setImage(e.target.value)}
            /> : <TextField id='image'
              required
              name='image'
              type='file'
              // label='Image Upload'
              inputProps={{
                'accept': "image/png, image/jpeg, image/jpg, image/webp"
              }}
              fullWidth
              autoComplete='off'
              onChange={(e) => setImageFile(e.target.files[0])}
              helperText='Aspect ratio of image must be 6 / 3.' />}
        </div>

        <div></div>

          <FormControlLabel control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />} label="Active" />

          <FormControlLabel control={<Switch checked={forLoggedIn} onChange={(e) => setForLoggedIn(e.target.checked)} />} label="For Logged In Users" />

        {/* <FormControl>
          <InputLabel id="for-roles">Roles</InputLabel>
          <Select
          id='roles'
          name='roles'
          label='Roles'
          multiple
          value={roles}
          onChange={(e) => setRoles(e.target.value)}
          >
            {customerRoles.map(r => <MenuItem key={r ?? 'Select Roles'} disabled={!r} value={r}>{r ? r.toUpperCase() : 'Select Roles'}</MenuItem>)}
          </Select>
        </FormControl> */}

        <Autocomplete id="roles" multiple value={roles}
          onChange={(e, v) => setRoles(v)}
          options={customerRoles}
          disabled={!forLoggedIn}
          renderInput={(params) => <TextField {...params} label="Roles" />} />

        {/* <FormControl>
          <InputLabel id="for-location-codes">Location Codes</InputLabel>
          <Select
          id='location-codes'
          name='location-codes'
          label='Location Codes'
          multiple
          value={locationCodes}
          onChange={(e) => setLocationCodes(e.target.value)}
          >
            {locationCodesSelect.map(lc => <MenuItem key={lc ? lc.locationCode : 'Select Location Codes'} disabled={!lc} value={lc.locationCode}>{lc ? lc.locationCode.toUpperCase() : 'Select Location Codes'}</MenuItem>)}
          </Select>
        </FormControl> */}

        <Autocomplete id="location-codes" multiple value={locationCodes}
          onChange={(e, v) => setLocationCodes(v)}
          options={locationCodesSelect.map(e => {
            return e.locationCode ?? '';
          })}
          renderInput={(params) => <TextField {...params} label="Location Codes" />} />

        <div>
          <Button type="submit" variant="contained" sx={{
            backgroundColor: '#ff6200',
            ":hover": {
              backgroundColor: '#ff6200',
            }
          }} style={{
            color: 'white',
          }}
          disabled={loading}
          >{loading ? <CircularProgress size={24} style={{color: 'white'}} /> : 'Update'}</Button>
        </div>

        <div>
          <Button type="button" variant="contained" sx={{
            backgroundColor: '#ff1111',
            ":hover": {
              backgroundColor: '#ff1111',
            }
          }} style={{
            color: 'white',
          }}
          disabled={loading2}
          onClick={deleteDB}
          >{loading2 ? <CircularProgress size={24} style={{color: 'white'}} /> : 'Delete'}</Button>
        </div>
      </form> : null}

      {loading1 ? (
        <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}>
          <Spinner />
        </div>
      ) : null}
    </Fragment>
    
  )
}

export default DynamicBanner