import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    DialogActions,
    DialogContentText,
} from '@mui/material';

const OnHoldPopup = ({ isOpen, onClose, products, order, setReRender, setSnackBar }) => {

    const handleReturn = async () => {
        let data = products.map((product) => ({
            oLineItemId: product.oLineItemId,
            quantity: product?.quantity,
            fulfillmentName: product.fulfillmentName,
            reason: "Cancel",
            description: '',
            type: 'Refund',
        }))

        await axiosInstance
            .post(`/pgOrder/admin/returnReqLineItemWise/${order}`, data)
            .then((response) => {
                setReRender((prev) => !prev);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: "Return Request created Successfully", type: "success" }
                });
            })
            .catch((error) => {
                setReRender((prev) => !prev);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })

        onClose();
    };


    return (

        <Dialog open={isOpen} onClose={onClose} fullWidth={true}
        >
            <DialogTitle>On Hold Order</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Return request will be generated for the following products:
            </DialogContentText>
                {products?.map((product) => (
                    <div
                        key={product.oLineItemId}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '15px 0px',
                            justifyContent: 'space-between',
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <img
                                src={product?.mainImageUrl ? product?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                alt={product?.productTitle}
                                width="50"
                                height="50"
                                style={{ marginRight: '10px', borderRadius: '5px', border: '0.5px solid gray' }}
                            />
                            <div>
                                <p style={{ fontWeight: 600 }}>{product.sku}</p>
                                <p style={{ fontSize: '12px' }}>Quantity: {product?.quantity}</p>
                            </div>
                        </div>
                    </div>
                ))}

            </DialogContent>
            <DialogActions>
                {products.length > 0 && (
                    <Button variant="contained" color="primary" onClick={handleReturn}>
                        Confirm
                    </Button>
                )}
                <Button variant="outlined" color="primary" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>


        </Dialog>
    );
};

export default OnHoldPopup;
