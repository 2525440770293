import { Fragment, useState } from 'react';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import ConfirmPopup from '../customers/confirmPopup';
import { useSelector } from 'react-redux';
import Table from "../../../components/table/table";
import Button from '@mui/material/Button';
import VisitCustomerModal from './selectVisitCustomerModal';
import EndVisitModal from './endVisitModal';
import CancelIcon from '@mui/icons-material/Cancel';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CancelMeetingModal from './cancelMeetingModal';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadCSV } from "../../../utility/downloadCSV";
import Spinner from '../../../components/spinner';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment';
import { setMyTasks } from '../../../store/myTasksSlice';

const FieldSales = (props) => {
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);
    const [storeCustomerId, setCustomerId] = useState([]);
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [salesUser,setSalesUser] = useState('');
    const [customerInfo, setCustomerInfo] = useState([]);
    const [modeOfCommunication,setModeOfCommunication] = useState('');
    const [showVisitCustomerModal,setVisitCustomerModal] = useState(false);

    // endVisit Modal
    const [endVisitModal,setEndVisitModal] = useState(false);
    const [selectedRatings,setSelectedRatings] = useState('');
    const [visitRemarks,setVisitRemarks] = useState('');
    const [endVisitedCustomer,setEndVisitedCustomer] = useState('');

    // CancelMeeting Modal
    const [cancelMeetingModal,setCancelMeetingModal] = useState(false);
    const [cancelVisitReason,setCancelVisitReason] = useState('');
    const [cancelVisitCustomer,setCancelVisitCustomer] = useState('');
    const [reRender,setReRender] = useState(false);
    const [previousVisitCustomer,setPreviousVisitCustomer] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const auth = useSelector((state) => state.auth);
    const myTasks = useSelector((state) => state.myTasks);
    const visitCustomerModal = () => {
        setVisitCustomerModal(true);
    };

    const hideVisitCustomerModal = () => {
        setVisitCustomerModal(false);
    };

    const getEndVisitModal = (customer) => {
        setEndVisitedCustomer(customer);
        setEndVisitModal(true);
    }

    const hideEndVisitModal = () => {
        setSelectedRatings('');
        setEndVisitModal(false);
    }

    const getCancelMeetingModal = (event,customer) => {
        setCancelVisitCustomer(customer);
        setCancelMeetingModal(true);
    }

    const hideCancelMeetingModal = () => {
        setCancelMeetingModal(false);
    }

    const handleDeleteCustomer = () => {
        axiosInstance
            .post('/user/admin/deleteCustomersInBulk/', {
                customersIds: storeCustomerId
            })
            .then((res) => {
                const arr = []
                setCustomerId(arr);
                document.getElementById('deleteBtn').style.backgroundColor = 'transparent';
                document.getElementById('deleteBtn').style.color = '';
                document.getElementById('parentBox').checked = false;
                // fetchCustomers();
            })
    }

    const hideConfirmPopup = (event) => {
        if (event.target.innerText === 'Yes') {
            if (storeCustomerId.length !== 0) {
                handleDeleteCustomer();
            }
        }
        setConfirmShowPopup(false)
    }

    const handleStartVisit = async (fieldSaleId,user) => {
        await axiosInstance
            .get(`/fieldSalesVisit/previousVisitCompleted/${user}`)
            .then((res) => {
                if(!res?.data?.data) {
                    console.log('hello')
                    axiosInstance
                    .post(`/fieldSalesVisit/updateFieldVisit/${fieldSaleId}`,{
                        startTime: Date.now()
                    })
                    .then((res) => {
                        setReRender(!reRender);
                    });
                } else {
                    setError(true);
                    setPreviousVisitCustomer(res.data.data[0]?.user?.displayName);
                    setTimeout(() => {
                        setPreviousVisitCustomer('');
                        setError(false);
                    }, 5000);
                }
            })
    }

    const handleEndVisit = async (customer) => {
        await axiosInstance
            .post(`/fieldSalesVisit/updateFieldVisit/${customer}`,{
                endTime: Date.now()
            })
            .then((res) => {
                setReRender(!reRender);
            });
    }

    const handleExport = (event) => {
        setLoading(true);
        event.preventDefault();

        axiosInstance
            .get('/exports/myTasks')
            .then((result) => {
                setLoading(false);
                const arrayOfRowObjects = result?.data?.data;
                downloadCSV(arrayOfRowObjects, `Tasks export - ${new Date()}`);
                const successMessage = "Tasks Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    const columns = [
        {
            field: 'displayName',
            headerName: 'Customer Name',
            flex: 1.8,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'users',
            columnName: 'display_name',
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: false
        },
        {
            field: 'customerRole',
            headerName: 'Role',
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'users',
            columnName: 'customer_role',
            renderCell: (params) => (
                <div>{params?.row?.customerRole ? params?.row?.customerRole : 'N/A'}</div>
            )
        },
        {
            field: 'modeOfCommunication',
            headerName: 'Communication',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'FieldSale',
            columnName: 'mode_of_communication'
        },
        {
            field: 'salesUser',
            headerName: 'Sales User',
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'admins',
            columnName: 'name'
        },
        {
            field: 'startTime',
            headerName: 'Mark Start',
            flex: 0.9,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => (
                <div>
                    {params?.row?.startTime ? moment(parseFloat(params?.row?.startTime)).format('MMM DD, YYYY h:mm:ss A') : (
                        !params?.row?.cancellationReason ? (
                            <Button 
                                variant="contained" 
                                sx={{textTransform: "capitalize",padding:'4px 12px',fontSize:'13px'}} 
                                onClick={() => handleStartVisit(params?.row?.id,params?.row?.adminId)}
                            >
                                {params?.row?.modeOfCommunication && params?.row?.modeOfCommunication === 'Field Visit' ?  
                                "Start Visit" : "Start Call"}
                            </Button>
                        ) : (
                            "N/A"
                        )
                    )}
                </div>
            )
        },
        {
            field: 'endTime',
            headerName: 'Mark End',
            flex: 0.9,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => (
                <div>
                    {params?.row?.endTime ? moment(parseFloat(params?.row?.endTime)).format('MMM DD, YYYY, h:mm:ss A') :  
                    params?.row?.startTime ? 
                        <Button 
                            variant="contained" 
                            sx={{textTransform: "capitalize",padding:'4px 12px',fontSize:'13px'}}
                            onClick={()=>  getEndVisitModal(params?.row?.id)}
                        >
                            {params?.row?.modeOfCommunication && params?.row?.modeOfCommunication === 'Field Visit'?  
                            "End Visit" : "End Call"}
                        </Button> : "N/A"
                    }
                </div>
            )
        },
        {
            field: 'duration',
            headerName: 'Duration',
            flex: 0.8,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.duration ? params?.row?.duration : "N/A" }</div>
            )
        },
        {
            field: 'meeting',
            headerName: 'Meeting',
            headerClassName: 'super-app-theme--header',
            flex: 0.6,
            align:'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <div>
                    {params?.row?.startTime && !params?.row?.endTime ? (
                        <PersonPinIcon sx={{color:'orange',fontSize:28}}/> 
                    ): params?.row?.endTime ? (
                            <HowToRegIcon sx={{color:'green',fontSize:28}}/> 
                        ) : (
                            params?.row?.cancellationReason ? (
                                <p className={Classes.meetingCancelled}>Cancelled</p>
                            ) : (
                                <div>
                                    <CancelIcon 
                                        sx={{color:'#F34336',cursor:'pointer',fontSize:22}} 
                                        onClick={(event) => getCancelMeetingModal(event,params?.row?.id)}
                                    />
                                </div>
                            )
                    )}
                </div>
            ),
        },
    ];

	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2"  data-file-upload-alert role="alert" 
                    style={{ "display": "none" }} id='fileDownloading'
                >
                    Sheet is exporting...
                </div>
                
                <div className="d-flex mb-2" style={{justifyContent:'flex-end',marginTop:-27}}>
                    <div style={{ display:'flex',marginRight:10 }}>
                        <Button
                            id='exportbtn'
                            variant="contained"
                            style={{backgroundColor:'#26b278'}}
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExport}
                        >
                            Export
                        </Button>
                    </div>

                    {(auth?.user?.role === 'super-admin' || auth?.user?.role === 'admin' || 
                    auth?.user?.role === 'salesManager') && (
                        <Button 
                            variant="contained" 
                            startIcon={<AddCircleIcon />} 
                            sx={{textTransform: "capitalize"}}
                            onClick={visitCustomerModal}
                        >
                            Add Task
                        </Button>
                    )}
                </div>

                {error && (
                    <Alert severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        Please complete previous visit — <strong>{previousVisitCustomer}!</strong>
                    </Alert>
                )}

                <div className={Classes.Container}>
                    <Table
                        columns={columns}
                        dataPosition={"fieldVisit"}
                        endpoint={`/fieldSalesVisit`}
                        rowId={'id'}
                        checkBoxSelection={false}
                        version="version-1"
                        reRender={reRender}
                        service={'fieldSales'}
                        tabName={'My Tasks'}
                        setData={setMyTasks}
                        data={myTasks}
                        mobileResponsive={true}
                        exportEndpoint={'/exports/fieldSalesVisit'}
                        exportFileName={`Visit - ${new Date()}`}
                    />

                    <div>
                        <ConfirmPopup
                            showConfirmPopup={showConfirmPopup}
                            hideConfirmPopup={hideConfirmPopup}
                            storeCustomerId={storeCustomerId}
                        />
                    </div>
                </div>
            </div>

            <div className={endVisitModal ? Classes.UpdateSalesDialog : undefined}>
                <EndVisitModal 
                    endVisitModal={endVisitModal}
                    hideEndVisitModal={hideEndVisitModal}
                    handleEndVisit={handleEndVisit}
                    selectedRatings={selectedRatings}
                    setSelectedRatings={setSelectedRatings}
                    visitRemarks={visitRemarks}
                    setVisitRemarks={setVisitRemarks}
                    endVisitedCustomer={endVisitedCustomer}
                    reRender={reRender}
                    setReRender={setReRender}
                />
            </div>

            <div className={cancelMeetingModal ? Classes.UpdateSalesDialog : undefined}>
                <CancelMeetingModal 
                    cancelMeetingModal={cancelMeetingModal}
                    hideCancelMeetingModal={hideCancelMeetingModal}
                    setCancelVisitReason={setCancelVisitReason}
                    cancelVisitReason={cancelVisitReason}
                    reRender={reRender}
                    setReRender={setReRender}
                    cancelVisitCustomer={cancelVisitCustomer}
                />
            </div>

            <div className={showVisitCustomerModal ? Classes.UpdateSalesDialog : undefined}>
                <VisitCustomerModal
                    showVisitCustomerModal={showVisitCustomerModal}
                    hideVisitCustomerModal={hideVisitCustomerModal}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    modeOfCommunication={modeOfCommunication}
                    setModeOfCommunication={setModeOfCommunication}
                    reRender={reRender}
                    setReRender={setReRender}
                />
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            {loading && <Spinner />}
        </Fragment>
    );
};

export default FieldSales;