import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import { Fragment } from 'react';
import { Autocomplete, IconButton, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import ProductCards from './productCards';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#243750',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CustomizedTables = (props) => {
    const { endpoint, dataPosition, queryParams, locationCode, selectedProductsRef } = props;
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })
    const [responseData, setResponseData] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const isFirstRender = React.useRef(true);
    const [loading, setLoading] = React.useState(false);
    const [isProductsVisible, setIsProductsVisible] = React.useState(false);
    const [productsCardData, setProductsCardData] = React.useState({});
    const [columns, setColumns] = React.useState([]);
    const [filters, setFilters] = React.useState([])
    const [selectedProducts, setSelectedProducts] = React.useState({});
    const [optionalFilters, setOptionalFilters] = React.useState({
        brand_name: '',
        finish: '',
        material_type: '',
        size: '',
        load_capacity: '',
        weight: '',
    });
    const [optionalFilterRows, setOptionalFilterRows] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [loadingMoreProducts, setLoadingMoreProducts] = React.useState({});

    const fetchData = async () => {
        const params = queryParams || {};
        try {
            const res = await axiosInstance.get(`${endpoint}`, { params });
            const responseData = res?.data?.data;
            const data = responseData?.[dataPosition];
            setResponseData(data);
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
            });
        } finally {
            setLoading(false);
        }
    }

    const fetchProducts = async (filter, filterId, page) => {
        try {
            setLoadingMoreProducts((prevLoadingMoreProducts) => {
                return { ...prevLoadingMoreProducts, [filterId]: true };
            });
            const tempOptionalFilters = JSON.parse(JSON.stringify(optionalFilters));
            Object.keys(tempOptionalFilters).forEach((key) => (optionalFilters[key] == null || optionalFilters[key] == "") && delete tempOptionalFilters[key]);
            const params = {
                filter: filter || '{}',
                locationCode: locationCode?.value || '',
                searchStr: queryParams?.searchTerm,
                filterId: filterId,
                optionalFilter: JSON.stringify(tempOptionalFilters) || '{}',
                page: page || 1,
                limit: 10,
            };
            const res = await axiosInstance.get(`/smartProductSearch/fetchProducts`, { params });
            const responseData = res?.data?.data;
            const data = responseData?.["fetchedProducts"];
            const productsData = [];
            for (let product of data) {
                const productData = {
                    id: product?.id,
                    productName: product?.productName,
                    image: product?.image,
                    price: product?.price,
                    sku: product?.sku,
                    filterId: filterId,
                    availableQty: product?.availableQty,
                    variantType: product?.variantType,
                    variantValue: product?.variantValue,
                    totalPages: responseData?.["totalPages"],
                    totalRows: responseData?.["totalRows"],
                    handleProductSelect: () => {
                        setSelectedProducts((prevSelectedProducts) => {
                            selectedProductsRef.current = {
                                ...prevSelectedProducts, [filterId]: {
                                    id: product?.id,
                                    sku: product?.sku,
                                    variantId: product?.variantId,
                                    quantity: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    PCS: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    locationCode: product?.locationCode || '',
                                }
                            };
                            return {
                                ...prevSelectedProducts, [filterId]: {
                                    id: product?.id,
                                    sku: product?.sku,
                                    variantId: product?.variantId,
                                    quantity: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    PCS: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    locationCode: product?.locationCode || '',
                                }
                            };
                        });

                    },
                }
                productsData.push(productData);
            }
            setProductsCardData((prevProductsCardData) => {
                const previousProductsData = prevProductsCardData?.[filterId] || [];
                return { ...prevProductsCardData, [filterId]: [...previousProductsData, ...productsData] };
            });
            setLoadingMoreProducts((prevLoadingMoreProducts) => {
                return { ...prevLoadingMoreProducts, [filterId]: false };
            });
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            console.log(error, "errorMessage")
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
            });
        }
    }

    const fetchProductsByCombo = async (filter, filterId, page) => {
        try {
            setLoadingMoreProducts((prevLoadingMoreProducts) => {
                return { ...prevLoadingMoreProducts, [filterId]: true };
            });
            const tempOptionalFilters = JSON.parse(JSON.stringify(optionalFilters));
            Object.keys(tempOptionalFilters).forEach((key) => (optionalFilters[key] == null || optionalFilters[key] == "") && delete tempOptionalFilters[key]);
            const params = {
                filter: filter || '{}',
                locationCode: locationCode?.value || '',
                searchStr: queryParams?.searchTerm,
                filterId: filterId,
                optionalFilter: JSON.stringify(tempOptionalFilters) || '{}',
                page: page || 1,
                limit: 10,
                combo: true,
            };
            const res = await axiosInstance.get(`/smartProductSearch/fetchProducts`, { params });
            const responseData = res?.data?.data;
            const data = responseData?.["fetchedProducts"];
            const productsData = [];
            for (let product of data) {
                const productData = {
                    ...product,
                    filterId: filterId,
                    combo: true,
                    totalPages: responseData?.["totalPages"],
                    totalRows: responseData?.["totalRows"],
                    handleProductSelect: (id, sku, variantId, locationCode) => {
                        setSelectedProducts((prevSelectedProducts) => {
                            selectedProductsRef.current = {
                                ...prevSelectedProducts, [filterId]: {
                                    id: id,
                                    sku: sku,
                                    variantId: variantId,
                                    quantity: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    PCS: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    locationCode: locationCode || '',
                                }
                            };
                            return {
                                ...prevSelectedProducts, [filterId]: {
                                    id: id,
                                    sku: sku,
                                    variantId: variantId,
                                    quantity: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    PCS: +filters.find((filter) => { return filter?.rowId === filterId })?.["quantity"] || 1,
                                    locationCode: locationCode || '',
                                }
                            };
                        });

                    },
                }
                productsData.push(productData);
            }
            setProductsCardData((prevProductsCardData) => {
                const previousProductsData = prevProductsCardData?.[filterId] || [];
                return { ...prevProductsCardData, [filterId]: [...previousProductsData, ...productsData] };
            });
            setLoadingMoreProducts((prevLoadingMoreProducts) => {
                return { ...prevLoadingMoreProducts, [filterId]: false };
            });
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            console.log(error, "errorMessage")
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
            });
        }
    }

    React.useEffect(() => {
        setProductsCardData({});
        setSelectedProducts({});
        setLoadingMoreProducts({});
    }, [filters])

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            fetchData(); // Fetch data immediately without delay
            return;
        }
        setRows([]);
        setFilters([]);
        setSelectedProducts({});
        setProductsCardData({});
        selectedProductsRef.current = {};
        const delay = 550;
        const debounce = setTimeout(() => {
            fetchData();
        }, delay);
        return () => clearTimeout(debounce);
    }, [queryParams])

    // set columns
    React.useEffect(() => {
        if (responseData?.length > 0) {
            const columns = [];
            for (let column of responseData) {
                columns.push(column?.variant_type);
            }
            setColumns(columns);
        }
    }, [responseData])


    const handleAddRow = () => {
        const newRow = {};
        const filter = {};
        for (let column of columns) {
            newRow["rowId"] = rows?.length + 1;
            newRow[column] = responseData?.find((row) => { return row?.variant_type === column })?.variant_values;
            const defaultVariantValue = ""
            filter[column] = defaultVariantValue;
            filter["quantity"] = null;
            filter["page"] = 1;
            filter["totalPages"] = 1;
            filter["rowId"] = newRow["rowId"];
        }
        setFilters((prevFilters) => {
            return [...prevFilters, filter];
        });
        setRows((prevRows) => {
            return [...prevRows, newRow];
        });
    }

    const handleRemoveRow = (index) => {
        setRows((prevRow) => {
            return prevRow.filter((row, rowIndex) => {
                return rowIndex !== index;
            });
        });
        setFilters((prevFilters) => {
            return prevFilters.filter((filter, filterIndex) => {
                return filterIndex !== index;
            });
        });
    }

    const handleFormSubmit = (event) => {
        setIsProductsVisible(true);
        setProductsCardData({});
        const tempFilters = JSON.parse(JSON.stringify(filters));
        const stringifiedFilters = tempFilters?.map((filter) => {
            Object.keys(filter)
                .forEach((key) => (
                    filter[key] == null ||
                    filter[key] == "" ||
                    key == "quantity" ||
                    key == "page" ||
                    key == "totalPages"
                )
                    && delete filter[key]);
            return JSON.stringify(filter);
        })
        stringifiedFilters?.length > 0 ? stringifiedFilters?.map((filter) => {
            const modifiedFilter = JSON.parse(filter);
            const filterId = modifiedFilter.rowId;
            delete modifiedFilter.rowId;
            const modifiedFilterString = JSON.stringify(modifiedFilter);
            fetchProducts(modifiedFilterString, filterId, 1);
        }) : fetchProducts('{}', 1, 1);
    };

    const handleSearchByCombo = (event) => {
        setIsProductsVisible(true);
        setProductsCardData({});
        const tempFilters = JSON.parse(JSON.stringify(filters));
        const stringifiedFilters = tempFilters?.map((filter) => {
            Object.keys(filter)
                .forEach((key) => (
                    filter[key] == null ||
                    filter[key] == "" ||
                    key == "quantity" ||
                    key == "page" ||
                    key == "totalPages"
                )
                    && delete filter[key]);
            return JSON.stringify(filter);
        })
        stringifiedFilters?.length > 0 ? stringifiedFilters?.map((filter) => {
            const modifiedFilter = JSON.parse(filter);
            const filterId = modifiedFilter.rowId;
            delete modifiedFilter.rowId;
            const modifiedFilterString = JSON.stringify(modifiedFilter);
            fetchProductsByCombo(modifiedFilterString, filterId, 1);
        }) : fetchProductsByCombo('{}', 1, 1);
    };

    React.useEffect(() => {
        if (isProductsVisible) {
            setIsProductsVisible(false);
        }
    }, [rows, queryParams])

    React.useEffect(() => {
        setOptionalFilterRows([
            {
                title: 'Brand Name',
                value: optionalFilters?.["brand_name"],
                field: 'brand_name',
            },
            {
                title: 'Finish',
                value: optionalFilters?.["finish"],
                field: 'finish',
            },
            {
                title: 'Material Type',
                value: optionalFilters?.["material_type"],
                field: 'material_type',
            },
            {
                title: 'Size',
                value: optionalFilters?.["size"],
                field: 'size',
            },
            {
                title: 'Load Capacity',
                value: optionalFilters?.["load_capacity"],
                field: 'load_capacity',
            },
            {
                title: 'Weight',
                value: optionalFilters?.["weight"],
                field: 'weight',
            },
        ])
    }, [])

    const carouselsSkeleton = () => {
        return (
            filters.map((filter, index) => {
                return (
                    <React.Fragment key={index}>
                        {loadingMoreProducts?.[filter?.rowId] && <div style={{ marginTop: '2em', marginBottom: '2em' }}>
                            <Typography variant="h6" component="h6" style={{ marginLeft: '0.8em', display: 'flex', alignItems: 'center' }}>
                                Select Product - Filter {filter?.rowId}
                            </Typography>
                            <div style={{ position: 'relative', overflow: 'hidden' }} >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflow: 'scroll',
                                        scrollbarWidth: 'none',
                                        width: '100%',
                                        scrollBehavior: 'smooth',
                                        overflowX: 'hidden',
                                        overflowY: 'hidden',
                                    }}
                                >
                                    <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: '464px', }}>
                                        <Skeleton variant="rectangular" height='464px' animation='pulse' />
                                    </div>
                                    <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: '464px', }}>
                                        <Skeleton variant="rectangular" height='464px' animation='pulse' />
                                    </div>
                                    <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: '464px', }}>
                                        <Skeleton variant="rectangular" height='464px' animation='pulse' />
                                    </div>
                                    <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: '464px', }}>
                                        <Skeleton variant="rectangular" height='464px' animation='pulse' />
                                    </div>
                                    <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: '464px', }}>
                                        <Skeleton variant="rectangular" height='464px' animation='pulse' />
                                    </div>
                                    <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: '464px', }}>
                                        <Skeleton variant="rectangular" height='464px' animation='pulse' />
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </React.Fragment>
                )
            })
        )
    }
    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {loading && <Skeleton variant="rectangular" width={'100%'} height={200} animation={"wave"} />}
            {rows?.length > 0 && <div style={{
                padding: '6px 31px',
                border: '2px solid #243750',
                width: 'fit-content',
                borderRadius: '9px 30px 0px 0px',
                background: '#243750',
                color: 'white',
                borderBottomColor: 'white',
            }}>Filters</div>}
            {rows?.length > 0 && <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Filter Id</StyledTableCell>
                            {
                                columns && columns?.map((variant_type, index) => {
                                    return (
                                        <StyledTableCell align="left" key={index}>{variant_type}</StyledTableCell>
                                    )
                                })
                            }
                            <StyledTableCell>Qty</StyledTableCell>
                            <StyledTableCell>Remove Filter</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <StyledTableRow key={row?.id || index}>
                                <StyledTableCell align="left">
                                    {row?.rowId}
                                </StyledTableCell>
                                {
                                    columns && columns?.map((variant_type, index) => {
                                        return (
                                            <StyledTableCell align="left" key={index}>
                                                <Autocomplete
                                                    size='small'
                                                    disabled={row?.[variant_type]?.length > 0 ? false : true}
                                                    id="Variant Value"
                                                    required
                                                    options={row?.[variant_type] || []}
                                                    onChange={(event, newValue) => {
                                                        setFilters((prevFilters) => {
                                                            return prevFilters.map((filter) => {
                                                                if (filter?.rowId === row?.rowId) {
                                                                    filter[variant_type] = newValue;
                                                                }
                                                                return filter;
                                                            });
                                                        });
                                                    }}
                                                    value={filters.find((filter) => { return filter?.rowId === row?.rowId })?.[variant_type] || ''}
                                                    renderInput={(params) => <TextField required={true} {...params} label="Variant Value" />}
                                                />
                                            </StyledTableCell>
                                        )
                                    })
                                }
                                <StyledTableCell align="left">
                                    <TextField
                                        id="quantity"
                                        type="number"
                                        label="Quantity"
                                        value={filters.find((filter) => { return filter?.rowId === row?.rowId })?.["quantity"] || ""}
                                        onChange={(e) => {
                                            setFilters((prevFilters) => {
                                                return prevFilters.map((filter) => {
                                                    if (filter?.rowId === row?.rowId) {
                                                        filter["quantity"] = e?.target?.value;
                                                    }
                                                    return filter;
                                                });
                                            });
                                        }}
                                        autoComplete='off'
                                        sx={{ width: '100%', "& label": { top: filters.find((filter) => { return filter?.rowId === row?.rowId })?.["quantity"] ? "0%" : "-16%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <IconButton onClick={() => { handleRemoveRow(index) }} style={{}}>
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
            <Button onClick={handleAddRow} style={{ marginTop: '10px', backgroundColor: '#243750', color: 'white', borderRadius: '5px', padding: '5px 10px', border: 'none', cursor: 'pointer' }}>Add Filter Row</Button>
            {rows?.length > 0 && <div>
                <div style={{
                    padding: '6px 31px',
                    borderBottom: '2px solid white',
                    width: 'fit-content',
                    borderRadius: '9px 30px 0px 0px',
                    background: '#243750',
                    color: 'white',
                    // borderBottomColor: 'white',
                    marginTop: '10px'
                }}>
                    <span>
                        Optional Filters
                    </span>
                    <IconButton size="small" style={{ marginLeft: '0.5em' }} onClick={() => { setVisible(!visible); }}>
                        {visible ? <Visibility style={{ color: 'white', width: '0.6em', height: '0.6em' }} /> : <VisibilityOff style={{ color: 'white', width: '0.6em', height: '0.6em' }} />}
                    </IconButton>
                </div>
                {visible && <div>
                    <Paper style={{ width: 'fit-content' }}>
                        {optionalFilterRows.length && optionalFilterRows.map((optionalFilterRow, index) => {
                            const field = optionalFilterRow?.field;
                            const title = optionalFilterRow?.title;
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? 'whitesmoke' : 'white', width: 'fit-content' }}>
                                    <div style={{
                                        padding: '1.2em 0em',
                                        backgroundColor: '#243750',
                                        width: '20vw',
                                        textAlign: 'center'
                                    }}>
                                        <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: 'auto', color: 'white' }}>
                                            {title}
                                        </Typography>
                                    </div>
                                    <div style={{
                                        padding: '0 0em',
                                        width: '30vw',
                                        textAlign: 'center'
                                    }}>
                                        <TextField
                                            id={field}
                                            value={optionalFilters?.[field] || ''}
                                            onChange={(e) => {
                                                setOptionalFilters((prevOptionalFilters) => {
                                                    return { ...prevOptionalFilters, [field]: e?.target?.value };
                                                });
                                            }}
                                            size='small'
                                            autoComplete='off'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </Paper>
                </div>}
            </div>}
            <Button onClick={handleFormSubmit} style={{ marginTop: '10px', marginLeft: "10px", backgroundColor: '#ff6200', color: 'white', borderRadius: '5px', padding: '5px 10px', border: 'none', cursor: 'pointer' }}>Search</Button>
            <Button onClick={handleSearchByCombo} style={{ marginTop: '10px', marginLeft: "10px", backgroundColor: '#ff6200', color: 'white', borderRadius: '5px', padding: '5px 10px', border: 'none', cursor: 'pointer' }}>Search By Combo</Button>
            {isProductsVisible &&
                Object.keys(productsCardData).length > 0 && Object.keys(productsCardData)?.map((filterId) => {
                    return (
                        <ProductCards
                            selectedProducts={selectedProducts}
                            productCardsData={productsCardData[filterId]}
                            carouselTitle={`Select Product - Filter ${filterId}`}
                            key={filterId}
                            filters={filters}
                            fetchProducts={fetchProducts}
                            fetchProductsByCombo={fetchProductsByCombo}
                            filterId={filterId}
                            loadingMoreProducts={loadingMoreProducts}
                            setLoadingMoreProducts={setLoadingMoreProducts}
                        />
                    )
                })
            }
            {
                isProductsVisible
                && filters?.length > 0
                && carouselsSkeleton()
            }
        </Fragment>
    );
}

export default CustomizedTables;