import React, { forwardRef, useState } from 'react'
import { convertCsvToJson } from '../../../utility/csvToJson';
import axiosInstance from '../../../utility/axios-instance';
import { Backdrop, Button, CircularProgress, Fab, Snackbar } from '@mui/material';
import { CloudUpload, FileOpen } from '@mui/icons-material';
import { downloadCSV } from '../../../utility/downloadCSV';
import MuiAlert from '@mui/material/Alert';


const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function BulkProductAddForCreateOrders({ setProductDetails, setAddToggle, createInstantDraftOrder, setDraftOrderCreate, draftOrderCreate, type }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [error, setError] = useState({ display: false, message: "default message!", data: [] })
    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };
    const handleErrorClose = () => {
        setError(
            { display: false, message: "default message!", data: [] }
        );
    };

    const handleErrorExport = () => {
        if (error?.data?.length > 0) {
            downloadCSV(error?.data, `Error - ${new Date()}`);
        }
        handleErrorClose()
    }

    const handleProductBulkAdd = async () => {
        setLoading(true);
        try {
            let jsonData = await convertCsvToJson(selectedFile)
            // remove all entries with empty sku 
            jsonData = jsonData.filter((item) => item?.sku?.trim() !== '');

            let payload = [];
            let failedSkus = []

            // find is there any duplicate sku
            const duplicateSkus = jsonData?.map((item) => item?.sku).filter((item, index, arr) => arr.indexOf(item) !== index);
            if (duplicateSkus.length > 0) {
                setSnackBar((prevSnackBar) => {
                  return {
                    ...prevSnackBar,
                    display: true,
                    message: `
                          ${duplicateSkus.length} 
                          duplicate entries for follwing skus: 
                          ${duplicateSkus.join(",")}
                          `,
                    type: "error",
                  };
                });
                setLoading(false);
                return;
            }
            for(let data of jsonData) {
                if (data?.discount) {
                    data.discount = parseFloat(data?.discount)
                }
            }

            await axiosInstance
                .post(`/product/getVariantIdsBySku?type=${type}`, { skus: jsonData?.filter((item) => item?.sku !== '') })
                .then((res) => {
                    payload = res?.data?.data?.variantIds;
                    failedSkus = res?.data?.data?.failedSkus;
                    const successMessage = `Products added successfully`
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    if (failedSkus.length > 0) {
                        setError(
                            { display: true, message: `${res?.data?.data?.failedSkus?.length} invalid entries!!`, data: res?.data?.data?.failedSkus }
                        );
                    }
                    // reset the file input
                    if (createInstantDraftOrder) {
                        setDraftOrderCreate(true)
                    }
                    document.getElementById('fileInput').value = '';
                    setSelectedFile(null);

                })
                .catch((err) => {
                    console.log("error", err);
                    const errorMessage = err?.response?.data?.message || "Something went wrong!";
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                }).finally(() => {
                    setLoading(false);
                });

            setProductDetails(payload);
            setAddToggle(true);


        } catch (error) {
            console.log("error", error)
            setLoading(false);
        }
    }

    const handleTemplateDownload = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`/templates/productsBulkAdd`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
	            downloadCSV(arrayOfRowObjects,`Bulk Product Add template - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `Product Add template downloaded successfully`, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: error?.response?.data?.message, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }
    

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} open={error?.display} autoHideDuration={20000} onClose={handleErrorClose}
            >
                <Alert action={
                    <Button color="inherit" size="small" onClick={() => handleErrorExport()}>
                        EXPORT
                    </Button>
                } onClose={handleSnackBarClose} severity="error" sx={{ width: '100%' }}>
                    {error?.message}
                </Alert>
            </Snackbar>
            <div 
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
                gap:10
            }}
            >

            <input
                type="file"
                id="fileInput"
                className="form-control"
                accept=".csv"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                />
            <Button disabled={!selectedFile} variant='contained' startIcon={<CloudUpload />} onClick={handleProductBulkAdd}>Add</Button>
            <Fab color="primary" size="small" onClick={handleTemplateDownload}>
                    <FileOpen />
                </Fab>
                </div>
            <Snackbar open={snackBar?.display} autoHideDuration={1500} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
