import { Doughnut, getElementsAtEvent } from "react-chartjs-2"
import { CategoryScale, Chart } from "chart.js";
import 'chart.js/auto';
import { useEffect, useRef, useState } from "react";

Chart.register(CategoryScale);

const PieChartComponent = (props) => {
    const {labels, label, data,backgroundColor, hoverBackgroundColor, 
        setskuStatusLabel, clickEvent = false, tableRef} = props


    const [index, setIndex] = useState();
    const chartRef = useRef();

    const handleClick = (event) => {
        const element = getElementsAtEvent(chartRef.current, event);
        const dataPoint = element[0].index;
        setIndex(dataPoint)
        clickEvent && tableRef?.current?.scrollIntoView({behavior : 'smooth'})
    }

    useEffect(() => {
        clickEvent && setskuStatusLabel(labels[index])
    },[index])

    return (
        <>
            <Doughnut
                height='100%'
                width='100%'
                data={{
                    labels : labels.slice(0,10),
                    datasets : [{
                        label,
                        data : data.slice(0,30),
                        backgroundColor : backgroundColor,
                        hoverBackgroundColor : hoverBackgroundColor,
                        borderWidth: .2,
                        borderColor : 'white',
                    }]
                }}
                options={{
                    maintainAspectRatio : false,
                    plugins: { 
                        title: {
                            display: true,
                            text: label,
                            color: 'rgba(61, 58, 58, 1)',
                            position: 'top',
                            align: 'center',
                            font: {
                                weight: 'bold',
                                size : '18px'
                            },
                            padding: 8,
                            fullSize: true,
                        },
                    },
                }}
                ref={chartRef}
                onClick={clickEvent && handleClick}
            />
        </>
    )
}

export default PieChartComponent;