import { Fragment, useState } from 'react';
// import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import NewSalesUser from './newSalesUser';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button from '@mui/material/Button';
import UpdateSalesUser from './updateSalesUser';
import { useSelector } from 'react-redux';
import DeleteSalesUser from './deleteUser';
import SalesUserDialog from './addSalesUser';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportReport from './exportReport';
import { setReportDashboardData } from '../../../store/reportDashboardSlice';
import Table from "../../../components/table/table";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
const month = new Date().getDate() <= 5 ? monthNames[new Date().getMonth() -1] : monthNames[new Date().getMonth()];

const Reports = (props) => {
    const [userId,setUserId] =  useState('');

    // Create SalesUser
    const [userName,setUserName] = useState('');
    const [name,setName] = useState(''); 
    const [phoneNo,setPhoneNo] = useState('');
    const [customerType,setCustomerType] = useState('');
    const [password,setPassword] = useState(''); 
    const [monthlyTarget,setMonthlyTarget] = useState('');
    const [showModal, setShowModal] = useState(false);

    // exports
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [month,setMonth] = useState('');

    // Update Sales User
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    // Delete Sales User
    const [showDeleteModal,setShowDeleteModal] = useState(false);
    const [reRender,setReRender] = useState(false);

    // Add Sales User
    const [open,setOpen] = useState(false);

    const [exportDialogOpen, setExportDialogOpen] = useState(false);

    const auth = useSelector((state) => state.auth);
    const reportDashboardData = useSelector((state) => state.reportDashboardData);

    const showSalesUserDialog = () => {
        setOpen(true);
    }

    const hideSalesUserDialog = () => {
        setOpen(false);
    };

    const hideDialog = () => {
        setShowModal(false);
    };

    const showDialog = () => {
        setShowModal(true);
    }

    const handleUpdateDialog = (event,customerId) => {
        event.stopPropagation();
        setUserId(customerId);
        setShowUpdateModal(true);
    }

    const hideUpdateDialog = () => {
        setUserId('');
        setShowUpdateModal(false);
    }

    const handleDeleteDialog = (event,customerId) => {
        event.stopPropagation();
        setUserId(customerId);
        setShowDeleteModal(true);
    }

    const hideDeleteDialog = () => {
        setUserId('');
        setShowDeleteModal(false);
    }

    const handleExport = () => {
        setExportDialogOpen(true);
    }

    const handleClose = () => {
        setExportDialogOpen(false);
        selectedUsers.splice(0, selectedUsers.length);
    }

    const columns = [
        {
            field: 'name',
            headerName: 'User',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => (
                <div>{params?.row?.phone ? params?.row?.phone : 'N/A'}</div>
            )
        },
        {
            field: 'customerType',
            headerName: 'Customer Type',
            flex: 0.8,
            align: 'center',
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'admins',
            columnName: 'type_of_customer',
            renderCell: (params) => (
                <div>{params?.row?.customerType ? params?.row?.customerType : 'N/A'}</div>
            )
        },
        {
            field: 'totalVisits',
            headerName: `${month} Total Visits`,
            flex: 0.8,
            align: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'totalNumberOfCustomer',
            headerName: 'Total No. Of Customer',
            flex: 1,
            align: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'totalSale',
            headerName: 'Sum Of Orders',
            flex: 0.8,
            filterable: false,
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.totalSale ? (parseFloat(params?.row?.totalSale))?.toFixed(2) : "N/A" }</div>
            )
        },
        {
            field: 'monthlyTarget',
            headerName: `${month} Target`,
            flex: 0.8,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.monthlyTarget ? params?.row?.monthlyTarget : "250000" }</div>
            )
        },
        {
            field: 'Update',
            headerName: 'Update',
            filterable: false,
            flex: 0.4,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Button 
                    variant="contained"
                    sx={{
                        textTransform: "capitalize", padding:'3px 10px',
                        minWidth:'unset', fontSize:'12px'
                    }}
                    onClick={(event) => handleUpdateDialog(event,params?.row?.doc_id)}
                >
                    Edit
                </Button>
            )
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            flex: 0.4,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "capitalize",
                        padding:'2px 5px',
                        fontSize:'12px',
                        cursor:'pointer',
                        backgroundColor:'rgb(211, 47, 47)',
                        ":hover": {backgroundColor:'rgb(211, 47, 47)'},
                        minWidth: "fit-content"
                    }}
                    onClick={(event) => handleDeleteDialog(event,params?.row?.doc_id)}
                    id='btnArea'
                >
                    Delete
                </Button>
            )
        },
    ];

	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>
                
                <div style={{display:'flex',marginTop:-25}} className={Classes.salesReportHeader}>
                    <div style={{display:'flex',marginBottom:5}}>
                        {
                            (auth?.user?.role === 'super-admin' || 
                            auth?.user?.role === 'admin' || 
                            auth?.user?.role === 'salesManager') &&
                        (
                            <Button
                                id='exportbtn'
                                variant="contained"
                                style={{backgroundColor:'#26b278',marginRight:'5px',fontSize:14}}
                                startIcon={<FileDownloadIcon />}
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                        )}
                        
                        {
                            (auth?.user?.role === 'super-admin' || 
                            auth?.user?.role === 'admin' || 
                            auth?.user?.role === 'salesManager') &&
                        (
                            <Button 
                                className={Classes.ResponsiveBtn}
                                sx={{
                                    textTransform:'capitalize',
                                    "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                                }} 
                                variant="contained"
                                startIcon={<PersonAddIcon />}
                                onClick={showSalesUserDialog}
                            >
                                ADD
                            </Button>
                        )}
                    </div>

                    <div style={{display:'flex'}}>
                        {/* <Button  
                            startIcon={<RefreshIcon />} 
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize:'14px',
                                textTransform:'capitalize',
                                marginRight:'5px',
                                backgroundColor:'#EE5C55',
                                ":hover": {backgroundColor:'#EE5C55'},
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                            }}
                            onClick={handleRefreshUsers}
                        >
                            Refresh
                        </Button> */}
                        
                        {
                            (auth?.user?.role === 'super-admin' || 
                            auth?.user?.role === 'admin' || 
                            auth?.user?.role === 'salesManager') &&
                        (
                            <Button 
                                sx={{
                                    textTransform:'capitalize',
                                    "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                                }} 
                                variant="contained"
                                startIcon={<PersonAddIcon />}
                                onClick={showDialog}
                            >
                                Create New
                            </Button>
                        )}
                    </div>
                </div>
                
                <div className={Classes.Container}>
                    <Table
                        columns={columns}
                        dataPosition={"users"}
                        endpoint={`/fieldSalesVisit/salesUsers`}
                        rowId={'doc_id'}
                        checkBoxSelection={false}
                        navigateOnRowClickEndpoint={`/reports`}
                        version="version-1"
                        reRender={reRender}
                        service={'fieldSales'}
                        tabName={'Report Dashboard'}
                        setData={setReportDashboardData}
                        data={reportDashboardData}
                        mobileResponsive={true}
                        exportEndpoint={'/exports/fieldSalesVisit/salesUsers'}
                        exportFileName={`SalesUsers - ${new Date()}`}
                    />
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewSalesUser 
                    showModal={showModal}
                    hideDialog={hideDialog}
                    userName={userName}
                    setUserName={setUserName}
                    name={name}
                    setName={setName}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    customerType={customerType}
                    setCustomerType={setCustomerType}
                    password={password}
                    setPassword={setPassword}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={showUpdateModal ? Classes.UpdateSalesDialog : undefined}>
                <UpdateSalesUser 
                    showModal={showUpdateModal}
                    hideDialog={hideUpdateDialog}
                    userId={userId}
                    userName={userName}
                    setUserName={setUserName}
                    name={name}
                    setName={setName}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    customerType={customerType}
                    setCustomerType={setCustomerType}
                    password={password}
                    setPassword={setPassword}
                    monthlyTarget={monthlyTarget}
                    setMonthlyTarget={setMonthlyTarget}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={showDeleteModal ? Classes.UpdateSalesDialog : undefined}>
                <DeleteSalesUser 
                    showModal={showDeleteModal}
                    hideDialog={hideDeleteDialog}
                    userId={userId}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={open ? Classes.UpdateSalesDialog : undefined}>
                <SalesUserDialog 
                    open={open}
                    handleClose={hideSalesUserDialog}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>
            <div className={exportDialogOpen ? Classes.UpdateSalesDialog : undefined}>
                <ExportReport 
                    open={exportDialogOpen}
                    handleClose={handleClose}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    month={month}
                    // setMonth={setMonth}
                />
            </div>
        </Fragment>
    );
};

export default Reports;
