import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../../components/SnackBar';
import { TextField } from '@mui/material';
import axiosInstance from '../../../../utility/axios-instance';
import Classes from "../css/Modal.module.css";
import Spinner from '../../../../components/spinner';
import { isValidCINNumber, isValidPan } from '../../../../utility/validations';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateSeller = (props)  => {
    const { open, handleClose, setSeller,fetchData, setSellers,seller } = props;

    const [loading,setLoading] = useState(false);
    // const [phoneNo,setPhoneNo] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });

    const handleSubmit = async (event)=> {
        event.preventDefault();
        setLoading(true);

        const data = {
            id: seller?.id,
            sellerName: event.target.sellerName.value,
            sellerGstNumbers: event.target.gstNumber.value ?  [event.target.gstNumber.value] : undefined,
            phoneNumber:event.target.phoneNumber.value || undefined,
            email: event.target.email.value || undefined,
        }

        axiosInstance
            .put('/seller/updateSeller', {...data})
            .then((res) =>{
                const form = document.getElementById('form');
                form.reset();
                fetchData('/seller/fetchAllSellers', setSellers)
                handleClose();
                setLoading(false);
                setTimeout(() => {
                    setSeller(res.data.data?.[1]?.[0]);
                },500)
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                document.getElementById('phoneErrMsgUpdate').style.display = 'none';
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsgUpdate').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsgUpdate').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    const handleValidGst = (event) =>{
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGstUpdate').style.display = 'none';
        } else {
            if(event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGstUpdate').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGstUpdate').style.display = 'block';
            }
        }
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle>Update Seller</DialogTitle>
                <form method="post" onSubmit={handleSubmit} id='form' >
                    <div style={{padding:20}}>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{ width:'49%' }}>
                                <TextField
                                    required
                                    name='sellerName'
                                    id="sellerName"
                                    label="Seller Name"
                                    placeholder='Seller Name'
                                    key={seller?.sellerName || ''}
                                    defaultValue={seller?.sellerName || ''}
                                    autoComplete='off'
                                    size='small'
                                    sx={{ width: '100%',"& label": {fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />
                            </div>

                            <div style={{ width:'49%'}}>
                                <TextField
                                    id="gstNumber"
                                    name="gstNumber"
                                    label="Gst Number"
                                    placeholder='GST Number'
                                    minLength={15}
                                    maxLength={15}
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={handleValidGst}
                                    key={seller?.sellerGstNumbers?.[0] || ''}
                                    defaultValue={seller?.sellerGstNumbers?.[0] || ''}
                                    autoComplete='off'
                                    size='small'
                                    sx={{ width: '100%',"& label": {fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />

                                <div id='isValidGstUpdate' className={Classes.IsValidGst}>
                                    <p>Please fill valid Gst Number</p>
                                </div>
                            </div>
                        </div>


                        <div style={{marginTop:25,display:'flex',justifyContent:'space-between'}}>
                            <div style={{ width:'49%'}}>
                                <TextField 
                                    // required
                                    id='phoneNumber'
                                    name="phoneNumber"
                                    type="number"
                                    label="Phone Number"
                                    onInput={(e)=>{ e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)}}
                                    min={10}
                                    onBlur={(e) => handleMinLength(e)}
                                    placeholder='Enter Your 10 Digit Phone Number'
                                    autoComplete='off'
                                    key={seller?.phoneNumber ? parseInt(seller?.phoneNumber) :  ''}
                                    defaultValue={seller?.phoneNumber ? parseInt(seller?.phoneNumber) :  ''}
                                    size='small'
                                    onKeyDown={preventNegativeValues}
                                    sx={{ width: '100%',"& label": {fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />
                            </div>

                            <div style={{width:'49%'}}>
                                <TextField 
                                    // required
                                    id='email'
                                    name="email"
                                    label="Email"
                                    type='email'
                                    placeholder='Enter Your Email'
                                    autoComplete='off'
                                    key={seller?.email || ''}
                                    defaultValue={seller?.email || ''}
                                    size='small'
                                    sx={{ width: '100%',"& label": {fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />
                            </div>
                        </div>

                        <div className={Classes.PhoneErrorMsg} id='phoneErrMsgUpdate'>
                            <p>Please enter a valid 10 digit mobile number</p>
                        </div>
                    </div>

                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        <Button type='submit' id='btn'>UPDATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            {loading ? <Spinner /> : null}
        </div>
    );
}

export default UpdateSeller;