import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedSkuBinMappings: [],
};

const inventoryViewSlice = createSlice({
	name: 'inventoryView',
	initialState: initialState,
	reducers: {
		setInventoryView: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setInventoryView, reset } = inventoryViewSlice.actions;

export default inventoryViewSlice.reducer;