import { Fragment } from 'react';
import PaymentGatewayTransactions from '../paymentGatewayTransactions/paymentGatewayTransactions'

const Transactions = (props) => {
    const { draftOrder } = props;
    

    return (
        <Fragment>
            <div style={{width:'100%',marginBottom:20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Transactions</p>
                    <PaymentGatewayTransactions text={draftOrder.orderName} />
                    
                </div>
            </div>
        </Fragment>
    )
}

export default Transactions;