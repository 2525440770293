import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import Classes from './css/return.module.css';
import axiosInstance from '../../../utility/axios-instance';
import { setReturns } from '../../../store/returnsSlice';
import resetState from '../../../store/actions';

import Return from './return';
import Spinner from '../../../components/spinner';
import Search from '../../../components/search';
import ReturnMobile from './returnMobile';
import NewPagination from '../../../components/newPagination';

const Returns = (props) => {
	const returns = useSelector((state) => state.returns);
	const dispatch = useDispatch();
	const ref = useRef();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const [text, setText] = useState('');

	const fetchReturns = async (page) => {
		setLoading(true);
		setError(null);
		if (!page) {
			page = returns.page;
		}
		const params = {
			page,
		};
		await axiosInstance
			.get('/returns', {
				params,
			})
			.then((response) => {
				dispatch(setReturns(response?.data?.data));
			})
			.catch((error) => {
				setError(error?.response?.data?.message);
				if (error?.response?.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					fetchReturns(page);
				}, 1000000);
			});
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page');
		fetchReturns(page ? page: 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="d-flex flex-column-reverse flex-md-row justify-content-between mb-5">
				<div className="px-3 py-1 border border-dark rounded text-center">
					<p className="mb-0 fs-5">
						<strong>Returns</strong>
					</p>
				</div>
				<button
					type="button"
					className={`${Classes.ReloadBtn} btn btn-outline-dark`}
					onClick={() => {
						fetchReturns();
					}}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
					{loading ? 'Loading...' : 'ReLoad'}
				</button>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			<Search func={fetchReturns} text={text} setText={setText} />
			<div className="d-block d-sm-none">
				{returns?.returns?.map((returnn, index) => (
					<ReturnMobile key={returnn?._id} returnn={returnn} index={index} />
				))}
			</div>

			<table className={`${Classes.Table} table d-none d-sm-table`} style={{}}>
				<thead>
					<tr>
						<th scope="col">Order</th>
						<th scope="col">OutputKey</th>
						<th scope="col">SKU</th>
						<th scope="col">QNT</th>
						<th scope="col">Requested At</th>
						<th scope="col">Status</th>
						<th scope="col">Type</th>
						<th scope="col">Reason</th>
						<th scope="col">Refund Amount</th>
						<th scope="col">Refund Status</th>
					</tr>
				</thead>
				<tbody
					style={{
						fontSize: '.8rem',
					}}
				>
					{returns?.returns?.map((returnn, index) => (
						<Return key={returnn?._id} returnn={returnn} index={index} />
					))}
				</tbody>
			</table>
			{returns?.returns?.length > 0 ? (
				<nav aria-label="Page navigation">
					<NewPagination 
						totalPage={returns}
						fetchData={fetchReturns}
					/>
				</nav>
			) : null}
			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default Returns;
