import { useState } from 'react';
import { Modal, TextField } from '@mui/material';
import Classes2 from '../../createPurchaseOrder/css/Modal.module.css'
import axiosInstance from '../../../../utility/axios-instance';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const UpdateShipmentDetails = (props) => {
    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;

    const {updateShipmentDetail, draftOrder, setUpdateToggle, setUpdateShipmentDetail} = props;
    const poShipmentDetail = draftOrder.PoShipmentDetails;

    const [estimatedDispatchDate, setEstimatedDispatchDate] = useState(poShipmentDetail?.estimatedDispatchDate ? getFormattedDate(new Date(poShipmentDetail?.estimatedDispatchDate)) : null)
    const [estimatedReceivingDate, setEstimatedReceivingDate] = useState(poShipmentDetail?.estimatedReceivingDate ? getFormattedDate(new Date(poShipmentDetail?.estimatedReceivingDate)) : null)
    const [biltyDate, setBiltyDate] = useState(poShipmentDetail?.biltyDate ? getFormattedDate(new Date(poShipmentDetail?.biltyDate)) : null)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        let data = {
            transporterType: event.target.transporterType.value,
            transporterName: event.target.transporterName.value,
            freightCharges: +event.target.freightCharges.value,
            biltyNumber: event.target.biltyNumber.value
        }

        if (estimatedDispatchDate) {
            data.estimatedDispatchDate = estimatedDispatchDate
        }

        if (estimatedReceivingDate) {
            data.estimatedReceivingDate = estimatedReceivingDate
        }

        if (biltyDate) {
            data.biltyDate = biltyDate
        }

        setError(null)
        setLoading(true)
        
        axiosInstance
                .put(`/purchaseOrders/updatePoShipmentDetail/${poShipmentDetail.poShipmentDetailId}`, data)
                .then((res) => {
                    setUpdateShipmentDetail(false)
                    setUpdateToggle(Date.now())
                })
                .catch(err => {
                    setError(err?.response?.data?.message)
                })
                .finally(() => {
                    setLoading(false)
                });
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Modal open={updateShipmentDetail} onClose={()=> {
                setUpdateShipmentDetail(false)
            }} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div
                    className={Classes2.modal}
                    style={{ bottom: '10%', top: '3%' }}
                >
                    <div className={Classes2.BoxContent}>
                        <p>Edit Shipping Address</p>
                    </div>

                    <form id='form' onSubmit={handleSubmit}>
                        <div className={Classes2.AddressPopUp}>
                            {error ? <div style={{
                                marginBottom: '2rem'
                            }}>
                                <p style={{
                                    color: 'red',
                                    fontSize: '14px'
                                }}>{error}</p>
                            </div> : null}

                            <div style={{ display:'flex',marginTop:15,justifyContent:'space-between' }}>
                                <div style={{ width:'48%' }}>
                                    <TextField 
                                        id='transporterType'
                                        name='transporterType'
                                        label='Transporter Type'
                                        autoComplete='off'
                                        key={poShipmentDetail?.transporterType}
                                        defaultValue={poShipmentDetail?.transporterType}
                                        placeholder='Transporter Type'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width:'48%' }}>
                                    <TextField 
                                        id='transporterName'
                                        name='transporterName'
                                        label='Transporter Name'
                                        autoComplete='off'
                                        key={poShipmentDetail?.transporterName}
                                        defaultValue={poShipmentDetail?.transporterName}
                                        placeholder='Transporter Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{ display:'flex',marginTop:15,justifyContent:'space-between' }}>
                                <div style={{ width: '48%'}}>
                                    <TextField 
                                        type='number'
                                        id='freightCharges'
                                        name='freightCharges'
                                        label='Freight Charges '
                                        key={poShipmentDetail?.freightCharges}
                                        defaultValue={poShipmentDetail?.freightCharges}
                                        autoComplete='off'
                                        placeholder='Enter Freight Charges'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width:'48%' }}>
                                    <TextField 
                                        type='number'
                                        id='biltyNumber'
                                        name='biltyNumber'
                                        label='Bilty Charges '
                                        key={poShipmentDetail?.biltyNumber}
                                        defaultValue={poShipmentDetail?.biltyNumber}
                                        autoComplete='off'
                                        placeholder='Enter Bilty Charges'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{ display:'flex',marginTop:15,justifyContent:'space-between' }}>
                                <div style={{ width:'32%' }}>
                                    <DatePicker
                                        label="Estimate Dispatch Date" 
                                        sx={{ 
                                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px", width: '100%',fontSize:'14px'},
                                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                                "& .Mui-error": { color:'#00000099 !important' }
                                        }}
                                        inputFormat="YYYY-MM-DD"
                                        format="YYYY-MM-DD"
                                        defaultValue={dayjs(poShipmentDetail?.estimatedDispatchDate ? getFormattedDate(new Date(poShipmentDetail?.estimatedDispatchDate)) : null)}
                                        // value={dayjs(estimatedDispatchDate)}
                                        onChange={(newValue) => {
                                            setEstimatedDispatchDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                                        }} 
                                    />
                                </div>

                                <div style={{ width:'32%' }}>
                                    <DatePicker 
                                        label="Estimate Receiving Date" 
                                        sx={{ 
                                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px", width: '100%',fontSize:'14px'},
                                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                                "& .Mui-error": { color:'#00000099 !important' }
                                        }}
                                        inputFormat="YYYY-MM-DD"
                                        format="YYYY-MM-DD"
                                        defaultValue={dayjs(poShipmentDetail?.estimatedReceivingDate ? getFormattedDate(new Date(poShipmentDetail?.estimatedReceivingDate)) : null)}
                                        // value={dayjs(estimatedReceivingDate)}
                                        onChange={(newValue) => {
                                            setEstimatedReceivingDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                                        }} 
                                    />
                                </div>

                                <div style={{ width:'32%' }}>
                                    <DatePicker 
                                        label="Bilty Date" 
                                        sx={{ 
                                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px", width: '100%',fontSize:'14px'},
                                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                                "& .Mui-error": { color:'#00000099 !important' }
                                        }}
                                        inputFormat="YYYY-MM-DD"
                                        format="YYYY-MM-DD"
                                        defaultValue={dayjs(poShipmentDetail?.biltyDate ? getFormattedDate(new Date(poShipmentDetail?.biltyDate)) : null)}
                                        // value={dayjs(biltyDate)}
                                        onChange={(newValue) => {
                                            setBiltyDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                                        }} 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Classes2.DialogBtnBox} style={{bottom: "10%"}}>
                            <button 
                                type="button" 
                                className={Classes2.UpdateSalesUserOfCustomer}
                                onClick={() => {
                                    setUpdateShipmentDetail(false)
                                }}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes2.UpdateSalesUserOfCustomer}
                            >
                                {loading ? 'UPDATING' : 'UPDATE'}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            </LocalizationProvider>
}

export default UpdateShipmentDetails;