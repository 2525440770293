import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';

const ConfirmPopup = (props) => {
    const [loading,setLoading] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })

    const {
        openConfirm,
        handlePickListConfirmClose,
        pickList,
        setReRender
    } = props;

    const handlePickOrder  = () => {
        setLoading(true);

        const data = {
            payload: pickList
        }
        axiosInstance
            .post('/picklist/generate-picklist', {...data})
            .then((res) => {
                setLoading(false);
                setReRender((prev) => !prev)
                const successMessage = `Order Pick Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handlePickListConfirmClose()
            })
            .catch((error) => {
                console.log(error,'error')
                const errorMessage = error?.response?.data?.message
                setReRender((prev) => !prev)
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            {openConfirm && (
                <div
                    className={Classes.confirmModal}
                    style={{ bottom: '20%',top:'24%',zIndex:99999,boxShadow:'0 0px 9px 0' }}
                >
                    <div style={{ textAlign:'center',margin:'30px 0px'}}>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            className={Classes.XMark}
                            style={{color:'#E12D39'}}
                        />
                    </div>

                    <div
                        style={{ fontSize:30,color:'black' }}
                        className={Classes.confirmPopUpCommonStyles}
                    >
                        <p>Are you sure?</p>
                    </div>

                    <div
                        style={{ padding:"10px 20px",color:'black' }}
                        className={Classes.confirmPopUpCommonStyles}
                    >
                        <p>Do you really want to Pick this {pickList?.length !== 0 && pickList[0]}  order?</p>
                    </div>


                    <div
                        className={Classes.Button}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '15px',
                            margin: '0px auto',
                            width: 290
                        }}
                    >
                        <div>
                            <button
                                id='btn'
                                type="submit"
                                onClick={(e) => handlePickOrder(e)}
                                style={{ backgroundColor:'#E12D39',color:'white',borderRadius:30,border:0 }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button
                                id='btn'
                                type="submit"
                                style={{borderRadius:30,backgroundColor:"#627D98",color:'#fff'}}
                                onClick={(e) => handlePickListConfirmClose(e)}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {loading ? <Spinner /> : null}
            
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default ConfirmPopup;