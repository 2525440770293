import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utility/axios-instance";
import { RichTreeView } from "@mui/x-tree-view";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import TreeView from "../../../components/TreeView/TreeView";

const CategoryTreeDialog = (props) => {
  const { payloadToUpload, setPayloadToUpload } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isCategoriesDialogOpen, setIsCategoriesDialogOpen] = useState(false);
  const [categoryTreeData, setCategoryTreeData] = useState([]); // data structure for tree nodes
  const [selectedNodeHierarchy, setSelectedNodeHierarchy] = useState([]);
  const [leafNode, setLeafNode] = useState(false);
  useEffect(() => {
    if (payloadToUpload?.l3) {
      setSelectedCategories([
        {
          id: "l1",
          label: payloadToUpload?.l1,
          children: [
            {
              id: "l2",
              label: payloadToUpload?.l2,
              children: [
                {
                  id: "l3",
                  label: payloadToUpload?.l3,
                },
              ],
            },
          ],
        },
      ]);
    }
  }, [payloadToUpload?.l3]);

  const convertToTreeData = (categories, currentLevel = 2) => {
    return categories.map((category) => {
      const nextLevelName = `l${currentLevel}s`;
      const hasNextLevel = category?.[nextLevelName]?.length > 0;
      if(!category?._id){
        return null;
      }else{
        return {
          id: category?._id,
          label: category?.title,
          disabled: !hasNextLevel,
          children: hasNextLevel
            ? convertToTreeData(category?.[nextLevelName], currentLevel + 1)
            : null,
        };
      }
    })?.filter(Boolean);
  };
  useEffect(() => {
    axiosInstance.get("/others/getAll-l1s").then((res) => {
      const data = res?.data?.data || [];
      setCategoryTreeData(convertToTreeData(data));
    });
  }, []);

  const getAllItemsWithChildrenItemIds = (tree) => {
    const itemIds = [];
    const registerItemId = (item) => {
      if (item.children?.length) {
        itemIds.push(item.id);
        item.children.forEach(registerItemId);
      }
    };
    tree.forEach(registerItemId);
    return itemIds;
  };

  return (
    <div>
      <TextField
        type="text"
        id="collection3"
        autoComplete="off"
        size="small"
        sx={{ width: "100%", fontSize: "12px" }}
        label="Collections"
        value={payloadToUpload?.l3 || ""}
        onClick={() => {
          setIsCategoriesDialogOpen(true);
        }}
      />
      <Dialog
        onClose={() => {
          setIsCategoriesDialogOpen(false);
        }}
        open={isCategoriesDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        sx={{ height: "100%" }}
        scroll={"paper"}
      >
        <DialogTitle>Categories Tree</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{marginBottom: "1em"}}>
            {selectedNodeHierarchy.map((node) => node?.label).join(" > ")}
          </DialogContentText>
          <TreeView
            treeData={categoryTreeData}
            setSelectedNodeHierarchy={setSelectedNodeHierarchy}
			setLeafNode={setLeafNode}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsCategoriesDialogOpen(false);
            }}
            color="primary"
          >
            Close
          </Button>
          {leafNode && <Button
            onClick={() => {
              setPayloadToUpload((prevPayloadToUpload) => {
                return {
                  ...prevPayloadToUpload,
                  l1: selectedNodeHierarchy?.[0]?.label,
                  l2: selectedNodeHierarchy?.[1]?.label,
                  l3: selectedNodeHierarchy?.[2]?.label,
                };
              });
			  setIsCategoriesDialogOpen(false);
            }}
            color="primary"
          >
            Submit
          </Button>}
        </DialogActions>
      </Dialog>
      <div style={{ marginTop: "0.5em" }}>
        <RichTreeView
          disableSelection
          items={selectedCategories}
          expandedItems={getAllItemsWithChildrenItemIds(selectedCategories)}
        />
      </div>
    </div>
  );
};

export default CategoryTreeDialog;
