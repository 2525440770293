import React from "react";
import Classes from '../draftOrdersNew/css/draftOrderDetailPage.module.css';
import { toTitleCase } from "../../../utility/textFormatting";

const SellerDetail = (props) => {
    const { SellerDetail, SellerAddress } = props;

    return (
        <div className={[Classes.Box,'box'].join(' ')}>
            <div>
                {SellerDetail && (
                    <div>
                        <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                            <h6 style={{ margin: 'auto 0px' }}>Seller</h6>
                            <p style={{ marginRight: '5px', fontSize:14, color:'#2c6ecb', marginTop:12  }}>
                                {SellerDetail?.sellerName}
                            </p>
                        </div>

                        <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                            <p className={[Classes.asideHeaders, 'mb-3'].join(' ')}>
                                CONTACT INFORMATION
                            </p>

                            {SellerDetail && (
                                <div style={{ color:'#2c6ecb', fontSize:14 }}>
                                    <p><span>Seller Code:</span>&nbsp;{SellerDetail?.sellerCode}</p>
                                    <p>{SellerDetail?.email}</p>
                                    <p>{SellerDetail?.phoneNumber}</p>
                                    <p>
                                        {SellerDetail?.sellerGstNumber ? SellerDetail?.sellerGstNumber : 
                                        !SellerDetail?.sellerGstNumber && Array.isArray(SellerDetail?.sellerGstNumbers) ? SellerDetail?.sellerGstNumbers?.[0] : ''}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {SellerAddress && (
                    <div className={Classes.containerBox} style={{ borderBottom: '1px solid #DBDDE0' }}>
                        <div>
                            <p 
                                className={[Classes.asideHeaders]} 
                                style={{ marginBottom:10, textTransform:'uppercase' }}
                            >
                                {SellerAddress?.addressType} Address
                            </p>

                            <div style={{ fontSize: '14px' }}>
                                <p>{SellerAddress?.firstName} {SellerAddress?.lastName ? SellerAddress?.lastName: ''}</p>
        
                                <p style={{ marginRight: '5px' }}>{SellerAddress?.addressLine1}</p>
                                {(SellerAddress?.addressLine2 !== null || SellerAddress?.addressLine2 !== "null") && (
                                    <p>{SellerAddress?.addressLine2}</p>
                                )}

                                <p>{SellerAddress?.city},</p>
                                <p>{toTitleCase(SellerAddress?.state)},</p>
                                <div>
                                    <span>{SellerAddress?.pincode},</span>&nbsp;
                                    <span>{toTitleCase(SellerAddress?.country)}</span>
                                </div>
                                <p>{SellerAddress?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SellerDetail;