import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedRefunds: [],
};

const refundsSlice = createSlice({
	name: 'refunds',
	initialState: initialState,
	reducers: {
		setRefunds: (state, action) => action.payload,
		reset: (state, action) => initialState,
		setRefund: (state, action) => {
			const refund = action.payload;
			const index = state.fetchedRefunds.findIndex((r) => r._id === refund._id);
			if (index !== -1) {
				state.fetchedRefunds[index] = refund;
			}
		},
	},
});

export const { setRefunds, reset, setRefund } = refundsSlice.actions;

export default refundsSlice.reducer;
