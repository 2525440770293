import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "./css/Modal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";


const ConfirmPopup = (props)=> {
    const { showConfirmPopup, hideConfirmPopup, productId } = props;

    return (
        <Fragment>
            {showConfirmPopup && (
                <div className={Classes.confirmModal} style={{bottom:'32%',top:'14%'}}>
                    <div style={{ textAlign:'center', margin:'30px 0px' }}>
                        <FontAwesomeIcon 
                            icon={faCircleXmark} 
                            style={{ 
                                fontSize:75, color:'rgb(211, 47, 47)', cursor: "pointer"
                            }}
                        />
                    </div>

                    <p style={{ color:'#000', textAlign: 'center', fontSize: 30 }}>Are you sure?</p>
                    <p style={{ color:'#000',  padding:'10px 0px',textAlign: 'center' }}>
                        Do you really want to delete {productId?.length === 1 ? 'this': 'these'} product?
                    </p>

                    <div className={Classes.Button}>
                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => hideConfirmPopup(e)}
                                style={{backgroundColor:'rgb(225, 45, 57)',color:'white',border:0,borderRadius:20 }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => hideConfirmPopup(e)}
                                style={{border:0,backgroundColor:'rgb(98, 125, 152)',borderRadius:20,color:'#fff'}}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default ConfirmPopup;