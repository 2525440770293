import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SnackBar from "../../../../../components/SnackBar";
import { Button, Typography,Alert,AlertTitle,Box } from "@mui/material";
import { Add, Edit, TableView } from "@mui/icons-material";
import Table from "../../../../../components/table/table";
import { useSelector, useDispatch } from "react-redux";
import { setStores } from "../../../../../store/storesLocationSlice";
import Permission from "../../../../auth/permissions";
import Classes from "../../../dynamicBanners/dynamicBanners.module.css";
import axiosInstance from "../../../../../utility/axios-instance";
import FormDialog from "../../../../../components/FormDialog/FormDialog";
import CreateModal from "./createStoreLoc";
import UpdateModal from "./editStoreLoc";
import { setActiveStoreId } from "../../../../../store/storesLocationSlice";

const ShowStoreLocationTable = () => {
  const stores = useSelector((state) => state.storeLocation.stores);
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState("");
  const [store, setStore] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const columns = [
    {
      field: "id",
      headerName: "STORE ID",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "streetAddress",
      headerName: "STREET ADD. ",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "updatedAt",
      headerName: "UPDATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "landmark",
      headerName: "LANDMARK",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "postalCode",
      headerName: "POSTAL CODE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: true,
      sortable: true,
      flex: 1,
    },
    {
      field: `show`,
      headerName: `SHOW IMAGES`,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          startIcon={<TableView />}
          onClick={() => {
            dispatch(setActiveStoreId(params?.row?.id))
            navigate(`images`);
          }}
        ></Button>
      ),
    },
    {
      field: `edit`,
      headerName: `EDIT ADD.`,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="store" permission="update">
          <Button
            variant="contained"
            size="small"
            startIcon={<Edit />}
            onClick={() => handleUpdate(params.id)}
          ></Button>
        </Permission>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="store" permission={"delete"}>
          <FormDialog
            dialogTitle="Confirm Delete Store Details"
            dialogActionType="confirmation"
            openDialogButtonName="Delete"
            submitDialogButtonName="Confirm"
            onConfirmation={() => {
              handleDelete(params?.row?.id);
            }}
          >
            <Alert
              severity="warning"
              variant="outlined"
              sx={{
                borderRadius: 2,
                boxShadow: "none",
                bgcolor: "background.paper",
              }}
            >
              <AlertTitle>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="warning.main"
                >
                  Confirm Deletion
                </Typography>
              </AlertTitle>
              <Box mb={1}>
                <Typography variant="body1">
                  This action will permanently delete the store Named:{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    fontWeight="bold"
                  >
                    {params.row.name}
                  </Typography>{" "}
                  with StoreId :
                  <Typography
                    variant="body1"
                    component="span"
                    fontWeight="bold"
                  >
                    {params.id}
                  </Typography>{" "}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="warning.main"
              >
                Are you sure you want to continue?
              </Typography>
            </Alert>
          </FormDialog>
        </Permission>
      ),
    },
  ];
  const columnVisibilityModel = {
    createdAt: false,
    updatedAt: false,
    landmark: false,
  };

  const handleDelete = (storeId) => {
    axiosInstance
      .delete(`/adminRoutes/stores/locations/${storeId}`)
      .then((res) => {
        setReRender(!reRender);
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
        if (stores?.storesLocationDetails?.length === 1) {
          navigate('/settings/website/stores')
        }
      })
      .catch((err) =>
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        })
      );
  };

  const handleUpdate = (storeId) => {
    const store = stores.storesLocationDetails.find(
      (store) => store.id === storeId
    );
    setStoreId(storeId);
    setShowUpdateModal(true);
    setStore(store);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };
  return (
    <>
      <div style={{ display: "flex", gap: "1rem 1rem" }}>
        <Permission service="store" permission="create">
          <div className={Classes.UsersHead}>
            {" "}
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<Add />}
              onClick={() => setShowCreateModal(true)}
              className="mt-4"
            >
              Add New Store
            </Button>
          </div>
        </Permission>
      </div>
      <Permission service="store" permission="read">
        <Table
          columns={columns}
          dataPosition={"storesLocationDetails"}
          endpoint={`/adminRoutes/stores/${params.storeName}/locations`}
          rowId={"id"}
          checkBoxSelection={false}
          service={"store"}
          tabName={"Store"}
          setData={setStores}
          data={stores}
          version={"version-1"}
          mobileResponsive={true}
          columnVisibilityModel={columnVisibilityModel}
          reRender={reRender}
        />
      </Permission>

      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      {showCreateModal ? (
        <CreateModal
          open={showCreateModal}
          handleClose={closeCreateModal}
          setReRender={setReRender}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          open={showUpdateModal}
          handleClose={closeUpdateModal}
          setReRender={setReRender}
          detail={store}
          storeId={storeId}
        />
      ) : null}
    </>
  );
};

export default ShowStoreLocationTable;
