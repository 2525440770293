import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";


const RefundClosePopup = (props) => {

    const {
        showPopup,
        hidePopup,
        handleRefundClose,
        refundAmt,
        setRefundAmt
    } = props;


    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom: '35%', top: '26%', zIndex:'9999' }}>
                    <div className={Classes.BoxContent}>
                        <p>Upload Proof</p>
                    </div>
                    <form className="import" onSubmit={(e)=>handleRefundClose(e)}>
                        <div style={{ padding: '30px 20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <input
                                    required
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    name="file"
                                ></input>
                            </div>
                        </div>

                        <div className={Classes.FooterBox} style={{ gap: '7px',borderTop:0 }}>
                            <button
                                type="submit"
                                className={Classes.ApplyButton}
                                style={{ background: '#fff', color: 'black' }}
                                onClick={hidePopup}
                            >
                                Close
                            </button>
                            <button
                                id='btn'
                                className={Classes.ApplyButton}
                                style={{ background: 'green', color: '#fff' }}
                                type="submit"
                            >
                                Confirm
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </Fragment>
    );
}


export default RefundClosePopup;