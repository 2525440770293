import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedReturns: [],
};

const putAwayOrdersSlice = createSlice({
	name: 'putAwayOrders',
	initialState: initialState,
	reducers: {
		setPutAwayOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setPutAwayOrders, reset } = putAwayOrdersSlice.actions;

export default putAwayOrdersSlice.reducer;