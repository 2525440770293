import { Fragment, useState, forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, MenuItem } from '@mui/material';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PutAwayPopup = (props) => {
    const { title, open, handleClose, binId, setBinId, loading, locationCode, returnId, bins, rejectBins, locations, setLocationCode, handleSubmit, rejectBinId, setRejectBinId } = props;

    useEffect(() => {
        setBinId(null)
        setRejectBinId(null)
    }, [locationCode])

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{`${title} Return`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Total Qty:{returnId?.quantity} | Pass: {returnId?.qcPass} | Fail: {returnId?.qcFail}
                    </DialogContentText>
                    <div style={{ margin: '8px 0px' }} >
                        <form onSubmit={handleSubmit} >
                            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', justifyContent: 'center' }}>

                                {/* <Autocomplete
                                    size='small'
                                    required
                                    id="lcationId"
                                    options={locations}
                                    disabled={true}
                                    onChange={(event, newValue) => {
                                        setLocationCode(newValue)
                                    }}
                                    style={{ width: "20%" }}
                                    value={locationCode}
                                    renderInput={(params) => <TextField {...params} label="Location" defaultValue="" />}
                                /> */}

                                {returnId?.qcPass > 0 ?
                                    <Autocomplete
                                        size='small'
                                        disabled={locationCode === null ? true : false}
                                        id="binId"
                                        required
                                        options={bins}
                                        style={{ width: "50%" }}
                                        getOptionLabel={(bin) => bin.code}
                                        onChange={(event, newValue) => {
                                            setBinId(newValue);
                                        }}
                                        value={binId}
                                        renderInput={(params) => <TextField required={returnId?.qcPass > 0 ? true : false}{...params} label="Pass Bin" defaultValue="" />}
                                    />
                                    : null}

                                {returnId?.qcFail > 0 ?
                                    <Autocomplete
                                        size='small'
                                        disabled={locationCode === null ? true : false}
                                        id="rejectBinId"
                                        required
                                        options={rejectBins}
                                        style={{ width: '50%' }}
                                        getOptionLabel={(rejectBin) => rejectBin.code}
                                        onChange={(event, newValue) => {
                                            setRejectBinId(newValue);
                                        }}
                                        value={rejectBinId}
                                        renderInput={(params) => <TextField required={returnId?.qcFail > 0 ? true : false} {...params} label="Reject Bin" defaultValue="" />}
                                    />
                                    : null}
                            </div>
                            <DialogActions>
                                <Button type="submit" id='move' variant="contained" color="primary" >Putaway</Button>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </form>
                    </div>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
}

export default PutAwayPopup;