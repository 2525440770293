const NotFound = (props) => {
	return (
		<div className="d-flex justify-content-center align-center vw-100 vh-100 flex-column">
			<h1 className="text-center">404</h1>
			<h3 className="text-center">Not Found</h3>
		</div>
	);
};

export default NotFound;
