import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { setCancel } from '../../../store/cancelsSlice';
import resetState from '../../../store/actions';

import axiosInstance from '../../../utility/axios-instance';

const years = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

// const days = [
// 	'Sunday',
// 	'Monday',
// 	'Tuesday',
// 	'Wednesday',
// 	'Thursday',
// 	'Friday',
// 	'Saturday',
// ];

const CancelMobile = (props) => {
	const [hover, setHover] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { cancel } = props;

	const dn = new Date();
	const cancelledAt = new Date(cancel.cancelledAt);

	let ca = cancelledAt
		? cancelledAt.getFullYear() === dn.getFullYear()
			? cancelledAt.getMonth() === dn.getMonth()
				? cancelledAt.getDate() === dn.getDate()
					? `Today at ${cancelledAt.toLocaleTimeString()}`
					: cancelledAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${cancelledAt.toLocaleTimeString()}`
					: years[cancelledAt.getMonth()] +
					  ' ' +
					  cancelledAt.getDate() +
					  ' at ' +
					  cancelledAt.toLocaleTimeString()
				: years[cancelledAt.getMonth()] +
				  ' ' +
				  cancelledAt.getDate() +
				  ' at ' +
				  cancelledAt.toLocaleTimeString()
			: years[cancelledAt.getMonth()] + ' ' + cancelledAt.getFullYear()
		: null;

	const updateCancel = async () => {
		setLoading(true);
		setError(null);

		axiosInstance
			.post(`/cancels/updateCancel/${cancel._id}`)
			.then((res) => {
				dispatch(setCancel(res.data.data));
			})
			.catch((err) => {
				setError(err.response.data.message);
				setTimeout(() => {
					setError(null);
				}, 500);
				if (err.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
        <div>
		<div
			key={cancel.id}
			className={hover ? 'bg-light' : ''}
			style={{
				cursor: 'pointer',
				margin:'20px 0px',
                padding:'8px 15px',
				border: '2px solid #798294 '
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			onClick={() => {
				navigate(`/orders/${cancel?.order?._id}`);
			}}
		>
			<div className='d-flex my-2'>
			<p className='text-muted'>Order:</p>
			<p className='mx-2'>
				{cancel?.order?.draftOrder?.order !== null ? (
					<>{cancel?.order?.draftOrder?.order?.name}</>
				): (
					<>{cancel?.order?.draftOrder?.name}</>
				)}
				</p>
			</div>
			<div className='d-flex my-2'>
			<p className='text-muted'>Cancelled At:</p>
			<p className='mx-2'>{ca}</p>
			</div>
			<div className='d-flex my-2'>
			<p className='text-muted'>Status:</p>

				<div
					className="d-flex align-items-center mx-2"
					style={{
						gap: '4px',
						position: 'relative',
						overflow: 'visible',
						zIndex: '2',
					}}
				>
					{cancel.status === 'complete' ? (
						<BsCheck color="green" size={24} />
					) : (
						<div
							className="border rounded justify-content-center align-items-center"
							style={{
								height: '16px',
								width: '16px',
							}}
							onClick={(e) => {
								e.stopPropagation();

								if (cancel.status !== 'complete') {
									updateCancel();
								}
							}}
						>
							{loading ? (
								<div
									className="spinner-border"
									role="status"
									style={{
										height: '16px',
										width: '16px',
									}}
								>
									<span className="sr-only"></span>
								</div>
							) : null}
						</div>
					)}
					{cancel.status}
					{error ? (
						<div
							className="p-1 rounded bg-danger text-light"
							style={{
								position: 'absolute',
								top: '10%',
								left: '10%',
								width: '10rem',
								zIndex: '4',
							}}
						>
							{error}
						</div>
					) : null}
				</div>
			</div>
			<div className='d-flex my-2'>
			<p className='text-muted'>Refund Amount:</p>
			<p className='mx-2'>{cancel?.refundAmount ? `₹${cancel?.refundAmount}` : null}</p>
			</div>
			<div className='d-flex my-2'>
			<p className='text-muted'>Refund Status:</p>
				{cancel?.refund ? (
					<div
						className="d-flex align-items-center mx-2"
						style={{
							gap: '4px',
							position: 'relative',
							overflow: 'visible',
							zIndex: '2',
						}}
					>
						{cancel.refund.status === 'complete' ? (
							<BsCheck color="green" size={24} />
						) : null}
						{cancel.refund.status}
					</div>
				) : null}
			</div>
			<div className='d-flex my-2'>
			<p className='text-muted'>Cancellation Reason:</p>
			<p className='mx-2'>{cancel.reason}</p>
			</div>
		</div>
    </div>
	);
};

export default CancelMobile;
