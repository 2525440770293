import { Fragment, useEffect, useState } from 'react';
import Classes from '../css/addProduct.module.css';
import { TextField, Autocomplete, Button, Chip } from '@mui/material';
import axiosInstance from '../../../../../utility/axios-instance';
import CategoryTreeDialog from '../../CategoryTreeDialog';

const  Aside2ndBox = (props) => {
	const [tagInputValue, setTagInputValue] = useState('');
	const [brands, setBrands] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [automaticTags, setAutomaticTags] = useState([]);
	const [customTags, setCustomTags] = useState([]);

    const {
		payloadToUpload,
		setPayloadToUpload
    }= props;

	useEffect(() => {
		axiosInstance
			.get('/others/adminBrands')
			.then((res) => {
				setBrands(res?.data?.data)
			})
	},[]);

	useEffect(() => {
		axiosInstance
			.get('/others/vendors')
			.then((res) => {
				setVendors(res?.data?.data)
			})
	},[]);

	useEffect(() => {
		if (payloadToUpload?.l1 && payloadToUpload?.l2 && payloadToUpload?.l3) {
			const automaticTagsToBeUpdated = [
				payloadToUpload?.variable1Value,
				payloadToUpload?.l1,
				payloadToUpload?.l2,
				payloadToUpload?.l3,
				payloadToUpload?.defaultPrices?.gst,
			]
			setCustomTags(()=>{
				return payloadToUpload?.tags?.slice(automaticTagsToBeUpdated?.length) || [];
			})
			setAutomaticTags(() => {
				return automaticTagsToBeUpdated;
			});
		}
	}, [payloadToUpload?.l3, payloadToUpload?.l2, payloadToUpload?.l1, payloadToUpload?.variable1Value, payloadToUpload?.defaultPrices?.gst]);

	useEffect(() => {
		setPayloadToUpload((prevPayloadToUpload) => {
			return { 
				...prevPayloadToUpload, 
				tags: [...automaticTags, ...customTags].filter(Boolean) 
			}
		})
	}, [automaticTags, customTags]);

	return (
		<Fragment>
            <div className={Classes.Box} id='removeTagsPrint'>
				<div>
					<Autocomplete
						freeSolo
						forcePopupIcon={true}
						name='brands' 
						id='brands'
						key={ payloadToUpload?.brandName || '' }
						defaultValue={ payloadToUpload?.brandName || '' }
						size='small'
						options={
							brands?.map((brand, index) => (
								{id: brand?.brandAbr, label: brand?.title, index: index}
							)) || []
						}
						renderOption={(props, option) => (
							<li {...props} key={`${option.id}-${option.label}-${option.index}`}>
								{option.label}
							</li>
						)}
						onChange={(e, newValue) => {
							setPayloadToUpload((prevPayloadToUpload) => {
								return { 
									...prevPayloadToUpload, 
									brandName: newValue?.label || "", 
									brandAbr: newValue?.id || "" 
								}
							})
						}}
						renderInput={(params) => <TextField {...params}  label="Brands"/>}
						disablePortal
						ListboxProps={{sx: { fontSize: 13 }}}
						sx={{  width: '100%', '& .MuiAutocomplete-input': {border:'0px', fontSize:'14px'} }}
						getOptionSelected ={(option, value) => option?.label === value?.label}
						isOptionEqualToValue={(option, value) =>  option.label === value.label}
					/>
				</div>

				<div style={{marginTop:25}}>
					<Autocomplete
						freeSolo
						forcePopupIcon={true}
						name='vendors' 
						id='vendors'
						key={ payloadToUpload?.vendorName || "" }
						defaultValue={ payloadToUpload?.vendorName || "" }
						size='small'
						options={
							vendors?.map((vendor, index) => (
								{id: vendor?.vendorCode, label: vendor?.vendorName, index: index}
							)) || []
						}
						renderOption={(props, option) => (
							<li {...props} key={`${option.id}-${option.label}-${option.index}`}>
								{option.label}
							</li>
						)}
						onChange={(e, newValue) => {
							setPayloadToUpload((prevPayloadToUpload) => {
								return { 
									...prevPayloadToUpload, 
									vendorName: newValue?.label || "", 
									vendorCode: newValue?.id || "" 
								}
							})
						}}
						renderInput={(params) => <TextField {...params}  label="Vendors" />}
						disablePortal
						ListboxProps={{sx: { fontSize: 13 }}}
						sx={{  width: '100%', '& .MuiAutocomplete-input': {border:'0px', fontSize:'13px'} }}
						getOptionSelected ={(option, value) => option?.label === value?.label}
						isOptionEqualToValue={(option, value) =>  option?.label === value?.label}
					/>
				</div>
				
				<div style={{marginTop:25}}>
					<CategoryTreeDialog payloadToUpload={payloadToUpload} setPayloadToUpload={setPayloadToUpload} />
				</div>
				
				<div style={{display:'flex',justifyContent:'space-between', gap: "0.5em", marginTop:15}}>
					<TextField
						type="text"
						id="search"
						style={{width:'100%'}}
						size='small'
						autoComplete='off'
						placeholder='create tags'
						value={tagInputValue}
						onChange={(e)=>{
							setTagInputValue(e?.target?.value || '');
						}}
						label='Tags'
					/>
					<Button
						style={{fontSize:'10px',padding:'6px'}}
						variant="contained"
						color="primary"
						onClick={()=>{
							setCustomTags((prevCustomTags) => {
								if(!tagInputValue) return prevCustomTags;
								return [
									...(prevCustomTags || []), tagInputValue
								];
							})
							setTagInputValue('');
						}}
					>
						Add Tag
					</Button>
				</div>

				{automaticTags && automaticTags?.length !== 0 && (
					<div className={Classes.ShowCollections} style={{gap: "0.5em", marginTop: "1em"}}>
						{automaticTags?.map((tag, index) => (
							<Chip key={index} label={tag} size="small" />
						))?.filter(Boolean)}
					</div>
				)}

				{customTags && customTags?.length !== 0 && (
					<div className={Classes.ShowCollections} style={{gap: "0.5em", marginTop: "1em"}}>
						{customTags?.map((tag, index) => (
							<Chip key={index} label={tag} size="small" onDelete={
								() => {
									setCustomTags((prevCustomTags) => {
										return prevCustomTags?.filter((prevTag) => prevTag !== tag);
									})
								}
							} />
						))?.filter(Boolean)}
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default Aside2ndBox;
