import { Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Slide, Switch, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CategoryIcon from '@mui/icons-material/Category';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PercentIcon from '@mui/icons-material/Percent';
import axiosInstance from "../../../utility/axios-instance";
import SnackBar from "../../../components/SnackBar";
import Classes from './dashboard.module.css'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import InfinityScrollAutoComplete from "../../../components/infinityScrollAutocomplete";
import Spinner from "../../../components/spinner";
import { useNavigate } from "react-router-dom";

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

function DatePick ({expireDate, setExpireDate}) {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expire date of discount code" 
                    sx={{ 
                            "& .MuiOutlinedInput-input": {padding: "10px 8px",width: '200px',fontSize:'14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' }
                    }}
                    inputFormat="YYYY-MM-DD"
                    size="small"
                    format="YYYY-MM-DD"
                    defaultValue={dayjs(new Date())}
                    value={expireDate}
                    onChange={(newValue) => setExpireDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`)} 
                    minDate={dayjs(Date.now())}
                />
            </LocalizationProvider>
        </div>
    )
}

export default function CreateDiscount() {

    const [discountCode, setDiscountCode] = useState(null);
    const [description, setDescription] = useState(null);
    const appliesToTypes = ["SpecificProducts", "SpecificCollections", "SpecificBrands"];
    const discountAmountOff = ["Order", "Product"];
    const discountTypes = ["PERCENTAGE", "FIXED_AMOUNT"];
    const [discountType, setDiscountType] = useState(discountTypes[1]);
    const [discountValue, setDiscountValue] = useState()
    const [active, setActive] = useState(true);
    const [discountMinPrice, setDiscountMinPrice] = useState(null);
    const [amountOff, setAmountOff] = useState(discountAmountOff[0]);
    const [maxUsesLimit, setMaxUsesLimit] = useState(false);
    const [maxUses, setMaxUses] = useState(null);
    const [uptoDiscountAmount, setUptoDiscountAmount] = useState(null);
    const [appliesTo, setAppliesTo] = useState(appliesToTypes[0]);
    const [expireDate, setExpireDate] = useState('');
    const [showExpireDate, setShowExpireDate] = useState(false);
    const [showCustomerEligibility, setShowCustomerEligibility] = useState(false);
    const [discountSpecificToCustomerRole, setDiscountSpecificToCustomerRole] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedSku, setSelectedSku] = useState([]);
    const [l3Collections, setL3Collections] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedL3s, setSelectedL3s] = useState([]);
    const [loading,setLoading] = useState(false);
    const firstRenderRef = useRef(true);
    const navigate = useNavigate();

    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    const preventSpace = (e) => [" "].includes(e.key) && e.preventDefault();

    const makeDataBody = () => {
        let data = {
            discountCode, discountType, discountValue, description, 
            active, discountMinPrice, uptoDiscountAmount, amountOff
        }
        if(amountOff === discountAmountOff[1]){
            const DiscountFilters = {appliesTo}
            if(appliesTo === appliesToTypes[0]){
                Object.assign(DiscountFilters, {sku : selectedSku.map(item => item.key)});
            }
            else if(appliesTo === appliesToTypes[1]){
                Object.assign(DiscountFilters, {l3 : selectedL3s});
            }else if(appliesTo === appliesToTypes[2]){
                Object.assign(DiscountFilters, {brands : selectedBrands});
            }
            Object.assign(data, {DiscountFilters});
        }

        if(maxUsesLimit){
            Object.assign(data, {maxUses});
        }

        if(showCustomerEligibility){
            Object.assign(data, {customers : selectedCustomers.map(item => item.key)});
        }

        if(discountSpecificToCustomerRole){
            Object.assign(data, {customer_roles : selectedRoles.map(item => item?.label)});
        }

        if(showExpireDate) {
            Object.assign(data, {expireDate});
        }
        return data;
    }

    const handleCreateDiscount = async (event) => {
        event.preventDefault();
        const data = makeDataBody();
        setLoading(true)
        await axiosInstance
            .post(`/settings/createDiscount`, {...data})
            .then((res) => {
                setLoading(false)
                const successMessage = "Discount Created Successfully";
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setTimeout(() => {
                    navigate(-1)
                }, 500);
            })
            .catch((err) => {
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: err?.response?.data?.errorMessage , type: "error" }
                });
            });
    }

    const handleL3CategoriesSearch = async (searchTerm) => {
        const params = {
            searchTerm: searchTerm || '',
        };
        try {
            const res = await axiosInstance.get(`/smartProductSearch/fetchL3sForSmartSearch`, { params });
            const responseData = res?.data?.data;
            const fetchedL3s = responseData?.fetchedL3s;
            setL3Collections(fetchedL3s);
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
            });
        }
    }

    const handleBrandsSearch = async () => {
        try {
            const res = await axiosInstance.get(`/exports/brands`);
            const responseData = res?.data?.data;
            const fetchedBrands = responseData?.map((brandObj) => {
                return brandObj?.title
            });
            setBrands(fetchedBrands);
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
            });
        }
    }

    useEffect(() => {
        handleL3CategoriesSearch();
        handleBrandsSearch();
    },[])

    useEffect(() => {
        if(!showExpireDate) setExpireDate(null);
        if(!maxUsesLimit) setMaxUses(null);
        if(discountType === discountTypes[1]) setUptoDiscountAmount(null);
        if(!showCustomerEligibility) setSelectedCustomers([]);
    },[showExpireDate, maxUsesLimit, discountType, setShowCustomerEligibility])

    return (
        <>
            <div>
                <form onSubmit={handleCreateDiscount} method="post">
                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'5px', marginRight: '15%'}}>
                        <Button
                            sx={{
                                textTransform: "capitalize",
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                            }}
                            type='submit'
                            variant="contained"
                        >Create</Button>
                    </div>

                    <div  className={Classes.ItemsContainer}>
                        <h6 style={{margin:'5px 15px'}}>Discount Details</h6>
                        <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="amountOffDropdown" required>Choose Applies Discount on</InputLabel>
                                <Select
                                    labelId="amountOffDropdown"
                                    value={amountOff}
                                    size='small'
                                    label="Amount Off Product or Order"
                                    defaultValue={amountOff}
                                    onChange={(e) => {
                                        setAmountOff(e.target.value);
                                    }}
                                    id="operator"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                >
                                    <MenuItem value={discountAmountOff[0]}>Amount off Orders</MenuItem>
                                    <MenuItem value={discountAmountOff[1]}>Amount off Product</MenuItem>
                                </Select>
                            </FormControl>

                            <div style={{ display: 'flex', justifyContent : 'space-between',}}>
                                <InputLabel sx={{paddingTop : '6px'}} id="demo-simple-select-label" required>Active</InputLabel>
                                <Switch
                                    checked={active}
                                    onChange={(e) => setActive(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                            <FormControl fullWidth>
                                <TextField
                                    id="description"
                                    type="text"
                                    value={description}
                                    label="Description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    autoComplete='off'
                                    size="small"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{ width: '100%' }}
                                />
                            </FormControl>
                        </div>

                        <div style={{margin: '15px 15px', gap: '8px'}}>
                            <div style={{margin: '15px 0px' ,padding : '0px 10px', border: '1px solid rgba(184, 186, 191,1)', borderRadius : '6px'}}>
                                <FormControlLabel 
                                    control={<Checkbox checked={maxUsesLimit}  onChange={(e) => setMaxUsesLimit(e.target.checked)}/>} 
                                    label="Limit number of times this discount can be used in total by user"
                                />
                            </div>
                            {maxUsesLimit && <TextField
                                id="maxUses"
                                type="number"
                                value={maxUses}
                                label="Max Uses"
                                onChange={(e) => setMaxUses(e.target.value)}
                                autoComplete='off'
                                size="small"
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{ width: '30%' }}
                            />}
                        </div>
                    </div>

                    {amountOff === discountAmountOff[1] &&  <div  className={Classes.ItemsContainer}>
                        <h6 style={{margin:'5px 15px 10px'}}>Applies To</h6>
                        <div>
                            <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" required>Applies To</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        value={appliesTo}
                                        size='small'
                                        label="Applies To"
                                        defaultValue={appliesToTypes[0]}
                                        onChange={(e) => {
                                            setAppliesTo(e.target.value);
                                        }}
                                        id="operator"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    >
                                        <MenuItem value={appliesToTypes[0]}>Specific Products</MenuItem>
                                        <MenuItem value={appliesToTypes[1]}>Specific Collections</MenuItem>
                                        <MenuItem value={appliesToTypes[2]}>Specific Brands</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {appliesTo === appliesToTypes[0] && <div style={{ margin: '15px 15px' }}>
                                <InfinityScrollAutoComplete 
                                    endpoint={'/scheme/products'} 
                                    searchTag={'searchText'} 
                                    dataPosition={'products'} 
                                    uniqueKey={'sku'} 
                                    label={'product_name'} 
                                    placeholder={'Search products'}
                                    selectedValue={selectedSku}
                                    setSelectedValue={setSelectedSku}
                                />
                            </div>}
                            {appliesTo === appliesToTypes[1] && <div style={{ margin: '15px 15px' }}>
                                <Autocomplete
                                    multiple
                                    id="collections"
                                    size="small"
                                    disablePortal
                                    options={l3Collections ? l3Collections : [{name:"Loading...",id:0}]}
                                    value={selectedL3s}
                                    onChange={(e, newValue) => setSelectedL3s(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choose l3 collections"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>}
                            {appliesTo === appliesToTypes[2] && <div style={{ margin: '15px 15px' }}>
                                <Autocomplete
                                    multiple
                                    id="brands"
                                    size="small"
                                    disablePortal
                                    options={brands ? brands : [{name:"Loading...",id:0}]}
                                    value={selectedBrands}
                                    onChange={(e, newValue) => setSelectedBrands(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choose Brands"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>}
                        </div>
                    </div>}

                    <div className={Classes.ItemsContainer}>
                        <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                            <TextField
                                id="discountCode"
                                type="discountCode"
                                label="Discount Code"
                                value={discountCode}
                                onChange={(e) => {
                                    setDiscountCode(e.target.value.toUpperCase());
                                }}
                                onKeyDown={preventSpace}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}
                                required
                            />
                            <TextField
                                id="discountMinPrice"
                                type="number"
                                label="Minimum Price"
                                value={discountMinPrice}
                                onChange={(e) => {
                                    setDiscountMinPrice(e.target.value);
                                }}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onKeyDown={preventNegativeValues}
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CurrencyRupeeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Discount Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={discountType}
                                    size='small'
                                    label="Discount Type"
                                    // defaultValue={"PERCENTAGE"}
                                    onChange={(e) => {
                                        setDiscountType(e.target.value);
                                    }}
                                    id="operator"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    required
                                >
                                    <MenuItem value={discountTypes[0]}>Percentage</MenuItem>
                                    <MenuItem value={discountTypes[1]}>Fixed Amount</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                id="discountValue"
                                type="number"
                                label="discountValue"
                                value={discountValue}
                                onChange={(e) => {
                                    setDiscountValue(e.target.value);
                                }}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onKeyDown={preventNegativeValues}
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {discountType === "FIXED_AMOUNT" ? <CurrencyRupeeIcon /> : <PercentIcon />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        {discountType === "PERCENTAGE" &&  <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                            <TextField
                                id="uptoDiscountAmount"
                                type="number"
                                label="Maximum discount amount"
                                value={uptoDiscountAmount}
                                onChange={(e) => {
                                    setUptoDiscountAmount(e.target.value);
                                }}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}
                                inputProps={{ 'aria-label': 'controlled' }}
                                // onKeyDown={preventNegativeValues}
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CurrencyRupeeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>}
                    </div>

                    <div className={Classes.ItemsContainer}>
                        <h6 style={{margin:'5px 15px'}}>Customer eligibility & expire dates</h6>
                        <div style={{margin: '15px 15px' ,padding : '0px 10px', border: '1px solid rgba(184, 186, 191,1)', borderRadius : '6px'}}>
                            <FormControlLabel 
                                control={<Checkbox checked={showCustomerEligibility}  onChange={(e) => setShowCustomerEligibility(e.target.checked)}/>} 
                                label="set specific customers"
                            />
                        </div>

                        {showCustomerEligibility && <div style={{ margin: '15px 15px' }}>
                            <InfinityScrollAutoComplete 
                                endpoint={'/user/admin'} 
                                dataPosition={'users'} 
                                uniqueKey={'phone'} 
                                label={'displayName'} 
                                placeholder={'Search customers'}
                                selectedValue={selectedCustomers}
                                setSelectedValue={setSelectedCustomers}
                            />
                        </div>}

                        <div style={{margin: '15px 15px' ,padding : '0px 10px', border: '1px solid rgba(184, 186, 191,1)', borderRadius : '6px'}}>
                            <FormControlLabel 
                                control={<Checkbox checked={discountSpecificToCustomerRole}
                                onChange={(e) => setDiscountSpecificToCustomerRole(e?.target?.checked)}/>}
                                label="set specific role"
                            />
                        </div>

                        {discountSpecificToCustomerRole && <div style={{ margin: '15px 15px' }}>
                            <InfinityScrollAutoComplete 
                                endpoint={'/user/admin/customerRoles/v2'} 
                                dataPosition={'roles'}
                                shouldOptionValueContainUniqueKey={false}
                                uniqueKey={'_id'} 
                                label={'title'} 
                                placeholder={'Search roles'}
                                selectedValue={selectedRoles}
                                setSelectedValue={setSelectedRoles}
                            />
                        </div>}

                        {/* Expire Date */}
                        <div style={{margin: '15px 15px' ,padding : '0px 10px', border: '1px solid rgba(184, 186, 191,1)', borderRadius : '6px'}}>
                            <FormControlLabel 
                                control={<Checkbox checked={showExpireDate}  onChange={(e) => setShowExpireDate(e.target.checked)}/>} 
                                label="set expire date"
                            />
                        </div>

                        {showExpireDate && <div style={{ display: 'flex', margin: '15px 15px', gap: '8px' }}>
                            <DatePick expireDate={expireDate} setExpireDate={setExpireDate}/>
                        </div>}
                    </div>
                </form>
            </div>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {loading ? <Spinner /> : null}
        </>
    )
}