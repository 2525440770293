import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from 'moment';
import { Autocomplete, Button, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

function DatePick ({handleChange, value}) {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Estimate Date" 
                    sx={{ 
                            "& .MuiOutlinedInput-input": {height: '2.5rem', padding: '0px 14px', fontSize: '14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' }
                    }}
                    inputFormat="YYYY-MM-DD"
                    size="small"
                    format="YYYY-MM-DD"
                    value={value}
                    onChange={(newValue) => {
                        handleChange(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                    }} 
                    minDate={dayjs(Date.now())}
                />
            </LocalizationProvider>
        </div>
    )
}

const InventoriesWithPurchaseOrderDetails = () => {
    const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');

    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [locationCode, setLocationCode] = useState('');
    const [locations, setLocations] = useState([]);
    const [searchParams] = useSearchParams();
    const searchData = searchParams.get('searchData');
    const [reRender, setReRender] = useState(false);


    const updateRow = (updateData, id) => {
        let index = data?.inventories?.findIndex(e => e.lineItemId === id);

        console.log(index, id, updateData);

            if (index >= 0) {
                setData((prev) => {
                    const newData = {...prev};
                    newData.inventories[index] = {...newData.inventories[index], ...updateData};

                    return newData;
                })
            }
    }

    const updateEstimateDate = (date, lineItemId) => {
        axiosInstance.put(`/inventory/updateEstimateDate/${lineItemId}`, {estimate_date: date})
        .then(res => {
            updateRow(res.data.data, lineItemId);

            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "success",
                    message: res?.data?.message ?? "Successfully updated"
                }
            });
            setReRender(!reRender);
        })
        .catch(err => {
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "error",
                    message: err?.response?.data?.message ?? "Something went wrong"
                }
            });
        })
    }

    const generateReport = async () => {
        const newReportName = 'Inventory Insights';
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        const params = {
            uploadFileName : fileName,
            reportName : newReportName,
            locationCode : locationCode
        };
    
        await axiosInstance
            .get(`/analytics/reports/inventoryInsights`, { params })
            .then((res) => {
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: res?.data?.message, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    };

    const dueDifferenceDays = (date) => {
        const estimateDate = new Date(date);
        const currentDate = new Date();
    
        // Calculate the time difference in milliseconds
        const timeDifference = estimateDate.getTime() - currentDate.getTime();
    
        // Convert the time difference to days
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Rounding up the difference
    
        if (daysDifference >= 0) {
            return (
                <p style={{ color: 'green' }}>
                    {`${daysDifference} ${daysDifference > 1 ? 'days' : 'day'}`}
                </p>
            );
        } else {
            return (
                <p style={{ color: 'red' }}>
                    {`${Math.abs(daysDifference)} ${Math.abs(daysDifference) > 1 ? 'days' : 'day'} overdue`}
                </p>
            );
        }
    };
    

    const columns = [
    {
        field: 'sku_code',
        headerName: 'SKU Code',
        flex:2,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    {
        field: 'product_name',
        headerName: 'Product Name',
        flex:2,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'locationCode', 
        headerName: 'Location Code', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'purchaseOrderName', 
        headerName: 'Purchase Order Name', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'dueDays', 
        headerName: 'Due Days', 
        flex:.75,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        filterable : false,
        renderCell: (params) => {
            return params?.row?.estimate_date ? dueDifferenceDays(params?.row?.estimate_date)  : '--'
        }
    },
    { 
        field: 'openQty', 
        headerName: 'Confirm Quantity', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'quantity', 
        headerName: 'Purchase QTY', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'discountedTotal', 
        headerName: 'Total Price', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return `₹${params.value}`
        }
    },
    { 
        field: 'estimate_date', 
        headerName: 'Estimate date', 
        flex:1.5,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return <div onClick={(event) => { event.stopPropagation() }}>
                    <DatePick
                        value={params?.row?.estimate_date ? dayjs(params?.row?.estimate_date) : null}
                        handleChange={(value) => {
                            console.log(value, params);
                            updateEstimateDate(value, params.row.lineItemId)
                        }}
                    />
                </div>
        }
    },
    { 
        field: 'estimate_date_updated_by', 
        headerName: 'Updated By', 
        flex:1.5,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
];

const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations`)
            .then((res) => {
                const locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                }
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchLocation();
    },[])

    return (
        <Fragment>
                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

                <div style={{
                    margin: '8px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: '24px'
                }}>
                    <div>
                    <Button variant='contained' onClick={generateReport}>Export Report</Button>
                </div>

                    <div style={{width: '200px'}}>
                        <Autocomplete
                            size='small'
                            disablePortal
                            id="locationCode"
                            name="locationCode"
                            options={locations}
                            renderInput={(params) => <TextField 
                                {...params} label="Location Code"
                                onBlur={(event) => {setLocationCode(event.target.value)}}
                            />}
                        />
                    </div>
                </div>
            
                <div>
                    <div className={Classes.MuiTable}>
                        <Table 
                            columns={columns} 
                            dataPosition={"inventories"} 
                            endpoint={`/inventory/getInventoriesWithPurchaseOrders`} 
                            rowId={'lineItemId'}
                            checkBoxSelection={false}
                            tabName={'Inventories With Purchase Order Details'}
                            setData={setData}
                            data={data}
                            createdByFilter={false}
                            isReactDispatch={true}
                            locationCode={locationCode}
                            searchData={searchData}
                            reRender={reRender}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={columns} 
                            dataPosition={"inventories"} 
                            endpoint={`/inventory/getInventoriesWithPurchaseOrders`} 
                            rowId={'inventory_id'}
                            searchData={searchData}
                        />
                    </div>
                </div>
        </Fragment>
    )
}
export default InventoriesWithPurchaseOrderDetails;