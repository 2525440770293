import React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import { TextField } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateParchiPeople = (props) => {
    const { Id, open, closeDialog } = props;

    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const getParchiPeople = async () => {
        setLoading(true);

        axiosInstance.get(`/pos/parchiPerson/${Id}`)
        .then(res => {
            setData(res.data.data);
        })
        .catch(err => {
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "error",
                    message: err?.response?.data?.message ?? "Something went wrong"
                }
            })
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (open) {
            getParchiPeople();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, Id]);

    const addParchiPerson = async (data) => {
        setLoading(true);

        axiosInstance.post(`/pos/parchiPerson/${Id}`, data)
        .then(res => {
            setData(prev => {
                return [res.data.data, ...prev];
            });

             setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "success",
                    message: res?.data?.message ?? "Successfully Added Parchi person"
                }
            })
        })
        .catch(err => {
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "error",
                    message: err?.response?.data?.message ?? "Something went wrong"
                }
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: e.target['name'].value,
            number: e.target['number'].value
        }

        await addParchiPerson(data);

        e.target.reset();
    }

    const deleteParchiPerson = async (index) => {
        setLoading(true);

        const parchiPerson = data[index];

        axiosInstance.delete(`/pos/parchiPerson/${Id}/${parchiPerson.id}`)
        .then(res => {
            setData(prev => {
                const array = [...prev];
                array.splice(index, 1);

                return array;
            });

            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "success",
                    message: res?.data?.message ?? "Successfully Deleted Parchi person"
                }
            })
        })
        .catch(err => {
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "error",
                    message: err?.response?.data?.message ?? "Something went wrong"
                }
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const updateParchiPerson = async (e, index) => {
        e.preventDefault();
        setLoading(true);

        const parchiPerson = data[index];
        const body = {
            name: e.target['name'].value,
            number: e.target['number'].value
        }

        axiosInstance.put(`/pos/parchiPerson/${Id}/${parchiPerson.id}`, body)
        .then(res => {
            setData(prev => {
                const array = [...prev];
                array[index] = res.data.data;

                return array;
            });

            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "success",
                    message: res?.data?.message ?? "Successfully Updated Parchi person"
                }
            })
        })
        .catch(err => {
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "error",
                    message: err?.response?.data?.message ?? "Something went wrong"
                }
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeDialog}
                fullWidth={true}
                sx={{'& .MuiDialog-paper': {maxWidth:'775px'}}}
            >
                <DialogTitle>Update Parchi People</DialogTitle>

                <div style={{
                    minHeight: '50vh',
                    padding: '20px',
                }}>
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <TextField
                                label={'Name'}
                                placeholder={'Enter Parchi Person Name'}
                                required={true}
                                name={'name'}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '32%' }}
                                type='text'
                            />
                            <TextField
                                    label={'Parchi Person Number'}
                                    placeholder={'Enter Parchi Person Number'}
                                    required={true}
                                    name={'number'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    size="small"
                                    type='number'
                                />

                            <div style={{ width: '32%' }}>
                                <Button type='submit' variant='contained' color='success'>ADD</Button>
                            </div>
                        </div>
                    </form>

                    <div style={{
                        marginTop: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        {data.map((e, index) => {
                            return <form style={{ display: 'flex', gap: '20px' }} key={e.id + " Parchi Person"} onSubmit={(event) => {
                                    event.preventDefault()
                                    updateParchiPerson(event, index)
                                }}>
                                <TextField
                                    placeholder={'Enter Parchi Person Name'}
                                    required={true}
                                    key={e.id + " Parchi Person Name"}
                                    name={'name'}
                                    autoComplete='off'
                                    size="small"
                                    sx={{ width: '32%' }}
                                    type='text'
                                    defaultValue={e.name}
                                />
                                <TextField
                                    placeholder={'Enter Parchi Person Number'}
                                    required={true}
                                    key={e.id + " Parchi Person Number"}
                                    name={'number'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    size="small"
                                    type='number'
                                    defaultValue={e.number}
                                />

                                <div style={{ width: '32%', display: 'flex', gap: '10px' }}>
                                    <Button type='submit' variant='contained' color='success' size='small'>UPDATE</Button>
                                    <Button type='button' variant='contained' color='error' size='small' onClick={() => {
                                        deleteParchiPerson(index)
                                    }}>DELETE</Button>
                                </div>
                            </form>
                        })}
                    </div>
                </div>

                {loading ? <Spinner /> : null}
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}

export default UpdateParchiPeople;