import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedReturns: [],
};

const returnsSlice = createSlice({
	name: 'returns',
	initialState: initialState,
	reducers: {
		setReturns: (state, action) => action.payload,
		reset: (state, action) => initialState,
		setReturn: (state, action) => {
			const returnn = action.payload;
			const index = state.fetchedReturns.findIndex((r) => r._id === returnn._id);
			if (index !== -1) {
				state.fetchedReturns[index] = returnn;
			}
		},
	},
});

export const { setReturns, reset, setReturn } = returnsSlice.actions;

export default returnsSlice.reducer;
