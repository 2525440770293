import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "./css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../utility/axios-instance';


const ShippingPopUp = (props) => {

    const {
        shippingModal,
        hideShippingPopup,
        productDetails,
        overAllDiscount,
        setProduct,
        shippingPrice,
        setShippingPrice,
        previousTarget,
        setPreviousTarget,
        shippingPriceType,
        shippingAmount,
        setShippingAmount,
        setShippingPriceType,
        setLoadingPurchaseOrderCalculationServiceState
    } = props;

    const handleChange = (event) => {
        if (shippingPriceType.length !== 0) {
            const radio = document.getElementById(shippingPriceType[0].fieldName);
            radio.checked = false;
            if (event.target.checked) {
                if (event.target.id === 'custom') {
                    setShippingPriceType([{ fieldName: 'custom' }]);
                    setPreviousTarget(['custom']);
                    setShippingAmount(true);
                } else {
                    setShippingPriceType([{ fieldName: 'freeShipping' }])
                    setPreviousTarget(['freeShipping']);
                    setShippingAmount(false);
                }
            }
        } else {
            if (event.target.checked) {
                if (event.target.id === 'custom') {
                    setShippingPriceType([{ fieldName: 'custom' }])
                    setPreviousTarget(['custom']);
                    setShippingAmount(true);
                } else {
                    setShippingPriceType([{ fieldName: 'freeShipping' }])
                    setPreviousTarget(['freeShipping']);
                    setShippingAmount(false);
                }
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setPreviousTarget([])

        hideShippingPopup();

        let shipping = {}
        if (previousTarget[0] === 'freeShipping') {
            shipping = {
                price: '0',
                title: 'Free-Shipping'
            }
            setShippingAmount(false);
        } else {
            if (event.target.price.value) {
                shipping = {
                    price: event.target.price.value,
                    title: event.target.rateName.value,
                }
                setShippingAmount(true);
            } else {
                if (event.target.rateName.value) {
                    shipping = {
                        price: "0.0",
                        title: event.target.rateName.value,
                    }
                } else {
                    shipping = {
                        price: '0.0',
                        title: 'Free-Shipping'
                    }
                }
            }
        }

        const variantLineItems = productDetails.map(obj => {
            const { variantId, ...rest } = obj;
            return { productId: variantId, ...rest };
        });

        let data = {}
        if (overAllDiscount.length !== 0) {
            data = {
                lineItems: variantLineItems,
                overallDiscount: overAllDiscount[0],
                shippingLine: shipping
            }
        } else {
            data = {
                lineItems: variantLineItems,
                shippingLine: shipping
            }
        }

        setLoadingPurchaseOrderCalculationServiceState(true);
        const endpoint = '/calculate/purchaseOrder'
        axiosInstance
            .post(endpoint, { ...data })
            .then((res) => {
                setProduct(res.data);
            })
            .catch((error) => {
                const errorMessage = error.response.data.message;
                alert(errorMessage || 'An error occurred while calculating the purchase order');
            })
            .finally(() => {
                let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
                calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
                if (calculatePurchaseOrderRoutePendingCount === 0) {
                    setLoadingPurchaseOrderCalculationServiceState(false);
                }
            })

        setShippingPrice([shipping])

    }


    return (
        <Fragment>
            {shippingModal && (
                <div className={Classes.modal} style={{ bottom: '26%', top: '24%' }}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Add shipping</p>
                            <div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{
                                        fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideShippingPopup}
                                />
                            </div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} method='post'>
                        <div style={{ padding: '30px 20px', height: '190px' }}>
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                {shippingPriceType.length === 0 ? (
                                    <div>
                                        <input
                                            type='radio'
                                            id='freeShipping'
                                            name='freeShipping'
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <input
                                            type='radio'
                                            id='freeShipping'
                                            name='freeShipping'
                                            onChange={(e) => handleChange(e)}
                                            defaultChecked={shippingPriceType[0].fieldName === 'freeShipping' && true}
                                        />
                                    </div>
                                )}

                                <div style={{ marginLeft: '5px' }}>
                                    <p>Free shipping</p>
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                {shippingPriceType.length === 0 ? (
                                    <div>
                                        <input
                                            type='radio'
                                            id='custom'
                                            name='custom'
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <input
                                            type='radio'
                                            id='custom'
                                            name='custom'
                                            onChange={(e) => handleChange(e)}
                                            defaultChecked={shippingPriceType[0].fieldName === 'custom' && true}
                                        />
                                    </div>
                                )}

                                <div style={{ marginLeft: '5px' }}>
                                    <p>Custom</p>
                                </div>
                            </div>

                            {shippingAmount && (
                                <div className={Classes.RateBox} id='rate'>
                                    <div style={{ width: '50%' }}>
                                        <p>Rate Name</p>
                                        <input
                                            type='text'
                                            id='rateName'
                                            autoComplete="off"
                                            key={shippingPrice.length !== 0 ? shippingPrice[0].title : ''}
                                            defaultValue={shippingPrice.length !== 0 ? shippingPrice[0].title : ''}
                                            style={{
                                                width: '99%',
                                                padding: '5px',
                                                borderRadius: 4,
                                                border: '1px solid #b0aaaa'
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginLeft: '5px', width: '50%' }}>
                                        <p>Rate Value</p>
                                        <input
                                            type='text'
                                            id='price'
                                            autoComplete="off"
                                            key={shippingPrice.length !== 0 ? shippingPrice[0].price : '0.00'}
                                            defaultValue={shippingPrice.length !== 0 ? shippingPrice[0].price : '0.00'}
                                            style={{
                                                width: '99%',
                                                padding: '5px',
                                                borderRadius: 4,
                                                border: '1px solid #b0aaaa'
                                            }}
                                        // onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>

                        <div className={Classes.Button} style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            borderTop: '1px solid #d2d5d8',
                            padding: '15px 20px'
                        }}>
                            <button id='btn' type="submit"
                                style={{
                                    backgroundColor: '#006E52',
                                    color: '#fff', fontSize: '15px',
                                    padding: '6px 15px'
                                }}>
                                Apply
                            </button>
                        </div>
                    </form>

                </div>
            )}
        </Fragment>
    );
}


export default ShippingPopUp;