import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedInventories: [],
};

const inventoryViewBySkuSlice = createSlice({
	name: 'inventoryViewBySku',
	initialState: initialState,
	reducers: {
		setInventoryViewBySku: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setInventoryViewBySku, reset } = inventoryViewBySkuSlice.actions;

export default inventoryViewBySkuSlice.reducer;