
// sectionId is the DOM id, it print child container of provided sectionId
const printPageSection = (sectionId) =>{
    const sectionContent = document?.getElementById(sectionId)?.innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write(sectionContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.onafterprint = function () {
        printWindow.onfocus = function () {
            console.log("window is closing ...")
            printWindow.close();
        };
    };      
}

export default printPageSection;