import React, {Fragment, useState} from "react";
import Classes from "../createOrders/css/Modal.module.css";
import { TextField } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import { useNavigate } from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Spinner from '../../../components/spinner';

const DeleteOrderConfirmPopup = (props)=> {
    const { showDeleteConfirmPopup, hideDeleteOrderConfirmPopup, draftOrderId } = props;
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const navigate = useNavigate();
    
	const handleDeleteOrder = async (event) => {
        event.preventDefault();
		setLoading(true);

		await axiosInstance
			.delete(`/orders/admin/draftOrderDelete/${draftOrderId}?deleteRemark=${event.target.reason.value}`)
			.then((res) => {
                setLoading(false);
                hideDeleteOrderConfirmPopup();
				const successMessage = 'Draft Order Deleted Successfully'
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
                setTimeout(() => { navigate('/draftOrdersNew') }, 1000);
			}).catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
				setLoading(false);
			})
	}
    return (
        <Fragment>
            {showDeleteConfirmPopup && (
                <form 
                    action="" method="post" onSubmit={handleDeleteOrder}
                    className={Classes.modal} style={{ bottom:'34%',top:'14%' }}
                >
                    <p className={Classes.BoxContent} style={{fontWeight:600}}>Delete Order</p>
                    <div style={{padding:20}}>
                        <TextField 
                            id='reason'
                            name="reason"
                            label="Reason"
                            multiline
                            rows={4}
                            autoComplete='off'
                            required
                            sx={{ width: '100%',"& .MuiOutlinedInput-input": {padding:"7.5px 0px" }}}
                        />
                    </div>

                    <div className={Classes.DialogBtnBox} style={{bottom:'34%'}}>
                        <button 
                            type="button" 
                            className={Classes.UpdateSalesUserOfCustomer}
                            onClick={(e) => hideDeleteOrderConfirmPopup(e)}
                        >
                            CANCEL
                        </button>

                        <button 
                            id='btn' 
                            type="submit" 
                            className={Classes.UpdateSalesUserOfCustomer}
                            // onClick={(e) => hideDeleteOrderConfirmPopup(e,draftOrderId)}
                        >
                            DELETE
                        </button>
                    </div>
                </form>
            )}

            {loading ? <Spinner /> : null}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}

export default DeleteOrderConfirmPopup;