import React, {Fragment, useState} from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField } from '@mui/material';

const GSTNoPopup = (props)=> {
    const [gstNO,setGstNO] = useState();
    const {newGstNo,hideGstNoPopUp,showCustomerDetail,setCustomerDetail,setGstNumber} = props;


    const handleSubmit = (event) => {
        event.preventDefault();
        hideGstNoPopUp();
        if(event.target.gst.value){
            const newGstNum = event.target.gst.value;
            axiosInstance
                .post(`/user/admin/${showCustomerDetail[0]?._id}`,{
                    gstNumbers: {add: [event.target.gst.value]}
                })
                .then((res) =>{
                    setCustomerDetail([res.data.data])
                    setGstNumber(prev =>[...prev, newGstNum])
                });
        }
    }

    const handleGstField = (event) => {
        const result=  event.target.value.replace(/[^\w\s]/gi, "")
        setGstNO(result);
    }

    const handleValidGst = (event) =>{
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if(event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    return (
        <Fragment>
            {newGstNo && (
                <div 
                    className={Classes.modal} 
                    style={{bottom:'40%',top:'16%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>GST Number</p>
                    </div>
                    
                    <form method ='post' onSubmit={handleSubmit} id='form'>
                        <div 
                            style={{padding:'40px 20px', height:'180px'}} 
                            className={Classes.GstNO}
                        >
                            <TextField
                                required
                                id='gst'
                                name="gst"
                                label="GST Number"
                                placeholder='Add New GST Number'
                                autoComplete='off'
                                minLength={15}
                                maxLength={15}
                                inputProps={{ maxLength: 15 }}
                                onBlur={handleValidGst}
                                onChange={(event) => setGstNO(event.target.value)}
                                sx={{ width: '100%',"& label": {top:gstNO ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                            />

                            <div 
                                id='isValidGst'
                                className={Classes.IsValidGst}
                            >
                                <p>Please fill valid Gst Number</p>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'40%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideGstNoPopUp}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>
                    </form>
                    
                </div>
            )}
        </Fragment>
    );
}


export default GSTNoPopup;