import React from 'react';
import FormDialog from '../../../components/FormDialog/FormDialog';
import HistoryIcon from '@mui/icons-material/History';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import moment from 'moment';

function CollectedPaymentHistory({ paymentHistory }) {
    return (
        <div>
            <FormDialog
                dialogTitle="Collected Payment History"
                dialogActionType="confirmation"
                openDialogButtonName={
                    <Tooltip title="Collected Payment History">
                        <HistoryIcon style={{ color: '#6c757d' }} />
                    </Tooltip>
                }
                onConfirmation={() => { return null }}
            >
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Collected Amount</TableCell>
                                <TableCell>Delivery Rider</TableCell>
                                <TableCell>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentHistory.map((payment) => (
                                <TableRow key={payment?.id}>
                                    <TableCell>₹ {payment.paymentAmount}</TableCell>
                                    <TableCell>{payment.deliveryRider}</TableCell>
                                    <TableCell>{moment(payment?.createdAt).format('MMMM Do YYYY, h:mm a')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </FormDialog>
        </div>
    );
}

export default CollectedPaymentHistory;
