import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedFranchises: [],
};

const franchisesSlice = createSlice({
	name: 'franchises',
	initialState: initialState,
	reducers: {
		setFranchises: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setFranchises, reset } = franchisesSlice.actions;

export default franchisesSlice.reducer;