import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from '../pages/home/orders/css/order.module.css';
import TextField from '@mui/material/TextField';


const NewPagination = (props) => {
	const {
		totalPage,
		fetchData
	} = props;

	const navigate = useNavigate();

	const createURLAndNavigate = (page) => {
		let q = '?';

		if (page && !isNaN(page)) {
			q += `page=${page}`;
		}

		navigate(q);
		fetchData(page);
	};

	const handleDirectPageMove = (e) => {
		let page = e.target.value ? parseInt(e.target.value) : 1;
		if (page > totalPage?.maxPage) {
			alert(`Max page is ${totalPage?.maxPage}`);
			return
		} else if (page < 1) {
			alert(`Min page is 1`);
			return
		}
		createURLAndNavigate(page);
		e.target.value = '';
	}

	const handleEdit = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			event.target.blur();
			return;
		}
	};

	return (
		<Fragment>
			{totalPage?.length !== 0 ? (
				<div style={{ marginTop: 20 }}>
					{totalPage ?
						<div>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', margin:'0px 10px' }}>
								<div className={Classes.PaginateBtn}>
									<button
										type='button'
										className='px-3 py-2'
										disabled={totalPage?.page <= 1}
										style={{
											border: 0,
											borderTopLeftRadius: "0.5rem",
											borderBottomLeftRadius: "0.5rem",
											backgroundColor: totalPage.page <= 1 && 'rgb(237 237 237)'
										}}
										onClick={() => { createURLAndNavigate(totalPage?.page - 1) }}
									>
										Prev
									</button>

									{Array.from({ length: totalPage?.maxPage }, (_, i) => i + 1).map((pageNo) => {
										return (((pageNo === 1) || (pageNo === totalPage?.maxPage)) ||
											((pageNo >= totalPage?.page - 2 && pageNo <= totalPage?.page) ||
												(pageNo <= totalPage?.page + 2 && pageNo >= totalPage?.page))) ?
											<button
												key={'pagination' + pageNo}
												className='px-3 py-2'
												disabled={totalPage.page === pageNo}
												style={{ border: totalPage?.page === pageNo ? '1px solid black' : 0 }}
												onClick={() => { createURLAndNavigate(pageNo) }}
											>
												{pageNo}
											</button> :
											((pageNo === totalPage?.page + 3 && pageNo < totalPage?.maxPage) ||
												(pageNo === totalPage?.page - 3 && pageNo > 1)) ?
												<button
													key={'pagination' + pageNo}
													type='button'
													className='px-3 py-2'
													disabled
												>. . .</button>
												: null
									})}

									<button
										type='button'
										className='px-3 py-2'
										disabled={totalPage?.page >= totalPage?.maxPage}
										onClick={() => { createURLAndNavigate(totalPage?.page + 1) }}
										style={{
											border: 0,
											backgroundColor: totalPage?.page >= totalPage?.maxPage && "rgb(237 237 237)"
										}}
									>
										Next
									</button>
								</div>
								<TextField type='number' onKeyDown={(e) => handleEdit(e)} onBlur={(e) => handleDirectPageMove(e)} sx={{ width: "7%" }} size='small' id="outlined-basic" label="Page" variant="outlined" />
							</div>
						</div>
						: null}
				</div>
			) : null}
		</Fragment>
	);
};

export default NewPagination;
