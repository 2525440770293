import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	tab1: {
		name: null,
		url: null,
		multiTabName: null
	},
	tab2: {
		name: null,
		url: null,
		multiTabName: null
	},
	tab3: {
		name: null,
		url: null,
		multiTabName: null
	},
	tab4: {
		name: null,
		url: null,
		multiTabName: null
	},
};

const pinTabSlice = createSlice({
	name: 'pinTab',
	initialState: initialState,
	reducers: {
		setPinTab: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setPinTab, reset } = pinTabSlice.actions;

export default pinTabSlice.reducer;