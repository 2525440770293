import React, {Fragment,useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';


const RemoveGstPopup = (props)=> {
    const [selectedGstNo,setSelectedGstNo] = useState([]);
    const {
        removeGstModal,
        hideRemoveGstPopup,
        showCustomerDetail,
        setCustomerDetail,
        gstNumber,
        setGstNumber,
        type
    } = props;
    
    const handleSubmit = (event) =>{
        event.preventDefault();
        if(selectedGstNo.length === 0) {
            // document.getElementById('selectGstMsg').style.display = 'block';
        } else {
            const arr = []
            const GstArray = [];
            for (let gst of selectedGstNo) {
                if (gst.id === gstNumber) {
                    setGstNumber(arr);
                }
                GstArray.push(gst.id)
            }


            if (type === "Vendor") {
                axiosInstance
                    .put(`/vendor/updateVendor/${showCustomerDetail[0].vendorCode}`,{
                        vendorGstNumber: {remove: GstArray}
                    })
                    .then((res) =>{
                        hideRemoveGstPopup();
                        setCustomerDetail([res.data.data])
                    });
                } else {
                    axiosInstance
                    .put(`/location/updateLocation/${showCustomerDetail[0].locationCode}`,{
                        locationGstNumber: {remove: GstArray}
                    })
                    .then((res) =>{
                        hideRemoveGstPopup();
                        setCustomerDetail([res.data.data])
                    });
            }
        }
    }

    const handleGstNo = (event) => {
        if (event.target.checked) {
            // document.getElementById('selectGstMsg').style.display = 'none';
            const array = selectedGstNo;
            array.push({id:event.target.id})
            setSelectedGstNo(array)
        } else {
            const array = selectedGstNo;
            const store = array.filter((gstNo) => gstNo.id !== event.target.id);
            setSelectedGstNo(store)
        }
    }

    return (
        <Fragment>
            {removeGstModal && (
                <div className={Classes.modal} style={{bottom:'40%',top:'14%'}}>
                    <div className={Classes.BoxContent}>
                        <p>Delete GST Number</p>
                    </div>
                    
                    <form method='post' onSubmit={handleSubmit}>
                        <div style={{padding:'10px 20px',overflowY:'auto',height:200}}>
                            {showCustomerDetail?.length !==0 && (
                                <div>
                                {type === "Vendor" ? (
                                    <>
                                    {showCustomerDetail[0]?.vendorGstNumber?.length !== 0 && (
                                        <>
                                            {showCustomerDetail[0]?.vendorGstNumber?.map((gst,index) => (
                                                <div key={index} style={{display:'flex'}}>
                                                    <input 
                                                        type="checkbox" 
                                                        name="" 
                                                        id={gst}
                                                        onChange={handleGstNo} 
                                                    />
                                                    <p style={{marginLeft:5}}>{gst}</p>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    </>
                                ) : (
                                    <>
                                    {showCustomerDetail[0]?.locationGstNumber?.length !== 0 && (
                                        <>
                                            {showCustomerDetail[0]?.locationGstNumber?.map((gst,index) => (
                                                <div key={index} style={{display:'flex'}}>
                                                    <input 
                                                        type="checkbox" 
                                                        name="" 
                                                        id={gst}
                                                        onChange={handleGstNo} 
                                                    />
                                                    <p style={{marginLeft:5}}>{gst}</p>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    </>
                                )}

                                </div>
                            )}
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'40%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideRemoveGstPopup}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                DELETE
                            </button>
                        </div>
                    </form>

                </div>
            )}
        </Fragment>
    );
}


export default RemoveGstPopup;