import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  CircularProgress,
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  Slide,
  Divider,
  Typography,
} from "@mui/material";
import ProductCarousel from "./previewProductRange";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../../../../utility/axios-instance";
import { styled } from "@mui/system";
import SnackBar from "../../../../components/SnackBar";
import MediaPreview from "../../../../components/mediaPreview";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});
const CreateProductRange = ({ open, handleClose, setReRender }) => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [imageInputType, setImageInputType] = useState("URL");
  const [imageURL, setImageURL] = useState(""); //useRef
  const [imageFile, setImageFile] = useState(null); //useRef
  const [titleColor, setTitleColor] = useState("#000000");
  const [isActive, setIsActive] = useState(true);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const imagePreviewData = useRef({ title: "", titleColor: "", image: "" });
  const [imagePreview, setImagePreview] = useState("");
  const [objectURL, setObjectURL] = useState("");
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    let data = { title, link, titleColor, active: isActive };
    if (imageInputType === "URL") {
      data = { ...data, imageURL };
    } else {
      formData.append("imageFile", imageFile);
    }
    formData.append("data", JSON.stringify(data));
    
    setLoading(true);
    axiosInstance
      .post("/adminRoutes/productRange", formData)
      .then((res) => {
        handleClose();
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
        setReRender((prev) => !prev);
      })
      .catch((err) => {
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePreviewURL = () => {
    if (imageInputType === "URL") {
      return imageURL;
    } else {
      if (imageFile) {
        const url = URL.createObjectURL(imageFile);
        setObjectURL(url);
        return url;
      }
    }
  };
  const handlePreviewClose = () => {
    if (objectURL) {
      URL.revokeObjectURL(objectURL);
    }

    setPreviewToggle(false);
  };
  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="upload-dialog-title"
      >
        <Grid container>
          <Grid item xs={6}>
            <DialogTitle id="upload-dialog-title">Upload Image</DialogTitle>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            marginLeft={-1}
          >
            <Button
              variant="contained"
              disabled={!imagePreview}
              onClick={() => setPreviewToggle(true)}
              startIcon={<VisibilityIcon />}
            >
              Preview{" "}
            </Button>
          </Grid>
        </Grid>
        <StyledForm onSubmit={handleSubmit}>
          <StyledDialogContent>
            <Divider
              component="div"
              role="presentation"
              style={{ marginBottom: "2rem" }}
            >
              <Typography variant="subtitle1">Add Image Details</Typography>
            </Divider>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setTitle(e.target.value)}
                  margin="dense"
                  required
                  label="Title"
                  type="text"
                  variant="outlined"
                  value={title}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TextField
                      margin="dense"
                      required
                      label="Title Color"
                      type="text"
                      variant="outlined"
                      value={titleColor}
                      autoComplete="off"
                      onChange={(e) => setTitleColor(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="dense"
                      required
                      label="Title Color"
                      type="color"
                      value={titleColor}
                      onChange={(e) => setTitleColor(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="Link"
                  type="text"
                  variant="outlined"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  fullWidth
                  helperText="Enter the redirect link for when this image is clicked."
                />
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={imageInputType}
                onChange={(e) => {
                  if (imageInputType === "FILE") {
                    setImageFile(null);
                  }
                  setImageInputType(e.target.value);
                }}
              >
                <FormControlLabel value="URL" control={<Radio />} label="URL" />
                <FormControlLabel
                  value="FILE"
                  control={<Radio />}
                  label="File Upload"
                />
              </RadioGroup>
            </FormControl>
            {imageInputType === "URL" ? (
              <TextField
                required
                label="Image URL"
                type="url"
                value={imageURL}
                onChange={(e) => {
                  setImagePreview(e.target.value);
                  setImageURL(e.target.value);
                }}
                fullWidth
              />
            ) : (
              <TextField
                required
                type="file"
                onChange={(e) => {
                  setImagePreview(e.target.files[0]);
                  setImageFile(e.target.files[0]);
                }}
                inputProps={{ accept: "image/*" }}
                fullWidth
              />
            )}
          </StyledDialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={6} display="flex" justifyContent="center">
                {/* <Button
                  
                  variant="contained"
                  onClick={() => setPreviewToggle(true)}
                >
                  Preview{" "}
                </Button> */}
              </Grid>
            </Grid>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </DialogActions>
        </StyledForm>
      </StyledDialog>
      {/* {previewToggle && (
        <ProductCarousel
          image={imagePreviewData.current}
          previewState={previewToggle}
          handlePreviewState={setPreviewToggle}
        />
      )} */}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type="img"
          handleUrl={handlePreviewURL}
        />
      )}
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default CreateProductRange;
