import React, {Fragment, useEffect, useState} from "react";
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';
import SnackBar from '../../../components/SnackBar';
import Button from '@mui/material/Button';

const PackOrder = (props)=> {
    const [loading,setLoading] = useState(false);
    const [user,setUser] = useState('');
    const [users,setUsers] = useState([]);
    const [rider,setRider] = useState('');
    const [riders,setRiders] = useState([]);
    const [boxes,setBoxes] = useState('');
    const [weight,setWeight] = useState('');
    const [packaging,setPackaging] = useState('');
    const [packagingType,setPackagingType] = useState([]);
    const [length,setLength] = useState('');
    const [breadth,setBreadth] = useState('');
    const [height,setHeight] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [updateDimension,setUpdateDimension] = useState(false);
    const [weightUnit,setWeightUnit] = useState('');
    const [unit,setUnit] = useState('');
    const [lenBreHeight,setLenBreHeight] = useState('');

    const { 
        openPack,
        handlePackClose,
        pack,
        setReRender,
        newDimension,
        setNewDimension
    } = props;
    
    useEffect(() => {
        axiosInstance
            .get(`/admin/allPiAdmin?type=packer&service=packer&locationCode=${pack[0]?.locationCode}`)
            .then((res) => {
                setUsers(res?.data?.data);
            });
    }, [pack]);

    useEffect(() => {
        axiosInstance
            .get('/packing/fetchDimensions')
            .then((res) => {
                setPackagingType(res.data.data)
            })
    },[updateDimension])

    const handleSubmit = (event)=> {
        setLoading(true);
        event.preventDefault();

        const data = {
            "packOrderDetails": [{
                "picklist_number": pack?.length !== 0 && pack[0]?.pickListNo,
                "deliveryNumber": pack?.length !== 0 && pack[0]?.deliveryNo,
                "packer": event.target.packer.value,
                // "deliveryRider": event.target.rider.value ? event.target.rider.value : undefined,
                "boxes":event.target.boxes.value ? event.target.boxes.value : undefined,
                "length" : length ? length : undefined,
                "breadth": breadth ? breadth : undefined,
                "height": height ? height : undefined,
                "weight":event.target.weight.value ? event.target.weight.value : undefined,
                "lengthUnit": unit ? unit : undefined,
                "weightUnit": event.target.weightUnit.value ? event.target.weightUnit.value : undefined,
                "locationCode":pack?.length !== 0 && pack[0]?.locationCode
            }]
        }
        // setReRender(false);
        axiosInstance
            .post('/packing/packingOrders',{...data})
            .then((res) =>{
                handlePackClose();
                
                const successMessage = `Order Pack Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender((prev) => !prev);
                setRider('');
                setPackaging('');
                setBoxes('');
                setWeight('');
                setLength('');
                setBreadth('');
                setHeight('');
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setReRender((prev) => !prev);
            })
    }

    const handlePackaging = (packaging,id) => {
        setPackaging(packaging);
        axiosInstance
            .get(`/packing/fetchDimension/${id}`)
            .then((res) => {
                const unit = res.data.data.unit;
                const dimension = `${res.data.data.length}${unit} x ${res.data.data.breadth}${unit} x ${res.data.data.height}${unit}`
                setLenBreHeight(dimension)
                setLength(res.data.data.length);
                setBreadth(res.data.data.breadth);
                setHeight(res.data.data.height);
                setUnit(res.data.data.unit);
            })
    }

    const handleNewDimension = (event) => {
        event.preventDefault();

        const units = {
            "CM":"cm",
            "M":"m",
            "Inch":"inch"
        }

        const data ={
            "title":event.target.packaging.value,
            "length" : event.target.length.value,
            "breadth": event.target.breadth.value,
            "height": event.target.height.value,
            "unit": units[event.target.unit.value]
        }

        axiosInstance
            .post(`/packing/createDimension`,{...data})
            .then((res) => {
                setUnit('');
                setUpdateDimension(true);
                setNewDimension(false);
            })
    }

    return (
        <Fragment>
            {openPack && (
                <div 
                    className={Classes.modal} 
                    style={{top:'8%',bottom:'18%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>Picklist No: {pack?.length !== 0 && pack[0]?.pickListNo}</p>
                        <p>Delivery No: {pack?.length !== 0 && pack[0]?.deliveryNo}</p>
                    </div>

                   <div style={{padding:'0px 20px',display:'flex',justifyContent:'end'}}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            style={{textTransform:'capitalize',fontSize:14,padding:'2px 10px'}}
                            onClick={(e) => setNewDimension(true)}
                        >New Dimension</Button>
                   </div>

                    {newDimension && (
                        <div style={{padding:'10px 20px 0px'}}>
                            <form method="post" onSubmit={handleNewDimension} id='form'>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <div style={{width:'36%'}}>
                                        <TextField 
                                            required={true}
                                            id='packaging'
                                            name="packaging"
                                            label="Packaging"
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top: packaging ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>
    
                                    <div style={{width:'12%'}}>
                                        <TextField 
                                            required={true}
                                            id='length'
                                            name="length"
                                            type="number"
                                            label="Length"
                                            autoComplete='off'
                                            InputProps={{ inputProps: {  step: 'any' } }}
                                            sx={{ width: '100%',"& label": {top: length ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>
    
                                    <div style={{width:'12%'}}>
                                        <TextField 
                                            required={true}
                                            id='breadth'
                                            name="breadth"
                                            type="number"
                                            label="Breadth"
                                            autoComplete='off'
                                            InputProps={{ inputProps: {  step: 'any' } }}
                                            sx={{ width: '100%',"& label": {top: breadth ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>
    
                                    <div style={{width:'12%'}}>
                                        <TextField
                                            required={true} 
                                            id='height'
                                            name="height"
                                            type="number"
                                            label="Height"
                                            autoComplete='off'
                                            InputProps={{ inputProps: {  step:'any' } }}
                                            sx={{ width: '100%',"& label": {top: height ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>

                                    <div style={{width:'12%'}}>
                                        <Autocomplete
                                            id="unit"
                                            name="unit"
                                            disablePortal
                                            options={["CM","M","INCH"]}
                                            key='CM'
                                            defaultValue="CM"
                                            sx={{width: '100%',"& label": {top: unit? "0%":"-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'},"& .MuiAutocomplete-clearIndicator":{display:'none'}}}
                                            ListboxProps={{sx: { fontSize: 14 }}}
                                            onChange={(event, newValue) => { setUnit(newValue) }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="UNIT" variant="outlined" required
                                                />
                                            )}
                                        />
                                    </div>
    
                                    <div>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            type='submit'
                                            style={{textTransform:'capitalize',fontSize:14}}
                                        >ADD</Button>
                                    </div>    
                                </div>   
                            </form>
                        </div>
                    )}
                    
                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{padding:20}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        id="packer"
                                        name="packer"
                                        disablePortal
                                        options={users ? users : [{name:"Loading...",id:0}]}
                                        sx={{width: '100%',"& label": {top: user? "0%":"-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        getOptionLabel={(user) => user.name}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        onChange={(event, newValue) => { setUser(newValue?.name) }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Packer"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="packaging"
                                        name="packaging"
                                        options={packagingType ? packagingType : [{name:"Loading...",id:0}]}
                                        getOptionLabel={(packagingType) => packagingType.title}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{width: '100%',"& label": {top: packaging ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => { 
                                            handlePackaging(newValue.title,newValue.id) 
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Packaging"/>}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <TextField 
                                        id='length'
                                        name="length"
                                        // type="number"
                                        label="Length x Breadth x Height"
                                        disabled={true}
                                        key={lenBreHeight || ''}
                                        defaultValue={lenBreHeight || ''}
                                        autoComplete='off'
                                        InputProps={{ inputProps: {  step:'any' }}}
                                        sx={{ width: '100%',"& label": {top: lenBreHeight ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                {/* <div style={{ width:'49%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="rider"
                                        name="rider"
                                        options={riders}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        renderOption={(props, option) => (
                                                <li {...props} key={option.Id}>
                                                {option.label}
                                            </li>
                                            )}
                                        sx={{width: '100%',"& label": {top: rider ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => { 
                                            setRider(newValue.label) 
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Transporting"/>}
                                    />
                                </div> */}
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25,justifyContent:'flex-start'}}>
                                <div style={{ width:'15%',marginRight:15 }}>
                                    <TextField 
                                        id='boxes'
                                        name="boxes"
                                        type="number"
                                        label="Boxes"
                                        autoComplete='off'
                                        onChange={(event) => setBoxes(event.target.value)}
                                        sx={{ width: '100%',"& label": {top: boxes ? "0%" : "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'30%',display:'flex'}}>
                                    <TextField 
                                        // required
                                        type="number"
                                        id='weight'
                                        step="any"
                                        name="weight"
                                        label="Weight"
                                        autoComplete='off'
                                        InputProps={{ inputProps: {  step: 'any' } }}
                                        onChange={(event) => setWeight(event.target.value)}
                                        sx={{ width: '100%',"& label": {top: weight ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />

                                    <div style={{width:'40%'}}>
                                        <Autocomplete
                                            disablePortal
                                            id="weightUnit"
                                            name="weightUnit"
                                            options={["kg","gm"]}
                                            key={weight && "kg"}
                                            defaultValue={weight && "kg"}
                                            ListboxProps={{sx: { fontSize: 14 }}}
                                            sx={{width: '100%',"& label": {top: weight ?"0%":"-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'},"& .MuiAutocomplete-clearIndicator":{display:'none'}}}
                                            onChange={(event, newValue) => { setWeightUnit(newValue)}}
                                            renderInput={(params) => <TextField {...params} label="UNIT" />}
                                        />
                                    </div>
                                </div>
                            </div>       
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'18%'}}>
                            <button 
                                onClick={handlePackClose}
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                PACK
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default PackOrder;