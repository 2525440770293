import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import axiosInstance from "../../../utility/axios-instance";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import Classes from "./css/blog.module.css";
import { useSelector } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField, Button, } from "@mui/material";


const BlogDetail = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { Id } = useParams();
  const [blog, setBlog] = useState({});
  const [status, setStatus] = useState("");
  const editor = useRef(null);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(status);

    axiosInstance
      .post(`/blogs/update/${Id}`, {
        title: title,
        content: content,
        image: imageUrl,
        status: status,
      })
      .then((response) => {
        setMessage(response.data.message);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const handleChange = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    axiosInstance
      .get(`/blogs/${Id}`)
      .then((response) => {
        setBlog(response.data.data.blog);
        setTitle(response.data.data.blog.title);
        setContent(response.data.data.blog.content);
        setImageUrl(response.data.data.blog.image);
        setStatus(response.data.data.blog.status);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className={Classes.AddProductHeader}>
        <div className={Classes.AddProductHeaderContent}>
          <div>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                navigate(-1);
              }}
            >
              <BiArrowBack />
            </button>
          </div>
          <div className="mb-0">
            <strong className={Classes.AddProductBtn}>
              <p>Blog</p>
            </strong>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {message && (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      )}
      <div className={Classes.Box}>
        <form id="form">
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '80%' }}>
              <TextField
                type="text"
                className="form-control"
                id="title"
                label="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                required
                autoComplete='off'
                sx={{ width: '100%', "& label": { top: title ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
              />
            </div>
            <div style={{ width: '20%' }}>
              {auth.user.role === "super-admin" || auth.user.role === "admin" ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    size='small'
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"draft"}>Draft</MenuItem>
                  </Select>
                </FormControl>
              ) : null}
            </div>
          </div>

          <div className="form-group mt-3">
            <label htmlFor="content">Content:</label>
            <JoditEditor
              ref={editor}
              value={content}
              onChange={(newContent) => {
                setContent(newContent);
              }}
            ></JoditEditor>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="imageUrl">Image URL:</label>
            <input
              type="text"
              className="form-control"
              placeholder="https://example.com/image.jpg"
              id="imageUrl"
              value={imageUrl}
              onChange={(event) => setImageUrl(event.target.value)}
              required
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogDetail;
