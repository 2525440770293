import Papa from 'papaparse';

export const convertCsvToJson = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            resolve(result.data);
          },
          error: (error) => {
            reject(new Error('CSV parsing error:', error));
          }
        });
      };

      reader.readAsText(file);
    } catch (error) {
      console.error("Error in convertCsvToJson", error);
      reject(error);
    }
  });
};
