import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField, Chip } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';



const DeliveryRider = (props) => {

    const [riders, setRiders] = useState([]);
    const {
        showPopup,
        hidePopup,
        handleDeliveryDetails,
        deliveryDetails,
        setDeliveryDetails
    } = props;

    const handleSelect = (event, value) => {
        setDeliveryDetails(value);
    };

    useEffect(() => {
        axiosInstance.get('/pgOrder/admin/deliveryRiders')
            .then(res => {
                const ridersName = res?.data?.data?.deliveryRidersName?.map((rider) => rider?.deliveryRider);
                setRiders(ridersName);
            })
            .catch(err => {
                console.log(err);
            })

    }, []);


    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom: '35%', top: '26%', zIndex:'9999' }}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Delivery Rider</p>
                            <div>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{
                                        fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hidePopup}
                                />
                            </div>
                        </div>
                    </div>

                    {riders?.length > 0 && (

                    <div style={{ padding: '30px 20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                            <Autocomplete
                                id="rider"
                                disablePortal
                                options={riders}
                                defaultValue={deliveryDetails}
                                onChange={handleSelect}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Delivery Rider"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    )}

                    <div className={Classes.FooterBox} style={{ gap: '7px' }}>
                        <button
                            type="submit"
                            className={Classes.ApplyButton}
                            style={{ background: '#fff', color: 'black' }}
                            onClick={hidePopup}
                        >
                            Discard
                        </button>
                        <button
                            id='btn'
                            className={Classes.ApplyButton}
                            style={{ background: 'green', color: '#fff' }}
                            onClick={(e) => handleDeliveryDetails(e)}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DeliveryRider;