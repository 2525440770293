import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
const MediaPreview = ({
  open,
  handleClose,
  type,
  handleUrl = () => {},
  mediaURL = "",
}) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    const url = handleUrl();
    setUrl(url);
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Media Preview</DialogTitle>
      <DialogContent>
        {type === "img" ? (
          <img
            src={mediaURL ? mediaURL : url}
            alt="media"
            style={{ maxWidth: "100%", objectFit: "contain" }}
          />
        ) : (
          <div style={{ width: "500px", height: "400px" }}>
            <iframe
              width="100%"
              height="100%"
              src={mediaURL ? mediaURL : url}
              title="video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default MediaPreview;
