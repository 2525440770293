import { Fragment } from "react";
import Table from "../../../components/table/table";
import { setTransactions } from "../../../store/paymentGatewayTransactionsSlice";
import { useSelector } from 'react-redux';

const PaymentGatewayTransactions = (props) => {
  const transactions = useSelector((state) => state.transactions);

  const {text} = props;

  const columns = [
    {
      field: "medium",
      headerName: "MEDIUM",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "medium_id",
      headerName: "MEDIUM ID",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      align: 'center'
    },
    {
      field: "payment_gateway",
      headerName: "PAYMENT GATEWAY",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      align: 'center'
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false
    },
    {
      field: "error_response",
      headerName: "FAILURE REASON",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      align: 'center'
    },
    {
      field: "transaction_payment_mode",
      headerName: "PAYMENT MODE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      align: 'center',
      renderCell: (params) => {
        let v = params.value;

        if (!v && params.row.payment_mode) {
          v = JSON.parse(params.row.payment_mode)?.type;
        }

        return v
      }
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1.8,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
  ];

  return (
    <Fragment>
      <Table
				columns={columns}
				dataPosition={"transactions"}
				endpoint={`/pos/paymentGatewaysTransactions`}
				rowId={'id'}
				checkBoxSelection={false}
				version="version-1"
				// service={'payments'}
				tabName={'Payment Gateway Transactions'}
				setData={setTransactions}
				data={transactions}
				mobileResponsive={true}
				exportEndpoint={'/exports/paymentGatewayTransactions'}
				exportFileName={`Payment Gateway Transactions - ${new Date()}`}
        searchData={text}
			/>
    </Fragment>
  );
};

export default PaymentGatewayTransactions;
