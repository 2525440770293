import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	activities: [],
};

const userActivitiesSlice = createSlice({
	name: 'userActivities',
	initialState: initialState,
	reducers: {
		set: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { set, reset } = userActivitiesSlice.actions;

export default userActivitiesSlice.reducer;