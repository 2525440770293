import { Fragment, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import LocationComponent from '../../../components/createPurchaseOrder/asideLocation';
import ShippingDetailsComponent from '../../../components/createPurchaseOrder/asideShipmentDetails';
import DocumentUploadComponent from '../../../components/createPurchaseOrder/asideDocuments';
import PaymentDetailsComponent from '../../../components/createPurchaseOrder/asidePaymentDetails';
import NotesComponent from '../../../components/createPurchaseOrder/asideNotes';

const Aside = (props) => {
    const [showModalLocation, setShowModalLocation] = useState(false);
    const [showModalLocation2, setShowModalLocation2] = useState(false);
    const [toggleButtonLocation, setToggleButtonLocation] = useState(false);
    const [searchLocation, setSearchLocation] = useState('');
    const [contactModalLocation, setContactModalLocation] = useState(false);
    const [contactModalLocation2, setContactModalLocation2] = useState(false);
    const [shippingLocModal, setShippingLocModal] = useState(false);
    const [shippingLocModal2, setShippingLocModal2] = useState(false);
    const [updateShippingLocModal, setUpdateShippingLocModal] = useState(false);
    const [updateShippingLocModal2, setUpdateShippingLocModal2] = useState(false);
    const [defaultAddressLocationModal, setDefaultAddressLocationModal] = useState(false);
    const [defaultAddressLocationModal2, setDefaultAddressLocationModal2] = useState(false);
    // const [shippingAddressLocationArray, setShippingAddressLocationArray] = useState([]);
    // const [billingAddressLocationArray, setBillingAddressLocationArray] = useState([]);
    const [removeLocGstModal, setRemoveLocGstModal] = useState(false);
    const [newLocGstNo, setNewLocGstNo] = useState(false);
    const [newLocGstNo2, setNewLocGstNo2] = useState(false);
    const [previousTarget, setPreviousTarget] = useState([]);
    const [addressType, setAddressType] = useState('');
    const [addressType2, setAddressType2] = useState('');
    const [updateBillingAddressLocModal, setUpdateBillingAddressLocModal] = useState(false);
    const [updateBillingAddressLocModal2, setUpdateBillingAddressLocModal2] = useState(false);
    const [defaultLocBillingAddressModal, setDefaultLocBillingAddressModal] = useState(false);
    const [defaultLocBillingAddressModal2, setDefaultLocBillingAddressModal2] = useState(false);
    // const [BillingAddressArray, setBillingAddressArray] = useState([]);

    // set asideModalToggle
    const [toggleLocation, setToggleLocation] = useState(true);

    // shippingAddressPopUp
    const [num, setNum] = useState('');
    const [pincode, setPincode] = useState('');

    const [num2, setNum2] = useState('');
    const [pincode2, setPincode2] = useState('');

    const {
        sellerGstNumber, 
        setSellerGstNumber,
        customerGstNumber, 
        setCustomerGstNumber,
        updateExistOrder,
        setNotes,
        notes,
        sellerLocationDetail,
        sellerAddress,
        setSellerLocationDetail,
        sellerLocationAddress,
        setSellerLocationAddress,
        sellerLocationBillingAddress,
        setSellerLocationBillingAddress,
        sellerLocationAddressArray,
        setSellerLocationAddressArray,
        sellerLocationContacts,
        setSellerLocationContacts,
        sellerLocationContactDetails,
        setSellerLocationContactDetails,
        sellerLocationGstNumber,
        setSellerLocationGstNumber,
        shippingDetails,
        setShippingDetails,
        paymentDetails,
        setPaymentDetails,
        setFiles,
        files,
        defaultFiles, 
        setDefaultFiles, 
        productDetails,
        overAllDiscount,
        setProduct, 
        setShippingPrice, 
        inputRef,
        customerLocationDetail,
        setCustomerLocationDetail,
        customerLocationAddress,
        setCustomerLocationAddress,
        customerLocationBillingAddress,
        setCustomerLocationBillingAddress,
        customerLocationAddressArray,
        setCustomerLocationAddressArray,
        customerLocationContact,
        setCustomerLocationContact,
        customerLocationContactDetails,
        setCustomerLocationContactDetails,
        customerLocationGstNumber,
        setCustomerLocationGstNumber,
        customerAddress,
        setLoadingInterWHCalculationServiceState,
    } = props;

    const handleFileSelect = (event) => {
        setFiles([...files, ...event.target.files]);
        event.target.value = null;
    };

    const removeFile = (index, files, setFiles) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles)
    };

    const showRemoveLocGstPopup = () => {
        setRemoveLocGstModal(true);
    }

    const hideRemoveLocGstPopup = () => {
        setRemoveLocGstModal(false);
    }

    const handleShippingChange = (key, e) => {
        if (key === 'freightCharges') {
            setShippingDetails({ ...shippingDetails, [key]: Number(e.target.value) });
        } else {
            setShippingDetails({ ...shippingDetails, [key]: e.target.value });
        }
    }

    const handlePaymentChange = (key, e) => {
        const value = e.target.value || null;
        setPaymentDetails({ ...paymentDetails, [key]: value });
    }

    const handleGstNumber = (e, type) => {
        setSellerLocationGstNumber(e.target.value);
    }
    
    const handleGstNumber2 = (e, type) => {
        setCustomerLocationGstNumber(e.target.value);
    }

    const showLocationModal = (searchItem) => {
        setShowModalLocation(true);
    };

    const hideLocationModal = () => {
        setShowModalLocation(false);
    }

    const showLocationModal2 = (searchItem) => {
        setShowModalLocation2(true);
    };

    const hideLocationModal2 = () => {
        setShowModalLocation2(false);
    }

    const removeElementLocation = (Id) => {
        setToggleLocation(true);
        setSearchLocation('');
        setSellerLocationContactDetails(null)
        setSellerLocationGstNumber('');
        sellerLocationContacts?.splice(0, sellerLocationContacts?.length);
        setSellerLocationAddressArray([]);
        setSellerLocationDetail(sellerLocationDetail => sellerLocationDetail.filter(ele => ele?._id !== Id));
        if (sellerLocationAddress?.length !== 0) {
            setSellerLocationAddress(sellerLocationAddress => sellerLocationAddress.filter(ele => ele.user !== Id));
        }

        if (sellerLocationBillingAddress?.length !== 0) {
            setSellerLocationBillingAddress(sellerLocationBillingAddress => sellerLocationBillingAddress.filter(ele => ele.user !== Id));
        }

        if (sellerLocationAddressArray.length !== 0) {
            setSellerLocationAddressArray(sellerLocationAddressArray => sellerLocationAddressArray?.filter(ele => ele.user !== Id));
        }
    }

    const removeElementLocation2 = (Id) => {
        setToggleLocation(true);
        setSearchLocation('');
        setCustomerLocationContactDetails(null)
        setCustomerLocationGstNumber('');
        customerLocationContact?.splice(0, customerLocationContact?.length);
        setCustomerLocationAddressArray([]);
        setCustomerLocationDetail(customerLocationDetail => customerLocationDetail.filter(ele => ele?._id !== Id));
        if (customerLocationAddress?.length !== 0) {
            setCustomerLocationAddress(customerLocationAddress => customerLocationAddress.filter(ele => ele?.user !== Id));
        }

        if (customerLocationBillingAddress?.length !== 0) {
            setCustomerLocationBillingAddress(customerLocationBillingAddress => customerLocationBillingAddress.filter(ele => ele?.user !== Id));
        }

        if (customerLocationAddressArray?.length !== 0) {
            setCustomerLocationAddressArray(customerLocationAddressArray => customerLocationAddressArray.filter(ele => ele.user !== Id));
        }
    }

    const handleSearchLocation = (customer) => {
        setToggleButtonLocation(true);
        setSearchLocation(customer);
    }

    const showContactPopLocation = (e) => {
        setContactModalLocation(true);
    }

    const hideContactPopLocation = () => {
        setContactModalLocation(false)
    }

    const showContactPopLocation2 = (e) => {
        setContactModalLocation2(true);
    }

    const hideContactPopLocation2 = () => {
        setContactModalLocation2(false)
    }

    const showShippingLocPop = (event) => {
        setAddressType(event.target.id);
        setShippingLocModal(true);
    }

    const hideShippingLocPop = () => {
        setPincode('');
        setNum('');
        setShippingLocModal(false);
        setAddressType('');
    }

    const showShippingLocPop2 = (event) => {
        setAddressType2(event.target.id);
        setShippingLocModal2(true);
    }

    const hideShippingLocPop2 = () => {
        setPincode2('');
        setNum2('');
        setShippingLocModal2(false);
        setAddressType2('');
    }

    const showUpdateShippingLocPop = (event) => {
        setAddressType(event.target.id);
        setUpdateShippingLocModal(true);
    }

    const hideUpdateShippingLocPop = () => {
        setAddressType('');
        setUpdateShippingLocModal(false);
    }

    const showUpdateShippingLocPop2 = (event) => {
        setAddressType2(event.target.id);
        setUpdateShippingLocModal2(true);
    }

    const hideUpdateShippingLocPop2 = () => {
        setAddressType2('');
        setUpdateShippingLocModal2(false);
    }

    const showUpdateBillingLocPop = (event) => {
        setAddressType(event.target.id);
        setUpdateBillingAddressLocModal(true);
    }

    const hideUpdateBillingLocPop = () => {
        setUpdateBillingAddressLocModal(false);
        setAddressType('');
    }

    const showUpdateBillingLocPop2 = (event) => {
        setAddressType2(event.target.id);
        setUpdateBillingAddressLocModal2(true);
    }

    const hideUpdateBillingLocPop2 = () => {
        setUpdateBillingAddressLocModal2(false);
        setAddressType2('');
    }

    const showdefaultLocBillingAddressPop = () => {
        // if (billingAddressLocationArray.length === 0) {
        //     setBillingAddressLocationArray(sellerLocationBillingAddress);
        // } else {
        //     setBillingAddressLocationArray(billingAddressLocationArray);
        // }
        setDefaultLocBillingAddressModal(true);
    }

    const hideDefaultLocBillingAddressPop = () => {
        setPreviousTarget([]);
        // setBillingAddressLocationArray(BillingAddressArray);
        setDefaultLocBillingAddressModal(false);
    }

    const showdefaultLocBillingAddressPop2 = () => {
        // if (billingAddressLocationArray.length === 0) {
        //     setBillingAddressLocationArray(sellerLocationBillingAddress);
        // } else {
        //     setBillingAddressLocationArray(billingAddressLocationArray);
        // }
        setDefaultLocBillingAddressModal2(true);
    }

    const hideDefaultLocBillingAddressPop2 = () => {
        setPreviousTarget([]);
        // setBillingAddressLocationArray(BillingAddressArray);
        setDefaultLocBillingAddressModal2(false);
    }

    const showDefaultAddressLocationPopUp = () => {
        // if (shippingAddressLocationArray.length === 0) {
        //     setShippingAddressLocationArray(sellerLocationAddress);
        // } else {
        //     setShippingAddressLocationArray(shippingAddressLocationArray);
        // }
        setDefaultAddressLocationModal(true)
    }

    const hideDefaultAddressLocationPopUp = () => {
        setPreviousTarget([]);
        // setShippingAddressLocationArray(shippingAddressLocationArray);
        setDefaultAddressLocationModal(false)
    }

    const showDefaultAddressLocationPopUp2 = () => {
        // if (shippingAddressLocationArray.length === 0) {
        //     setShippingAddressLocationArray(sellerLocationAddress);
        // } else {
        //     setShippingAddressLocationArray(shippingAddressLocationArray);
        // }
        setDefaultAddressLocationModal2(true)
    }

    const hideDefaultAddressLocationPopUp2 = () => {
        setPreviousTarget([]);
        // setShippingAddressLocationArray(shippingAddressLocationArray);
        setDefaultAddressLocationModal2(false)
    }

    const showLocGstNoPopUp = () => {
        setNewLocGstNo(true);
    }

    const hideLocGstNoPopUp = () => {
        setNewLocGstNo(false);
    }

    const showLocGstNoPopUp2 = () => {
        setNewLocGstNo2(true);
    }

    const hideLocGstNoPopUp2 = () => {
        setNewLocGstNo2(false);
    }

    const handleShippingPrice = (event) => {
        event.preventDefault();

            let shipping = {
                price: event.target.value ? event.target.value : 0,
            }

            const variantLineItems = productDetails.map(obj => {
                const { variantId, ...rest } = obj;
                return { productId: variantId, ...rest };
            });

            let data = {}
            if (overAllDiscount.length !== 0) {
                data = {
                    lineItems: variantLineItems,
                    overallDiscount: overAllDiscount[0],
                    shippingLine: shipping
                }
            } else {
                data = {
                    lineItems: variantLineItems,
                    shippingLine: shipping
                }
            }

            setLoadingInterWHCalculationServiceState(true);
            const endpoint = '/calculate/purchaseOrder'
            axiosInstance
                .post(endpoint, { ...data })
                .then((res) => {
                    setProduct(res.data);
                })
                .finally(()=>{
                    let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
                    calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
                    if (calculatePurchaseOrderRoutePendingCount === 0) {
                        setLoadingInterWHCalculationServiceState(false);
                    }
                })

            setShippingPrice([shipping]);
    }

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('contactOutline');
        if (container !== null) {
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleOutline = () => {
        document.getElementById('contactOutline').style.border = '2px solid #458fff';
    }

    const extractFileName = (url) => {
        const splitUrl = url.split("/");
        const fileNameWithParams = splitUrl[splitUrl.length - 1];
        const fileName = fileNameWithParams.split("?")[0];
        return fileName;
    }


    const handleEdit = (event) => {
        if (document.activeElement.isContentEditable) {
            if (event.keyCode === 13) {
                event.preventDefault();
                event.target.blur();
                return;
            }
        }
    };

    const handleBlur = (event) => {
        if (event.target.id === 'notes') {
            setNotes(event.target.innerText)
        }
    }

    return (
        <Fragment>
            <LocationComponent 
                showLocationDetail={sellerLocationDetail}
                removeElementLocation={removeElementLocation}
                customerIDAddress={sellerAddress}
                handleOutline={handleOutline}
                handleGstNumber={handleGstNumber}
                showContactPopLocation={showContactPopLocation}
                locationGstNumber={sellerLocationGstNumber}
                locationAddressArray={sellerLocationAddressArray}
                setLocationAddressArray={setSellerLocationAddressArray}
                locationIDAddress={sellerLocationAddress}
                showUpdateShippingLocPop={showUpdateShippingLocPop}
                showDefaultAddressLocationPopUp={showDefaultAddressLocationPopUp}
                showShippingLocPop={showShippingLocPop}
                locationBillingAddress={sellerLocationBillingAddress}
                showUpdateBillingLocPop={showUpdateBillingLocPop}
                showdefaultLocBillingAddressPop={showdefaultLocBillingAddressPop}
                showLocGstNoPopUp={showLocGstNoPopUp}
                showRemoveLocGstPopup={showRemoveLocGstPopup}
                handleSearchLocation={handleSearchLocation}
                showLocationModal={showLocationModal}
                // aside locationModal
                showModalLocation={showModalLocation}
                hideLocationModal={hideLocationModal}
                setLocationDetail={setSellerLocationDetail}
                searchLocation={searchLocation}
                toggleButtonLocation={toggleButtonLocation}
                setToggleButtonLocation={setToggleButtonLocation}
                setLocationIDAddress={setSellerLocationAddress}
                setPreviousTarget={setPreviousTarget}
                setLocationBillingAddress={setSellerLocationBillingAddress}
                toggleLocation={toggleLocation}
                setToggleLocation={setToggleLocation}
                locationContacts={sellerLocationContacts}
                setLocationContacts={setSellerLocationContacts}
                locationContactDetails={sellerLocationContactDetails}
                setLocationContactDetails={setSellerLocationContactDetails}
                setLocationGstNumber={setSellerLocationGstNumber}
                // contact PopUp Location
                contactModalLocation={contactModalLocation}
                setContactModalLocation={setContactModalLocation}
                hideContactPopLocation={hideContactPopLocation}
                // shipping Dialog
                shippingLocModal={shippingLocModal}
                setShippingLocModal={setShippingLocModal}
                hideShippingLocPop={hideShippingLocPop}
                pincode={pincode}
                setPincode={setPincode}
                num={num}
                setNum={setNum}
                addressType={addressType}
                // update shippingDialog
                updateShippingLocModal={updateShippingLocModal}
                hideUpdateShippingLocPop={hideUpdateShippingLocPop}
                setUpdateShippingLocModal={setUpdateShippingLocModal}
                updateExistOrder={updateExistOrder}
                // update BillingDialog
                updateBillingAddressLocModal={updateBillingAddressLocModal}
                hideUpdateBillingLocPop={hideUpdateBillingLocPop}
                setUpdateBillingAddressLocModal={setUpdateBillingAddressLocModal}
                // default LocationAddressDialog
                defaultAddressLocationModal={defaultAddressLocationModal}
                hideDefaultAddressLocationPopUp={hideDefaultAddressLocationPopUp}
                previousTarget={previousTarget}
                // default Billing Address Dialog
                defaultLocBillingAddressModal={defaultLocBillingAddressModal}
                hideDefaultLocBillingAddressPop={hideDefaultLocBillingAddressPop}
                // newGST No
                newLocGstNo={newLocGstNo}
                setNewLocGstNo={setNewLocGstNo}
                hideLocGstNoPopUp={hideLocGstNoPopUp}
                // remove GST No
                removeLocGstModal={removeLocGstModal}
                setRemoveLocGstModal={setRemoveLocGstModal}
                hideRemoveLocGstPopup={hideRemoveLocGstPopup}
                gstNumber={sellerGstNumber}
                setGstNumber={setSellerGstNumber}
                title={"From Warehouse"}
                defaultLocation={false}
            />

            <LocationComponent 
                showLocationDetail={customerLocationDetail}
                removeElementLocation={removeElementLocation2}
                customerIDAddress={customerAddress}
                handleOutline={handleOutline}
                handleGstNumber={handleGstNumber2}
                locationGstNumber={customerLocationGstNumber}
                locationAddressArray={customerLocationAddressArray}
                setLocationAddressArray={setCustomerLocationAddressArray}
                locationIDAddress={customerLocationAddress}
                setLocationIDAddress={setCustomerLocationAddress}
                showUpdateShippingLocPop={showUpdateShippingLocPop2}
                showDefaultAddressLocationPopUp={showDefaultAddressLocationPopUp2}
                showShippingLocPop={showShippingLocPop2}
                locationBillingAddress={customerLocationBillingAddress}
                showUpdateBillingLocPop={showUpdateBillingLocPop2}
                showdefaultLocBillingAddressPop={showdefaultLocBillingAddressPop2}
                showRemoveLocGstPopup={showRemoveLocGstPopup}
                handleSearchLocation={handleSearchLocation}
                showLocationModal={showLocationModal2}
                // aside locationModal
                showModalLocation={showModalLocation2}
                hideLocationModal={hideLocationModal2}
                setLocationDetail={setCustomerLocationDetail}
                searchLocation={searchLocation}
                toggleButtonLocation={toggleButtonLocation}
                setToggleButtonLocation={setToggleButtonLocation}
                setPreviousTarget={setPreviousTarget}
                setLocationBillingAddress={setCustomerLocationBillingAddress}
                toggleLocation={toggleLocation}
                setToggleLocation={setToggleLocation}
                locationContacts={customerLocationContact}
                setLocationContacts={setCustomerLocationContact}
                locationContactDetails={customerLocationContactDetails}
                setLocationContactDetails={setCustomerLocationContactDetails}
                setLocationGstNumber={setCustomerLocationGstNumber}
                // contact PopUp Location
                showContactPopLocation={showContactPopLocation2}
                contactModalLocation={contactModalLocation2}
                setContactModalLocation={setContactModalLocation2}
                hideContactPopLocation={hideContactPopLocation2}
                // shipping Dialog
                shippingLocModal={shippingLocModal2}
                setShippingLocModal={setShippingLocModal2}
                hideShippingLocPop={hideShippingLocPop2}
                pincode={pincode2}
                setPincode={setPincode2}
                num={num2}
                setNum={setNum2}
                addressType={addressType2}
                // update shippingDialog
                updateShippingLocModal={updateShippingLocModal2}
                hideUpdateShippingLocPop={hideUpdateShippingLocPop2}
                setUpdateShippingLocModal={setUpdateShippingLocModal2}
                updateExistOrder={updateExistOrder}
                // update BillingDialog
                updateBillingAddressLocModal={updateBillingAddressLocModal2}
                hideUpdateBillingLocPop={hideUpdateBillingLocPop2}
                setUpdateBillingAddressLocModal={setUpdateBillingAddressLocModal2}
                // default LocationAddressDialog
                defaultAddressLocationModal={defaultAddressLocationModal2}
                hideDefaultAddressLocationPopUp={hideDefaultAddressLocationPopUp2}
                previousTarget={previousTarget}
                // default Billing Address Dialog
                defaultLocBillingAddressModal={defaultLocBillingAddressModal2}
                hideDefaultLocBillingAddressPop={hideDefaultLocBillingAddressPop2}
                // newGST No
                newLocGstNo={newLocGstNo2}
                setNewLocGstNo={setNewLocGstNo2}
                hideLocGstNoPopUp={hideLocGstNoPopUp2}
                showLocGstNoPopUp={showLocGstNoPopUp2}
                // remove GST No
                removeLocGstModal={removeLocGstModal}
                setRemoveLocGstModal={setRemoveLocGstModal}
                hideRemoveLocGstPopup={hideRemoveLocGstPopup}
                gstNumber={customerGstNumber}
                setGstNumber={setCustomerGstNumber}
                title={"To Warehouse"}
                defaultLocation={false}
            />

            <ShippingDetailsComponent 
                shippingDetails={shippingDetails}
                handleShippingChange={handleShippingChange}
                handleShippingPrice={handleShippingPrice}
                inputRef={inputRef}
            />

            <DocumentUploadComponent 
                handleFileSelect={handleFileSelect}
                defaultFiles={defaultFiles}
                extractFileName={extractFileName}
                removeFile={removeFile}
                setDefaultFiles={setDefaultFiles}
                files={files}
                setFiles={setFiles}
            />

            <PaymentDetailsComponent 
                paymentDetails={paymentDetails}
                handlePaymentChange={handlePaymentChange}
            />

            <NotesComponent 
                handleEdit={handleEdit}
                handleBlur={handleBlur}
                notes={notes}
            />
        </Fragment>
    )
};

export default Aside;