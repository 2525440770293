import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Classes from '../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const navigate = useNavigate();
    const serviceName = services?.inwardReturns;
    const { dataPosition, endpoint, rowId, columns, clickable, handleClickOpen, reRender } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor,reRender]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box} onClick={() => clickable &&  navigate(`/returnsNew/${row[rowId]}`)}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                            <p>
                                {column?.field === "created_at" || column?.field === "createdAt" || 
                                column?.field === 'updatedAt' || column?.field === 'updated_at' ? 
                                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                                column?.field ==='Inbound' || column?.field === 'PutAway' ?  
                                <Permission service={serviceName} permission={"create"}>
                                    <Button
                                        variant="contained"
                                        size='small'
                                        color="primary"
                                        onClick={() => handleClickOpen(row)}
                                    >{column?.field}</Button>
                                </Permission>
                                : column?.field === 'QC' ?  
                                    <Permission service={serviceName} permission={"update"}>
                                        <Button
                                            variant="contained"
                                            size='small'
                                            color="primary"
                                            onClick={() => handleClickOpen(row)}
                                        >QC</Button>
                                    </Permission> 
                                : row[column?.field]}
                            </p>
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;