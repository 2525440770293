import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Card, CardContent, CardMedia, Paper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import Classes from "./productCard.module.css";

const ProductCard = (props) => {
  const { image, productName, price, sku, availableQty, } = props;
  const { filterId, selectedProducts, filters, } = props;   // common props for both combo and non-combo
  const { variants, groupName, combo, locationIdsToCodesMap, variantOptions } = props; // when combo is true
  const [isClicked, setIsClicked] = useState(false);
  const [selectedVariantInformation, setSelectedVariantInformation] = useState(
    variantOptions
      ? Object.keys(variantOptions).map((variantType) => {
        const foundSelectedVariant = variants.find((variant) => {
          return (
            variant?.variantOptions[variantType]
            === (filters?.find((filter) => {
              return filter?.rowId === filterId
            })?.[variantType])
          )
        })
        return {
          variantType: variantType,
          variantValue: filters?.find((filter) => {
            return filter?.rowId === filterId
          })?.[variantType]
            || variantOptions?.[variantType]?.[0]?.variantValue,
          variantId: foundSelectedVariant?.variantId || variants?.[0]?.variantId,
          sku: foundSelectedVariant?.sku || variants?.[0]?.sku,
          id: foundSelectedVariant?.id || variants?.[0]?.id,
        };
      })
      : []
  );
  const handleProductSelectForCombo = (id, sku, variantId, locationId) => {
    props.handleProductSelect(id, sku, variantId, locationIdsToCodesMap[locationId]);
    setIsClicked(true);
  };
  const handleProductSelect = () => {
    props.handleProductSelect();
    setIsClicked(true);
  };
  const selectedProductVariant = variants?.find((variant) => { return variant?.variantId === selectedVariantInformation?.[0]?.variantId })
  const productVariantImage = combo ? selectedProductVariant?.image : image
  const quantityToBeOrdered = filters?.find((filter) => { return filter?.rowId === filterId })?.quantity
  const selectedVariantAvailableQty = selectedProductVariant?.availableQty
  const availableQtyForThisProduct = combo ? selectedVariantAvailableQty : availableQty
  useEffect(() => {
    if (
      combo === true
      && selectedProducts?.[filterId]?.sku
      && (selectedProducts?.[filterId]?.sku === selectedProductVariant?.sku)
    ) {
      setIsClicked(true);
    }
    else if (
      selectedProducts?.[filterId]?.sku
      && (selectedProducts?.[filterId]?.sku === sku)) {
      setIsClicked(true);
    }
    else {
      setIsClicked(false);
    }
  }, [selectedProducts]);
  return (
    <Card
      style={{ margin: '1em', minWidth: '14vw', maxWidth: '14vw', ...(combo ? { height: '530px' } : { height: '464px' }), display: 'flex', flexDirection: 'column' }}
      ref={props?.innerRef}
      className={
        availableQtyForThisProduct < 1 ? Classes.productCardRed :
          availableQtyForThisProduct < quantityToBeOrdered ? Classes.productCardYellow :
            Classes.productCardGreen
      }
    >
      <CardMedia component="img" image={productVariantImage || "https://d1xkbitcv5i7vd.cloudfront.net/Assets/Image+Coming+Soon.png"} title={productName} style={{ width: '100%', height: '215px', objectFit: 'cover' }} />
      <CardContent style={{ display: 'flex', gap: '0.5em', flexDirection: 'column', ...(combo ? { height: '62%' } : { height: '53%' }) }}>
        <Tooltip title={combo ? groupName : productName} placement="top" arrow>
          <Typography variant="body2" component="h6" sx={{
            overflow: 'auto',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}>
            {combo ? groupName : productName}
          </Typography>
        </Tooltip>
        {combo
          ?
          <React.Fragment>
            {selectedProductVariant?.variantId &&
              <React.Fragment>
                <Typography variant="body2" component="p">
                  Sku: {selectedProductVariant?.sku}
                </Typography>
                <Typography variant="body2" component="p">
                  Available Quantity: {availableQtyForThisProduct}
                </Typography>
                <Typography variant="body2" component="p">
                  Price: ₹ {selectedProductVariant?.price}
                </Typography>
              </React.Fragment>
            }
            {Object.keys(variantOptions).length > 0
              && Object.keys(variantOptions).map((variantType, index) => {
                return (
                  <Autocomplete
                    size='small'
                    id={variantType}
                    required
                    onChange={(event, value) => {
                      setSelectedVariantInformation((prev) => {
                        return prev.map((variantInfo) => {
                          if (variantInfo.variantType === variantType) {
                            const selectedVariant = variants?.find((variant) => {
                              return variant?.variantOptions[variantType] === value
                            })
                            return {
                              ...variantInfo,
                              variantValue: value,
                              variantId: selectedVariant?.variantId,
                              sku: selectedVariant?.sku,
                              id: selectedVariant?.id,
                            }
                          }
                          else {
                            return variantInfo
                          }
                        })
                      })
                    }}
                    options={variantOptions?.[variantType] || []}
                    value={selectedVariantInformation?.find((variantInfo) => { return variantInfo.variantType === variantType })?.variantValue || ''}
                    renderInput={(params) => <TextField required={true} {...params} label={variantType} />}
                  />
                )
              })
            }
          </React.Fragment>
          :
          <React.Fragment>
            <Typography variant="body2" component="p">
              Sku: {sku}
            </Typography>
            <Typography variant="body2" component="p">
              Available Quantity: {availableQty}
            </Typography>
            <Typography variant="body2" component="p">
              Price: ₹ {price}
            </Typography>
          </React.Fragment>
        }
        <Button
          variant="contained"
          color={isClicked ? "secondary" : "primary"}
          style={{ width: 'fit-content', height: 'fit-content', marginTop: 'auto', backgroundColor: isClicked ? "rgb(255, 98, 0)" : "rgb(36, 55, 80)", color: 'white', alignSelf: 'center' }}
          onClick={() => {
            combo ? handleProductSelectForCombo(selectedProductVariant?.id, selectedProductVariant?.sku, selectedProductVariant?.variantId, selectedProductVariant?.locationForPrice)
              : handleProductSelect()
          }
          }
          disabled={isClicked}
        >
          {isClicked ? "Selected" : "Select"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
