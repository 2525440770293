import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Platform': 'portal',
	},
	withCredentials: true,
});


const pendingRequestsBeingTracked = [
	'/calculate/purchaseOrder',
	'/product/fetchProductDetails/bulk',
];

// Add a request interceptor to track the pending requests for specific routes
pendingRequestsBeingTracked.forEach((route) => {
	axiosInstance.interceptors.request.use(
		(config) => {
			// Check if the request is for a specific route
			if (config.url.includes(route)) {
				// Increment the pending request count for the specific route
				const calculateRoutePendingCount = localStorage.getItem(route);
				localStorage.setItem(route, calculateRoutePendingCount ? parseInt(calculateRoutePendingCount) + 1 : 1);
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	axiosInstance.interceptors.response.use(
		(response) => {
			// Check if the response is for a specific route
			if (response.config.url.includes(route)) {
				// Decrement the pending request count for the specific route
				const calculateRoutePendingCount = localStorage.getItem(route);
				localStorage.setItem(route, calculateRoutePendingCount ? parseInt(calculateRoutePendingCount) - 1 : 0);
			}
			return response;
		},
		(error) => {
			// Check if the error response is for a specific route
			if (error.config.url.includes(route)) {
				// Decrement the pending request count for the specific route
				const calculateRoutePendingCount = localStorage.getItem(route);
				localStorage.setItem(route, calculateRoutePendingCount ? parseInt(calculateRoutePendingCount) - 1 : 0);
			}
			return Promise.reject(error);
		}
	);
});
export default axiosInstance;
