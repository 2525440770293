import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	show: false,
};

const toggleNavSlice = createSlice({
	name: 'toggleNav',
	initialState: initialState,
	reducers: {
		setShow: (state, action) => ({
			...state,
			...action.payload,
		}),
	},
});

export const { setShow } = toggleNavSlice.actions;

export default toggleNavSlice.reducer;
