import React, { Fragment } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import { Autocomplete, TextField } from '@mui/material';

const CancelPopup = (props) => {

    const {
        showPopup,
        hidePopup,
        handleCancelOrder,
        reason,
        setReason,
        description,
        setDescription
    } = props;

    const reasons = [
        "Other",
        "Order Created by Mistake",
        "Item(s) Would Not Arrive on Time",
        "Shipping Cost Too High",
        "Item Price Too High",
        "Found Cheaper Somewhere Else",
        "Need to Change Shipping Address",
        "Need to Change Shipping Speed",
        "Need to Change Billing Address",
        "Need to Change Payment Method",
        "Wrong Size",
        "Wrong Color",
        "Wrong Product",
        "Wrong Quantity",
        "Poor Quality"
    ]

    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom:'34%',top:'14%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Cancel Order</p>
                    </div>

                    <div style={{ padding:20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                            <div >
                                <Autocomplete
                                    disablePortal
                                    id="reason"
                                    name="reason"
                                    key="Other"
                                    defaultValue="Other"
                                    options={reasons}
                                    sx={{width: '100%',"& label": {top: reason ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                    onChange={(e, value) => setReason(value)}
                                    renderInput={(params) => <TextField {...params} label="Reason"/>}
                                />
                            </div>

                            <div style={{marginTop:15}}>
                                <TextField 
                                    id='description'
                                    name="description"
                                    label="Description"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    onChange={(e) => setDescription(e.target.value)}
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top: description ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 0px" }}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={Classes.DialogBtnBox} style={{bottom:'34%'}}>
                        <button 
                            type="button" 
                            className={Classes.UpdateSalesUserOfCustomer}
                            onClick={hidePopup}
                        >
                            CANCEL
                        </button>

                        <button 
                            id='btn' 
                            type="button" 
                            className={Classes.UpdateSalesUserOfCustomer}
                            onClick={(e) => handleCancelOrder(e, reason, description)}
                        >
                            APPLY
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default CancelPopup;