import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';
import './css/specialImg.css';
import { TextField, Autocomplete } from '@mui/material';

const Inventory = (props) => {
    const { payloadToUpload, setPayloadToUpload }= props;

	return (
		<Fragment>
            <div className={Classes.Pricing}>
                <div className={Classes.Boxes}>
                    <h6>Inventory</h6>
                    <div style={{width:'100%', marginTop:25}}>
                        <Autocomplete
                            freeSolo
                            forcePopupIcon={true}
                            name="availableOnRequest" 
                            id="availableOnRequest"
                            key={ payloadToUpload?.isCustomOrder }
                            defaultValue={ payloadToUpload?.isCustomOrder }
                            size='small'
                            options={['yes','no']}
                            disablePortal
                            ListboxProps={{sx: { fontSize: 14 }}}
                            sx={{  width: '100%' }}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionSelected ={(option, value) => option === value}
                            onChange={(e, value) => setPayloadToUpload((prevPayloadToUpload) => {
                                return { ...prevPayloadToUpload, isCustomOrder: value }
                            })}
                            renderInput={(params) => <TextField {...params} label="Available On Request" />}
                        />
                    </div>

                    <div style={{width:'100%', marginTop:25}}>
                        <TextField
                            name='costPerItem'
                            type="number"
                            id="costPerItem"
                            step="0.01"
                            size='small'
                            onKeyDown={e => ( e.keyCode === 69) && e.preventDefault()}
                            key={ payloadToUpload?.defaultPrices?.baseCost || '' }
                            defaultValue={ payloadToUpload?.defaultPrices?.baseCost || '' }
                            sx={{  width: '100%' }}
                            autoComplete='off'
                            className={Classes.searchField}
                            label='Cost per item'
                            onBlur={(e) => setPayloadToUpload((prevPayloadToUpload) => {
                                return { 
                                    ...prevPayloadToUpload, 
                                    defaultPrices: { 
                                    ...prevPayloadToUpload.defaultPrices, baseCost: e?.target?.value || "" 
                                    } 
                                }
                            })}
                        />
                    </div>

                    <p style={{color:'gray'}}>Customer won't see this</p>
                    <div style={{ display:'flex', marginTop:15 }}>
                        <input
                            type='checkbox'
                            name='trackQty'
                            id='trackQty'
                            defaultChecked={ !!payloadToUpload?.isTracked }
                            key={ !!payloadToUpload?.isTracked }
                            onChange={(e) => setPayloadToUpload((prevPayloadToUpload) => {
                                return { ...prevPayloadToUpload, isTracked: e?.target?.checked }
                            })}
                        />
                        <p style={{ padding:'3px 10px' }}>Track quantity</p> 
                    </div>

                    <div style={{ display:'flex' }}>
                        <input
                            type='checkbox'	
                            name='continueSelling'
                            id='continueSelling'
                            defaultChecked={ 
                                (payloadToUpload?.backOrder === 'CONTINUE' 
                                || payloadToUpload?.backOrder === true) ? true : false 
                            }
                            key={ 
                                (payloadToUpload?.backOrder === 'CONTINUE' 
                                || payloadToUpload?.backOrder === true) ? true : false 
                            }
                            onChange={(e) => setPayloadToUpload((prevPayloadToUpload) => {
                                return { ...prevPayloadToUpload, backOrder: e.target.checked }
                            })}
                        />
                        <p style={{ padding:'3px 10px' }}>Contiue selling when out of stock</p>
                    </div>

                    <div style={{display:'flex'}}>
                        <input
                            type='checkbox'	
                            name='sample'
                            id="sample"
                            defaultChecked={ payloadToUpload?.isSampleAvailable === 'yes' ? true : false }
                            key={ payloadToUpload?.isSampleAvailable === 'yes' ? true : false }
                            onChange={(e) => setPayloadToUpload((prevPayloadToUpload) => {
                                return { ...prevPayloadToUpload, isSampleAvailable: e?.target?.checked ? 'yes' : 'no' }
                            })}
                        />
                        <p style={{padding: '3px 10px'}}>Sample Available</p>
                    </div>

                    <div style={{width:'100%', marginTop:25}}>
                        <div>
                            <label htmlFor="availableQuantity" style={{marginRight: "10px"}}>Available Quantity:</label>
                            <span>
                                {payloadToUpload?.availableQuantity || 0}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={Classes.Boxes} style={{ paddingBottom:'0.5rem' }}>
                    <p className={Classes.Location}>Location name</p>
                    <p className={Classes.LocationField}>77/1/A, Christopher Road, Topsia, Kolkata, West Bengal 700046</p>
                </div>
            </div>
		</Fragment>
	);
};

export default Inventory;
