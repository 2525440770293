import { TextField, Autocomplete } from '@mui/material';

const AutoCompleteValueComponent = (props) => {
    const { fieldName, required, name, value, options, size, onChange} = props;

    return (
        <Autocomplete
            freeSolo
            disablePortal
            id={name}
            name={name}
            options={options}
            key={value}
            defaultValue={value}
            sx={{width: '100%'}}
            renderInput={(params) => 
                <TextField {...params} label={fieldName} required={required} />
            }
            size={size || 'medium'} 
            onChange={onChange}
        />
    )
}

export default AutoCompleteValueComponent;