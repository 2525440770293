import React, { Fragment, useState } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';

const CustomerModal = (props) => {
    const [loading,setLoading] = useState(false);
    const {
        setDetail,
        errorMessage,
        setErrorMessage,
        gstNO,
        setGstNO,
        setVendorContactDetails,
        setLocationContactDetails
    } = props;


    const handleGstField = (event) => {
        const result = event.target.value.replace(/[^\w\s]/gi, "")
        setGstNO(result)
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const button = document.getElementById('btn')
        button.innerHTML = 'Saving...';

        const data = {
            // "vendorCode": event.target.vendorCode.value,
            "vendorName": event.target.name.value.trim(),
            "vendorGstNumber": [event.target.gst.value],
            "status": event.target.vendorStatus.value ? event.target.vendorStatus.value: undefined,
        }

        axiosInstance
            .post("/vendor/createVendor", { ...data })
            .then((res) => {
                setLoading(false);
                const form = document.getElementById('form');
                form.reset();
                props.onHideModal();
                setDetail([res.data.data]);
                setVendorContactDetails(null)
                setLocationContactDetails(null);
                setErrorMessage('');
                button.innerHTML = 'Vendor Created';
            }).catch((err) => {
                setLoading(false);
                button.innerHTML = 'Create Vendor';
                setErrorMessage(err.response.data.message)
            })
    }

    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }


    return (
        <Fragment>
            {props.onShowModal && (
                <div className={Classes.modal} style={{ top: '10%', bottom: '17%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Create a New Vendor</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form' style={{ overflowY:'auto',height:'73%' }}>
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>
                        <div style={{ padding: '20px', height: '338px' }}>
                            <div style={{ display: 'flex',justifyContent:'space-between' }}>
                                <div style={{ width: '49%'}}>
                                    <TextField
                                        type="text"
                                        id='name'
                                        name='name'
                                        label='Vendor Name'
                                        autoComplete='off'
                                        placeholder='Vendor Name'
                                        sx={{ width: '100%' }}
                                        required={true}
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="vendorStatus"
                                        name="vendorStatus"
                                        options={["Active", "In-Active", "Dis-Continued"]}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={"Vendor Status"} />}
                                    />
                                </div>

                            </div>

                            <div className={Classes.ContentBox}>
                                {/* <div style={{ width:'49%' }}>
                                    <TextField
                                        type="text"
                                        id='vendorCode'
                                        name='vendorCode'
                                        label='Vendor Code'
                                        autoComplete='off'
                                        placeholder='Vendor Code'
                                        sx={{ width: '100%' }}
                                        required={true}
                                    />
                                </div> */}

                                <div style={{ width: '49%' }}>
                                    <TextField
                                        type="text"
                                        id='gst'
                                        name='gst'
                                        label='GST Number'
                                        autoComplete='off'
                                        placeholder='Enter Your GST Number'
                                        sx={{ width: '100%' }}
                                        required={true}
                                        inputProps={{ maxLength:15,minLength:15 }}
                                        value={gstNO || ''}
                                        onChange={handleGstField}
                                        onBlur={handleValidGst}
                                    />

                                    <div id='isValidGst' className={Classes.IsValidGst}>
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={ Classes.DialogBtnBox}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={props.onHideModal}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default CustomerModal;