import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../utility/axios-instance';
import { useNavigate, useLocation } from 'react-router-dom';

import { setAuth } from '../../store/authSlice';

const Auth = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const getMe = () => {
		axios
			.get('/admin/auth/getMe')
			.then((res) => {
				dispatch(
					setAuth({
						authenticated: true,
						user: res.data.data,
						loading: false,
					})
				);
				if (res.data.data.role === 'delivery-boy') {
					navigate('/deliveryBoy');
				}
			})
			.catch((err) => {
				dispatch(
					setAuth({
						loading: false,
					})
				);
				if (location.pathname !== '/sign-in') {
					navigate('/sign-in');
				}
			});
	};

	useEffect(() => {
		getMe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex justify-content-center d-flex align-items-center vw-100 vh-100">
			<div
				className="spinner-border"
				style={{ width: '3rem', height: '3rem' }}
				role="status"
			></div>
		</div>
	);
};

export default Auth;
