import React from "react";
import OthersFormSection from "./othersFormSection"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function UploadOthersMaster() {

  const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })


  const handleSuccess = (msg) => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: msg, type: "success" }
    });
  }
  const handleError = (msg) => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: msg, type: "error" }
    });
  }

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false }
    });
  };


  return (
    <>
      <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
          {snackBar?.message}
        </Alert>
      </Snackbar>
      <OthersFormSection
        formName="l1"
        fileName="l1s"
        endpoint={`/others/uploadL1sCsv`}
        templateDownloadEndpoint={`/templates/l1sCsv`}
        exportEndpoint={`/exports/l1sCsv`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="l2"
        fileName="l2s"
        endpoint={`/others/uploadL2sCsv`}
        templateDownloadEndpoint={`/templates/l2sCsv`}
        exportEndpoint={`/exports/l2sCsv`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="l3"
        fileName="l3s"
        endpoint={`/others/uploadL3sCsv`}
        templateDownloadEndpoint={`/templates/l3sCsv`}
        exportEndpoint={`/exports/l3sCsv`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="Unit"
        fileName="Unit"
        endpoint={`/others/createUnits`}
        templateDownloadEndpoint={`/templates/units`}
        exportEndpoint={`/exports/units`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="brands"
        fileName="brands"
        endpoint={`/others/uploadBrands`}
        templateDownloadEndpoint={`/templates/brands`}
        exportEndpoint={`/exports/brands`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="vendors"
        fileName="vendors"
        endpoint={`/others/createVendors`}
        templateDownloadEndpoint={`/templates/vendors`}
        exportEndpoint={`/exports/vendors`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="hsnCodes"
        fileName="hsnCodes"
        endpoint={`/others/hsnCodes`}
        templateDownloadEndpoint={`/templates/hsnCodes`}
        exportEndpoint={`/exports/hsnCodes`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="vendorBrandMapping"
        fileName="vendorBrandMapping"
        endpoint={`/others/vendorBrandMapping`}
        templateDownloadEndpoint={`/templates/vendorBrandMapping`}
        exportEndpoint={`/exports/vendorBrandMapping`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="appHeroBanners"
        fileName="banners"
        endpoint={`/others/uploadBanners`}
        templateDownloadEndpoint={`/templates/appHeroBanners`}
        exportEndpoint={`/exports/appHeroBanners`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="appHomePageCategoriesProductCarousels"
        fileName="appHomePageCategoriesProductCarousels"
        endpoint={`/others/uploadHomePageCategories`}
        templateDownloadEndpoint={`/templates/appHomePageCategoriesProductCarousels`}
        exportEndpoint={`/exports/appHomePageCategoriesProductCarousels`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="appKeywords"
        fileName="keywords"
        endpoint={`/others/uploadKeyWordsCsv`}
        templateDownloadEndpoint={`/templates/keywords`}
        exportEndpoint={`/exports/keywords`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="appDynamicBanners"
        fileName="dynamicBanners"
        endpoint={`/others/upload-dynamic-banners`}
        templateDownloadEndpoint={`/templates/dynamic-banners`}
        exportEndpoint={`/exports/dynamic-banners`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
      <OthersFormSection
        formName="Pincodes"
        fileName="pincodes"
        endpoint={`/pincode/createPincodesViaCSV`}
        templateDownloadEndpoint={`/templates/pincodeUploadCsv`}
        exportEndpoint={`/exports/pincodes`}
        handleSuccess={handleSuccess}
        handleError={handleError}
      />
    </>
  );
}
