import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SnackBar from "../../../../../components/SnackBar";
import { Button} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import Table from "../../../../../components/table/table";
import { useSelector } from "react-redux";
import { setStoresImage } from "../../../../../store/storesImagesSlice";
import Permission from "../../../../auth/permissions";
import Classes from "../../../dynamicBanners/dynamicBanners.module.css";
import CreateModal from "./create";
import UpdateModal from "./edit";
import DeleteModal from "./delete";
import MediaPreview from "../../../../../components/mediaPreview";
import Styles from "../../settings.module.css";

const ShowStoreImages = () => {
  const storeImages = useSelector((state) => state.storesImagesSettings);
  const activeStoreId = useSelector((state) => state.storeLocation.activeStoreId);
  const [storeImage, setStoreImage] = useState({});
  const [storeId, setStoreId] = useState("");
  const [imageId, setImageId] = useState("");
  const params = useParams();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [mediaPreviewURL, setMediaPreviewURL] = useState("");
  const [reRender, setReRender] = useState(false);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const [activeCount, setActiveCount] = useState(0);
  const columns = [
    {
      field: "id",
      headerName: "IMAGE ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: true,
      sortable: false,
    },
    {
      field: "imageURL",
      headerName: "Image",
      width: 90,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <img
          src={params?.row?.imageURL}
          alt="icon"
          className={Styles.Images}
          onClick={() => {
            setPreviewToggle(true);
            setMediaPreviewURL(params?.row?.imageURL);
          }}
        />
      ),
    },
    {
      field: "storeId",
      headerName: "STORE ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: true,
      sortable: false,
    },
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "updatedAt",
      headerName: "UPDATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    // {
    //   field: "link",
    //   headerName: "LINK",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   sortable: false,
    // },
    {
      field: "active",
      headerName: "ACTIVE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: `edit`,
      headerName: `EDIT`,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="productRange" permission="update">
          <Button
            size="small"
            variant="contained"
            color="success"
            startIcon={<Edit />}
            onClick={() => {
              handleUpdate(params.row.storeId, params.id);
            }}
          ></Button>
        </Permission>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="bannerSettings" permission={"delete"}>
          <Button
            size="small"
            variant="contained"
            color="warning"
            startIcon={<Delete />}
            onClick={() => {
              handleDelete(params.row.storeId, params.id);
            }}
          ></Button>
        </Permission>
      ),
    },
  ];
  const columnVisibilityModel = {
    createdAt: false,
    updatedAt: false,
    storeId: false,
  };
  const handleDelete = (storeId, imageId) => {
    const imageDetail = storeImages.storesImageDetails.find(
      (image) => image.id === imageId && image.storeId === storeId
    );
    setStoreImage(imageDetail);
    setStoreId(storeId);
    setImageId(imageId);
    setShowDeleteModal(true);
  };
  const handleUpdate = (storeId, imageId) => {
    const imageDetail = storeImages.storesImageDetails.find(
      (image) => image.id === imageId && image.storeId === storeId
    );
    const activeCount = storeImages.storesImageDetails.reduce(
      (count, image) => {
        if (image.active) {
          return count + 1;
        }
        return count;
      },
      0
    );
    setActiveCount(activeCount)
    setStoreImage(imageDetail);
    setStoreId(storeId);
    setImageId(imageId);
    setShowUpdateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handlePreviewClose = () => {
    setPreviewToggle(false);
  };
  return (
    <>
      <div style={{ display: "flex", gap: "1rem 1rem" }}>
        <Permission service="store" permission="create">
          <div className={Classes.UsersHead}>
            {" "}
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<Add />}
              onClick={() => setShowCreateModal(true)}
              className="mt-4"
            >
              Add New Image
            </Button>
          </div>
        </Permission>
      </div>
      <Permission service="store" permission="read">
        <Table
          columns={columns}
          dataPosition={"storesImageDetails"}
          endpoint={`/adminRoutes/stores/${activeStoreId}/images`}
          rowId={"id"}
          checkBoxSelection={false}
          service={"store"}
          tabName={"Store"}
          setData={setStoresImage}
          data={storeImages}
          version={"version-1"}
          mobileResponsive={true}
          reRender={reRender}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Permission>

      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      {showCreateModal ? (
        <CreateModal
          open={showCreateModal}
          handleClose={closeCreateModal}
          setReRender={setReRender}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          open={showUpdateModal}
          handleClose={closeUpdateModal}
          detail={storeImage}
          setReRender={setReRender}
          storeId={storeId}
          imageId={imageId}
          imagesLength={storeImages?.storesImageDetails?.length}
          activeCount={activeCount}
        />
      ) : null}
      {showDeleteModal ? (
        <DeleteModal
          open={showDeleteModal}
          handleClose={closeDeleteModal}
          detail={storeImage}
          setReRender={setReRender}
          storeId={storeId}
          imageId={imageId}
          imagesLength={storeImages?.storesImageDetails?.length}
        />
      ) : null}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type="img"
          mediaURL={mediaPreviewURL}
        />
      )}
    </>
  );
};

export default ShowStoreImages;
