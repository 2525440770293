import { useEffect, useState } from 'react';
import axiosInstance from '../../utility/axios-instance';
import SnackBar from '../SnackBar';
import Classes from '../mobileResponsive/style.module.css';
import Spinner from '../spinner';
import NewPagination from '../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import { downloadCSV } from '../../utility/downloadCSV';

const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
)

const MobileResponsiveTable = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: true, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const navigate = useNavigate();
    const {
      dataPosition,
      endpoint,
      rowId,
      columns,
      clickable,
      tab,
      exportEndpoint,
      exportFileName,
      delayedExport,
      redirectUrlOnSnackBarClick,
    } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const handleFilteredExport = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(!delayedExport){
            setLoading(true);
        }
        axiosInstance
            .get(exportEndpoint, {
                params: {
                    searchFor: searchFor || '',
                }
            })
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data?.exportedData
                const responseMessage = result?.data?.message
                downloadCSV(arrayOfRowObjects, exportFileName || `Export - ${new Date()}`);
                const successMessage = responseMessage || "Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
        setSnackBar((prevSnackBar) => {
            return {
                ...prevSnackBar,
                display: true,
                message:
                `${exportFileName} Report is being generated. Click here to check it's status.`,
                type: "success",
                redirectUrl: redirectUrlOnSnackBarClick,
            };
        });
    };

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor]);

    return (
        <>
            {
                exportEndpoint &&
                <Button
                    color='primary'
                    size='small'
                    startIcon={<ExportIcon />}
                    onClick={(event) => handleFilteredExport(event)}
                    sx={{ marginBottom: '1em' }}
                >
                    Filtered rows
                </Button>
            }
            
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box} onClick={() => clickable &&  navigate(`${tab}/${row[rowId]}`)}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                            <p>
                                {column?.field === "created_at" || column?.field === "createdAt" || 
                                column?.field === 'updatedAt' || column?.field === 'updated_at' ? 
                                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                                column?.field === 'total' ? `₹${row[column?.field]}`: 
                                row[column?.field] ? row[column?.field === 'createdBy' ? 
                                column?.field?.name : column?.field] : ''}
                            </p>
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}

            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
        </>
    )
};

export default MobileResponsiveTable;