import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DeliveryBoyOrder = (props) => {
	const [hover, setHover] = useState(false);
	const navigate = useNavigate();

	const { order } = props;

	return (
		<tr
			className={hover ? 'bg-light' : ''}
			style={{
				cursor: 'pointer',
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			onClick={() => {
				navigate(`${order?._id}?awbno=${order?.awbno}`);
			}}
		>
			<th>
				{order.cancel ? (
					<del>
						<b>{order?.awbno}</b>
					</del>
				) : (
					<b>{order?.awbno}</b>
				)}
			</th>
			<td>
				<div
					className="py-1 px-3"
					style={{
						backgroundColor:
							order.paymentStatus === 'paid' ? '#ffea88' : '#e4e5e7',
						borderRadius: '1rem',
						width: 'fit-content',
					}}
				>
					{order?.cancel ? (
						<del>{order?.paymentStatus}</del>
					) : (
						order?.paymentStatus
					)}
				</div>
			</td>
			<td>
				<div
					className="py-1 px-3"
					style={{
						backgroundColor: order?.delivered ? '#ffea88' : 'transparent',
						borderRadius: '1rem',
						width: 'fit-content',
					}}
				>
					{order?.cancel ? <del>order?.orderStatus</del> : order?.orderStatus}
				</div>
			</td>
			<td>
				{order?.cancel ? (
					<del>order?.draftOrder?.shippingAddress?.zip</del>
				) : (
					order?.draftOrder?.shippingAddress?.zip
				)}
			</td>
		</tr>
	);
};

export default DeliveryBoyOrder;
