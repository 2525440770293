import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';

const ConfirmDelivered = (props) => {
    const [loading,setLoading] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })

    const {
        openDelivered,
        hideDeliveredDialog,
        deliveredId,
        setReRender
    } = props;

    const handleDeliveredOrder = async () => {
        setLoading(true);
        setReRender(false);
        await axiosInstance
			.post(`/orderDispatches/markDelivered/${deliveredId}`)
			.then((res) => {
                setLoading(false);
                const successMessage = 'Order Delivered Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender((prev) => !prev);
                hideDeliveredDialog();
			})
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setReRender((prev) => !prev);
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                hideDeliveredDialog();
            })
    }

    return (
        <Fragment>
            {openDelivered && (
                <div
                    className={Classes.confirmModal}
                    style={{ bottom: '28%',top:'16%',boxShadow:'0 0px 9px 0' }}
                >
                    <div style={{ textAlign:'center',margin:'30px 0px'}}>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            className={Classes.XMark}
                            style={{color:'#E12D39'}}
                        />
                    </div>

                    <div
                        style={{ fontSize:30,color:'black' }}
                        className={Classes.confirmPopUpCommonStyles}
                    >
                        <p>Are you sure?</p>
                    </div>

                    <div
                        style={{ padding:"10px 20px",color:'black' }}
                        className={Classes.confirmPopUpCommonStyles}
                    >
                        <p>Do you really want to delivered this {deliveredId} Order?</p>
                    </div>


                    <div
                        className={Classes.Button}
                        style={{ display:'flex',justifyContent:'space-between',padding:15,margin:'0px auto',width:290}}
                    >
                        <div>
                            <button
                                id='btn'
                                type="submit"
                                onClick={(e) => handleDeliveredOrder(e)}
                                style={{ backgroundColor:'#E12D39',color:'white',borderRadius:30,border:0 }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button
                                id='btn'
                                type="submit"
                                style={{borderRadius:30,backgroundColor:"#627D98",color:'#fff'}}
                                onClick={(e) => hideDeliveredDialog(e)}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {loading ? <Spinner /> : null}
            
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default ConfirmDelivered;