import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axiosInstance from "../../../utility/axios-instance";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import socket from '../../../socket';
import { Button, DialogContentText, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Tooltip, Typography } from '@mui/material';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NotificationDialog = (props) => {
  const { open,handleClose,setOpen, notifications, fetchMoreData, maxPages, page } =props;
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);

  const handleRedirect = async (redirectTab,id) => {
    try{
      navigate(redirectTab);
      await axiosInstance.put(`/adminNotifications/${id}`);
    } 
    catch(err){
      console.log("Error in updating notification", err);
    }
    finally{
      socket?.emit("New Notification", user);
      setOpen(false);
    }
  }

  const handleSeeAllNotifications = () => {
    navigate('/settings/myNotifications');
    setOpen(false);
  }

  const handleMarkAllAsRead = async () => {
    try{
      await axiosInstance.put(`/adminNotifications/markAllAsRead`)
      socket?.emit("New Notification", user);
    }
    catch(err){
      console.log(err);
    }
    finally{
      setOpen(false);
    }
  }
  
    return (
      <React.Fragment>
        <Dialog
          // fullScreen
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: 'absolute',
              top: '7%',
              right: '1%',
              margin: 0,
              borderRadius: 15,
            }
          }}
        >
          <DialogTitle 
            style={{
              display:'flex',justifyContent:'space-between',
            }}
          >
            Notifications
            <div >
              {/* Fo Incoming feature */}
              <span style={{marginRight:'10px', cursor: 'pointer'}} onClick={handleSeeAllNotifications}>
                <Tooltip title="See All" arrow> <ReadMoreIcon color='primary'/> </Tooltip>
              </span>
              
              <span style={{marginRight:'10px', cursor: 'pointer'}} onClick={handleMarkAllAsRead}>
                <Tooltip title="Mark all as read" arrow> <DraftsOutlinedIcon color='primary'/> </Tooltip>
              </span>
              <CloseIcon style={{color:'gray',cursor:'pointer'}} onClick={handleClose}/>
            </div>
            
          </DialogTitle>
          <DialogContent dividers
            style={{ 
              padding:'0px 0px 0px 15px',
              overflowY:'hidden',
              borderRadius: 10
            }}
          >
            {notifications?.length !== 0 && 
              <InfiniteScroll
                dataLength={notifications?.length}
                next={fetchMoreData}
                hasMore={true}
                height={'auto'}
                style={{maxHeight:'75vh'}}
                loader={
                  page-1 !== maxPages ? 
                  <ListItem>
                    <ListItemIcon>
                      <Skeleton variant="circular" width={40} height={40} />
                    </ListItemIcon>
                    <ListItemText>
                      <Skeleton variant="text"  />
                      <Skeleton variant="text"  />
                      <Skeleton variant="text"  width='50%'/>
                    </ListItemText>
                  </ListItem>
                  : ''}
              >
                <List>
                  {notifications?.map((item,index) => (
                      <ListItemButton
                        key={item?.id}
                        onClick={() => handleRedirect(item?.redirection_link, item?.id)}
                        sx={{
                          backgroundColor: item?.isRead === true ? 'white': '#f9f9f9',
                          borderRadius: 5,  
                          marginBottom: index === notifications.length - 1 ? 0 : 1,
                        }}
                      >
                        <ListItemIcon>
                            {item?.isRead === true ? (
                                <DraftsOutlinedIcon 
                                  style={{ color: 'lightgrey' }} 
                                />
                            ) : (
                                <MarkEmailUnreadOutlinedIcon 
                                  style={{ color: 'grey' }} 
                                />
                            )}
                          </ListItemIcon>
                          <ListItemText 
                            primary={
                              <Typography 
                                style={{ 
                                  color: item?.isRead === true ? 'lightgrey': 'grey', 
                                  fontWeight: item?.isRead === true ? 'normal': 'bold',
                                  fontSize: 14
                                }}
                              >{item.content}</Typography>
                            }
                            secondary={
                                <>
                                    <Typography style={{ color: item?.isRead === true ? 'lightgrey': 'grey' }}>{item.sub_content}</Typography>
                                    <Typography variant="body2" style={{ color: item?.isRead === true ? 'lightgrey': 'grey' }}>
                                      {formattedDate(item?.created_at)}
                                    </Typography>
                                </>
                            }
                          />
                      </ListItemButton>
                  ))}
                  {/* Not have Notifications */}
                  {maxPages === 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <DraftsOutlinedIcon 
                          style={{ color: 'lightgrey' }} 
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary = "No notifications have been received."
                      >
                      </ListItemText>
                  </ListItem>
                  )}
                </List>
              </InfiniteScroll>
            }
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
}

const formattedDate = (date) => {
    // date is today
    if(moment(date).isSame(moment(), 'day')){
        return moment(date).format('h:mm:ss A');
    }
    else {
      const yesterday = moment().subtract(1, 'days').startOf('day');
      if(moment(date).isSame(yesterday, 'day')){
        return `Yesterday, ${moment(date).format('h:mm:ss A')}`;
      }
      else {
        return moment(date).format('MMM DD YYYY');
      }
    }
}

export default NotificationDialog;