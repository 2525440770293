import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/cancel.module.css';
import axiosInstance from '../../../utility/axios-instance';
import { setCancels } from '../../../store/cancelsSlice';
import resetState from '../../../store/actions';
import Cancel from './cancel';
import Spinner from '../../../components/spinner';
import Search from '../../../components/search';
import CancelMobile from './cancelMobile';
import NewPagination from '../../../components/newPagination';

const Orders = (props) => {
	const cancels = useSelector((state) => state.cancels);
	const dispatch = useDispatch();
	const ref = useRef();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const [text, setText] = useState('');

	const fetchCancels = async (page) => {
		setLoading(true);
		setError(null);
		
		const params = {
			page,
		};

		await axiosInstance
			.get(`/cancels?text=${text}`, {
				params,
			})
			.then((response) => {
				dispatch(setCancels(response.data.data));
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					fetchCancels(page);
				}, 1000000);
			});
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page');
		fetchCancels(page ? page : 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="d-flex flex-column-reverse flex-md-row justify-content-between mb-5">
				<div className="px-3 py-1 border border-dark rounded text-center">
					<p className="mb-0 fs-5">
						<strong>Cancelled Orders</strong>
					</p>
				</div>
				<div className={Classes.ReloadBtn}>
				<button
					type="button"
					className={`btn btn-outline-dark`}
					onClick={() => {
						fetchCancels();
					}}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
					{loading ? 'Loading...' : 'ReLoad'}
				</button>
				</div>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}
				
			<Search func={fetchCancels} text={text} setText={setText} />

			<div className={Classes.TableMobile}>
				{cancels.cancels.map((cancel) => (
					<CancelMobile key={cancel._id} cancel={cancel} />
				))}

			</div>

			<table className={`${Classes.Table} table`}>
				<thead>
					<tr>
						<th scope="col">Order</th>
						<th scope="col">Canceled At</th>
						<th scope="col">Status</th>
						<th scope="col">Refund Amount</th>
						<th scope="col">Refund Status</th>
						<th scope="col">Cancellation Reason</th>
					</tr>
				</thead>
				<tbody
					style={{
						fontSize: '.8rem',
					}}
				>
					{cancels.cancels.map((cancel) => (
						<Cancel key={cancel._id} cancel={cancel} />
					))}
				</tbody>
			</table>
			{cancels.cancels.length > 0 ? (
                <nav aria-label="Page navigation">
                    <NewPagination 
                        totalPage={cancels}
                        fetchData={fetchCancels}
                    />
                </nav>
            ) : null}
			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default Orders;
