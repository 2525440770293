import { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import axiosInstance from "../../../utility/axios-instance";
import { BiArrowBack } from "react-icons/bi";
import { Spinner } from "react-bootstrap";
import Classes from "../ordersNew/css/orders.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import fileDownload from "js-file-download";
import Classes2 from "./css/estimateId.module.scss";
import Classes3 from "../affiliateDashboard/scss/createCommission.module.scss";
import { services } from "../../../utility/checkRoleBasedPermission";
import Permission from "../../auth/permissions";
import { useSelector } from "react-redux";
import socket from '../../../socket';

const EstimateId = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [estimate, setEstimate] = useState(null);
  const [draftOrder, setDraftOrder] = useState(null);
  const [order, setOrder] = useState(null);

  const [text, setText] = useState("");
  const inputRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const [loading2, setLoading2] = useState(false);
  const [admins, setAdmins] = useState([]);

  const user = useSelector((state) => state?.auth?.user);

  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: 1,
    total: 0,
  });
  const apiRequest = useRef();

  const getDraftOrder = async () => {
    if (!estimate?.draftOrderName) return;
    axiosInstance
      .get(`/pgOrder/admin/draftOrder/${estimate.draftOrderName}`)
      .then((res) => {
        setDraftOrder(res.data.data.draftOrder);
      })
      .catch((err) => {});
  };

  const getOrder = async () => {
    if (!estimate?.draftOrderName) return;
    axiosInstance
      .get(`/pgOrder/admin/order/${estimate.orderName}`)
      .then((res) => {
        setOrder(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDraftOrder();
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimate]);

  const years = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dn = new Date();

  const createdAt = (createdAt) => {
    const date = new Date(createdAt);
    let createdAtDate =
      date.getFullYear() === dn.getFullYear()
        ? date.getMonth() === dn.getMonth()
          ? date.getDate() === dn.getDate()
            ? `Today at ${date.toLocaleTimeString()}`
            : date.getDate() === dn.getDate() - 1
            ? `Yesterday at ${date.toLocaleTimeString()}`
            : years[date.getMonth()] +
              " " +
              date.getDate() +
              " at " +
              date.toLocaleTimeString()
          : years[date.getMonth()] +
            " " +
            date.getDate() +
            " at " +
            date.toLocaleTimeString()
        : years[date.getMonth()] + " " + date.getFullYear();

    return createdAtDate;
  };

  const navigate = useNavigate();

  const { id } = useParams();

  const fetchEstimate = async () => {
    if (id) {
      setLoading(true);
      axiosInstance
        .get(`/estimate/admin/${id}`)
        .then((response) => {
          let ti = 0;
          for (let i = 0; i < response?.data?.data?.products?.length; i++) {
            ti += response?.data?.data?.products?.[i].quantity;
          }
          setTotalItems(ti);
          setEstimate(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    fetchEstimate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchAdmins = (page = 1, searchText = "", pagination = false) => {
    setLoading2(true);

    if (apiRequest.current) apiRequest.current?.abort();

    apiRequest.current = new AbortController();

    axiosInstance
      .get(`/admin?page=${page}&text=${searchText}`, {
        signal: apiRequest.current?.signal,
      })
      .then((res) => {
        // console.log(res.data.data);
        if (pagination) {
          setAdmins((prev) => [...prev, ...res.data.data.users]);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        } else {
          setAdmins(res.data.data.users);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    fetchAdmins(1, text);
  }, [text]);

  const updateEstimate = async (data) => {
    setLoading(true);

    axiosInstance
      .post(`/estimate/admin/${id}`, data)
      .then((response) => {
        let ti = 0;
        for (let i = 0; i < response?.data?.data?.products?.length; i++) {
          ti += response?.data?.data?.products?.[i].quantity;
        }
        setTotalItems(ti);
        setEstimate(response.data.data);
        
        socket?.emit("New Notification", user);
      })
      .catch((err) => {
        setError(err.response?.data?.message ?? "Something went wring");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading ? (
        <div className={Classes.Spinner}>
          <Spinner animation='border' variant='light' />
        </div>
      ) : null}
      <div className={Classes.container} id='color'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          id='hideHeader'
        >
          <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
            <div>
              <button
                type='button'
                className='btn btn-light'
                onClick={() => {
                  navigate("/estimates");
                }}
              >
                <BiArrowBack />
              </button>
            </div>

            {estimate ? (
              <div>
                <h6 className={Classes.draftOrderDetail}>
                  {createdAt(estimate.createdAt)}
                </h6>
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        </div>

        {error ? (
          <Alert variant='danger' onClose={() => setError(false)} dismissible>
            <Alert.Heading>{error}</Alert.Heading>
          </Alert>
        ) : null}

        {estimate ? (
          <div
            className={`mt-4 mb-4 d-flex align-items-start ${Classes2.C}`}
            style={{
              gap: "1rem",
              flexWrap: "wrap",
              // flexDirection: 'row-reverse'
            }}
          >
            <div
              className={Classes2.CC}
              style={{
                flexGrow: 1,
              }}
            >
              {estimate?.products ? (
                <div
                  className='pt-3 rounded flex-grow-1'
                  style={{
                    boxShadow:
                      "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  }}
                >
                  <h6
                    className=''
                    style={{
                      marginLeft: "1rem",
                    }}
                  >
                    Products
                  </h6>
                  {estimate?.products.map((p) => {
                    const pp = p.productId;
                    return (
                      <div
                        key={pp._id}
                        className='p-3 mt-3 border-bottom fs-6 d-flex align-items-start'
                        style={{
                          gap: ".5rem",
                        }}
                      >
                        <div>
                          <img
                            src={
                              pp?.images?.["400x400"]?.mainImageUrl &&
                              pp?.images?.["400x400"]?.mainImageUrl !== ""
                                ? pp?.images?.["400x400"]?.mainImageUrl
                                : process.env.REACT_APP_IMAGE_COMMING_SOON
                            }
                            alt=''
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#6d7175",
                          }}
                        >
                          <p
                            style={{
                              color: "#0d6efd",
                            }}
                          >
                            {pp.basicCollectionDataBrandData.productName}
                          </p>
                          <p
                            style={{
                              color: "#f66200",
                            }}
                          >
                            {/* <strong>
                              {pp.basicCollectionDataBrandData.brandName}
                            </strong> */}
                            {pp.basicCollectionDataBrandData.brandName}
                          </p>
                          <p>
                            {/* <strong>
                              {pp.basicCollectionDataBrandData.sku}
                            </strong> */}
                            {pp.basicCollectionDataBrandData.sku}
                          </p>

                          <p className=''>
                            {/* <strong>
                              Quantity: {p.quantity.toString().padStart(2, "0")}
                            </strong> */}
                            Quantity: {p.quantity.toString().padStart(2, "0")}
                          </p>
                          <p
                            style={{
                              color: "#0d6efd",
                            }}
                          >
                            ₹{pp.priceDataAffiliateData.listingPrice}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {estimate?.attachments && estimate?.attachments.length > 0 ? (
                <div
                  className='pt-3 rounded flex-grow-1'
                  style={{
                    boxShadow:
                      "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  }}
                >
                  <h6
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Attachments
                  </h6>
                  {estimate?.attachments.map((a, index) => {
                    // const pp = p.productId;
                    return (
                      <div
                        key={a}
                        className='p-3 mt-2 border-bottom d-flex align-items-start justify-content-between'
                        style={{
                          gap: ".5rem",
                          cursor: "pointer",
                          color: "#6d7175",
                          fontSize: "14px !important",
                        }}
                        onClick={() => {
                          // const aTag = document.createElement("a");
                          // aTag.href = estimate.attachments2[index];
                          // aTag.setAttribute("download", a);
                          // document.body.appendChild(aTag);
                          // aTag.click();
                          // aTag.remove();

                          Axios.get(estimate.attachments2[index], {
                            responseType: "blob",
                          }).then((res) => {
                            fileDownload(res.data, a);
                          });
                        }}
                      >
                        <p>{a}</p>

                        <div>
                          <FontAwesomeIcon icon='fa-solid fa-download' />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {estimate.comment ? (
                <div
                  className='py-3 mt-3 rounded flex-grow-1'
                  style={{
                    boxShadow:
                      "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  }}
                >
                  <h6
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Comment
                  </h6>

                  <pre
                    className='mt-3'
                    style={{
                      paddingLeft: "1rem",
                      color: "#6d7175",
                      fontSize: "14px !important",
                    }}
                  >
                    {estimate.comment ?? ""}
                  </pre>
                </div>
              ) : null}

              {order ? (
                <Link
                  to={`/ordersNew/${order.orderName}`}
                  className='p-3 mt-4 rounded flex-grow-1'
                  target='_blank'
                  style={{
                    display: "block",
                    color: "black",
                    boxShadow:
                      "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  }}
                >
                  <h6>Order</h6>

                  <div
                    className='d-flex align-items-start'
                    style={{
                      gap: "1rem",
                      fontSize: "14px",
                      // fontWeight: "500",
                      color: "#6d7175",
                    }}
                  >
                    <p>{order.orderName}</p>
                    <p
                      style={{
                        color: "blue",
                      }}
                    >
                      {order.status}
                    </p>
                    <p
                      style={{
                        color: "#ff6200",
                      }}
                    >
                      ₹{order.OPaymentDetail.totalPrice}
                    </p>
                  </div>
                </Link>
              ) : null}

              {draftOrder ? (
                <Link
                  to={`/draftOrder/${draftOrder.draftOrderName}`}
                  target='_blank'
                  className='p-3 mt-4 rounded flex-grow-1'
                  style={{
                    display: "block",
                    color: "black",
                    boxShadow:
                      "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  }}
                >
                  <h6>Draft Order</h6>

                  <div
                    className='d-flex align-items-start'
                    style={{
                      gap: "1rem",
                      fontSize: "14px",
                      // fontWeight: "500",
                      color: "#6d7175",
                    }}
                  >
                    <p>{draftOrder.draftOrderName}</p>
                    <p
                      style={{
                        color: "blue",
                      }}
                    >
                      {draftOrder.status}
                    </p>
                    <p
                      style={{
                        color: "#ff6200",
                      }}
                    >
                      ₹{draftOrder.DoPaymentDetail.totalPrice}
                    </p>
                  </div>
                </Link>
              ) : null}

              <div
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Permission service={services?.estimates} permission={"create"}>
                  {!estimate.draftOrderName && estimate.status === "Pending" ? (
                    <Link
                      to={
                        estimate.rejected
                          ? ""
                          : `/createOrder?estimateId=${estimate._id}`
                      }
                      aria-disabled={estimate.rejected}
                      target='_blank'
                      style={{
                        outline: "none",
                        border: "none",
                        borderRadius: "8px",
                        backgroundColor: "green",
                        color: "white",
                        padding: "2px 16px",
                        opacity: estimate.rejected ? "0.5" : "1",
                      }}
                    >
                      Create Order
                    </Link>
                  ) : null}
                </Permission>

                <Permission service={services?.estimates} permission={"delete"}>
                {!estimate.orderName ? (
                  <button
                    style={{
                      outline: "none",
                      border: "none",
                      borderRadius: "8px",
                      backgroundColor: "red",
                      color: "white",
                      padding: "2px 16px",
                      opacity: estimate.rejected ? "0.5" : "1",
                    }}
                    disabled={estimate.status === "Rejected"}
                    onClick={() => {
                      updateEstimate({ status: "Rejected" });
                    }}
                  >
                    {estimate.status === "Rejected" ? "Rejected" : "Reject"}
                  </button>
                ) : null}
                </Permission>
              </div>
            </div>

            <div className={Classes2.CC}>
              <div
                className={`px-3 py-2 rounded border`}
                style={{
                  boxShadow:
                    "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  minWidth: "260px",
                }}
              >
                <h6>CUSTOMER</h6>

                <div
                  className='mt-2'
                  style={{
                    fontSize: "14px",
                    color: "#6d7175",
                  }}
                >
                  <p>{estimate.user.displayName}</p>
                  <p>{estimate.user.phone}</p>
                  <p>{estimate.user.email}</p>
                </div>
              </div>

              <div
                className={`px-3 py-3 rounded border mt-3`}
                style={{
                  boxShadow:
                    "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  minWidth: "260px",
                }}
              >
                <h6>Assign To</h6>

                <div
                  className={Classes2.OrderTextSearch}
                  onClick={() => {
                    inputRef?.current?.focus();
                    setShowModal(true);
                  }}
                >
                  <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
                  <div>
                    <input
                      type='text'
                      placeholder='Search Orders'
                      readOnly
                      value={text}
                    />
                  </div>
                </div>
              </div>

              {estimate.assignedTo ? (
                <div
                  className={`px-3 py-2 rounded border mt-3`}
                  style={{
                    boxShadow:
                      "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                    minWidth: "260px",
                  }}
                >
                  <h6>ASSIGNED TO</h6>

                  <div
                    className='mt-2'
                    style={{
                      fontSize: "14px",
                      color: "#6d7175",
                    }}
                  >
                    <p>{estimate.assignedTo.username}</p>
                    <p>{estimate.assignedTo.name}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      {showModal && (
        <div className={Classes3.Modal}>
          <div className={Classes3.Head}>
            <h5>All Admins</h5>
            <FontAwesomeIcon
              icon='fa-solid fa-xmark'
              size='xl'
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>

          <div
            className={Classes3.OrderTextSearch}
            onClick={() => {
              inputRef?.current?.focus();
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
            <div>
              <input
                ref={inputRef}
                type='text'
                placeholder='Search Admin'
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>

          <div
            className={Classes3.Orders}
            style={{
              height: "calc(100% - 2rem - 32px - 36px)",
            }}
          >
            {admins.map((adm) => {
              return (
                <div
                  key={adm._id}
                  className='border-bottom px-4 py-2'
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateEstimate({ assignedTo: adm._id });
                    setShowModal(false);
                  }}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "1rem" }}
                    >
                      <h6 className='mb-0'>{adm.username}</h6>
                    </div>
                  </div>

                  <div className='mt-2' style={{ fontSize: "14px" }}>
                    <p>{adm.name}</p>
                    <p>{adm.role}</p>
                  </div>
                </div>
              );
            })}

            {loading2 && <p className='my-1'>Loading ...</p>}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EstimateId;
