import React, { Fragment, useEffect, useState } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

const InvoiceDialog = (props) => {
    const {
        invoiceDialogOpen,
        invoiceRowData,
        handleInvoiceDialogClose,
        setReRender
    } = props;

    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [invoiceDate, setInvoiceDate] = useState(new Date);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceUrls, setInvoiceUrls] = useState([]);
    const invoiceFilesRef = React.useRef(null);

    const setInvoiceNumberValue = (invoiceNumber) => {
        setInvoiceNumber(invoiceNumber)
    }

    useEffect(() => {
        setInvoiceDate(dayjs(invoiceRowData?.invoiceDate || new Date))
        setInvoiceNumber(invoiceRowData?.invoiceNumber || '')
        setInvoiceUrls(invoiceRowData?.invoiceUrl?.map((invoiceUrl) => {
            return {
                url: invoiceUrl,
                name: invoiceUrl?.split('/')?.pop()?.split('?')?.shift(),
                type: "Uploaded",
            }
        }) || [])
    }, [invoiceRowData])

    const handleFileInputChange = () => {
        const invoiceFiles = invoiceFilesRef?.current?.files;
        if (invoiceUrls?.length + invoiceFiles?.length > 2) {
            invoiceFilesRef.current.files = null;
            alert('Only 2 invoices can be uploaded!');
            return;
        }
        if (invoiceFiles?.length > 0) {
            const urls = [];
            for (let i = 0; i < invoiceFiles?.length; i++) {
                urls.push({
                    url: URL.createObjectURL(invoiceFiles[i]),
                    name: invoiceFiles[i]?.name,
                    type: "To Upload",
                })
            }
            setInvoiceUrls((prevInvoiceUrls) => [...prevInvoiceUrls, ...urls])
        }
    }

    const handleInvoiceUpload = (event) => {
        event.preventDefault();
        setLoading(true);

        var formData = new FormData();
        for (let i = 0; i < invoiceFilesRef?.current?.files?.length; i++) {
            formData.append("invoicesAttached", invoiceFilesRef?.current?.files[i]);
        }
        formData.append("invoiceNumber", invoiceNumber);
        formData.append("invoiceDate", invoiceDate);

        axiosInstance
            .put(
                `/purchaseOrders/uploadInvoice/${invoiceRowData?.purchaseOrderName}`,
                formData
            )
            .then((res) => {
                const successMessage = res?.data?.message || "Invoice uploaded successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleInvoiceDialogClose();
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message || "Could not update invoice!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
                setReRender((prev) => !prev);
            });
    }

    const handleInvoiceDelete = (invoiceUrlToBeDeleted) => {
        setInvoiceUrls((prevInvoiceUrls) => {
            return prevInvoiceUrls.filter((invoiceUrl, index) => invoiceUrl?.url !== invoiceUrlToBeDeleted?.url)
        })
        const invoiceFilesSelected = invoiceFilesRef?.current?.files;
        if (invoiceFilesSelected?.length > 0) {
            const files = [];
            for (let i = 0; i < invoiceFilesSelected?.length; i++) {
                if (invoiceFilesSelected[i]?.name === invoiceUrlToBeDeleted?.name) continue;
                files.push(invoiceFilesSelected[i])
            }
            invoiceFilesRef.current.files = files;
        }
    }

    return (
        <Fragment>
            {invoiceDialogOpen && (
                <div
                    className={Classes.confirmModal}
                    style={{ top: '24%', boxShadow: '0 0px 9px 0' }}
                >
                    <div style={{ fontSize: '1rem', padding: '10px' }}>
                        <h4>Upload Invoice - {invoiceRowData?.purchaseOrderName}</h4>
                    </div>
                    <form method='post' onSubmit={handleInvoiceUpload}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                            <TextField
                                required
                                label="Invoice Number"
                                name="invoiceNumber"
                                variant="outlined"
                                size="small"
                                style={{ marginTop: '10px' }}
                                value={invoiceNumber}
                                onChange={(e) => {
                                    setInvoiceNumberValue(e?.target?.value)
                                }}
                                fullWidth
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <DateField
                                        label="Invoice Date"
                                        value={invoiceDate}
                                        onChange={(newInvoiceDate) => setInvoiceDate(newInvoiceDate)}
                                        slotProps={{ textField: { size: 'small' } }}
                                        fullWidth
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <input
                                required
                                type="file"
                                id="invoice-upload"
                                name="invoicesAttached"
                                multiple={true}
                                disabled={invoiceUrls?.length >= 2}
                                className="form-control" aria-label="Upload Invoice"
                                ref={invoiceFilesRef}
                                style={{ marginTop: '10px' }}
                                onChange={handleFileInputChange}
                            ></input>
                        </div>

                        <div>
                            <List>
                                {
                                    invoiceUrls?.length > 0 &&
                                    invoiceUrls?.map((invoiceUrl, index) => {
                                        return (
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleInvoiceDelete(invoiceUrl)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemButton component="a" href={invoiceUrl?.url}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <FolderIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={invoiceUrl?.name || `Invoice ${index + 1}`}
                                                        secondary={invoiceUrl?.type || null}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>

                        <div style={{
                            bottom: '18%',
                            padding: '0px 9px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleInvoiceDialogClose}
                            >
                                CANCEL
                            </button>

                            <button
                                id='addCustomer'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default InvoiceDialog;