import React, { Fragment, useState } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';
import Spinner from "../../../../components/spinner";

const UpdateContactModal = (props) => {
    const [loading,setLoading] = useState(false);
    const {
        contacts,
        setContacts,
        errorMessage,
        num,
        type,
        selectedContact,
        setSelectedContact,
        handleNumChange
    } = props;



    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const button = document.getElementById('btn')
        button.innerHTML = 'Saving...';

        const data = {
            "email": event.target.email.value,
            "phoneNumber": event.target.phone.value,
            "firstName": event.target.firstName.value.trim(),
            "lastName": event.target.lastName.value ? event.target.lastName.value.trim(): undefined,
            "role": event.target.role.value,
        }

        if (type === "Vendor") {
            axiosInstance
                .put(`/vendor/vendorContactDetail/${selectedContact?.vendorContactDetailId}`, { ...data })
                .then((res) => {
                    setLoading(false);
                    const form = document.getElementById('form');
                    form.reset();
                    props.onHideModal();
                    setSelectedContact(res.data.data);
                    setContacts([...contacts, res.data.data]);
                }).catch((err) => {
                    console.log(err)
                    button.innerHTML = 'Create Vendor';
                })
        } else if (type === 'Location') {
            axiosInstance
                .put(`/location/locationContactDetail/${selectedContact?.locationContactDetailId}`, { ...data })
                .then((res) => {
                    setLoading(false);
                    const form = document.getElementById('form');
                    form.reset();
                    props.onHideModal();
                    setSelectedContact(res.data.data);
                    setContacts([...contacts, res.data.data]);
                }).catch((err) => {
                    console.log(err)
                    button.innerHTML = 'Create Vendor';
                })

        }
    }

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if (event.target.value.match(phoneno)) {
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false; 
        }
    }

    return (
        <Fragment>
            {loading && <Spinner />}
            {props.onShowModal && (
                <div className={Classes.modal} style={{ top:'10%',bottom:'13%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Contact Details</p>
                    </div>

                    <form 
                        method="post" onSubmit={handleSubmit} id='form'
                        style={{ overflowY:'auto',height:'73%' }}
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>

                        <div style={{ padding:20,height:338 }}>
                            <div style={{ display:'flex',justifyContent:'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField 
                                        required={true}
                                        id='firstName'
                                        name='firstName'
                                        label='First Name'
                                        autoComplete='off'
                                        placeholder='Enter Your First Name'
                                        defaultValue={selectedContact?.firstName && selectedContact?.firstName}
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField 
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        autoComplete='off'
                                        placeholder='Enter Your Last Name'
                                        defaultValue={selectedContact?.lastName && selectedContact?.lastName}
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <TextField 
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%'}}
                                        value={num}
                                        onBlur={(e) => handleMinLength(e)}
                                        onChange={(e) => handleNumChange(e)}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="email"
                                        id='email'
                                        name='email'
                                        label='Email'
                                        autoComplete='off'
                                        placeholder='Enter Your Email'
                                        defaultValue={selectedContact?.email && selectedContact?.email}
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                <p>Please enter a valid 10 digit mobile number</p>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id='role'
                                        name='role'          
                                        options={["Warehouse Manager", "Warehouse Executive"]}
                                        defaultValue={selectedContact?.role && selectedContact?.role}
                                        getOptionLabel={(option) => option || ""}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={'Role'} required={true} />}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>


                        <div className={Classes.DialogBtnBox} style={{bottom:"13%"}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={props?.onHideModal}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>

                    </form>
                </div>

            )}
        </Fragment>
    );
}


export default UpdateContactModal;