import React, { useEffect, useState } from "react";
import Classes from "./dashboard.module.css";
import { useNavigate } from "react-router-dom";
import SettingsItem from "../../../components/settingsMenu";
import { settingsData } from "../../../constants/settingsConstants";
import Permission from '../../auth/permissions';

const Settings = () => {
  const [settingType, setSettingType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    settingType && navigate(`${settingType}`);
  }, [settingType, navigate]);

  const handleItemClick = (type) => {
    setSettingType(type);
  };

  return (
    <>
      <div className={Classes.Container}>
        {settingsData.map((setting, index) => {
          return (
            <Permission service={setting?.service} permission="read">
              <SettingsItem
                key={index}
                onClick={() => handleItemClick(setting.navigate)}
                iconSrc={setting.src}
                iconAlt={setting.alt}
                title={setting.text}
                subTitle={setting?.subTitle}
              />
            </Permission>
          );
        })}
      </div>
    </>
  );
};

export default Settings;
