import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stores: [],
    activeStoreId: 0,
}

const storeLocationSlice = createSlice({
    name: 'storeLocation',
    initialState: initialState,
    reducers: {
        setStores: (state, action) => {
            state.stores = action.payload
        },
        setActiveStoreId: (state, action) => {
            state.activeStoreId = action.payload
        }
    }
})

export const {setStores,setActiveStoreId} = storeLocationSlice.actions

export default storeLocationSlice.reducer