import {
    GridToolbarDensitySelector,

    GridToolbarColumnsButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import SearchInput from './SearchInput';
import CustomExportButton from './CustomExportButton';
import React from 'react';
import Classes from './css/table.module.css';
import CreatedByModal from '../createdByFilter';
import CustomFilterPanel from './CustomFilters';
import FiltersButton from './filterListIcon';
import { Button } from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Autocomplete, TextField } from '@mui/material';

const CustomToolbar = (
    {
        searchFor,
        handleSearch,
        extraToolBarButtons,
        service,
        handleFilteredExport,
        filteredExport,
        isOtherExports,
        handleOtherExports, 
        otherExportLabel,
        createdByFilter,
        handleUserSelect, 
        users, 
        setUsers,
        multiFilters, 
        setMultiFilters,
        handleApplyFilter,
        filtersPanel,
        setFiltersPanel,
        applyFilter,
        setApplyFilter,
        handleRemoveAllFilter,
        downloadDocsBulk,
        rowSelectionModel,
        handleDownloadBulkDocs,
        handleSelectDocType,
        exportDocType
    }
) => {

    const DocsHandler = () => {
        return (
            <div style={{display:'flex'}}>
                <Autocomplete
                    id="multiple-limit-tags"
                    size="small"
                    disablePortal
                    options={["PI", "OrderReceipt", "Picklist", "Shipment", "Fulfillment Invoice", "All Docs"]}
                    onChange={(e, newValue) => handleSelectDocType(newValue)}
                    value={exportDocType}
                    disabled={rowSelectionModel?.length === 0 ? true : false}
                    sx={{width:'225px'}}
                    renderInput={(params) => (
                        <TextField {...params} label="Document Type" variant="outlined" />
                    )}
                />
                <Button
                    type="submit"
                    id='deleteBtn'
                    onClick={handleDownloadBulkDocs}
                    disabled={rowSelectionModel?.length === 0 && !exportDocType ? true : false}
                    variant="outlined"
                    size='small'
                    style={{
                        textTransform:'capitalize', margin:'auto', marginRight: 10, 
                        border: rowSelectionModel?.length !== 0 && exportDocType ? "1px solid #ed1c24" : "1px solid #0000001f",
                        boxShadow: rowSelectionModel?.length !== 0 && exportDocType ? "0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)" : '',
                        padding: '0px 8px', height:'40px', width:'122px' 
                    }}
                    sx={{ "& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px" }}}
                >
                    <SimCardDownloadIcon style={{color: rowSelectionModel?.length !== 0 && exportDocType ? '#ED1C24' : '#00000042'}} />
                    <span style={{
                        color: rowSelectionModel?.length !== 0 && exportDocType ? '#ED1C24' : '#00000042', marginTop:4, marginLeft:5 
                    }}>Export Docs</span>
                </Button>
            </div>
        )
    }

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            {/* <GridToolbarFilterButton /> */}
            <GridToolbarDensitySelector />
            <FiltersButton
                setFiltersPanel={setFiltersPanel} 
                filtersPanel={filtersPanel} 
                multiFilters={multiFilters}
                applyFilter={applyFilter}
            />
            <CustomExportButton
                service={service}
                handleFilteredExport={handleFilteredExport}
                filteredExport={filteredExport}
                isOtherExports={isOtherExports}
                handleOtherExports={handleOtherExports}
                otherExportLabel={otherExportLabel}
            />
            {extraToolBarButtons?.length > 0 ? extraToolBarButtons.map((button) => button) : null}
            <div
                className={Classes.SearchBox}
                style={{ border: 'none', marginLeft: 'auto', display:'flex' }}
            >
                {downloadDocsBulk && <DocsHandler />}
                {createdByFilter && <CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />}
                {handleSearch && <SearchInput searchFor={searchFor} handleSearch={handleSearch} />}
            </div>
            {filtersPanel && 
                <CustomFilterPanel 
                    multiFilters={multiFilters} 
                    setMultiFilters={setMultiFilters} 
                    handleApplyFilter={handleApplyFilter}
                    setApplyFilter={setApplyFilter}
                    handleRemoveAllFilter={handleRemoveAllFilter}
                /> 
            }
        </GridToolbarContainer>
    );
}

export default CustomToolbar;