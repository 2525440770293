import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DateTimePickerComponent(props) {
    const {label, value, setValue, id , setId, setClosed, startDateTime, color} = props;

    const handleClosedComponent = async () => {
        setId(id);
        setClosed(true)
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                timeSteps={{minutes : 1}}
                label={label}
                slotProps={{ textField: { size: 'small' } }}
                onClose={handleClosedComponent}
                sx={{ '& .MuiInputLabel-root': {color: color ? color: '#00000099'}}}
                onChange={(newValue) => {
                    setValue(`${newValue.$y}-${newValue.$M+1}-${newValue.$D} ${newValue.$H}:${newValue.$m}:00`)
                    setClosed(false)
                }}
                minDate={(startDateTime && dayjs(new Date(startDateTime)).startOf('hour') )|| undefined}
            />
        </LocalizationProvider>
    );
}