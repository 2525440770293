import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	salesUsers: [],
};

const reportDashboardSlice = createSlice({
	name: 'reportDashboard',
	initialState: initialState,
	reducers: {
		setReportDashboardData: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setReportDashboardData, reset } = reportDashboardSlice.actions;

export default reportDashboardSlice.reducer;