import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import axiosInstance from './axios-instance';

const firebaseConfig = {
	apiKey: 'AIzaSyB9Zn3nePJDUYsgux6EIEOgnqF0fVXq-jY',
	authDomain: 'depo24-d2ae5.firebaseapp.com',
	projectId: 'depo24-d2ae5',
	storageBucket: 'depo24-d2ae5.appspot.com',
	messagingSenderId: '488988496713',
	appId: '1:488988496713:web:364f10ae0ba33c3450c098',
	measurementId: 'G-G55K09KDYM',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

getToken(messaging, {
	vapidKey:
		'BCAIzXH1wq1r2VTrnrsFh99SIkHuc0s1xtwa-MQNNgnVChHzVPaFBl4cBuovaSyuPfVyTVz3mCPTkq8vzguun_4',
}).then((currentToken) => {
	if (currentToken) {
		// console.log('Current token: ', currentToken);
		axiosInstance
			.post('/admin/auth/firebaseTokens', {
				token: currentToken,
			})
			.then((res) => {
				// console.log(res.data);
			})
			.catch((err) => {
				localStorage.setItem('token', currentToken);
				// console.log(err);
			});
	} else {
		// console.log('Can not get token');
	}
});


function requestPermission() {
	if ('Notification' in window) {
		// console.log('Requesting permission...');
		Notification.requestPermission().then((permission) => {
			if (permission === 'granted') {
				// console.log('Notification permission granted.');
			} else {
				// console.log('Do not have permission.');
			}
		});
	}
}

requestPermission();

onMessage(messaging, (payload) => {
	// console.log('Message received. ', payload);
	// ...
});
