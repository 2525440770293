import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	interWarehouseMove: [],
};

const interWarehouseMovesSlice = createSlice({
	name: 'interWarehouseMoves',
	initialState: initialState,
	reducers: {
		setInterWarehouseMoves: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setInterWarehouseMoves, reset } = interWarehouseMovesSlice.actions;

export default interWarehouseMovesSlice.reducer;