import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	purchaseOrders: [],
};

const inboundPurchaseOrdersSlice = createSlice({
	name: 'inboundPurchaseOrders',
	initialState: initialState,
	reducers: {
		setInboundPurchaseOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
		setInboundPurchaseOrder: (state, action) => {
			const inboundPurchaseOrder = action.payload;
			const index = state.purchaseOrders.findIndex(
				(e) => e._id === inboundPurchaseOrder._id
			);
			if (index !== -1) {
				state.purchaseOrders[index] = inboundPurchaseOrder;
			}
		},
	},
});

export const { setInboundPurchaseOrders, reset, setInboundPurchaseOrder } = inboundPurchaseOrdersSlice.actions;

export default inboundPurchaseOrdersSlice.reducer;
