import { Fragment, useEffect, useState } from 'react';
import CreateInventoryView from './CreateInventoryMove';
import axiosInstance from '../../../utility/axios-instance';
import Alert from 'react-bootstrap/Alert';
import Table from '../../../components/table/table';
import Button from '@mui/material/Button';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { Typography } from '@mui/material';
import ExportDatas from '../../../components/exportDataDialog';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';
import MobileResponsive from './mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { ImportFileComponent } from '../../../components/importFileComponent';
import { useSelector } from 'react-redux';
import { setInventoryMovesHistory } from '../../../store/inventoryMoveSlice';


const InventoryMove = () => {
    const columnVisibilityModel = {
        locationName: false,
    }
    const columns = [
        {
            field: `sku`,
            headerName: `SKU`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `moveQty`,
            headerName: `Move Qty`,
            flex: 0.6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `type`,
            headerName: `From Bin Type`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `fromBin`,
            headerName: `From Bin`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `toBin`,
            headerName: `To Bin`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `locationCode`,
            headerName: `Location Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `locationName`,
            headerName: `Location Name`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `user`,
            headerName: `User`,
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `createdAt`,
            headerName: `Created At`,
            flex: 1.6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: 'isReverted',
            headerName: 'Revert',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                params.value === false ?
                    <Permission service={services?.inventoryMove} permission={"delete"}>
                        <FormDialog
                            dialogTitle="Confirm Inventory Revert"
                            dialogActionType="confirmation"
                            openDialogButtonName="Revert"
                            submitDialogButtonName="Confirm"
                            onConfirmation={() => { handleRevert(params?.row?.inventoryMoveHistoryId) }}
                        >
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                This action would permanently move inventory in reverse direction?
                            </Typography>
                        </FormDialog>
                    </Permission>
                    :<Button variant="contained" color="primary" disabled>Reverted</Button>
                    
            )
        },
    ];

    const binType = {
        sale: 'Saleable',
        rej: 'Rejected',
        qc: "QC",
        pack: "Packing",
        return: "Returned",
        stag: "Staging"
    };
    const [sku, setSku] = useState("");
    const [type, setType] = useState("");
    const [toType, setToType] = useState("")
    const [selectedToBin, setSelectedToBin] = useState('');
    const [selectedFromBin, setSelectedFromBin] = useState('');
    const [suggestedBins, setSuggestedBins] = useState([]);
    const [availableQty, setAvlQty] = useState(0)
    const [fromBin, setFromBin] = useState([])
    const [toBins, setToBins] = useState([])
    const [locationCodes, setLocationCodes] = useState([])
    const [selectedLocationCode, setSelectedLocationCode] = useState([])
    const [qtyError, setQtyError] = useState(false);
    const [binError, setBinError] = useState(false)
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("")
    const [popup, setPopup] = useState(false)
    const [reRender, setReRender] = useState(false);
    const [open,setOpen] = useState(false);
    const handleExportDialog = () => {
        setOpen(true);
    }

    const inventoryMovesHistory = useSelector((state) => state.inventoryMovesHistory);

    const handleClose = () => {
        setOpen(false);
    }


    const convertDataForSelect = (data) => {
        let opts = data.map(item => {
            let option = {
                label: item.code
            }
            return option;
        });
        return opts;
    }

    const hidePopup = () => {
        setSku('');
        setFromBin([]);
        setSelectedToBin('')
        setToType("");
        setType("");
        setAvlQty(0)
        setPopup(false)
    }

    useEffect(() => {
        if (selectedLocationCode) {
            axiosInstance.get(`/inventory/bins/?locationCode=${selectedLocationCode}&type=${toType}`)
                .then(res => {
                    let bins = res.data.data.bins;
                    let opts = convertDataForSelect(bins);
                    setToBins(opts)
                })
                .catch(error => {
                    console.log(error);
                    setError(error.response.data.message)
                })
        }
        setSelectedToBin('')
    }, [toType, selectedLocationCode])

    useEffect(() => {
        axiosInstance.get(`/location/locations?text=`)
            .then(res => {
                let locations = res?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            })
            .catch(error => {
                setError(error?.response?.data?.message)
            })
        setSelectedLocationCode('')
    }, [])

    const handleRevert = (Id) => {

        axiosInstance.get(`/inventory/revertInventoryMove/${Id}`)
            .then(res => {
                setSuccess("Inventory Revert Successfully")
                setReRender(!reRender)
            })
            .catch(error => {
                console.log(error);
                setError(error.response.data.message)
            })
    }


    useEffect(() => {
        if (sku && type && selectedLocationCode) {
            axiosInstance.post(`/inventory/suggestedBin?locationCode=${selectedLocationCode}`, {
                sku, type
            })
                .then(res => {
                    setFromBin(res.data.data.mapping)
                }).catch(error => {
                    console.log("Error ", error)
                    setError(error.response.data.message)
                })
        }
        setSelectedFromBin("")

    }, [sku, type, selectedLocationCode]);

    useEffect(() => {
        setAvlQty(0)
        if (selectedFromBin) {
            axiosInstance.post(`/inventory/SkuQtyInBin?locationCode=${selectedLocationCode}`, {
                sku, type, code: selectedFromBin.code
            })
                .then(res => {
                    setAvlQty(res.data.data.SkuAvlQty)
                }).catch(error => {
                    console.log("Error ", error)
                    setError(error.response.data.message)
                })
        }
    }, [selectedFromBin]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess(null);
        setError(null);
        let { moveQty, sku, fromBin, toBin, locationCode } = e.target.elements
        if (fromBin.value === toBin.value) {
            alert("From and To Bin cannot be same!")
            return
        }
        if (!qtyError) {
            axiosInstance.post('/inventory/createInventoryMove', {
                moveQty: moveQty.value,
                sku: sku.value,
                fromBin: fromBin.value,
                toBin: toBin.value,
                type,
                locationCode: locationCode?.value
            })
                .then(res => {
                    setSuccess("Inventory Move Successfully")
                    e.target.moveQty.value = ""
                    hidePopup();
                    setReRender(!reRender)
                })
                .catch(error => {
                    console.log("Error ", error)
                    setError(error?.response?.data?.message)
                })
        }
        else {
            alert("Cannot Move Inventory")
        }
    }

    return (
        <Fragment>
            <div id='header' style={{ display:'flex',marginTop:-5,justifyContent:'space-between',width:'99%' }}>
	            <div style={{display:"flex"}}>
                    <Permission service={services?.inventoryMove} permission={"create"}>
                        <Button variant="contained" color="primary" onClick={() => setPopup(true)} style={{textTransform:'capitalize',marginRight:10}}>
                            Move Inventory
                        </Button>
                    </Permission>
                    <Button  variant="contained" color="primary" sx={{ textTransform:'capitalize' }} onClick={handleExportDialog}>
                        Export
                    </Button>
                </div>
                <Permission service={services?.inventoryMove} permission={"create"}>
                     <div style={{marginTop:-23, marginLeft: '10px'}}>
                         <p>Inventory Move via CSV</p>
                         <ImportFileComponent
                             endpoint="/inventory/createBulkInventoryMove"
                             serviceName={services?.inventory}
	                             type="inventoryMove"
                             templateDownloadEndpoint={"/templates/inventoryMoveCsv"}
                             setReRender={setReRender}
                         />
                     </div>
                 </Permission>
             </div>
            <div style={{marginTop:10}}>
                {error ? (
                    <Alert variant="danger" onClose={() => setError(null)} dismissible>
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>{error}</p>
                    </Alert>
                ) : null}
                {success ? (
                    <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>{success}</p>
                    </Alert>
                ) : null}
                <CreateInventoryView
                    binType={binType}
                    fromBin={fromBin}
                    toType={toType}
                    type={type}
                    sku={sku}
                    availableQty={availableQty}
                    selectedFromBin={selectedFromBin}
                    setSku={setSku}
                    setType={setType}
                    setToType={setToType}
                    setSelectedToBin={setSelectedToBin}
                    selectedToBin={selectedToBin}
                    setSelectedFromBin={setSelectedFromBin}
                    toBins={toBins}
                    handleSubmit={handleSubmit}
                    setQtyError={setQtyError}
                    qtyError={qtyError}
                    suggestedBins={suggestedBins}
                    open={popup}
                    handleClose={hidePopup}
                    locationCodes={locationCodes}
                    selectedLocationCode={selectedLocationCode}
                    setSelectedLocationCode={setSelectedLocationCode}
                />
                
                <div className={Classes.MuiTable}>
                    <Table 
                        rowId={'inventoryMoveHistoryId'} 
                        columns={columns} 
                        dataPosition={"fetchedInventoryMoveHistories"} 
                        endpoint={'/inventory/inventoryMoveHistory'} 
                        checkBoxSelection={false} 
                        reRender={reRender} 
                        columnVisibilityModel={columnVisibilityModel} 
                        service={'inventoryMove'}
                        tabName={'Inventory Move'}
                        setData={setInventoryMovesHistory}
                        data={inventoryMovesHistory}
                    />
                </div>
                    
                <div className={Classes.MobileResponsive}>
                    <MobileResponsive 
                        columns={columns} 
                        dataPosition={"fetchedInventoryMoveHistories"} 
                        endpoint={'/inventory/inventoryMoveHistory'} 
                        rowId={'inventoryMoveHistoryId'}
                        handleRevert={handleRevert}
                        reRender={reRender}
                    />
                </div>

            </div>

            <ExportDatas 
                open={open}
                handleClose={handleClose}
                endPoint={'/exports/inventoryMovesHistory'}
                dataName="Inventory Move"
            />
        </Fragment>
    )
}

export default InventoryMove;