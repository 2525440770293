import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Switch,
  TextField,
  FormControlLabel,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axiosInstance from "../../../utility/axios-instance";
import imageCompression from "browser-image-compression";

import Classes from "./createLaminate.module.scss";

const CreateLaminate = () => {
  const navigate = useNavigate();

  // States
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [titleColor, setTitleColor] = useState("#000000");
  const [imageFile, setImageFile] = useState(null);
  const [active, setActive] = useState(true);
  const [compressing, setCompressing] = useState(false);
  const [compressedImage, setCompressedImage] = useState(null);
  const [compressionPercentage, setCompressionPercentage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [skus, setSkus] = useState([]);
  const [selectedSku, setSelectedSku] = useState("");

  // Fetch SKUs
  useEffect(() => {
    fetchSkus();
  }, []);

  const fetchSkus = (text = "") => {
    axiosInstance
      .get(`/product/variant/sku?text=${text}`)
      .then((res) => {
        setSkus(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching SKUs:", error);
        setError("Failed to fetch SKUs. Please try again.");
      });
  };

  // Image Compression
  const handleImageCompression = async () => {
    try {
      setCompressing(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        compressPercentage: parseFloat(compressionPercentage) || undefined,
      };
      const compressedFile = await imageCompression(imageFile, options);
      setCompressedImage(compressedFile);
      setCompressing(false);
      handleCloseDialog();
    } catch (error) {
      console.error("Image compression error:", error);
      setError("Failed to compress image. Please try again.");
      setCompressing(false);
    }
  };

  // Form Submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("titleColor", titleColor);
    formData.append("active", active);
    formData.append("imageFile", compressedImage || imageFile);
    formData.append("sku", selectedSku); 

    setError(null);
    setLoading(true);

    axiosInstance
      .post("/adminRoutes/laminate/upload", formData)
      .then(() => navigate(-1))
      .catch((err) => setError(err.response.data.message))
      .finally(() => setLoading(false));
  };

  // Dialog Handling
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Fragment>
      {error && (
        <Alert severity="error">
          <AlertTitle>{error}</AlertTitle>
          Try Reloading!
        </Alert>
      )}

      <div style={{ position: "relative" }}>
        {compressing && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backdropFilter: "blur(4px)",
              zIndex: 999,
            }}
          />
        )}

        <div style={{ padding: "10px 20px" }}>
          <form onSubmit={handleSubmit} className={`mt-4 ${Classes.Grid}`}>
            <div>
              <Autocomplete
                freeSolo
                disablePortal
                id="sku"
                name="sku"
                options={skus}
                value={selectedSku}
                onChange={(event, newValue) => {
                  setSelectedSku(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="SKU"
                    required
                    onChange={(event) => {
                      const { value } = event.target;
                      fetchSkus(value);
                      setSelectedSku(value);
                    }}
                  />
                )}
              />
            </div>

            <TextField
              id="title"
              required
              name="title"
              type="text"
              label="Title"
              placeholder="Enter Title"
              autoComplete="off"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <TextField
                style={{
                  flexGrow: "1",
                }}
                id="titleColor"
                required
                name="titleColor"
                type="text"
                label="Title Color"
                placeholder="Enter Title Color"
                autoComplete="off"
                value={titleColor}
                onChange={(e) => setTitleColor(e.target.value)}
                helperText="Enter hex (ex. #000000) or rgba (ex. rgba(0, 0, 0, 1)) color code."
              />
              <div
                style={{
                  height: "56px",
                  aspectRatio: "1 / 1",
                  backgroundColor: titleColor,
                  border: "1px solid grey",
                  borderRadius: "4px",
                }}
              />
            </div>

            <TextField
              id="image"
              required
              name="imageFile"
              type="file"
              fullWidth
              inputProps={{
                accept: "image/png, image/jpeg, image/jpg, image/webp",
              }}
              autoComplete="off"
              onChange={(e) => setImageFile(e.target.files[0])}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="compressionPercentage"
                name="compressionPercentage"
                type="number"
                label="Compression Percentage (%)"
                placeholder="Enter Compression Percentage"
                autoComplete="off"
                value={compressionPercentage}
                onChange={(e) => setCompressionPercentage(e.target.value)}
                inputProps={{ min: 1, max: 100 }}
                sx={{ width: "45%" }}
              />

              <div style={{ display: "flex", alignItems: "baseline" }}>
                <Button
                  variant="contained"
                  onClick={handleImageCompression}
                  disabled={!imageFile}
                  sx={{
                    margin: "5%",
                    backgroundColor: "#ff6200",
                    ":hover": {
                      backgroundColor: "#ff6200",
                    },
                    color: "white",
                  }}
                  style={{ marginTop: "10px" }}
                >
                  Compress
                </Button>
                {compressedImage && (
                  <Button
                    variant="contained"
                    onClick={handleOpenDialog}
                    sx={{
                      backgroundColor: "#ff6200",
                      ":hover": {
                        backgroundColor: "#ff6200",
                      },
                      color: "white",
                    }}
                  >
                    Preview
                  </Button>
                )}
              </div>
            </div>

            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              }
              label="Active"
            />

            <Button
              type="submit"
              variant="contained"
              style={{
                position: "absolute",
                top: "100%",
                right: "3%",
              }}
              sx={{
                backgroundColor: "#ff6200",
                ":hover": {
                  backgroundColor: "#ff6200",
                },
                color: "white",
                marginTop: "10px",
                width: "fit-content",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </div>

        {compressing && (
          <div
            className={Classes.UploadContainer}
            style={{
              position: "relative",
              top: "50%",
              left: "47%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <div className={Classes.UploadAnimation}>
              <CircularProgress />
              <p>Compressing...</p>
            </div>
          </div>
        )}
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Preview Compressed Image</DialogTitle>
        <DialogContent>
          {compressedImage && (
            <img
              src={URL.createObjectURL(compressedImage)}
              alt="Compressed"
              style={{ maxWidth: "100%", objectFit: "contain" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CreateLaminate;
