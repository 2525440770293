import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	purchaseOrders: [],
};

const inwardPurchaseOrdersSlice = createSlice({
	name: 'inwardPurchaseOrders',
	initialState: initialState,
	reducers: {
		setInwardPurchaseOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setInwardPurchaseOrders, reset } = inwardPurchaseOrdersSlice.actions;

export default inwardPurchaseOrdersSlice.reducer;