import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
  Box,
} from "@mui/material";
import SnackBar from "../../../../../components/SnackBar";
import axiosInstance from "../../../../../utility/axios-instance";
const DeleteModal = ({
  open,
  handleClose,
  detail,
  setReRender,
  imagesLength,
  storeId,
  imageId,
}) => {
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete(
        `/adminRoutes/stores/${storeId}/images/${imageId}`
      );
      handleClose();
      setSnackBar({
        display: true,
        type: "success",
        message: res?.data?.message,
      });
      setReRender((prev) => !prev);
    } catch (err) {
      setSnackBar({
        display: true,
        type: "error",
        message:
          err?.response?.data?.message || "An error occurred during submission",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete Image</DialogTitle>
        <DialogContent>
          {imagesLength > 1 ? (
            <>
              <Alert
                severity="warning"
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  boxShadow: "none",
                  bgcolor: "background.paper",
                }}
              >
                <AlertTitle>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="warning.main"
                  >
                    Confirm Deletion
                  </Typography>
                </AlertTitle>
                <Box mb={1}>
                  <Typography variant="body1">
                    This action will permanently delete the image Titled:{" "}
                    <Typography
                      variant="body1"
                      component="span"
                      fontWeight="bold"
                    >
                      {detail.title}
                    </Typography>{" "}
                    with StoreId:{" "}
                    <Typography
                      variant="body1"
                      component="span"
                      fontWeight="bold"
                    >
                      {detail.storeId}
                    </Typography>{" "}
                    & ImageId:{" "}
                    <Typography
                      variant="body1"
                      component="span"
                      fontWeight="bold"
                    >
                      {detail.id}
                    </Typography>
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="warning.main"
                >
                  Are you sure you want to continue?
                </Typography>
              </Alert>
            </>
          ) : (
            <Alert
              severity="info"
              variant="outlined"
              sx={{
                borderRadius: 2,
                boxShadow: "none",
                bgcolor: "background.paper",
              }}
            >
              <AlertTitle>
                <Typography variant="subtitle1" fontWeight="bold">
                  Important Notice
                </Typography>
              </AlertTitle>
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight="bold"
              >
                This action cannot be performed as at least one image is
                mandatory for store location.
                <br />
                <span
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    display: "block",
                  }}
                >
                  To delete this, you need to delete the store itself.
                </span>
              </Typography>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {imagesLength > 1 && (
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              onClick={handleDelete}
            >
              {loading ? <CircularProgress size={24} /> : "Confirm"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default DeleteModal;
