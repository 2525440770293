import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { Flag } from '@mui/icons-material';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import { Button } from '@mui/material';
import UpdateLowerAndUpperLimit from './updateLowerAndUpperLimit';
import { ImportFileComponent } from './../../../components/importFileComponent';
import {Switch, FormControlLabel, Autocomplete, TextField} from '@mui/material';
import moment from 'moment';


const LowInventoryTracker = () => {
    const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');
    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [update, setUpdate] = useState(null);
    const [flagged, setFlagged] = useState(false);
    const [locationCode, setLocationCode] = useState('');
    const [locations, setLocations] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [text, setText] = useState('');

    const flagInventory = async (id) => {
        axiosInstance.put(`/inventory/flagInventory/${id}`).then(res => {
            setReRender(prev => !prev);
        }).catch(err => {
            setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'error',
                message: err?.response?.data?.message ?? "Something went wrong"
            }));
        })
    }

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations`)
            .then((res) => {
                const locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                }
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchLocation();
    },[])

    const columns = [
    {
        field: 'sku_code',
        headerName: 'SKU Code',
        flex:1.5,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    {
        field: 'product_name',
        headerName: 'Product Name',
        flex:2,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'available_qty', 
        headerName: 'Available Qty', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return <p style={{
                color: params.row.flagged ? 'red' : 'inherit'
            }}>{params.value}</p>
        }
    },
    { 
        field: 'refill_qty', 
        headerName: 'Refill Qty', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return <p style={{
                color: params.row.flagged ? 'red' : 'inherit'
            }}>{params.value}</p>
        }
    },
    { 
        field: 'lower_limit', 
        headerName: 'Lower Limit', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'upper_limit', 
        headerName: 'Upper Limit', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'locationCode', 
        headerName: 'Location Code', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
    },
    { 
        field: 'totalPoQuantity', 
        headerName: 'Total Po Quantity', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => {
            return params.value ?? '--'
        }
    },
    { 
        field: 'flagged', 
        headerName: 'Flagged', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => {
            return <Flag style={{
                color: params.value ? 'red' : 'gray'
            }} onClick={() => {
                flagInventory(params.id)
            }} />
        }
    },
     { 
        field: 'update', 
        headerName: 'Update', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => {
            return <Button variant='contained' size='small' onClick={() => {
                setUpdate(params.row)
            }}>Update</Button>
        }
    }
];


const generateReport = async () => {
    const newReportName = 'Low Inventory Tracker';
    const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
    const params = {
        uploadFileName : fileName,
        reportName : newReportName,
        locationCode,
        flagged,
        text
    };

    await axiosInstance
        .get(`/analytics/reports/lowInventory`, { params })
        .then((res) => {
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: res?.data?.message, type: "success" }
            });
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
        });
};

const closeDialog = () => {
    setUpdate(null);
}

    return (
        <Fragment>
                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

                {update ? <UpdateLowerAndUpperLimit data={update} closeDialog={closeDialog} setReRender={setReRender} /> : null}

                <div style={{
                    margin: '8px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: '8px'
                }}>
                <div>
                    <Button variant='contained' onClick={generateReport}>Export Report</Button>
                </div>
                    <ImportFileComponent
                        endpoint={'/inventory/bulkUpdateLowerAndUpperLimit'}
                        // serviceName={services?.pincodeSetting}
                        type="update lower & upper limit of inventories"
                        templateDownloadEndpoint={"/templates/bulkUpdateLowerAndUpperLimit"}
                        setReRender={setReRender}
                        // setData={setCsvResponse}
                        text={'Bulk update lower & upper limit'}
                    />
                </div>

                <div style={{
                    margin: '8px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: '24px'
                }}>
                    <FormControlLabel control={<Switch checked={flagged} onChange={(e) => {
                        setFlagged(e.target.checked);
                    }} color='error' />} label="Flagged" labelPlacement='start' />

                    <div style={{width: '200px'}}>
                        <Autocomplete
                            size='small'
                            disablePortal
                            id="locationCode"
                            name="locationCode"
                            options={locations}
                            renderInput={(params) => <TextField 
                                {...params} label="Location Code"
                                onBlur={(event) => {setLocationCode(event.target.value)}}
                            />}
                        />
                    </div>
                </div>
                
            
                <div>
                    <div className={Classes.MuiTable}>
                        <Table 
                            columns={columns} 
                            dataPosition={"inventories"} 
                            endpoint={`/inventory/getInventories`} 
                            rowId={'inventory_id'}
                            checkBoxSelection={false}
                            tabName={'Low Inventory Tracker'}
                            setData={setData}
                            data={data}
                            createdByFilter={false}
                            isReactDispatch={true}
                            flagged={flagged}
                            locationCode={locationCode}
                            reRender={reRender}
                            setText={setText}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={columns} 
                            dataPosition={"inventories"} 
                            endpoint={`/inventory/getInventories`} 
                            rowId={'inventory_id'}
                        />
                    </div>
                </div>
        </Fragment>
    )
}
export default LowInventoryTracker;