import { Fragment, useState } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';

const CancelMeetingModal = (props)=> {
    const [loading,setLoading] = useState(false);

    const { 
        errorMessage,
        setErrorMessage,
        cancelMeetingModal,
        hideCancelMeetingModal,
        cancelVisitCustomer,
        setCancelVisitReason,
        cancelVisitReason,
        reRender,
        setReRender
    } = props;


    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn')
        button.innerHTML = 'Cancel Meeting..';

        axiosInstance
            .post(`/fieldSalesVisit/updateFieldVisit/${cancelVisitCustomer}`,{
                reason: event.target.reason.value 
            })
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                hideCancelMeetingModal();
                setLoading(false);
                setErrorMessage('');
                setCancelVisitReason('');
                button.innerHTML = 'Visit End Completed';
                setReRender(!reRender);
            }).catch((err) => {
                button.innerHTML = 'Submit';
                setLoading(false);
            })
    }

    const handleCancelMeeting = (event) => {
        if (event.target.value) {
            setCancelVisitReason(event.target.value);
        }
    }

    return (
        <Fragment>
            {cancelMeetingModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'16%',bottom:'20%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>Cancel Meeting</p>
                    </div>
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form'
                        style={{overflowY:'auto',height:'72%'}}
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>

                        <div style={{padding:20}}>
                            <TextField 
                                // required
                                id='cancelVisitReason'
                                name="reason"
                                label="Meeting Cancel Reason"
                                onBlur={handleCancelMeeting}
                                multiline
                                placeholder='Enter Visit Remarks'
                                autoComplete='off'
                                sx={{ width: '100%',"& label": {top: cancelVisitReason ? "0%":"-16%",fontSize:16},"& .MuiOutlinedInput-input": {padding:"5px 2px" }}}
                            />
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'20%',
                                position:'fixed',
                                boxShadow: 'none',
                                borderTop:0
                            }}
                        >
                             <button
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideCancelMeetingModal}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                disabled={cancelVisitReason ? false : true}
                            >
                                SUBMIT
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default CancelMeetingModal;