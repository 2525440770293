import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedBins: [],
};

const binsSlice = createSlice({
	name: 'bins',
	initialState: initialState,
	reducers: {
		setBins: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setBins, reset } = binsSlice.actions;

export default binsSlice.reducer;