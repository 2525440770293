import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';

const PaymentDetailsComponent = (props) => {
    const { 
        paymentDetails,
        handlePaymentChange
    } = props;

    return (
        <Fragment>
            <div className={Classes.Box} style={{padding:'1.1rem'}}>
                <h6 style={{ marginBottom: '15px' }}>Payment Details</h6>
                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Payment Mode
                    </p>
                    <select
                        name='paymentMode'
                        id='paymentMode'
                        className={Classes.select}
                        value={paymentDetails?.paymentMode ? paymentDetails?.paymentMode : 'cod'}
                        onChange={(e) => handlePaymentChange('paymentMode', e)}
                    >
                        <option value='DEFAULT'>Select Payment Mode</option>
                        <option value='online'>Online</option>
                        <option value='cod'>Cash on Delivery</option>
                        <option value='cheque'>Cheque</option>
                        <option value='others'>Others</option>
                    </select>
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Invoice Number
                    </p>
                    <input 
                        type="" 
                        className="form-control" 
                        id="invoiceNumber"
                        value={paymentDetails?.invoiceNumber ? paymentDetails?.invoiceNumber : ''}
                        onChange={(e) => handlePaymentChange('invoiceNumber', e)}
                        name="invoiceNumber" 
                        placeholder="Invoice Number" 
                    />
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Invoice Date
                    </p>
                    <input
                        type="date"
                        id="invoiceDate"
                        value={paymentDetails?.invoiceDate ? paymentDetails?.invoiceDate?.slice(0, 10) : ''}
                        name="invoiceDate"
                        onChange={(e) => handlePaymentChange('invoiceDate', e)}
                        min={new Date().toISOString().slice(0, 10)}
                        className="form-control"
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default PaymentDetailsComponent;