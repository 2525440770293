import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedRiders: [],
};

const ridersSlice = createSlice({
	name: 'riders',
	initialState: initialState,
	reducers: {
		setRiders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setRiders, reset } = ridersSlice.actions;

export default ridersSlice.reducer;