import {useEffect, useState} from 'react'
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, Box, Button, Chip, InputAdornment, TextField, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import Classes from './css/dashboard.module.css'
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadCSV } from '../../../utility/downloadCSV';
import SourceIcon from '@mui/icons-material/Source';
import { DataGrid } from '@mui/x-data-grid';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import NewPagination from '../../../components/newPagination';
import Spinner from '../../../components/spinner';
import moment from 'moment';
import { Autorenew, DownloadDone, Downloading, ErrorOutline } from '@mui/icons-material';

const orderStatuses = [
    'Confirmed',
    'Part Allocated',
    'Part Packed',
    'Partially Dispatched',
    'Partially Delivered',
    'Part Delivered & Cancelled',
    'Partially Fulfilled',
    'Partially Return Requested',
    'Partially Returned',
    'Allocated',
    'Packed',
    'Dispatched',
    'Delivered',
    'Cancelled',
    'Return Requested',
    'Returned',
]

const purchaseOrderStatuses = [
    "Open",
    "Dispatched",
    "Received",
    "Closed",
    "Force Closed",
    "Partially Dispatched",
    "Partially Received",
    "Returned",
    "Partially Returned",
    "QC-Pass",
    "QC-Fail",
    "QC-Done",
    "Partially QC-Done",
    "Partially Closed",
]

const draftOrderStatuses = [
    "Completed",
    "Draft"
];

const lineItemStatuses = [
    "Open",
    "Allocated",
    "Part Allocated",
    "Packed",
    "Part Packed",
    "Dispatched",
    "Received",
    "Closed",
    "Partially Closed",
    "Confirmed",
    "Force Closed",
    "Partially Dispatched",
    "Partially Received",
    "Returned",
    "Cancelled",
    "On Hold",
    "Partially Returned",
    "Picklist",
    "Delivered",
    "Partially Delivered"
]

function StatusInput({ status, handleStatusFilter, reportName }) {
    return (
        <div>
            <Autocomplete
                multiple
                size="small"
                disablePortal
                sx={{ width: '100%',"& .MuiInputBase-sizeSmall": {paddingTop:'5px !important'} }}
                options={reportName === 'Sales-Orders' ? orderStatuses : reportName === 'Purchase-Orders' ? purchaseOrderStatuses : reportName === "Draft-Order" ? draftOrderStatuses : reportName === 'POS-Order-LineItem' ? lineItemStatuses : []}
                value={status}
                onChange={handleStatusFilter}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                    />
                )}
            />
        </div>
    )
}

function SearchInput({ searchFor, handleSearch, label }) {
    return (
        <div>
            <TextField
                id="search"
                type="search"
                label={label}
                value={searchFor}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ 
                    width: '100%',
                    "& label": { top: searchFor ? "0%" : "-18%" },
                    "& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

const ReportAnalytics = (props) => {
    const {locCode, setSnackBar, startDate, endDate} = props;
    const [customerRoles, setCustomerRoles] = useState([]);
    const [customerRole,setCustomerRole] = useState('');
    const [filters, setFilters] = useState([]);
    const [status, setStatus] = useState([]);
    const [searchFor, setSearchFor] = useState("");
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState('');
    const [platforms] = useState(['Portal','App','Website','POS']);
    const reportNames = ['Sales-Orders', 'Purchase-Orders', 'Inventory-In-Out', "Draft-Order", "Product", "POS-Order-LineItem", "Sku-Transactions"];
    const searchLabels = {'Sales-Orders' : 'Customer Phone', 'Purchase-Orders' : 'Vendor Phone', 'Inventory-In-Out' : 'SKU', "Draft-Order": "Customer Phone", "POS-Order-LineItem": "Customer Phone", "Sku-Transactions": "Search"};
    const inOutTypesOfInventory = ['Inward','Outward']
    const salesOrderType = ['Complete','LineItems']
    const [reportName, setReportName] = useState(reportNames[0]);
    const [inOutInvType, setInOutInvType] = useState(inOutTypesOfInventory[0]);
    const [selectedSalesOrderType, setSelectedSalesOrderType] = useState(salesOrderType[0]);
    const [exportHistory, setExportHistory] = useState([]);
    const [draftOrderType] = useState(["B2B", "B2C"]);
    const [selectedDraftOrderType, setSelectedDraftOrderType] = useState("");

    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState('');

    const [allLs, setAllLs] = useState({});
    const [l1s, setL1s] = useState([]);
    const [l2s, setL2s] = useState([]);
    const [l3s, setL3s] = useState([]);
    const [selectedL1s, setSelectedL1s] = useState([]);
    const [selectedL2s, setSelectedL2s] = useState([]);
    const [selectedL3s, setSelectedL3s] = useState([]);
    const [orderName, setOrderName] = useState("");
    const [allProducts, setAllProducts] = useState(false);

    // const [topSellingSkuByQty, setTopSellingSkuByQty] = useState([]);

    const handleStatusFilter = (e, newValue) => {
        setStatus(newValue);
    }

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');

    const fetchSalesOrderReport = async () => {
        const newReportName = `
            ${selectedSalesOrderType===salesOrderType[0] 
            ? `${reportName}` 
            : `${reportName}-${selectedSalesOrderType}`}`
        setLoading(true);
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        let statusQuery = status?.join(',');
        const params = {
            startDate, endDate,
            status: statusQuery || undefined,
            role : customerRole || undefined,
            phoneNumber : searchFor || undefined,
            locationCode : locCode || undefined,
            platform : platform || undefined,
            uploadFileName : fileName,
            reportName : newReportName,
            isLineItemWise : selectedSalesOrderType===salesOrderType[1] 
        };

        await axiosInstance
            .get(`/analytics/reports/salesOrder`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.orders
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    };

    const fetchPurchaseOrderReport = async () => {
        const newReportName = `
            ${selectedSalesOrderType===salesOrderType[0] 
            ? `${reportName}` 
            : `${reportName}-${selectedSalesOrderType}`}`
        setLoading(true);
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        let statusQuery = status?.join(',');
        const params = {
            startDate, endDate,
            status: statusQuery || undefined,
            locationCode : locCode || undefined,
            phoneNumber : searchFor || undefined,
            uploadFileName : fileName,
            reportName : newReportName,
            isLineItemWise : selectedSalesOrderType===salesOrderType[1] 
        };

        await axiosInstance
            .get(`/analytics/reports/purchaseOrder`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.purchaseOrders
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    };

    const fetchInwardReport = async () => {
        setLoading(true);
        const fileName = `${inOutInvType}_Report_${currentTimeStamp()}`;
        const params = {
            startDate, endDate,
            uploadFileName : fileName,
            locationCode : locCode || undefined,
            searchFor : searchFor || undefined,
            reportName : inOutInvType
        };

        await axiosInstance
            .get(`/analytics/reports/inwardReport`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.inWardReport
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    };
    const fetchOutwardReport = async () => {
        setLoading(true);
        const fileName = `${inOutInvType}_Report_${currentTimeStamp()}`;
        const params = {
            startDate, endDate,
            uploadFileName : fileName,
            searchFor : searchFor || undefined,
            locationCode : locCode || undefined,
            reportName: inOutInvType
        };

        await axiosInstance
            .get(`/analytics/reports/outwardReport`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.outWardReport
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    };

    const fetchDraftOrderReport = async () => {
        setLoading(true);
        const newReportName = reportName;
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        let statusQuery = status?.join(',');
        const params = {
            startDate,
            endDate,
            locationCode : locCode || undefined,
            type: selectedDraftOrderType || undefined,
            role : customerRole || undefined,
            status: statusQuery || undefined,
            phoneNumber : searchFor || undefined,
            platform : platform || undefined,
            reportName: newReportName,
            uploadFileName : fileName,
        };

        await axiosInstance
            .get(`/analytics/reports/draftOrder`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.draftOrders
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    }

    const fetchProductReport = async () => {
        setLoading(true);
        const date = new Date();
        const newReportName = reportName;
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        const params = {
           ...(!allProducts ? {startDate, endDate} : {
            startDate: '1971-1-1',
            endDate: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
           }),
            locationCode : locCode || undefined,
            brands : selectedBrands || undefined,
            l1s: selectedL1s.join(",") || undefined,
            l2s: selectedL2s.join(",") || undefined,
            l3s: selectedL3s.join(",") || undefined,
            reportName: newReportName,
            uploadFileName : fileName,
        };

        await axiosInstance
            .get(`/analytics/reports/product`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.products
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    }

    const fetchPOSOrderLineItemReport = async () => {
        setLoading(true);
        const newReportName = reportName;
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        let statusQuery = status?.join(',');
        const params = {
            startDate,
            endDate,
            locationCode : locCode || undefined,
            type: selectedDraftOrderType || undefined,
            role : customerRole || undefined,
            status: statusQuery || undefined,
            phoneNumber : searchFor || undefined,
            reportName: newReportName,
            uploadFileName : fileName,
            orderName
        };

        await axiosInstance
            .get(`/analytics/reports/posOrderLineItem`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.orderLineItems
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    }

    const fetchSkuTransactionReport = async () => {
        setLoading(true);
        const newReportName = reportName;
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        const txnTypeFilters = filters?.filter((filter)=>{return filter?.field === "txnType"});
        const directionFilters = filters?.filter((filter)=>{return filter?.field === "direction"});
        const params = {
            searchFor,
            filters: [
            ...(txnTypeFilters?.length > 0 ? [
                {
                    "OR": txnTypeFilters
                }
            ] : []),
            ...(directionFilters?.length > 0 ? [
                {
                    "OR": directionFilters
                }
            ] : []),
            {
                field: "dateRange", 
                value: startDate && endDate ? [ startDate, endDate ] : [],
            },
            ...(locCode ? [
                {
                field: "locationCode", 
                value: locCode,
                }
            ] : [])
            ],
            reportName: newReportName,
            uploadFileName : fileName,
        };

        await axiosInstance
            .get(`/analytics/reports/skuTransactionReport`, { params })
            .then((res) => {
                const arrayOfRowObjects = res?.data?.data?.fetchedSkuTransactions
                arrayOfRowObjects.length > 0 && downloadCSV(arrayOfRowObjects, fileName);
                const successMessage = arrayOfRowObjects.length > 0 ? `${reportName} Report Exported Successfully!` : `No Such Record Found`;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });

        await fetchExportedReports();
        setLoading(false);
    }

    const fetchReports = async () => {
        if(reportName === reportNames[0]){
            await fetchSalesOrderReport();
        }
        else if(reportName === reportNames[1]){
            await fetchPurchaseOrderReport();
        }
        else if(reportName === reportNames[2] && inOutInvType === inOutTypesOfInventory[0]){
            await fetchInwardReport();
        }
        else if(reportName === reportNames[2] && inOutInvType === inOutTypesOfInventory[1]){
            await fetchOutwardReport();
        }
        else if(reportName === reportNames[3]){
            await fetchDraftOrderReport();
        }
        else if (reportName === reportNames[4]) {
            fetchProductReport();
        }
        else if (reportName === reportNames[5]) {
            fetchPOSOrderLineItemReport();
        }
        else if (reportName === reportNames[6]) {
            fetchSkuTransactionReport();
        }
    }

    const fetchExportedReports = async (page) => {
        setLoading(true);
        
        await axiosInstance
            .get(`/analytics/reports/exportedFilesHistory?page=${page}`)
            .then((res) => {
                setExportHistory(res?.data?.data)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
        setLoading(false);
    };

    useEffect(() => {
        axiosInstance
            .get('/user/admin/customerRoles')
            .then((res) => {
                let roles = res?.data?.data
                roles = roles.map((role) => {
                    return role?.title
                })
                setCustomerRoles([...roles])
            });
        fetchExportedReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllBrands = async () => {
        axiosInstance.get('/others/adminBrands')
        .then(res => {
            setBrands(res.data.data)
        })
        .catch(err => {})
    }

    const getAllL1s = async () => {
        axiosInstance.get('/others/getAll-l1s-2')
        .then(res => {
            setAllLs(res.data.data);
            setL1s(Object.keys(res.data.data));
        })
        .catch(err => {})
    }

    useEffect(() => {
        getAllL1s();
        getAllBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const l2s = [];

        selectedL1s.forEach(e => {
            l2s.push(...Object.keys(allLs[e]['l2s']))
        });

        setL2s([...new Set(l2s)])

    }, [selectedL1s, allLs]);

    useEffect(() => {
        const l3s = [];

        selectedL1s.forEach(l1 => {
            selectedL2s.forEach(l2 => {
                if (allLs[l1]['l2s'][l2]) {
                    l3s.push(...Object.keys(allLs[l1]['l2s'][l2]['l3s']))
                }
            })
        });

        setL3s([...new Set(l3s)])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedL2s]);

    const reportsColumn = [
        {
            field: 'id',
            headerName: 'Sr',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            // align: 'center',
            flex: .2,
            sortable : false,
            filterable : false,
        },
        {
            field: 'reportName',
            headerName: 'FILE',
            headerClassName: 'super-app-theme--header',
            // headerAlign: 'center',
            // align: 'center',
            flex: .8,
            sortable : false,
            filterable : false,
        },
        { 
            field: 'exportedBy', 
            headerName: 'EXPORTED BY', 
            flex: .6, headerClassName: 
            'super-app-theme--header',
            sortable : false,
            filterable : false,
        },
        { 
            field: 'docs', 
            headerName: 'DOCS', 
            flex: 1, headerClassName: 
            'super-app-theme--header',
            sortable : false,
            filterable : false,
            renderCell: (params) => {
                const status = params?.row?.status;

                return (
                params?.row?.docs &&
                <Tooltip title={status === 'fail' ? 'Failed to generate report.' : status === 'pending' ? 'Generating report' : 'Report Generated'}>
                    <Chip size="small"
                    icon={<FilePresentIcon />}
                    deleteIcon={status === 'fail' ? <ErrorOutline color='error' style={{
                        color: 'red'
                    }} /> : status === 'pending' ? <Downloading color='primary' style={{
                        color: '#1976d2'
                    }} /> : <DownloadDone style={{
                        color: 'green'
                    }} />}
                    clickable label={params?.row?.docs?.split('/').pop()}
                    component="a"
                    href={params?.row?.attachDocsUrl}
                    onDelete={() => {}}
                />
                </Tooltip>
            )
            }
        }
    ]


    return <>
        <div className={Classes.reportAnalytics}>
            <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    disablePortal
                    id="reportName"
                    options={reportNames}
                    name='reportName'
                    sx={{width: '100%',"& label": {top: customerRole ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setReportName(newValue);
                    }}
                    defaultValue={reportNames[0]}
                    renderInput={(params) => <TextField {...params} label="Report Names"/>}
                />
            </div>

            {(reportName === reportNames[0] || reportName === reportNames[1])  && <div className={Classes.reportAnalyticsItem} >
                <Autocomplete
                    disablePortal
                    id="lineItemWiseOrComplete"
                    options={salesOrderType}
                    defaultValue={salesOrderType[0]}
                    name='lineItemWiseOrComplete'
                    sx={{width: '100%',"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setSelectedSalesOrderType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Type"/>}
                />
            </div>}

            {(reportName === reportNames[3] || reportName === reportNames[5])  && <div className={Classes.reportAnalyticsItem} >
                <Autocomplete
                    disablePortal
                    id="draftOrderType"
                    options={draftOrderType}
                    defaultValue={draftOrderType[0]}
                    name='draftOrderType'
                    sx={{width: '100%',"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setSelectedDraftOrderType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Type"/>}
                />
            </div>}

            {(reportName === reportNames[0] || reportName === reportNames[3] || reportName === reportNames[5]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="customerRole"
                    options={[...customerRoles.map((role) => { return { "label": role } })]}
                    name='customerRole'
                    sx={{width: '100%',"& label": {top: customerRole ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setCustomerRole(newValue.map(e => e.label).join(","));
                    }}
                    renderInput={(params) => <TextField {...params} label="Customer Roles"/>}
                />
            </div>}
            {reportName === reportNames[2] && <div className={Classes.reportAnalyticsItem} >
                <Autocomplete
                    disablePortal
                    id="inwardOutward"
                    options={inOutTypesOfInventory}
                    defaultValue={inOutTypesOfInventory[0]}
                    name='inwardOutward'
                    sx={{width: '100%',"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setInOutInvType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Type"/>}
                />
            </div>}

            {reportName !== reportNames[2] &&  reportName !== reportNames[4] && reportName !== reportNames[6] && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.9}}>
                <StatusInput status={status} handleStatusFilter={handleStatusFilter} reportName={reportName}/>
            </div>}

            {reportName === reportNames[5] && <div className={Classes.reportAnalyticsItem} style={{flexGrow:1.2}}>
                <SearchInput searchFor={orderName} handleSearch={(e) => setOrderName(e.target.value)} label={"Order Name"}/>
            </div>}

            {reportName !== reportNames[4] && <div className={Classes.reportAnalyticsItem} style={{flexGrow:1.2}}>
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} label={searchLabels[reportName]}/>
            </div>}

            {(reportName === reportNames[0] || reportName === reportNames[3]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.6}}>
                <Autocomplete
                        disablePortal
                        id="platform"
                        options={platforms}
                        name='Platform'
                        sx={{width: '100%',"& label": {top: platform ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                        onChange={(event, newValue) => {
                            setPlatform(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Platform"/>}
                />
            </div>}

            {(reportName === reportNames[4]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="brands"
                    options={[...brands.map((brand) => { return { "label": brand.title } })]}
                    name='brands'
                    sx={{width: '100%',"& label": {top: selectedBrands ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setSelectedBrands(newValue.map(e => e.label).join(','));
                    }}
                    renderInput={(params) => <TextField {...params} label="Brands"/>}
                />
            </div>}

            {(reportName === reportNames[4]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="l1s"
                    options={[...l1s.map((l1) => { return { "label": l1 } })]}
                    name='l1s'
                    sx={{width: '100%',"& label": {top: selectedL1s && selectedL1s.length ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setSelectedL1s(newValue.map(e => e.label));
                    }}
                    renderInput={(params) => <TextField {...params} label="L1s"/>}
                />
            </div>}

            {(reportName === reportNames[4]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="l2s"
                    options={[...l2s.map((l2) => { return { "label": l2 } })]}
                    name='l2s'
                    sx={{width: '100%',"& label": {top: selectedL2s && selectedL2s.length ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setSelectedL2s(newValue.map(e => e.label));
                    }}
                    renderInput={(params) => <TextField {...params} label="L2s"/>}
                />
            </div>}

            {(reportName === reportNames[4]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="l3s"
                    options={[...l3s.map((l3) => { return { "label": l3 } })]}
                    name='l3s'
                    sx={{width: '100%',"& label": {top: selectedL3s && selectedL3s.length ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                    onChange={(event, newValue) => {
                        setSelectedL3s(newValue.map(e => e.label));
                    }}
                    renderInput={(params) => <TextField {...params} label="L3s"/>}
                />
            </div>}

            {reportName === reportNames[4] ? 
                <FormControlLabel control={<Checkbox checked={allProducts} onChange={(e, c) => {
                    setAllProducts(c)
                }} />} label="Get All Products" />
            : null}
            
            {(reportName === reportNames[6]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="transaction-type"
                    options={
                        [...["Adjustment",
                            "Receiving",
                            "Allocate",
                            "Unallocate",
                            "Movement",
                            "Dispatch"]
                            .map((transactionType) => { return { "label": transactionType } })
                        ]}
                    name='transaction-type'
                    sx={{
                        width: '100%',"& label": {
                        top: filters.find((filter)=>{
                            return filter?.field === "txnType"}
                        ) 
                        ? "0%"
                        : "-16%",fontSize:14},
                        "& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}
                    }}
                    onChange={(event, newValue) => {
                        setFilters((prevFilters) => {
                            const newFilter = [
                                ...prevFilters?.filter((filter)=>{
                                    return filter?.field !== "txnType"
                                }), 
                                ...newValue.map(value => {
                                    return {
                                        field: "txnType",
                                        value: value?.label
                                    }
                                })
                            ]
                            return newFilter
                        });
                    }}
                    renderInput={(params) => <TextField {...params} label="Transaction Type"/>}
                />
            </div>}

            {(reportName === reportNames[6]) && <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Autocomplete
                    multiple
                    disablePortal
                    id="direction"
                    options={
                        [...[
                            "In",
                            "out",
                            "within"
                            ]
                            .map((transactionType) => { return { "label": transactionType } })
                        ]}
                    name='direction'
                    sx={{
                        width: '100%',"& label": {
                        top: filters.find((filter)=>{
                            return filter?.field === "direction"}
                        ) 
                        ? "0%"
                        : "-16%",fontSize:14},
                        "& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}
                    }}
                    onChange={(event, newValue) => {
                        setFilters((prevFilters) => {
                            const newFilter = [
                                ...prevFilters?.filter((filter)=>{
                                    return filter?.field !== "direction"
                                }), 
                                ...newValue.map(value => {
                                    return {
                                        field: "direction",
                                        value: value?.label
                                    }
                                })
                            ]
                            return newFilter
                        });
                    }}
                    renderInput={(params) => <TextField {...params} label="Direction"/>}
                />
            </div>}

            <div className={Classes.reportAnalyticsItem} style={{flexGrow:.8}}>
                <Button
                        id='exportbtn'
                        variant="contained"
                        color="primary"
                        startIcon={<FileDownloadIcon />}
                        onClick={fetchReports}
                        style={{padding:'8px 12px',fontSize:14, width : '100%'}}
                    >
                        GET REPORT
                    </Button>
            </div>
        </div>
        {/* Exported Reports */}
        <div style={{marginTop : '20px'}}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.5rem'
            }}>
                <h3 style={{textAlign : 'center', color : 'grey', marginTop: '15px'}}><SourceIcon color='grey' fontSize='large'/> Today's Reports</h3>
                <Button variant='outlined' startIcon={<Autorenew />} size="small" onClick={() => {
                    fetchExportedReports()
                }}>Refresh</Button>
            </div>
            <div>
                <Box sx={{ height: 400, width: '100%' ,marginTop : '15px'}}>
                    <DataGrid
                        rows={exportHistory?.exportedReports ?? []}
                        columns={reportsColumn}
                        sx={{
                            '& .super-app-theme--header': {
                                color: '#858382',
                                cursor: "default",
                                fontSize : '12px',
                                border : 0
                            },
                            "& .MuiDataGrid-footerContainer": {
                                display: 'none'
                            }
                        }}
                    />
                </Box>
            </div>
        </div>

        {exportHistory?.exportedReports?.length > 0 && (
            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination totalPage={exportHistory} fetchData={fetchExportedReports} />
            </div>
        )}

        {loading && <Spinner />}
    </>
}

export default ReportAnalytics;