import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { TextField, Autocomplete, Typography } from '@mui/material';
import Table from '../../../components/table/table';
import Button from '@mui/material/Button';
import Dialog from '../../../components/dialog';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import CreateBinPopUp from './createBinPopup';
import { ImportFileComponent } from '../../../components/importFileComponent';
import ExportDatas from '../../../components/exportDataDialog';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import FormDialog from '../../../components/FormDialog/FormDialog';
import MobileResponsive from './mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setBins } from '../../../store/binsSlice';

const Bins = () => {

    const columns = [
        {
            field: 'code',
            headerName: 'Bin Code',
            flex: 1,
            // headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'type',
            headerName: 'Bin Type',
            flex: 1,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qty',
            headerName: 'Quantity',
            type: 'number',

            flex: 1,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'default',
            headerName: 'Default',
            flex: 1,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.value ? (
                    <BsCheckCircleFill color="green" />
                ) : (
                    <BsXCircleFill color="red" />
                )
            )
        },
        {
            field: 'update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={serviceName} permission={"update"}>
                <Button size='small' color='success' variant="contained" onClick={(e) => openUpdateDialog(params?.row)}>Update</Button>
                </Permission>
            )
        },
        {
            field: 'delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={serviceName} permission={"delete"}>
                <FormDialog
                    dialogTitle="Confirm Delete Bin"
                    dialogActionType="confirmation"
                    openDialogButtonName="Delete"
                    submitDialogButtonName="Confirm"
                    onConfirmation={() => { handleDeleteBin(params?.row.bin_id, params?.row?.locationCode) }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        This action would permanently Delete '{params?.row?.code}' Bin ?
                    </Typography>
                </FormDialog>
                {/* <Button size='small' color='error' variant="contained" onClick={(e) => handleDeleteBin(params?.row.bin_id, params?.row?.locationCode)}>Delete</Button> */}
                </Permission>
            )
        },
    ];
    const [locations, setLocations] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [locationCode, setLocationCode] = useState('');
    const [reRender, setReRender] = useState(false);
    const [update, setUpdate] = useState(false);
    const [binCode, setBinCode] = useState('');
    const [binType, setBinType] = useState('');
    const [binQty, setBinQty] = useState('');
    const [binStatus, setBinStatus] = useState('');
    const [binId, setBinId] = useState(null);
    const [binDefault, setBinDefault] = useState('');
    const [open,setOpen] = useState(false);
    const serviceName = services?.bin;

    const bins = useSelector((state) => state.bins);
    const handleExportDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const showCreatePopup = () => {
        setShowPopup(true)
    }

    const hideCreatePopup = () => {
        setShowPopup(false)
    }

    const openUpdateDialog = (bin) => {
        setBinId(bin?.bin_id);
        setBinCode(bin?.code);
        setBinType(bin?.type);
        setBinQty(bin?.qty);
        setBinStatus(bin?.status);
        setLocationCode(bin?.locationCode);
        setBinDefault(bin?.default.toString());
        setUpdate(true);
    }

    const closeUpdateDialog = () => {
        setBinCode('');
        setBinType('');
        setBinQty('');
        setBinStatus('');
        setLocationCode('');
        setBinDefault('');
        setUpdate(false);
    }

    const fetchLocation = () => {
        axiosInstance.get('/location/locations?text=')
            .then(res => {
                const locations = [];
                if (res.data.data.length !== 0) {
                    for (let location of res?.data?.data) {
                        locations.push(location?.locationCode)
                    }
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        fetchLocation()
    }, [])

    // const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const updateFields = [
        <TextField
            label={'Bin Code'}
            placeholder={'Enter Bin Code'}
            required={true}
            id='code'
            name={'code'}
            defaultValue={binCode}
            autoComplete='off'
            sx={{ width: '100%' }}
        />,

        <Autocomplete
            freeSolo
            disablePortal
            id="status"
            name="status"
            options={["Active", "In-Active", "Dis-Continued"]}
            sx={{ width: '100%' }}
            defaultValue={binStatus}
            renderInput={(params) =>
                <TextField {...params} label={"Status"} required={true} />
            }
        />,
        // <TextField
        //     label={'Quantity'}
        //     placeholder={'Enter Qty'}
        //     type='number'
        //     required={true}
        //     id='qty'
        //     name={'qty'}
        //     autoComplete='off'
        //     sx={{ width: '100%' }}
        //     defaultValue={binQty}
        //     min={0}
        //     onKeyDown={preventNegativeValues}
        // />,
        <Autocomplete
            freeSolo
            disablePortal
            id="type"
            name="type"
            options={["Saleable", "Rejected", "QC", "Packing", "Returned", "Staging"]}
            sx={{ width: '100%' }}
            renderInput={(params) =>
                <TextField {...params} label={"Bin Type"} required={true} />
            }
            defaultValue={binType}
        />,
        <Autocomplete
            freeSolo
            disablePortal
            id="location"
            name="location"
            options={locations}
            sx={{ width: '100%' }}
            renderInput={(params) =>
                <TextField {...params} label={"Locations"} required={true} onChange={(event) => setLocationCode(event.target.value)} />
            }
            defaultValue={locationCode}
        />,
        <Autocomplete
            disablePortal
            id="default"
            name="default"
            options={['true', 'false']}
            sx={{ width: '100%' }}
            renderInput={(params) =>
                <TextField {...params} label={"Default"} />
            }
            defaultValue={binDefault}
        />
    ]


    const handleDeleteBin = (binId, locCode) => {
        axiosInstance
            .delete(`/inventory/deleteBin/${binId}?locationCode=${locCode}`)
            .then(result => {
                setReRender(!reRender)
            })
            .catch(error => console.log('error', error))
    }


    return (
        <div>
            <div style={{ display:'flex',justifyContent:'space-between',marginBottom:15,flexWrap:'wrap' }}>
                <div>
                    <Permission service={serviceName} permission={"create"}>
                    <Button
                        variant="contained"
                        onClick={showCreatePopup}
                        style={{ textTransform: 'capitalize',marginRight:10 }}
                    >
                        Create Bin
                    </Button>
                    </Permission>

                    <Permission service={serviceName} permission={"read"}>
                    <Button  
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize' }}
                        // sx={{ fontSize:14,textTransform:'capitalize',padding:"4px 14px",letterSpacing:"0.5px" }}
                        onClick={handleExportDialog}
                    >
                        Export
                    </Button>
                    </Permission>
                </div>

                <Permission service={serviceName} permission={"create"}>
                <div style={{marginTop:-25}}>
                    <p>Bin Create/ Update via CSV will override existing values</p>
                    <ImportFileComponent
                        endpoint="/inventory/createBinCsv"
                        serviceName={services?.inventory}
                        type="bin"
                        templateDownloadEndpoint={"/templates/binCsv"}
                        setReRender={setReRender}
                    />
                </div>
                </Permission>

            </div>

            <div className={Classes.MuiTable}>
                <Permission service={serviceName} permission={"read"}>
                    <Table
                        columns={columns}
                        dataPosition={"fetchedBins"}
                        endpoint={`/inventory/fetchBins`}
                        rowId={'bin_id'}
                        checkBoxSelection={false}
                        reRender={reRender}
                        service={'bins'}
                        tabName={'Bins'}
                        setData={setBins}
                        data={bins}
                    />
                </Permission>
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"fetchedBins"}
                    endpoint={`/inventory/fetchBins`}
                    rowId={'bin_id'}
                    openUpdateDialog={openUpdateDialog}
                    handleDeleteBin={handleDeleteBin}
                    reRender={reRender}
                />
            </div>

            <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                <CreateBinPopUp
                    showPopup={showPopup}
                    hideCreatePopup={hideCreatePopup}
                    locations={locations}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={update ? Classes.UpdateSalesDialog : undefined}>
                <Dialog
                    open={update}
                    closeDialog={closeUpdateDialog}
                    headerName={'Update Bin'}
                    fields={updateFields}
                    endPoint={`/inventory/updateBin/${binId}?locationCode=${locationCode}`}
                    setReRender={setReRender}
                    type={'update'}
                    reRender={reRender}
                />
            </div>

            <ExportDatas 
                open={open}
                handleClose={handleClose}
                endPoint={'/exports/allBins'}
                dataName="Bins"
            />
        </div>
    )
}

export default Bins