import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	users: [],
};

const myCustomersSlice = createSlice({
	name: 'myCustomers',
	initialState: initialState,
	reducers: {
		setMyCustomers: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setMyCustomers, reset } = myCustomersSlice.actions;

export default myCustomersSlice.reducer;