import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Classes from "./css/Modal.module.css";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import Classes from '../css/asideModal.module.css';
import CustomerModal from "./newLocationModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from 'react-redux';

const AsideModalLocations = (props) => {
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [toggleBtn, setToggleBtn] = useState(true);
    const [searchPage, setSearchPage] = useState(1);
    const [page, setPages] = useState(1);
    const [maxPages, setMaxPage] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const adminUser = useSelector((state) => state?.auth?.user);
    const defaultLocationCode = adminUser?.settings?.locationSetting.length && adminUser?.settings?.locationSetting[0]

    // newCustomer Model
    const [gstNO, setGstNO] = useState();
    const [num, setNum] = useState('');

    const {
        setDetail,
        searchCustomer,
        toggleButton,
        setToggleButton,
        setCustomerIDAddress,
        setPreviousTarget,
        setBillingAddress,
        toggle,
        setToggle,
        setAddressArray,
        setLocationContacts,
        setLocationContactDetails,
        setLocationGstNumber,
        defaultLocation
    } = props;

    const getModal = () => {
        setShowModal(true);
        props.onHideModal();
    };

    const hideModal = () => {
        props.onHideModal();
        setNum('');
        setGstNO('');
        setErrorMessage('');
        setShowModal(false);
    };

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('modal');
        if (container !== null) {
            if (!container.contains(e.target)) {
                props.onHideModal(false);
            }
        }
    });

    const handleCustomer = (customer) => {
        props.onHideModal();
        setToggleBtn(true);
        setCustomerInfo(customer);
    }
  
    useEffect(()=>{
        if (defaultLocation) {
            handleCustomer(defaultLocationCode)
        }
    },[])

    useEffect(() => {
        if (toggleButton) {
            axiosInstance
                .get(`/location/locations?text=${searchCustomer}`)
                .then((res) => {
                    setCustomers(res.data.data);
                    setToggleButton(false);
                })
        }
    }, [toggleButton, searchCustomer, setToggleButton, searchPage])

    useEffect(() => {
        if (toggle) {
            axiosInstance
                .get(`/location/locations?text=${searchCustomer}`)
                .then((res) => {
                    setCustomers(res.data.data);
                    setPages(2)
                    setToggle(false)
                })
        }
    }, [toggle, setToggle, searchCustomer])

    useEffect(() => {
        if (toggleBtn) {
            if (customerInfo?.length > 0) {
                axiosInstance
                    .get(`/location/location/${customerInfo}`)
                    .then((res) => {
                        setDetail([res.data.data]);
                        if (res?.data?.data?.locationGstNumber?.length > 0) {
                            setLocationGstNumber(res?.data?.data?.locationGstNumber[0]);
                        }
                        setToggleBtn(false);
                        axiosInstance
                            .get(`/location/locationContactDetails/${customerInfo}`)
                            .then((res) => {
                                setLocationContacts(res.data.data);
                                if (res?.data?.data.length > 0) {
                                    setLocationContactDetails(res?.data?.data[0]);
                                }
                                axiosInstance
                                    .get(`/location/locationAddresses/${customerInfo}`)
                                    .then((res) => {
                                        if (res.data.data.length !== 0) {
                                            setAddressArray(res.data.data);
                                            const addresses = res.data.data;
                                            const shippingAddressArrays = [];
                                            const BillingAddressArrays = [];
                                            for (let address of addresses) {
                                                if (address.addressType === 'Shipping') {
                                                    shippingAddressArrays.push(address);
                                                } else {
                                                    BillingAddressArrays.push(address);
                                                }
                                            }

                                            if (shippingAddressArrays.length !== 0) {
                                                setCustomerIDAddress([...shippingAddressArrays]);
                                            }

                                            if (BillingAddressArrays.length !== 0) {
                                                setBillingAddress([...BillingAddressArrays]);
                                            } else {
                                                setBillingAddress([shippingAddressArrays[0]]);
                                            }

                                            setPreviousTarget([]);
                                            setToggleBtn(false);
                                        }

                                    }).catch((error) => {
                                        console.log(error?.response?.data)
                                    })
                                })
                            })
                    }
        }
        }, [toggleBtn,
            setDetail,
            customerInfo,
            setBillingAddress,
            setCustomerIDAddress,
            setPreviousTarget,
            setAddressArray,
            setLocationContacts,
            setLocationContactDetails,
            setLocationGstNumber
        ]);


    const fetchMoreData = () => {
        if (maxPages >= page) {
            setTimeout(() => {
                axiosInstance
                    .get(`/user/admin?page=${page}&text=${searchCustomer}`)
                    .then((res) => {
                        if (res.data.data.maxPage >= page) {
                            setPages(page + 1)
                        } else {
                            setPages(1)
                        }
                        setCustomers([...customers, ...res.data.data.users]);
                    })
            }, 1500);
        }
    }

    return (
        <Fragment>
            {props.showModal && (
                <div className={Classes.modal} id="modal">
                    <div className={Classes.ContactSearch} onClick={() => getModal()}>
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{ fontSize:17,paddingRight:10,color:'rgb(129, 138, 155)',paddingTop:3 }}
                        />
                        <p>Create New Location</p>
                    </div>

                    <div style={{ height:350 }}>
                        {customers.length !== 0 &&
                            <div>
                                <InfiniteScroll
                                    dataLength={customers.length}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    loader={page - 1 !== maxPages
                                        ? <h6>Loading...</h6>
                                        : ''}
                                >
                                    {customers.map((item, index) => (
                                        <div key={index} className={Classes.styles} onClick={() => handleCustomer(item.locationCode)}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    {item.locationName !== "undefined"
                                                        ? <p style={{ marginRight: '5px' }}>{item.locationName}</p>
                                                        : ''
                                                    }

                                                </div>
                                            </div>
                                            <div>
                                                <p style={{ color: 'gray' }}>{item.locationCode}</p>
                                            </div>
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            </div>
                        }
                    </div>
                </div>
            )}

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <CustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    setDetail={setDetail}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    setLocationContactDetails={setLocationContactDetails}
                />
            </div>
        </Fragment>
    );
}


export default AsideModalLocations;