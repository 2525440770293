import React, {Fragment} from "react";
import Classes from "../createOrders/css/Modal.module.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

const ConfirmPopup = (props)=> {
    const { showConfirmPopup, hideConfirmPopup, storeCustomerId } = props;

    return (
        <Fragment>
            {showConfirmPopup && (
                <div className={Classes.modal} style={{top:'15%',bottom:'30%',left:"35%",right:"35%"}}>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <HighlightOffIcon 
                            sx={{ color:'rgb(211, 47, 47)',fontSize:"75px",marginTop:"30px" }}
                        />
                    </div>

                    <div style={{marginTop:25,textAlign:'center',fontSize:25}}>
                        <p>Are you sure?</p>
                    </div>

                    <div style={{textAlign:'center',marginTop:5}}>
                        <p>Do you really want to delete{" "}
                            {storeCustomerId?.length === 1 ? "this" : "these" }{" "}
                            customers?
                        </p>
                    </div>

                    <div style={{ margin:"25px auto 0px",width:'70%',display:"flex",justifyContent: "space-around" }}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor:'#E12D39',width:120,borderRadius:20}} 
                            onClick={hideConfirmPopup}
                            startIcon={<DeleteIcon />}
                        >
                            DELETE
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={hideConfirmPopup}
                            style={{backgroundColor:'#627D98',width:120,borderRadius:20}}
                        >
                            CANCEL
                        </Button>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default ConfirmPopup;