import {Fragment,useEffect,useState,forwardRef} from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';
import Classes from "../createOrders/css/Modal.module.css";
import '../createOrders/css/styles.css';
import Spinner from '../../../components/spinner';
import { downloadCSV } from "../../../utility/downloadCSV";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExportReport = (props)=> {
    const [loading,setLoading] = useState(false);
    const [users,setUsers] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const handleSelect = (event, value) => {
        setSelectedUsers(value);
    };

    const { 
        open, 
        handleClose,
        selectedUsers,
        setSelectedUsers,
        month,
        setMonth
    } = props;

    useEffect(() => {
        axiosInstance
            .get('/fieldSalesVisit/allSalesUsers')
            .then((res) => {
                setUsers(res?.data?.data);
            })
    }, []);

    const handleExport = (event) => {
        setLoading(true);
        event.preventDefault();

        const admins = selectedUsers.map(user => user?._id);
        const data = admins?.length !== 0 ? admins : '';
        const monthIndex = monthNames.findIndex(months => months === month);

        axiosInstance
            .get(`/exports/salesUserReport?month=${month ? monthIndex + 1: ''}&users=${data}`,)
            .then((result) => {
                setLoading(false);
                const arrayOfRowObjects = result?.data?.data;
                if (arrayOfRowObjects?.length !== 0) {
                    downloadCSV(arrayOfRowObjects, `Report export - ${new Date()}`);
                    const successMessage = "Report Exported Successfully!"
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                } else {
                    const errorMessage = "Report Not Exist";
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                }
                handleClose();
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    return (
        <Fragment>
            {open && (
                <div className={Classes.exportReportDialog}>
                    <div className={Classes.BoxContent}>
                        <p>Export Reports</p>
                        <button 
                            id='addCustomer' 
                            type="submit" 
                            className={Classes.UpdateSalesUserOfCustomer}
                            onClick={handleExport}
                        >
                            EXPORT ALL
                        </button>
                    </div>

                    <form method="post" onSubmit={handleExport}>
                        <div style={{padding:"20px 20px 0px 20px"}}>
                            {/* <div>
                                <Autocomplete
                                    required
                                    aria-required
                                    multiple
                                    limitTags={1}
                                    id="multiple-limit-tags"
                                    size="small"
                                    disablePortal
                                    options={users ? users : [{name:"Loading...",id:0}]}
                                    value={selectedUsers}
                                    getOptionLabel={(user) => user.name}
                                    getOptionSelected={(option, user) => option === user}
                                    onChange={handleSelect}
                                    renderInput={(params) => (
                                        <TextField {...params} label="SalesUser" variant="outlined"/>
                                    )}
                                />
                            </div> */}

                            <div>
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="modeOfCommunication"
                                    name="modeOfCommunication"
                                    options={monthNames}
                                    onChange={(event,newValue) => { setMonth(newValue) }}
                                    sx={{width: '100%',"& label": {top: month ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                    renderInput={(params) => <TextField {...params} label="Month" />}
                                />
                            </div>
                        </div>

                        <div className={Classes.reportExportDialog}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleClose}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                // onClick={handleExport}
                            >
                                EXPORT
                            </button>
                        </div>
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
                
            )}

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}


export default ExportReport;