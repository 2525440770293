import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';

import axiosInstance from '../../../utility/axios-instance';
import { setOrders } from '../../../store/deliveryBoySlice';
import resetState from '../../../store/actions';

import Spinner from '../../../components/spinner';
import Search from '../../../components/search';

import DeliveryBoyOrder from './deliveryBoyOrder';

const DeliveryBoyOrders = (props) => {
	const deliveryBoy = useSelector((state) => state.deliveryBoy);
	const dispatch = useDispatch();
	const ref = useRef();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const [text, setText] = useState('');

	const fetchOrders = async (page) => {
		setLoading(true);
		setError(null);
		await axiosInstance
			.get('/deliveryBoy')
			.then((response) => {
				dispatch(setOrders(response.data.data));
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					fetchOrders();
				}, 1000000);
			});
	};

	useEffect(() => {
		fetchOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="d-flex justify-content-between mb-5">
				<div className="px-3 py-1 border border-dark rounded">
					<p className="mb-0">
						<strong>Orders</strong>
					</p>
				</div>
				<button
					type="button"
					className="btn btn-outline-dark"
					onClick={() => {
						fetchOrders();
					}}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
					{loading ? 'Loading...' : 'ReLoad'}
				</button>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			<Search func={fetchOrders} text={text} setText={setText} />

			<table className="table table-sm">
				<thead>
					<tr>
						<th scope="col">AWB No</th>
						<th scope="col">Payment</th>
						<th scope="col">Status</th>
						<th scope="col">Pincode </th>
					</tr>
				</thead>
				<tbody
					style={{
						fontSize: '.8rem',
					}}
				>
					{deliveryBoy?.orders?.map((order, index) => (
						<DeliveryBoyOrder 
							key={order?._id} 
							order={order} 
							index={index} 
						/>
					))}
				</tbody>
			</table>
			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default DeliveryBoyOrders;
