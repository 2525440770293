import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Divider,
  Typography,
  Slide,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  DialogTitle,
  Grid,
} from "@mui/material";
import Permission from "../../../../auth/permissions";
import axiosInstance from "../../../../../utility/axios-instance";

import SnackBar from "../../../../../components/SnackBar";
import { styled } from "@mui/system";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});
const EditStoreLoc = ({ open, handleClose, setReRender, detail, storeId }) => {
  const [data, setData] = useState(detail);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      name: event.target.name.value,
      streetAddress: event.target.streetAddress.value,
      landmark: event.target.landmark.value,
      city: event.target.city.value,
      state: event.target.state.value,
      postalCode: event.target.postalCode.value,
      country: event.target.country.value,
    };
    setLoading(true);
    axiosInstance
      .post(`/adminRoutes/stores/locations/${storeId}`, data)
      .then((res) => {
        handleClose();
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
        setReRender((prev) => !prev);
      })
      .catch((err) => {
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    axiosInstance.get("/others/AllStates").then((response) => {
      const states = [];
      for (let stateName of response.data.data) {
        states.push({ id: stateName?._id, label: stateName?.state });
      }
      setStates(states);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get("/pincode/getAllCities").then((response) => {
      setCities(response.data.data);
    });
  }, []);
  return (
    <>
      {data ? (
        <StyledDialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="update-dialog-title"
        >
          <DialogTitle id="update-dialog-title">Update Store</DialogTitle>
          <StyledForm onSubmit={handleSubmit}>
            <StyledDialogContent>
              <Divider
                component="div"
                role="presentation"
                style={{ marginBottom: "1rem" }}
              >
                <Typography variant="subtitle1">
                  Edit Store Location Details
                </Typography>
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    required
                    id="name"
                    name="name"
                    type="text"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    key={data?.name}
                    defaultValue={data?.name}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    required
                    id="streetAddress"
                    name="streetAddress"
                    label="Street Address"
                    type="text"
                    variant="outlined"
                    fullWidth
                    key={data?.streetAddress}
                    defaultValue={data?.streetAddress}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label="Landmark"
                    id="landmark"
                    name="landmark"
                    type="text"
                    variant="outlined"
                    fullWidth
                    key={data?.landmark}
                    defaultValue={data?.landmark}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    required
                    id="postalCode"
                    name="postalCode"
                    type="number"
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    key={data?.postalCode}
                    defaultValue={data?.postalCode}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id={"city"}
                    name={"city"}
                    options={cities}
                    sx={{ width: "100%", marginTop: 1 }}
                    key={data?.city}
                    defaultValue={data?.city}
                    renderInput={(params) => (
                      <TextField {...params} label="City" required={true} />
                    )}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // freeSolo
                    required={true}
                    disablePortal
                    id="state"
                    name="state"
                    options={states}
                    key={data?.state}
                    defaultValue={data?.state}
                    ListboxProps={{ sx: { fontSize: 14 } }}
                    size="small"
                    sx={{ width: "100%", marginTop: 1 }}
                    renderInput={(params) => (
                      <TextField {...params} label="States" required />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    required
                    id="country"
                    name="country"
                    type="text"
                    label="Country"
                    variant="outlined"
                    fullWidth
                    key={data?.country}
                    defaultValue={data?.country}
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </StyledDialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Permission service="store" permission="update">
                <Button type="submit" variant="contained" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : "Update"}
                </Button>
              </Permission>
            </DialogActions>
          </StyledForm>
        </StyledDialog>
      ) : null}
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default EditStoreLoc;
