import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "./css/asideModal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";


const ConfirmPopup = (props)=> {
    const { 
        showConfirmPopup, 
        hideConfirmPopup, 
        orderId, 
        text
    } = props;

    return (
        <Fragment>
            {showConfirmPopup && (
                <div 
                    className={Classes.confirmModal} 
                    style={{ bottom:'20%',top:'24%' }}
                >
                    <div className={Classes.confirmPopupBox}>
                        <FontAwesomeIcon 
                            icon={faCircleXmark} 
                            className={Classes.XMark}
                        />
                    </div>

                    <div 
                        style={{ fontSize:30 }}
                        className={Classes.colorTextAlign}
                    >
                        <p>Are you sure?</p>
                    </div>

                    <div 
                        style={{ padding:'10px 0px' }}
                        className={Classes.colorTextAlign}
                    >
                        <p>Do you really want to {text} order?</p>
                    </div>

                    <div className={Classes.confirmPopupBtn}>
                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => hideConfirmPopup(e,orderId)}
                                style={{ backgroundColor:'#F37171' }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => hideConfirmPopup(e)}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default ConfirmPopup;