import React, { useEffect, useState } from "react";
import Permission from "../../../auth/permissions";
import Table from "../../../../components/table/table";
import { useSelector } from "react-redux";
import { setBanners } from "../../../../store/bannerSettingsSlice";
import { Button, Typography } from "@mui/material";
import FormDialog from "../../../../components/FormDialog/FormDialog";
import SnackBar from "../../../../components/SnackBar";
import Classes from "../../dynamicBanners/dynamicBanners.module.css";
import { Add } from "@mui/icons-material";
import UploadModal from "./create";
import UpdateModal from "./edit";
import axiosInstance from "../../../../utility/axios-instance";
import MediaPreview from "../../../../components/mediaPreview";
import Styles from "../settings.module.css";

const Home = () => {
  const [showUploadModal, setShowUplodModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [bannerDetail, setBannerDetail] = useState([]);
  const [activeGroupId, setActiveGroupId] = useState(null);
  const [reRender, setReRender] = useState(false);
  const [activeImageSet, setActiveImageSet] = useState(true);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [mediaPreviewURL, setMediaPreviewURL] = useState("");
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const bannerDetails = useSelector((state) => state.bannerSettings);
  const columns = [
    {
      field: "group_id",
      headerName: "Group Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "image",
      headerName: "Image",
      width: 90,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <img
          src={params?.row?.url}
          alt="icon"
          className={Styles.Images}
          onClick={() => {
            setPreviewToggle(true);
            setMediaPreviewURL(params?.row?.url);
          }}
        />
      ),
    },
    {
      field: "image_type",
      headerName: "Image Type",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: true,
      sortable: true,
      flex: 1,
    },
    {
      field: "url",
      headerName: "Image URL",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "cta_link",
      headerName: "CTA Link",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },

    {
      field: "active",
      headerName: "Active",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: `edit`,
      headerName: `Edit`,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="bannerSettings" permission="update">
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={() => handleUpdate(params.row.group_id)}
          >
            EDIT
          </Button>
        </Permission>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="bannerSettings" permission={"delete"}>
          <FormDialog
            dialogTitle="Confirm Delete Banner Images"
            dialogActionType="confirmation"
            openDialogButtonName="Delete"
            submitDialogButtonName="Confirm"
            onConfirmation={() => {
              handleDelete(params?.row?.group_id);
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div style={{ marginBottom: "10px" }}>
                This action will permanently delete the items in the group
                titled 'Title':
              </div>
              <ul style={{ paddingLeft: "20px" }}>
                {bannerDetails?.bannerDetails?.map(
                  (item) =>
                    item.group_id === params?.row?.group_id && (
                      <li key={item.id}>{item.title}</li>
                    )
                )}
              </ul>
              <div style={{ marginTop: "10px" }}>
                Are you sure you want to continue?
              </div>
            </Typography>
          </FormDialog>
        </Permission>
      ),
    },
  ];
  const columnVisibilityModel = {
    group_id: false,
    cta_link: false,
    url: false,
  };
  const handleDelete = (groupId) => {
    axiosInstance
      .delete(`/adminRoutes/banners/images/${groupId}`)
      .then((res) => {
        setReRender(!reRender);
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) =>
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        })
      );
  };

  const handleUpdate = (groupId) => {
    const defaultImageObject = {
      id: "",
      title: "",
      cta_link: "",
      url: "",
      file: null,
      inputType: "url",
    };
    const defaultImageSet = {
      primaryImage: { ...defaultImageObject },
      secondaryImage1: { ...defaultImageObject },
      secondaryImage2: { ...defaultImageObject },
    };

    const details = (bannerDetails?.bannerDetails || [])
      .filter((item) => item.group_id === groupId)
      .reduce(
        (acc, item) => {
          if (item.image_type === "primary") {
            acc.primaryImage = {
              id: item.id,
              title: item.title,
              cta_link: item.cta_link || "",
              url: item.url,
              file: null,
              inputType: "url",
            };
          } else if (item.image_type === "secondary") {
            const secondaryImageSlot = acc.secondaryImage1.title
              ? "secondaryImage2"
              : "secondaryImage1";
            acc[secondaryImageSlot] = {
              id: item.id,
              title: item.title,
              cta_link: item.cta_link || "",
              url: item.url,
              file: null,
              inputType: "url",
            };
          }
          return acc;
        },
        { ...defaultImageSet }
      );

    // Check if all images in the set are active
    const activeImageSet = bannerDetails?.bannerDetails?.reduce((acc, item) => {
      return item.group_id === groupId ? acc && item.active : true;
    }, true);

    setBannerDetail(details);
    setActiveGroupId(groupId);
    setShowUpdateModal(true);
    setActiveImageSet(activeImageSet);
  };

  const closeUpdateModal = () => {
    setBannerDetail([]);
    setActiveGroupId(null);
    setShowUpdateModal(false);
  };

  const closeUploadModal = () => {
    setShowUplodModal(false);
  };
  const handlePreviewClose = () => {
    setPreviewToggle(false);
  };
  return (
    <>
      <div className={Classes.UsersHead}>
        <p></p>
        <Permission service="bannerSettings" permission="create">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={() => setShowUplodModal(true)}
            disabled={bannerDetails?.bannerDetails?.length > 0 ? true : false}
          >
            Add New
          </Button>
        </Permission>
      </div>
      <Permission service="bannerSettings" permission="read">
        <Table
          columns={columns}
          dataPosition={"bannerDetails"}
          endpoint={`/adminRoutes/banners/images`}
          rowId={"id"}
          checkBoxSelection={false}
          reRender={reRender}
          service={"bannerSettings"}
          tabName={"Banner Settings"}
          setData={setBanners}
          data={bannerDetails}
          version={"version-2"}
          mobileResponsive={true}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Permission>

      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      {showUploadModal ? (
        <UploadModal
          open={showUploadModal}
          handleClose={closeUploadModal}
          setReRender={setReRender}
          reRender={reRender}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          groupId={activeGroupId}
          open={showUpdateModal}
          handleClose={closeUpdateModal}
          details={bannerDetail}
          setDetails={setBannerDetail}
          setReRender={setReRender}
          reRender={reRender}
          activeImageStatus={activeImageSet}
        />
      ) : null}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type="img"
          mediaURL={mediaPreviewURL}
        />
      )}
    </>
  );
};

export default Home;
