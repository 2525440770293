import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedReturns: [],
};

const putAwayPurchaseOrdersSlice = createSlice({
	name: 'putAwayPurchaseOrders',
	initialState: initialState,
	reducers: {
		setPutAwayPurchaseOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setPutAwayPurchaseOrders, reset } = putAwayPurchaseOrdersSlice.actions;

export default putAwayPurchaseOrdersSlice.reducer;