import { Fragment, useState } from 'react';
import axiosInstance from '../../../../utility/axios-instance';
import VendorComponent from '../../../../components/createPurchaseOrder/asideVendor';
import LocationComponent from '../../../../components/createPurchaseOrder/asideLocation';
import ShippingDetailsComponent from '../../../../components/createPurchaseOrder/asideShipmentDetails';
import DocumentUploadComponent from '../../../../components/createPurchaseOrder/asideDocuments';
import PaymentDetailsComponent from '../../../../components/createPurchaseOrder/asidePaymentDetails';
import NotesComponent from '../../../../components/createPurchaseOrder/asideNotes';

const Aside = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalLocation, setShowModalLocation] = useState(false);
    const [toggleButtonLocation, setToggleButtonLocation] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [toggleButton, setToggleButton] = useState(false);
    const [contactModal, setContactModal] = useState(false);
    const [contactModalLocation, setContactModalLocation] = useState(false);
    const [shippingModal, setShippingModal] = useState(false);
    const [shippingLocModal, setShippingLocModal] = useState(false);
    const [updateShippingModal, setUpdateShippingModal] = useState(false);
    const [updateShippingLocModal, setUpdateShippingLocModal] = useState(false);
    const [defaultAddressModal, setDefaultAddressModal] = useState(false);
    const [defaultAddressLocationModal, setDefaultAddressLocationModal] = useState(false);
    const [shippingAddressArray, setShippingAddressArray] = useState([]);
    const [shippingAddressLocationArray, setShippingAddressLocationArray] = useState([]);
    const [billingAddressLocationArray, setBillingAddressLocationArray] = useState([]);
    const [removeGstModal, setRemoveGstModal] = useState(false);
    const [removeLocGstModal, setRemoveLocGstModal] = useState(false);
    const [newGstNo, setNewGstNo] = useState(false);
    const [newLocGstNo, setNewLocGstNo] = useState(false);
    const [previousTarget, setPreviousTarget] = useState([]);
    const [addressType, setAddressType] = useState('');
    const [updateBillingAddressModal, setUpdateBillingAddressModal] = useState(false);
    const [updateBillingAddressLocModal, setUpdateBillingAddressLocModal] = useState(false);
    const [defaultBillingAddressModal, setDefaultBillingAddressModal] = useState(false);
    const [defaultLocBillingAddressModal, setDefaultLocBillingAddressModal] = useState(false);
    const [BillingAddressArray, setBillingAddressArray] = useState([]);


    // set asideModalToggle
    const [toggle, setToggle] = useState(true);
    const [toggleLocation, setToggleLocation] = useState(true);

    // shippingAddressPopUp
    const [num, setNum] = useState('');
    const [pincode, setPincode] = useState('');

    const {
        showCustomerDetail,
        setCustomerDetail,
        customerIDAddress,
        setCustomerIDAddress,
        setGstNumber,
        setBillingAddress,
        BillingAddress,
        gstNumber,
        addressArray,
        setAddressArray,
        updateExistOrder,
        setNotes,
        notes,
        showLocationDetail,
        setLocationDetail,
        locationIDAddress,
        setLocationIDAddress,
        locationBillingAddress,
        setLocationBillingAddress,
        locationAddressArray,
        setLocationAddressArray,
        shippingDetails,
        setShippingDetails,
        paymentDetails,
        setPaymentDetails,
        setFiles,
        files,
        vendorContacts,
        setVendorContacts,
        vendorContactDetails,
        setVendorContactDetails,
        locationContacts,
        setLocationContacts,
        locationContactDetails,
        setLocationContactDetails,
        vendorGstNumber, setVendorGstNumber,
        locationGstNumber, setLocationGstNumber,
        defaultFiles, setDefaultFiles, productDetails,
        overAllDiscount,
        setProduct, setShippingPrice, inputRef,
        setLoadingPurchaseOrderCalculationServiceState
    } = props;

    const handleFileSelect = (event) => {
        setFiles([...files, ...event.target.files]);
        event.target.value = null;
    };

    const removeFile = (index, files, setFiles) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles)
    };

    const showRemoveGstPopup = () => {
        setRemoveGstModal(true);
    }

    const hideRemoveGstPopup = () => {
        setRemoveGstModal(false);
    }
    const showRemoveLocGstPopup = () => {
        setRemoveLocGstModal(true);
    }

    const hideRemoveLocGstPopup = () => {
        setRemoveLocGstModal(false);
    }

    const handleShippingChange = (key, e) => {
        if (key === 'freightCharges') {
            setShippingDetails({ ...shippingDetails, [key]: Number(e.target.value) });
        }
        else {
            setShippingDetails({ ...shippingDetails, [key]: e.target.value });
        }
    }
    const handlePaymentChange = (key, e) => {
        const value = e.target.value || null;
        setPaymentDetails({ ...paymentDetails, [key]: value });
    }

    const handleGstNumber = (e, type) => {
        if (type === 'Vendor') {
            setVendorGstNumber(e.target.value);
        }
        else {
            setLocationGstNumber(e.target.value);
        }
    }


    const showCustomerModal = (searchItem) => {
        setShowModal(true);
    };

    const showLocationModal = (searchItem) => {
        setShowModalLocation(true);
    };

    const hideCustomerModal = () => {
        setShowModal(false);
    }

    const hideLocationModal = () => {
        setShowModalLocation(false);
    }

    const removeElementLocation = (Id) => {
        setToggleLocation(true);
        setSearchLocation('');
        setLocationContactDetails(null)
        setLocationGstNumber('');
        locationContacts?.splice(0, locationContacts?.length);
        setLocationAddressArray([]);
        setLocationDetail(showLocationDetail => showLocationDetail.filter(ele => ele._id !== Id));
        if (locationIDAddress.length !== 0) {
            setLocationIDAddress(locationIDAddress => locationIDAddress.filter(ele => ele.user !== Id));
        }

        if (locationBillingAddress.length !== 0) {
            setLocationBillingAddress(locationBillingAddress => locationBillingAddress.filter(ele => ele.user !== Id));
        }

        if (shippingAddressLocationArray.length !== 0) {
            setLocationAddressArray(shippingAddressLocationArray => shippingAddressLocationArray.filter(ele => ele.user !== Id));
        }

        // if(previousTarget.length !== 0){
        //  setPreviousTarget(previousTarget.splice(0,previousTarget.length));
        // }
    }

    const removeElement = (Id) => {
        setToggle(true);
        setSearchCustomer('');
        setVendorContactDetails(null)
        setVendorGstNumber('')
        setAddressArray([]);
        vendorContacts?.splice(0, vendorContacts?.length);
        setCustomerDetail(showCustomerDetail => showCustomerDetail.filter(ele => ele._id !== Id));
        if (customerIDAddress.length !== 0) {
            setCustomerIDAddress(customerIDAddress => customerIDAddress.filter(ele => ele.user !== Id));
        }

        if (BillingAddress.length !== 0) {
            setBillingAddress(BillingAddress => BillingAddress.filter(ele => ele.user !== Id));
        }

        if (shippingAddressArray.length !== 0) {
            setAddressArray(shippingAddressArray => shippingAddressArray.filter(ele => ele.user !== Id));
        }

        if (previousTarget.length !== 0) {
            setPreviousTarget(previousTarget.splice(0, previousTarget.length));
        }
    }

    const handleSearchCustomer = (customer) => {
        setToggleButton(true);
        setSearchCustomer(customer);
    }

    const handleSearchLocation = (customer) => {
        setToggleButtonLocation(true);
        setSearchLocation(customer);
    }

    const showContactPop = (e) => {
        setContactModal(true);
    }

    const hideContactPop = () => {
        setContactModal(false)
    }

    const showContactPopLocation = (e) => {
        setContactModalLocation(true);
    }

    const hideContactPopLocation = () => {
        setContactModalLocation(false)
    }


    const showShippingPop = (event) => {
        setAddressType(event.target.id);
        setShippingModal(true);
    }

    const hideShippingPop = () => {
        setPincode('');
        setNum('');
        setShippingModal(false);
    }

    const showShippingLocPop = (event) => {
        setAddressType(event.target.id);
        setShippingLocModal(true);
    }

    const hideShippingLocPop = () => {
        setPincode('');
        setNum('');
        setShippingLocModal(false);
    }

    const showUpdateShippingPop = (event) => {
        setAddressType(event.target.id);
        setUpdateShippingModal(true);
    }

    const hideUpdateShippingPop = () => {
        setUpdateShippingModal(false);
    }
    const showUpdateShippingLocPop = (event) => {
        setAddressType(event.target.id);
        setUpdateShippingLocModal(true);
    }

    const hideUpdateShippingLocPop = () => {
        setUpdateShippingLocModal(false);
    }

    const showUpdateBillingPop = (event) => {
        setAddressType(event.target.id);
        setUpdateBillingAddressModal(true);
    }

    const hideUpdateBillingPop = () => {
        setUpdateBillingAddressModal(false);
    }
    const showUpdateBillingLocPop = (event) => {
        setAddressType(event.target.id);
        setUpdateBillingAddressLocModal(true);
    }

    const hideUpdateBillingLocPop = () => {
        setUpdateBillingAddressLocModal(false);
    }

    const showdefaultBillingAddressPop = () => {
        if (BillingAddressArray.length === 0) {
            setBillingAddressArray(BillingAddress);
        } else {
            setBillingAddressArray(BillingAddressArray);
        }
        setDefaultBillingAddressModal(true);
    }

    const hideDefaultBillingAddressPop = () => {
        setPreviousTarget([]);
        setBillingAddressArray(BillingAddressArray);
        setDefaultBillingAddressModal(false);
    }
    const showdefaultLocBillingAddressPop = () => {
        if (billingAddressLocationArray.length === 0) {
            setBillingAddressLocationArray(locationBillingAddress);
        } else {
            setBillingAddressLocationArray(billingAddressLocationArray);
        }
        setDefaultLocBillingAddressModal(true);
    }

    const hideDefaultLocBillingAddressPop = () => {
        setPreviousTarget([]);
        setBillingAddressLocationArray(BillingAddressArray);
        setDefaultLocBillingAddressModal(false);
    }

    const showDefaultAddressPopUp = () => {
        if (shippingAddressArray.length === 0) {
            setShippingAddressArray(customerIDAddress);
        } else {
            setShippingAddressArray(shippingAddressArray);
        }
        setDefaultAddressModal(true)
    }
    const showDefaultAddressLocationPopUp = () => {
        if (shippingAddressLocationArray.length === 0) {
            setShippingAddressLocationArray(locationIDAddress);
        } else {
            setShippingAddressLocationArray(shippingAddressLocationArray);
        }
        setDefaultAddressLocationModal(true)
    }
    const hideDefaultAddressLocationPopUp = () => {
        setPreviousTarget([]);
        setShippingAddressLocationArray(shippingAddressLocationArray);
        setDefaultAddressLocationModal(false)
    }


    const hideDefaultAddressPopUp = () => {
        setPreviousTarget([]);
        setShippingAddressArray(shippingAddressArray);
        setDefaultAddressModal(false)
    }

    const showGstNoPopUp = () => {
        setNewGstNo(true);
    }

    const hideGstNoPopUp = () => {
        setNewGstNo(false);
    }

    const showLocGstNoPopUp = () => {
        setNewLocGstNo(true);
    }

    const hideLocGstNoPopUp = () => {
        setNewLocGstNo(false);
    }

    const handleShippingPrice = (event) => {
        event.preventDefault();

            let shipping = {
                price: +event.target.value ? +event.target.value : 0,
            }

            const variantLineItems = productDetails.map(obj => {
                const { variantId, ...rest } = obj;
                return { productId: variantId, ...rest };
            });

            let data = {}
            if (overAllDiscount.length !== 0) {
                data = {
                    lineItems: variantLineItems,
                    overallDiscount: overAllDiscount[0],
                    shippingLine: shipping
                }
            } else {
                data = {
                    lineItems: variantLineItems,
                    shippingLine: shipping
                }
            }

            setLoadingPurchaseOrderCalculationServiceState(true);
            const endpoint = '/calculate/purchaseOrder'
            axiosInstance
                .post(endpoint, { ...data })
                .then((res) => {
                    setProduct(res.data);
                })
                .catch((error) => {
                    const errorMessage = error.response.data.message;
                    alert(errorMessage || 'An error occurred while calculating the purchase order');
                })
                .finally(() => {
                    let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
                    calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
                    if (calculatePurchaseOrderRoutePendingCount === 0) {
                        setLoadingPurchaseOrderCalculationServiceState(false);
                    }
                })

            setShippingPrice([shipping]);
    }

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('contactOutline');
        if (container !== null) {
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleOutline = () => {
        document.getElementById('contactOutline').style.border = '2px solid #458fff';
    }

    const extractFileName = (url) => {
        const splitUrl = url.split("/");
        const fileNameWithParams = splitUrl[splitUrl.length - 1];
        const fileName = fileNameWithParams.split("?")[0];
        return fileName;
    }


    const handleEdit = (event) => {
        if (document.activeElement.isContentEditable) {
            if (event.keyCode === 13) {
                event.preventDefault();
                event.target.blur();
                return;
            }
        }
    };

    const handleBlur = (event) => {
        if (event.target.id === 'notes') {
            console.log(event.target.innerText)
            setNotes(event.target.innerText)
        }
    }

    return (
        <Fragment>
            <VendorComponent 
                showCustomerDetail={showCustomerDetail}
                removeElement={removeElement} 
                showContactPop={showContactPop} 
                vendorGstNumber={vendorGstNumber} 
                addressArray={addressArray}
                customerIDAddress={customerIDAddress}
                showUpdateShippingPop={showUpdateShippingPop}
                showDefaultAddressPopUp={showDefaultAddressPopUp}
                showShippingPop={showShippingPop}
                vendorContacts={vendorContacts}
                setVendorContacts={setVendorContacts}
                vendorContactDetails={vendorContactDetails}
                setVendorContactDetails={setVendorContactDetails}
                handleOutline={handleOutline}
                handleSearchCustomer={handleSearchCustomer}
                showCustomerModal={showCustomerModal}
                showGstNoPopUp={showGstNoPopUp}
                showRemoveGstPopup={showRemoveGstPopup}
                handleGstNumber={handleGstNumber}
                BillingAddress={BillingAddress}
                showUpdateBillingPop={showUpdateBillingPop}
                showdefaultBillingAddressPop={showdefaultBillingAddressPop}
                showModal={showModal}
                hideCustomerModal={hideCustomerModal}
                setCustomerDetail={setCustomerDetail}
                searchCustomer={searchCustomer}
                toggleButton={toggleButton}
                setToggleButton={setToggleButton}
                setCustomerIDAddress={setCustomerIDAddress}
                setPreviousTarget={setPreviousTarget}
                setBillingAddress={setBillingAddress}
                toggle={toggle}
                setToggle={setToggle}
                setAddressArray={setAddressArray}
                setVendorGstNumber={setVendorGstNumber}
                // contact Dialog
                contactModal={contactModal}
                setContactModal={setContactModal}
                hideContactPop={hideContactPop}
                // shippingModal
                shippingModal={shippingModal}
                setShippingModal={setShippingModal}
                hideShippingPop={hideShippingPop}
                pincode={pincode}
                setPincode={setPincode}
                num={num}
                setNum={setNum}
                addressType={addressType}
                setAddressType={setAddressType}
                // updateShippingModal
                updateShippingModal={updateShippingModal}
                hideUpdateShippingPop={hideUpdateShippingPop}
                setUpdateShippingModal={setUpdateShippingModal}
                updateExistOrder={updateExistOrder}
                // updateBillingAddressModal
                updateBillingAddressModal={updateBillingAddressModal}
                hideUpdateBillingPop={hideUpdateBillingPop}
                setUpdateBillingAddressModal={setUpdateBillingAddressModal}
                // defaultAddressModal
                defaultAddressModal={defaultAddressModal}
                hideDefaultAddressPopUp={hideDefaultAddressPopUp}
                previousTarget={previousTarget}
                // defaultBillingAddressModal
                defaultBillingAddressModal={defaultBillingAddressModal}
                hideDefaultBillingAddressPop={hideDefaultBillingAddressPop}
                // newGstNo
                newGstNo={newGstNo}
                setNewGstNo={setNewGstNo}
                hideGstNoPopUp={hideGstNoPopUp}
                // removeGstModal
                removeGstModal={removeGstModal}
                setRemoveGstModal={setRemoveGstModal}
                hideRemoveGstPopup={hideRemoveGstPopup}
                gstNumber={gstNumber}
                setGstNumber={setGstNumber}
            />

            <LocationComponent 
                showLocationDetail={showLocationDetail}
                removeElementLocation={removeElementLocation}
                customerIDAddress={customerIDAddress}
                handleOutline={handleOutline}
                handleGstNumber={handleGstNumber}
                showContactPopLocation={showContactPopLocation}
                locationGstNumber={locationGstNumber}
                locationAddressArray={locationAddressArray}
                locationIDAddress={locationIDAddress}
                showUpdateShippingLocPop={showUpdateShippingLocPop}
                showDefaultAddressLocationPopUp={showDefaultAddressLocationPopUp}
                showShippingLocPop={showShippingLocPop}
                locationBillingAddress={locationBillingAddress}
                showUpdateBillingLocPop={showUpdateBillingLocPop}
                showdefaultLocBillingAddressPop={showdefaultLocBillingAddressPop}
                showLocGstNoPopUp={showLocGstNoPopUp}
                showRemoveLocGstPopup={showRemoveLocGstPopup}
                handleSearchLocation={handleSearchLocation}
                showLocationModal={showLocationModal}
                // aside locationModal
                showModalLocation={showModalLocation}
                hideLocationModal={hideLocationModal}
                setLocationDetail={setLocationDetail}
                searchLocation={searchLocation}
                toggleButtonLocation={toggleButtonLocation}
                setToggleButtonLocation={setToggleButtonLocation}
                setLocationIDAddress={setLocationIDAddress}
                setPreviousTarget={setPreviousTarget}
                setLocationBillingAddress={setLocationBillingAddress}
                toggleLocation={toggleLocation}
                setToggleLocation={setToggleLocation}
                setLocationAddressArray={setLocationAddressArray}
                locationContacts={locationContacts}
                setLocationContacts={setLocationContacts}
                locationContactDetails={locationContactDetails}
                setLocationContactDetails={setLocationContactDetails}
                setLocationGstNumber={setLocationGstNumber}
                // contact PopUp Location
                contactModalLocation={contactModalLocation}
                setContactModalLocation={setContactModalLocation}
                hideContactPopLocation={hideContactPopLocation}
                // shipping Dialog
                shippingLocModal={shippingLocModal}
                setShippingLocModal={setShippingLocModal}
                hideShippingLocPop={hideShippingLocPop}
                pincode={pincode}
                setPincode={setPincode}
                num={num}
                setNum={setNum}
                addressType={addressType}
                setAddressType={setAddressType}
                // update shippingDialog
                updateShippingLocModal={updateShippingLocModal}
                hideUpdateShippingLocPop={hideUpdateShippingLocPop}
                setUpdateShippingLocModal={setUpdateShippingLocModal}
                updateExistOrder={updateExistOrder}
                // update BillingDialog
                updateBillingAddressLocModal={updateBillingAddressLocModal}
                hideUpdateBillingLocPop={hideUpdateBillingLocPop}
                setUpdateBillingAddressLocModal={setUpdateBillingAddressLocModal}
                // default LocationAddressDialog
                defaultAddressLocationModal={defaultAddressLocationModal}
                hideDefaultAddressLocationPopUp={hideDefaultAddressLocationPopUp}
                previousTarget={previousTarget}
                // default Billing Address Dialog
                defaultLocBillingAddressModal={defaultLocBillingAddressModal}
                hideDefaultLocBillingAddressPop={hideDefaultLocBillingAddressPop}
                // newGST No
                newLocGstNo={newLocGstNo}
                setNewLocGstNo={setNewLocGstNo}
                hideLocGstNoPopUp={hideLocGstNoPopUp}
                // remove GST No
                removeLocGstModal={removeLocGstModal}
                setRemoveLocGstModal={setRemoveLocGstModal}
                hideRemoveLocGstPopup={hideRemoveLocGstPopup}
                gstNumber={gstNumber}
                setGstNumber={setGstNumber}
                title={"Location"}
                defaultLocation={true}
            />

            <ShippingDetailsComponent 
                shippingDetails={shippingDetails}
                handleShippingChange={handleShippingChange}
                handleShippingPrice={handleShippingPrice}
                inputRef={inputRef}
            />

            <DocumentUploadComponent 
                handleFileSelect={handleFileSelect}
                defaultFiles={defaultFiles}
                extractFileName={extractFileName}
                removeFile={removeFile}
                setDefaultFiles={setDefaultFiles}
                files={files}
                setFiles={setFiles}
            />

            <PaymentDetailsComponent 
                paymentDetails={paymentDetails}
                handlePaymentChange={handlePaymentChange}
            />

            <NotesComponent 
                handleEdit={handleEdit}
                handleBlur={handleBlur}
                notes={notes}
            />
        </Fragment>
    )
};

export default Aside;