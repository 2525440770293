import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	recentCustomersData: [],
};

const allLedgerSlice = createSlice({
	name: 'ledgers',
	initialState: initialState,
	reducers: {
		setLedgers: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setLedgers, reset } = allLedgerSlice.actions;

export default allLedgerSlice.reducer;