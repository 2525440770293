Array.prototype.unique = function () {
    const uniqueArray = [];
    const seenElements = {};

    for (let i = 0; i < this.length; i++) {
        const element = this[i];
        if (!seenElements[element]) {
            uniqueArray.push(element);
            seenElements[element] = true;
        }
    }

    return uniqueArray;
};

String.prototype.toTitleCase = function () {
  return this.replace(/\b\w/g, (char) => char.toUpperCase());
};