import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../utility/axios-instance";
import { preventNegativeValues } from "../../../../components/createPurchaseOrder/helperFunction";
import Spinner from "../../../../components/spinner";


export default function CollectCustomerPayment({ open, setOpen, id, setReRender, sellers }) {
  const [loading, setLoading] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [billingCodes, setBillingCodes] = useState([]);
  const getBillingCodes = async () => {
    setLoading(true);
    await axiosInstance
      .get(`/user/admin/getBillingCodes/${id}`)
      .then((res) => {
        setBillingCodes(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBillingCodes()
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { amount, mode, billingCode } = e.target.elements;

    if (parseFloat(amount.value) <= 0) {
      alert("Amount should be greater than 0");
      setLoading(false);
      return;
    }

    await axiosInstance
      .post(`/pos/collectCustomerPayment`, {
        id,
        amount: amount?.value,
        mode: mode?.value,
        companyId: selectedSeller?.id,
        billingCode: billingCode?.value,
      })
      .then((res) => {
        // toast({
        //   title: "Sucess",
        //   description: "Payment collected successfully",
        // });
        setLoading(false);
        setReRender((prev) => !prev);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        // toast({
        //   title: "Error",
        //   description: err?.response?.data?.message || "Something went wrong",
        //   variant: "destructive",
        // });
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        height="auto"
      >
        <DialogContent>
          <DialogContentText>Collect Payment</DialogContentText>
          <form onSubmit={handleSubmit}>

            <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
              <FormControl sx={{ width: '20%' }}
                size="small"

              >
                <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
                <Select
                  id="mode"
                  size="small"
                  name="mode"
                  label="Payment mode"
                  labelId='label'
                  required
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Card">Card</MenuItem>
                  <MenuItem value="UPI">UPI</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: '25%' }}
                size="small"

              >
                <InputLabel id="demo-simple-select-label">Billing Codes</InputLabel>
                <Select
                  id="billingCode"
                  size="small"
                  name="billingCode"
                  label="Billing Code"
                  labelId='label'
                  required
                >
                  {billingCodes?.map((code) => (
                    <MenuItem value={code?.billingCode}>{code?.billingCode}</MenuItem>
                  ))}

                </Select>
              </FormControl>
              <div style={{ width: '40%' }}>
                <Autocomplete
                  size="small"
                  required={true}
                  name="sellerId"
                  id="multiple-limit-tags"
                  options={sellers ? sellers : [{ name: "Loading...", id: 0 }]}
                  value={selectedSeller}
                  getOptionLabel={(seller) => seller?.companyNameInTally}
                  getOptionSelected={(option, seller) => option === seller}
                  onChange={(e, newVal) => setSelectedSeller(newVal)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <TextField
                id="amount"
                size="small"

                name="amount"
                placeholder="Amount"
                style={{ width: '25%' }}

                required
                type="number"
                onKeyDown={preventNegativeValues}
              />
            </div>
            <DialogActions className="flex w-full mt-3">
              <Button type="submit" size="small" variant="outlined" color="error" onClick={() => setOpen(false)}>Close</Button>
              <Button size="small" variant="contained" type="submit">Confirm</Button>
            </DialogActions>
          </form>
        </DialogContent>
        {loading && (<Spinner />)}
      </Dialog>
    </div>
  );
}
