
import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';


const  GroupTitle = (props) => {
    const {
        payloadToUpload,
        setPayloadToUpload,
    }= props;

	
	return (
		<Fragment>
            <div className={Classes.Box}>
            <div style={{display:'flex'}}>
                        <h6>Description</h6>
                    </div>

                <div className={Classes.Field} >  
    
                    <div>
                        <textarea 
                            name='description'
                            autoComplete='off'
                            className={Classes.TextArea}
                            key={ payloadToUpload?.productDescription || "" }
                            defaultValue={ payloadToUpload?.productDescription || "" }
                            onBlur={(e) => setPayloadToUpload((prevPayloadToUpload) => {
                                return { ...prevPayloadToUpload, productDescription: e?.target?.value || "" }
                            })}
                        ></textarea>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};

export default GroupTitle;
