import { Fragment, useState, forwardRef, useEffect } from 'react';
import Slide from '@mui/material/Slide';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    DialogActions,
    Autocomplete, MenuItem
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from '../../../utility/axios-instance';



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const InboundPopup = (props) => {
    const { open, handleClose, products, loading, setLoading, bins, locationCode, url, setSnackBar } = props;
    const [selectedProducts, setSelectedProducts] = useState([]);

    const closePopup = () => {
        setSelectedProducts([]);
        handleClose();
    }

    const handleInboundSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const data = selectedProducts
        await axiosInstance.post(`${url}`, data)
            .then(res => {
                console.log(res);
                closePopup();
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }

    const handleCheckboxChange = (product, checked) => {
        if (checked) {
            // If the checkbox is checked, add the product to the selectedProducts array.
            setSelectedProducts((prevSelected) => [
                ...prevSelected,
                {
                    binCode: null,
                    returnId: product.returnId,
                    quantity: product.openQty,
                },
            ]);
        } else {
            // If the checkbox is unchecked, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item?.returnId !== product?.returnId)
            );
        }
    };

    const handleQuantityChange = (product, e) => {
        const newQuantity = parseInt(e.target.value);
        const productId = product?.returnId;

        if (newQuantity === 0) {
            // If the quantity is 0, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item?.returnId !== productId)
            );
            return;
        }

        if (newQuantity > product?.openQty) {
            // If the quantity is greater than the available quantity, set the quantity to the available quantity.
            alert(`The maximum quantity available for ${product?.sku} is ${product?.openQty}.`);
            return
        }


        setSelectedProducts((prevSelected) =>
            prevSelected?.map((item) =>
                item?.returnId === productId
                    ? { ...item, quantity: newQuantity }
                    : item
            )
        );
    };

    const handleBinChange = (product, newValue) => {
        const productId = product?.returnId;

        if (newValue === null) {
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item?.returnId !== productId)
            );
            return;
        }


        setSelectedProducts((prevSelected) =>
            prevSelected?.map((item) =>
                item?.returnId === productId
                    ? { ...item, binCode: newValue }
                    : item
            )
        );
    };

    const preventNegativeValues = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{`Inbound Return`}</DialogTitle>
                <DialogContent>
                    <div style={{ margin: '8px 0px' }} >

                        {products?.length && products?.filter((prod) => prod.openQty > 0)?.map((product) => (
                            <div
                                key={product?.returnId}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '15px 0px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedProducts.some(
                                                (item) => item?.returnId === product?.returnId
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(product, e.target.checked)
                                            }
                                        />
                                    }
                                    label={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <img
                                                src={product?.mainImageUrl ? product?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                alt={product?.productTitle}
                                                width="50"
                                                height="50"
                                                style={{ marginRight: '10px', borderRadius: '5px', border: '0.5px solid gray' }}
                                            />
                                            <div>
                                                <p style={{ fontWeight: 600 }}>{product.sku}</p>
                                                <p style={{ fontSize: '12px' }}>Quantity: {product?.quantity}</p>
                                                <p style={{ fontSize: '12px' }}>Open: {product?.openQty}</p>
                                                <p style={{ fontSize: '12px' }}>Inbound: {product?.inboundQty}</p>
                                            </div>
                                        </div>
                                    }
                                />
                                {selectedProducts.some(
                                    (item) => item?.returnId === product?.returnId
                                ) && (
                                        <div style={{ display: 'flex', gap: '5px', justifyContent:'end' }}>
                                            <Autocomplete
                                                size='small'
                                                disabled={locationCode === null ? true : false}
                                                id="binId"
                                                required
                                                options={bins}
                                                style={{ width: "50%" }}
                                                onChange={(e, newValue) => {
                                                    handleBinChange(product, newValue);
                                                }}
                                                value={
                                                    selectedProducts.find(
                                                        (item) => item?.returnId === product?.returnId
                                                    ).bin
                                                }
                                                renderInput={(params) => <TextField required {...params} label="Bin" defaultValue="" />}
                                            />
                                            <TextField
                                                label="Quantity"
                                                type="number"
                                                size='small'
                                                value={
                                                    selectedProducts.find(
                                                        (item) => item?.returnId === product?.returnId
                                                    ).quantity
                                                }
                                                onChange={(e) => { handleQuantityChange(product, e) }}
                                                onKeyDown={preventNegativeValues}
                                                style={{ marginLeft: '10px' }}
                                            />
                                        </div>
                                    )}
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" id='moveInventory' variant="contained" color="primary" onClick={handleInboundSubmit} >Move</Button>
                    <Button onClick={closePopup}>Close</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default InboundPopup;