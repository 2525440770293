import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    TextField,
    Button,
    DialogActions,
    DialogContentText,
    Autocomplete
} from '@mui/material';

const LocationChangePopup = ({selectedProducts, setSelectedProducts, isOpen, onClose, products, order, setReRender, setLoading }) => {
    const [locations, setLocations] = useState([]);

    const handleSubmit = async () => {
        // check if all the products have location codes or not
        if (selectedProducts.find((product) => product.locationCode === null) !== undefined) {
            alert('Please select a location for all the products.');
            return;
        }

        setLoading(true);
        await axiosInstance
            .put(`/pgOrder/admin/updateOLineItemFulfillmentLocation`, selectedProducts)
            .then((response) => {
                setReRender((prev) => !prev);
            })
            .catch((error) => {
                setReRender((prev) => !prev);
                console.log(error);
            })

        setLoading(true);

        onClose();
    };

    const handleLocationChange = (product, locCode) => {
        setSelectedProducts((prevSelected) =>
            prevSelected?.map((item) =>
                item.oLineItemId === product.oLineItemId
                    ? { ...item, locationCode: locCode }
                    : item
            )
        );
    };

    useEffect(() => {
        axiosInstance.get(`/location/locations?text=`)
            .then(res => {
                const locations = [];
                for (let location of res?.data?.data) {
                    locations.push(location?.locationCode)
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);


    return (

        <Dialog open={isOpen} onClose={onClose} fullWidth={true}
        >
            <DialogTitle>Change Line Item Location</DialogTitle>
            <DialogContent>
                {products?.map((product) => (
                    <div
                        key={product.oLineItemId}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '15px 0px',
                            justifyContent: 'space-evenly',
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <img
                                src={product?.mainImageUrl ? product?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                alt={product?.productTitle}
                                width="50"
                                height="50"
                                style={{ marginRight: '10px', borderRadius: '5px', border: '0.5px solid gray' }}
                            />
                            <div>
                                <p style={{ fontWeight: 600 }}>{product.sku}</p>
                                <p style={{ fontSize: '12px' }}>Quantity: {product?.quantity}</p>
                            </div>
                        </div>
                        <Autocomplete
                            size='small'
                            required
                            id="location"
                            options={locations}
                            onChange={(event, newValue) => {
                                handleLocationChange(product, newValue);
                            }}
                            style={{width:'70%'}}
                            value={
                                selectedProducts.find(
                                    (item) => item.oLineItemId === product.oLineItemId
                                ).locationCode
                            }
                            renderInput={(params) => <TextField {...params} label="Location" defaultValue="" />}
                        />

                    </div>
                ))}

            </DialogContent>
            <DialogActions>
                {products.length > 0 && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Confirm
                    </Button>
                )}
                <Button variant="outlined" color="primary" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>


        </Dialog>
    );
};

export default LocationChangePopup;
