import { useEffect, useState } from "react";
import axiosInstance from "../utility/axios-instance";
import InfiniteScroll from "react-infinite-scroll-component";
import { Checkbox, Chip, Divider, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, useForkRef } from "@mui/material";
import {Search} from '@mui/icons-material';
import Classes from "../pages/home/createOrders/css/create.module.css";



const InfinityScrollAutoComplete = (props) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [searchPage, setSearchPage] = useState(1);
  const [page, setPages] = useState(1);
  const [maxPages, setMaxPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [toggleButton, setToggleButton] = useState(false);
  const [toggle, setToggle] = useState(true);
  const limit = 10;
  // const [selectedValue, setSelectedValue] = useState([]);// [{key : '', label : ''}]
  const {
    endpoint,
    dataPosition,
    uniqueKey,
    label,
    placeholder,
    searchTag = "text",
    selectedValue,
    setSelectedValue,
    multiSelect = true,
    filters,
    required=false, 
    index, 
    shouldOptionValueContainUniqueKey = true
  } = props;

  const [searchData, setSearchData] = useState(null);

  const handleSelectValue = async (value) => {
    if(multiSelect){
      const dataExist = selectedValue.find( item => item?.key === value?.key);
      let newChecked = [...selectedValue];
      if (!dataExist) {
        newChecked.push(value);
      } else {
        newChecked = newChecked.filter((item ) => item?.key !== value?.key);
      }
      setSelectedValue(newChecked);
    }
    else{
      setSelectedValue([value]);
      hideDataModal(false);
      setSearchData('')
    }
  }

  const fetchMoreData = () => {
    if (maxPages >= page) {
      setTimeout(() => {
        searchData && axiosInstance
          .get(`${endpoint}?page=${page}&${searchTag}=${searchData}${filters && `&filters=${JSON.stringify(filters)}`}${limit && `&limit=${limit}`}`) // searchTag can be 'searchText' / 'text' / 'searchFor'
          .then((res) => {
            if (res.data.data.maxPage >= page) {
              setPages(prev => prev + 1);
            } else {
              setPages(1);
            }
            const moreData = res.data.data[dataPosition].map(item => {
              return {
                key : item[uniqueKey], label : item[label], ...item
              }
            })
            setFetchedData([...fetchedData, ...moreData]);
            // setFetchedData([...moreData]);
          });
      }, 1000);
    }
  };

  const showDataModal = () => {
    setShowModal(true);
  };

  const handleSearchData = (searchText) => {
    setToggleButton(true);
    showDataModal()
    setSearchData(searchText);
  };

  const hideDataModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (toggleButton) {

      const delay = 500;
      const debounce = setTimeout(() => {
        
      searchData && axiosInstance
        .get(`${endpoint}?${searchTag}=${searchData}&page=${searchPage}${filters && `&filters=${JSON.stringify(filters)}`}${limit && `&limit=${limit}`}`)
        .then((res) => {
          setMaxPage(res.data.data.maxPage);
          // data is like [{key : '', label : ''}, {}, .....]
          const data = res.data.data[dataPosition]?.map( item => {
            return {
              key : item[uniqueKey], label : item[label], ...item
            }
          })
          setFetchedData(data);
          if (res?.data?.data?.maxPages > searchPage) {
            setSearchPage(prev => prev + 1);
            setPages(2);
          }
          setToggleButton(false);
        });

      }, delay);
      return () => clearTimeout(debounce);
    }
  }, [toggleButton, searchData, searchPage]);

  useEffect(() => {
    if (toggle & searchData) {
      axiosInstance.get(`${endpoint}?${filters && `filters=${JSON.stringify(filters)}`}${limit && `&limit=${limit}`}`).then((res) => {
        const data = res.data.data[dataPosition]?.map( item => {
          return {
            key : item[uniqueKey], label : item[label], ...item
          }
        })
        setFetchedData(data);
        setTotal(res.data.data.total);
        setMaxPage(res.data.data.maxPage);
        if (res?.data?.data?.maxPages > 1){
          setPages(2);
        }
        setToggle(false);
      });
    }
  }, [toggle]);

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("modal");
    if (container !== null) {
      if (!container.contains(e.target)) {
        hideDataModal(false);
        setSearchData('');
        setToggle(true);
      }
    }
  });

  const handleRemoveSelectedValue = (itemToDelete) => {
    setSelectedValue(values => values.filter((v) => v.key !== itemToDelete.key))
  }

  return (
    <div>
    {multiSelect && selectedValue.length !== 0 && 
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 , marginBottom: '5px'}}>
        {selectedValue.map( item => <Chip key={item.key} label={item.label} sx={{height:'30px', cursor: 'pointer'}} variant="outlined" color="primary" size='medium' onDelete={() => handleRemoveSelectedValue(item)}/>)}
      </div>
    }
    <div style={{ width: "100%" }}>
      <TextField 
        key={index}
        type="text"
        id='search'
        size="small"
        label={placeholder}
        style={{width: '100%'}}
        className={Classes.searchField}
        placeholder={placeholder}
        autoComplete="off"
        value={searchData || ""}
        onChange={(e) => handleSearchData(e.target.value)}
        // onClick={() => showDataModal()}
        sx={{paddingRight:'0px !important',width: '100%'}}
        required={required}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </div>
    {showModal &&(
      <div style={styles} id='modal'>
        <div style={{ maxHeight: "350px" }}>
          {/* {fetchedData?.length !== 0 && */}
          <div>
            <InfiniteScroll
              dataLength={fetchedData?.length}
              next={fetchMoreData}
              hasMore={page < maxPages}
              loader={page < maxPages ? <h6>Loading...</h6> : ""}
              endMessage={<p  style={{ textAlign: 'center' }} >No more data to load</p>}
            >
              <List>
                {fetchedData && fetchedData?.map(value => {
                      const labelId = `checkBox-${value.key}`;
                      return (
                        <> 
                          <ListItem disablePadding key={value.key}>
                            <ListItemButton onClick={(e) => handleSelectValue(value)} dense>
                              {multiSelect && <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={selectedValue?.find( item => item?.key === value?.key) ? true : false}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>}
                              {
                                shouldOptionValueContainUniqueKey 
                                ? <ListItemText id={labelId} primary={`${value?.label}`} secondary={`${uniqueKey?.toUpperCase()} : ${value?.key}`} />
                                : <ListItemText id={labelId} primary={value?.label} />
                              }
                            </ListItemButton>
                          </ListItem>
                          <Divider variant="fullWidth" component="li" />
                        </>
                      )}
                )}
              {fetchedData && fetchedData?.length === 0 && <ListItem disablePadding>
                <ListItemButton disabled>
                  <ListItemText primary="No items available" />
                </ListItemButton>
              </ListItem>}
            </List>
            </InfiniteScroll>
          </div>
          {/* } */}
        </div>
      </div>
    )}
    </div>
  )
}

const styles = {
  boxShadow:'0 10px 30px 0 rgba(127, 127, 127, 0.3)',
  background:'#fff',
  borderRadius:'10px',
  zIndex:'1000',
  overflowY:'scroll',
  fontSize:'14px',
  padding:'5px',
}

export default InfinityScrollAutoComplete;