import React, { useState } from 'react';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';


const CollectPaymentDialog = ({ codAmount, creditAmount,creditAmountCollected, remainingAmount, orderId, setReRender}) => {
    const [formData, setFormData] = useState(
        {
            deliveryRider: '',
            amount: '',
            type: '' 
        }
    );
    const modes = [];
    if (codAmount) { modes.push('COD'); }

    if (creditAmountCollected === false && creditAmount) { modes.push('Credit'); }
    
    const [openDialog, setOpenDialog] = useState(false);

    const preventNegativeValues = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

    return (
        <FormDialog
            dialogTitle={"Collect Payment"}
            openDialogButtonName={"Collect ₹"}
            submitDialogButtonName={"Submit"}
            dialogActionType={"update"}
            endPoint={`/pos/collectPayment/${orderId}`}
            formData={{ ...formData }}
            setFormData={setFormData}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            defaultFormData={{
                deliveryRider: '',
                amount: '',
            }}
            setReRender={setReRender}
        >
            <div>
                {codAmount ? ( <p>COD Amount: {codAmount}</p> ) : null}
                
                {creditAmountCollected === false && creditAmount ? ( <p>Credit Amount: {creditAmount}</p> ) : null}
            </div>
            <div>
                Remaining Amount: {remainingAmount}
            </div>
            <Grid container spacing={1.5} marginTop={'0.2em'}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Rider Name"
                        value={formData?.deliveryRider || ''}
                        onChange={(event) => setFormData((prevFormData) => {
                            const deliveryRider = event?.target?.value
                            return {
                                ...prevFormData,
                                deliveryRider
                            }
                        })}
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        disablePortal
                        id="mode"
                        name="mode"
                        options={modes}
                        ListboxProps={{sx: { fontSize: 14 }}}
                        sx={{  width: '100%' }}
                        size='small'
                        onChange={(event,newValue) => {
                            setFormData((prevFormData) => {
                                const mode = newValue
                                return {
                                    ...prevFormData,
                                    type: mode,
                                    amount: mode === 'COD' ? parseFloat(codAmount) : parseFloat(creditAmount)
                                }
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="Mode"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Amount"
                        type="number"
                        onKeyDown={preventNegativeValues}
                        value={formData?.amount || ''}
                        onChange={(event) => {
                            setFormData((prevFormData) => {
                                const userAmount = parseFloat(event?.target?.value)
                                const amount = prevFormData?.type === 'COD' ? codAmount : creditAmount
                                if (userAmount > amount) {
                                    alert(`Amount can not be greater than Remaining ${prevFormData?.type} Amount!`)
                                    return {
                                        ...prevFormData,
                                        amount: 0
                                    }
                                }
                                return {
                                    ...prevFormData,
                                    amount: userAmount
                                }
                            })
                        }}
                        fullWidth
                        size='small'
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default CollectPaymentDialog;
