import React, { Fragment,useState } from 'react';
import Table from '../../../components/table/table';
import ExportDatas from '../../../components/exportDataDialog';
import Button from '@mui/material/Button';
import MobileResponsive from './mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setOrderAllocations } from '../../../store/orderAllocationsSlice';
import { Tooltip } from '@mui/material';

const columnVisibilityModel = {
    order_id: false,
    // created_at: false,
    updated_at: false,
}

const colorizeRowData = (data, condition) => (
    <Tooltip title={condition ? "Child LineItem" : ""} placement="top">
        <div style={{color: condition === true ? "#ff6200" : '#000'}}>{data}</div>
    </Tooltip>);
const columns = [
    {
        field: 'order_id', 
        headerName: 'Order Id', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.order_id, params?.row?.parentSKU !== null)
    },
    {
        field: 'order_name', 
        headerName: 'Order Name', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.order_name, params?.row?.parentSKU !== null)
    },
    {
        field: 'sku',
        headerName: 'SKU',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.sku, params?.row?.parentSKU !== null)
    },
    { 
        field: 'bin_code', 
        headerName: 'Bin Code', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.bin_code, params?.row?.parentSKU !== null)
    },
    {
        field: 'location_code',
        headerName: 'Location Code',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.location_code, params?.row?.parentSKU !== null)
    },
    {
        field: 'mode',
        headerName: 'Mode',
        flex:0.6,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.mode, params?.row?.parentSKU !== null)
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.created_by, params?.row?.parentSKU !== null)
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        flex:1.4,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.created_at, params?.row?.parentSKU !== null)
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.updated_at, params?.row?.parentSKU !== null)
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.quantity, params?.row?.parentSKU !== null)
    }
];

const OrderAllocated = (props) => {
    const [open,setOpen] = useState(false);
    const handleExportDialog = () => {
        setOpen(true);
    }

    const orderAllocations = useSelector((state) => state.orderAllocations);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Fragment>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:-25,width:'100%',marginBottom:10}}>
                <Button  
                    variant="contained"
                    color="primary"
                    sx={{ fontSize:14,textTransform:'capitalize',padding:"4px 14px",letterSpacing:"0.5px" }}
                    onClick={handleExportDialog}
                >
                    Export
                </Button>
            </div>

            <div className={Classes.MuiTable}>
                <Table 
                    columns={columns} 
                    dataPosition={"fetchedOrderAllocations"} 
                    endpoint={`/orderAllocation/fetchOrderAllocations`} 
                    rowId={'id'}
                    checkBoxSelection={false}
                    version={"version-2"}
                    columnVisibilityModel={columnVisibilityModel}
                    service={'orderAllocations'}
                    tabName={'Order Allocations'}
                    setData={setOrderAllocations}
                    data={orderAllocations}
                />
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns} 
                    dataPosition={"fetchedOrderAllocations"} 
                    endpoint={`/orderAllocation/fetchOrderAllocations`} 
                    rowId={'id'}
                />
            </div>

            <ExportDatas 
                open={open}
                handleClose={handleClose}
                endPoint={'/exports/orderAllocations'}
                dataName="Order Allocations"
            />

        </Fragment>
    )
}
export default OrderAllocated;