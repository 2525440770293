import React, { Fragment } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';

const ContactPopUp = (props) => {
    const {
        contactModal,
        hideContactPop,
        showCustomerDetail,
        setCustomerDetail
    } = props;

    const handleSubmit = (event) => {
        event.preventDefault();
        hideContactPop();

        const data = {
            "vendorName": event.target.name.value,
            "status": event.target.vendorStatus.value === 'None' ? undefined : event.target.vendorStatus.value
        }

        axiosInstance
            .put(`/vendor/updateVendor/${showCustomerDetail[0].vendorCode}`, { ...data })
            .then((res) => {
                console.log(res.data);
                setCustomerDetail([res.data.data])
            });
    }


    return (
        <Fragment>
            {contactModal && (
                <div className={[Classes.modal, Classes.contactPopUp].join(' ')} style={{bottom:'36%'}}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Contact Information</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{ padding: '30px 20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField 
                                        required
                                        id='name'
                                        name="name"
                                        label="Vendor Name"
                                        key={showCustomerDetail[0]?.vendorName}
                                        defaultValue={showCustomerDetail[0]?.vendorName}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: showCustomerDetail[0]?.vendorName ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'48%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="vendorStatus"
                                        name="vendorStatus"
                                        key={showCustomerDetail[0]?.status}
                                        defaultValue={showCustomerDetail[0]?.status}
                                        options={["Active","In-Active","Dis-Continued"]}
                                        sx={{width: '100%',"& label": {top: showCustomerDetail[0]?.status ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        renderInput={(params) => <TextField {...params} label="Status"/>}
                                    />
                                </div>
                            </div>

                            <div style={{ width:'48%',marginTop:20 }}>
                                <TextField 
                                    required
                                    disabled={true}
                                    id='vendorCode'
                                    name="vendorCode"
                                    label="Vendor Code"
                                    key={showCustomerDetail[0]?.vendorCode}
                                    defaultValue={showCustomerDetail[0]?.vendorCode}
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top: showCustomerDetail[0]?.vendorName ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'36%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>

                    </form>
                </div>
            )}
        </Fragment>
    );
}


export default ContactPopUp;