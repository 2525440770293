import { Fragment, useState, useEffect } from 'react';
import axiosInstance from '../../../../utility/axios-instance';
import Classes from './css/addProduct.module.css';
import { TextField, Autocomplete } from '@mui/material';
import Prices from '../prices';

const  ProductBasicDetails = (props) => {
    const [units, setUnits] = useState([]);
    const [hsnCodes, setHsnCodes] = useState([]);

    const { payloadToUpload, setPayloadToUpload } = props;

    useEffect(() => {
      axiosInstance.get("/others/unit").then((res) => {
        setUnits(res.data.data);
      });
    }, []);
    
    useEffect(() => {
      axiosInstance.get("/others/hsnCodes").then((res) => {
        setHsnCodes(res?.data?.data);
      });
    }, []);

    const preventSpecialCharValues = (e) => ["/"].includes(e.key) && e.preventDefault();
    
    return (
        <Fragment>
            <div className={[Classes.Pricing,Classes.Boxes].join(' ')}>
                <h6>Basic Details</h6>
                <div style={{marginTop:25}}>
                    <TextField
                        type="text"
                        id="groupTitle"
                        name='title'
                        size='small'
                        required={true}
                        key={payloadToUpload?.groupName ||  ""}
                        defaultValue={payloadToUpload?.groupName ||  ""}
                        autoComplete='off'
                        className={[Classes.searchField,Classes.InputField].join(' ')}
                        onBlur={(e) => {
                            setPayloadToUpload({...payloadToUpload, groupName: e?.target?.value})
                        }}
                        label='Group Title (Shopify Listing Name)'
                    />
                </div>

                <div style={{marginTop:25}}>
                    <TextField
                        name="productName"
                        type="text"
                        id="product_title"
                        required={true}
                        size='small'
                        autoComplete="off"
                        defaultValue={payloadToUpload?.productName ||  ""}
                        key={payloadToUpload?.productName || ""}
                        className={[Classes.searchField,Classes.InputField,"productTitle"].join(" ")}
                        onBlur={(e) => {
                            setPayloadToUpload({...payloadToUpload, productName: e?.target?.value})
                        }}
                        placeholder="Hinges"
                        label='Product Title (System Listing Name)'
                    />
                </div>

                <div style={{display:'flex',justifyContent:'space-between', marginTop:25}}>
                    <div style={{width:'48%'}}>
                        <TextField
                            name='dsinCode'
                            type="text"
                            id="dsinCode"
                            size='small'
                            defaultValue={payloadToUpload?.sku ||  ""}
                            key={payloadToUpload?.sku || ""}
                            autoComplete='off'
                            required={true}
                            onBlur={(e) => {
                                setPayloadToUpload({...payloadToUpload, sku: e?.target?.value})
                            }}
                            onKeyDown={preventSpecialCharValues}
                            className={[Classes.searchField,Classes.InputField].join(' ')}
                            label='DSIN Code (SKU)'
                        />
                    </div>

                    <div style={{width:'48%'}}>
                        <Autocomplete
                            freeSolo
                            forcePopupIcon={true}
                            name='hsnCode'
                            id='hsnCode'
                            defaultValue={
                                payloadToUpload?.hsnCode || ''
                            }
                            key={
                                payloadToUpload?.hsnCode || ''
                            }
                            size='small'
                            options={
                                hsnCodes?.length !== 0 ? 
                                hsnCodes?.map((e, index) => (
                                    {id: e?.Percentage, label: JSON.stringify(e?.HSNCode), index: index}
                            )) : []}
                            renderOption={(props, option) => (
                                <li {...props} key={`${option.id}-${option.label}-${option.index}`}>
                                    {option.label}
                                </li>
                            )}
                            onChange={(event, newValue) => (setPayloadToUpload({...payloadToUpload, hsnCode: newValue?.label}))}
                            renderInput={(params) => <TextField {...params} required label="HSN Code"/>}
                            disablePortal
                            ListboxProps={{sx: { fontSize: 14 }}}
                            sx={{  width: '100%' }}
                            getOptionSelected ={(option, value) => option?.label === value?.label}
                            isOptionEqualToValue={(option, value) =>  option.label === value.label}
                        />
                    </div> 
                </div>

                <div style={{display:'flex',justifyContent:'space-between', marginTop:25, flexWrap: "wrap", rowGap: "1rem"}}>
                    <Prices
                        payloadToUpload={payloadToUpload}
                        setPayloadToUpload={setPayloadToUpload}
                        pricesType="defaultPrices"
                    />
                </div>

                <div style={{display:'flex',justifyContent:'space-between', marginTop:25}}>
                    <div style={{width:'48%' }}>
                        <Autocomplete
                            freeSolo
                            forcePopupIcon={true}
                            name="unit" 
                            id='unit'
                            defaultValue={ payloadToUpload?.unit || '' }
                            key={ payloadToUpload?.unit || '' }
                            size='small'
                            options={units?.length !== 0 ? units?.map(unit => (unit?.unit)) : []}
                            onChange={(event,newValue) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, unit: newValue}))}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionSelected ={(option, value) => option === value}
                            renderInput={(params) => <TextField {...params} label="UNIT" required />}
                            disablePortal
                            ListboxProps={{sx: { fontSize: 14 }}}
                            sx={{  width: '100%' }}
                        />
                    </div>

                    <div style={{width:'48%'}}>
                        <TextField
                            name='deliveryTime'
                            type="number"
                            id="delivery_time"
                            size='small'
                            onKeyDown={e => ( e.keyCode === 69)  && e.preventDefault()}
                            key={ payloadToUpload?.shipmentTime || '' }
                            defaultValue={ payloadToUpload?.shipmentTime || '' }
                            label='Delivery Time (Hour/s)'
                            autoComplete='off'
                            onBlur={(e) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, shipmentTime: e?.target?.value}))}
                            className={[Classes.searchField, Classes.InputField].join(' ')}
                        />
                    </div>
                </div>

                <div style={{display:'flex',justifyContent:'space-between', marginTop:25}}>
                    <div style={{width:'48%'}}>
                        <Autocomplete
                            freeSolo
                            forcePopupIcon={true}
                            name="bulkType1" 
                            id='bulkType1'
                            defaultValue={ payloadToUpload?.bulkPackingType1 || '' }
                            key={ payloadToUpload?.bulkPackingType1 || '' }
                            size='small'
                            options={units?.length !== 0 ? units?.map(unit => (unit?.unit)) : []}
                            disablePortal
                            ListboxProps={{sx: { fontSize: 14 }}}
                            sx={{  width: '100%' }}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionSelected ={(option, value) => option === value}
                            onChange={(event, newValue) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, bulkPackingType1: newValue}))}
                            renderInput={(params) => <TextField {...params} label="Bulk Packaging Type 1" />}
                        />
                    </div>

                    <div style={{display:'flex',width:'48%'}}>
                        <TextField
                            name='bulkVal1'
                            type="number"
                            id="bulkVal1"
                            size='small'
                            onKeyDown={e => ( e.keyCode === 69) && e.preventDefault()}
                            defaultValue={ payloadToUpload?.bulkPackingValue1 || '' }
                            key={ payloadToUpload?.bulkPackingValue1 || '' }
                            className={[Classes.searchField, Classes.InputField].join(' ')}
                            onChange={(event) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, bulkPackingValue1: event?.target?.value}))}
                            label='Quantity'
                        />
                        <p style={{margin:'auto 0px auto 5px'}}>{payloadToUpload?.unit}</p>
                    </div>
                </div>

                <div style={{ display:'flex', justifyContent:'space-between', marginTop:25 }}>
                    <div style={{width:'48%'}}>
                        <Autocomplete
                            freeSolo
                            forcePopupIcon={true}
                            name="bulkType2" 
                            id='bulkType2'
                            defaultValue={ payloadToUpload?.bulkPackingType2 || '' }
                            key={ payloadToUpload?.bulkPackingType2 || '' }
                            size='small'
                            options={units?.length !== 0 ? units?.map(unit => (unit?.unit)) : []}
                            disablePortal
                            ListboxProps={{sx: { fontSize: 14 }}}
                            sx={{  width: '100%' }}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionSelected ={(option, value) => option === value}
                            onChange={(event, newValue) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, bulkPackingType2: newValue}))}
                            renderInput={(params) => <TextField {...params} label="Bulk Packaging Type 2" />}
                        />
                    </div>

                    <div style={{display:'flex',width:'48%'}}>
                        <TextField
                            name='bulkVal2'
                            type="number"
                            id="bulkVal2"
                            size='small'
                            onKeyDown={e => ( e.keyCode === 69) && e.preventDefault()}
                            defaultValue={ payloadToUpload?.bulkPackingValue2 || '' }
                            key={ payloadToUpload?.bulkPackingValue2 || '' }
                            onChange={(event) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, bulkPackingValue2: event?.target?.value}))}
                            className={[Classes.searchField, Classes.InputField].join(' ')}
                            label='Quantity'
                        />
                        <p style={{margin:'auto 0px auto 5px'}}>{payloadToUpload?.unit}</p>
                    </div>
                </div>

                <div style={{width:'48%', marginTop:25}}>
                    <TextField
                        name='modelNumber'
                        type="text"
                        id="modelNumber"
                        size='small'
                        key={ payloadToUpload?.modelNo || '' }
                        defaultValue={ payloadToUpload?.modelNo || '' }
                        autoComplete='off'
                        onBlur={(e) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, modelNo: e?.target?.value}))}
                        className={[Classes.searchField, Classes.InputField].join(' ')}
                        label='Model Number'
                    />
                </div>

                <div className={Classes.CommonMarginTop} style={{display:'flex'}}>
                    <div>
                        <input
                            type='checkbox' 
                            name='chargeTax'
                            id='chargeTax'
                            checked={payloadToUpload?.isTaxable == "no" ? false : true} // default value is true
                            onChange={(e) => setPayloadToUpload((prevPayloadToUpload) => ({...prevPayloadToUpload, isTaxable: e?.target?.checked ? "yes" : "no"}))}
                        />
                    </div>
                    <p style={{padding: '3px 10px'}}>Charge tax on this product</p>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductBasicDetails;