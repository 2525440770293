import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from './css/order.module.css';

const years = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const Order = (props) => {
	const [hover, setHover] = useState(false);
	const navigate = useNavigate();

	const { order } = props;
	const cancelled = !!order.cancel;

	const dn = new Date();
	const createdAt = new Date(order.createdAt);
	const cancelledAt = cancelled ? new Date(order.cancel.cancelledAt) : null;

	let createdAtDate =
		createdAt.getFullYear() === dn.getFullYear()
			? createdAt.getMonth() === dn.getMonth()
				? createdAt.getDate() === dn.getDate()
					? `Today at ${createdAt.toLocaleTimeString()}`
					: createdAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${createdAt.toLocaleTimeString()}`
					: years[createdAt.getMonth()] +
					  ' ' +
					  createdAt.getDate() +
					  ' at ' +
					  createdAt.toLocaleTimeString()
				: years[createdAt.getMonth()] +
				  ' ' +
				  createdAt.getDate() +
				  ' at ' +
				  createdAt.toLocaleTimeString()
			: years[createdAt.getMonth()] + ' ' + createdAt.getFullYear();

	let cancelledAtDate = cancelledAt
		? cancelledAt.getFullYear() === dn.getFullYear()
			? cancelledAt.getMonth() === dn.getMonth()
				? cancelledAt.getDate() === dn.getDate()
					? `Today at ${cancelledAt.toLocaleTimeString()}`
					: cancelledAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${cancelledAt.toLocaleTimeString()}`
					: years[cancelledAt.getMonth()] +
					  ' ' +
					  cancelledAt.getDate() +
					  ' at ' +
					  cancelledAt.toLocaleTimeString()
				: years[cancelledAt.getMonth()] +
				  ' ' +
				  cancelledAt.getDate() +
				  ' at ' +
				  cancelledAt.toLocaleTimeString()
			: years[cancelledAt.getMonth()] + ' ' + cancelledAt.getFullYear()
		: null;

	return (
		<div
			key={order?._id}
			className={[hover ? 'bg-light' : '',Classes.MultiOrders].join(' ')}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={() => navigate(`/orders/${order?._id}`)}
		>
			<div className={ Classes.orderName }>
				<h6 className={Classes.MultiOrdersName}>
					{cancelled ? (
						<del>
							{order?.draftOrder?.order ? 
							order?.draftOrder?.order?.name : 
							order?.draftOrder?.name}
						</del>
					) : (
						<>
							{order?.draftOrder?.order ? 
							order?.draftOrder?.order?.name : 
							order?.draftOrder?.name}
						</>
					)}
				</h6>
	
				<div>
					<div className={ Classes.ResponsiveCreatedAt }>
						{cancelled ? <del>{createdAtDate}</del> : createdAtDate}
					</div>

					<div className={ Classes.ResponsiveCustomerName }>
						<p>
							{order?.user?.displayName ? 
							order?.user?.displayName : 
							`${order?.user?.firstName} ${order?.user?.lastName && order?.user?.lastName}`}
						</p>
					</div>

					<div className={Classes.ResponsivePaymentStatus}>
						<p className={Classes.MultiOrdersStatus}>
							Payment Status:
						</p>
						<p
							className={["px-2",Classes.MultiOrdersBorderWidth].join(' ')}
							style={{ backgroundColor:order.paymentStatus === 'paid' ? '#e4e5e7' : '#ffd79d' }}
						>
							{order?.paymentStatus}
						</p>
					</div>

					<div className={ Classes.ResponsiveOrderStatus }>
						<p className={Classes.MultiOrdersStatus}>
							Order Status: 
						</p>
						<p
							className={["px-2",Classes.MultiOrdersBorderWidth].join(' ')}
							style={{ backgroundColor:order.orderStatus === 'delivered' ? '#e4e5e7' : '#ffea88' }}
						>
							{order?.orderStatus}
						</p>
					</div>

					{cancelled ? (
						<div className={ Classes.ResponsiveCancelledAt }>
							<p className={Classes.MultiOrdersStatus}>
								CancelledAt: 
							</p>
							<p>{cancelledAtDate}</p>
						</div>
					): null}
					

					<div className={ Classes.ResponsiveOrderType }>
						<p className={Classes.MultiOrdersStatus}>
							Order Type: 
						</p>
						<p>{order?.orderType}</p>
					</div>
				</div>
			</div>

			<div className={ Classes.CreatedAt }>
				{cancelled ? <del>{createdAtDate}</del> : createdAtDate}
			</div>

			<div className={ Classes.Customer }>
				{order?.user?.displayName ? 
				order?.user?.displayName : 
				`${order?.user?.firstName} ${order?.user?.lastName && order?.user?.lastName}`}
			</div>

			<div className={ Classes.Payment }>
				<p
					className={["py-1 px-3",Classes.MultiOrdersBorderWidth].join(' ')}
					style={{ backgroundColor:order.paymentStatus === 'paid' ? '#e4e5e7' : '#ffd79d' }}
				>
					{order?.paymentStatus}
				</p>
			</div>

			<div className={ Classes.OrderStatus }>
				<p
					className={["py-1 px-3",Classes.MultiOrdersBorderWidth].join(' ')}
					style={{ backgroundColor:order.orderStatus === 'delivered' ? '#e4e5e7' : '#ffea88' }}
				>
					{order?.orderStatus}
				</p>
			</div>

			<p className={ Classes.CancelledAt }>
				{cancelledAtDate}
			</p>

			<p 
				className={ Classes.OrderType }
				style={{ paddingLeft: 18 }}
			>
				{order?.orderType}
			</p>

			<p 
				className={ Classes.OrderType }
				style={{ paddingLeft:5 }}
			>
				{order?.createdBy?.name}
			</p>
		</div>
	);
};

export default Order;
