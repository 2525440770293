import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';

const DateRange = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDateRef,
    runAfterStartDateChange,
    runAfterEndDateChange
}) => {
    return (
        <div style={{ display: 'flex' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Start Date"
                    ref={startDateRef}
                    sx={{
                        "& .MuiOutlinedInput-input": { padding: "9.5px 6px", fontSize: '14px' },
                        "& .MuiSvgIcon-root": { fontSize: "18px" },
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: '#0000003b !important' },
                        "& .Mui-error": { color: '#00000099 !important' }
                        // "& .MuiInputLabel-root": { top: endDate? "0%" :"-18%" }
                    }}
                    value={startDate || " "}
                    onChange={(newValue) => {
                        setStartDate(newValue);
                        // runAfterStartDateChange(newValue);
                    }}
                />
                <DatePicker
                    label="End Date"
                    sx={{
                        "& .MuiOutlinedInput-input": { padding: "9.5px 6px", fontSize: '14px' },
                        "& .MuiSvgIcon-root": { fontSize: "18px" },
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: '#0000003b !important' },
                        "& .Mui-error": { color: '#00000099 !important' }
                        // "& .MuiInputLabel-root": { top: endDate? "0%" :"-18%" }
                    }}
                    value={endDate || " "}
                    onChange={(newValue) => {
                        setEndDate(newValue);
                        // runAfterEndDateChange(newValue);
                    }}
                />
            </LocalizationProvider>
        </div>
    )
}

export default DateRange;