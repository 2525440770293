import React from 'react'
import Classes from './css/orders.module.css';

const ProductContainer = ({ draftOrder }) => {

    const countPlaces = (num) => {
        var sep = String(23.32).match(/\D/)[0];
        var b = String(num).split(sep);
        return b[1] ? b[1].length : 0;
    }
    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    // handle Discount
    const handleDiscount = (mrp, discountedPrice, value) => {
        const MRP = parseFloat(mrp);
        const DP = parseFloat(discountedPrice);
        const GST = parseInt(value);
        const result = (MRP - (DP * (1 + GST / 100))) / MRP;
        const TotalDiscount = result * 100;
        const countDecimal = countPlaces(TotalDiscount);
        let results = countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? TotalDiscount : Math.trunc(TotalDiscount * 100) / 100;
        return results;
    };

    const handleInclGstTotal = (amount, tax) => {
        const total = parseFloat(amount);
        const gst = parseInt(tax);
        const result = total * (1 + (gst / 100));
        const countDecimal = countPlaces(result)
        return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result * 100) / 100
    }

    const rateInclGST = (amount, tax) => {
        const total = parseFloat(amount);
        const gst = parseInt(tax);
        const result = total * (1 + (gst / 100));
        const countDecimal = countPlaces(result)
        return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result * 100) / 100
    }
    return (
        <div id="invoiceProductContainer">
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {draftOrder.length !== 0 ? (
                    <div
                        style={{ fontSize: '14px', padding: 0 }}
                    >
                        <div className={Classes.Thead}>
                            <div
                                style={{
                                    padding: '10px 20px',
                                    fontWeight: 600,
                                    width:'40%'
                                    
                                }}
                                className={Classes.ProductHeading}
                                id='productHeading'
                            >
                                <p>Product</p>
                            </div>

                            <div
                                className={[
                                    Classes.hideResponsive,
                                    'discount'
                                ].join(' ')}
                                style={{
                                    width: '13%',
                                    padding: '10px 0px'
                                }}
                            >
                                <p style={{ fontWeight: 600 }}>
                                    Discount
                                </p>
                                <p
                                    style={{ fontSize: '13px' }}
                                    id='hideInclGst'
                                >
                                    (Incl. GST)
                                </p>
                            </div>
                            <div
                                className={Classes.hideResponsive}
                                style={{ width: '10%', padding: '10px 0px' }}
                            >
                                <p style={{ fontWeight: 600 }}>
                                    Quantity
                                </p>
                            </div>
                            <div
                                className={Classes.hideResponsive}
                                style={{ width: '13%', padding: '10px 10px' }}
                            >
                                <p style={{ fontWeight: 600 }}>
                                    Subtotal
                                </p>
                            </div>
                            <div
                                className={Classes.hideResponsive}
                                style={{ padding: '10px 0px 10px 8px', width: '13%' }}>
                                <p style={{ fontWeight: 600, paddingLeft: 5 }}>
                                    Total
                                </p>
                                <p>(Incl. GST)</p>
                            </div>


                        </div>
                        <div id='productContainer'>
                            {draftOrder?.OLineItems?.unfulfilledLineItems?.map((item, index) => (
                                <div
                                    className={[
                                        Classes.containProduct,
                                        'variantProduct'
                                    ].join(' ')}
                                    id={item.oLineItemId}
                                    key={index}
                                >
                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', color:'#6d7175'  }}>#{index+ 1}. </div>
                                    <div
                                        className={[
                                            Classes.productDetail,
                                            'products'
                                        ].join(' ')}
                                        id={index}
                                        style={{width: '40%'}}
                                    >
                                        <div>
                                            <img
                                                src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                alt="icon"
                                                className={Classes.productImage}
                                            />
                                        </div>

                                        <div className={Classes.VariantDetail}>
                                            <div>
                                                <p style={{ color: '#0d6efd' }}>
                                                    {item.productTitle}
                                                </p>
                                            </div>
                                            <div style={{ color: '#6d7175' }}>
                                                {/* <p>{item.node.variantTitle}</p> */}
                                                <p>{item.sku}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}
                                                className={[
                                                    Classes.rateFontSize,
                                                ].join(' ')}
                                            >
                                                <p className={Classes.newRateExclGst}>
                                                    Rate Excl. GST
                                                </p>
                                                <div className={Classes.DiscountedUnitPrice}>
                                                    <p>&#8377;</p>
                                                    <p>
                                                        {item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={Classes.newCompareAtPrice}>
                                                        &#8377;{item.mrp}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}
                                                className={[
                                                    Classes.rateFontSize,
                                                ].join(' ')}
                                            >
                                                <p className={Classes.newRateExclGst}>
                                                    Rate Incl. GST
                                                </p>
                                                <div className={Classes.DiscountedUnitPrice}>
                                                    <p>&#8377;</p>

                                                    <p>
                                                        {item?.discountValue !== "0.00" ?
                                                            rateInclGST(item?.discountValue, item?.gstPercentage) :
                                                            rateInclGST(item.originalUnitPrice, item?.gstPercentage)}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={Classes.newCompareAtPrice}>
                                                        &#8377;{item.mrp}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={[
                                            Classes.percent,
                                            Classes.PercentBox,
                                            'percent'
                                        ].join(' ')}
                                    >
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <p>
                                                {handleDiscount(
                                                    item.mrp,
                                                    item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
                                                    item.gstPercentage === null ?
                                                        18 : item.gstPercentage
                                                )}
                                            </p>
                                            <p>%</p>
                                        </div>

                                        {item.discountTitle !== null && (
                                            <div
                                                className={[
                                                    Classes.hideDiscountTitle,
                                                    'hideDiscountTitle'
                                                ].join(' ')}
                                                style={{ margin: '0px auto' }}
                                            >
                                                {item.discountTitle}
                                            </div>
                                        )}

                                        {item.node?.appliedDiscount !== null && (
                                            <div
                                                className='exclGSTPrint'
                                                style={{
                                                    fontSize: '12px',
                                                    display: 'none'
                                                }}
                                            >
                                                <p>(Incl. GST)</p>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={[
                                            Classes.percent,
                                            Classes.Quantity,
                                            'qty'
                                        ].join(' ')}
                                        style={{marginLeft:'10px'}}
                                    >
                                        <p>{item.quantity}</p>
                                    </div>
                                    <div className={[
                                        Classes.percent,
                                        Classes.Quantity2,
                                        'qty'
                                    ].join(' ')}>
                                    </div>
                                    <div className={[
                                        Classes.percent,
                                        Classes.Quantity2,
                                        'qty'
                                    ].join(' ')}>
                                        &#8377;{formatNumber(item?.subTotal)}
                                    </div>

                                    <div
                                        className={[
                                            Classes.percent,
                                            'total'
                                        ].join(' ')}
                                        style={{
                                            padding: '10px 8px',
                                            width: '80px'
                                        }}
                                        id='discTotalPrint'
                                    >
                                        &#8377;{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div id='productContainer'>
                            {draftOrder?.OLineItems?.fulfilledLineItems?.map((item, index) => (
                                <div
                                    className={[
                                        Classes.containProduct,
                                        'variantProduct'
                                    ].join(' ')}
                                    id={item.oLineItemId}
                                    key={index}
                                >
                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>{draftOrder?.OLineItems?.unfulfilledLineItems?.length + (index+1)}. </div>
                                    <div
                                        className={[
                                            Classes.productDetail,
                                            'products'
                                        ].join(' ')}
                                        id={index}
                                    >
                                        <div>
                                            <img
                                                src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                alt="icon"
                                                className={Classes.productImage}
                                            />
                                        </div>

                                        <div className={Classes.VariantDetail}>
                                            <div>
                                                <p style={{ color: '#0d6efd' }}>
                                                    {item.productTitle}
                                                </p>
                                            </div>
                                            <div style={{ color: '#6d7175' }}>
                                                <p>{item.sku}</p>
                                            </div>
                                            <div style={{ display: 'flex' }}
                                                className={[
                                                    Classes.rateFontSize,
                                                ].join(' ')}
                                            >
                                                <p className={Classes.newRateExclGst}>
                                                    Rate Excl. GST
                                                </p>
                                                <div className={Classes.DiscountedUnitPrice}>
                                                    <p>&#8377;</p>
                                                    <p>
                                                        {item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={Classes.newCompareAtPrice}>
                                                        &#8377;{item.mrp}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}
                                                className={[
                                                    Classes.rateFontSize,
                                                ].join(' ')}
                                            >
                                                <p className={Classes.newRateExclGst}>
                                                    Rate Incl. GST
                                                </p>
                                                <div className={Classes.DiscountedUnitPrice}>
                                                    <p>&#8377;</p>

                                                    <p>
                                                        {item?.discountValue !== "0.00" ?
                                                            rateInclGST(item?.discountValue, item?.gstPercentage) :
                                                            rateInclGST(item.originalUnitPrice, item?.gstPercentage)}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={Classes.newCompareAtPrice}>
                                                        &#8377;{item.mrp}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={[
                                            Classes.percent,
                                            Classes.PercentBox,
                                            'percent'
                                        ].join(' ')}
                                    >
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <p>
                                                {handleDiscount(
                                                    item.mrp,
                                                    item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
                                                    item.gstPercentage === null ?
                                                        18 : item.gstPercentage
                                                )}
                                            </p>
                                            <p>%</p>
                                        </div>

                                        {item.discountTitle !== null && (
                                            <div
                                                className={[
                                                    Classes.hideDiscountTitle,
                                                    'hideDiscountTitle'
                                                ].join(' ')}
                                                style={{ margin: '0px auto' }}
                                            >
                                                {item.discountTitle}
                                            </div>
                                        )}

                                        {item.node?.appliedDiscount !== null && (
                                            <div
                                                className='exclGSTPrint'
                                                style={{
                                                    fontSize: '12px',
                                                    display: 'none'
                                                }}
                                            >
                                                <p>(Incl. GST)</p>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={[
                                            Classes.percent,
                                            Classes.Quantity,
                                            'qty'
                                        ].join(' ')}
                                        style={{marginLeft:'10px'}}
                                    >
                                        <p>{item.quantity}</p>
                                    </div>
                                    <div className={[
                                        Classes.percent,
                                        Classes.Quantity2,
                                        'qty'
                                    ].join(' ')}>
                                    </div>
                                    <div className={[
                                        Classes.percent,
                                        Classes.Quantity2,
                                        'qty'
                                    ].join(' ')}>
                                        &#8377;{formatNumber(item?.subTotal)}
                                    </div>

                                    <div
                                        className={[
                                            Classes.percent,
                                            'total'
                                        ].join(' ')}
                                        style={{
                                            padding: '10px 8px',
                                            width: '80px'
                                        }}
                                        id='discTotalPrint'
                                    >
                                        &#8377;{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ProductContainer