import React, { useEffect, useState} from "react";
import Permission from "../../../auth/permissions";
import Table from "../../../../components/table/table";
import { useSelector } from "react-redux";
import { setProductsRange } from "../../../../store/productRangeSlice";
import { Button,Typography,Alert,AlertTitle,Box } from "@mui/material";
import SnackBar from "../../../../components/SnackBar";
import { Add, Edit } from "@mui/icons-material";
import CreateModal from "./createProductRange";
import UpdateModal from "./editProductRange";
import FormDialog from "../../../../components/FormDialog/FormDialog";
import axiosInstance from "../../../../utility/axios-instance";
import Classes from "../settings.module.css";
import MediaPreview from "../../../../components/mediaPreview";


const ProductRangeSettings = () => {
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const productsRangeDetails = useSelector(
    (state) => state.productsRangeSettings,
  );
    const [imageDetail, setImageDetail] = useState({});
    const [imageId, setImageId] = useState("");
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [previewToggle, setPreviewToggle] = useState(false);
    const [mediaPreviewURL, setMediaPreviewURL] = useState("");
  const columns = [
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "imageURL",
      headerName: "Image",
      width: 90,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <img
          src={params?.row?.imageURL}
          alt="icon"
          className={Classes.Images}
          onClick={() => {
            setPreviewToggle(true)
            setMediaPreviewURL(params?.row?.imageURL);
          }
          }
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "updatedAt",
      headerName: "UPDATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "link",
      headerName: "LINK",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "active",
      headerName: "ACTIVE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: `edit`,
      headerName: `EDIT`,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="productRange" permission="update">
          <Button
            size="small"
            variant="contained"
            color="success"
            startIcon={<Edit />}
            onClick={() => handleUpdate(params.id)}
          ></Button>
        </Permission>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service="productRange" permission="delete">
          <FormDialog
            dialogTitle="Confirm Delete Image"
            dialogActionType="confirmation"
            openDialogButtonName="Delete"
            submitDialogButtonName="Confirm"
            onConfirmation={() => {
              handleDelete(params?.row?.id);
            }}
          >
            <Alert
              severity="warning"
              variant="outlined"
              sx={{
                borderRadius: 2,
                boxShadow: "none",
                bgcolor: "background.paper",
              }}
            >
              <AlertTitle>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="warning.main"
                >
                  Confirm Deletion
                </Typography>
              </AlertTitle>
              <Box mb={1}>
                <Typography variant="body1">
                  This action will permanently delete the image Titled:{" "}
                  <Typography
                    variant="body1"
                    component="span"
                    fontWeight="bold"
                  >
                    {params.row.title}
                  </Typography>{" "}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="warning.main"
              >
                Are you sure you want to continue?
              </Typography>
            </Alert>
          </FormDialog>
        </Permission>
      ),
    },
  ];

  const handleDelete = (imageId) => {
    axiosInstance
      .delete(`/adminRoutes/productRange/${imageId}`)
      .then((res) => {
        setReRender(!reRender);
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) =>
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        })
      );
  };

  const handleUpdate = (imageId) => {
    const imageDetail = productsRangeDetails.productsRangeDetails.find(
      (image) => image.id === imageId 
    );
    setImageDetail(imageDetail);
    setImageId(imageId);
    setShowUpdateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const handlePreviewClose = () => {
    setPreviewToggle(false);
  };
  return (
    <>
      <div style={{ display: "flex", margin: "1rem 0 1rem 0" }}>
        <Permission service="productRange" permission="create">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={() => setShowCreateModal(true)}
          >
            Add New
          </Button>
        </Permission>
      </div>
      <Permission service="productRange" permission="read">
        <Table
          columns={columns}
          dataPosition={"productsRangeDetails"}
          endpoint={`/adminRoutes/productRange`}
          rowId={"id"}
          checkBoxSelection={false}
          service={"productRange"}
          tabName={"Product Range"}
          setData={setProductsRange}
          data={productsRangeDetails}
          version={"version-1"}
          mobileResponsive={true}
          reRender={reRender}
        />
      </Permission>

      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      {showCreateModal ? (
        <CreateModal
          open={showCreateModal}
          handleClose={closeCreateModal}
          setReRender={setReRender}
        />
      ) : null}
      {showUpdateModal ? (
        <UpdateModal
          open={showUpdateModal}
          handleClose={closeUpdateModal}
          detail={imageDetail}
          setReRender={setReRender}
          imageId={imageId}
        />
      ) : null}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type="img"
          mediaURL={mediaPreviewURL}
        />
      )}
    </>
  );
};

export default ProductRangeSettings;
