import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import { useSelector } from "react-redux";

const Breadcrumb = () => {
  const location = useLocation();
  const productName = useSelector((state) => state.breadcrumb.product); // Access the product name from the store
  const pathnames = location.pathname.split("/").filter((x) => x);
  const isProductSection = pathnames.includes("products"); // Check if the current section is "products"
  const isDraftOrderSection = pathnames.includes("draftOrder"); // Check if the current section is "products"
  const isProductManagementSection = pathnames.includes("productManagement");
  const productManagementName = useSelector((state) => state.breadcrumb.productManagement);
  const draftOrderName = useSelector(
    (state) => state.breadcrumb.draftOrderName
  ); // Access the product name from the store
  const isUsersSection = pathnames.includes("users"); // Check if the current section is "users"
  const userName = useSelector((state) => state.breadcrumb.userName); // Access the product name from the store
  const isCustomerSection = pathnames.includes("customers"); // Check if the current section is "users"
  const customerName = useSelector((state) => state.breadcrumb.customerName);
  const isMyCustomerSection = pathnames.includes("myCustomers"); // Check if the current section is "users"
  const myCustomerName = useSelector(
    (state) => state.breadcrumb.myCustomerName
  ); // Access the myCustomerName from the store
  const isSalesReportSection = pathnames.includes("reports"); // Check if the current section is "users"
  const salesUserName = useSelector((state) => state.breadcrumb.salesUser);
  const isGroupIdSection = pathnames.includes("productsCreated");
  const groupId = useSelector((state) => state.breadcrumb.groupId);
  const groupLabels = {
    dashboard: "Dashboard",
    ordersNew: "Orders",
    draftOrder: "Draft Orders",
    purchase_orders: "Purchase Orders",
    products: "Products",
    productTransactions :"Product Transactions",
    addProduct: "Add Product",
    inventory: "Inventory",
    users: "Users",
    customers: "Customers",
    createOrder: "Create Order",
    createPurchaseOrder: "Create Purchase Order",
    draftPurchaseOrders: "Draft Purchase Orders",
    draftOrders: "Draft Orders",
    draftOrdersNew: "Draft Orders",
    purchaseOrders: "Purchase Orders",
    editPurchaseOrder: "Edit Purchase Order",
    inboundPurchaseOrders: "Inbound Purchase Orders",
    productsCreated: "Report Service",
    group: "Group",
    inventoryView: "Inventory View",
    inventoryMove: "Inventory Move",
    inventoryAdjustments: "Inventory Adjustments",
    bins: "Bins",
    blog: "Blog",
    affiliates: "Affiliates",
    fieldSales: "Field Sales",
    returnsNew: "Returns",
    cancelsNew: "Cancels",
    refundsNew: "Refunds",
    dispatch: "Dispatches",
    updatePricing: "Update Pricing",
    uploadImages: "Upload Images",
    uploadOthersMaster: "Upload Others Master",
    skuToQrcodeBarcode: "SKU to Qrcode/Barcode",
    myTasks: "My Tasks",
    myCustomers: "My Customers",
    reports: "Sales User",
    manager: "Manager",
    deliveryRiders: "Delivery Riders",
    user: "User",
    schemeDashboard: "Scheme Dashboard",
    schemes: "Schemes",
    orderAllocations: "Order Allocations",
    skuTransactions: "SKU Transactions",
    locations: "Locations",
    estimates: "Estimates",
    commissions: "Commissions",
    createCommission: "Create Commission",
    affiliateCommissions: "Affiliate Commissions",
    deliveryShipping: "Delivery Shipping",
    pickList: "Pick List",
    qc: "QC",
    pack: "Pack",
    onHold: "On Hold",
    inwardReturns: "Inward Returns",
    pushNotifications: "Push Notifications",
    inwardPurchaseOrders: "Inward Purchase Orders",
    inbound: "Inbound",
    putaway: "Putaway",
    createInterWHInventoryMoves: "Create Inter WH Inventory Moves",
    realtimeLocation: "Realtime Location",
    dynamicBanners: "Dynamic Banners",
    new: "New",
    roles: "Roles",
    draftInterWarehouseInventoryMoves: "Draft Inter Warehouse Inventory Moves",
    interWarehouseInventoryMoves: "Inter Warehouse Inventory Moves",
    franchise: "Franchise List",
    inventoryManagement: "Inventory Management",
    productManagement: "Product Management",
    // Add more group labels as needed
    settings: "Settings",
    discount: "Discount",
    createDiscount: "Create Discount",
    pincode: "Pincode",
    paymentGatewayTransactions: "Payment Gateway Transactions",
    company: "Company",
    newCompany: "New Company",
    website: "Website",
    productRange: "Product Range",
    newProductImage: "New Product Image",
    stores: "Stores",
    newStoreImage: "New Store Image",
    newStoreLocation: "New Store Location",
    editStoreLocation: "Edit Store Location",
    customer: "Customer",
    newCustomerVideo: "New Customer Video",
    mainBanners: "Main Banners",
    userActivities: "User Activities",
    images:"Images",
    ledgerDashboard: "Ledger Dashboard",
    customerAddresses: "Customer Billing Addresses",
    inventoryStockOut: "Inventory Stock Out",
    myNotifications: "My Notifications",
    lowInventoryTracker: "Low inventory Tracker",
    inventoriesWithPurchaseOrderDetails: "Insights",
    docsList: 'Docs List',
    inventoryAnalytics: 'Inventory Analytics',
    reportAnalytics: 'Report Analytics',
  };

  return (
    <Breadcrumbs
      id="breadCrumb"
      separator={<NavigateNext fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ marginBottom: "5px" }}
    >
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        let displayText;
        if (groupLabels[name]) {
          displayText = groupLabels[name]; // Use the custom label if available
        } else if (isLast && isProductSection) {
          displayText = productName;
        } else if (isLast && isDraftOrderSection) {
          displayText = draftOrderName;
        } else if (isLast && isUsersSection) {
          displayText = userName;
        } else if (isLast && isCustomerSection) {
          displayText = customerName;
        } else if (isLast && isMyCustomerSection) {
          displayText = myCustomerName;
        } else if (isLast && isSalesReportSection) {
          displayText = salesUserName;
        } else if (isLast && isGroupIdSection) {
          displayText = groupId;
        } else if (isLast && isProductManagementSection) {
          displayText = productManagementName;
        } else {
          displayText = name;
        }

        return isLast ? (
          <Typography
            style={{ fontSize: "13.5px" }}
            color="textPrimary"
            key={name}
          >
            {decodeURIComponent(displayText)}
          </Typography>
        ) : (
          <Link
            style={{ color: "#FF6200", fontSize: "13.5px" }}
            color="inherit"
            to={routeTo}
            key={name}
          >
            {decodeURIComponent(displayText)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
