import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import ContactDetails from '../../pages/home/createPurchaseOrder/aside/ContactDetails';
import RemoveGstPopup from '../../pages/home/createPurchaseOrder/aside/removeGstPopup';
import AsideModalLocations from '../../pages/home/createPurchaseOrder/aside/asideModalLocations';
import ShippingPopUp from '../../pages/home/createPurchaseOrder/aside/shippingAddressPopup';
import UpdateShippingPopUp from '../../pages/home/createPurchaseOrder/aside/updateShippingAddress';
import DefaultLocAddressPopUp from '../../pages/home/createPurchaseOrder/aside/defaultLocAddressPopup';
import GSTNoPopup from '../../pages/home/createPurchaseOrder/aside/newGstNo';
import DetailsPopUp from '../../pages/home/createPurchaseOrder/aside/contactDetailsPopUp';
import ContactPopUpLocation from '../../pages/home/createPurchaseOrder/aside/contactPopUpLocation';
import DefaultLocBillingAddressPopUp from '../../pages/home/createPurchaseOrder/aside/defaultLocBillingAddressPopup';
import UpdateBillingPopUp from '../../pages/home/createPurchaseOrder/aside/updateBillingAddress';

const LocationComponent = (props) => {

    const { 
        showLocationDetail,
        removeElementLocation,
        customerIDAddress,
        handleOutline,
        handleGstNumber,
        showContactPopLocation,
        locationGstNumber,
        locationAddressArray,
        locationIDAddress,
        showUpdateShippingLocPop,
        showDefaultAddressLocationPopUp,
        showShippingLocPop,
        locationBillingAddress,
        showUpdateBillingLocPop,
        showdefaultLocBillingAddressPop,
        showLocGstNoPopUp,
        showRemoveLocGstPopup,
        handleSearchLocation,
        showLocationModal,
        // aside locationModal
        showModalLocation,
        hideLocationModal,
        setLocationDetail,
        searchLocation,
        toggleButtonLocation,
        setToggleButtonLocation,
        setLocationIDAddress,
        setPreviousTarget,
        setLocationBillingAddress,
        toggleLocation,
        setToggleLocation,
        setLocationAddressArray,
        locationContacts,
        setLocationContacts,
        locationContactDetails,
        setLocationContactDetails,
        setLocationGstNumber,
        // contact PopUp Location
        contactModalLocation,
        setContactModalLocation,
        hideContactPopLocation,
        // shipping Dialog
        shippingLocModal,
        setShippingLocModal,
        hideShippingLocPop,
        pincode,
        setPincode,
        num,
        setNum,
        addressType,
        // update shippingDialog
        updateShippingLocModal,
        hideUpdateShippingLocPop,
        setUpdateShippingLocModal,
        updateExistOrder,
        // update BillingDialog
        updateBillingAddressLocModal,
        hideUpdateBillingLocPop,
        setUpdateBillingAddressLocModal,
        // default LocationAddressDialog
        defaultAddressLocationModal,
        hideDefaultAddressLocationPopUp,
        previousTarget,
        // default Billing Address Dialog
        defaultLocBillingAddressModal,
        hideDefaultLocBillingAddressPop,
        // newGST No
        newLocGstNo,
        setNewLocGstNo,
        hideLocGstNoPopUp,
        // remove GST No
        removeLocGstModal,
        setRemoveLocGstModal,
        hideRemoveLocGstPopup,
        gstNumber,
        setGstNumber,
        title,
        defaultLocation
    } = props;

    const capitalName = (text) => {
        const result1 = text.replaceAll('_', ' ');
        const arr = result1.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    const capitalizeState = (text) => {
        const arr = text.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    return (
        <Fragment>
            <div className={[Classes.Box, 'box'].join(' ')}>
                {showLocationDetail?.length !== 0 ? (
                    <div>
                        {showLocationDetail?.map((item, index) => (
                            <div key={index} id={item?._id}>
                                <div style={{ borderBottom:'1px solid #DBDDE0',padding:'1.1rem' }}>
                                    <div className={Classes.customerBox}>
                                        <h6 style={{ margin:'auto 0px' }}>{title}</h6>
                                        <div className='removeIcon'>
                                            <FontAwesomeIcon 
                                                icon={faXmark} className={Classes.xMarkIcon}
                                                onClick={() => removeElementLocation(item._id)}
                                            />
                                        </div>
                                    </div>

                                    <div className={Classes.customerDetailBox}>
                                        <p style={{ marginRight:5 }} className={Classes.customerName}>{item?.locationName}</p>

                                        {item?.status && (
                                            <p 
                                                className={Classes.customerRole}
                                                style={{ backgroundColor: item?.status === "Active"? '#00B87C':'#FF4D4D',color:'white' }}
                                            >
                                                {capitalName(item?.status)}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div style={{ borderBottom:'1px solid #DBDDE0',padding:'1.1rem' }}>
                                    <div style={{  display:'flex',justifyContent:'space-between',marginBottom:10 }}>
                                        <p className={Classes.contactInformation}>CONTACT INFORMATION</p>
                                        <p
                                            className={['removeEdit',Classes.contactEditBtn].join(' ')}
                                            onClick={(e) => showContactPopLocation(e)}
                                        >
                                            Edit
                                        </p>
                                    </div>

                                    <div style={{ color:'#2c6ecb',fontSize:14 }}>
                                        <p>{item.locationCode && item.locationCode}</p>
                                        <p>{locationGstNumber && locationGstNumber}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {locationAddressArray?.length !== 0 ? (
                            <div style={{ borderBottom: '1px solid #DBDDE0',padding:'1.1rem' }}>
                                <div style={{ display:'flex',justifyContent:'space-between',marginBottom:8 }}>
                                    <p className={Classes.contactInformation}>SHIPPING ADDRESS</p>
                                    {locationIDAddress?.length !== 0 && (
                                        <p
                                            className={['removeAddressOnPrint',Classes.contactEditBtn].join(' ')}
                                            id='Shipping'
                                            onClick={(e) => showUpdateShippingLocPop(e)}
                                        >
                                            Edit address
                                        </p>
                                    )}
                                </div>

                                {locationIDAddress?.length !== 0 ? (
                                    <div style={{ fontSize:14 }}>
                                        <div style={{ display:'flex' }}>
                                            <p style={{ marginRight:5 }}>{locationIDAddress[0]?.firstName}</p>
                                            <p>{locationIDAddress[0]?.lastName &&  locationIDAddress[0]?.lastName}</p>
                                        </div>

                                        <div style={{ display:'flex',flexDirection:'column' }}>
                                            <p style={{ marginRight:5 }}>{locationIDAddress[0]?.addressLine1}</p>
                                            <p>{locationIDAddress[0]?.addressLine2 !== null && locationIDAddress[0]?.addressLine2}</p>
                                        </div>

                                        <div style={{ display:'flex' }}>
                                            <p style={{ marginRight:5 }}>
                                                {locationIDAddress[0]?.pincode && locationIDAddress[0]?.pincode}
                                            </p>
                                            <p style={{ marginRight:5 }}>
                                                {locationIDAddress[0]?.city && locationIDAddress[0]?.city},
                                            </p>
                                            <p>{locationIDAddress[0]?.state && locationIDAddress[0]?.state}</p>
                                        </div>
                                        <p>{locationIDAddress[0]?.phoneNumber && locationIDAddress[0]?.phoneNumber}</p>
                                    </div>
                                ) : null}

                                <div className={['removeAddressOnPrint',Classes.selectAddressBtn].join(' ')}>
                                    <p onClick={showDefaultAddressLocationPopUp}>
                                        Select address
                                    </p>
                                    <p id='Shipping' onClick={(e) => showShippingLocPop(e)}>
                                        Add new address
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div style={{ borderBottom:'1px solid #DBDDE0',padding:'1.1rem' }}>
                                <p style={{ margin:'auto 0px 8px',fontSize:12,fontWeight:600 }}>
                                    SHIPPING ADDRESS
                                </p>
                                <p id='Shipping' className={Classes.contactEditBtn} onClick={(e) => showShippingLocPop(e)}>
                                    Please enter shipping address
                                </p>
                            </div>
                        )}

                        {locationAddressArray?.length !== 0 ? (
                            <div style={{ borderBottom: '1px solid #DBDDE0',padding:'1.1rem' }}>
                                <div style={{ display:'flex',justifyContent:'space-between',marginBottom:8 }}>
                                    <p className={Classes.contactInformation}>BILLING ADDRESS</p>
                                    {locationBillingAddress.length !== 0 && (
                                        <p
                                            className={['removeAddressOnPrint',Classes.contactEditBtn].join(' ')}
                                            onClick={(e) => showUpdateBillingLocPop(e)}
                                            id='Billing'
                                        >
                                            Edit Address
                                        </p>
                                    )}
                                </div>

                                {locationBillingAddress?.length !== 0 ? (
                                    <div style={{ fontSize:14 }}>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ marginRight:5 }}>{locationBillingAddress[0]?.firstName}</p>
                                            <p>{locationBillingAddress[0]?.lastName && locationBillingAddress[0]?.lastName}</p>
                                        </div>

                                        <div style={{ display:'flex',flexDirection:'column' }}>
                                            <p style={{ marginRight:5 }}>{locationBillingAddress[0]?.addressLine1}</p>
                                            <p>{locationBillingAddress[0]?.addressLine2 !== null && locationBillingAddress[0]?.addressLine2}</p>
                                        </div>

                                        <div style={{ display:'flex' }}>
                                            <p style={{ marginRight:5 }}>
                                                {locationBillingAddress[0]?.pincode && locationBillingAddress[0]?.pincode}
                                            </p>
                                            <p style={{ marginRight:5 }}>
                                                {locationBillingAddress[0]?.city && locationBillingAddress[0]?.city},
                                            </p>
                                            <p>
                                                {locationBillingAddress[0]?.state && capitalizeState(locationBillingAddress[0]?.state)},
                                            </p>
                                        </div>

                                        <p>{locationBillingAddress[0]?.phoneNumber && locationBillingAddress[0]?.phoneNumber}</p>
                                        <div className={Classes.selectAddressBtn}>
                                            <p className='removeAddressOnPrint' onClick={showdefaultLocBillingAddressPop}>
                                                Select Address
                                            </p>
                                            <p id='Billing' onClick={(e) => showShippingLocPop(e)}>
                                                Add new address
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={['removeAddressOnPrint',Classes.selectAddressBtn].join(' ')}>
                                        <p onClick={showdefaultLocBillingAddressPop}>Select address</p>
                                        <p id='Billing' onClick={(e) => showShippingLocPop(e)}>
                                            Add new address
                                        </p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{ borderBottom: '1px solid #DBDDE0',padding: '1.1rem' }}>
                                <p style={{ margin:'auto 0px 6px',fontSize:12,fontWeight:600 }}>
                                    BILLING ADDRESS
                                </p>

                                {customerIDAddress?.length !== 0 ? (
                                    <div>
                                        <p style={{ color: '#6c757d' }}>Same as shipping address</p>
                                        <p id='Billing' className={Classes.AddNewAddressBtn} onClick={(e) => showShippingLocPop(e)}>
                                            Add new address
                                        </p>
                                    </div>
                                ) : (
                                    <p id='Billing' className={Classes.contactEditBtn} onClick={(e) => showShippingLocPop(e)}>
                                        Please enter billing address
                                    </p>
                                )}
                            </div>
                        )}

                        {showLocationDetail?.length !== 0 && (
                            <div style={{ borderBottom:'1px solid #DBDDE0',padding:'1.1rem' }} id='removeSelectGst'>
                                <div className='d-flex justify-content-between mb-2'>
                                    <h6 style={{ margin:'auto 0px',fontSize:12,fontWeight:600 }}>
                                        SELECT GST NO:
                                    </h6>
                                    <p onClick={showLocGstNoPopUp} className={Classes.addNewGstNo}>
                                        Add New
                                    </p>
                                </div>
                                <div className={Classes.fontSize}>
                                    <select
                                        name='gst'
                                        defaultValue={'DEFAULT'}
                                        id='gstNo'
                                        className={Classes.select}
                                        onChange={(e) => handleGstNumber(e, "Location")}
                                        value={locationGstNumber}
                                    >
                                        <option value=''>Select GSTno</option>
                                        {showLocationDetail?.[0]?.locationGstNumber?.length !== 0 && 
                                            showLocationDetail?.[0]?.locationGstNumber?.map((gst, index) => (
                                            <option value={gst} key={index}>{gst}</option>
                                        ))}
                                    </select>
                                </div>

                                {showLocationDetail?.length !== 0 && showLocationDetail?.[0]?.locationGstNumber?.length !== 0 && (
                                    <p 
                                        onClick={showRemoveLocGstPopup} className={Classes.addNewGstNo} 
                                        style={{ marginTop:10,marginLeft:5 }}
                                    >
                                        Remove GST
                                    </p>
                                )}
                            </div>
                        )}

                        <div style={{ borderBottom: '1px solid #DBDDE0',padding:'1.1rem' }}>
                            <ContactDetails 
                                contacts={locationContacts} 
                                setContacts={setLocationContacts} 
                                selectedContact={locationContactDetails} 
                                setSelectedContact={setLocationContactDetails} 
                                type={"Location"} 
                                details={showLocationDetail} 
                            />
                        </div>
                    </div>
                ) : (
                    <div className={Classes.containerBox}>
                        <h6 style={{ margin:'auto 0px 12px',padding:6 }}>{title}</h6>

                        <div className={Classes.searchContainer}>
                            <div onClick={handleOutline} id='contactOutline' className={Classes.contactInputBox}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} className={Classes.contactSearchIcon} />
                                <div style={{ width: '100%' }}>
                                    <input
                                        type="text"
                                        id="search"
                                        className={Classes.searchField}
                                        placeholder="Search Locations"
                                        autoComplete='off'
                                        onChange={(e) => handleSearchLocation(e.target.value)}
                                        onClick={() => showLocationModal()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    <AsideModalLocations
                        showModal={showModalLocation}
                        onHideModal={hideLocationModal}
                        setDetail={setLocationDetail}
                        searchCustomer={searchLocation}
                        toggleButton={toggleButtonLocation}
                        setToggleButton={setToggleButtonLocation}
                        setCustomerIDAddress={setLocationIDAddress}
                        setPreviousTarget={setPreviousTarget}
                        setBillingAddress={setLocationBillingAddress}
                        toggle={toggleLocation}
                        setToggle={setToggleLocation}
                        setAddressArray={setLocationAddressArray}
                        locationContacts={locationContacts}
                        setLocationContacts={setLocationContacts}
                        locationContactDetails={locationContactDetails}
                        setLocationContactDetails={setLocationContactDetails}
                        setLocationGstNumber={setLocationGstNumber}
                        defaultLocation={defaultLocation}
                    />
                </div>
                <div className={contactModalLocation ? Classes.UpdateSalesDialog : undefined}>
                    <DetailsPopUp
                        contactModal={contactModalLocation}
                        setContactModal={setContactModalLocation}
                        showContactPop={showContactPopLocation}
                        hideContactPop={hideContactPopLocation}
                        showCustomerDetail={showLocationDetail}
                        setCustomerDetail={setLocationDetail}
                        locationContactDetails={locationContactDetails}
                    />
                </div>

                <div className={contactModalLocation ? Classes.UpdateSalesDialog : undefined}>
                    <ContactPopUpLocation
                        contactModal={contactModalLocation}
                        setContactModal={setContactModalLocation}
                        showContactPop={showContactPopLocation}
                        hideContactPop={hideContactPopLocation}
                        showCustomerDetail={showLocationDetail}
                        setCustomerDetail={setLocationDetail}
                        locationContactDetails={locationContactDetails}
                    />
                </div>

                <div className={shippingLocModal ? Classes.UpdateSalesDialog : undefined}>
                    <ShippingPopUp
                        shippingModal={shippingLocModal}
                        setShippingModal={setShippingLocModal}
                        showShippingPop={showShippingLocPop}
                        hideShippingPop={hideShippingLocPop}
                        pincode={pincode}
                        setPincode={setPincode}
                        num={num}
                        setNum={setNum}
                        showCustomerDetail={showLocationDetail}
                        setCustomerIDAddress={setLocationIDAddress}
                        addressArray={locationAddressArray}
                        setAddressArray={setLocationAddressArray}
                        setBillingAddress={setLocationBillingAddress}
                        addressType={addressType}
                        type={"Location"}
                    />
                </div>

                <div className={updateShippingLocModal ? Classes.UpdateSalesDialog : undefined}>
                    <UpdateShippingPopUp
                        hideUpdateShippingPop={hideUpdateShippingLocPop}
                        showUpdateShippingPop={showUpdateShippingLocPop}
                        updateShippingModal={updateShippingLocModal}
                        setUpdateShippingModal={setUpdateShippingLocModal}
                        showCustomerDetail={showLocationDetail}
                        setCustomerIDAddress={setLocationIDAddress}
                        updateExistOrder={updateExistOrder}
                        customerIDAddress={locationIDAddress}
                        addressType={addressType}
                        addressArray={locationAddressArray}
                        setAddressArray={setLocationAddressArray}
                        type={"Location"}
                    />
                </div>

                <div className={updateBillingAddressLocModal ? Classes.UpdateSalesDialog : undefined}>
                    <UpdateBillingPopUp
                        hideUpdateBillingPop={hideUpdateBillingLocPop}
                        showUpdateBillingPop={showUpdateBillingLocPop}
                        updateBillingModal={updateBillingAddressLocModal}
                        setUpdateBillingModal={setUpdateBillingAddressLocModal}
                        showCustomerDetail={showLocationDetail}
                        setBillingAddress={setLocationBillingAddress}
                        updateExistOrder={updateExistOrder}
                        BillingAddress={locationBillingAddress}
                        addressType={addressType}
                        addressArray={locationAddressArray}
                        setAddressArray={setLocationAddressArray}
                        type={"Location"}
                    />
                </div>

                <div className={defaultAddressLocationModal ? Classes.UpdateSalesDialog : undefined}>
                    <DefaultLocAddressPopUp
                        defaultAddressModal={defaultAddressLocationModal}
                        hideDefaultAddressPopUp={hideDefaultAddressLocationPopUp}
                        setCustomerIDAddress={setLocationIDAddress}
                        customerIDAddress={locationIDAddress}
                        showCustomerDetail={showLocationDetail}
                        addressArray={locationAddressArray}
                        previousTarget={previousTarget}
                        setPreviousTarget={setPreviousTarget}
                    />
                </div>

                <div className={defaultLocBillingAddressModal ? Classes.UpdateSalesDialog : undefined}>
                    <DefaultLocBillingAddressPopUp
                        defaultBillingAddressModal={defaultLocBillingAddressModal}
                        hideDefaultBillingAddressPop={hideDefaultLocBillingAddressPop}
                        setBillingAddress={setLocationBillingAddress}
                        showCustomerDetail={showLocationDetail}
                        showdefaultBillingAddressPop={showdefaultLocBillingAddressPop}
                        BillingAddress={locationBillingAddress}
                        addressArray={locationAddressArray}
                        previousTarget={previousTarget}
                        setPreviousTarget={setPreviousTarget}
                    />
                </div>

                <div className={newLocGstNo ? Classes.UpdateSalesDialog : undefined}>
                    <GSTNoPopup
                        newGstNo={newLocGstNo}
                        setNewGstNo={setNewLocGstNo}
                        showGstNoPopUp={showLocGstNoPopUp}
                        hideGstNoPopUp={hideLocGstNoPopUp}
                        showCustomerDetail={showLocationDetail}
                        setCustomerDetail={setLocationDetail}
                        type={"Location"}
                    />
                </div>

                <div className={removeLocGstModal ? Classes.UpdateSalesDialog : undefined}>
                    <RemoveGstPopup
                        removeGstModal={removeLocGstModal}
                        setRemoveGstModal={setRemoveLocGstModal}
                        showRemoveGstPopup={showRemoveLocGstPopup}
                        hideRemoveGstPopup={hideRemoveLocGstPopup}
                        showCustomerDetail={showLocationDetail}
                        setCustomerDetail={setLocationDetail}
                        gstNumber={gstNumber}
                        setGstNumber={setGstNumber}
                        type={"Location"}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default LocationComponent;