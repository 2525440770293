import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	orders: [],
};

const deliveryBoySlice = createSlice({
	name: 'deliveryBoy',
	initialState: initialState,
	reducers: {
		setOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setOrders, reset } = deliveryBoySlice.actions;

export default deliveryBoySlice.reducer;
