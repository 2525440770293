import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import { setOrders } from '../../../store/ordersSlice';
import resetState from '../../../store/actions';
import Order from './order';
import Spinner from '../../../components/spinner';
import Classes from './css/order.module.css';
import NewPagination from '../../../components/newPagination';
import CreatedByModal from './createdByFilter';


const Orders = (props) => {
	const orders = useSelector((state) => state.orders);
	const [text, setText] = useState('');
	const [createdBy, setCreatedBy] = useState([]);

	const dispatch = useDispatch();
	const ref = useRef();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [storeCustomerName, setCustomerName] = useState('');

	const { id } = useParams();

	const fetchOrders = async (type) => {
		setLoading(true);
		let usersQuery = createdBy.join(',');
		setError(null);
		const params = {
			text,
			createdBy: usersQuery,
		};

		const page = type;

		if (type) {
			if (type === 'next') {
				params.after = orders.pageInfo.endCursor;
			}
			if (type === 'prev') {
				params.before = orders.pageInfo.startCursor;
			}
		}

		const url = storeCustomerName?.length !== 0 ? `/orders/admin?page=${page}&createdBy=${createdBy[0]}` : id !== undefined ? `/orders/admin?users=${id}&page=${page}` : `/orders/admin?page=${page}`

		await axiosInstance
			.get(url, {
				params,
			})
			.then((response) => {
				dispatch(setOrders(response.data.data));
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					fetchOrders();
				}, 1000000);
			});
	};


	const handleUserSelect = async (selectedUsers) => {
		const admins = selectedUsers.map(user => user.name);
		let usersQuery = admins.join(',');
		const params = {
			createdBy: usersQuery,
			text: text,
		};
		setCreatedBy(admins);

		await axiosInstance
			.get('/orders/admin', {
				params,
			})
			.then((response) => {
				dispatch(setOrders(response.data.data));
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					fetchOrders();
				}, 1000000);
			});
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
		fetchOrders(page ? page : 1);
	}, []);

	const fetchOrders2 = async (e) => {
		setText(e.target.value);
		let usersQuery = createdBy.join(',');
		const url = `/orders/admin`
		const params = {
			text: e.target.value,
			createdBy: usersQuery,
		};
		await axiosInstance
			.get(url, {
				params,
			})
			.then((response) => {
				dispatch(setOrders(response.data.data));
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					// fetchOrders();
				}, 1000000);
			});
	}


	return (
		<Fragment>
			<div className="d-flex justify-content-between mb-5" style={{ width: "98%", margin: '0px auto' }}>
				<div className="px-3 py-1 border border-dark rounded">
					<p className="mb-0">
						<strong>Orders</strong>
					</p>
				</div>
				<button
					type="button"
					className="btn btn-outline-dark"
					onClick={() => {
						fetchOrders();
					}}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
					{loading ? 'Loading...' : 'ReLoad'}
				</button>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			<form style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '15px' }}>
				<div className={Classes.SearchDraftOrder}>
					<input
						type="text"
						id="search"
						style={{
							border: '1px solid rgb(206, 212, 218)',
							borderRadius: '0.25rem'
						}}
						className={Classes.searchField}
						autoComplete='off'
						placeholder="Search orders here"
						value={text}
						onChange={fetchOrders2}
					/>
				</div>

				<div className={Classes.CreatedByFilter}>
					<CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
				</div>
			</form>



			<div style={{ padding: '10px 10px 0px' }}>
				<div className={Classes.ordersHeader}>
					<p className={Classes.OrderName}>
						Order
					</p>

					<p className={Classes.CreatedAt}>
						Date
					</p>

					<p className={Classes.Customer}>
						Customer
					</p>

					<p className={Classes.Payment}>
						Payment Status
					</p>

					<p className={Classes.OrderStatus}>
						Order Status
					</p>

					<p className={Classes.CancelledAt}>
						Canceled At
					</p>

					<p className={Classes.OrderType}>
						Order Type
					</p>

					<p className={Classes.OrderType}>
						Created By
					</p>
				</div>

				{orders?.orders?.map((order) => (
					<Order key={order?._id} order={order} />
				))}

			</div>

			{orders?.orders?.length > 0 ? (
				<div>
					<NewPagination
						totalPage={orders}
						fetchData={fetchOrders}
					/>
				</div>
			) : null}

			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default Orders;
