import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedReturns: [],
};

const qcOrderSlice = createSlice({
	name: 'qcOrder',
	initialState: initialState,
	reducers: {
		setQCOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setQCOrders, reset } = qcOrderSlice.actions;

export default qcOrderSlice.reducer;