import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import axiosInstance from '../../utility/axios-instance';
import { InputAdornment, TextField, Autocomplete } from "@mui/material";
import Classes from '../../pages/home/product/css/product.module.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from "@mui/icons-material/Search";
import { 
    GridToolbarColumnsButton, GridToolbarContainer, 
    GridToolbarDensitySelector, GridToolbarExport, 
    GridToolbarFilterButton 
} from '@mui/x-data-grid';

export const BrandInputValue = (props) => {
    const { item, applyValue, focusElementRef } = props;
  
    const brandRef = useRef(null);
    useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            brandRef.current.focus();
        },
    }));
  
    const handleFilterChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
    };
  
    const fetchBrands = async () => {
        const query = {
            tableName: "product_collections",
            columnName: "brand_name",
        }
        await axiosInstance
            .get(`/product/filtersForUI`, {
                params: query,
            })
            .then((res) => {
                setBrands(res?.data?.data?.enumValues);
            });
    };
  
    const [brands, setBrands] = useState([]);
  
    useEffect(() => {
        fetchBrands();
    }, []);
  
    return (
        <Autocomplete
            id="brandName"
            value={item?.value || ''}
            onChange={handleFilterChange}
            options={brands || []}
            getOptionLabel={(brand) => brand && brand}
            disabled={item?.operator !== 'equal'}
            ref={brandRef}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="standard"
                    label="Brand Name"
                    InputLabelProps={{ shrink: true }} // Add this line
                />
            }
        />
    );
}
  
export const StatusInputValue = (props) => {
    const { item, applyValue, focusElementRef } = props;
  
    const statusRef = useRef(null);
    useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            statusRef.current.focus();
        },
    }));
  
    const handleFilterChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
    };
  
    return (
        <Autocomplete
            id="status"
            value={
                item?.value === true
                    ? 'Active'
                    : item?.value === false
                    ? 'Draft'
                    : item?.value === 'Active'
                        ? 'Active'
                        : item?.value === 'Draft'
                        ? 'Draft'
                        : ''
            }
            onChange={handleFilterChange}
            options={['Active', 'Draft']}
            disabled={item?.operator !== 'equal'}
            ref={statusRef}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="standard"
                    label="Status"
                    InputLabelProps={{ shrink: true }} // Add this line
                />
            }
        />
    );
}
  
export const TimeStampInputValue = (props) => {
    const { item, applyValue, focusElementRef } = props;
    const dateTimeRef = React.useRef(null);
    useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            dateTimeRef.current.focus();
        },
    }));
  
    const handleFilterChange = (newValue) => {
        applyValue({ ...item, value: newValue });
    };
  
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                ref={dateTimeRef}
                value={item?.value || ''}
                onChange={(event) => {
                    handleFilterChange(JSON.stringify(event))
                }}
                slotProps={{
                    textField: {
                    label: item?.field === 'createdAt' ? 'Created At' : 'Updated At',
                    size: 'small',
                    variant: 'standard',
                    InputLabelProps: { shrink: true },
                    sx: { "& .MuiInputBase-root": { top: "3px" }}}
                }}
            />
        </LocalizationProvider>
    );
}
  
export const SearchInput = ({ searchFor, handleSearch, width }) => {
    return (
        <div style={{ display: 'flex', }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ 
                    width: width ? width: '100%', 
                    "& label": { top: searchFor ? "0%" : "-18%" }, 
                    "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                    "& .MuiInputBase-input": {textAlign:'start !important'}
                }}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                    ),
                }}
            />
      </div>
    )
};
  
export const CustomToolbar = ({ searchFor, handleSearch, }) => {
    return (
        <GridToolbarContainer style={{ paddingBottom: '4px' }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <div
                className={Classes.SearchBox}
                style={{ border: 'none', marginLeft: 'auto' }}
            >
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />
            </div>
        </GridToolbarContainer>
    );
}


export const brandOnlyOperators = [
    {
      label: 'Equals',
      value: 'equal',
      InputComponent: BrandInputValue,
      getValueAsString: (value) => `${value}`,
    },
];

export const timestampOnlyOperators = [
    {
      label: 'Equals',
      value: 'equal',
      InputComponent: TimeStampInputValue,
      getValueAsString: (value) => `${value}`,
    },  
    {
      label: 'Greater Than',
      value: 'greaterThan',
      InputComponent: TimeStampInputValue,
      getValueAsString: (value) => `${value}`,
    },
    {
      label: 'Less Than',
      value: 'lessThan',
      InputComponent: TimeStampInputValue,
      getValueAsString: (value) => `${value}`,
    }
];

export const statusOnlyOperators = [
    {
      label: 'Equals',
      value: 'equal',
      InputComponent: StatusInputValue,
      getValueAsString: (value) => `${value}`,
    },
];

export const B2CAndB2BValue = (props) => {
    const { item, applyValue, focusElementRef } = props;
  
    const b2bAndB2cCRef = useRef(null);
    useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            b2bAndB2cCRef.current.focus();
        },
    }));
  
    const handleFilterChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
    };
  
    return (
        <>
            <Autocomplete
                id="status"
                value={
                    item?.value === true
                        ? 'Yes'
                        : item?.value === false
                        ? 'No': ''
                }
                onChange={handleFilterChange}
                options={['Yes', 'No']}
                disabled={item?.operator !== 'equal'}
                getOptionLabel={(brand) => brand && brand}
                ref={b2bAndB2cCRef}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="standard"
                        label="Status"
                        InputLabelProps={{ shrink: true }} // Add this line
                    />
                }
            />
        </>
    );
}

export const b2cAndB2BOperators = [
    {
      label: 'Equals',
      value: 'equal',
      InputComponent: B2CAndB2BValue,
      getValueAsString: (value) => value,
    },
];