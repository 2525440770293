import React, { Fragment, useEffect, useState } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';
import SnackBar from '../../../../components/SnackBar';
import Spinner from '../../../../components/spinner';

const ContactPopUp = (props) => {
    const [loading, setLoading] = useState(false);
    const [districts, setDistricts] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [companies, setCompanies] = useState([]);

    const {
        contactModal,
        hideContactPop,
        showCustomerDetail,
        setCustomerDetail,
        setReRender,
        reRender,
    } = props;

    const fetchAllCompanies = () => {
        axiosInstance
            .get(`/company/getAllCompanies`)
            .then((res) => {
                const companies = res?.data?.data?.companies;
                const companyNames = companies.map((company) => company?.companyName);
                setCompanies(companyNames);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Unable to fetch companies'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const fetchAllDistricts = () => {
        axiosInstance
            .get(`/pincode/getAllDistricts`)
            .then((res) => {
                const districts = res?.data?.data;
                setDistricts(districts);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Unable to fetch districts'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleSubmit = (event) => {
      setLoading(true);
      event.preventDefault();
    
      const data = {
        locationName: event?.target?.locationName?.value,
        status: event?.target?.locationStatus?.value
          ? event?.target?.locationStatus?.value
          : undefined,
        locationType: event?.target?.locationType?.value
          ? event?.target?.locationType?.value
          : undefined,
        district: event?.target?.district?.value
          ? event?.target?.district?.value
          : undefined,
        locationGstNumber: {
          add: [event?.target?.locationGstNumber?.value] || [],
        },
        default: event?.target?.default?.value === "true" ? true : false,
        companyName: event?.target?.companyName?.value
          ? event?.target?.companyName?.value
          : undefined,
      };
    
      axiosInstance
        .put(`/location/updateLocation/${showCustomerDetail[0]?.locationCode}`, {
          ...data,
        })
        .then((res) => {
          const successMessage = res?.data?.message
            ? res?.data?.message
            : "Location Updated Successfully";
          setLoading(false);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: successMessage,
              type: "success",
            };
          });
          hideContactPop(); // Close the popup
          if (setReRender) {
            setReRender(!reRender);
          }
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong";
          setLoading(false);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: errorMessage,
              type: "error",
            };
          });
        });
    };
    
    useEffect(() => {
        fetchAllDistricts();
        fetchAllCompanies();
    }, []);

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {contactModal && (
                <div className={[Classes.modal, Classes.contactPopUp].join(' ')} style={{ bottom: '13%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Location</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{ padding: '8px 20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='locationName'
                                        name='locationName'
                                        label='Location Name'
                                        placeholder='Enter Location Name'
                                        autoComplete='off'
                                        key={"showCustomerDetail[0]?.locationName"}
                                        defaultValue={showCustomerDetail[0]?.locationName}
                                        // value={showCustomerDetail[0]?.locationName}
                                        required={true}
                                        sx={{ width: '100%' }}
                                        size="small"
                                        onChange={(event) => {
                                            const value = event?.target?.value
                                            setCustomerDetail((prevCustomerDetail) => {
                                                return [{
                                                    ...prevCustomerDetail[0],
                                                    locationName: value,
                                                }]
                                            })
                                        }}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id={'locationStatus'}
                                        name={'locationStatus'}
                                        options={["Active", "In-Active", "Dis-Continued"]}
                                        key={showCustomerDetail[0]?.status}
                                        defaultValue={showCustomerDetail[0]?.status}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={'Location Status'} />
                                        }
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='locationCode'
                                        name='locationCode'
                                        label='Location Code'
                                        placeholder='Enter Location Code'
                                        autoComplete='off'
                                        key={showCustomerDetail[0]?.locationCode}
                                        defaultValue={showCustomerDetail[0]?.locationCode}
                                        disabled={true}
                                        sx={{ width: '100%' }}
                                        size="small"
                                        onChange={(event) => {
                                            const value = event?.target?.value
                                            setCustomerDetail((prevCustomerDetail) => {
                                                return [{
                                                    ...prevCustomerDetail[0],
                                                    locationCode: value,
                                                }]
                                            })
                                        }}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id={'locationType'}
                                        name={'locationType'}
                                        options={["WH", "Store","Head Office", "Office", "Franchise"]}
                                        key={showCustomerDetail[0]?.locationType}
                                        defaultValue={showCustomerDetail[0]?.locationType}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={'Location Type'} />
                                        }
                                        size="small"
                                    // value={showCustomerDetail[0]?.locationType}
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id={'district'}
                                        name={'district'}
                                        options={districts}
                                        key={showCustomerDetail[0]?.district}
                                        defaultValue={showCustomerDetail[0]?.district}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={'District'} required={true} />
                                        }
                                        size="small"
                                    // value={showCustomerDetail[0]?.district}
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='locationGstNumber'
                                        name='name'
                                        label='Gst Number'
                                        placeholder='Enter Gst Number'
                                        autoComplete='off'
                                        key={showCustomerDetail[0]?.locationGstNumber}
                                        defaultValue={showCustomerDetail[0]?.locationGstNumber}
                                        required={true}
                                        sx={{ width: '100%' }}
                                        minLength={15}
                                        maxLength={15}
                                        inputProps={{ maxLength: 15 }}
                                        size="small"
                                        onChange={(event) => {
                                            const value = event?.target?.value
                                            setCustomerDetail((prevCustomerDetail) => {
                                                return [{
                                                    ...prevCustomerDetail[0],
                                                    locationGstNumber: [value],
                                                }]
                                            })
                                        }}
                                    />
                                </div>

                            </div>
                                                  
                          
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id='default'
                                        name='default'          
                                        options={["true", "false"]}
                                        defaultValue={showCustomerDetail[0]?.default}
                                        key={showCustomerDetail[0]?.default}
                                        getOptionLabel={(option) => option || ""}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={'Default'} required={true} />}
                                        size="small"
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id='companyName'
                                        name='companyName'          
                                        options={companies}
                                        defaultValue={showCustomerDetail[0]?.companyName}
                                        getOptionLabel={(option) => option || ""}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={'Company Name'} required={true} />}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{ bottom: '13%' }}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button
                                id='addCustomer'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default ContactPopUp;