import Classes from './css/order.module.css';

const LineItem = (props) => {
	const { e } = props;

	const handleDiscount = (mrp,discountedPrice,value) => {
		const MRP = parseFloat(mrp);
		const DP = parseFloat(discountedPrice);
		const GST = parseInt(value);
		const result = (MRP - (DP * (1 + GST / 100))) / MRP;	
		const TotalDiscount = result * 100;
		const countDecimal = countPlaces(TotalDiscount);
		let results = countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? TotalDiscount : Math.trunc(TotalDiscount*100)/100;
		return results;
	};

	const handleInclGstTotal = (total,gst) => {
		const result = total*(1+(gst/100));
		const countDecimal = countPlaces(result)
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result*100)/100
	}

	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

	const rateInclGST = (amount,gst) => {
		const result = amount * (1+(gst/100));
		const countDecimal = countPlaces(result)
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result*100)/100
	}

	return (
		<div
			className={[Classes.VariantContainer,'variantProduct'].join(' ')}
			key={e?.id}
		>
			<div className={Classes.ProductContainer}>
				<div>
					<img
						src={
							e?.images && e?.images ? 
							e?.images :
							process.env.REACT_APP_IMAGE_COMMING_SOON
						}
						alt=""
						className={Classes.ProductImage}
					/>
				</div>

				<div className={Classes.ProductDetail}>
					<p style={{ color:'#0d6efd',marginBottom:0 }}>
						{e?.groupName}
					</p>

					<div style={{ color:'rgb(109, 113, 117)' }}>
						<p>
							{e?.variable1Value}{' '} 
							{e?.variable2Value && `/ ${e?.variable2Value}`}{' '}
							{e?.variable3Value && `/ ${e?.variable3Value}`}
						</p>
					</div>

					<p style={{ marginBottom:0,color:'rgb(109, 113, 117)' }}>
						SKU: {e?.sku}
					</p>

					<div style={{ display:'flex',marginBottom:0 }}>
						<p style={{ color:'rgb(109, 113, 117)' }}>
							Rate Excl. GST
						</p>

						<div className={Classes.DiscountedUnitPrice}>
							<p>{e?.appliedDiscount ? e?.appliedDiscount?.value: e?.listingPrice}</p>
						</div>

						<p className={Classes.lineItemMrp}>
							&#8377;{e?.mrp}
						</p>
					</div>

					<div style={{ display:'flex',marginBottom:0 }}>
						<p style={{ color:'rgb(109, 113, 117)' }}>
							Rate Incl. GST
						</p>

						<div className={Classes.DiscountedUnitPrice}>
							<p>&#8377;</p>
							<p>
								{e?.appliedDiscount ? 
								rateInclGST(e?.appliedDiscount?.value,e?.gst): 
								rateInclGST(e?.listingPrice,e?.gst)}
							</p>
						</div>

						<p className={Classes.lineItemMrp}>
							&#8377;{e?.mrp}
						</p>
					</div>

					<div className={Classes.ResponsiveBox}>
						<div style={{ display:'flex' }}>
							<p style={{color:'#6D7175'}}>Discount Incl. GST:</p>
							<div style={{ display:'flex' }}>
								<p style={{ marginLeft:10,marginBottom:0 }}>
									{handleDiscount(
										e?.mrp,
										e?.appliedDiscount ? 
										e?.appliedDiscount?.value :
										e?.listingPrice,
										e?.gst
									)}%
								</p>

								{e?.appliedDiscount ? (
									<p className={Classes.lineItemDiscountTitle}>
										{e?.appliedDiscount?.title}
									</p>
								): null}
							</div>
						</div>

						<div className={Classes.ResponsiveDiscount}>
							<p style={{color:'#6D7175'}}>Quantity:</p>
							<p style={{ marginLeft:10,marginBottom:0 }}>
								{e?.quantity}
							</p>
						</div>
						
						<div  style={{ display: 'flex'}}>
							<p style={{ color:'#6D7175' }}>Subtotal:</p>
							<p style={{ paddingLeft: 12 }}>&#8377;{e?.subTotal}</p>
						</div>

						<div  style={{ display: 'flex'}}>
							<p style={{ color:'#6D7175' }}>Total:</p>
							<p style={{ paddingLeft: 12 }}>
								&#8377;{handleInclGstTotal(e?.subTotal,e?.gst)}
							</p>
						</div>
					</div>
					
				</div>
			</div>

			<div className={Classes.discountPercent}>
				<p style={{ marginLeft:16,marginBottom:0 }}>
					{handleDiscount(
						e?.mrp,
						e?.appliedDiscount ? 
						e?.appliedDiscount?.value :
						e?.listingPrice,
						e?.gst
					)}%
				</p>

				{e?.appliedDiscount ?  (
					<p 
						className={Classes.lineItemDiscountTitle}
						style={{ marginLeft:0 }}
					>
						{e?.appliedDiscount?.title}
					</p>
				): null}
			</div>


			<div className={Classes.Qty}>
				<p className="mb-0">{e?.quantity}</p>
			</div>
			
			<div className={Classes.Total}>
				<p className="mb-0">&#8377;{e?.subTotal}</p>
			</div>

			<div className={Classes.ActualTotal}>
				<p className="mb-0">
					&#8377;{handleInclGstTotal(e?.subTotal,e?.gst)}
				</p>
			</div>
		</div>
	);
};

export default LineItem;
