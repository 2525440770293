import React from 'react';
import { ManageSearch, Search } from '@mui/icons-material';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import SnackBar from '../../../components/SnackBar';
import { Button, Spinner } from 'react-bootstrap';
import axiosInstance from '../../../utility/axios-instance';
import { useEffect } from 'react';
import CustomizedTables from './CustomizedTables';

const SmartProductSearch = (props) => {
    const { locationCode, productsAddedToCartRef, setProductDetails, setAddToggle } = props;
    const [open, setOpen] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })
    const [searchFor, setSearchFor] = React.useState("")
    const [options, setOptions] = React.useState([]);
    const [selectedL3, setSelectedL3] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const selectedProductsRef = React.useRef({});
    const isFirstRender = React.useRef(true);

    const handleSearch = async (e) => {
        const searchTerm = e.target.value;
        setSearchFor(searchTerm);
        setSelectedL3(null);
        setLoading(true);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            handleL3CategoriesSearch(searchFor); // Fetch data immediately without delay
            return;
        }
        const delay = 550;
        const debounce = setTimeout(() => {
            handleL3CategoriesSearch(searchFor);
        }, delay);
        return () => clearTimeout(debounce);
    }, [searchFor])


    const handleL3CategoriesSearch = async (searchTerm) => {
        const params = {
            searchTerm: searchTerm || '',
        };
        try {
            const res = await axiosInstance.get(`/smartProductSearch/fetchL3sForSmartSearch`, { params });
            const responseData = res?.data?.data;
            const fetchedL3s = responseData?.fetchedL3s;
            setOptions(fetchedL3s);
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
            });
        } finally {
            setLoading(false);
        }
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSearchFor("");
        setSelectedL3(null);
    };

    const handleAddToCart = () => {
        setOpen(false);
        const selectedProducts = Object.values(selectedProductsRef?.current);
        productsAddedToCartRef.current = selectedProducts;
        setAddToggle((prevToggle) => !prevToggle);
        setProductDetails(selectedProducts);
        setSearchFor("");
        setSelectedL3(null);
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: "Products added to cart successfully!", type: "success" };
        });
    }

    return (
        <>
            {loading && <Spinner />}
            <Tooltip title="Smart Product Search">
                <IconButton onClick={handleClickOpen}>
                    <ManageSearch />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullScreen
            >
                <DialogTitle>Smart Product Search</DialogTitle>
                <DialogContent>
                    <div style={{ width: '100%', marginTop: '10px', marginBottom: '10px', display: 'flex', gap: '10px' }}>
                        <TextField
                            id="search"
                            type="search"
                            label="Search"
                            value={searchFor}
                            autoFocus={true}
                            onChange={(e) => handleSearch(e)}
                            autoComplete='off'
                            sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Autocomplete
                            size='small'
                            disabled={options?.length > 0 ? false : true}
                            id="level3Categories"
                            required
                            options={options}
                            style={{ width: '50%' }}
                            onChange={(event, newValue) => {
                                setSelectedL3(newValue);
                            }}
                            value={selectedL3}
                            renderInput={(params) => {
                                return (
                                    <TextField required={true} {...params} label="Level 3 Categories" />
                                )
                            }}
                        />
                    </div>
                    <CustomizedTables
                        endpoint={`/smartProductSearch/fetchSmartSearchFilters`}
                        dataPosition="fetchedSmartSearchFilters"
                        queryParams={{
                            searchTerm: selectedL3 || '',
                        }}
                        locationCode={locationCode}
                        selectedProductsRef={selectedProductsRef}
                    />
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '2em' }}>
                    <Button onClick={handleAddToCart} style={{ marginLeft: 'auto', backgroundColor: '#243750', color: 'white', borderRadius: '5px', padding: '5px 10px', border: 'none', cursor: 'pointer' }}>Add to Cart</Button>
                    <Button onClick={handleClose} style={{ backgroundColor: '#243750', color: 'white', borderRadius: '5px', padding: '5px 10px', border: 'none', cursor: 'pointer' }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </>
    )
}

export default SmartProductSearch;