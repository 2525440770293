import React, { useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    DialogActions,
    Autocomplete
} from '@mui/material';

const CloseSoPopup = ({ isOpen, onClose, products, order, navigate, setLoading,setSnackBar, setReRender }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const generateUniqueKey = (product) => `${product.oLineItemId}${product.fulfillmentName ? product.fulfillmentName : ''}`;

    const quantity = (product) => {
        return product?.fulfillmentName ? product?.quantity : product?.openQty
    }

    const closeQty = (product) => {
        return product?.fulfillmentName ?  
            (product?.status === 'Confirmed' || 
            product?.status === 'Part Allocated' ||
            product?.status === 'Part Packed') ? "openQty" :
            product?.status === 'Allocated' ? "committedQty" : 
            product?.status === 'Picklist' ? "pickedQty" : 
            product?.status === 'Packed' ? "packedQty"  : ""
            : "openQty"
    }

    const handleSelectAll = (checked) => {
        if (checked) {
            // Select all products
            setSelectedProducts(products.map((product) => ({
                uniqueKey: generateUniqueKey(product),
                oLineItemId: product?.oLineItemId,
                fulfillmentName: product?.fulfillmentName,
                quantityType: closeQty(product),
                quantity: quantity(product),
            })));
        } else {
            // Deselect all products
            setSelectedProducts([]);
        }
        setSelectAll(checked);
    };

    const handleCheckboxChange = (product, checked) => {
        const uniqueKey = generateUniqueKey(product);

        if (checked) {
            // If the checkbox is checked, add the product to the selectedProducts array.
            setSelectedProducts((prevSelected) => [
                ...prevSelected,
                {
                    uniqueKey,
                    oLineItemId: product?.oLineItemId,
                    quantity: quantity(product),
                    fulfillmentName: product?.fulfillmentName,
                    quantityType: (
                        selectedProducts?.length !== 0 && 
                        selectedProducts.find(
                            (item) => item?.uniqueKey === generateUniqueKey(product)
                        )?.quantityType
                    ) ? 
                        selectedProducts.find(
                            (item) => item?.uniqueKey === generateUniqueKey(product)
                        )?.quantityType 
                    : closeQty(product)
                },
            ]);
        } else {
            // If the checkbox is unchecked, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item.uniqueKey !== uniqueKey)
            );
        }
    };

    const handleQuantityChange = (product, e) => {
        const newQuantity = parseInt(e.target.value);
        const uniqueKey = generateUniqueKey(product);

        if (newQuantity === 0) {
            // If the quantity is 0, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item?.uniqueKey !== uniqueKey)
            );
            return;
        }

        if (newQuantity > product?.quantity) {
            // If the quantity is greater than the openQty, set the quantity to the openQty quantity.
            alert(`The maximum Open Quantity for ${product?.sku} is ${product?.quantity}.`);
            return
        }


        setSelectedProducts((prevSelected) =>
            prevSelected?.map((item) =>
                item.uniqueKey === uniqueKey
                    ? { ...item, quantity: newQuantity }
                    : item
            )
        );
    };

    const handleCloseQty = (product, e) => {
        const quantityType = e?.target?.textContent;
        const uniqueKey = generateUniqueKey(product);

        let lineItemsList = selectedProducts;

        lineItemsList = lineItemsList.map(item => {
            if (item?.uniqueKey === uniqueKey) {
                item.quantityType = quantityType;
            }
            return item;
        });

        setSelectedProducts(lineItemsList)
    };


    const handleCloseSo = async () => {
        setLoading(true)
        if (selectedProducts.length <= 0) {
            setLoading(false)
            return
        }

        let data = selectedProducts?.map((product) => ({
            oLineItemId: product?.oLineItemId,
            quantity: product?.quantity,
            fulfillmentName: product?.fulfillmentName,
            quantityType: product?.quantityType
        }))

        await axiosInstance
            .put(`/pgOrder/admin/closeSo/${order?.orderName}`, data)
            .then((response) => {
                // navigate('/ordersNew')
                const successMessage = response?.data?.message ? response?.data?.message : `So with order name: ${order?.orderName} Closed Successfully!`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })

        // reset all state values and close
        setLoading(false)
        hidePopup();
        setReRender((prev) => {
            return !prev
        })
    };

    const hidePopup = () => {
        setSelectedProducts([]);
        setSelectAll(false);
        onClose();
    };

    return (

        <Dialog open={isOpen} onClose={onClose} fullWidth={true}>
            <DialogTitle>Close Sales Order</DialogTitle>
            {(
                (products?.length > 0)
                && ((
                        products?.filter((product) => (quantity(product) > 0))
                    )?.length > 0)
            ) ? (
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectAll}
                                onChange={(e) => handleSelectAll(e.target.checked)}
                            />
                        }
                        label="Select All"
                    />
                    {/* only map those products which are not returned */}
                    {products?.length && products?.filter((prod) => quantity(prod) > 0)?.map((product) => (
                        <div
                            key={generateUniqueKey(product)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '15px 0px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedProducts.some(
                                            (item) => item.uniqueKey === generateUniqueKey(product)
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(product, e.target.checked)
                                        }
                                    />
                                }
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <img
                                            src={product?.mainImageUrl ? product?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                            alt={product?.productTitle}
                                            width="55"
                                            height="55"
                                            style={{ marginRight: '10px', borderRadius: '5px', border: '0.5px solid gray' }}
                                        />
                                        <div>
                                            <p style={{ fontWeight: 600 }}>{product.sku}</p>
                                            <p style={{ fontSize: '12px' }}>Quantity: {quantity(product)}
                                            </p>
                                            {product?.fulfillmentName && <p style={{ fontSize: '12px' }}>FulfillmentName: {product?.fulfillmentName}</p>}
                                        </div>
                                    </div>
                                }
                            />
                            {selectedProducts.some(
                                (item) => item.uniqueKey === generateUniqueKey(product)
                            ) && (
                                    <div style={{ display: 'flex', gap: '5px', justifyContent: 'end' }}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id={'closeQty'}
                                            name={'closeQty'}
                                            disabled
                                            options={["openQty","committedQty","pickedQty","packedQty"]}
                                            sx={{width: '100%'}}
                                            value={(
                                                selectedProducts?.length !== 0 && 
                                                selectedProducts.find(
                                                    (item) => item?.uniqueKey === generateUniqueKey(product)
                                                )?.quantityType
                                            ) ? 
                                                selectedProducts.find(
                                                    (item) => item?.uniqueKey === generateUniqueKey(product)
                                                )?.quantityType 
                                            : 
                                                closeQty(product)
                                            }
                                            renderInput={(params) => 
                                                <TextField {...params} label='Close Qty' required />
                                            }
                                            size={"small"}
                                            onChange={(e) => {
                                                handleCloseQty(product, e);
                                            }}
                                        />

                                        <TextField
                                            label="Quantity"
                                            type="number"
                                            defaultValue={
                                                (selectedProducts?.length !== 0 && 
                                                selectedProducts.find(
                                                    (item) => item?.uniqueKey === generateUniqueKey(product)
                                                )?.openQty) 
                                            ? 
                                                selectedProducts.find(
                                                    (item) => item?.uniqueKey === generateUniqueKey(product)
                                                )?.openQty
                                            : quantity(product)}
                                            onChange={(e) => { handleQuantityChange(product, e)}}
                                            style={{ marginLeft: '10px' }}
                                            size={"small"}
                                        />
                                    </div>
                                )}
                        </div>
                    ))}

                </DialogContent>
            ) : (
                <DialogContent>
                    <p>No products to close.</p>
                </DialogContent>
            )}
            <DialogActions>
                {products?.length > 0 && (
                    <Button variant="contained" color="primary" onClick={handleCloseSo}>
                        Confirm
                    </Button>
                )}
                <Button variant="outlined" color="primary" onClick={hidePopup}>
                    Cancel
                </Button>
            </DialogActions>


        </Dialog>
    );
};

export default CloseSoPopup;
