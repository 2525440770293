import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import axios from '../../utility/axios-instance';

import resetState from '../../store/actions';

const Profile = () => {
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const updatePassword = (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);
		setSuccess(null);

		const { oldPassword, newPassword, confirmPassword } = e.target.elements;

		if (newPassword.value !== confirmPassword.value) {
			setError('Passwords do not match');
			setLoading(false);
			return;
		}

		axios
			.post('/admin/auth/changePassword', {
				oldPassword: oldPassword.value,
				newPassword: newPassword.value,
				confirmPassword: confirmPassword.value,
			})
			.then((res) => {
				setSuccess(res.data.message);
				e.target.reset();
			})
			.catch((err) => {
				setError(err.response.data.message);
				if (err.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div
			className="d-flex flex-column w-100 h-100 p-5"
			style={{ gridGap: '5rem' }}
		>
			<form
				className="d-flex flex-column justify-content-between"
				style={{ gridGap: '1rem', maxWidth: '500px' }}
			>
				<div className="form-group">
					<label htmlFor="userName">Username</label>
					<input
						type="text"
						className="form-control"
						id="userName"
						name="userName"
						defaultValue={auth.user.username}
						placeholder="UserName"
						disabled={true}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="name">Name</label>
					<input
						type="text"
						className="form-control"
						id="name"
						name="name"
						defaultValue={auth.user.name}
						placeholder="Name"
						disabled={true}
					/>
				</div>
				<div className="form-check form-switch">
					<input
						className="form-check-input"
						type="checkbox"
						name="active"
						id="flexSwitchCheckChecked"
						defaultChecked={auth.user.active}
						disabled={true}
					/>
					<label className="form-check-label" htmlFor="flexSwitchCheckChecked">
						Active
					</label>
				</div>
				<div className="form-group">
					<label htmlFor="role">Role</label>
					<select
						className="form-select"
						id="role"
						name="role"
						defaultValue={auth.user.role}
						disabled={true}
					>
						<option value="" disabled>
							Choose Role
						</option>
						<option value="super-admin">Super Admin</option>
						<option value="admin">Admin</option>
						<option value="pi">PI</option>
						<option value='piManager'>PI Manager</option>
						<option value="operations">Operations</option>
						<option value="accounts">Accounts</option>
						<option value="logistics">Logistics</option>
						<option value="catalogue">Catalogue</option>
						<option value='catalogueManager'>Catalogue Manager</option>
						<option value="photography">Photography</option>
						{/* exhibition */}
						<option value="exhibition">Exhibition</option>

					</select>
				</div>
			</form>

			<form
				className="d-flex flex-column justify-content-between"
				style={{ gridGap: '1rem', maxWidth: '500px' }}
				onSubmit={updatePassword}
			>
				<h5>Change Password</h5>
				{error ? (
					<Alert variant="danger" onClose={() => setError(null)} dismissible>
						<Alert.Heading>Error!</Alert.Heading>
						<p>{error}</p>
					</Alert>
				) : null}
				{success ? (
					<Alert variant="success" onClose={() => setSuccess(null)} dismissible>
						<Alert.Heading>Success!</Alert.Heading>
						<p>{success}</p>
					</Alert>
				) : null}
				<div className="form-group">
					<label htmlFor="oldPassword">Old Password</label>
					<input
						type="password"
						className="form-control"
						id="oldPassword"
						name="oldPassword"
						placeholder="Old Password"
						minLength={6}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="newPassword">New Password</label>
					<input
						type="password"
						className="form-control"
						id="newPassword"
						name="newPassword"
						placeholder="New Password"
						minLength={6}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="confirmPassword">Confirm Password</label>
					<input
						type="password"
						className="form-control"
						id="confirmPassword"
						name="confirmPassword"
						placeholder="Confirm Password"
						required
					/>
				</div>
				<button
					className="btn btn-light"
					type="submit"
					style={{ backgroundColor: '#ff6200', color: '#fff' }}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : (
						'Update Password'
					)}
				</button>
			</form>
		</div>
	);
};

export default Profile;
