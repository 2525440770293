import { Fragment } from 'react';
import Classes from './css/orders.module.css';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const OtherDetails = (props) => {
    const { draftOrder } = props;

    return (
        <Fragment>
            <div style={{width:'100%',marginBottom:20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Other Details</p>
                    <div style={{display:'flex',padding:'15px',flexWrap:'wrap',width:'100%'}}>
                        <div style={{marginBottom:15}} className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Locations:</p>
                            {draftOrder?.orderLocationList?.slice(0,2)?.map((locationData, index) => (
                                <Chip key={index} label={`${locationData.locationName} (${locationData.locationCode})`} style={{ margin: '3px' }} />
                            ))}
                            {draftOrder?.orderLocationList?.length > 2 ? (
                                <Tooltip title={
                                    <div>
                                        {draftOrder?.orderLocationList?.slice(2)?.map((locationData, index) => (
                                            <p>{locationData.locationName}{" "}({locationData.locationCode})</p>
                                        ))}
                                    </div>
                                } arrow>
                                <Chip size="small" label={`+ ${draftOrder?.orderLocationList?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                                </Tooltip>
                            ) : null}
                           
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{ fontWeight: 500, color: '#6d7175', fontSize: 14 }}>Platform:</p>
                            <p>{draftOrder?.createdVia || 'N/A'}</p>
                        </div>

                        <div style={{marginBottom:15}} className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Delivery Remark:</p>
                            <p>{draftOrder?.deliveryRemark || 'N/A'}</p>
                        </div>

                        <div style={{marginBottom:15}} className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Payment Remark:</p>
                            <p>{draftOrder?.paymentRemark || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Affiliate User:</p>
                            <p>{draftOrder?.OAffiliateUser?.name || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Sales User:</p>
                            <p>{draftOrder?.OSalesUser?.name || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Pre-Sales User:</p>
                            <p>{draftOrder?.OPreSalesUser?.name || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14,marginTop:10}}>Tags:</p>
                            <p>{(draftOrder?.tags?.length !== 0 && draftOrder?.tags?.[0]) ?? 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14,marginTop:10}}>Invoice Type:</p>
                            <p>{(draftOrder?.invoiceType !== 0 && draftOrder?.invoiceType) ?? 'N/A'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default OtherDetails;