import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Divider, Collapse, Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import React, { useEffect, useRef } from 'react';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import Permission from '../../pages/auth/permissions';

import './index.css';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useState } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { setPinTab } from '../../store/pinTabSlice';
import SnackBar from '../SnackBar';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], handleNavigation, handleHover, isNavCollapse,...other }) {
  const [openList, setOpenList] = React.useState({});
  const {isNavSectionScroll , setIsNavSectionScroll} = other;

  const listRef = useRef(null);
  const [showExpandIcon, setShowExpandIcon] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleScroll = () => {
    const scrollHeight = listRef.current.scrollHeight;
    const scrollTop = listRef.current.scrollTop;
    const clientHeight = listRef.current.clientHeight;

    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10; // Adjust the threshold as needed
    setIsAtBottom(isAtBottom);
    setShowExpandIcon(scrollHeight > clientHeight);
  };

  useEffect(() => {
    const listElement = listRef.current;
    listElement.addEventListener('scroll', handleScroll);
    return () => {
      listElement.removeEventListener('scroll', handleScroll);
    };
  }, [])



  const updateOpenList = () => {
    let newOpenList = {};
    data?.forEach(item => {
      if (item?.childs?.length) {
        newOpenList[item.serviceName] = false;
      }
    });
    setOpenList(newOpenList);
  }

  useEffect(() => {
    updateOpenList();
  }, [])

  function handleClick(serviceName) {
    let newOpenList = {
      ...openList
    };
    newOpenList[serviceName] = !newOpenList[serviceName];
    setOpenList(newOpenList);
  }

  return (
    <>
    <Box {...other}>
      <List
        disablePadding
        sx={{
          p : 1,
          maxHeight: '78vh',
          overflowY: 'hidden',
          overflowX: 'hidden',
          '&:hover': {
            overflowY: 'auto',
          },
          '&::-webkit-scrollbar': {
            width: isNavCollapse ?'3px' : '5px',
            opacity: isNavCollapse ? 0 : .5,
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#D3D3D3',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
          },
        }}
        ref={listRef}
      >
        {data.map((item, index) => (
          item?.childs?.length ? (
            <div key={item.title + index} style={{width: "225px"}}>
              <Permission service={item?.serviceName} permission={item?.permission}>
                <div onClick={() => handleClick(item.serviceName)}>
                  <DropdownItem handleHover={handleHover} style={{ cursor: 'pointer' }} item={item} open={openList[item.serviceName]} isNavCollapse={isNavCollapse} />
                </div>
                <Collapse in={openList[item.serviceName]} timeout="auto" unmountOnExit>
                  <Divider />
                  {!isNavCollapse && (
                    <List className='child-nav-section' component="div" disablePadding>
                      {item?.childs?.map((childItem, index) => (
                        <div key={childItem.title + index} style={{ marginLeft: '2px' }}>
                          <Permission service={childItem?.serviceName} permission={childItem?.permission}>
                            <NavItem
                              item={childItem}
                              handleNavigation={handleNavigation}
                              handleHover={handleHover}
                              isNavSectionScroll ={isNavSectionScroll }
                            />
                          </Permission>
                        </div>
                      ))}
                    </List>
                  )}
                  <Divider />
                </Collapse>
              </Permission>
            </div>
          ) : (
            <Permission key={item.title} service={item?.serviceName} permission={item?.permission}>
              <NavItem
                item={item}
                handleNavigation={handleNavigation}
                isNavCollapse={isNavCollapse}
                handleHover={handleHover}
              />
            </Permission>
          )
        ))}
      </List>
      {showExpandIcon && (
          <div style={{ display: 'flex', justifyContent: 'center',margin:"3px 0px 4px 0px"}}>
            {isAtBottom ? <img src="/assets/up-arrow.png" alt='' width={20} /> : <img src="/assets/down-arrow.png" alt='' width={20} />}
          </div>
        )}
    </Box>
  </>  
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

const handlePinTab = (e,tabName,path,dispatch, pinTab,setSnackBar) => {
  e.preventDefault();
  e.stopPropagation();
  const multiTabName = window.location.pathname?.split('/')?.[2] ? window.location.pathname?.split('/')?.[2]: null;

  const checkTab = Object.keys(pinTab)?.some((key) => pinTab[key]?.name === null);
  if (!checkTab) {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: "You can pin only 4 tabs", type: "error" }
    });
  } else {
    for (const key in pinTab) {
      if(!pinTab[key]?.name) {
        dispatch(setPinTab({ ...pinTab, [key]:{ name: tabName, url:path, multiTabName:multiTabName }  }));
        return;
      }
    }
  }
}

const handleRemovePinnedTab = (e,tabName,dispatch, pinTab) => {
  e.preventDefault();
  e.stopPropagation();

  for (const key in pinTab) {
    if(pinTab[key]?.name === tabName) {
      dispatch(setPinTab({ ...pinTab, [key]:{ name: null, url:null, multiTabName:null }  }));
      return;
    }
  }
}

function NavItem({ item, handleNavigation, handleHover, isNavCollapse  }) {
  const { title, path, icon, info } = item;
  const pinTab = useSelector((state) => state.pinTab);
  const dispatch = useDispatch();
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

  return (
    <div style={{display:'flex', width:"225px"}} className='Item'>
      <StyledNavItem
        component={RouterLink}
        onClick={(e) => handleNavigation(e, path)}
        to={path}
        onMouseEnter={(event) => handleHover(event, item, false)}
        onMouseLeave={(event) => handleHover(event, item, false)}
        sx={{
          '&.active': {
            color: "text.primary",
            bgcolor: 'action.selected',
            fontWeight: "550"
          },
          '&:hover': {
            color: "rgb(255, 98, 0)"
          }
        }} 
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        {!isNavCollapse && <ListItemText disableTypography primary={title} />}

        {info && info}

        {Object.keys(pinTab)?.some((key) => pinTab[key]?.name === title) ? (
          <div style={{margin:'auto 10px',cursor:'pointer'}} className='Icon'> 
            <img 
              src="https://www.svgrepo.com/show/436850/pin-slash-fill.svg"  
              alt="" srcset="" width='18px' height='16px'
              onClick={(e) => handleRemovePinnedTab(e,title,dispatch,pinTab)}
            />
          </div>
        ) : (
          <PushPinIcon 
            style={{fontSize:18,margin:'auto 10px',cursor:'pointer'}} 
            onClick={(e) => handlePinTab(e,title,path,dispatch,pinTab,setSnackBar)}
          />
        )}
      </StyledNavItem>
      
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </div>
  );
}

function DropdownItem({ item, open, handleHover, isNavCollapse }) {
  const { title, icon } = item;

  return (
    <StyledNavItem
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: '550',
        },
        '&:hover' : {
          color: 'rgb(255, 98, 0)'
        }
      }}
      onMouseEnter={(event) => handleHover(event, item, true)}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      {
        !isNavCollapse &&
        <ListItemText disableTypography primary={title} />
      }
      {!isNavCollapse && (
        <>
          {open ? (
            <div style={{marginRight: '5px'}}>
              <ExpandLess />
            </div>
          ) : (
            <div style={{marginRight: '5px'}}>
              <ExpandMore />
            </div>
          )}
        </>
      )}
    </StyledNavItem>
  );
}