import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Classes from "../pages/home/home.module.scss";
import Permission from "../pages/auth/permissions";

export const Navbar = ({ auth, isCreateOrderPage, setIsCreateOrderPage }) => {

  const checkPermission = (targetService) => {
    const services = auth?.user?.services
    const role = auth?.user?.role

    const constraint1 = services.some((service) => {
      return service?.service === targetService
    })
    const constraint2 = services.some((service) => {
      if (targetService === 'createOrder') {
        return service?.permissions?.includes('create');
      }
      return service?.permissions?.length > 0
    })
    const constraint3 = ['super-admin', 'admin'].includes(role)
    return (constraint1 && constraint2) || constraint3
  }

  const navigate = useNavigate();
  const handleNavigation = (event, destination) => {
    if (isCreateOrderPage) {
      event.preventDefault();
      const confirmNavigation = window.confirm(
        "Do you want to quit this page?"
      );
      if (confirmNavigation) {
        setIsCreateOrderPage(false);
        navigate(destination);
        // history.push(destination);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleNavigation);

    return () => {
      window.removeEventListener("beforeunload", handleNavigation);
    };
  }, [isCreateOrderPage]);

  const navData = [
    {
      serviceName: "users",
      permission:"read",
      toPath: "users",
      title: "Users"
    },
    // {
    //   serviceName: "deliveryBoy",
    //   permission:"read",
    //   toPath: "deliveryBoy",
    //   title: "Delivery Boy"
    // },
    {
      serviceName: "customers",
      permission:"read",
      toPath: "customers",
      title: "Customers"
    },
    {
      serviceName: "products",
      permission:"read",
      toPath: "products",
      title: "Products"
    },
    {
      serviceName: "products",
      permission:"read",
      toPath: "productTransactions",
      title: "Products Transaction"
    },
    {
      serviceName: "updatePricing",
      permission:"read",
      toPath: "updatePricing",
      title: "Update Pricing"
    },
    {
      serviceName: "uploadImages",
      permission:"read",
      toPath: "uploadImages",
      title: "Upload Images"
    },
    {
      serviceName: "uploadOtherMaster",
      toPath: "uploadOthersMaster",
      permission: "create",
      title: "Upload Others Master",
    },
    {
      serviceName: "skuToQRC",
      permission:"read",
      toPath: "skuToQrcodeBarcode",
      title: "Sku To QRC"
    },
    {
      serviceName: "createOrder",
      permission:"create",
      toPath: "createOrder",
      title: "Create Order"
    },
    {
      serviceName: "draftOrder",
      permission:"read",
      toPath: "draftOrdersNew",
      title: "Draft Order"
    },
    {
      serviceName: "orders",
      permission:"read",
      toPath: "ordersNew",
      title: "Orders"
    },
    {
      serviceName: "orders",
      toPath: "docsList",
      permission: "read",
      title: "Docs List",
    },
    {
      serviceName: "deliveryShipping",
      toPath: "deliveryShipping",
      permission: "read",
      title: "Delivery Shipping",
    },
    {
      serviceName: "deliveryRiders",
      toPath: "deliveryRiders",
      permission: "read",
      title: "Delivery Riders"
    },
    {
      serviceName: "returnOrder",
      toPath: "returnsNew",
      permission: "read",
      title: "Returns"
    },
    {
      serviceName: "inwardReturns",
      toPath: "inwardReturns",
      permission: "read",
      title: "Inward Returns"
    },
    {
      serviceName: "pushNotifications",
      toPath: "pushNotifications",
      permission: "read",
      title: "Push Notifications"
    },
    {
      serviceName: "cancelOrder",
      toPath: "cancelsNew",
      permission: "read",
      title: "Cancels"
    },
    {
      serviceName: "refundOrder",
      toPath: "refundsNew",
      permission: "read",
      title: "Refunds"
    },
    {
      serviceName: "createPurchaseOrder",
      toPath: "createPurchaseOrder",
      title: "Create P.O"
    },
    {
      serviceName: "draftPurchaseOrders",
      permission:"read",
      toPath: "draftPurchaseOrders",
      title: "Draft P.O"
    },
    {
      serviceName: "purchaseOrders",
      permission:"read",
      toPath: "purchaseOrders",
      title: "Purchase Orders"
    },
    {
      serviceName: "inwardPurchaseOrders",
      toPath: "inwardPurchaseOrders",
      permission: "read",
      title: "Inward Po"
    },
    {
      serviceName: "inventory",
      toPath: "inventoryStockOut",
      permission: "read",
      title: "Inventory Stock Out"
    },
    {
      serviceName: "scheme",
      toPath: "schemeDashboard",
      permission: "read",
      title: "Scheme Dashboard"
    },
    {
      serviceName: "scheme",
      toPath: "schemes",
      permission: "read",
      title: "Schemes"
    },
    {
      serviceName: "salesManager",
      permission:"create",
      toPath: "manager",
      title: "Manager"
    },
    {
      serviceName: "fieldSales",
      permission:"create",
      toPath: "myTasks",
      title: "My Tasks"
    },
    {
      serviceName: "fieldSales",
      permission:"create",
      toPath: "myCustomers",
      title: "My Customers"
    },
    {
      serviceName: "fieldSales",
      permission:"create",
      toPath: "reports",
      title: "Report Dashboard"
    },
    {
        serviceName: "RealtimeLocation",
        permission: "read",
        path: "realtimeLocation",
        title: "Agent Location",
      },
    // {
    //   serviceName: "orderDispatches",
    //   toPath: "dispatch",
    //   title: "Dispatch"
    // },
    {
      serviceName: "inboundPurchaseOrders",
      permission:"read",
      toPath: "inboundPurchaseOrders",
      title: "Inbound P.Os"
    },
    {
      serviceName: "reportService",
      permission:"read",
      toPath: "productsCreated",
      title: "Report Service"
    },
    // {
    //   serviceName: "inventory",
    //   permission:"read",
    //   toPath: "inventory",
    //   title: "Inventory"
    // },
    {
      serviceName: "locations",
      toPath: "locations",
      permission: "read",
      title: "Locations"
    },
    {
      serviceName: "inventoryView",
      toPath: "inventoryView",
      permission: "read",
      title: "Inventory View"
    },
    {
      serviceName: "inventoryMove",
      toPath: "inventoryMove",
      permission: "read",
      title: "Inventory Move"
    },
    {
      serviceName: "inventoryAdjustments",
      toPath: "inventoryAdjustments",
      permission: "read",
      title: "Inventory Adjustments"
    },
    {
      serviceName: "bins",
      toPath: "bins",
      permission: "read",
      title: "Bins"
    },
    {
      serviceName: "skuTransactions",
      toPath: "skuTransactions",
      permission: "read",
      title: "SKU Transactions"
    },
    {
      serviceName: "orderAllocations",
      toPath: "orderAllocations",
      permission: "read",
      title: "Order Allocations"
    },
    {
      serviceName: "affiliates",
      permission:"create",
      toPath: "affiliates",
      title: "Affiliates"
    },
    {
      serviceName: "commissions",
      toPath: "/commissions",
      permission: "read",
      title: "Commissions"
    },
    {
      serviceName: "createCommission",
      toPath: "/createCommission",
      permission: "create",
      title: "Create Commission"
    },
    {
      serviceName: "affiliateCommissions",
      permission: "read",
      toPath: "affiliateCommissions",
      title: "Affiliate Commissions"
    },
    {
      serviceName: "blog",
      permission:"read",
      toPath: "blog",
      title: "Blog"
    },
    {
      serviceName: "estimates",
      permission: "read",
      toPath: "estimates",
      title: "Estimates"
    },
    {
      serviceName: "franchiseOwner",
      permission: "read",
      toPath: "inventoryManagement",
      title: "Inventory Management"
    },
    {
      serviceName: "franchise",
      permission: "read",
      toPath: "franchise",
      title: "Franchise List"
    },
    {
      serviceName: "franchiseOwner",
      permission: "read",
      toPath: "productManagement",
      title: "Product Management"
    },
  ]

  const navTabs = [];
  for (let currentIndex = 0; currentIndex < navData.length; currentIndex++) {

    const currentValue = navData[currentIndex];
    navTabs.push(
      <Permission key={`${currentIndex}-${currentValue?.serviceName}`} service={`${currentValue?.serviceName}`} permission={`${currentValue?.permission}`}>
        <NavLink
          to={`${currentValue?.toPath}`}
          className={({ isActive }) =>
            [Classes.link, isActive ? Classes.active : null].join(" ")
          }
          onClick={(e) => handleNavigation(e, `/${currentValue?.toPath}`)}
        >
          {`${currentValue?.title}`}
        </NavLink>
      </Permission>
    )
  }


  return (
    <>
      {navTabs}
    </>
  );
};
