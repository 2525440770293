import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import { downloadCSV } from "../../../utility/downloadCSV";
import Permission from "../../auth/permissions";
import OthersFormSection from "../uploadOthersMaster/othersFormSection";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Alert } from "react-bootstrap";
import { services } from '../../../utility/checkRoleBasedPermission';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InventoryUploadPopup({ open, handleClose }) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = React.useState(null);
    const [exportedData, setExportedData] = React.useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const handleSuccess = (msg) => {
        setSuccess(msg);
    }
    const handleError = (msg) => {
        setError(msg);
    }

    const handleExport = (event) => {
        event.preventDefault();
        downloadCSV(exportedData, `Vinculum InventoryUpload Response - ${new Date()}`);
    }

    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            // maxWidth={maxWidth}
            >
                <div style={{padding:"60px 50px 50px"}}>
                    <Permission service={services?.inventory} permission="update">
                        <OthersFormSection
                            formName="Vinculum Inventory"
                            fileName="vinculumInventory"
                            endpoint={`/inventory/uploadVinculumInventory`}
                            handleSuccess={handleSuccess}
                            handleError={handleError}
                            setExportedData={setExportedData}
                        />
                    </Permission>

                    {exportedData ?
                        <div className="d-flex justify-content-between mb-3" style={{ width: "98%", margin: '0px auto' }}>
                            <Button
                                id='exportbtn'
                                variant="contained" color="success"
                                onClick={handleExport}
                                startIcon={<FileDownloadIcon />}
                            >
                                Export Response
                            </Button>
                        </div>
                        : null
                    }
                </div>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}