import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateRange from '../DateRange/DateRange';

const TimeStampInputValue = (props) => {
    const { item, applyValue, focusElementRef, startDate, endDate, setStartDate, setEndDate } = props;
    const startDateRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            startDateRef.current.focus();
        },
    }));

    const handleStartDateFilterChange = (newValue) => {
        applyValue({ ...item, startDate: newValue });
    };

    const handleEndDateFilterChange = (newValue) => {
        applyValue({ ...item, endDate: newValue });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRange
                startDateRef={startDateRef}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runAfterStartDateChange={handleStartDateFilterChange}
                runAfterEndDateChange={handleEndDateFilterChange}
            />
        </LocalizationProvider>
    );
}

export default TimeStampInputValue;