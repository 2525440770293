import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	products: [],
};

const updatePricingSlice = createSlice({
	name: 'productsPricing',
	initialState: initialState,
	reducers: {
		setProductsData : (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setProductsData, reset } = updatePricingSlice.actions;

export default updatePricingSlice.reducer;