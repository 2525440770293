import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    DialogActions,
} from '@mui/material';

const BulkReturnPopup = ({ isOpen, onClose, products, order, navigate, setLoading }) => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const generateUniqueKey = (product) => `${product.oLineItemId}_${product.fulfillmentName}`;

    const handleSelectAll = (checked) => {
        if (checked) {
            // Select all products
            setSelectedProducts(products.map((product) => ({
                uniqueKey: generateUniqueKey(product),
                oLineItemId: product.oLineItemId,
                returnableQty: product?.returnableQty,
                fulfillmentName: product.fulfillmentName,
                reason: 'Other',
                description: '',
                type: 'Refund',
            })));
        } else {
            // Deselect all products
            setSelectedProducts([]);
        }
        setSelectAll(checked);
    };

    const handleCheckboxChange = (product, checked) => {
        const uniqueKey = generateUniqueKey(product);

        if (checked) {
            // If the checkbox is checked, add the product to the selectedProducts array.
            setSelectedProducts((prevSelected) => [
                ...prevSelected,
                {
                    uniqueKey,
                    oLineItemId: product.oLineItemId,
                    returnableQty: product?.returnableQty,
                    fulfillmentName: product.fulfillmentName,
                    reason: 'Other',
                    description: '',
                    type: 'Refund',
                },
            ]);
        } else {
            // If the checkbox is unchecked, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item.uniqueKey !== uniqueKey)
            );
        }
    };

    const handleQuantityChange = (product, e) => {
        const newQuantity = parseInt(e.target.value);
        const uniqueKey = generateUniqueKey(product);

        if (newQuantity === 0) {
            // If the quantity is 0, remove the product from the selectedProducts array.
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((item) => item.uniqueKey !== uniqueKey)
            );
            return;
        }

        if (newQuantity > product?.returnableQty) {
            // If the quantity is greater than the available quantity, set the quantity to the available quantity.
            alert(`The maximum quantity available for ${product?.sku} is ${product?.returnableQty}.`);
            return
        }


        setSelectedProducts((prevSelected) =>
            prevSelected?.map((item) =>
                item.uniqueKey === uniqueKey
                    ? { ...item, returnableQty: newQuantity }
                    : item
            )
        );
    };

    const handleReturn = async () => {
        setLoading(true)
        if (selectedProducts.length <= 0) {
            setLoading(false)
            return
        }

        let data = selectedProducts.map((product) => ({
            oLineItemId: product.oLineItemId,
            quantity: product?.returnableQty,
            fulfillmentName: product.fulfillmentName,
            reason: product.reason,
            description: product.description,
            type: product.type,
        }))

        await axiosInstance
            .post(`/pgOrder/admin/returnReqLineItemWise/${order.orderName}`, data)
            .then((response) => {
                navigate('/ordersNew')
            })
            .catch((error) => {
                console.log(error);
            })

        // reset all state values and close
        setLoading(false)
        hidePopup();
    };

    const hidePopup = () => {
        setSelectedProducts([]);
        setSelectAll(false);
        onClose();
    };

    useEffect(() => {
        if (selectedProducts.length === products.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedProducts]);

    return (

        <Dialog open={isOpen} onClose={onClose} fullWidth={true}
        >
            <DialogTitle>Return Order</DialogTitle>
            {products.length > 0 ? (
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectAll}
                                onChange={(e) => handleSelectAll(e.target.checked)}
                            />
                        }
                        label="Select All"
                    />
                    {/* only map those products which are not returned */}
                    {products?.map((product) => (
                        <div
                            key={generateUniqueKey(product)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '15px 0px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedProducts.some(
                                            (item) => item.uniqueKey === generateUniqueKey(product)
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(product, e.target.checked)
                                        }
                                    />
                                }
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <img
                                            src={product?.mainImageUrl ? product?.mainImageUrl : 'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                            alt={product?.productTitle}
                                            width="55"
                                            height="55"
                                            style={{ marginRight: '10px', borderRadius: '5px', border: '0.5px solid gray' }}
                                        />
                                        <div>
                                            <p style={{ fontWeight: 600 }}>{product.sku}</p>
                                            <p style={{ fontSize: '12px' }}>FulfillmentName: {product?.fulfillmentName}</p>
                                            <p style={{ fontSize: '12px' }}>Quantity: {product?.returnableQty}</p>
                                        </div>
                                    </div>
                                }
                            />
                            {selectedProducts.some(
                                (item) => item.uniqueKey === generateUniqueKey(product)
                            ) && (
                                    <TextField
                                        label="Quantity"
                                        type="number"
                                        value={
                                            selectedProducts.find(
                                                (item) => item.uniqueKey === generateUniqueKey(product)
                                            ).returnableQty
                                        }
                                        onChange={(e) => {
                                            handleQuantityChange(product, e);
                                        }}
                                        style={{ marginLeft: '10px' }}
                                    />
                                )}
                        </div>
                    ))}

                </DialogContent>
            ) : (
                <DialogContent>
                    <p>No products to return.</p>
                </DialogContent>
            )}
            <DialogActions>
                {products.length > 0 && (
                    <Button variant="contained" color="primary" onClick={handleReturn}>
                        Confirm
                    </Button>
                )}
                <Button variant="outlined" color="primary" onClick={hidePopup}>
                    Cancel
                </Button>
            </DialogActions>


        </Dialog>
    );
};

export default BulkReturnPopup;
