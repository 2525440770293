import React, { useEffect } from "react";
import Classes from "./css/addProduct.module.css";
import { IconButton, TextField, Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Prices from "../prices";
import { v4 as uuidv4 } from "uuid";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import axiosInstance from "../../../../utility/axios-instance";
import { priceTypesDiscountMap } from "./AllFunctions";

export default function MultiPricing({
  priceCategory,
  payloadToUpload,
  setPayloadToUpload,
  endpointToFetchCategories,
}) {
  const [priceCategories, setPriceCategories] = React.useState([]);
  const priceCategoryAttribute = priceCategory + "BasedPrices";

  const addMultiPricing = (e) => {
    // priceCategory is either district or franchise
    const priceCardId = uuidv4();
    const pricesAndDiscounts = Object.keys(priceTypesDiscountMap).reduce(
      (acc, priceType) => {
        acc[priceType + "Price"] = ""; // set all price types to empty string
        acc[priceTypesDiscountMap[priceType]] = ""; // set all discount types to empty string
        return acc;
      },
      {}
    );
    setPayloadToUpload((prevPayloadToUpload) => {
      return {
        ...prevPayloadToUpload,
        [priceCategory + "BasedPrices"]: [
          ...(prevPayloadToUpload?.[priceCategory + "BasedPrices"] || []),
          {
            priceCardId,
            [priceCategory]: "",
            ...pricesAndDiscounts,
          },
        ],
      };
    });
  };

  const handleSectionRemoval = (priceCardId) => {
    setPayloadToUpload((prevPayloadToUpload) => {
      return {
        ...prevPayloadToUpload,
        [priceCategory + "BasedPrices"]: prevPayloadToUpload?.[
          priceCategory + "BasedPrices"
        ]?.filter((price) => price?.priceCardId !== priceCardId),
      };
    });
  };

  const handlePriceCategoryValueChange = (chosenValue, priceCardId) => {
    //check if the location already exists in the multiPriceObjArray if yes don't set and show alert
    if (payloadToUpload?.[priceCategoryAttribute]?.length !== 0) {
      if (
        payloadToUpload?.[priceCategoryAttribute]?.find((price) => {
          return price[priceCategory] === chosenValue;
        })
      ) {
        alert(`Price for this ${priceCategory} already exists`);
      } else {
        setPayloadToUpload((prevPayloadToUpload) => {
          return {
            ...prevPayloadToUpload,
            [priceCategoryAttribute]: prevPayloadToUpload?.[
              priceCategoryAttribute
            ]?.map((price) => {
              if (price?.priceCardId === priceCardId) {
                return {
                  ...price,
                  [priceCategory]: chosenValue,
                };
              } else {
                return price;
              }
            }),
          };
        });
      }
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance(endpointToFetchCategories);
        const priceCategoriesData = await response?.data?.data;
        setPriceCategories(priceCategoriesData);
      } catch (error) {
        console.error(`Error fetching ${"district"}:`, error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <React.Fragment>
      <div
        className={Classes.Pricing}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "5px 12px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {priceCategory?.toTitleCase()} Wise Pricing
        </div>
        {payloadToUpload?.defaultPrices?.mrp !== "" && (
          <IconButton onClick={(e) => addMultiPricing(e, priceCategory)}>
            <ControlPointIcon />
          </IconButton>
        )}
      </div>
      {payloadToUpload?.[priceCategory + "BasedPrices"]?.length > 0 &&
        payloadToUpload?.[priceCategory + "BasedPrices"]?.map(
          (pricesObject) => {
            return <div className={Classes.Pricing}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <IconButton
                  onClick={()=>handleSectionRemoval(pricesObject?.priceCardId)}
                >
                  <ClearIcon />
                </IconButton>
              </div>
              <div className={Classes.Boxes}>
                {priceCategories && (
                  <Autocomplete
                    freeSolo
                    forcePopupIcon={true}
                    size="small"
                    id="price-categories"
                    required
                    options={priceCategories}
                    style={{ width: "48%" }}
                    onChange={(e, newValue) =>
                      handlePriceCategoryValueChange(
                        newValue,
                        pricesObject?.priceCardId
                      )
                    }
                    value={
                      payloadToUpload[priceCategoryAttribute].find((price) => {
                        return price?.priceCardId === pricesObject?.priceCardId;
                      })?.[priceCategory]
                    }
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionSelected={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={priceCategory?.toUpperCase()}
                        defaultValue=""
                      />
                    )}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    marginTop: "2em",
                  }}
                >
                  <Prices
                    payloadToUpload={payloadToUpload}
                    setPayloadToUpload={setPayloadToUpload}
                    pricesType={priceCategoryAttribute}
                    priceCardId={pricesObject?.priceCardId}
                  />
                </div>
              </div>
            </div>;
          }
        )}
    </React.Fragment>
  );
}
