import { Fragment } from "react";
import ReactJson from 'react-json-view'
const VisualizeJSONData = (props) => {
  const { payload } = props;

  return <Fragment>
    <ReactJson src={payload} />
  </Fragment>
}

export default VisualizeJSONData;