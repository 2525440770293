import React from "react";
import Classes from "../pages/home/settings/dashboard.module.css";

const SettingsItem = ({ onClick, iconSrc, iconAlt, title, subTitle }) => {
  return (
    <div className={Classes.Item} onClick={onClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "100%",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {React.isValidElement(iconSrc) ? (
            iconSrc
          ) : (
            <img
              className={`${Classes.BtnImage}`}
              style={{ width: "auto", height: "4rem" }}
              src={iconSrc}
              alt={iconAlt}
            />
          )}
        </div>
        <h4
          style={{
            fontSize: "1.25rem",
            width: "66.666%",
          }}
        >
          {title}
          <p style={{
                fontSize: '11px',
                marginTop: '10px'
          }}>{subTitle}</p>
        </h4>
      </div>
    </div>
  );
};

export default SettingsItem;
