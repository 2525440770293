import { Fragment } from "react";
import { useSelector } from 'react-redux';
import { setDraftInterWarehouseMoves } from "../../../store/draftInterWarehouseMovesSlice";
import Table from "../../../components/table/table";

const DraftInterWarehouseInventoryMoves = () => {
  const draftInterWarehouseMoves = useSelector((state) => state.draftInterWarehouseMoves);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.6,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1.7,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "draft_purchase_order_id",
      headerName: "DRAFT PURCHASE ORDER ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "draft_order_id",
      headerName: "DRAFT ORDER ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "created_by",
      headerName: "CREATED BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      type: 'enum',
      columnName: 'created_by',
      tableName: 'DraftInterWarehouseInventoryMoves'
    },
    {
      field: "completed",
      headerName: "COMPLETED",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      enumValues: [true, false]
    },
    {
      field: "completed_at",
      headerName: "COMPLETED AT",
      flex: 1.7,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "inter_warehouse_inventory_move_id",
      headerName: "INTER WAREHOUSE INVENTORY MOVE ID",
      flex: 1.7,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  return (
    <Fragment>
      <Table
				columns={columns}
				dataPosition={"draftInterWarehouseMoves"}
				endpoint={`/interWarehouseInvMove/fetchDraftInterWarehouseInventoryMoves`}
				rowId={'draft_purchase_order_id'}
				checkBoxSelection={false}
				version="version-1"
				service={'inventory'}
				tabName={'Draft Inter Warehouse Inventory Moves'}
				navigateOnRowClickEndpoint={`/draftInterWarehouseInventoryMoves`}
				setData={setDraftInterWarehouseMoves}
				data={draftInterWarehouseMoves}
				mobileResponsive={true}
				exportEndpoint={'/exports/draftInterWarehouseInventoryMoves'}
				exportFileName={`Draft InterWarehouse Inventory Moves - ${new Date()}`}
			/>
    </Fragment>
  )
}

export default DraftInterWarehouseInventoryMoves;