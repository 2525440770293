import React from 'react';
import { useState } from 'react';
import SnackBar from '../../../components/SnackBar';
import { TextField,
    Button,
    Dialog,
    DialogTitle,
    Slide,
    DialogContent,
    DialogContentText
    } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateLowerAndUpperLimit = (props) => {
    const { data, closeDialog, setReRender } = props;

    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [loading, setLoading] = useState(false);

    const updateLowerAndUpperLimit = async (payload) => {
        setLoading(true);

        axiosInstance.put(`/inventory/updateLowerAndUpperLimit/${data.inventory_id}`, payload)
        .then(res => {
            setReRender((prev) => !prev);
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "success",
                    message: res?.data?.message ?? "Successfully updated"
                }
            });

            closeDialog();
        })
        .catch(err => {
            setSnackBar((prev) => {
                return {
                    ...prev,
                    display: true,
                    type: "error",
                    message: err?.response?.data?.message ?? "Something went wrong"
                }
            });
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            lower_limit: e.target['lower_limit'].value,
            upper_limit: e.target['upper_limit'].value
        }

        if (isNaN(data.lower_limit) || isNaN(data.upper_limit)) {
            setSnackBar({ display: true, type: "error", message: "Lower limit and Upper limit must be greater than 0." });
            return;
        }

        data.lower_limit = +data.lower_limit;
        data.upper_limit = +data.upper_limit;

        updateLowerAndUpperLimit(data)
    }

    return (
        <div>
            <Dialog
                open={!!data}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeDialog}
                fullWidth={true}
                sx={{'& .MuiDialog-paper': {maxWidth:'775px'}}}
            >
                <DialogTitle>Update Lower And Upper Limit</DialogTitle>

                <DialogContent>
                    <DialogContentText >
                        {data.sku_code}, {data.locationCode}
                    </DialogContentText>
                </DialogContent>

                <div style={{
                    minHeight: '50vh',
                    padding: '20px',
                }}>
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <TextField
                                label={'Lower Limit'}
                                placeholder={'Enter Lower Limit'}
                                required={true}
                                name={'lower_limit'}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '32%' }}
                                type='number'
                                defaultValue={data?.lower_limit}
                                key={'lower_limit'}
                            />
                            <TextField
                                label={'Upper Limit'}
                                placeholder={'Enter Upper Limit'}
                                required={true}
                                name={'upper_limit'}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '32%' }}
                                type='number'
                                defaultValue={data?.upper_limit}
                                key={'upper_limit'}
                            />

                            <div style={{ width: '32%' }}>
                                <Button type='submit' variant='contained' disabled={loading} color='success'>Update</Button>
                            </div>
                        </div>
                    </form>
                </div>

                {loading ? <Spinner /> : null}
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}

export default UpdateLowerAndUpperLimit;