import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  CircularProgress,
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  Slide,
  Divider,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../../../utility/axios-instance";
import { styled } from "@mui/system";
import StorePreview from "../storePreview";
import SnackBar from "../../../../../components/SnackBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MediaPreview from "../../../../../components/mediaPreview";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const UpdateModal = ({
  open,
  handleClose,
  setReRender,
  detail,
  storeId,
  imageId,
  imagesLength,
  activeCount
}) => {
  const [title, setTitle] = useState(detail.title || "");
  const [link, setLink] = useState(detail.link || "");
  const [imageInputType, setImageInputType] = useState("URL");
  const [imageURL, setImageURL] = useState(detail.imageURL || "");
  const [imageFile, setImageFile] = useState(null);
  const [titleColor, setTitleColor] = useState(detail.titleColor || "#000000");
  const [isActive, setIsActive] = useState(detail.active);
  const [previewToggle, setPreviewToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const imagePreviewData = useRef({ title: "", image: "" });
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const [imagePreview, setImagePreview] = useState(detail.imageURL || "");
  const [objectURL, setObjectURL] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    let data = { title, link, titleColor, active: isActive };
    if (imageInputType === "URL") {
      data = { ...data, imageURL };
    } else {
      formData.append("imageFile", imageFile);
    }
    formData.append("data", JSON.stringify(data));

    try {
      const res = await axiosInstance.post(
        `/adminRoutes/stores/${storeId}/images/${imageId}`,
        formData
      );
      handleClose();
      setSnackBar({
        display: true,
        type: "success",
        message: res?.data?.message,
      });
      setReRender((prev) => !prev);
    } catch (err) {
      setSnackBar({
        display: true,
        type: "error",
        message:
          err?.response?.data?.message || "An error occurred during submission",
      });
    } finally {
      setLoading(false);
    }
  };
    const handlePreviewURL = () => {
      if (imageInputType === "URL") {
        return imageURL;
      } else {
        if (imageFile) {
          const url = URL.createObjectURL(imageFile);
          setObjectURL(url);
          return url;
        }
      }
    };
    const handlePreviewClose = () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }

      setPreviewToggle(false);
    };
  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="update-dialog-title"
      >
        <Grid container>
          <Grid item xs={6}>
            <DialogTitle id="upload-dialog-title">
              Update Store Image
            </DialogTitle>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            marginLeft={-1}
          >
            <Button
              variant="contained"
              disabled={!imagePreview}
              onClick={() => setPreviewToggle(true)}
              startIcon={<VisibilityIcon />}
            >
              Preview{" "}
            </Button>
          </Grid>
        </Grid>
        <StyledForm onSubmit={handleSubmit}>
          <StyledDialogContent>
            <Divider
              component="div"
              role="presentation"
              style={{ marginBottom: "1rem" }}
            >
              <Typography variant="subtitle1">
                Edit Store Image Details
              </Typography>
            </Divider>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => setTitle(e.target.value)}
                  margin="dense"
                  required
                  label="Title"
                  type="text"
                  variant="outlined"
                  value={title}
                  autoComplete="off"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <TextField
                      margin="dense"
                      required
                      label="Title Color"
                      type="text"
                      variant="outlined"
                      value={titleColor}
                      onChange={(e) => setTitleColor(e.target.value)}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      margin="dense"
                      required
                      label="Title Color"
                      type="color"
                      value={titleColor}
                      onChange={(e) => setTitleColor(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={imageInputType}
                onChange={(e) => {
                  if (imageInputType === "FILE") {
                    setImageFile(null);
                  }
                  setImageInputType(e.target.value);
                }}
              >
                <FormControlLabel value="URL" control={<Radio />} label="URL" />
                <FormControlLabel
                  value="FILE"
                  control={<Radio />}
                  label="File Upload"
                />
              </RadioGroup>
            </FormControl>
            {imageInputType === "URL" ? (
              <TextField
                required
                label="Image URL"
                type="url"
                value={imageURL}
                onChange={(e) => {
                  setImagePreview(e.target.value);
                  setImageURL(e.target.value);
                }}
                fullWidth
              />
            ) : (
              <TextField
                required
                type="file"
                onChange={(e) => {
                  setImagePreview(e.target.files[0]);
                  setImageFile(e.target.files[0]);
                }}
                inputProps={{ accept: "image/*" }}
                fullWidth
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              }
              label="Active"
            />
          </StyledDialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={6} display="flex" justifyContent="center">
                {/* <Button
                  variant="contained"
                  onClick={() => setPreviewToggle(true)}
                >
                  Preview
                </Button> */}
              </Grid>
            </Grid>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </DialogActions>
        </StyledForm>
      </StyledDialog>
      {/* {previewToggle && (
        <StorePreview
          image={imagePreviewData.current}
          previewState={previewToggle}
          handlePreviewState={setPreviewToggle}
        />
      )} */}
      {previewToggle && (
        <MediaPreview
          open={previewToggle}
          handleClose={handlePreviewClose}
          type="img"
          handleUrl={handlePreviewURL}
        />
      )}
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default UpdateModal;
