import { Fragment, useState, useEffect } from 'react';
import Classes2 from '../createOrders/css/create.module.css';
import {ContentCopy} from '@mui/icons-material/';

import axiosInstance from '../../../utility/axios-instance';

const GenerateOrderDetailLink = (props) => {
    const { draftOrder, setSnackBar } = props;

    const [data, setData] = useState(null);
    const [loading0, setLoading0] = useState(true);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
      axiosInstance.get(`/orders/admin/generateOrderDetailLink/${draftOrder.orderId}`).then(res => {
            setData(res.data.data)
        }).catch(err => {
            setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'error',
                message: err?.response?.data?.message
            }))
        }).finally(() => {
            setLoading0(false)
        })
    }

    useEffect(() => {
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const generateOrderDetailLink = async () => {
        setLoading(true);
        axiosInstance.post(`/orders/admin/generateOrderDetailLink/${draftOrder.orderId}`).then(res => {
            setData(res.data.data)
        }).catch(err => {
            setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'error',
                message: err?.response?.data?.message
            }))
        }).finally(() => {
            setLoading(false)
        })
    }

    const sendOrderDetailLinkToCustomer = async () => {
      setLoading(true);

      axiosInstance.post(`/orders/admin/sendOrderDetailLinkToCustomer/${data.uuid}`).then(res => {
        setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'success',
                message: res?.data?.message
            }))
      }).catch(err => {
        setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'error',
                message: err?.response?.data?.message
            }))
      }).finally(() => {
        setLoading(false)
      })
    }


    return (
        <Fragment>
            <div style={{width:'100%',marginBottom:20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Order Detail Link</p>
                    <div style={{display:'flex',padding:'15px', gap: '16px',flexWrap:'wrap',width:'100%'}}>

                        {!data ? <button
                          className={[
                            loading0 || loading 
                            ? Classes2.disabledDraftOrderButton 
                            : Classes2.draftOrderButton,
                            Classes2.Btn
                          ].join(" ")}
                          style={{
                            ...(loading0 || loading 
                              ? {
                                backgroundColor: "#6c757d"
                              } : {
                                backgroundColor: "#6c943eeb"
                              })
                          }}
                          onClick={(e) => {
                            generateOrderDetailLink()
                          }}
                          disabled={loading0 || loading }
                        >
                          {
                            loading0 ? 'Loading ...' :
                            loading
                              ? "Generating ..."
                              : "Generate"
                          }
                        </button> : null}

                        {data ? <Fragment>
                          <button
                          className={[
                            Classes2.draftOrderButton,
                            Classes2.Btn
                          ].join(" ")}
                          style={{
                            ...({
                                backgroundColor: "#6c943eeb"
                              })
                          }}
                          onClick={(e) => {
                            navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/orders/orderDetails/${data.uuid}`)
                            setSnackBar(prev => ({
                            ...prev,
                            display: true,
                            type: 'success',
                            message: `Successfully copied the link!`
            }))
                          }}
                          
                        >
                          {
                            <ContentCopy />
                          }
                        </button>

                            <button
                          className={[
                            loading 
                            ? Classes2.disabledDraftOrderButton 
                            : Classes2.draftOrderButton,
                            Classes2.Btn
                          ].join(" ")}
                          style={{
                            ...(loading 
                              ? {
                                backgroundColor: "#6c757d"
                              } : {
                                backgroundColor: "#6c943eeb"
                              })
                          }}
                          onClick={(e) => {
                            sendOrderDetailLinkToCustomer()
                          }}
                          disabled={loading }
                        >
                          {
                            
                            loading
                              ? "Sending Link To customer ..."
                              : "Send Link To customer"
                          }
                        </button>
                        </Fragment> : null}

                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default GenerateOrderDetailLink;