import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { TextField, Autocomplete, Box, Chip } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from 'moment';
import NewPagination from '../../../components/newPagination';
import Spinner from "../../../components/spinner";
import EnumInputValue from '../../../components/table/EnumInputValue';
import { CustomToolbar } from '../../../components/products/productComponents';
import SnackBar from "../../../components/SnackBar";

function DatePick ({estimateDate, setEstimateDate, setLineItemId, lineItemId}) {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Estimate Date" 
                    sx={{ 
                            "& .MuiOutlinedInput-input": {padding: "10px 8px",width: '105px',fontSize:'14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' }
                    }}
                    inputFormat="YYYY-MM-DD"
                    size="small"
                    format="YYYY-MM-DD"
                    onChange={(newValue) => {
                        setEstimateDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        setLineItemId(lineItemId)
                    }} 
                    minDate={dayjs(Date.now())}
                />
            </LocalizationProvider>
        </div>
    )
}

const InventoryStockOut = () => {
    const [estimateDate, setEstimateDate] = useState('');
    const [lineItemId, setLineItemId] = useState('');
    const [lineItemsBasedOnStatus, setLineItemsBasedOnStatus] = useState([]);
    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const [startDate, setStartDate] = useState(() => 
        getFormattedDate(new Date(Date.now() - 15 * 24 * 60 * 60 * 1000))
    );// set last 15 days date by default
    const [endDate, setEndDate] = useState(() => getFormattedDate(new Date()));
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [loading,setLoading] = useState(false);
    const [dayRange , setDayRange ] = useState('15 days')
    const allDaysRange = ['7 days', '15 days', '30 days'];
    const [filter, setFilter] = useState(null);
    const [searchFor,setSearchFor] = useState('');


    let skuWiseOrderDetailColumn = [
        {
            field: 'sku',
            headerName: 'SKU',
            flex:1.5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'openQty',
            headerName: 'Open Qty',
            flex:0.7,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'availableInventory',
            headerName: 'Inventory',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
            renderCell : (params) => params?.row?.availableInventory > 0 ? <p style={{color:'green'}}>{params?.row?.availableInventory}</p> : <p style={{color:'red'}}>{params?.row?.availableInventory}</p>
        },
        {
            field: 'orderName',
            headerName: 'OrderName',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex:1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
        },
        {
            field: 'estimateProcurementDate',
            headerName: 'Estimate',
            flex:1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            valueFormatter : (params) => params?.row?.estimateProcurementDate || '',
            renderCell : (params) => (
                params?.row?.estimateProcurementDate
                ?   <p style={{fontWeight : '500', color : params?.row?.deadlineDays > 1 ? 'green' : 'red'}}>
                        {params?.row?.estimateProcurementDate}, {params?.row?.deadlineDays >= 0 ? `${params?.row?.deadlineDays} days left` : "expired"}
                    </p>
                : 
                <div onClick={(event) => { event.stopPropagation() }}>
                    <DatePick
                        estimateDate={estimateDate}
                        setEstimateDate={setEstimateDate}
                        lineItemId={params?.row?.oLineItemId}
                        setLineItemId={setLineItemId}
                    />
                </div>
            )
        },
        {
            field: 'status',
            headerName: 'STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'enum',
            tableName: 'Order',
            columnName: 'status',
            renderCell: (params) => (
                params.value === "Cancelled" || params?.value === 'Delivered' ? (
                    <Chip label={params.value} style={{ backgroundColor: params.value === "Cancelled" ? '#e54545' : '#128038', color: '#fff' }} />
                ) : (
                    <Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
                )
            )
        },
        {
            field: 'createdAt',
            headerName: 'CreatedAt',
            flex:1.7,
            headerClassName: 'super-app-theme--header',
            renderCell : (params) => moment(params?.row?.createdAt).format('MMM DD, YYYY h:mm:ss A') 
        }
    ]

    const fetchAllLineItemsBasedOnStatus = async (type) => {
        setLoading(true);

        const params = {
            page: type,
            searchFor: searchFor ? searchFor : '',
            filter: filter?.value ? JSON.stringify(filter): undefined,
            startDate,
            endDate,
            status: "Pending Due To Stock Out"
        };
        await axiosInstance
            .get(`/analytics/salesAnalytics/fetchLineItemsBasedOnStatus`, {
                params
            })
            .then((response) => {
                setLineItemsBasedOnStatus(response?.data?.data)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
            setLoading(false);
    }
    
    const changeDaysToLastDate = (dayRange) => {
        const currentDate = Date.now();
        let startDateRange;
        if( dayRange === "7 days"){
            startDateRange = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
        }
        else if( dayRange === "15 days"){
            startDateRange = new Date(currentDate - 15 * 24 * 60 * 60 * 1000);
        }
        else if( dayRange === "30 days"){
            startDateRange = new Date(currentDate - 30 * 24 * 60 * 60 * 1000);
        }
        setStartDate(getFormattedDate(startDateRange))
        setEndDate(getFormattedDate(new Date()))
    }

    useEffect(() => {
        fetchAllLineItemsBasedOnStatus()
    }, [startDate,endDate, filter, searchFor])

    useEffect(() => {
        changeDaysToLastDate(dayRange);
    }, [dayRange])

    const addEstimateProcurementDate = async () => {
        setLoading(true)
        await axiosInstance
            .put(`/pgOrder/admin/addEstimateProcurementDateOnLineItem/${lineItemId}`,{
                estimateProcurementDate:estimateDate
            })
            .then((response) => {
                setSnackBar({display: true, message: 'Estimate Date Added', type: "success"})
                fetchAllLineItemsBasedOnStatus();
                setLoading(false)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    useEffect(() => {
        lineItemId && addEstimateProcurementDate()
    },[estimateDate])


    const enumOnlyOperators = (enumValues, tableName, columnName) => [
        {
            label: 'Equals',
            value: 'equal',
            InputComponent: (props) => EnumInputValue({ enumValues, ...props }),
            InputComponentProps: { tableName, columnName },
            getValueAsString: (value) => `${value}`,
        },
        {
            label: 'Not Equals',
            value: 'notEqual',
            InputComponent: (props) => EnumInputValue({ enumValues, ...props }),
            InputComponentProps: { tableName, columnName },
            getValueAsString: (value) => `${value}`,
        },
    ];

    skuWiseOrderDetailColumn = React.useMemo(
        () =>
            skuWiseOrderDetailColumn.map((col) => {
                if (col?.type === 'enum') {
                    return {
                        ...col,
                        filterOperators: enumOnlyOperators(col?.enumValues, col?.tableName, col?.columnName),
                    }
                }
                return col;
            }),
        [],
    );

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    return (
        <div>
            <div style={{width:'100%',display:'flex',justifyContent:'flex-end',marginTop:-18}}>
                 <Autocomplete
                    disablePortal
                    size='small'
                    id="daysRange"
                    name="daysRange"
                    defaultValue='15 days'
                    options={allDaysRange}
                    renderInput={(params) => <TextField 
                        {...params} label='Last days' required={true}
                        onBlur={(event) => {setDayRange(event.target.value)}}
                    />}
                    sx={{width:'15%'}}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Start Date" 
                        sx={{ 
                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px",fontSize:'14px'},
                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                "& .Mui-error": { color:'#00000099 !important' },
                                width:'15%'
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        onChange={(newValue) => {
                            setStartDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                    <DatePicker 
                        label="End Date"
                        sx={{ 
                            "& .MuiOutlinedInput-input": {padding: "9.5px 8px",fontSize:'14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' },
                            width:'15%'
                        }}
                        
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(endDate)}
                        value={dayjs(endDate)}
                        onChange={(newValue) => {
                            setEndDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                </LocalizationProvider>
            </div>

            <Box 
                sx={{ 
                    width: '100%' ,marginTop : '15px',
                    '& .super-app-theme--header': {
                        backgroundColor: '#243750',
                        color: '#ffffff',
                        cursor: "default"
                    }
                }}
            >
                <DataGrid
                    rows={lineItemsBasedOnStatus?.linesItemsBasedOnStatus ?? []}
                    columns={skuWiseOrderDetailColumn}
                    getRowId={(row) => row['oLineItemId']}
                    getRowHeight={() => "auto"}
                    sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiTablePagination-displayedRows": { display: 'none' },
                        "& .MuiTablePagination-actions": { display: 'none' },
                        ".PrivateSwitchBase-input": {
                            height: "23px",
                            margin: "10px 13px",
                            // width: "20px"
                        },
                        cursor: "pointer",
                        '& .MuiDataGrid-cell': { py: '10px' },
                        "& .MuiDataGrid-footerContainer": {
                            display: 'none'
                        }
                    }}
                    filterMode="server"
                    sortingMode="server"
                    onFilterModelChange={(filter) => (setFilter(filter.items[0]))}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                        toolbar: {
                            searchFor: searchFor,
                            handleSearch: handleSearch,
                            showQuickFilter: true,
                            csvOptions: { allColumns: true, fileName : 'Product wise order details' }
                        }
                    }}        
                />
            </Box>

            {lineItemsBasedOnStatus?.linesItemsBasedOnStatus?.length > 0 && (
                <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                    <NewPagination totalPage={lineItemsBasedOnStatus} fetchData={fetchAllLineItemsBasedOnStatus} />
                </div>
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            {loading && <Spinner />}
        </div>
    )
}

export default InventoryStockOut;