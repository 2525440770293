import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRefund } from '../../../store/refundsSlice';
import resetState from '../../../store/actions';
import axiosInstance from '../../../utility/axios-instance';
import { BsCheck } from 'react-icons/bs';

const years = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

// const days = [
// 	'Sunday',
// 	'Monday',
// 	'Tuesday',
// 	'Wednesday',
// 	'Thursday',
// 	'Friday',
// 	'Saturday',
// ];

const Refund = (props) => {
	const [hover, setHover] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { refund } = props;

	const dn = new Date();
	const createdAt = new Date(refund.createdAt);

	let ca = createdAt
		? createdAt.getFullYear() === dn.getFullYear()
			? createdAt.getMonth() === dn.getMonth()
				? createdAt.getDate() === dn.getDate()
					? `Today at ${createdAt.toLocaleTimeString()}`
					: createdAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${createdAt.toLocaleTimeString()}`
					: years[createdAt.getMonth()] +
					  ' ' +
					  createdAt.getDate() +
					  ' at ' +
					  createdAt.toLocaleTimeString()
				: years[createdAt.getMonth()] +
				  ' ' +
				  createdAt.getDate() +
				  ' at ' +
				  createdAt.toLocaleTimeString()
			: years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
		: null;

	const updateRefund = async () => {
		setLoading(true);
		await axiosInstance
			.post(`/refunds/${refund?._id}`, {})
			.then((res) => {
				dispatch(setRefund(res?.data?.data));
			})
			.catch((err) => {
				setError(error?.response?.data?.message);
				setTimeout(() => {
					setError(null);
				}, 500);
				if (error?.response?.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<tr
			key={refund?.id}
			className={hover ? 'bg-light' : ''}
			style={{
				cursor: 'pointer',
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			onClick={() => {
				navigate(`/orders/${refund?.against?.order?._id}`);
			}}
		>
			<th scope="row">{refund?.against?.order?.draftOrder?.order?.name}</th>
			<td>{refund?.docModel}</td>
			<td>{refund?.against?.outputKey}</td>
			<td>{refund?.against?.lineItem}</td>
			<td>{refund?.against?.sku}</td>
			<td>{ca}</td>
			<td>
				<div
					className="d-flex align-items-center"
					style={{
						gap: '4px',
						position: 'relative',
						overflow: 'visible',
						zIndex: '2',
					}}
				>
					{refund?.status === 'Complete' ? (
						<BsCheck color="green" size={24} />
					) : (
						<div
							className="border rounded justify-content-center align-items-center"
							style={{
								height: '16px',
								width: '16px',
							}}
							onClick={(e) => {
								e.stopPropagation();
								updateRefund();
							}}
						>
							{loading ? (
								<div
									className="spinner-border"
									role="status"
									style={{
										height: '16px',
										width: '16px',
									}}
								>
									<span className="sr-only"></span>
								</div>
							) : null}
						</div>
					)}
					{refund?.status}
					{error ? (
						<div
							className="p-1 rounded bg-danger text-light"
							style={{
								position: 'absolute',
								top: '10%',
								left: '10%',
								width: '10rem',
								zIndex: '4',
							}}
						>
							{error}
						</div>
					) : null}
				</div>
			</td>
			<td>{refund?.amount}</td>
		</tr>
	);
};

export default Refund;
