import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/system";
import axiosInstance from "../../../../utility/axios-instance";
import Spinner from "../../../../components/spinner";
import SnackBar from "../../../../components/SnackBar";
const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});
const SectionBox = styled(Box)({
  marginBottom: "2rem",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});
const UploadModal = ({ open, handleClose, setReRender }) => {
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const [imageData, setImageData] = useState({
    primaryImage: {
      title: "",
      cta_link: "",
      url: "",
      file: null,
      inputType: "url",
    },
    secondaryImage1: {
      title: "",
      cta_link: "",
      url: "",
      file: null,
      inputType: "url",
    },
    secondaryImage2: {
      title: "",
      cta_link: "",
      url: "",
      file: null,
      inputType: "url",
    },
  });

  const [isActive, setIsActive] = useState(true);
  const handleInputChange = (e, key, field) => {
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    setImageData((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],

        [field]: value,
      },
    }));
  };

  const handleInputTypeChange = (key, value) => {
    setImageData((prev) => ({
      ...prev,
      [key]: { ...prev[key], inputType: value },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const allFilesUploaded = Object.values(imageData).some((item) => {
      if (item.url === "" && item.file === null) {
        return false;
      }
      return true;
    });
    if (!allFilesUploaded) {
      setSnackBar({
        display: true,
        type: "error",
        message: "All images are required",
      });
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify({ ...imageData, active: isActive }));

      formData.append("primaryImageFile", imageData.primaryImage.file);
      formData.append("secondaryImage1File", imageData.secondaryImage1.file);
      formData.append("secondaryImage2File", imageData.secondaryImage2.file);
      const res = await axiosInstance.post(
        `/adminRoutes/banners/images`,
        formData
      );
      handleClose();
      setReRender((prev) => !prev);
      setSnackBar({
        display: true,
        type: "success",
        message: res?.data?.message,
      });
    } catch (err) {
      setSnackBar({
        display: true,
        type: "error",
        message:
          err?.response?.data?.message || "An error occurred during submission",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="upload-dialog-title"
      >
        <DialogTitle id="upload-dialog-title">Upload Images</DialogTitle>
        <StyledForm onSubmit={handleSubmit}>
          <StyledDialogContent>
            <SectionBox>
              <Divider
                component="div"
                role="presentation"
                style={{ marginBottom: "1rem" }}
              >
                <Typography variant="subtitle1">Primary Image</Typography>
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Title"
                    fullWidth
                    value={imageData.primaryImage.title}
                    onChange={(e) =>
                      handleInputChange(e, "primaryImage", "title")
                    }
                    margin="dense"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="CTA_Link"
                    fullWidth
                    value={imageData.primaryImage.cta_link}
                    onChange={(e) =>
                      handleInputChange(e, "primaryImage", "cta_link")
                    }
                    margin="dense"
                    helperText="Enter the redirect link for when this image is clicked."
                  />
                </Grid>
              </Grid>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={imageData["primaryImage"].inputType}
                  onChange={(e) =>
                    handleInputTypeChange("primaryImage", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="url"
                    control={<Radio />}
                    label="URL"
                  />
                  <FormControlLabel
                    value="file"
                    control={<Radio />}
                    label="File Upload"
                  />
                </RadioGroup>
              </FormControl>
              {imageData["primaryImage"].inputType === "url" ? (
                <TextField
                  label="URL"
                  type="url"
                  fullWidth
                  value={imageData["primaryImage"].url}
                  onChange={(e) => handleInputChange(e, "primaryImage", "url")}
                  margin="dense"
                  required
                />
              ) : (
                <TextField
                  type="file"
                  onChange={(e) => handleInputChange(e, "primaryImage", "file")}
                  inputProps={{ accept: "image/*" }}
                  required
                  fullWidth
                  margin="dense"
                />
              )}
            </SectionBox>
            <SectionBox>
              <Divider
                component="div"
                role="presentation"
                style={{ marginBottom: "1rem" }}
              >
                <Typography variant="subtitle1">Secondary Images</Typography>
              </Divider>
              <Grid container spacing={2}>
                {["secondaryImage1", "secondaryImage2"].map((key) => (
                  <Grid item xs={6} key={key}>
                    <Typography variant="subtitle1">{`Secondary Image ${
                      key[key.length - 1]
                    }`}</Typography>
                    <TextField
                      label="Title"
                      required
                      fullWidth
                      value={imageData[key].title}
                      onChange={(e) => handleInputChange(e, key, "title")}
                      margin="dense"
                    />
                    <TextField
                      label="CTA_Link"
                      fullWidth
                      value={imageData[key].cta_link}
                      onChange={(e) => handleInputChange(e, key, "cta_link")}
                      margin="dense"
                      helperText="Enter the redirect link for when this image is clicked."
                    />
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={imageData[key].inputType}
                        onChange={(e) =>
                          handleInputTypeChange(key, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="url"
                          control={<Radio />}
                          label="URL"
                        />
                        <FormControlLabel
                          value="file"
                          control={<Radio />}
                          label="File Upload"
                        />
                      </RadioGroup>
                    </FormControl>
                    {imageData[key].inputType === "url" ? (
                      <TextField
                        label="URL"
                        type="url"
                        fullWidth
                        value={imageData[key].url}
                        onChange={(e) => handleInputChange(e, key, "url")}
                        margin="dense"
                        required
                      />
                    ) : (
                      <TextField
                        type="file"
                        onChange={(e) => handleInputChange(e, key, "file")}
                        inputProps={{ accept: "image/*" }}
                        required
                        fullWidth
                        margin="dense"
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </SectionBox>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              }
              label="Activate Images Set"
            />
          </StyledDialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </StyledForm>
        {loading ? <Spinner /> : null}
      </StyledDialog>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default UploadModal;
