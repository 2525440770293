import React, { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from "../../../components/spinner";

const CreditInfoDialog = (props) => {
    const [loading, setLoading] = useState(false);
    const { showPopup, hideClosePopup, billingCode, setReRender, companyId, creditLimit, creditDays, customerId} =
        props;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        
        const { creditLimit, creditDays } = event.target.elements;
        const data = {
            creditLimit: creditLimit.value,
            creditDays: creditDays.value,
            companyId: companyId,
            customerId: customerId
        }

        await axiosInstance
            .post(`/ledger/editCreditInfo/${billingCode}`, data)
            .then((res) => {
                setReRender((prev) => !prev);
                hideClosePopup();
                setLoading(false);
            })
            .catch((err) => {
                hideClosePopup();
                setLoading(false);
                setReRender((prev) => !prev);
            });
    };

    const preventNegativeValues = (e) => ["e", "E", "+", "-", " "].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            <Dialog
                open={showPopup}
                onChange={(e, newVal) => {
                    if (newVal === false) {
                        hideClosePopup();
                    }
                }}
            >
                <DialogContent className="">
                    <DialogTitle>Edit Credit Info. </DialogTitle>
                    <form method="post" onSubmit={handleSubmit}>
                        <div className="input-group" style={{ padding: 20 }}>
                            <input
                                required
                                placeholder="Enter Credit Limit"
                                className="form-control"
                                type="number"
                                id="creditLimit"
                                defaultValue={creditLimit}
                                name="creditLimit"
                                onKeyDown={preventNegativeValues}
                            />

                            <input
                                required
                                placeholder="Enter Credit Days"
                                className="form-control"
                                type="number"
                                defaultValue={creditDays}
                                id="creditDays"
                                name="creditDays"
                                onKeyDown={preventNegativeValues}
                            />
                        </div>
                        <DialogActions className="flex w-full">
                            <Button onClick={hideClosePopup}>Close</Button>

                            <Button type="submit" disabled={loading}>
                                Save
                            </Button>
                        </DialogActions>

                    </form>
                </DialogContent>
                {loading && <Spinner />}
            </Dialog> 

        </Fragment>
    );
};

export default CreditInfoDialog;
