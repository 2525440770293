import { Fragment, useEffect, useState } from 'react';
import Classes2 from '../createOrders/css/create.module.css';

import axiosInstance from '../../../utility/axios-instance';
import SelectCompany from '../../../components/selectCompany';

const CollectPayment = (props) => {
    const { draftOrder, setSnackBar, isLedger, setLedger, ledger } = props;

    const [paymentGateways, setPaymentGateways] = useState([]);

    const [paymentGateway, setPaymentGateway] = useState('');
    const [amount, setAmount] = useState(null);
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const getPaymentGateways = async () => {
        axiosInstance.get('/pos/paymentGatewaysPortal').then(res => {
        setPaymentGateways(res.data.data.paymentGateways);
        }).catch(err => {});
    }

    useEffect(() => {
        getPaymentGateways()
    }, []);

    const sendPaymentLink = async (company) => {
        setLoading(true);

        axiosInstance.post(`/paymentMethod/sendPaymentLink${isLedger ? '2' : ''}/${isLedger ? ledger.customerId : draftOrder.orderId}`, {
            amount,
            paymentGatewayId: +paymentGateway,
            company,
            billingCode: ledger?.billingCode
        }).then(res => {
            setPaymentGateway('');
            setAmount('');
            setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'success',
                message: 'Successfully send payment link!'
            }));
            setLedger?.(null)
        }).catch(err => {
            console.log(err)
            setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'error',
                message: err?.response?.data?.message
            }))
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Fragment>
            <SelectCompany open={open} setOpen={setOpen} func={sendPaymentLink} />
            <div style={{width:'100%',marginBottom:isLedger ? 0 : 20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Collect Payment</p>
                    <div style={{display:'flex',padding:'15px', gap: '16px',flexWrap:'wrap',width:'100%'}}>
                        
                        <select name="paymentGateway" value={paymentGateway}
                            onChange={(e) => {
                                setPaymentGateway(e.target.value);
                            }}
                            style={{
                                maxWidth: '180px'
                            }}
                            >
                            <option value={null}>Select Payment Gateway</option>

                            {paymentGateways?.map(p => {
                                return <option key={p.id + p.payment_gateway} value={p.id}>{p.payment_gateway.toUpperCase()}</option>
                            })}
                        </select>

                        <input placeholder='Amount' type='number' min={1} value={amount} onChange={(e) => {
                            const v = e.target.value;
                            if (v && (isNaN(v) || +v < 0)) {

                            } else {
                                setAmount(v)
                            }
                        }} />

                        <button
                          className={[
                            !paymentGateway || !amount || isNaN(amount) 
                            ? Classes2.disabledDraftOrderButton 
                            : Classes2.draftOrderButton,
                            Classes2.Btn
                          ].join(" ")}
                          style={{
                            ...(!paymentGateway || !amount || isNaN(amount)
                              ? {
                                backgroundColor: "#6c757d"
                              } : {
                                backgroundColor: "#6c943eeb"
                              })
                          }}
                          onClick={(e) => {
                            setOpen(true)
                            // sendPaymentLink()
                          }}
                          disabled={!paymentGateway || !amount || isNaN(amount)}
                        >
                          {
                            loading
                              ? "Loading ..."
                              : "Send Payment Link"
                          }
                        </button>

                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default CollectPayment;