import React, { Fragment, useState, useEffect } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';
import SnackBar from '../../../../components/SnackBar';

const CustomerModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [districts, setDistricts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const closeDialog = () => {
        const form = document.getElementById('form');
        if (form) {
            form.reset();
        }
        props.onHideModal();
    }

    const {
        errorMessage,
        reRender,
        setReRender
    } = props;

    const fetchAllDistricts = () => {
        axiosInstance
            .get(`/pincode/getAllDistricts`)
            .then((res) => {
                const districts = res?.data?.data;
                setDistricts(districts);
            }).catch((error) => {
                const errorMessage = error?.response?.data?.message || 'Something went wrong'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const fetchAllCompanies = () => {
        axiosInstance
            .get(`/company/getAllCompanies`)
            .then((res) => {
                const companies = res?.data?.data?.companies;
                const companyNames = companies.map((company) => company.companyName)
                setCompanies(companyNames);
            }).catch((error) => {
                const errorMessage = error?.response?.data?.message || 'Something went wrong'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const button = document.getElementById('btn');
        button.innerHTML = 'Saving...';
      
        const data = {
            "locationCode": event.target.locationCode.value,
            "locationName": event.target.name.value.trim(),
            "locationGstNumber": event.target.gst.value ? [event.target.gst.value] : undefined,
            "status": event.target.locationStatus.value ? event.target.locationStatus.value : undefined,
            "locationType": event.target.locationType.value ? event.target.locationType.value : undefined,
            "district": event.target.district.value ? event.target.district.value : undefined,
            "companyName": event?.target?.companyName?.value || undefined
        };
      
        axiosInstance
            .post("/location/createLocation", { ...data })
            .then((res) => {
                const successMessage = 'Location Created Successfully';
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" };
                });
                closeDialog();
                if (setReRender) { setReRender(!reRender); }
                button.innerHTML = 'Location Created';
            }).catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong';
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" };
                });
            });
      };

    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    useEffect(() => {
        fetchAllDistricts()
        fetchAllCompanies()
    }, []);

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {props.onShowModal && (
                <div className={Classes.modal} style={{ top: '10%', bottom: '17%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Create a new Location</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>
                        <div style={{ padding: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="text"
                                        id='locationName'
                                        name='name'
                                        label='Location Name'
                                        autoComplete='off'
                                        placeholder='Location Name'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        required={true}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="locationStatus"
                                        name="locationStatus"
                                        defaultValue={"Active"}
                                        options={["Active", "In-Active", "Dis-Continued"]}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Location Status"} />
                                        }
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="text"
                                        id='locationCode'
                                        name='locationCode'
                                        label='Location Code'
                                        autoComplete='off'
                                        placeholder='Location Code'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        required={true}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField
                                        required={true}
                                        id='gst'
                                        name='gst'
                                        label='GST'
                                        placeholder='Enter GST No'
                                        autoComplete='off'
                                        inputProps={{ maxLength: 15, minLength: 15 }}
                                        sx={{ width: '100%' }}
                                        // onChange={handleGstField}
                                        onBlur={handleValidGst}
                                        size="small"
                                    />

                                    <div
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="locationType"
                                        name="locationType"
                                        options={["WH", "Store","Head Office" ,"Office", "Franchise"]}
                                        defaultValue="WH"
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Location Type"} />
                                        }
                                        size="small"
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="district"
                                        name="district"
                                        options={districts}
                                        defaultValue={districts[0]}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={"District"} required={true}/>}
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="companyName"
                                        name="companyName"
                                        options={companies}
                                        defaultValue={companies[0]}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Company Name"} required={true}/>
                                        }
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={Classes.DialogBtnBox}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={closeDialog}
                            >
                                CANCEL
                            </button>

                            <button
                                id='btn'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>

                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default CustomerModal;