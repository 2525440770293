import React, { Fragment, useState } from "react";
import Classes from "../orders/css/asideModal.module.css";
import { FiCheckCircle } from 'react-icons/fi';
import { ImCross } from 'react-icons/im';
import axiosInstance from "../../../utility/axios-instance";
import { useNavigate } from 'react-router-dom';



const QcPopup = (props) => {
    const {
        showConfirmPopup,
        bins,
        selectedBin,
        setSelectedBin,
        defaultSaleableBin, lineItem, setLineItem, hideConfirmPopupWindow,
        fetchPurchaseOrder
    } = props;

    const [quantity, setQuantity] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setQuantity(e.target.value);
        validateQuantities();
    }


    // Function to validate the quantities
    const validateQuantities = () => {
        let remainingQuantity = lineItem.quantity - (lineItem.qcFail + lineItem.qcPass);
        if (quantity > remainingQuantity) {
            // setErrorMessage('Total quantity should be below 100');
        } else {
            setErrorMessage(null);
        }
    };


    const handleQCSubmit = (status) => {
        const data = [{
            lineItemId: lineItem.lineItemId,
            quantity: quantity,
            binCode: selectedBin,
            qcStatus: `QC-${status}`
        }]

        axiosInstance
            .post(`/purchaseOrders/qualityCheckLineItemOfPoFulfillments?purchaseOrderName=${lineItem.purchaseOrderName}&fulfillmentName=${lineItem.fulfillmentName}`, data)
            .then((res) => {
                hideConfirmPopupWindow();
                fetchPurchaseOrder();
                setQuantity(0);
                setSelectedBin(defaultSaleableBin);
            })
            .catch((err) => {
                setErrorMessage(err.response?.data?.message);
                setQuantity(0);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2000);
            });
    };


    const handleBinSelect = (e) => {
        setSelectedBin(e.target.value);
    }

    return (
        <Fragment>
            {showConfirmPopup && (
                <div className={Classes.confirmModal} style={{ bottom:'32%',top: '14%' }}>
                    <div style={{ display: 'flex', justifyContent: 'end', padding: '12px 15px 0px 0px' }}>
                        <ImCross style={{ cursor: 'pointer' }} onClick={hideConfirmPopupWindow} />
                    </div>

                    {errorMessage && (
                        <div style={{ color:'red',textAlign:'center',fontSize:12,padding:'0px 10px' }}>
                            {errorMessage}
                        </div>
                    )}

                    <div style={{ textAlign:'center',margin: '10px 0px' }}>
                        <FiCheckCircle size={30} color="blue" />
                    </div>

                    <div style={{ textAlign:'center',fontSize:30 }}>
                        <p>Quality Check</p>
                    </div>

                    <div style={{ display:'flex',justifyContent:'center',gap:'10px',fontWeight:500 }}>
                        <p>{lineItem.sku}</p>
                        <p>({lineItem.fulfillmentName})</p>
                    </div>

                    <div style={{ textAlign:'center',padding:'10px 0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <p>Passed: {lineItem.qcPass ? lineItem.qcPass : 0}</p>
                            <p>Failed: {lineItem.qcFail ? lineItem.qcFail : 0}</p>
                        </div>

                        <p>Remaining: {lineItem.quantity - (lineItem.qcFail + lineItem.qcPass)}</p>
                    </div>

                    <div>
                        <div style={{ display:'flex',flexDirection:'column',gap:'10px',justifyContent:'center',alignItems:'center',padding:10 }} >
                            <div style={{ display:'flex',justifyContent:'center',marginBottom:15 }}>
                                <div style={{ display:'flex',gap:'10px',justifyContent:'center',alignItems:'center' }}>
                                    <input
                                        type="number"
                                        value={quantity}
                                        onChange={(e) => handleChange(e)}
                                        style={{ border: '1px solid #ccc', padding: '5px', borderRadius: '5px', width: '120px' }}
                                    />
                                    <select name="bin" id="bin" defaultValue={defaultSaleableBin} onChange={handleBinSelect}>
                                        <option value="">--Select Bin--</option>
                                        {bins.map((bin) => (
                                            <option value={bin.code}>{bin.code}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                <button type="button" class="btn btn-success" onClick={(e) => handleQCSubmit("Pass")}>
                                    Pass
                                </button>
                                <button type="button" class="btn btn-danger" onClick={(e) => handleQCSubmit("Fail")}>
                                    Fail
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default QcPopup;