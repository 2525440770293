import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	draftInterWarehouseMoves: [],
};

const draftInterWarehouseMovesSlice = createSlice({
	name: 'draftInterWarehouseMoves',
	initialState: initialState,
	reducers: {
		setDraftInterWarehouseMoves: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setDraftInterWarehouseMoves, reset } = draftInterWarehouseMovesSlice.actions;

export default draftInterWarehouseMovesSlice.reducer;