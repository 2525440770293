import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	affiliateData: [],
};

const affiliatesDataSlice = createSlice({
	name: 'affiliates',
	initialState: initialState,
	reducers: {
		setAffiliates: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setAffiliates, reset } = affiliatesDataSlice.actions;

export default affiliatesDataSlice.reducer;