import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	orders: [],
	page: 1,
	maxPage: 1,
	total: 0,
};

const ordersSlice = createSlice({
	name: 'orders',
	initialState: initialState,
	reducers: {
		setOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setOrders, reset } = ordersSlice.actions;

export default ordersSlice.reducer;
