import {Fragment, useState, useEffect} from 'react';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from '../customers/css/customerProfile.module.css';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Autocomplete, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import Spinner from '../../../components/spinner';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TablePagination from '@mui/material/TablePagination';
import { useSelector,useDispatch  } from 'react-redux';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GradeIcon from '@mui/icons-material/Grade';
import PersonPinIcon from '@mui/icons-material/PersonPin';

const UserReportDetails = (props) => {
    const [loading,setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [salesUserDetails,setSalesUserDetails] = useState([]);
    const [toggle,setToggle] = useState(true);

    const [fieldVisitHistory,setFieldVisitHistory] = useState([]);
    const [fieldVisitCount,setFieldVisitCount] = useState(0);
    const [phoneCallsCount,setPhoneCallsCount] = useState(0);
    const [meetingScoreCount,setMeetingScoreCount] = useState(0);
    const [effortScorecard,setEffortScorecard] = useState(0);
    const [monthlySalesAmount,setMonthlySalesAmount] = useState(0);
    const [dateRange,setDateRange] = useState('');
    const [dates,setDates] = useState([]);

    const [uniqueCustomers,setUniqueCustomers] = useState([]);
    const [ordersCustomer,setOrders] = useState([]);

    const breadCrumb = useSelector((state) => state.breadcrumb);

    const { Id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const [salesMonth,setSalesMonth] = useState(monthNames[new Date().getMonth()]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const fetchReportDetails = (monthName,dateRanges) => {
        const month = monthName ? monthName : salesMonth;
        const monthIndex = monthNames.findIndex(months => months === month);
        setLoading(true);
        const startDate = dateRanges ? dateRanges[0]?.$d : dateRange[0]?.$d;
        const endDate = dateRanges ? dateRanges[1]?.$d : dateRange[1]?.$d;
        axiosInstance
            .get(`/fieldSalesVisit/adminUser/${Id}?startDate=${startDate}&endDate=${endDate}&month=${monthIndex + 1}`)
            .then((res) => {
                setToggle(false);
                setMonthlySalesAmount(res.data.data.totalMonthSales);
                setSalesUserDetails(res.data.data);
                setFieldVisitHistory(res.data.data);
                let fieldVisit = 0;
                let phoneCalls = 0;
                let meetingScore = 0;
                const storeUniqueCustomer = [];
                let effortScorecard = 0;
                if (res.data.data.fieldVisit.length !== 0) {
                    for (let visit of res.data.data.fieldVisit) {
                        if (storeUniqueCustomer.length === 0) {
                            storeUniqueCustomer.push(visit.user)
                        } else {
                            const check =  storeUniqueCustomer.some(user => user._id === visit?.user._id);
                            if (!check) {
                                storeUniqueCustomer.push(visit.user)
                            }
                        }
                    }
                }

                axiosInstance
                    .get(`/fieldSalesVisit/adminUser/UniqueCustomers/${Id}?startDate=${startDate}&endDate=${endDate}&month=${monthIndex + 1}`)
                    .then((response) => {
                        if(response.data.data.length !== 0) {
                            fieldVisit = response.data.data.reduce((a, b) => { 
                                return a + b.totalVisits;
                            }, 0);
    
                            phoneCalls = response.data.data.reduce((a, b) => { 
                                return a + b.totalCalls;
                            }, 0);
    
                            meetingScore = response.data.data.reduce((a, b) => { 
                                return a + b.ratings;
                            }, 0);
    
                            effortScorecard = response.data.data.reduce((a, b) => { 
                                return a + b.completed;
                            }, 0);
    
                            setEffortScorecard(effortScorecard);
                            setDates(response.data.data);
                            setMeetingScoreCount(meetingScore)
                            setFieldVisitCount(fieldVisit);
                            setPhoneCallsCount(phoneCalls);

                        }
                        
                        axiosInstance
                            .get(`/fieldSalesVisit/adminUser/CustomersOrders/${Id}?month=${monthIndex + 1}`)
                            .then((respon) => {
                                setOrders(respon.data.data)
                            })
                    })


                dispatch(setBreadcrumb({ ...breadCrumb, salesUser: res.data.data.user.name }));
                setUniqueCustomers(storeUniqueCustomer);
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchReportDetails()
    },[]);

    const todayDate = () => {
        const date =  new Date();
        return date;
    }

    const handleTotal = (total) => {
		let fetchDecimal= countPlaces(total)
        let amount = fetchDecimal === 1 || fetchDecimal === 0 || fetchDecimal === 2 ? total :  Math.trunc(total*100)/100;
		return amount;
	}

	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    const calculatePercentage = (exceedTarget, target) => {
        if((exceedTarget/target)*100 !== 0){
            const percentage =  (exceedTarget/target)*100;
            let fetchDecimal= countPlaces(percentage)
            return fetchDecimal === 1 || fetchDecimal === 0 || fetchDecimal === 2 ? percentage :  Math.trunc(percentage*100)/100
        } else {
            return 0
        }
    }

    const handleChangePage = (event,newPage) => {
        setLoading(true)
        const month = monthNames.findIndex(month => month === salesMonth);
        axiosInstance
            .get(`/fieldSalesVisit/adminUser/${Id}?page=${newPage + 1}&startDate=${dateRange[0]?.$d}&endDate=${dateRange[1]?.$d}&month=${month + 1}`)
            .then((res) => {
                setLoading(false);
                setFieldVisitHistory(res.data.data)
            })
    }


    const handleOrdersChangePage = (event,newPage) => {
        setLoading(true)
        const month = monthNames.findIndex(month => month === salesMonth);
        axiosInstance
            .get(`/fieldSalesVisit/adminUser/CustomersOrders/${Id}?page=${newPage + 1}&month=${month + 1}`)
            .then((respon) => {
                setLoading(false)
                setOrders(respon.data.data)
            })
    }

	return (
		<Fragment>
			<div className={Classes.Container}>
				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
						<Alert.Heading>{error}</Alert.Heading>
						<p>Try reloading!</p>
					</Alert>
				) : null}

                <div className={Classes.mainContainer} style={{display:'block'}}>
                    <div 
                        className={Classes.Boxes} 
                        style={{marginTop:20,marginBottom:20,boxShadow:'none'}}
                    >
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <p style={{fontWeight:600}}>{salesMonth} Month Sales</p>
                            <div className={Classes.SalesMonth}>
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="area"
                                    name='area'
                                    options={[
                                        {label:"January"},{label:"February"},{label:"March"},
                                        {label:"April"},{label:"May"},{label:"June"},
                                        {label:"July"},{label:"August"},{label:"September"},
                                        {label:"October"},{label:"November"},{label:"December"}
                                    ]}
                                    key={salesMonth ? salesMonth : ''}
                                    defaultValue={salesMonth ? salesMonth : ''}
                                    sx={{width: '100%',"& label": {top: salesMonth ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                    onChange={(event, newValue) => {
                                        setSalesMonth(newValue.label)
                                        fetchReportDetails(newValue.label)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Month"/>}
                                />
                            </div>
                        </div>

                        <TableContainer 
                            component={Paper} 
                            className={Classes.hideTable}
                            sx={{marginTop:2, "& .MuiTableContainer-root": {boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"}}}
                        >
                            <Table 
                                sx={{ minWidth: 'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'}}} 
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>This Month Sales</StyledTableCell>
                                        <StyledTableCell>Target Completion</StyledTableCell>
                                        <StyledTableCell>Monthly Target</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell style={{width:'53%'}}>{handleTotal(monthlySalesAmount).toLocaleString()}</StyledTableCell>
                                        <StyledTableCell>
                                            {calculatePercentage(handleTotal(monthlySalesAmount),salesUserDetails?.user?.monthlyTarget ? salesUserDetails?.user.monthlyTarget : 2500000)}%
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {salesUserDetails?.user?.monthlyTarget ? salesUserDetails?.user?.monthlyTarget : 2500000}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className={Classes.responsiveTable}>
                            {fieldVisitHistory?.length !== 0 && (
                                <div className={Classes.responsiveTableBox} style={{marginBottom:0}}>
                                    <div style={{display:'flex'}}>
                                        <p style={{marginRight:5,color: "#6c757d"}}>This Month Sales:</p>
                                        <p style={{fontWeight:600}}>
                                            {handleTotal(monthlySalesAmount).toLocaleString()}
                                        </p>
                                    </div>

                                    <div style={{display:'flex'}}>
                                        <p style={{marginRight:5,color: "#6c757d"}}>Target Completion:</p>
                                        <p>{calculatePercentage(handleTotal(monthlySalesAmount),salesUserDetails?.user?.monthlyTarget ? salesUserDetails?.user?.monthlyTarget : 250000)}%</p>
                                    </div>

                                    <div style={{display:'flex'}}>
                                        <p style={{marginRight:5,color: "#6c757d"}}>Monthly Target:</p>
                                        <p style={{textTransform:'capitalize'}}>{salesUserDetails?.user?.monthlyTarget ? salesUserDetails?.user?.monthlyTarget : 250000}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={Classes.responsiveTable}>
                        {ordersCustomer?.length !== 0 && ordersCustomer?.users?.map((user) =>  (
                            <div className={Classes.responsiveTableBox} style={{marginBottom:0}}>
                                <div style={{display:'flex'}}>
                                    <AccountCircleIcon sx={{marginRight:'5px'}}/>
                                    <p style={{fontWeight:600}}>
                                        {user?.displayName}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Phone:</p>
                                    <p>{user?.phone}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Sum Of Orders:</p>
                                    <p>{user?.totalMonthOrders ? handleTotal(user?.totalMonthOrders).toLocaleString() : 0}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <TableContainer 
                        component={Paper} 
                        className={Classes.hideTable}
                        sx={{marginTop:2, "& .MuiTableContainer-root": {boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"}}}
                    >
                        <Table 
                            sx={{ minWidth: 'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'}}} 
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Customer</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>Sum Of Orders</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ordersCustomer?.length !== 0 && ordersCustomer?.users?.map((user) => (
                                    <StyledTableRow key={user?._id}>
                                        <StyledTableCell style={{width:'53%'}}>{user?.displayName}</StyledTableCell>
                                        <StyledTableCell>{user?.phone}</StyledTableCell>
                                        <StyledTableCell>{user?.totalMonthOrders ? handleTotal(user?.totalMonthOrders).toLocaleString() : 0}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {ordersCustomer?.length !== 0 &&  (
                        <TablePagination
                            // rowsPerPageOptions={[5, 10, 25]}
                            sx={{ 
                                "& .MuiTablePagination-selectLabel": {display:'none'}, 
                                "& .MuiTablePagination-select": {display:'none'},
                                "& .MuiSelect-icon": {display:'none'}
                            }}
                            component="div"
                            count={ordersCustomer?.total}
                            rowsPerPage={10}
                            page={ordersCustomer?.page-1}
                            onPageChange={handleOrdersChangePage}
                            // onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}

                    <div style={{marginTop:40}}>
                        <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                            <p style={{fontWeight:600}}>Visit/Call History</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                    'DateRangePicker',
                                    'MobileDateRangePicker',
                                    'DesktopDateRangePicker',
                                    'StaticDateRangePicker',
                                    ]}
                                >
                                    <DemoItem component="MobileDateRangePicker" >
                                        <MobileDateRangePicker
                                            onChange={(newValue) => {
                                                setDateRange(newValue)
                                                fetchReportDetails(salesMonth,newValue)
                                            }}
                                            format="DD-MM-YYYY"
                                            views={["year", "month", "day"]}
                                            defaultValue={[dayjs(todayDate()), dayjs(todayDate())]}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>

                    <TableContainer 
                        component={Paper} 
                        sx={{marginTop:2, "& .MuiTableContainer-root": {boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"}}}
                        className={Classes.hideTable}
                    >
                        <Table 
                            sx={{  minWidth:'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'}}} 
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Total Scheduled Visits: {fieldVisitHistory.total}</StyledTableCell>
                                    <StyledTableCell>Total Field Visits: {fieldVisitCount}</StyledTableCell>
                                    <StyledTableCell>Total Calls: {phoneCallsCount}</StyledTableCell>
                                    <StyledTableCell sx={{textAlign:'center'}}>
                                        <p>Effort Scorecard: {effortScorecard}/{fieldVisitHistory.total}</p>
                                        <p>(Scheduled vs Completed)</p>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <p>Output Scorecard: {meetingScoreCount}/{fieldVisitHistory.total}</p>
                                        <p>(No. of Green Stars)</p>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dates?.length !== 0 && dates?.map((range,index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{moment(range?.date).format('DD/MM/YYYY')}</StyledTableCell>
                                        <StyledTableCell>{range?.scheduleVisits}</StyledTableCell>
                                        <StyledTableCell>{range?.totalVisits}</StyledTableCell>
                                        <StyledTableCell>{range?.totalCalls}</StyledTableCell>
                                        <StyledTableCell>{range?.completed}</StyledTableCell>
                                        <StyledTableCell>{range?.ratings}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className={Classes.responsiveTable}>
                        <p style={{fontWeight:600,fontSize:16,marginTop:10}}>Date Records</p>
                        {dates?.length !== 0 && (
                            <div className={Classes.responsiveTableBox}>
                                <p>Total Scheduled Visits: {fieldVisitHistory.total}</p>
                                <p>Total Field Visits: {fieldVisitCount}</p>
                                <p>Total Calls: {phoneCallsCount}</p>
                                <p>Effort Scorecard(Scheduled vs Completed): {effortScorecard}/{fieldVisitHistory.total}</p>
                                <p>Output Scorecard(No. of Green Stars): {meetingScoreCount}/{fieldVisitHistory.total}</p>
                            </div>
                        )}
                
                        {dates?.length !== 0 && dates?.map((range,index) => (
                            <div className={Classes.responsiveTableBox} style={{marginBottom:0}} key={index}>
                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Date:</p>
                                    <p style={{fontWeight:600}}>{moment(range?.date).format('DD/MM/YYYY')}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Total Scheduled Visits:</p>
                                    <p>{range?.scheduleVisits}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Total Field Visits:</p>
                                    <p>{range?.totalVisits}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Total Calls:</p>
                                    <p>{range?.totalCalls}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Effort Scorecard(Scheduled vs Completed):</p>
                                    <p>{range?.completed}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Output Scorecard(No. of Green Stars):</p>
                                    <p style={{textAlign:'center'}}>{range?.ratings}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div style={{marginTop:20}} className={Classes.hideTable}>
                        <TableContainer 
                            component={Paper} 
                            sx={{marginTop:2, "& .MuiTableContainer-root": {boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"}}}
                        >
                            <Table 
                                sx={{  minWidth:'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'}}} 
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Mode Of Communication</StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'center'}}>Meeting</StyledTableCell>
                                        <StyledTableCell>Ratings</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fieldVisitHistory?.length !== 0 && fieldVisitHistory?.fieldVisit?.map((customer,index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {moment(customer?.createdAt).format('DD/MM/YYYY')}
                                            </StyledTableCell>
                                            <StyledTableCell>{customer?.user?.displayName}</StyledTableCell>
                                            <StyledTableCell>{customer?.user?.modeOfCommunication}</StyledTableCell>
                                            <StyledTableCell sx={{textAlign:'center'}}>
                                                {customer?.startTime && !customer?.endTime ? (
                                                    <PersonPinIcon sx={{color:'orange',textAlign:'center'}}/> 
                                                ): customer?.endTime ? (
                                                    <TaskAltIcon sx={{color:'green',textAlign:'center'}}/>
                                                ) : (
                                                    customer?.cancellationReason ? (
                                                        <p className={Classes.meetingCancelled}>Cancelled</p>
                                                    ) : (
                                                        !customer?.startTime && (
                                                            <p 
                                                                className={Classes.meetingCancelled} 
                                                                style={{backgroundColor:'yellowgreen'}}
                                                            >Scheduled</p>
                                                        )
                                                    )
                                                )} 
                                            </StyledTableCell>
                                            <StyledTableCell sx={{textAlign:'center'}}>
                                                <GradeIcon sx={{color:customer?.ratings ? customer?.ratings: 'red' }}/>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {fieldVisitHistory?.length !== 0 &&  (
                            <TablePagination
                                // rowsPerPageOptions={[5, 10, 25]}
                                sx={{ 
                                    "& .MuiTablePagination-selectLabel": {display:'none'}, 
                                    "& .MuiTablePagination-select": {display:'none'},
                                    "& .MuiSelect-icon": {display:'none'}
                                }}
                                component="div"
                                count={fieldVisitHistory?.total}
                                rowsPerPage={10}
                                page={fieldVisitHistory?.page-1}
                                onPageChange={handleChangePage}
                                // onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </div>
{/* 
                    <div className={Classes.responsiveTable}>
                        {fieldVisitHistory?.length !== 0 && (
                            <div className={Classes.responsiveTableBox} style={{marginBottom:0}}>
                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Date:</p>
                                    <p style={{fontWeight:600}}>
                                        {dateRange? convertDate(dateRange[0]?.$d): currentDate()}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Total Scheduled Visits:</p>
                                    <p>{fieldVisitHistory.total}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Total Visits:</p>
                                    <p style={{textTransform:'capitalize'}}>{fieldVisitCount}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Total Phone Calls:</p>
                                    <p>{phoneCallsCount}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Meeting Scorecard:</p>
                                    <p>{meetingScoreCount}/{fieldVisitHistory.total}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Task Scorecard:</p>
                                    <p style={{textAlign:'center'}}>
                                        {fieldVisitCount + phoneCallsCount}/{fieldVisitHistory.total}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div> */}

                    {/* <div className={Classes.Boxes} style={{marginTop:20,padding:20}}> */}
                    <div style={{display:'flex',justifyContent:'space-between',margin:'20px 0px'}}>
                        <p style={{fontWeight:600}}>Customers</p>
                    </div>

                    <div>
                        <TableContainer 
                            component={Paper} 
                            sx={{marginTop:2, "& .MuiTableContainer-root": {boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"}}}
                            className={Classes.hideTable}
                        >
                            <Table 
                                sx={{  minWidth:'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'}}} 
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Mode Of Communication</StyledTableCell>
                                        <StyledTableCell>Order value of {monthNames[new Date().getMonth()]}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uniqueCustomers?.length !== 0 && uniqueCustomers?.map((customer,index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{customer?.displayName}</StyledTableCell>
                                            <StyledTableCell>{customer?.modeOfCommunication}</StyledTableCell>
                                            <StyledTableCell>{customer?.totalMonthSales}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div className={Classes.responsiveTable}>
                        {fieldVisitHistory?.length !== 0 && fieldVisitHistory?.fieldVisit?.map((customer,index) => (
                            <div 
                                key={customer?._id}   
                                className={Classes.responsiveTableBox}
                                style={{marginTop: index === 0 ? 0 : 15}}
                            >
                                <div style={{display:'flex'}}>
                                    <AccountCircleIcon sx={{marginRight:'5px'}}/>
                                    <p style={{fontWeight:600}}>
                                        {customer?.user?.displayName}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Date:</p>
                                    <p>{moment(customer?.createdAt).format('DD/MM/YYYY')}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Mode Of Communication:</p>
                                    <p style={{textTransform:'capitalize'}}>
                                        {customer?.user?.modeOfCommunication}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Meeting:</p>
                                    <p>
                                        {customer?.startTime && !customer?.endTime ? (
                                            <PersonPinIcon sx={{color:'orange',textAlign:'center'}}/> 
                                        ): customer?.endTime ? (
                                            <TaskAltIcon sx={{color:'green',textAlign:'center'}}/>
                                        ) : (
                                            customer?.cancellationReason ? (
                                                <p className={Classes.meetingCancelled}>Cancelled</p>
                                            ) : (
                                                !customer?.startTime && (
                                                    <p 
                                                        className={Classes.meetingCancelled} 
                                                        style={{backgroundColor:'yellowgreen'}}
                                                    >Scheduled</p>
                                                )
                                            )
                                        )} 
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Rating:</p>
                                    <p><GradeIcon sx={{color:customer?.ratings ? customer?.ratings: 'red' }}/></p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {fieldVisitHistory?.length !== 0 &&  (
                        <TablePagination
                            // rowsPerPageOptions={[5, 10, 25]}
                            sx={{ 
                                "& .MuiTablePagination-selectLabel": {display:'none'}, 
                                "& .MuiTablePagination-select": {display:'none'},
                                "& .MuiSelect-icon": {display:'none'}
                            }}
                            component="div"
                            count={fieldVisitHistory?.total}
                            rowsPerPage={10}
                            page={fieldVisitHistory?.page-1}
                            onPageChange={handleChangePage}
                            // onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )} 
                    {/* </div> */}
                </div>  

                {loading ? <Spinner /> : null}
            </div>
		</Fragment>
	)				
};

export default UserReportDetails;
