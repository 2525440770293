import React, {Fragment, useState} from "react";
import Classes from "./css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, Button, TextField } from '@mui/material';

const DiscountPopup = (props)=> {
    const [discountType,setDiscountType] = useState('Amount');
    const [discountAmount,setDiscountAmount] = useState('0.00');
    const [reason,setReason] = useState('');

    const {
        discountModal,
        setTaxLines,
        setTotalTaxAmount,
        hideDiscountPopup,
        productDetails,
        setProduct,
        setOverAllDiscount,
        overAllDiscount,
        shippingPrice,
        setLoadingSalesOrderCalculationServiceState,
        overallSubtotal
    } = props;

    const handleSubmit = (event) => {

        event.preventDefault();
        hideDiscountPopup();
        let discount = event.target.discountType.value;

        const appliedDiscount = {
            value: JSON.parse(event.target.discountAmount.value),
            valueType: discount === 'Amount' ? "FIXED_AMOUNT" : "PERCENTAGE",
            title: event.target.reason.value
        }
        
        const data = {
            productDetails: productDetails,
            discount: appliedDiscount,
            shippingLine: shippingPrice.length !== 0 ? shippingPrice[0] : undefined
        }

        setOverAllDiscount(appliedDiscount);

        setLoadingSalesOrderCalculationServiceState(true)
        const salesOrderCalculationServiceEndpoint = '/product/fetchProductDetails/bulk';
        axiosInstance
            .post(salesOrderCalculationServiceEndpoint, { ...data })
            .then((res) => {
                const calculatedResponse = res?.data?.product
                if (calculatedResponse?.TaxLines) {
                    setTaxLines(calculatedResponse?.TaxLines?.structuredTaxLines)
                    setTotalTaxAmount(calculatedResponse?.TaxLines?.totalTaxAmount)
                }
                setProduct(res.data.product);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                let calculateSalesOrderRoutePendingCount = localStorage.getItem(salesOrderCalculationServiceEndpoint);
                calculateSalesOrderRoutePendingCount = calculateSalesOrderRoutePendingCount ? parseInt(calculateSalesOrderRoutePendingCount) : 0;
                if (calculateSalesOrderRoutePendingCount === 0) {
                    setLoadingSalesOrderCalculationServiceState(false)
                }
            });
    }

    const isInputDiscountIncorrect = () => {
      if (
        discountAmount < 0 
        || (discountAmount > 100 && discountType === "Percentage")
        || (discountAmount > overallSubtotal && discountType === "Amount")
      ) {
        return true;
      }
      return false;
    };

    const errorMessageOnIncorrectDiscount = () => {
        if (discountAmount < 0) {
            return "Discount Can't be Negative";
        }
        if (discountAmount > 100 && discountType === "Percentage") {
            return "Discount Must be less than 100%";
        }
        if (discountAmount > overallSubtotal && discountType === "Amount") {
            return "Discount Must be less than Subtotal";
        }
    }

    return (
      <Fragment>
        {discountModal && (
            <div className={Classes.modal} style={{bottom:'36%',top:'16%'}}>
                <div className={Classes.BoxContent}>
                    <p>Add Discount</p>
                </div>
                
                <form method="post" onSubmit={handleSubmit} id='discountForm'>
                    <div style={{ padding:'30px 20px',overflowY:'auto',height:200 }}>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{width:'48%'}}>
                                {overAllDiscount?.length !== 0 ? (
                                    <div>
                                        <Autocomplete
                                            disablePortal
                                            id="discountType"
                                            name="discountType"
                                            key={overAllDiscount.valueType ===  "FIXED_AMOUNT" ? "Amount": "Percentage"}
                                            defaultValue={overAllDiscount.valueType ===  "FIXED_AMOUNT" ? "Amount": "Percentage"}
                                            options={["Amount","Percentage"]}
                                            sx={{width: '100%',"& label": {top: discountType ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => { setDiscountType(newValue) }}
                                            renderInput={(params) => <TextField {...params} label="Discount Type"/>}
                                        />
                                    </div>
                                ):(
                                    <Autocomplete
                                        disablePortal
                                        id="discountType"
                                        name="discountType"
                                        options={["Amount","Percentage"]}
                                        key={"Amount"}
                                        defaultValue={"Amount"}
                                        sx={{width: '100%',"& label": {top: discountType ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => { setDiscountType(newValue) }}
                                        renderInput={(params) => <TextField {...params} label="Discount Type"/>}
                                    />
                                )}
                            </div>

                            <div style={{width:'48%'}}>
                                <TextField 
                                    id='discountAmount'
                                    name="discountAmount"
                                    label="Discount Amount"
                                    error = {isInputDiscountIncorrect()}
                                    helperText={errorMessageOnIncorrectDiscount()}
                                    onChange={(event) => setDiscountAmount(event.target.value)}
                                    key={overAllDiscount.length !== 0 ? overAllDiscount.value : '0.00'}
                                    defaultValue={overAllDiscount.length !== 0 ? overAllDiscount.value : '0.00'}
                                    placeholder='Please Enter Discount'
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top: discountAmount ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />
                            </div> 
                        </div>

                        <div style={{marginTop:20}}>
                            <TextField 
                                id='reason'
                                name="reason"
                                label="Reason"
                                onChange={(event) => setReason(event.target.value)}
                                key={overAllDiscount.length !== 0 ? overAllDiscount.title : ''}
                                defaultValue={overAllDiscount.length !== 0 ? overAllDiscount.title : ''}
                                autoComplete='off'
                                sx={{ width: '100%',"& label": {top: reason ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                            />
                        </div>
                    </div>

                    <div className={Classes.DialogBtnBox} style={{bottom:'36%'}}>
                        <Button 
                            type="button" 
                            onClick={hideDiscountPopup}
                            variant="text"
                        >
                            CANCEL
                        </Button>

                        <Button 
                            id='btn' 
                            type="submit" 
                            variant="text"
                            disabled={isInputDiscountIncorrect()}
                        >
                            APPLY
                        </Button>
                    </div>
                </form>
            </div>
        )}
      </Fragment>
    );
}


export default DiscountPopup;