import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	orders: [],
};

const draftOrdersSlice = createSlice({
	name: 'draftOrders',
	initialState: initialState,
	reducers: {
		setDraftOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
		setDraftOrder: (state, action) => {
			const draftOrder = action.payload;
			const index = state.draftOrders.findIndex(
				(e) => e._id === draftOrder._id
			);
			if (index !== -1) {
				state.draftOrders[index] = draftOrder;
			}
		},
	},
});

export const { setDraftOrders, reset, setDraftOrder } =
	draftOrdersSlice.actions;

export default draftOrdersSlice.reducer;
