export default class JsonCSVExporter {
    constructor (jsonData) {
        this.jsonData = jsonData;
    }

    convertToCSV () {
        const lines = [];
        const numCols = this._findMaxColumnCount();
        const headers =  Object.keys(this.jsonData.find((row) => Object.keys(row).length === numCols));
        lines.push(headers.map(head=>JsonCSVExporter.parseCell(head)).join(","))

        this.jsonData.forEach((row)=>{
            let line = "";
            for(let i=0;i<numCols;i++){
                line += row[headers[i]] !== undefined ? JsonCSVExporter.parseCell(row[headers[i]]) : ""
                line += (i !== (numCols - 1)) ? "," : "";
            }
            lines.push(line);
        })
        
        return lines.join("\n");
    }

    _findMaxColumnCount () {
        return this.jsonData.reduce((count, row) => Object.keys(row).length > count ? count=Object.keys(row).length : count, 0);
    }



    static parseCell (cell) {
        
        let parsedValue = cell;

        // Parse any data type other than string to string
        parsedValue = String(parsedValue)

        // Replace all double quotes with two double quotes
        parsedValue = parsedValue.replace(/"/g, `""`);

        // If value contains comma, new-line or double-quote, enclose in double quotes
        parsedValue = /[",\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue;

        return parsedValue;
    }
}

