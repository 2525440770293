import React, { useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import axiosInstance from "../../../utility/axios-instance";
import MultipleSelectChip from "../../../components/MultiSelect/MultipleSelectChip";
import FormDialog from "../../../components/FormDialog/FormDialog";

const Sellers = (props) => {
    const { sellers, adminUser } = props
    const [rows, setRows] = React.useState(sellers)
    const [fetchedSellers, setFetchedSellers] = React.useState(null)
    const [selectedSellers, setSelectedSellers] = React.useState([])
    const [response, setResponse] = React.useState(null);
    const [selectedOptionsAdv, setSelectedOptionsAdv] = React.useState([])
    let formData = selectedOptionsAdv.map(({ id, value }) => { return { id: id, default: false, sellerName: value } })
    useEffect(() => {
        sellers?.length > 0 && setSelectedSellers(sellers?.map((seller) => {
            return seller?.sellerName
        }))
        sellers?.length > 0 && setSelectedOptionsAdv(sellers?.map((seller) => {
            return { id: seller?.id, value: seller?.sellerName }
        }))
    }, [])
    const findSellers = () => {
        axiosInstance
            .get(`/seller/fetchAllSellers`)
            .then((response) => {
                const responseData = response?.data?.data
                setFetchedSellers(responseData)
            });
    }
    const updateSellerAsDefault = (sellers) => {
        const payload = { sellers }
        axiosInstance
            .post(`/admin/${adminUser?._id}`, payload)
            .then((response) => {
                const responseData = response?.data?.data
                setRows(responseData?.sellers)
            });
    }
    useEffect(() => {
        findSellers()
    }, [])

    useEffect(() => {
        if (response) {
            setRows(response?.sellers)
        }
    }, [response])

    const setAsDefaultSeller = (event, key) => {
        const sellers = [...rows]
        for (let seller of sellers) {
            seller.default = false
        }
        for (let seller of sellers) {
            if (seller?.id === key) {
                seller.default = !seller?.default
            }
        }
        updateSellerAsDefault(sellers)
    }

    const OptionValues = fetchedSellers?.map(({ sellerName, id }) => { return { id: id, value: sellerName } });

    return (
        <>
            <div className="form-group">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label htmlFor="Sellers">Seller</label>
                    <FormDialog
                        dialogTitle={"Add Sellers"}
                        openDialogButtonName={"Add Sellers"}
                        submitDialogButtonName={"Submit"}
                        dialogActionType={"create"}
                        endPoint={`/admin/${adminUser?._id}`}
                        formData={{ sellers: formData }}
                        setData={setResponse}
                        width={'500px'}
                        height={'500px'}
                    >
                        {fetchedSellers &&
                            <MultipleSelectChip label={"Select Sellers"} selectedOptions={selectedSellers} setSelectedOptions={setSelectedSellers} OptionValues={OptionValues} selectedOptionsAdv={selectedOptionsAdv} setSelectedOptionsAdv={setSelectedOptionsAdv} />
                        }
                    </FormDialog>
                </div>
                {(rows?.length > 0) &&
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: '50%' }} size="small" aria-label="simple table">
                            <TableHead sx={{ backgroundColor: '#243750' }}>
                                <TableRow>
                                    <TableCell style={{ color: "white" }}>Seller</TableCell>
                                    <TableCell align="right" style={{ color: "white" }}>Default</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows && rows?.map((row) => (
                                    <TableRow
                                        key={row?.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row?.sellerName}
                                        </TableCell>
                                        <TableCell align="right" style={{cursor:'pointer'}} onClick={(event) => { setAsDefaultSeller(event, row?.id) }}>{row?.default === true ? <CheckBoxIcon sx={{ color: '#ff6200' }} /> : <DisabledByDefaultIcon sx={{ color: '#243750' }} />}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
        </>
    )
}

export default Sellers;