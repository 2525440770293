import React, { Fragment, useState } from "react";
import Classes from "../orders/css/asideModal.module.css";
import { FiCheckCircle } from 'react-icons/fi';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { ImCross } from 'react-icons/im';


const QcPopupProcurement = (props) => {
    const {
        showConfirmPopup,
        handleQcRejectCheck,
        handleQcApproveCheck,
        bins,
        selectedBin,
        setSelectedBin,
        show, setShow, hidePopup
    } = props;



    const handleBinSelect = (e) => {
        setSelectedBin(e.target.value);
    }

    return (
        <Fragment>
            {showConfirmPopup && (
                <div className={Classes.confirmModal} style={{ bottom:'32%',top:'14%' }}>
                    <div style={{ display: 'flex', justifyContent: 'end', padding: '12px 15px 0px 0px' }}>
                        <ImCross style={{ cursor: 'pointer' }} onClick={hidePopup} />
                    </div>

                    <div style={{ textAlign:'center',margin:'30px 0px' }}>
                        <FiCheckCircle className={Classes.XMark} style={{ color:'rgb(211, 47, 47)'}}/>
                    </div>

                    <div style={{ textAlign:'center',fontSize:30 }}>
                        <p>Quality Check</p>
                    </div>

                    <div style={{ textAlign:'center',padding: '10px 0px' }}>
                        <p>??</p>
                    </div>

                    {show && (
                        <div>
                            <div style={{ display:'flex',gap:'10px',justifyContent:'center',alignItems:'center',padding:10 }}>
                                <select name="bin" id="bin" onChange={handleBinSelect}>
                                    <option value="">--Select Bin--</option>
                                    {bins.map((bin) => (
                                        <option value={bin.code}>{bin.code}</option>
                                    ))}
                                </select>

                                <button type="button" class="btn btn-success" onClick={(e) => handleQcApproveCheck(selectedBin)}>
                                    Confirm
                                </button>

                            </div>
                            <div style={{ display:'flex',justifyContent:'center',alignItems:'center',marginTop:10 }}>
                                <IoArrowBackCircleSharp style={{ cursor: 'pointer' }} size={30} onClick={() => {
                                    setShow(false);
                                }} />
                            </div>
                        </div>

                    )}


                    {!show && (
                    <div
                        className={Classes.Button}
                        style={{ display:'flex',justifyContent:'space-between',padding:15,margin:'0px auto',width:290 }}
                    >
                        <div>
                            <button
                                id='btn'
                                type="submit"
                                onClick={(e) => setShow(true)}
                                // onClick={(e) => hideConfirmPopup(e, orderId)}
                                style={{ backgroundColor: 'green' }}
                                className={Classes.CancelButton}
                            >
                                Pass
                            </button>
                        </div>

                        <div>
                            <button
                                id='btn'
                                type="submit"
                                onClick={(e) => handleQcRejectCheck(e)}
                                style={{ backgroundColor: 'red' }}
                                className={Classes.CancelButton}
                            >
                                Fail
                            </button>
                        </div>
                    </div>)}
                </div>
            )}
        </Fragment>
    );
}


export default QcPopupProcurement;