import { useState, Fragment, forwardRef } from 'react';
import Classes from '../../customers/css/customer.module.css';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import SchemePopup from './schemePopup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { services } from '../../../../utility/checkRoleBasedPermission';
import Permission from '../../../auth/permissions';
import { useSelector } from 'react-redux';
import { setAllSchemes } from '../../../../store/allSchemesSlice.js';
import Table from "../../../../components/table/table";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Schemes = (props) => {
    const [reRender, setReRender] = useState(false);
    const [popup, setPopup] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [schemeId, setSchemeId] = useState(null);
    const [operator, setOperator] = useState('custom');
    const [value, setValue] = useState(null);
    const [skus, setSkus] = useState([]);
    const [unit, setUnit] = useState('Value');
    const [target, setTarget] = useState(null);
    const [name, setName] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const allSchemes = useSelector((state) => state.allSchemes);

    const columns = [
        { 
            field: 'scheme_name', 
            flex: 1.5, 
            headerName: 'NAME', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'sku', 
            flex: 2, 
            headerName: 'SKU', 
            headerClassName: 'super-app-theme--header', 
            filterable: false, sortable: false,
            renderCell: (params) => {
                let arr = params?.value
                return Array.isArray(arr) && (
                    <div>
                        {arr?.slice(0, 1)?.map((user, index) => (
                            <Chip key={index} label={user} style={{ margin: '3px' }} />
                        ))}
                        {arr?.length > 1 ? (
                            <Tooltip 
                                title={<div>{arr?.slice(1)?.map((service, index) => (<p>{service},</p>))}</div>} 
                                arrow
                            >
                                <Chip size="small" label={`+ ${arr?.length - 1} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                            </Tooltip>
                        ) : null}
                    </div>
                )
            }
        },
        { 
            field: 'unit', 
            flex: 1, 
            headerName: 'UNIT', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'minimum_target', 
            flex: 1, 
            headerName: 'MIN. TARGET', 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            renderCell: (params) => (
                <div>{params?.row?.unit === "Value"? `₹${params?.value}`: params?.value}</div>
            )
        },
        { 
            field: 'createdAt', 
            filterable: false, 
            headerName: 'DATE', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.scheme} permission={"update"}>
                    <Button size='small' color='success' variant="contained" 
                    onClick={(e) => showUpdatePopup(params?.row)}>Update</Button>
                </Permission>
            )
        },
    ];

    const showCreatePopup = () => {
        setPopup(true);
    }

    const showUpdatePopup = (scheme) => {
        setSchemeId(scheme.id);
        setName(scheme.scheme_name);
        setTarget(scheme.minimum_target);
        setUnit(scheme.unit);
        setSkus(scheme.sku);
        setValue(null);
        setOperator('custom');
        setUpdatePopup(true);
    }

    const hideUpdatePopup = () => {
        setName(null);
        setTarget(null);
        setUnit('Value');
        setSkus([]);
        setValue(null);
        setOperator('custom');
        setSchemeId(null);
        setUpdatePopup(false);
    }

    const hideCreatePopup = () => {
        setName(null);
        setTarget(null);
        setUnit('Value');
        setSkus([]);
        setValue(null);
        setOperator('custom');
        setPopup(false);
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    return (
        <Fragment>
            <Permission service={services} permission={"create"}>
                <div style={{ display:'flex',justifyContent:'end',marginBottom:10,marginTop:-25 }} className={Classes.inventoryTabHeader}>
                    <Button variant="contained" onClick={(e) => showCreatePopup(e)}>Create</Button>
                </div>
            </Permission>

            <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            <div style={{ width: '100%' }}>
                <Table
                    columns={columns}
                    dataPosition={"allSchemes"}
                    endpoint={"/scheme/schemes"}
                    rowId={'scheme_id'}
                    checkBoxSelection={false}
                    version="version-1"
                    // navigateOnRowClickEndpoint={`/myCustomers`}
                    // reRender={reRender}
                    service={'schemes'}
                    tabName={'Schemes'}
                    setData={setAllSchemes}
                    data={allSchemes}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/schemes'}
                    exportFileName={`Schemes - ${new Date()}`}
                    searchKey={'searchText'}
                />
            </div>

            {/* this is for create new scheme */}
            <SchemePopup
                open={popup}
                handleClose={hideCreatePopup}
                operator={operator}
                setOperator={setOperator}
                value={value}
                setValue={setValue}
                unit={unit}
                setUnit={setUnit}
                target={target}
                setTarget={setTarget}
                name={name}
                setName={setName}
                skus={skus}
                setSkus={setSkus}
                reRender={reRender}
                setReRender={setReRender}
                setSnackBar={setSnackBar}
                type={'create'}
            />

            {/* this for update and delete scheme */}
            <SchemePopup
                open={updatePopup}
                handleClose={hideUpdatePopup}
                operator={operator}
                setOperator={setOperator}
                value={value}
                setValue={setValue}
                unit={unit}
                setUnit={setUnit}
                target={target}
                setTarget={setTarget}
                name={name}
                setName={setName}
                skus={skus}
                setSkus={setSkus}
                reRender={reRender}
                setReRender={setReRender}
                setSnackBar={setSnackBar}
                type={'edit'}
                id={schemeId}
            />
        </Fragment>
    );
};

export default Schemes;