import { useState, useEffect } from 'react';

import { Autocomplete, TextField, Button } from '@mui/material';
import { Close} from '@mui/icons-material';

import axiosInstance from '../../../utility/axios-instance';

const SelectLocationCode = (props) => {
    const { onClose, updatePrice } = props;

        const [loading, setLoading] = useState(false);
      const [locationCodesSelect, setLocationCodesSelect] = useState(['']);
      const [locationCode, setLocationCode] = useState(null);

    const fetchLocationCodes = async () => {
        setLoading(true);
    axiosInstance.get(`/location/fetchAllLocations`, {
      params: {
        sort: {"field":"createdAt","sort":"desc"},
        limit: 1000
      }
    }).then(res => {
      // console.log(res.data.data.fetchedLocations);
      setLocationCodesSelect([...res.data.data.fetchedLocations]);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchLocationCodes();
  }, []);

  const submit = async () => {

    updatePrice(locationCode);
    onClose();
  }

  return (
    <div onClick={(e) => {
        e.preventDefault();
    }} style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '.5rem 1rem',
        maxWidth: '90%',
        width: '500px'
    }}>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <h5>Select Location Code</h5>

            <Close style={{cursor: 'pointer'}} onClick={() => onClose()} />
        </div>

        <form className='mt-2' onSubmit={submit}>
            <Autocomplete id="location-codes" value={locationCode}
          onChange={(e, v) => setLocationCode(v)}
          options={locationCodesSelect.map(e => {
            return e.locationCode;
          })}
          renderInput={(params) => <TextField {...params} label="Location Codes" />}
          disabled={loading}
           />

           <div className='mt-2' style={{
            display: 'flex',
            justifyContent: 'center'
           }}>
            <Button variant='contained' type='button' onClick={() => submit()}>Submit</Button>
           </div>
        </form>
    </div>
  )
}

export default SelectLocationCode