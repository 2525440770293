import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fieldVisit: [],
};

const myTasksSlice = createSlice({
	name: 'myTasks',
	initialState: initialState,
	reducers: {
		setMyTasks: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setMyTasks, reset } = myTasksSlice.actions;

export default myTasksSlice.reducer;