import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog({open, handleClose, handleSubmit, dialogTitle, dialogContent, submitButtonTitle, children, width, hideButton}) {
  
  return (
    <div>
      <Dialog 
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{"& .MuiDialog-paper": {maxWidth:width}}}
      >
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        <DialogContent>
          {
            dialogContent &&
            <DialogContentText>
              {dialogContent}
            </DialogContentText>
          }
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {!hideButton && (
            <Button onClick={handleSubmit}>{submitButtonTitle ? submitButtonTitle : "Submit"}</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}