import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedInventoryMoveHistories: [],
};

const inventoryMovesSlice = createSlice({
	name: 'inventoryMovesHistory',
	initialState: initialState,
	reducers: {
		setInventoryMovesHistory: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setInventoryMovesHistory, reset } = inventoryMovesSlice.actions;

export default inventoryMovesSlice.reducer;