import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';


const ShippingDetailsComponent = (props) => {
    const { 
        shippingDetails,
        handleShippingChange,
        handleShippingPrice,
        inputRef
    } = props;

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            <div className={Classes.Box} style={{padding:'1.1rem'}}>
                <h6 style={{ marginBottom:15 }}>Shipment Details</h6>
                <div style={{ marginBottom: '10px' }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Expected Delivery Date
                    </p>
                    <input
                        type="date"
                        id="deliveryDate"
                        name="deliveryDate"
                        value={shippingDetails?.estimatedReceivingDate ? shippingDetails?.estimatedReceivingDate?.slice(0, 10) : ''}
                        onChange={(e) => handleShippingChange('estimatedReceivingDate', e)}
                        min={new Date().toISOString().slice(0, 10)}
                        className="form-control"
                    />
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Expected Dispatch Date
                    </p>
                    <input
                        type="date"
                        id="dispatchDate"
                        name="dispatchDate"
                        value={shippingDetails?.estimatedDispatchDate ? shippingDetails?.estimatedDispatchDate?.slice(0, 10) : ''}
                        onChange={(e) => handleShippingChange('estimatedDispatchDate', e)}
                        min={new Date().toISOString().slice(0, 10)}
                        className="form-control"
                    />
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Transporter Type
                    </p>
                    <select
                        name='transporterType'
                        defaultValue={'DEFAULT'}
                        id='transporterType'
                        value={shippingDetails?.transporterType ? shippingDetails?.transporterType : ''}
                        className={Classes.select}
                        onChange={(e) => handleShippingChange('transporterType', e)}
                    >
                        <option value='DEFAULT'>Select Transporter Type</option>
                        <option value='self'>Self-Pickup</option>
                        <option value='logistic'>Logistic Company</option>
                        <option value='local'>Local Delivery</option>
                    </select>
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Transporter Name
                    </p>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="transporterName" 
                        name="transporterName"
                        onChange={(e) => handleShippingChange('transporterName', e)}
                        value={shippingDetails?.transporterName ? shippingDetails?.transporterName : ''}
                        placeholder="Transporter Name" 
                    />
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Freight Charges
                    </p>
                    <input 
                        type="number" 
                        step="any" 
                        className="form-control" 
                        id="freightCharges"
                        onKeyDown={preventNegativeValues}
                        value={shippingDetails?.freightCharges ? shippingDetails?.freightCharges : ''}
                        onChange={(e) => handleShippingChange('freightCharges', e)}
                        onBlur={(e) => handleShippingPrice(e)} ref={inputRef}
                        name="freightCharges" placeholder="Freight Charges (if any)" 
                    />
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Bilty Number
                    </p>
                    <input 
                        type="text" 
                        step="any" 
                        className="form-control" 
                        id="biltyNumber"
                        value={shippingDetails?.biltyNumber ? shippingDetails?.biltyNumber : ''}
                        onChange={(e) => handleShippingChange('biltyNumber', e)}
                        name="biltyNumber" placeholder="Bilty Number (if any)" 
                    />
                </div>

                <div style={{ marginBottom:10 }}>
                    <p style={{ margin:'auto 0px 8px',fontSize:13,fontWeight:600 }}>
                        Bilty Date
                    </p>
                    <input
                        type="date"
                        id="biltyDate"
                        name="biltyDate"
                        value={shippingDetails?.biltyDate ? shippingDetails?.biltyDate?.slice(0, 10) : ''}
                        onChange={(e) => handleShippingChange('biltyDate', e)}
                        min={new Date().toISOString().slice(0, 10)}
                        className="form-control"
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default ShippingDetailsComponent;