import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Switch } from '@mui/material';
import { Add } from '@mui/icons-material';
import Classes from './dynamicBanners.module.css';
import axiosInstance from "../../../utility/axios-instance";
import { setDynamicBanners } from "../../../store/dynamicBannersSlice";
import { useSelector } from 'react-redux';
import Table from "../../../components/table/table";

const DynamicBanners = () => {
  const navigate = useNavigate();
  const [reRender, setReRender] = useState(false);
  const dynamicBanners = useSelector((state) => state.dynamicBanners);

  const update = (id, active) => {
    axiosInstance
      .post(`/adminRoutes/dynamicBanners/${id}`, {active})
      .then(res => {
        setReRender(!reRender);
      }).catch(err => {}).catch((err) => {
        console.log(err,'err')
      });
  }

  const columns = [
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "link",
      headerName: "LINK",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "active",
      headerName: "ACTIVE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <Switch 
          checked={params?.row?.active} 
          onClick={(e) => { 
            e.stopPropagation();
            update(params?.value?.id, !params?.row?.active);
          }} 
        />
      )
    },
  ];

  return (
    <Fragment>
      <div className={Classes.UsersHead}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          startIcon={<Add />}
          onClick={() => navigate("/settings/website/dynamicBanners/new")}
        >
          Add New
        </Button>
      </div>

      <Table
				columns={columns}
				dataPosition={"dynamicBanners"}
				endpoint={`/adminRoutes/dynamicBanners`}
				rowId={'id'}
				checkBoxSelection={false}
				version="version-1"
				service={'dynamicBanners'}
				tabName={'Dynamic Banners'}
				navigateOnRowClickEndpoint={`/settings/website/dynamicBanners`}
				setData={setDynamicBanners}
				data={dynamicBanners}
				mobileResponsive={true}
				exportEndpoint={'/exports/dynamicBanners'}
				exportFileName={`Dynamic Banners - ${new Date()}`}
			/>
    </Fragment>
  )
}

export default DynamicBanners