import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedReturns: [],
};

const qcPurchaseOrdersSlice = createSlice({
	name: 'qcPurchaseOrders',
	initialState: initialState,
	reducers: {
		setQCPurchaseOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setQCPurchaseOrders, reset } = qcPurchaseOrdersSlice.actions;

export default qcPurchaseOrdersSlice.reducer;