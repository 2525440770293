import React from "react";
import "./uploadOthersMaster.css";
import axiosInstance from "../../../utility/axios-instance";
import resetState from '../../../store/actions';
import { useDispatch } from 'react-redux';
import { toTitleCase } from "../../../utility/textFormatting";
import { downloadCSV } from "../../../utility/downloadCSV";

export default function OthersFormSection(props) {
    const dispatch = useDispatch();
    const { formName, fileName, endpoint, templateDownloadEndpoint,
        exportEndpoint, handleSuccess, handleError, setExportedData } = props;
    const [loading, setLoading] = React.useState(false);

    const handleImport = (event) => {
        event.preventDefault();
        setLoading(true);
        if (setExportedData) {
            setExportedData(null);
        }
        handleSuccess(null);
        const formdata = new FormData();
        const selectedFile = document.querySelector(`#${formName}`).files[0]

        formdata.append(
            `${fileName}`,
            selectedFile
        );

        axiosInstance
            .post(`${endpoint}`, formdata)
            .then((result) => {
                handleSuccess(`${toTitleCase(formName)} Csv Updated Successfully`);
                if (setExportedData) {
                    setExportedData(result?.data?.data?.exportedResponse);
                }
                setTimeout(() => {
                    handleSuccess(null);
                }, 2000);
            })
            .catch((error) => {
                handleError(error?.response?.data?.message);
                if (error.response.status === 401) {
                    setTimeout(() => {
                        resetState(dispatch);
                    }, 1000);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleExport = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`${exportEndpoint}`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
	            downloadCSV(arrayOfRowObjects,`${formName} export - ${new Date()}`);
                handleSuccess(`${toTitleCase(formName)} file Exported Successfully`)
            })
            .catch((error) => {
                console.log(error)
                handleError(error?.response?.data?.message);
                if (error?.response?.status === 401) {
                    setTimeout(() => {
                        resetState(dispatch);
                    }, 1000);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleTemplateDownload = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`${templateDownloadEndpoint}`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
	            downloadCSV(arrayOfRowObjects,`${formName} template - ${new Date()}`);
                handleSuccess(`${toTitleCase(formName)} Template Downloaded Successfully`)
            })
            .catch((error) => {
                handleError(error.response.data.message);
                if (error.response.status === 401) {
                    setTimeout(() => {
                        resetState(dispatch);
                    }, 1000);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <form className="file-input" name={formName}>
                <div className="mb-3">
                    <label htmlFor={formName} className="form-label">Upload {toTitleCase(formName)}</label>
                    <input className="form-control" type="file" id={formName} />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary mb-2"
                    onClick={handleImport}
                >
                    {!loading ? (
                        "Submit"
                    ) : "..."}
                </button>
                {templateDownloadEndpoint ? <button
                    className="btn btn-primary mb-2 mx-2"
                    onClick={handleTemplateDownload}
                >
                    {!loading ? (
                        "Download Template"
                    ) : "..."}
                </button> : ""}
                {exportEndpoint ? <button
                    className="btn btn-primary mb-2"
                    onClick={handleExport}
                >
                    {!loading ? (
                        "Export"
                    ) : "..."}
                </button> : ""}
            </form>
        </>
    )
}