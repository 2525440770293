import React, { useEffect, useState } from 'react'
import SnackBar from '../../../components/SnackBar'
import Spinner from '../../../components/spinner'
import Table from '../../../components/table/table'
import Permission from '../../auth/permissions'
import { services } from '../../../utility/checkRoleBasedPermission'
import { Button } from '@mui/material'
import Classes from '../customers/css/customer.module.css'
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux'
import { setPincodes } from '../../../store/pincodesSettingSlice'
import {Delete} from '@mui/icons-material';
import PincodeDialog from './pincodeDialog'
import axiosInstance from '../../../utility/axios-instance'
import { ImportFileComponent } from '../../../components/importFileComponent'
import { downloadCSV } from '../../../utility/downloadCSV'

export default function Pincodes() {

    const [reRender, setReRender] = useState(false);
    const pincodes = useSelector((state) => state.pincodesSettings);
    const auth = useSelector((state) => state.auth);
    const user = auth?.user
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [action, setAction] = useState("create")

    const [pincode, setPincode] = useState(null)
    const [district, setDistrict] = useState(null)
    const [city, setCity] = useState(null)
    const [state, setState] = useState(null)
    const [deliveryStatus, setDeliveryStatus] = useState("Delivery")
    const [available, setAvailable] = useState(false);
    const [oavcw, setOavcw] = useState(false);
    const [csvResponse, setCsvResponse ] = useState();

    const handleUpdatePincode = async (data) => {
        setPincode(data?.pincode);
        setDistrict(data?.district);
        setCity(data?.city);
        setState(data?.state_name);
        setDeliveryStatus(data?.delivery);

        setAction("update")
        showCreatePopup()
    }

    const columnVisibilityModel={updated_at : false, deleted : true, id : false}
    const columns = [
        {
            field: `id`,
            headerName: `ID`,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false
        },
        {
            field: `district`,
            headerName: `District`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `pincode`,
            headerName: `Pincode`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `delivery`,
            headerName: `Delivery Status`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'center',
            renderCell : (params) => (params?.row?.delivery === "Delivery") 
                ? <div><CheckCircleIcon color='success'/><LocalShippingIcon color='success'/></div> 
                : <div><CancelIcon color='error'/><LocalShippingIcon color='error'/></div>
        },
        {
            field: `state_name`,
            headerName: `State Name`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `city`,
            headerName: `City`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `available`,
            headerName: `Availability`,
            flex: .8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            renderCell : (params) => (params?.row?.available === true) 
                ? <div><CheckCircleIcon color='success'/></div> 
                : <div><CancelIcon color='error'/></div>
        },
        {
            field: `oavcw`,
            headerName: `Only Available via calls`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'center',
            filterable: false,
            renderCell : (params) => (params?.row?.oavcw === true) 
                ? <div><CheckCircleIcon color='success'/></div> 
                : <div><CancelIcon color='error'/></div>
        },
        {
            field: `created_at`,
            headerName: `Created At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false
        },
        {
            field: `updated_at`,
            headerName: `Updated At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false
        },
        {
            field: `created_by`,
            headerName: `Created By`,
            flex: .7,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: `update`,
            headerName: `Update`,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            sortable: false,
            renderCell : (params) => <Permission service={services?.pincodeSetting} permission={"delete"}>
                <Button size='small' variant='outlined' color='success' onClick={() => handleUpdatePincode(params.row)}>UPDATE</Button>
            </Permission>
        },
        {
            field: `deleted`,
            headerName: `Delete`,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell : (params) => <Permission service={services?.pincodeSetting} permission={"delete"}>
                <div style={{cursor : 'pointer'}} onClick={(e) => handleDeletePincodeDetails(e, params.row.pincode)}><Delete color='error'/></div>
            </Permission>
        },
    ];

    const showCreatePopup = () => {
        setShowPopup(true)
    }

    const hideCreatePopup = () => {
        setDistrict(null)
        setPincode(null);
        setCity(null);
        setState(null);
        setShowPopup(false)
        const form = document.getElementById('form');
        form.reset();
    }

    const handleDeletePincodeDetails = async (event, pincode) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .delete(`/pincode/deletePincode/${pincode}`)
            .then((res) => {
                setReRender(!reRender);
                setLoading(false);
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        hideCreatePopup();
        setLoading(true);

        let data = {
            pincode : pincode,
            state : state,
            district : district,
            city : city,
            deliveryStatus : deliveryStatus,
            available: available,
            oavcw: oavcw
        }
        if(action === "create"){
            axiosInstance
                .post(`/pincode/createPincode`, {...data})
                .then((res) => {
                    setReRender(!reRender);
                    setLoading(false);
                    setSnackBar({ display: true, type: "success", message: res?.data?.message })
                }).catch((err) => {
                    setLoading(false);
                    setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
                })
        }
        else {
            axiosInstance
                .put(`/pincode/updatePincode/${pincode}`, {...data})
                .then((res) => {
                    setReRender(!reRender);
                    setLoading(false);
                    setSnackBar({ display: true, type: "success", message: res?.data?.message })
                }).catch((err) => {
                    setLoading(false);
                    setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
                })
        }
    }

    useEffect(() => {
        if(csvResponse){
            if(csvResponse?.invalidRecords?.length){
                downloadCSV(csvResponse?.invalidRecords, `Invalid Pincodes Please check- ${new Date()}`)
            }
        }
    },[csvResponse])

  return (
    <>
        <div style={{marginTop : '20px'}}>
                <Permission service={services?.pincodeSetting} permission={"create"}>
                    <div style={{display:'flex',justifyContent:'space-between',marginBottom:'5px'}}>
                        <Button startIcon={<AddCircleIcon />} 
                            sx={{
                                textTransform: "capitalize",
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                            }}
                            variant="contained"
                            onClick={() => {
                                setAction("create");
                                showCreatePopup()
                            }}
                        >Add New</Button>

                    <ImportFileComponent
                        endpoint={'/pincode/createPincodesViaCSV'}
                        serviceName={services?.pincodeSetting}
                        type="pincodes"
                        templateDownloadEndpoint={"/templates/pincodeUploadCsv"}
                        setReRender={setReRender}
                        setData={setCsvResponse}
                    />
                    </div>
                </Permission>
                <Permission service={services?.pincodeSetting} permission={"read"}>
                <div>
                    <Table 
                        rowId={'id'} 
                        columns={columns} 
                        dataPosition={"fetchedPincodes"} 
                        endpoint={'/pincode/fetchAllPincodeDetails'}
                        exportEndpoint={'/exports/pincodes'}
                        exportFileName={`Pincodes - ${new Date()}`}
                        checkBoxSelection={false} 
                        reRender={reRender} 
                        service={'pincode'}
                        tabName={'Pincodes'}
                        setData={setPincodes}
                        data={pincodes}
                        columnVisibilityModel={columnVisibilityModel}
                        version={'version-2'}
                        mobileResponsive={true}
                    />
                    {loading ? <Spinner /> : null}
                </div>
                </Permission>

                <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                    <PincodeDialog 
                        open={showPopup}
                        hideCreatePopup={hideCreatePopup}
                        setReRender={setReRender}
                        reRender={reRender}
                        action={action}

                        pincode={pincode}
                        setPincode={setPincode}
                        district={district}
                        setDistrict={setDistrict}
                        deliveryStatus={deliveryStatus}
                        setDeliveryStatus={setDeliveryStatus}
                        state={state}
                        setState={setState}
                        city={city}
                        setCity={setCity}
                        available ={available}
                        setAvailable={setAvailable}
                        oavcw={oavcw}
                        setOavcw={setOavcw}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </div>
            {loading ? <Spinner /> : null}
        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  )
}