import {
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridPrintExportMenuItem,
} from '@mui/x-data-grid';
import React, { Fragment } from 'react';
import { permission, services } from '../../utility/checkRoleBasedPermission';
import Permission from '../../pages/auth/permissions';
import FilteredExportMenuItem from './FilteredExportMenuItem';

const CustomExportButton = ({ service, handleFilteredExport, filteredExport, isOtherExports , handleOtherExports, otherExportLabel, ...props }) => {
    return (
        <Fragment>
            <Permission service={services?.[service]} permission={permission?.read}>
                <GridToolbarExportContainer {...props}>
                    {filteredExport && <FilteredExportMenuItem handleFilteredExport={handleFilteredExport} label={"Filtered rows"} />}
                    {isOtherExports && <FilteredExportMenuItem handleFilteredExport={handleOtherExports} label={otherExportLabel} />}
                    <GridCsvExportMenuItem />
                    <GridPrintExportMenuItem />
                </GridToolbarExportContainer>
            </Permission>
        </Fragment>
    );
}

export default CustomExportButton;