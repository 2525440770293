import Button from '@mui/material/Button';

const ButtonComponent = (props) => {
    const { Icon, open } = props;

    return (
        <div style={{display:'flex',justifyContent:'flex-end',marginBottom:15}}>
            <Button 
                variant="contained"
                startIcon={Icon} 
                sx={{
                    textTransform: "capitalize",
                    "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                }}
                onClick={open}
            >
                Add New
            </Button>
      </div>
    )
}

export default ButtonComponent;