
import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';

const  isImgUrl = async (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
    });
}

const handleUploadImage = async (event, dimension, payloadToUpload, setPayloadToUpload) => {
    if(event?.target?.value){
        const res = await isImgUrl(event.target.value);
        if (!event?.target?.checkValidity() || !res) {
            alert("Please enter valid Image URL");
            return
        } else {
            const keyName = Object.keys(payloadToUpload?.images?.[dimension])?.filter((value) => !payloadToUpload?.images?.[dimension][value])?.[0];
            setPayloadToUpload((prevPayloadToUpload) => {
                return { 
                    ...prevPayloadToUpload,
                    images: { 
                        ...prevPayloadToUpload?.images,
                        [dimension]: { ...prevPayloadToUpload?.images?.[dimension], [keyName]: event?.target?.value }
                    } 
                };
            });
            event.target.value = '';
        }
    }
}

const UploadImages = ({dimension, payloadToUpload, setPayloadToUpload}) => {
    return (
        <div>
            <p style={{ fontWeight:400 }}>Upload Url {dimension}</p>
            <input
                type="url"
                id="img"
                name='url'
                autoComplete='off'
                placeholder='Add File Url'
                className={[Classes.imgInput, Classes.ImageUrl].join(' ')}
                onBlur={(event) => handleUploadImage(event, dimension, payloadToUpload, setPayloadToUpload)}
            />
        </div>     
    )
}

const  Media = (props) => {
    const { payloadToUpload, setPayloadToUpload }= props;

    const dimensions = ["400x400", "1200x1200", "150x150"];
    const dimensionColor = {
        '400x400' : 'bisque',
        '1200x1200' : '#d0ffc4',
        '150x150': 'khaki'
    }

	return (
		<Fragment>
            <div className={Classes.Box}>
                <h6 className="mb-3">Media</h6>
                        
                <div>
                    <div className={Classes.ProductImg} id='product' style={{display:'flex',flexWrap:'wrap'}}>
                        {dimensions?.map((dimension,index) => (
                            <div id='smallImage'style={{ display:'flex',flexWrap:'wrap' }} key={index}>
                                {payloadToUpload?.images?.[dimension] && Object.values(payloadToUpload?.images?.[dimension])?.length !== 0 && 
                                Object.entries(payloadToUpload?.images?.[dimension])?.filter(([key, value]) => value)?.map(([key, value]) => (
                                    <div id={key} key={key} className='smallImageDiv'>
                                        <img 
                                            src={value ? value : process.env.REACT_APP_IMAGE_COMMING_SOON}
                                            style={{width:'100%',height:'100%'}}
                                            alt=""
                                        />
                                        <p className={Classes.dimension} style={{ backgroundColor: dimensionColor[dimension]}}>
                                            {dimension} {`- ${value?.split("/")?.slice(-1)[0]?.split(".")?.[0]}`}
                                        </p>
                                        <img 
                                            src="https://cdn-icons-png.flaticon.com/512/1828/1828613.png" 
                                            alt="" className='crossIcon'
                                            onClick={() => {
                                                setPayloadToUpload((prevPayloadToUpload) => {
                                                    return { 
                                                        ...prevPayloadToUpload,
                                                        images: { 
                                                            ...prevPayloadToUpload?.images,
                                                            [dimension]: { ...prevPayloadToUpload?.images?.[dimension], [key]: '' }
                                                        } 
                                                    };
                                                });
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                    {dimensions?.map((dimension,index) => (
                        <div key={index}>
                            {payloadToUpload?.images?.[dimension] && Object.entries(payloadToUpload?.images?.[dimension]).filter(([key, value]) => value)?.length < 8 && (
                                <UploadImages dimension={dimension} payloadToUpload={payloadToUpload} setPayloadToUpload={setPayloadToUpload} />
                            )}
                        </div>
                    ))}
                </div>
            </div>
		</Fragment>
	);
};

export default Media;
