import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Classes from '../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';


const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const { dataPosition, endpoint, rowId, columns, openUpdateDialog, handleDeleteBin, reRender } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor,reRender]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                            <p>
                                {column?.field === "created_at" || column?.field === "createdAt" || 
                                column?.field === 'updatedAt' || column?.field === 'updated_at' ? 
                                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                                column?.field === 'update'? 
                                    <Permission service={services?.bin} permission={"update"}>
                                        <Button 
                                            size='small' 
                                            color='success' 
                                            variant="contained" 
                                            onClick={(e) => openUpdateDialog(row)}
                                        >Update</Button>
                                    </Permission>
                                : column?.field === 'delete' ? 
                                    <Permission service={services?.bin} permission={"delete"}>
                                        <FormDialog
                                            dialogTitle="Confirm Delete Bin"
                                            dialogActionType="confirmation"
                                            openDialogButtonName="Delete"
                                            submitDialogButtonName="Confirm"
                                            onConfirmation={() => { handleDeleteBin(row?.bin_id, row?.locationCode) }}
                                        >
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                This action would permanently Delete '{row?.code}' Bin ?
                                            </Typography>
                                        </FormDialog>
                                    </Permission>
                                : column?.field === 'default' ? 
                                row?.default === false ? <BsXCircleFill color="red" /> : <BsCheckCircleFill color="green" /> :
                                row[column?.field] }
                            </p>
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;