import { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/create.module.css';
import Aside from './aside/aside';
import Modal from './Modal';
import TaxRatesPopup from './TaxRates_popup';
import DiscountPopup from './addDiscountPopup';
import ShippingPopUp from './AddShippingPopup';
import TaxRatesPrint from './TaxRatesPrint';
import ConfirmPopup from './confirmPopup';
import { Spinner } from 'react-bootstrap';
import SnackBar from '../../../components/SnackBar';
import CreateOrderComponent from '../../../components/createPurchaseOrder/createOrders';
import PaymentDetailsComponent from '../../../components/createPurchaseOrder/paymentDetails';
import { 
    getModalHelper,
    handleTaxAmountHelper,
    hideConfirmPopupHelper,
    addHideModalHelper,
    hideModalHelper,
    handleTaxRatesHelper,
    hideTaxPopupHelper,
    showDiscountPopupHelper,
    hideDiscountPopupHelper,
    shippingPopupHelper,
    hideShippingPopupHelper,
    handleOutlineHelper,
    updateArrHelper,
    incQtyHelper,
    decQtyHelper,
    handleEditPercentageHelper,
    handleBlurPercentageHelper,
    handleEditHelper,
    handleBlurHelper,
    handleKeyUpHelper,
    handleBlurBulkPackagingQty1Helper,
    handleBlurBulkPackagingQty2Helper,
    incQtyBulkPackagingHelper,
    decQtyBulkPackagingHelper,
    handleBlurQtyHelper,
    formatNumberHelper,
    showLimitDiscountHelper,
    handleSendPIHelper,
    handleCreateDraftOrderHelper,
    handleUpdateOrderHelper,
    handleChangeHelper, 
    handleDisableSelectHelper,
    handleEnableSelectHelper,
    countPlacesHelper,
    handleUpdateExistOrderHelper,
    handleCalculationHelper
} from '../../../components/createPurchaseOrder/helperFunction';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

const CreatePurchaseOrder = (props) => {
    const { isCreateOrderPage, setIsCreateOrderPage } = props;
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [productId, setProductId] = useState([]);
    const [variant, setVariant] = useState(0);
    const [addToggleBtn, setAddToggle] = useState(false);
    const [showProduct, setProduct] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [variantRole, setVariantRole] = useState([]);
    const [showTaxRate, setTaxRates] = useState(false);
    const [discountModal, setDiscountModal] = useState(false);
    const [shippingModal, setShippingModal] = useState(false);
    const [productDetails, setProductDetails] = useState([]);
    const [updateDiscountPercent, setUpdateDiscountPercent] = useState([]);
    const [editDiscountPercent, setDiscountPercent] = useState([]);
    const [storePrevDiscountId, setStorePrevDiscountId] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPurchaseOrderCalculationService, setLoadingPurchaseOrderCalculationService] = useState(false);
    const [notExistProductShopify, setProductNotExistShopify] = useState(false);
    const [notExistProductShopifyId, setProductNotExistShopifyId] = useState([]);
    const [lineItems,setLineItems] = useState([]);
    const adminUser = useSelector((state) => state?.auth?.user);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    // const locationCodeOptions = adminUser?.settings?.locationSetting.map((location) => {
    //     return {
    //         label: location,
    //         value: location
    //     }
    // });

    const districts = adminUser?.settings?.districts;
    const districtOptions = districts?.map((district) => {
        return {
            label: district,
            value: district
        }
    });
    const [selectedDistrict, setSelectedDistrict] = useState(districtOptions?.[0]);
  
    // modal
    const [productNew, setProductNew] = useState([]);
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);

    // ShippingPopUp
    const [shippingPrice, setShippingPrice] = useState([]);
    const [previousTarget, setPreviousTarget] = useState([]);
    const [shippingPriceType, setShippingPriceType] = useState([]);
    const [shippingAmount, setShippingAmount] = useState(false);

    // aside
    const [showCustomerDetail, setCustomerDetail] = useState([]);
    const [customerIDAddress, setCustomerIDAddress] = useState([]);
    const [gstNumber, setGstNumber] = useState([]);
    const [vendorGstNumber, setVendorGstNumber] = useState("");
    const [locationGstNumber, setLocationGstNumber] = useState("");
    const [BillingAddress, setBillingAddress] = useState([]);
    const [addressArray, setAddressArray] = useState([]);
    const [overAllDiscount, setOverAllDiscount] = useState([]);
    const [draftOrder, setDraftOrder] = useState([]);
    const [showSelectedTags, setSelectedTags] = useState([]);
    const [shippingDetails, setShippingDetails] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [documentsAttached, setDocumentsAttached] = useState([]);
    const [vendorContacts, setVendorContacts] = useState([]);
    const [vendorContactDetails, setVendorContactDetails] = useState(null);
    const [locationContacts, setLocationContacts] = useState([]);
    const [locationContactDetails, setLocationContactDetails] = useState(null);

    // store deliveryRemark & notes in State
    const [notes, setNotes] = useState(null);
    const [showLocationDetail, setLocationDetail] = useState([]);
    const [locationIDAddress, setLocationIDAddress] = useState([]);
    const [locationBillingAddress, setLocationBillingAddress] = useState([]);
    const [locationAddressArray, setLocationAddressArray] = useState([]);

    // sendPIDraftOrder
    const [sendPIDraftOrder, setSendPIDraftOrder] = useState([]);
    const navigate = useNavigate();
    const { Id } = useParams();

    const inputRef = useRef(null);
    const handleClick = () => {
        inputRef.current.focus();
    };

    // sendExistOrder
    const [updateToggle, setUpdateToggle] = useState(true);
    const [updateOrder, setUpdateOrder] = useState(false);
    const [updateDraftOrderId, setUpdateDraftOrderId] = useState([]);
    const [completeOrder, setCompleteOrder] = useState(false);
    const [completeExistOrder, setCompleteExistOrder] = useState([]);
    const [updateExistOrder, setUpdateExistOrder] = useState(false);
    const [showDiscountType, setDiscountType] = useState([])
    const [specificId, setSpecificId] = useState({});
    const [defaultFiles, setDefaultFiles] = useState([]);
	const [productIdArr, setProductIdArr] = useState([]);

    const [active, setActive] = useState(true);
    const [priceActiveDialog, setPriceActiveDialog] = useState(true);

    const onChangeActive = (e) => {
		setActive(e.target.checked);
	};

    const onChangePriceActiveDialog = (e) => {
		setPriceActiveDialog(e.target.checked);
	};

    const setLoadingPurchaseOrderCalculationServiceState = (value) => {
        setLoadingPurchaseOrderCalculationService(value);
    };

    // show Modal popup
    const getModal = (searchItem) => {
        document.getElementById('outline').style.border = '1px solid rgb(206, 212, 218)';
        document.getElementById('search').value = '';
        getModalHelper(searchItem,setToggle,setSearchProduct,setShowModal);
    };

    const handleTaxAmount = (amount) => {
        return handleTaxAmountHelper(amount);
    }

    const hideConfirmPopup = (event) => {
        hideConfirmPopupHelper(event,setConfirmShowPopup);
    }

    const handleLocationCodeChange = (event) => {
        setSelectedDistrict(event.target.value);
    };

    // AddProductButton&HideModal
    const addHideModal = () => {
        addHideModalHelper(
            setSearchProduct,setVariant,productIdArr,setShowModal,setAddToggle,
            productNew,productDetails,setProductId,setProductDetails,setProductNew
        );
    };

    // hide modal popup
    const hideModal = () => {
        setPriceActiveDialog(false);
        hideModalHelper(setSearchProduct,productIdArr,setVariant,productId,setShowModal);
    };

    // show TaxRate Popup
    const handleTaxRates = () => {
        handleTaxRatesHelper(setTaxRates);
    };

    // hide TaxRate Popup
    const hideTaxPopup = () => {
        hideTaxPopupHelper(setTaxRates);
    };

    // show discount popup
    const showDiscountPopup = () => {
        showDiscountPopupHelper(setDiscountModal);
    };

    // hide Discount popup
    const hideDiscountPopup = () => {
        hideDiscountPopupHelper(setDiscountModal);
    };

    const shippingPopup = (event) => {
        shippingPopupHelper(setShippingModal);
    };

    const hideShippingPopup = () => {
        hideShippingPopupHelper(setPreviousTarget,setShippingModal);
    };

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('outline');
        if (container !== null) {
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleOutline = () => {
        document.getElementById('outline').style.border = '2px solid #458fff';
    };

    // remove product from array
    const updateArr = (Id) => {
        updateArrHelper(
            Id,productDetails,notExistProductShopifyId,setProductNotExistShopifyId,
            setProductNotExistShopify,setProductId,setProductDetails,setAddToggle,
            setVariantRole,variantRole,setProduct,setOverAllDiscount,setShippingPrice,
            setPreviousTarget
        );
    };

    // increase qty from product
    const incQty = (event, Id,bulkPacking1,bulkPacking2) => {
        incQtyHelper(
            event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,
            setProductId,setProductDetails
        );
    };

    // decrease qty from product
    const decQty = (event, Id,bulkPacking1,bulkPacking2) => {
        decQtyHelper(
            event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,setProductId,
            setProductDetails
        );
    };

    // Handle Manually Percentage
    const handleEditPercentage = (event) => {
        return handleEditHelper(event)
    };

    // Handle Blur Percentage
    const handleBlurPercentage = (event, Id, MRP, GST, originalUnitPrice) => {
        handleBlurPercentageHelper(
            event, Id, MRP, GST, originalUnitPrice, setStorePrevDiscountId,productDetails,
            updateDiscountPercent,setUpdateDiscountPercent,setAddToggle,setProductId,
            setProductDetails
        );
    };

    const handleEdit = (event) => {
        handleEditHelper(event);
    };

    // handle Blur Rate(Price)
    const handleBlur = (event, Id, previousUnit, MRP) => {
        handleBlurHelper(
            event, Id, previousUnit, MRP,productDetails,setAddToggle,setProductId,
            setProductDetails
        )
    };

    // OnQty Blur Handled
    const handleKeyUp = (event) => {
        handleKeyUpHelper(event)
    }

    const handleBlurBulkPackagingQty1 = (event,Id,bulkPackagingValue,type,bulkPackagingValue2,restrictMsgId) => {
        handleBlurBulkPackagingQty1Helper(
            event, Id, bulkPackagingValue, type, bulkPackagingValue2, restrictMsgId,
            productDetails, setAddToggle, setProductId, setProductDetails
        )
    };

    const handleBlurBulkPackagingQty2 = (event,Id,bulkPackagingValue,type,bulkPackagingValue1,restrictMsgId) => {
        handleBlurBulkPackagingQty2Helper(
            event, Id, bulkPackagingValue, type, bulkPackagingValue1, restrictMsgId,
            productDetails, setAddToggle, setProductId, setProductDetails
        )
    };

    // increase qty from product
    const incQtyBulkPackaging = (event, Id, bulkPackagingValue, type) => {
        incQtyBulkPackagingHelper(
            event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
            setProductDetails
        )
    };

    const decQtyBulkPackaging = (event, Id, bulkPackagingValue, type) => {
        decQtyBulkPackagingHelper(
            event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
            setProductDetails
        )
    };

    // handle Blur Qty
    const handleBlurQty = (event, Id, bulkPacking1, bulkPacking2, restrictMsgId) => {
        handleBlurQtyHelper(
            event, Id, bulkPacking1, bulkPacking2, restrictMsgId, productDetails, setAddToggle,
            setProductId, setProductDetails
        );
    };

    const formatNumber = (number) => {
        return formatNumberHelper(number);
    };

    // showLimitDiscount
    const showLimitDiscount = (mrp, originalUnitPrice, value) => {
        return showLimitDiscountHelper(mrp, originalUnitPrice, value);
    };

    const handleDisableSelect = () => {
        handleDisableSelectHelper()
    }

    const handleEnableSelect = () => {
        handleEnableSelectHelper();
    }

    // Open Window Print
    const handleSendPI = (event) => {
        handleSendPIHelper(event,overAllDiscount,sendPIDraftOrder);
    }

    // Create draftOrder(Save as Draft Order)
    const handleCreateDraftOrder = (event) => {
        handleCreateDraftOrderHelper(
            event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
            customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
            locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
            shippingPrice, setShippingDetails, shippingDetails, documentsAttached,
            locationContactDetails, vendorContactDetails, notes, navigate,
            setIsCreateOrderPage, setLoading, setSnackBar
        )
    }

    // Update Exist Order
    const handleUpdateOrder = (event) => {
        handleUpdateOrderHelper(
            event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
            customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
            locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
            shippingDetails, documentsAttached, locationContactDetails, vendorContactDetails,
            notes, navigate, specificId, setVendorContactDetails, setLocationContactDetails,
            defaultFiles, draftOrder, updateDraftOrderId, setUpdateOrder, setCompleteOrder,
            setIsCreateOrderPage, 'draftPurchaseOrders', setSnackBar
        )
    }

    useEffect(() => {
        if (Id && updateToggle) {
            let timeoutId = setTimeout(() => {
                handleUpdateExistOrderHelper(
                    Id, setUpdateExistOrder, setDraftOrder, setCompleteExistOrder, setUpdateDraftOrderId, setShippingDetails,
                    setPaymentDetails, setVendorContactDetails, setVendorGstNumber, setLocationContactDetails, setNotes,
                    setLocationGstNumber, setSpecificId, setDefaultFiles, setUpdateDiscountPercent, setDiscountPercent,
                    setOverAllDiscount, setShippingPrice, setProductId, setProductDetails, setProduct, setUpdateOrder,
                    handleEnableSelect, setUpdateToggle, setLineItems, setCustomerDetail, setAddressArray, setCustomerIDAddress,
                    setBillingAddress, setVendorContacts, setLocationDetail, setLocationAddressArray, setLocationIDAddress,
                    setLocationBillingAddress, setLocationContacts, 'purchaseOrder', setLoadingPurchaseOrderCalculationServiceState
                )
            }, 500)
            return () => clearTimeout(timeoutId)
        }
    }, [Id, updateToggle])

    useEffect(() => {
        setProduct(showProduct)
    },[showProduct])


    useEffect(() => {
        if (addToggleBtn && productDetails?.length !== 0) {
            let timeoutId = setTimeout(() => {
                handleCalculationHelper(
                    lineItems, locationIDAddress, customerIDAddress, productDetails, overAllDiscount,
                    shippingPrice, setAddToggle, updateDiscountPercent, setDiscountPercent, 
                    editDiscountPercent, setLineItems, setProduct, setLoadingPurchaseOrderCalculationServiceState
                )
            }, 500)
            return () => clearTimeout(timeoutId)
        }
    }, [addToggleBtn, showProduct, overAllDiscount, productDetails, shippingPrice,
        updateDiscountPercent, editDiscountPercent, locationIDAddress, customerIDAddress,
        lineItems
    ]);

    useEffect(() => {
        setIsCreateOrderPage(true);
    }, [])

    const countPlaces = (num) => {
        return countPlacesHelper(num);
    }

    const handleChange = (value, sku) => {
        handleChangeHelper(value, sku, lineItems, setLineItems);
    }

    useEffect(() => {
        localStorage.clear();
    }, []);
    
    return (
        <Fragment>
            {loading ? (
                <div
                    style={{
                        position:'fixed', top:'50%', left:'50%',
                        transform:'translate(-50%, -50%)',
                        backgroundColor:'rgba(0, 0, 0, 0.5)',
                        zIndex:'9999', height:'100%', width:'100%',
                        display:'flex', justifyContent: 'center',
                        alignItems:'center',
                    }}
                >
                    <Spinner animation="border" variant="light" />
                </div>
            ) : null}

            <div className={Classes.container} id="color">
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Autocomplete
                        options={districtOptions}
                        value={selectedDistrict}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedDistrict(newValue);
                            }
                        }}
                        style={{ width: '20%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select District for Price"
                                size="small"
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </div>
                {error ? (
                    <Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}

                {sendPIDraftOrder?.length !== 0 && (
                    <div style={{ display: 'none' }} id='orderNamePrint'>
                        <h6 className={Classes.commonMargin}>Order Name:-</h6>
                        <p>{sendPIDraftOrder?.draftOrder?.name}</p>
                    </div>
                )}

                <div className={Classes.mainContainer} style={{ marginTop:30 }}>
                    <div className={Classes.containBoxes} id='createOrderProducts'>
                        <CreateOrderComponent 
                        setProductDetails={setProductDetails} setAddToggle={setAddToggle}
                            handleEdit={handleEdit}
                            handleBlur={handleBlur}
                            handleOutline={handleOutline}
                            getModal={getModal}
                            showProduct={showProduct}
                            lineItems={lineItems}
                            handleEditPercentage={handleEditPercentage}
                            handleBlurPercentage={handleBlurPercentage}
                            showLimitDiscount={showLimitDiscount}
                            handleChange={handleChange}
                            handleBlurQty={handleBlurQty}
                            incQty={incQty}
                            decQty={decQty}
                            handleBlurBulkPackagingQty1={handleBlurBulkPackagingQty1}
                            handleKeyUp={handleKeyUp}
                            incQtyBulkPackaging={incQtyBulkPackaging}
                            decQtyBulkPackaging={decQtyBulkPackaging}
                            handleBlurBulkPackagingQty2={handleBlurBulkPackagingQty2}
                            updateArr={updateArr}
                            onChangeActive={onChangeActive}
                            active={active}
                            componentType={'Purchase Order'}
                        />

                        <PaymentDetailsComponent 
                            showProduct={showProduct}
                            showDiscountPopup={showDiscountPopup}
                            handleClick={handleClick}
                            handleTaxRates={handleTaxRates}
                            handleTaxAmount={handleTaxAmount}
                            showCustomerDetail={showCustomerDetail}
                            showLocationDetail={showLocationDetail}
                            updateExistOrder={updateExistOrder}
                            notExistProductShopify={notExistProductShopify}
                            handleCreateDraftOrder={handleCreateDraftOrder}
                            handleSendPI={handleSendPI}
                            handleUpdateOrder={handleUpdateOrder}
                            buttonText={"Save as Draft Purchase Order"}
                            loadingPurchaseOrderCalculationService={loadingPurchaseOrderCalculationService}
                            active={active}
                        />

                        {showProduct?.length !== 0 && (
                            <div id='TaxRatesPrint' style={{ display: 'none' }}>
                                <TaxRatesPrint
                                    showProduct={showProduct}
                                    showCustomerDetail={showCustomerDetail}
                                    addressArray={addressArray}
                                    customerIDAddress={customerIDAddress}
                                />
                            </div>
                        )}

                        <div>
                            <ConfirmPopup
                                showConfirmPopup={showConfirmPopup}
                                hideConfirmPopup={hideConfirmPopup}
                            />
                        </div>
                    </div>

                    <div className={Classes.asideContainer} id='createOrderAsideContainer'>
                        <Aside
                            shippingDetails={shippingDetails}
                            setShippingDetails={setShippingDetails}
                            paymentDetails={paymentDetails}
                            setPaymentDetails={setPaymentDetails}
                            showLocationDetail={showLocationDetail}
                            setLocationDetail={setLocationDetail}
                            locationIDAddress={locationIDAddress}
                            setLocationIDAddress={setLocationIDAddress}
                            locationBillingAddress={locationBillingAddress}
                            setLocationBillingAddress={setLocationBillingAddress}
                            locationAddressArray={locationAddressArray}
                            setLocationAddressArray={setLocationAddressArray}
                            showCustomerDetail={showCustomerDetail}
                            setCustomerDetail={setCustomerDetail}
                            customerIDAddress={customerIDAddress}
                            setCustomerIDAddress={setCustomerIDAddress}
                            gstNumber={gstNumber}
                            setGstNumber={setGstNumber}
                            BillingAddress={BillingAddress}
                            setBillingAddress={setBillingAddress}
                            showSelectedTags={showSelectedTags}
                            setSelectedTags={setSelectedTags}
                            addressArray={addressArray}
                            setAddressArray={setAddressArray}
                            updateExistOrder={updateExistOrder}
                            notes={notes}
                            setNotes={setNotes}
                            style={Classes}
                            files={documentsAttached}
                            setFiles={setDocumentsAttached}
                            defaultFiles={defaultFiles}
                            setDefaultFiles={setDefaultFiles}
                            vendorContacts={vendorContacts}
                            setVendorContacts={setVendorContacts}
                            vendorContactDetails={vendorContactDetails}
                            setVendorContactDetails={setVendorContactDetails}
                            locationContacts={locationContacts}
                            setLocationContacts={setLocationContacts}
                            locationContactDetails={locationContactDetails}
                            setLocationContactDetails={setLocationContactDetails}
                            vendorGstNumber={vendorGstNumber}
                            setVendorGstNumber={setVendorGstNumber}
                            locationGstNumber={locationGstNumber}
                            setLocationGstNumber={setLocationGstNumber}
                            shippingPrice={shippingPrice}
                            overAllDiscount={overAllDiscount}
                            productDetails={productDetails}
                            setProduct={setProduct}
                            setShippingPrice={setShippingPrice}
                            inputRef={inputRef}
                            setLoadingPurchaseOrderCalculationServiceState={setLoadingPurchaseOrderCalculationServiceState}
                        />
                    </div>
                </div>
            </div>

            <div className={shippingModal ? Classes.UpdateSalesDialog : undefined}>
                <ShippingPopUp
                    shippingModal={shippingModal}
                    setShippingModal={setShippingModal}
                    shippingPopup={shippingPopup}
                    hideShippingPopup={hideShippingPopup}
                    productDetails={productDetails}
                    setProduct={setProduct}
                    shippingPrice={shippingPrice}
                    previousTarget={previousTarget}
                    shippingAmount={shippingAmount}
                    setShippingAmount={setShippingAmount}
                    setPreviousTarget={setPreviousTarget}
                    shippingPriceType={shippingPriceType}
                    setShippingPriceType={setShippingPriceType}
                    setShippingPrice={setShippingPrice}
                    overAllDiscount={overAllDiscount}
                    setLoadingPurchaseOrderCalculationServiceState={setLoadingPurchaseOrderCalculationServiceState}
                />
            </div>

            <div className={discountModal ? Classes.UpdateSalesDialog : undefined}>
                <DiscountPopup
                    discountModal={discountModal}
                    setDiscountModal={setDiscountModal}
                    showDiscountPopup={showDiscountPopup}
                    hideDiscountPopup={hideDiscountPopup}
                    productDetails={productDetails}
                    setProduct={setProduct}
                    overAllDiscount={overAllDiscount}
                    shippingPrice={shippingPrice}
                    showDiscountType={showDiscountType}
                    setDiscountType={setDiscountType}
                    setShippingPrice={setShippingPrice}
                    setOverAllDiscount={setOverAllDiscount}
                    setLoadingPurchaseOrderCalculationServiceState={setLoadingPurchaseOrderCalculationServiceState}
                />
            </div>

            <div className={showTaxRate ? Classes.UpdateSalesDialog : undefined}>
                <TaxRatesPopup
                    showTaxRate={showTaxRate}
                    handleTaxRates={handleTaxRates}
                    hideTaxPopup={hideTaxPopup}
                    taxLines={showProduct}
                    formatNumber={formatNumber}
                    draftOrder={showProduct?.length !== 0 ? {DpoTaxLines:showProduct?.taxLines,DpoPaymentDetails: {totalTaxAmount: showProduct?.totalTaxAmount}} : draftOrder}
                />
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <Modal
                    show={showModal}
                    onHide={hideModal}
                    AddHide={addHideModal}
                    styles={Classes}
                    search={searchProduct}
                    setSearch={setSearchProduct}
                    productArr={productId}
                    setProductArr={setProductId}
                    variant={variant}
                    setVariant={setVariant}
                    toggle={toggle}
                    setToggle={setToggle}
                    variantRole={variantRole}
                    setVariantRole={setVariantRole}
                    productNew={productNew}
                    setProductNew={setProductNew}
                    productIdArr={productIdArr}
                    setProductIdArr={setProductIdArr}
                    district={selectedDistrict?.value}
                    componentType={'Purchase Order'}
                    active={priceActiveDialog}
                    onChangeActive={onChangePriceActiveDialog}
                />
            </div>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
};

export default CreatePurchaseOrder;