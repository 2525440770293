import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';

const NotesComponent = (props) => {
    const { 
        handleEdit,
        handleBlur,
        notes
    } = props;

    return (
        <Fragment>
            <div className={Classes.Box} id='removeTagsPrint' style={{padding:'1.1rem'}}>
                <h6 style={{ margin:'auto 0px 12px',padding:6 }}>Notes</h6>
                <div className={Classes.deliveryRemark}>
                    <div
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                        id='notes'
                        onKeyDown={(e) => handleEdit(e)}
                        onBlur={(e) => handleBlur(e)}
                        className={Classes.Span}
                    >
                        {notes}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NotesComponent;