import Classes from '../orders/css/order.module.css';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { setOrders } from '../../../store/ordersSlice';
import moment from 'moment';
import Table from '../../../components/table/table';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';

const OrdersNew = (props) => {
    const {id} = useParams();
    const location = useLocation();
    const locations = location.pathname.split("/").slice(1)
    const auth = useSelector((state) => state.auth);
    const orders = useSelector((state) => state?.orders);
    const [locationCodes, setLocationCodes] = useState(auth?.user?.settings?.locationSetting || []); // locationCodes

    let endpoint = `/pgOrder/admin/orders`;

    if (locations[1] === 'user') {
        endpoint += `/user/${id}`
    }

    const columnVisibilityModel = {
        orderPaymentStatus: false,
        locationCode: false,
        createdVia: false,
        orderCancelledAt: false,
        customerPhoneNumber: false,
    }

    const fetchLocations = ()=>{
        // fetch locations
        axiosInstance
            .get('/location/locations')
            .then((res) => {
                const locations = res?.data?.data;
                const locationCodes = locations.map((location) => location?.locationCode);
                setLocationCodes(locationCodes);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if(["super-admin", "admin"].includes(auth?.user?.role)){
            fetchLocations();
        }
    }, [])

    let columns = [
        {
            field: 'orderName',
            headerName: 'ORDER',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdAt',
            headerName: 'DATE',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdVia',
            headerName: 'Platform',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'createdVia',
            renderCell: (params) => (params?.row?.createdVia),
        },
        {
            field: 'orderCancelledAt',
            headerName: 'Cancelled At',
            flex: 1.5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return params?.row?.cancelledAt ? moment(params?.row?.cancelledAt).format('MMM DD, YYYY h:mm:ss A') : 'N/A'
            },
        },
        {
            field: 'customerFullName',
            headerName: 'CUSTOMER',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (params?.row?.customerFullName),
        },
        {
            field: 'locationCodes',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: true,
            enumValues: locationCodes,
            renderCell: (params) => (params?.row?.locationCodes.join(', '))
        },
        {
            field: 'orderPaymentStatus',
            headerName: 'PAY STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            enumValues: ['Paid', 'Unpaid'],
            renderCell: (params) => (
                params?.row?.orderPaymentStatus === "Unpaid" || params?.row?.orderPaymentStatus === '' || params?.row?.orderPaymentStatus === null ? (
                    <Chip icon={<CancelIcon color='disabled' />} sx={{ color: 'red' }} label={'Unpaid'} />
                ) : (
                    <Chip icon={<CheckCircleIcon color='disabled' />} sx={{ color: 'green' }} label={params?.row?.orderPaymentStatus} />
                )
            )
        },
        {
            field: 'customerPhoneNumber',
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            type: 'enum',
            tableName: 'Order',
            columnName: 'status',
            renderCell: (params) => (
                params.value === "Cancelled" || params?.value === 'Delivered' ? (
                    <Chip label={params.value} style={{ backgroundColor: params.value === "Cancelled" ? '#e54545' : '#128038', color: '#fff' }} />
                ) : (
                    <Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
                )
            )
        },
        {
            field: 'total',
            renderCell: (params) => `₹${params?.row?.total}`,
            headerName: 'TOTAL',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'orderType',
            headerName: 'TYPE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            enumValues: ['B2B', 'B2C'],
            renderCell: (params) => (
                params.value === "B2C" ? (
                    <Chip icon={<PersonIcon color='disabled' />} label={params?.row?.orderType} />
                ) : (
                    <Chip icon={<StoreIcon color='disabled' />} label={params?.row?.orderType} />
                )
            )
        },
        {
            field: 'createdBy',
            headerName: 'CREATED BY',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'createdBy',
        },
    ];

    return (
        <div className={Classes.MuiTable}>
            <Table
                columns={columns}
                dataPosition={"orders"}
                endpoint={endpoint}
                rowId={'orderName'}
                checkBoxSelection={false}
                service={'orders'}
                tabName={'ordersNew'}
                setData={setOrders}
                data={orders}
                navigateOnRowClickEndpoint={`/ordersNew`}
                columnVisibilityModel={columnVisibilityModel}
                mobileResponsive={true}
                exportEndpoint={'/exports/orders'}
                exportFileName={`Orders - ${new Date()}`}
            />
        </div>
    )
}

export default OrdersNew;