import React, {Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../../css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../../utility/axios-instance';


const ShippingPopUp = (props)=> {
    const [states,setStates] = useState([]);
    const {shippingAddressModal,hideShippingAddressPopup} = props;

    const handleSubmit = (event)=> {
        event.preventDefault();
        hideShippingAddressPopup();

        const data = JSON.stringify({
            "email": event.target.email.value,
            "phone":`+91${event.target.phone.value}`,
            "firstName": event.target.first.value,
            "lastName": event.target.last.value ? event.target.last.value : undefined,
            "addresses": [{
                "address1": event.target.address.value,
                "address2": "412 fake st",
                "city": event.target.city.value,
                "province": event.target.state.value,
                "phone": `+91${event.target.phone.value}`,
                "zip": event.target.pincode.value,
                "lastName": event.target.last.value,
                "firstName": event.target.first.value,
                "country": event.target.country.value
            }]
        })

        axiosInstance
            .post("/customer/updateShipping?search=1",{body: data})
            .then((res) =>{
                console.log('Shipping Address Updated')
            })
            .catch((error) => {
                console.log(error)
            });
    }


    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                setStates(response.data.data)
            })
    },[])

    return (
        <Fragment>
            {shippingAddressModal && (
                <div className={Classes.modal} style={{bottom:'4%', zIndex:'9999'}}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Edit Shipping Address</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideShippingAddressPopup}
                                />
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{padding:'20px',overflowY: 'scroll',height: '449px'}}>
                            <div style={{marginTop:'10px'}}>
                                <div>
                                    <p>Country/Origin</p>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        id="search"
                                        name="country"
                                        disabled={true}
                                        value='India'
                                        style={{ fontSize: '15px' }}
                                        className={Classes.inputFields}
                                    />
                                </div>
                            </div>

                            <div style={{display:'flex'}}>
                                <div style={{width: '48%',marginRight: '24px'}}>
                                    <div>
                                        <p>First name</p>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="search"
                                            name="first"
                                            className={Classes.inputFields}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div style={{width: '48%'}}>
                                    <div>
                                        <p>Last name</p>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="search"
                                            name="last"
                                            className={Classes.inputFields}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop:'10px'}}>
                                <div>
                                    <p>Email</p>
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        id="search"
                                        name="email"
                                        className={Classes.inputFields}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div style={{marginTop:'10px'}}>
                                <div>
                                    <p>
                                        Address
                                    </p>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        id="search"
                                        name='address'
                                        style={{ fontSize: '15px' }}
                                        className={Classes.inputFields}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div style={{marginTop:'10px'}}>
                                <div>
                                    <p>
                                        Apartment,suite,etc
                                    </p>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        id="search"
                                        name="apartment"
                                        style={{ fontSize: '15px' }}
                                        className={Classes.inputFields}
                                        placeholder='Apartment,suite,etc'
                                    />
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div style={{width: '32%',marginRight: '16px'}}>
                                    <div>
                                        <p>City</p>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="search"
                                            name="city"
                                            style={{ fontSize: '15px' }}
                                            className={Classes.inputFields}
                                            placeholder='City'
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div style={{width: '32%',marginRight: '16px'}}>
                                    <div>
                                        <p>State</p>
                                    </div>
                                    <div>
                                        <select 
                                            name="state" 
                                            id="states" 
                                            required
                                            className={Classes.inputFields}
                                        >
                                            {states?.length !== 0 && (
                                                <>
                                                    {states?.map((stateName,index) => (
                                                        <option key={index} value={stateName?.state}>
                                                            {stateName?.state}
                                                        </option>
                                                    ))}
                                                </>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div style={{width: '32%'}}>
                                    <div>
                                        <p>Pincode</p>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="search"
                                            name="pincode"
                                            style={{ fontSize: '15px' }}
                                            className={Classes.inputFields}
                                            placeholder='PIN code'
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop:'10px'}}>
                                <div>
                                    <p>
                                        Phone
                                    </p>
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        id="search"
                                        name="phone"
                                        style={{ fontSize: '15px' }}
                                        className={Classes.inputFields}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div 
                            className={[
                                Classes.Footer, 
                                Classes.Button
                            ].join(' ')} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'4%'
                            }}
                        >
                            <div 
                                className={[
                                    Classes.TextAlignRight, 
                                    Classes.Add
                                ].join(' ')}
                            >
                                <button 
                                    id='btn' 
                                    type="submit" 
                                    style={{
                                        backgroundColor:'#006E52', 
                                        color: '#FFF',
                                        fontSize:'15px',
                                        padding: '6px 10px'
                                    }}
                                >
                                    Update Address
                                </button>
                            </div>
                        </div>
                        
                    </form>
                </div>
            )}
        </Fragment>
    );
}


export default ShippingPopUp;