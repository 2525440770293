import React, { useRef, useState, useEffect } from 'react';
import ProductCard from './productCard';
import { Chip, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ProductCards = (props) => {
    const { productCardsData, carouselTitle, selectedProducts, filters, fetchProducts, fetchProductsByCombo, filterId, loadingMoreProducts, } = props;
    const containerRef = useRef(null);
    const productCardRef = useRef(null);
    const timeOutRef = useRef(null);
    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(false);
    const [skeletonHeight, setSkeletonHeight] = React.useState('400px');
    const pageForThisCarousel = React.useRef(1);
    const slideLeft = () => {
        containerRef.current.scrollLeft -= (productCardRef?.current?.offsetWidth + 32);
    };

    const slideRight = () => {
        containerRef.current.scrollLeft += (productCardRef?.current?.offsetWidth + 32);
    };

    const filtersRef = React.useRef(filters);

    useEffect(() => {
        // Check scroll position and update showLeft and showRight states
        const scroll = () => {
            clearTimeout(timeOutRef.current);
            timeOutRef.current = setTimeout(async () => {
                const scrollableWidth = containerRef.current.scrollWidth - containerRef.current.clientWidth;
                if (containerRef.current.scrollLeft <= 0) {
                    setShowLeft(false);
                    setShowRight(true);
                } else if (
                    Math.round(containerRef.current.scrollLeft) >= Math.round(scrollableWidth)
                ) {
                    setShowLeft(true);
                    setShowRight(true);
                    const tempFilters = JSON.parse(JSON.stringify(filtersRef?.current));
                    const filterForThisCard = tempFilters?.find((filter) => {
                        return +filter?.rowId === +filterId
                    })
                    Object.keys(filterForThisCard).forEach((key) => (filterForThisCard[key] == null || filterForThisCard[key] == "" || key == "quantity" || key == "rowId" || key == "page" || key == "totalPages") && delete filterForThisCard[key]);
                    const stringifiedFilters = JSON.stringify(filterForThisCard);
                    if (pageForThisCarousel.current >= productCardsData?.[0]?.totalPages) {
                        setShowRight(false);
                        return;
                    };
                    productCardsData?.find((productCardData) => { return +productCardData?.filterId === +filterId })?.combo
                        ? await fetchProductsByCombo(stringifiedFilters, filterId, pageForThisCarousel.current + 1)
                        : await fetchProducts(stringifiedFilters, filterId, pageForThisCarousel.current + 1);
                    pageForThisCarousel.current += 1;
                } else {
                    setShowLeft(true);
                    setShowRight(true);
                }
            }, 100);
        };

        containerRef.current.addEventListener('scroll', scroll);
    }, []);
    const skeletonCards = () => {
        let cards = [];
        const remainingProducts = +productCardsData?.[0]?.totalRows < 10
            ? +productCardsData?.[0]?.totalRows
            : +productCardsData?.[0]?.totalRows - (+pageForThisCarousel.current * 10);
        const numberOfSkeletonCards = remainingProducts >= 10
            ? 10
            : remainingProducts;
        for (let i = 0; i < numberOfSkeletonCards; i++) {
            cards.push(
                <div style={{ minWidth: '14vw', maxWidth: '530px', margin: '1em', height: { skeletonHeight }, }}>
                    <Skeleton key={i + 1} variant="rectangular" height={skeletonHeight} animation='pulse' />
                </div>
            )
        }
        return cards;
    }
    useEffect(() => {
        // Check whether something is scrolled to the left
        if (containerRef.current.scrollLeft > 0) {
            setShowLeft(true);
        }
        // Check whether we have completely scrolled to the left
        const scrollableWidth = containerRef.current.scrollWidth - containerRef.current.clientWidth;
        if (
            containerRef.current.scrollLeft < scrollableWidth
        ) {
            setShowRight(true);
        }
    }, [containerRef.current]);

    useEffect(() => {
        setSkeletonHeight(productCardRef?.current?.offsetHeight + 'px')
    }, [productCardRef.current]);

    return (
        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
            {/* <div>Current Page: {pageForThisCarousel.current}</div>
            <div>Total Page: {productCardsData?.[0]?.totalPages}</div> */}
            <Typography variant="h6" component="h6" style={{ marginLeft: '0.8em', display: 'flex', alignItems: 'center' }}>
                {carouselTitle}
                {selectedProducts?.[filterId]?.sku && <Tooltip title={"Selected Product"} placement="top" arrow>
                    <Chip label={selectedProducts?.[filterId]?.sku} style={{ marginLeft: '1em', background: 'rgb(255, 98, 0)', color: 'white' }} size='small' />
                </Tooltip>}
            </Typography>
            <div style={{ position: 'relative', overflow: 'hidden' }} >
                {showLeft && <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
                    <IconButton style={{ marginRight: '1em' }} onClick={slideLeft}>
                        <ChevronLeftIcon color='primary' />
                    </IconButton>
                </div>}
                <div
                    ref={containerRef}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        overflow: 'scroll',
                        scrollbarWidth: 'none',
                        width: '100%',
                        scrollBehavior: 'smooth',
                    }}
                >
                    <style>
                        {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                    </style>
                    {productCardsData.length ? (
                        productCardsData.map((productCardData, index) => {
                            return (
                                <ProductCard
                                    key={index} {...productCardData}
                                    innerRef={index === 0 ? productCardRef : null}
                                    selectedProducts={selectedProducts}
                                    filters={filters}
                                    filterId={productCardData?.filterId}
                                />
                            )
                        })
                    ) : (
                        <div style={{ marginTop: '2em', marginLeft: '2em' }}>No products found!</div>
                    )}
                    {loadingMoreProducts?.[filterId] && skeletonCards()}
                </div>
                {showRight && (
                    <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
                        <IconButton style={{ marginLeft: '1em' }} onClick={slideRight}>
                            <ChevronRightIcon color='primary' />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductCards;
