import { Fragment, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/addProduct.module.css';
import axiosInstance from '../../../../utility/axios-instance';
import Aside from './aside/aside';
import GroupTitle from './GroupTitle';
import Media from './MediaFile';
import ProductBasicDetails from './ProductBasicDetails';
import Inventory from './Inventory';
import Variants from './Variants';
import './css/specialImg.css';
import { handleParentProductMRP } 
from './AllFunctions';
import SnackBar from '../../../../components/SnackBar';
import MultiPricing from './MultiPricing';
import UserDefinedFields from './UserDefinedFields';
import ChildProductsCollections from './childProductsCollections';
import Spinner from '../../../../components/spinner';
import { useNavigate } from 'react-router-dom';

const AddProduct = () => {

    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const navigate = useNavigate();
    const [payloadToUpload, setPayloadToUpload] = useState({
        salesChannel: [],
        removeSalesChannel: [],
        defaultPrices: {
            gst: "18"
        },
        images: {
            "150x150": { "01":'', "02":'', "03":'', "04":'', "05":'', "06":'', "07":'', "laminate": '' },
            "400x400": { "01":'', "02":'', "03":'', "04":'', "05":'', "06":'', "07":'', "laminate": '' },
            "1200x1200": { "01":'', "02":'', "03":'', "04":'', "05":'', "06":'', "07":'', "laminate": '' },
        }
    });
    const [isParentProduct, setIsParentProduct] = useState(false);
    const [isVirtualProduct, setIsVirtualProduct] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('payloadToUpload', payloadToUpload);
        const bulkPacking = [
            {
              type: payloadToUpload?.bulkPackingType1?.trim() || "",
              value: payloadToUpload?.bulkPackingValue1?.trim() || "",
              errorMessage: 'Please Add Bulk Packing 1',
            },
            {
              type: payloadToUpload?.bulkPackingType2?.trim() || "",
              value: payloadToUpload?.bulkPackingValue2?.trim() || "",
              errorMessage: 'Please Add Bulk Packing 2',
            },
        ];
          
        for (const { type, value, errorMessage } of bulkPacking) {
            if ((value && !type) || (!value && type)) {
                alert(errorMessage);
                return;
            }
        }
        
        const multiPriceData = [
            ...(payloadToUpload?.["districtBasedPrices"] || []),
            ...(payloadToUpload?.["franchiseBasedPrices"] || []),
          ]?.map((price) => {
            delete price?.errors;
            delete price?.priceCardId;
            return {
                ...price,
            };
        });
        
        const defaultPrices = Object.keys(payloadToUpload?.defaultPrices)
          .map((price) => {
            return {
              [price]: +payloadToUpload?.defaultPrices?.[price] || "",
            };
          })
          .reduce((acc, curr) => {
            return {
              ...acc,
              ...curr,
            };
          }, {});

        const data = {
            groupName: payloadToUpload?.groupName?.trim() || "",
            productName: payloadToUpload?.productName?.trim() || "",
            unit: payloadToUpload?.unit || "",
            l1: payloadToUpload?.l1,
            l2: payloadToUpload?.l2,
            l3: payloadToUpload?.l3,
            sku: payloadToUpload?.sku?.trim(),
            brandName: payloadToUpload?.brandName,
            brandAbr: payloadToUpload?.brandAbr,
            modelNo: payloadToUpload?.modelNo?.trim() || "",
            hsnCode: payloadToUpload?.hsnCode || "",
            baseCost:  defaultPrices?.baseCost || "",
            ...defaultPrices,
            variable1Type: payloadToUpload?.variable1Type?.trim() || null,
            variable1Value: payloadToUpload?.variable1Value?.trim() || null,
            variable2Type: payloadToUpload?.variable2Type?.trim() || null,
            variable2Value: payloadToUpload?.variable2Value?.trim() || null,
            variable3Type: payloadToUpload?.variable3Type?.trim() || null,
            variable3Value: payloadToUpload?.variable3Value?.trim() || null,
            productDescription: payloadToUpload?.productDescription?.trim() || "",
            countryOfOrigin: payloadToUpload?.countryOfOrigin?.trim() || "",
            customerCareAddress: payloadToUpload?.customerCareAddress?.trim() || "",
            marketedBy: payloadToUpload?.marketedBy?.trim() || "",
            includedComponents: payloadToUpload?.includedComponents?.trim() || "",
            warranty: payloadToUpload?.warranty?.trim() || "",
            weight: payloadToUpload?.weight?.trim() || "0",
            materialType: payloadToUpload?.materialType?.trim() || "",
            size: payloadToUpload?.size?.trim() || "",
            finish: payloadToUpload?.finish?.trim() || "",
            color: payloadToUpload?.color?.trim() || "",
            loadCapacity: payloadToUpload?.loadCapacity?.trim() || "",
            shipmentTime: parseInt(payloadToUpload?.shipmentTime) || null,
            images: payloadToUpload?.images,
            vendorCode: payloadToUpload?.vendorCode,
            vendorName: payloadToUpload?.vendorName,
            isSampleAvailable: payloadToUpload?.isSampleAvailable ? "yes" : "no",
            isTracked: payloadToUpload?.isTracked ? "yes" : "no",
            isTaxable: payloadToUpload?.isTaxable,
            isCustomOrder: payloadToUpload?.isCustomOrder,
            backOrder: payloadToUpload?.backOrder ? "CONTINUE" : "DENY",
            isActive: payloadToUpload?.isActive || false,
            salesChannel: payloadToUpload?.salesChannel,
            tags: payloadToUpload?.tags,
            bulkPackingType1: payloadToUpload?.bulkPackingType1,
            bulkPackingValue1: payloadToUpload?.bulkPackingValue1,
            bulkPackingType2: payloadToUpload?.bulkPackingType2,
            bulkPackingValue2: payloadToUpload?.bulkPackingValue2,
            multiPriceData: multiPriceData,
            userDefinedFields: payloadToUpload?.userDefinedFields,
            locations: payloadToUpload?.locations,
            franchises: payloadToUpload?.franchises,
            isParentProduct: isParentProduct,
            isVirtualProduct: isVirtualProduct,
            childProducts:payloadToUpload?.childProducts,
        }
        setLoading(true);
        axiosInstance
            .post('/product/create', {
                ...data
            })
            .then((res) => {
                setLoading(false);
                const successMessage = res?.data?.message ? res?.data?.message : 'Product Created'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setTimeout(() => {
                    navigate('/productsCreated');
                }, 2000);
            })
            .catch((error) => {
                setLoading(false);
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    useEffect(() => {
        if (payloadToUpload?.childProducts?.length !== 0) {
            handleParentProductMRP({payloadToUpload, setPayloadToUpload});
        }
    },[payloadToUpload?.childProducts])


    return (
        <Fragment>
            {loading ?  <Spinner animation='border' variant='light' /> : null}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            <div className={Classes.container} id="color">
                <form method='post' onSubmit={handleSubmit} encType="multipart/form-data" id='formData'>
                    <div className={Classes.AddProductHeader} id='header' style={{ justifyContent: 'end', marginTop: -25 }}>
                        <button
                            id='btn'
                            type="submit"
                            className={Classes.SaveBtn}
                            style={{ padding: '4px 10px', color :'#fff',fontSize: 15, border: 0 , backgroundColor: '#ff6200' }}
                        >
                            Add Product
                        </button>
                    </div>

                    <div className={Classes.AddProductContainer}>
                        <div className={[Classes.containBoxes, Classes.mainContainer].join(' ')} >
                            <ProductBasicDetails
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <MultiPricing 
                                priceCategory={'district'}
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                                endpointToFetchCategories='/pincode/getAllDistricts'
                            />

                            <MultiPricing 
                                priceCategory={'franchise'}
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                                endpointToFetchCategories='/pos/fetchAllFranchises'
                            />

                            <ChildProductsCollections 
                                setIsParentProduct={setIsParentProduct}
                                isVirtualProduct={isVirtualProduct}
                                setIsVirtualProduct={setIsVirtualProduct}
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                                action="create"
                            />

                            <UserDefinedFields payloadToUpload={payloadToUpload} setPayloadToUpload={setPayloadToUpload} />

                            <Variants
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <Inventory
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <GroupTitle
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />

                            <Media 
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />
                        </div>

                        <div className={Classes.asideContainer}>
                            <Aside
                                payloadToUpload={payloadToUpload}
                                setPayloadToUpload={setPayloadToUpload}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default AddProduct;