import React, { useState, useRef, useEffect } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Modal } from "@mui/material";
import styles from "./product.module.css";
import axiosInstance from "../../../../utility/axios-instance";
import Permission from "../../../auth/permissions";
SwiperCore.use([Navigation, Pagination, Autoplay]);
const ProductCarousel = ({ previewState, handlePreviewState, image }) => {
  const swiper = useRef(null);
  const [productItems, setProductItems] = useState([]);
  const [device, setDevice] = useState("Default");
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      image.title = "";
      image.titleColor = "";
      image.image = "";
      handlePreviewState(false);
    }
  };

  const devices = [
    { name: "iPhone X", width: 375, height: "auto", slidesPerView: 2 },
    { name: "iPad", width: 768, height: "auto", slidesPerView: 3 },
    { name: "Default", width: "100%", height: "auto", slidesPerView: 4 },
  ];

  const handleChange = (event) => {
    setDevice(event.target.value);
  };
  const getProductRangeImages = async () => {
    axiosInstance.get("/products").then((res) => {
      setProductItems(res.data.data);
    });
  };
  const selectedDevice = devices.find((d) => d.name === device);
  useEffect(() => {
    getProductRangeImages();
  }, []);
  return (
    <>
      <Modal
        open={previewState}
        onClose={(event, reason) => handleClose(event, reason)}
        className={`${styles.modalContainer}`}
      >
        <div className={`${styles.container}`}>
          <div className={`${styles.childContainer}`}>
            <div className={`${styles.selectContainer}`}>
              <select
                className={`${styles.btn}`}
                value={device}
                onChange={handleChange}
              >
                {devices.map((device) => (
                  <option
                    key={device.name}
                    value={device.name}
                    className={`${styles.options}`}
                  >
                    {device.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${styles.btnContainer}`}>
              <button className={`${styles.btn}`} onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
          <div
            className={` ${styles.swiperParent}`}
            style={{
              width:
                selectedDevice.name === "Default"
                  ? selectedDevice.width
                  : `${selectedDevice.width}px`,
              height: selectedDevice.height,

              backgroundColor: "#e2e8f0",
              border: "2px solid black",
            }}
          >
            <div
              className={`${
                selectedDevice.name === "iPhone X"
                  ? styles.storeHeaderiPhoneX
                  : styles.storeHeader
              }`}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontSize:
                    selectedDevice.name === "iPhone X" ? "1rem" : "2rem",
                }}
              >
                PRODUCT RANGE
              </h1>
              <div
                className={`${
                  selectedDevice.name === "iPhone X"
                    ? styles.storeHeaderLine1iPhoneX
                    : styles.storeHeaderLine1
                }`}
              ></div>
              <div
                className={`${
                  selectedDevice.name === "iPhone X"
                    ? styles.storeHeaderLine2iPhoneX
                    : styles.storeHeaderLine2
                }`}
              ></div>
            </div>
              <div
                className={`swiper-container`}
                style={{
                  padding: "1rem",
                }}
              >
                <Swiper
                  slidesPerView={selectedDevice.slidesPerView}
                  spaceBetween={15}
                  loop={true}
                  lazyPreloadPrevNext={selectedDevice.slidesPerView}
                  simulateTouch={true}
                  mousewheel={true}
                  navigation={true}
                  ref={swiper}
                >
                  <SwiperSlide>
                    <div className={styles.relative}>
                      <img
                        src={image?.image}
                        alt={image?.title}
                        loading="lazy"
                        className={`${styles.sliderImg}`}
                      />
                      <div
                        className={`${styles.sliderTextContainer}`}
                        style={{ color: `${image?.titleColor}` }}
                      >
                        {`${image?.title}`}
                      </div>
                    </div>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                  </SwiperSlide>
                  {productItems?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.relative}>
                        <img
                          src={item?.image}
                          alt={item?.title}
                          loading="lazy"
                          className={`${styles.sliderImg}`}
                        />
                        <div
                          className={`${styles.sliderTextContainer}`}
                          style={{ color: `${item.titleColor}` }}
                        >
                          {item.title}
                        </div>
                      </div>
                      <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductCarousel;
