import axiosInstance from "./axios-instance";

const requestSignedUrls = async (objUrlKeys, limit) => {
  const data = {
    objUrlKeys: objUrlKeys,
    limit: limit,
  };
  return await axiosInstance
    .post(`/pgOrder/admin/getSignedUrls`, data)
    .then((res) => {
      return res?.data?.data?.signedUrls;
    })
    .catch((err) => {
      alert("Error in downloading document");
      console.log(err);
    });
};

export { requestSignedUrls };
