import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import ConfirmPopup from './confirmPopUp';
import axiosInstance from '../../../utility/axios-instance';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props)  => {
    const  [transporter,setTransporter] = React.useState('');
    const  [box,setBox] = React.useState('');
    const  [weight,setWeight] = React.useState('');
    const  [packaging,setPackaging] = React.useState('');
    const [checked, setChecked] = React.useState([true, false]);
    const [packingOrders,setPackingOrders] = React.useState([]);
    const [confirmOpen,setConfirmOpen] = React.useState(false);
    const [riders,setRiders] = React.useState([]);

    const { 
        open,
        handleClose,
        packedOrders
    } = props;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'light-gray;',
            color: 'black',
            fontSize:12,
            padding:"0px 16px"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        },
    }));
    
    useEffect(() => {
        if (Array.isArray(packedOrders)) {
            const orders = [];
            for (let order of packedOrders) {
                orders.push({isChecked:false,deliveryNo:order})
            }
            setPackingOrders(orders)
        }
    },[packedOrders]);

    useEffect(() => {
        axiosInstance
            .get('/pgOrder/admin/deliveryRiders/all')
            .then((res) => {
                const riders = [];
                for (let rider of res?.data?.data?.fetchedRiders) {
                    riders.push({Id:rider?.deliveryBoyId,label:rider?.deliveryRider})
                }
                setRiders(riders)
            })
    },[])


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleChange = (event) => {
        let _items = packingOrders.map(item => {
            item.isChecked = event.target.checked
            return item;
        });
        setPackingOrders(_items)    
    };

    const handleChange1 = (event) => {
        let _items = packingOrders.map((item,index) => {
            if (index === parseInt(event.target.value)) {
                item.isChecked = event.target.checked
            }
            return item;
        })
        setPackingOrders(_items);
    };

    const handleClickOpen = () => {
        setConfirmOpen(true);
    };
    
    const handleCloseConfirm = () => {
        setConfirmOpen(false);
    };

    const handleRider = (rider,index,event) => {
        if (event.target.innerText) {
            Object.assign(packingOrders[index],{deliveryRider:rider});
        }
    }

    return (
        <div style={{width:'80%',position:'relative'}}>

            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={true}
                keepMounted
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
                style={{width:'96%',margin:"20px auto"}}
            >
                <AppBar sx={{ position: 'relative' }} style={{minHeight:34}}>
                    <Toolbar style={{padding:"0px 15px",minHeight:34}}>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div" style={{fontSize:15}}>
                            Pick All
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            style={{padding:0}}
                            sx={{"& .MuiSvgIcon-root":{fontSize:"16px"}}}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                
                {/* {(packingOrders?.length !== 0 && packingOrders.some(el => el.isChecked === true)) && ( */}
                    <div style={{margin:"10px 15px",display:'flex',justifyContent:'flex-end'}}>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "capitalize",
                                padding:'2px 10px',
                                fontSize:'13px',
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                            }}
                            disabled={(packingOrders?.length !== 0 && packingOrders.some(el => el.isChecked === true)) ? false : true}
                            onClick={handleClickOpen}
                            id='btnArea'
                        >
                            Packed Order
                        </Button>
                    </div>
                {/* )} */}

                <TableContainer component={Paper} style={{margin:10,width:'98%'}} >
                    <Table sx={{ minWidth: 'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'} }} aria-label="customized table">
                        <TableHead sx={{position:'relative'}} style={{backgroundColor:'gainsboro'}}>
                            <TableRow>
                                <StyledTableCell style={{width:'3%'}}>
                                    <input 
                                        type="checkbox"
                                        onChange={handleChange}
                                        checked={packingOrders?.length !== 0 && packingOrders?.every((item) => item.isChecked)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>Delivery</StyledTableCell>
                                <StyledTableCell style={{width:'20%'}}>Transporter</StyledTableCell>
                                <StyledTableCell style={{width:'8%'}}>Boxes</StyledTableCell>
                                <StyledTableCell style={{width:'10%'}}>Weight</StyledTableCell>
                                <StyledTableCell style={{width:'20%'}}>Packaging</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {packingOrders?.length !== 0 && packingOrders?.map((order,index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>
                                        <input 
                                            type="checkbox"
                                            onChange={handleChange1}
                                            checked={order?.isChecked}
                                            value={index} 
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell sx={{padding:'16px 10px'}}>
                                        {order?.deliveryNo}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Autocomplete
                                            disablePortal
                                            id="transporter"
                                            options={riders}
                                            name='transporter'
                                            key={order?.deliveryRider || ''}
                                            defaultValue={order?.deliveryRider || ''}
                                            renderOption={(props, option) => (
                                                 <li {...props} key={option.Id}>
                                                  {option.label}
                                                </li>
                                              )}
                                            sx={{width: '100%',"& label": {top: transporter ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => { 
                                                handleRider(newValue.label,index,event) 
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Transporting" required={true}/>}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required={true}
                                            id='box'
                                            type='number'
                                            name="box"
                                            label="Boxes"
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top:box ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        <TextField
                                            required={true}
                                            id='weight'
                                            type='number'
                                            name="weight"
                                            label="Weight"
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top:weight ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        <TextField
                                            id='packaging'
                                            name="packaging"
                                            label="Packaging"
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top:packaging ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
            
            {/* <div>
                <ConfirmPopup 
                    showConfirmPopup={confirmOpen}
                    hideConfirmPopup={handleCloseConfirm}
                    packingOrders={packingOrders}
                    packedOrders={packedOrders}
                />
            </div> */}
        </div>
    );
}


export default FullScreenDialog;
