import React, { useEffect, useState } from "react";
import { RichTreeView } from "@mui/x-tree-view";

const TreeView = (props) => {
  const [selectedNode, setSelectedNode] = useState();
  const [isLeafNode, setIsLeafNode] = useState(false);
  const [nodeHierarchy, setNodeHierarchy] = useState([]);

  // data structure for tree nodes
  const {
    setSelectedNodeHierarchy,
    setLeafNode,
    treeData=[]
  } = props;

  const handleItemSelectionToggle = (event, nodeId, isSelected) => {
    if (isSelected) {
      setSelectedNode(nodeId);

      // Find the hierarchy of the selected node
      const hierarchy = findNodeHierarchy(treeData, nodeId);
      setNodeHierarchy(hierarchy);

      // Check if it's a leaf node (no children)
      const selectedNodeData = hierarchy[hierarchy.length - 1];
      setIsLeafNode(selectedNodeData?.children?.length === 0 || selectedNodeData?.children === null);
    }
  };

  const findNodeHierarchy = (nodes, selectedNodeId, hierarchy = []) => {
    for (const node of nodes) {
      const currentHierarchy = [...hierarchy, node]; // Keep track of the current node in the hierarchy

      if (node.id === selectedNodeId) {
        return currentHierarchy; // If node is found, return the hierarchy
      }

      if (node.children && node.children.length > 0) {
        const result = findNodeHierarchy(
          node.children,
          selectedNodeId,
          currentHierarchy
        );
        if (result) return result; // Recursively search in children
      }
    }

    return null; // Node not found
  };

  useEffect(() => {
    setSelectedNodeHierarchy(nodeHierarchy);
  }, [nodeHierarchy]);

  useEffect(() => {
    setLeafNode(isLeafNode);
  }, [isLeafNode]);

  return (
    <React.Fragment>
      {treeData?.length && (
        <RichTreeView
          items={treeData}
          selectedItems={selectedNode}
          onItemSelectionToggle={handleItemSelectionToggle}
        />
      )}
    </React.Fragment>
  );
};

export default TreeView;
