import React, {Fragment, useState, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from "@mui/material";

const DefaultAddressPopUp = (props)=> {
    const textRef = useRef();
    const [searchRegex, setSearchRegex] = useState(new RegExp("", "i"));
    const [deleteAddressArray,setDeleteAddressArray] = useState([]);
    const [loading,setLoading] = useState(false);
    const {
        defaultAddressModal,
        hideDefaultAddressPopUp,
        showCustomerDetail,
        customerIDAddress,
        addressArray,
        setCustomerIDAddress,
        previousTarget,
        setPreviousTarget,
        setAddressArray
    } = props;

    const handleDeleteAddressId = (event) => {
        if (event.target.checked) {
            document.getElementById('deleteAddressBtn').style.display = 'block';
            let array = deleteAddressArray;
            array.push({id: event.target.value})
            setDeleteAddressArray(array)
        } else {
            let array = deleteAddressArray;
            const store = array.filter((ele) => ele.id !== event.target.value)
            if (store.length === 0) {
                document.getElementById('deleteAddressBtn').style.display = 'none';
            }
            setDeleteAddressArray(store)
        }
    }

    const handleDefaultAddress =(event,addressId) => {
        if(previousTarget.length !== 0){
            document.getElementById(previousTarget).style.background = 'rgb(250, 251, 251)';
            document.getElementById(previousTarget).style.color = '#000';
        }
        setPreviousTarget([event.target.id])
  
        event.target.style.background= '#008060';
        event.target.style.color = '#fff';

        axiosInstance
            .get(`/user/admin/${showCustomerDetail[0]?._id}/address/${addressId}`)
            .then((res) =>{
                setCustomerIDAddress([res.data.data]);
        });
    }

    const handleDeleteAddress = async (event) => {
        if (deleteAddressArray.length !== 0) {
            setLoading(true);
            const array = [];
            for (let ele of deleteAddressArray) {
                array.push(ele.id)
            }
            await axiosInstance
                .post(`/user/admin/${showCustomerDetail?.[0]?._id}/deleteAddressInBulk`, {
                    addressId: array
                })
                .then(async (res) =>{
                    await axiosInstance
                        .get(`/user/admin/${showCustomerDetail?.[0]?._id}/address`)
                        .then((resp) => {
                            const arr = []
                            setDeleteAddressArray(arr);
                            if (resp.data.data !== undefined) {
                                setAddressArray(resp.data.data);
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log(err,'error')
                        })
                });
        }
    }

    const capitalizeState = (text) => {
        const arr = text.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
		return str2;
	}

    const onChangeText = (value) => {
        clearTimeout(textRef.current);
        textRef.current = setTimeout(() => {
            setSearchRegex(new RegExp(value, "i"));
        }, 500);
    }

    return (
        <Fragment>
            {defaultAddressModal && (
                <div className={Classes.modal} style={{bottom:'6%', zIndex:'9999'}}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage address</p>
                            <div style={{display:'flex'}}>
                                <p 
                                    className={Classes.DeleteAddressBtn}
                                    id='deleteAddressBtn'
                                    onClick={handleDeleteAddress}
                                >
                                    Delete Address
                                </p>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    className={Classes.ClosePopUpIcon}
                                    onClick={hideDefaultAddressPopUp}
                                />
                            </div>
                        </div>

                        <div className={Classes.BoxContent} style={{
                            padding: "0px 25px 20px 25px"
                        }}>
                            <TextField size="small" type="text" label="Search via nick name" onChange={(e) => {
                                onChangeText(e.target.value)
                            }} />
                        </div>
                    </div>
                    
                    <div style={{overflowY: 'auto',height: 486 }}>
                        <div style={{ marginTop: '10px', padding: '10px 20px' }}>
                            <div>
                                <p style={{ fontWeight: '600', fontSize: '14px' }}>
                                    SELECT ADDRESS
                                </p>
                            </div>
                        </div>

                        {showCustomerDetail?.length !== 0 ?
                            <div>
                                {addressArray?.map((address, index) => (
                                    <>
                                        {address?.addressType === 'shipping' && searchRegex.test(address?.nick_name ?? "") && (
                                            <div
                                                key={index}
                                                style={{
                                                    borderBottom: '1px solid #ced4da',
                                                    padding: '20px'
                                                }}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ width: '5%' }}>
                                                        <input
                                                            type="checkbox"
                                                            id={index}
                                                            value={address?._id}
                                                            onChange={handleDeleteAddressId}
                                                        />
                                                    </div>
                                                    <div className={Classes.AddressContainer}>
                                                        {address.nick_name ? <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.nick_name}
                                                            </p>
                                                        </div> : null}

                                                        <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.firstName}
                                                            </p>
                                                            {address?.lastName !== 'undefined'
                                                                ? <p>{address?.lastName}</p>
                                                                : null
                                                            }
                                                        </div>

                                                        {address.nick_name ? <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.nick_name}
                                                            </p>
                                                        </div> : null}

                                                        <div>
                                                            <p>{address?.address1}</p>
                                                            {address?.address2 !== null
                                                                ? <p>{address?.address2}</p>
                                                                : ''}
                                                        </div>

                                                        <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.zip},
                                                            </p>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.city}
                                                            </p>
                                                            <p>{capitalizeState(address?.province)}</p>
                                                        </div>
                                                        <div>
                                                            <p>{address?.country}</p>
                                                        </div>

                                                        <div>
                                                            <p>{address?.phone}</p>
                                                        </div>

                                                    </div>

                                                    <div>
                                                        <div>
                                                            <p style={{ textAlign: 'center' }}>
                                                                Address Type
                                                            </p>
                                                            <p
                                                                className={Classes.AddressType}
                                                                style={{
                                                                    backgroundColor: address?.addressType === 'shipping' ?
                                                                        'turquoise' : 'greenyellow'
                                                                }}
                                                            >
                                                                {address?.addressType}
                                                            </p>
                                                        </div>

                                                        {customerIDAddress?.length !== 0 ? (
                                                            <div style={{ marginTop: 60 }}>
                                                                {customerIDAddress[0]?._id === address?._id ? (
                                                                    <button
                                                                        id={address?._id}
                                                                        disabled={true}
                                                                        className={Classes.selectedAddress}
                                                                    >
                                                                        Selected address
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        id={address?._id}
                                                                        className={Classes.selectAddress}
                                                                        onClick={(e) => handleDefaultAddress(e, address?._id)}
                                                                    >
                                                                        Select address
                                                                    </button>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div style={{ marginTop: 60 }}>
                                                                <button
                                                                    id={address?._id}
                                                                    className={Classes.selectAddress}
                                                                    onClick={(e) => handleDefaultAddress(e, address?._id)}
                                                                >
                                                                    Select address
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                            : null}
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DefaultAddressPopUp;