import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Classes from "./css/Modal.module.css";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../utility/axios-instance";
import Classes from "../css/asideModal.module.css";
import CustomerModal from "./newCustomerModal";
import InfiniteScroll from "react-infinite-scroll-component";

const AsideModal = (props) => {
  const [customers, setCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [toggleBtn, setToggleBtn] = useState(true);
  const [searchPage, setSearchPage] = useState(1);
  const [page, setPages] = useState(1);
  const [maxPages, setMaxPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  // newCustomer Model
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [gstNO, setGstNO] = useState();
  const [num, setNum] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");

  const {
    setDetail,
    searchCustomer,
    toggleButton,
    setToggleButton,
    setCustomerIDAddress,
    setPreviousTarget,
    setBillingAddress,
    fetchCustomerRoleDB,
    toggle,
    setToggle,
    setAddressArray,
    setSalesUser,
    setPreSalesUser,
    setAffiliateUser,
    estimateUserId,
    setGstNumber
  } = props;

  const getModal = () => {
    setShowModal(true);
    props.onHideModal();
  };

  const hideModal = () => {
    props.onHideModal();
    setDisplayName("");
    setFirstName("");
    setLastName("");
    setBusinessName("");
    setNum("");
    setGstNO("");
    setErrorMessage("");
    setShowModal(false);
    setSelectedState("");
    setCity("");
    setArea("");
  };

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("modal");
    if (container !== null) {
      if (!container.contains(e.target)) {
        props.onHideModal(false);
      }
    }
  });

  const handleCustomer = (customer) => {
    props.onHideModal();
    setToggleBtn(true);
    setCustomerInfo(customer);
  };

  useEffect(() => {
    if (estimateUserId) {
      handleCustomer(estimateUserId);
    }
  }, [estimateUserId]);

  useEffect(() => {
    if (toggleButton) {
      const debounce = 500
      const timer = setTimeout(() => {
      axiosInstance
        .get(`/user/admin?text=${searchCustomer}&page=${searchPage}&type=createOrder`)
        .then((res) => {
          setMaxPage(res.data.data.maxPage);
          setCustomers(res.data.data.users);
          if (res.data.data.maxPages >= searchPage) {
            setSearchPage(searchPage + 1);
            setPages(2);
          }
          setToggleButton(false);
        });
      }, debounce)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [toggleButton, searchCustomer, setToggleButton, searchPage]);

  useEffect(() => {
    if (toggle) {
      axiosInstance.get(`/user/admin`).then((res) => {
        setCustomers(res.data.data.users);
        setTotal(res.data.data.total);
        setMaxPage(res.data.data.maxPage);
        setPages(2);
        setToggle(false);
      });
    }
  }, [toggle, setToggle]);

  useEffect(() => {
    if (toggleBtn) {
      if (customerInfo?.length > 0) {
        axiosInstance.get(`/user/admin/${customerInfo}`).then((res) => {
          setDetail([res.data.data]);
          setToggleBtn(false);
          if(res.data.data?.gstNumbers?.length !== 0) {
            setGstNumber(res.data.data?.gstNumbers[0])
          }

          if (res?.data?.data.affiliateUser) {
            const affiliateUser = {
              label: res?.data?.data.affiliateUser?.name,
              Id: res?.data?.data.affiliateUser?.id,
            };
            setAffiliateUser(affiliateUser);
          }
          if (res?.data?.data.salesUser) {
            const salesUser = {
              label: res?.data?.data.salesUser?.name,
              Id: res?.data?.data.salesUser?.id,
            };
            setSalesUser(salesUser);
          }
          if (res?.data?.data.preSalesUser) {
            const preSalesUser = {
              label: res?.data?.data.preSalesUser?.name,
              Id: res?.data?.data.preSalesUser?.id,
            };
            setPreSalesUser(preSalesUser);
          }
          axiosInstance
            .get(`/user/admin/${customerInfo}/address`)
            .then((res) => {
              if (res.data.data.length !== 0) {
                setAddressArray(res.data.data);
                const addresses = res.data.data;
                const shippingAddressArrays = [];
                const BillingAddressArrays = [];
                for (let address of addresses) {
                  if (address.addressType === "shipping") {
                    shippingAddressArrays.push(address);
                  } else {
                    BillingAddressArrays.push(address);
                  }
                }

                if (shippingAddressArrays.length !== 0) {
                  setCustomerIDAddress([...shippingAddressArrays]);
                }

                if (BillingAddressArrays.length !== 0) {
                  setBillingAddress([...BillingAddressArrays]);
                } else {
                  setBillingAddress([]);
                }

                setPreviousTarget([]);
                setToggleBtn(false);
              } else {
                setBillingAddress([]);
                setCustomerIDAddress([]);
                setAddressArray([]);
              }
            });
        });
      }
    }
  }, [
    toggleBtn,
    setDetail,
    customerInfo,
    setBillingAddress,
    setCustomerIDAddress,
    setPreviousTarget,
    setAddressArray,
  ]);

  const fetchMoreData = () => {
    if (maxPages >= page) {
      setTimeout(() => {
        axiosInstance
          .get(`/user/admin?page=${page}&text=${searchCustomer}`)
          .then((res) => {
            if (res.data.data.maxPage >= page) {
              setPages(page + 1);
            } else {
              setPages(1);
            }
            setCustomers([...customers, ...res.data.data.users]);
          });
      }, 1500);
    }
  };

  return (
    <Fragment>
      {props.showModal && (
        <div className={Classes.modal} id='modal'>
          <div className={Classes.ContactSearch} onClick={() => getModal()}>
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{
                fontSize: "17px",
                paddingRight: "10px",
                color: "rgb(129, 138, 155)",
                paddingTop: "3px",
              }}
            />
            <p>Create a new customer</p>
          </div>
          <div style={{ height: "350px" }}>
            {customers?.length !== 0 && (
              <div>
                <InfiniteScroll
                  dataLength={customers?.length}
                  next={fetchMoreData}
                  hasMore={true}
                  loader={page - 1 !== maxPages ? <h6>Loading...</h6> : ""}
                >
                  {customers?.map((item, index) => (
                    <div
                      key={index}
                      className={Classes.styles}
                      onClick={() => handleCustomer(item?._id)}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          {item?.firstName !== "undefined" ? (
                            <p style={{ marginRight: "5px" }}>
                              {item?.firstName}
                            </p>
                          ) : null}

                          {item?.lastName && <p>{item?.lastName}</p>}
                        </div>
                      </div>
                      <div>
                        <p style={{ color: "gray" }}>{item?.phone}</p>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
        <CustomerModal
          onShowModal={showModal}
          setModal={setShowModal}
          onHideModal={hideModal}
          setDetail={setDetail}
          num={num}
          setNum={setNum}
          gstNO={gstNO}
          setGstNO={setGstNO}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          fetchCustomerRoleDB={fetchCustomerRoleDB}
          displayName={displayName}
          setDisplayName={setDisplayName}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          businessName={businessName}
          setBusinessName={setBusinessName}
          endpoint={`/user/admin`}
          setSelectedState={setSelectedState}
          selectedState={selectedState}
          setCity={setCity}
          city={city}
          setArea={setArea}
          area={area}
        />
      </div>
    </Fragment>
  );
};

export default AsideModal;
