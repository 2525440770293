import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Alert, AlertTitle } from "@mui/material";
import axiosInstance from "../../../../utility/axios-instance";
import { Add } from "@mui/icons-material";
import Permission from "../../../auth/permissions";
import SettingsItem from "../../../../components/settingsMenu";
import Classes from "../dashboard.module.css";
import CreateStoreModal from "./location/createStoreLoc";

const Store = () => {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [reRender,setReRender] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();

  const fetchStores = async () => {
    setError(null);
    axiosInstance
      .get(`/adminRoutes/stores/locations`)
      .then((res) => {
        setStores(res.data.data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    fetchStores();
  }, [reRender]);

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };
  return (
    <>
      {error ? (
        <Alert severity="error">
          <AlertTitle>{error}</AlertTitle>
          Try Reloading!
        </Alert>
      ) : null}
      <div style={{ padding: "1rem 1rem" }}>
        <Permission service="store" permission="create">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={() => setShowCreateModal(true)}
            className="mt-4"
          >
            Add New Store
          </Button>
        </Permission>
        <div className={Classes.Container}>
          {stores?.map((store, index) => (
            <SettingsItem
              key={index}
              onClick={() => navigate(`/settings/website/stores/${store.city}`)}
              iconSrc="/assets/icons/store.png"
              iconAlt={store.city}
              title={store.city}
            />
          ))}
        </div>
      </div>
            
      {showCreateModal && (
        <CreateStoreModal
          open={showCreateModal}
          handleClose={closeCreateModal}
          setReRender={setReRender}
        />
      )}
    </>
  );
};

export default Store;
