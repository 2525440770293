import { Backdrop, Button, Autocomplete, TextField} from '@mui/material';
// import { Close } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import {Snackbar, Alert} from '@mui/material';
import axiosInstance from '../utility/axios-instance';

const SelectCompany = (props) => {
    const {open, setOpen, func} = props;
    const [error, setError] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);

    const getCompanies = async () => {
        axiosInstance.get(`/adminRoutes/companies?sort=${JSON.stringify({"field":"created_at","sort":"desc"})}`).then((res) => {
            setCompanies(res.data.data.companiesDetails);
        }).catch(err => {
          setError(err?.response?.data?.message)
        });
    }

    useEffect(() => {
        getCompanies()
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        func?.(company.id);
        setOpen(false)
    }

    return <Backdrop open={open} onClick={() => {
                setOpen(false)
            }}>
                <div
      style={{
        width: "90%",
        maxWidth: "500px",
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '1rem',
        paddingTop: '8px',
        color: '#000',
        position: 'relative'
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Snackbar open={open && error} autoHideDuration={3000}>
            <Alert  severity='error' sx={{ width: '100%' }}>
                {error?.toString()}
            </Alert>
		</Snackbar>

      <h6>Select Company</h6>

      {/* <Close
        style={{
            position: 'absolute',
            top: '8px',
            right: '1rem',
            cursor: 'pointer'
        }}
        onClick={() => {
          setOpen(false)
        }}
      /> */}

      <form
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
            gap: '1rem'
        }}
        onSubmit={onSubmit}
      >

        <div style={{
            width: '90%'
        }}>

            <Autocomplete
              id="company-autocomplete"
              value={company}
              options={companies.map(e => ({id: e.id, label: e.company_name}))}
              renderOption={(props, option) => (
                                                 <li {...props} key={option.id}>
                                                  {option.label}
                                                </li>
                                              )}
              name='company'
              required
              sx={{width: '100%',"& label": {top: company ? "0%": "-16%", fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
              onChange={(event, newValue) => { 
                  setCompany(newValue)
              }}
              renderInput={(params) => <TextField {...params} label="Company" required={true}/>}
          />
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%'
        }}>
          <Button
          type='button'
          variant='contained'
          size='small'
          style={{
            width: "120px",
            backgroundColor: 'rgb(220 38 38)'
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>

          <Button
          type='submit'
          variant='contained'
          size='small'
          style={{
            width: "120px",
          }}
        >
          Submit
        </Button>
        </div>
      </form>
    </div>
            </Backdrop>
}

export default SelectCompany