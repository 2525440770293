import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utility/axios-instance";
import Classes from "./css/blog.module.css";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import Table from '../../../components/table/table';
import Chip from '@mui/material/Chip';
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Spinner from '../../../components/spinner';
import { useSelector,useDispatch } from 'react-redux';
import { setBlogs } from "../../../store/blogsSlice";

export const Blog = () => {
	// const [blogs, setBlogs] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate();

	const blogs = useSelector((state) => state.blogs);
	const pinTab = useSelector((state) => state.pinTab);
	const dispatch = useDispatch();
	const columns = [
        { 
			field: 'title', 
			filterable: false, 
			headerName: 'TITLE', 
			flex: 2, 
			headerClassName: 'super-app-theme--header', 
			sortable: true
		},
        {
            field: 'status',
            headerName: 'STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: true,
            flex: 1,
            renderCell: (params) => (
                params.value !== "active" ? (
                    <Chip color="error" size="small" label={params.value} />
                ) : (
                    <Chip color="success" size="small" label={params.value} />
                )
            )
        },
	]

	useEffect(() => {
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Blog')) {
			setLoading(true)
			axiosInstance
				.get("/blogs")
				.then((response) => {
					dispatch(setBlogs(response?.data?.data));
					setLoading(false)
				})
				.catch((error) => {
					setError(error?.response?.data?.message);
					setLoading(false)
				});
		}
	}, [])


	return (
		<>
			<div className={Classes.Container}>
				<div className={Classes.UsersHead}>
					<Link to="/blog/createBlog">
						<Button startIcon={<AddIcon />} variant="contained" color="primary">
							Create
						</Button>
					</Link>
				</div>

				<div className='d-none d-md-block' >
					<Table
						columns={columns}
						dataPosition={"blogs"}
						endpoint={`/blogs`}
						rowId={'_id'}
						navigateOnRowClickEndpoint={"/blog"}
						version="version-1"
						service={'blog'}
						tabName={'Blog'}
						setData={setBlogs}
						data={blogs}
					/>
				</div>

				<div className="d-block d-sm-none" style={{ margin: '15px 0px' }}>
					<table className={`${Classes.Table} table`} style={{}}>
						<thead className={Classes.TableHead} >
							<tr>
								<th scope="col">Title</th>
								<th scope="col">
									<div style={{ width: '100%', textAlign: 'center' }}>Active</div>
								</th>
							</tr>
						</thead>
						<tbody
							className={Classes.TableBody}
						>
							{blogs?.blogs?.map((blog) => (
								<tr
									key={blog.title}
									style={{
										cursor: 'pointer',
										border: '1px solid #dee2e6',
									}}
									onClick={() => {
										navigate(`/blog/${blog._id}`);
									}}
								>
									<th scope="row">{blog.title}</th>
									<td>
										<div style={{ width: '100%', textAlign: 'center' }}>
											{blog.status === "active" ? (
												<BsCheckCircleFill color="green" />
											) : (
												<BsXCircleFill color="red" />
											)}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			{loading && <Spinner />}
		</>
	);
};
