import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";

const SearchInput = ({ searchFor, handleSearch }) => {
    return (
        <div style={{ width: '100%' }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

export default SearchInput;