import { Fragment } from "react";
import ProductStatus from "./productStatus";
import Aside2ndBox from "./aside2ndBox";

const Aside = (props) => {
  const { payloadToUpload, setPayloadToUpload } = props;

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("contactOutline");
    if (container !== null) {
      if (!container.contains(e.target)) {
        container.style.border = "1px solid rgb(206, 212, 218)";
      }
    }
  });

  return (
    <Fragment>
      <Aside2ndBox
        payloadToUpload={payloadToUpload}
        setPayloadToUpload={setPayloadToUpload}
      />

      <ProductStatus
        payloadToUpload={payloadToUpload}
        setPayloadToUpload={setPayloadToUpload}
      />
    </Fragment>
  );
};

export default Aside;
