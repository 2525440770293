import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const PermissionError = () => {
  return (
    <div>
      <h1>Permission Error</h1>
      <p>You do not have permission to access this page.</p>
      <Button component={Link} to="/" variant="contained" sx={
        {
          backgroundColor: "#ff6200",
          color: "white",
          "&:hover": {
            backgroundColor: "#ff9552",
          },
          marginTop: "10px",
        }
      }>
        Go to Home
      </Button>
    </div>
  );
};

export default PermissionError;
