import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, selectedOptions, theme) {
  return {
    fontWeight:
      selectedOptions.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({label, selectedOptions, setSelectedOptions, options,width, service}) {
  const theme = useTheme();
  
  const handleChange = (event) => {
    const { target: { value } } = event;
    if (service) {
      for (let ele of service?.formData?.service) {
        if(ele?.service === service?.service) {
          ele.permissions = value;
        }
      }
      setSelectedOptions({...service?.formData})
    } else {
      setSelectedOptions(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    }
  };

  return (
    <div>
      <FormControl sx={{ minWidth: width ? width: 180 }} size="small">
        <InputLabel id="demo-multiple-option-label">{label}</InputLabel>
        <Select
          labelId={label}
          id={label}
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(option, selectedOptions, theme)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}