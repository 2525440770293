import Table from "../../../components/table/table";
import { useSelector } from 'react-redux';
import { setInventoryParticularDate } from "../../../store/inventoryParticularDate";

const columns = [
	{
		field: 'sku',
		headerName: 'SKU',
		flex: 1.5,
		headerClassName: 'super-app-theme--header',
	},
	{
		field: 'title',
		headerName: 'PRODUCT',
		flex: 3,
		headerClassName: 'super-app-theme--header',
	},
	{
		field: 'avialableQty',
		headerName: 'AVAILABLE QTY',
        align: 'center',
		flex: 1,
		headerClassName: 'super-app-theme--header',
	},
	{
		field: 'locationCode',
		headerName: 'Location Code',
		flex: 1,
		headerClassName: 'super-app-theme--header',
        type: 'enum',
        tableName: 'Locations',
        columnName: 'locationCode'
	},
	{
		field: 'createdAt',
		headerName: 'Created At',
		flex: 1.7,
		headerClassName: 'super-app-theme--header'
	},
];

const columnVisibilityModel = { createdAt: false }

const InventoryOnParticularDate = (props) => {
    const { date } = props;
    const inventoryParticularDate = useSelector((state) => state?.inventoryParticularDate);

    return (
        <div style={{marginTop:10}}>
            <Table
                columns={columns}
                dataPosition={"fetchedInventoryOnParticularDate"}
                endpoint={`/inventory/fetchInventoryByParticularDate`}
                rowId={'id'}
                version="version-1"
                service={'inventory'}
                tabName={'Inventory'}
                setData={setInventoryParticularDate}
                data={inventoryParticularDate}
                columnVisibilityModel={columnVisibilityModel}
                date={date}
            />
        </div>
    )
}

export default InventoryOnParticularDate;