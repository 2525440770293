import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedPincodes: [],
};

const pincodesSettingsSlice = createSlice({
	name: 'pincodes',
	initialState: initialState,
	reducers: {
		setPincodes: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setPincodes, reset } = pincodesSettingsSlice.actions;

export default pincodesSettingsSlice.reducer;