import * as React from 'react';
import axiosInstance from '../../utility/axios-instance';
import '../../pages/home/createOrders/css/styles.css';
import SnackBar from '../SnackBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Tooltip } from '@mui/material';
import Spinner from '../spinner';

export default function FormDialog(props) {
    const {
        dialogTitle,
        dialogActionType,
        openDialogButtonName,
        openDialogButtonType,
        iconForOpenDialogButton,
        submitDialogButtonName,
        formData,
        setFormData,
        endPoint,
        defaultFormData,
        children,
        setData,
        afterFormDialogOpen,
        afterFormDialogSubmit,
        beforeFormDialogOpen,
        onConfirmation,
        setReRender,
        openDialog,
        setOpenDialog,
        height,
        width
    } = props;
    const [open, setOpen] = React.useState(false);
    const [loading,setLoading] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })

    const handleClickOpen = () => {
        beforeFormDialogOpen && beforeFormDialogOpen()
        setOpen(true);
        afterFormDialogOpen && afterFormDialogOpen()
    };

    const handleClose = () => {
        setOpen(false);
        setOpenDialog && setOpenDialog(false)
    };

    const handleFormSubmit = (event) => {
        setLoading(true);
        const payload = formData;
        if (dialogActionType === 'create') {
            axiosInstance
                .post(endPoint, payload)
                .then((res) => {
                    setOpen(false);
                    setFormData && setFormData(defaultFormData);
                    const successMessage = res?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setData && setData(res?.data?.data);
                    setReRender && setReRender((prev) => !prev);
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
        } else if (dialogActionType === 'update') {
            axiosInstance
                .put(endPoint, payload)
                .then((res) => {
                    setOpen(false);
                    setFormData && setFormData(defaultFormData);
                    const successMessage = res?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setReRender && setReRender((prev) => !prev);
                    afterFormDialogSubmit && afterFormDialogSubmit()
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
        } else if (dialogActionType === 'delete') {
            axiosInstance
                .delete(endPoint)
                .then((res) => {
                    setOpen(false);
                    const successMessage = res?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    afterFormDialogSubmit && afterFormDialogSubmit();
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
        } else if (dialogActionType === 'confirmation') {
            onConfirmation()
            handleClose()
        }
        setLoading(false);
    }

    let openDialogButton = <Button style={{ textTransform: 'none' }} onClick={handleClickOpen} variant='outlined'>
        {openDialogButtonName}
    </Button>
    if (openDialogButtonType === 'icon' && iconForOpenDialogButton) {
        openDialogButton = 
            <Tooltip title={openDialogButtonName}>
                <IconButton onClick={handleClickOpen}>
                    {iconForOpenDialogButton}
                </IconButton>
            </Tooltip>
    };

    return (
        <div>
            {openDialogButton}
            <Dialog open={openDialog || open} onClose={handleClose} sx={{'& .MuiDialogContent-root': {height:height,width:width,paddingTop:'10px'}}}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {loading ? <Spinner /> : null}
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {submitDialogButtonName && <Button onClick={handleFormSubmit}>{submitDialogButtonName}</Button>}
                </DialogActions>
            </Dialog>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
        
    );
}