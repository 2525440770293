import React, { Fragment, useState } from "react";
import Classes from "./css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';


const DiscountPopup = (props) => {
    const [discountType,setDiscountsType] = useState('Amount');
    const [discountAmount,setDiscountAmount] = useState('0.00');
    const [reason,setReason] = useState('');


    const {
        discountModal,
        hideDiscountPopup,
        productDetails,
        setProduct,
        setOverAllDiscount,
        overAllDiscount,
        shippingPrice,
        showDiscountType,
        setDiscountType,
        setLoadingPurchaseOrderCalculationServiceState
    } = props;

    const handleSubmit = (event) => {
        event.preventDefault();
        const value = parseInt(event.target.discountAmount.value, 10);
        // If the value is negative
        if (value < 0) {
            alert('Please enter valid number');
            event.target.discountAmount.value = ''
            return;
        }

        const percentage = document.getElementById('discountType').value;
        if (percentage === 'Percentage' && value > 100) {
            alert('Discount Must be less than 100%');
            event.target.discountAmount.value = ''
            return;
        }

        hideDiscountPopup();

        let discount = event.target.discountType.value;

        let appliedDiscount = {
            value: JSON.parse(event.target.discountAmount.value),
            valueType: discount === 'Amount' ? "FIXED_AMOUNT" : "PERCENTAGE",
            title: event.target.reason.value
        }
       

        const variantLineItems = productDetails.map(obj => {
            const { variantId, ...rest } = obj;
            return { productId: variantId, ...rest };
        });


        let data =  {
            lineItems: variantLineItems,
            overallDiscount: appliedDiscount,
            shippingLine: shippingPrice.length !== 0 ? shippingPrice[0] : undefined
        }
      
        setOverAllDiscount([appliedDiscount]);

        setLoadingPurchaseOrderCalculationServiceState(true);
        const endpoint = '/calculate/purchaseOrder'
        axiosInstance
            .post(endpoint, { ...data })
            .then((res) => {
                setProduct(res.data);
            })
            .catch((error) => {
                const errorMessage = error.response.data.message;
                alert(errorMessage || 'An error occurred while calculating the purchase order');
            })
            .finally(()=>{
                let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
                calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
                if (calculatePurchaseOrderRoutePendingCount === 0) {
                    setLoadingPurchaseOrderCalculationServiceState(false);
                }
            })
    }


    return (
        <Fragment>
            {discountModal && (
                <div className={Classes.modal} style={{ bottom:'36%',top:'16%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Add Discount</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='discountForm'>
                        <div style={{ padding:'30px 20px',overflowY:'auto',height:200 }}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div style={{width:'48%'}}>
                                    {overAllDiscount?.length !== 0 ? (
                                        <div>
                                            <Autocomplete
                                                disablePortal
                                                id="discountType"
                                                name="discountType"
                                                key={overAllDiscount[0]?.valueType ===  "FIXED_AMOUNT" ? "Amount": "Percentage"}
                                                defaultValue={overAllDiscount[0]?.valueType ===  "FIXED_AMOUNT" ? "Amount": "Percentage"}
                                                options={["Amount","Percentage"]}
                                                sx={{width: '100%',"& label": {top: discountType ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                                onChange={(event, newValue) => { setDiscountsType(newValue) }}
                                                renderInput={(params) => <TextField {...params} label="Discount Type"/>}
                                            />
                                        </div>
                                    ):(
                                        <Autocomplete
                                            disablePortal
                                            id="discountType"
                                            name="discountType"
                                            options={["Amount","Percentage"]}
                                            key={"Amount"}
                                            defaultValue={"Amount"}
                                            sx={{width: '100%',"& label": {top: discountType ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => { setDiscountsType(newValue) }}
                                            renderInput={(params) => <TextField {...params} label="Discount Type"/>}
                                        />
                                    )}
                                </div>

                                <div style={{width:'48%'}}>
                                    <TextField 
                                        id='discountAmount'
                                        name="discountAmount"
                                        label="Discount Amount"
                                        onChange={(event) => setDiscountAmount(event.target.value)}
                                        key={overAllDiscount?.length !== 0 ? overAllDiscount[0]?.value : '0.00'}
                                        defaultValue={overAllDiscount?.length !== 0 ? overAllDiscount[0]?.value : '0.00'}
                                        placeholder='Please Enter Discount'
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: discountAmount ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />

                                    <div 
                                        id='RestrictDiscount' 
                                        className={Classes.DiscountRestrictMsg}
                                    >
                                        <p>Discount Can't be Negative</p>
                                    </div>

                                    <div 
                                        id='RestrictDiscountPercentage' 
                                        className={Classes.DiscountRestrictMsg}
                                    >
                                        <p>Discount Must be less than 100%</p>
                                    </div>
                                </div> 
                            </div>

                            <div style={{marginTop:20}}>
                                <TextField 
                                    id='reason'
                                    name="reason"
                                    label="Reason"
                                    onChange={(event) => setReason(event.target.value)}
                                    key={overAllDiscount?.length !== 0 ? overAllDiscount[0]?.title : ''}
                                    defaultValue={overAllDiscount?.length !== 0 ? overAllDiscount[0]?.title : ''}
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top: reason ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                />
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'36%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideDiscountPopup}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                APPLY
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </Fragment>
    );
}


export default DiscountPopup;