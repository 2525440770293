import { MdOutlineCancel } from 'react-icons/md';

const Search = (props) => {
	return (
		<form
			className="d-flex align-items-center mb-3"
			style={{
				gap: '0.5rem',
			}}
			onSubmit={(e) => {
				e.preventDefault();
				props.func();
			}}
		>
			<div
				className="form-group flex-grow-1 d-flex align-items-center"
				style={{ gap: '0.5rem' }}
			>
				<input
					type="text"
					className="form-control w-100"
					id="search"
					autoComplete='off'
					placeholder="Search here"
					value={props.text}
					onChange={(e) => props.setText(e.target.value)}
				/>
				<MdOutlineCancel
					color="grey"
					size={32}
					style={{
						cursor: 'pointer',
					}}
					onClick={() => {
						props.setText('');
					}}
				/>
			</div>
			<button
				type="submit"
				className="btn btn-light"
				style={{ backgroundColor: '#ff6200', color: '#fff' }}
			>
				Search
			</button>
		</form>
	);
};

export default Search;
