import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from '../../../utility/axios-instance';
import Button from '@mui/material/Button';
import FormDialog from './Components/formDialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MultipleSelect from './Components/multipleSelect';
import NewPagination from '../../../components/newPagination';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SyncIcon from '@mui/icons-material/Sync';
import Stack from '@mui/material/Stack';
import { downloadCSV } from '../../../utility/downloadCSV';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState, Fragment, forwardRef } from 'react';
import moment from 'moment';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Roles = () => {
	const [roles, setRoles] = useState([])
	const [open, setOpen] = useState(false);
	const [openDeleteDiaglog, setOpenDeleteDialog] = useState(false);
	const [openEditDiaglog, setOpenEditDialog] = useState(false);
	const [formData, setFormData] = useState({})
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [toBeDeletedRole, setToBeDeletedRole] = useState({});
	const [openSyncDialog, setOpenSyncDialog] = useState(false)
	const [servicesOptions, setServicesOptions] = useState([]);
	const [filter, setFilter] = useState({ field: 'role', value: '' });
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ field: 'role', sort: 'asc' });

	const columns = [
        { 
            field: 'role', 
            headerName: 'ROLE', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'services',
            headerName: 'SERVICES',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: true,
            flex: 4.8,
            renderCell: (params) => (
                ['super-admin', 'admin'].includes(params?.row?.role) ? (
                    <div>
                        <Chip label={"All"} style={{ margin: '3px' }} />
                    </div>
                ) : (
                    Array.isArray(params?.value) ? (
                        <div>
                            {params?.value?.slice(0,5)?.map((service, index) => (
                                <Chip key={index} label={service?.service} style={{ margin: '3px' }} />
                            ))}
                            {params?.value?.length > 5 ? (
                                <Tooltip title={
                                    <div>
                                        {params?.value?.slice(5)?.map((service, index) => (
                                            <p>{service?.service}, </p>
                                        ))}
                                    </div>
                                } arrow>
                                <Chip size="small" label={`+ ${params?.value?.length - 5} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                                </Tooltip>
                            ) : null}
                        </div>
                    ) : (
                        <Chip label={params?.value} style={{ margin: '3px' }} />
                    )
                )
            )
        },
		{ 
            field: 'createdAt', 
            headerName: 'CREATED AT', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
            filterable: false, 
            sortable: true 
        },
		{
			field: 'permissions', 
            headerName: '',
            headerClassName: 'super-app-theme--header', 
            filterable: false,
			flex: 0.4, 
            sortable: false,
			renderCell: (params) => (
				<BorderColorIcon sx={{cursor : 'pointer', color : 'gray'}}
					fontSize='small' 
					onClick={(event) => handleEditDialog(event,params?.row)}
				/>
            ),
		}
    ];

    if (document.querySelector('.MuiDataGrid-footerContainer')) {
        document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
    }
 
	const fetchServices = async () => {
		await axios
		.get(`/admin/fetchServices`)
		.then((res) => {
			const fetchedServices = res?.data?.data
			setServicesOptions([...fetchedServices])
			return res.data
		})
		.catch((error)=>{
			const errorMessage = error?.response?.data?.message
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
			});
		});
	};

	const fetchRoles = async (page) => {
		const params = {
            page,
            text: search ? search : '',
            filter: JSON.stringify(filter),
            sort,
        };
        
		await axios
			.get(`/roles/fetchData`, {
                params,
            })
			.then((res) => {
				const fetchedData = res?.data?.data;
				setRoles([fetchedData]);
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const exportRoles = async () => {
		await axios
			.get(`/roles/export`)
			.then((res) => {
				const fetchedData = res?.data?.data
				const successMessage = res?.data?.message
				downloadCSV(fetchedData?.fetchedRoles, `Roles Export - ${new Date()}`)
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const handleImport = (event) => {
        event.preventDefault();
        var formdata = new FormData();
        formdata.append(
            "roleServicesPermissions",
            document.querySelectorAll('input[type="file"]')[0].files[0]
        );

        axios
            .post("/others/roleServicesPermissions", formdata)
            .then((res) => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
            })
            .catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
    };

	const deleteRole = async ({role, service}) =>{
		await axios
			.delete(`/roles/?role=${role}&service=${service}`)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
			setOpenEditDialog(false);
			const obj = {}
			setFormData(obj)
	}

	const editRole = async (role, service, permissions) =>{
		const payload = {
			roleName: role,
			service: service,
			permissions: permissions.join(","),
		}
		await axios
			.put(`/roles`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	}

	const syncAdminsWithRole = async () =>{
		await axios
			.put(`/admin/syncPermissionsFromRoles`)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	}

	const createRole = async ({ role, service }) => {
		const payload = {
			roleName: role,
			service: service,
			permissions: selectedOptions.join(","),
		}
		await axios
			.post(`/roles/createRole`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page');
		fetchRoles(page ? page : 1)
		fetchServices()
	}, [search, filter, sort,snackBar])

	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClickExport = () => {
		exportRoles()
	};

	const handleClickSync = () => {
		setOpenSyncDialog(true)
	};

	const handleClose = () => {
		setOpen(false);
		setFormData({})
		setSelectedOptions([])
	};

	const handleEditDialog = (event,row) => {
		event.preventDefault();
		setSelectedOptions(row?.services)
		setOpenEditDialog(true);
		const roleToEdit = row?.role;
		const serviceToEdit = row?.services;
		setFormData({
			role: roleToEdit,
			service: serviceToEdit,
		})
	};

	const handleEdit = (event,role,service,permissions) => {
		editRole(role,service,permissions)
		setOpenEditDialog(false)
		setFormData({})
		setSelectedOptions([])
	};

	const handleDelete = () => {
		deleteRole(toBeDeletedRole)
		setOpenDeleteDialog(false)
		setToBeDeletedRole({})
	};

	const handleSync = () => {
		syncAdminsWithRole()
		setOpenSyncDialog(false)
	};

	const handleDeleteDialog = (event) => {
		event.preventDefault()
		setOpenDeleteDialog(true);
		const deleteButton = event?.currentTarget
		const roleToDelete = deleteButton?.dataset?.role
		const serviceToDelete = deleteButton?.dataset?.service;
		setToBeDeletedRole({ role: roleToDelete, service: serviceToDelete })
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false)
		setToBeDeletedRole({})
	}

	const handleCloseSyncDialog = () => {
		setOpenSyncDialog(false)
	}

	const handleCloseEditDialog = () => {
		const obj = {}
		setFormData(obj)
		setOpenEditDialog(false)
		setSelectedOptions([])
	}

	const handleSnackBarClose = () => {
		setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: false }
		});
	};

	const handleSubmit = () => {
		setOpen(false);
		createRole(formData)
		const obj = {}
		setFormData(obj)
		setSelectedOptions([])
	};

	return (
		<Fragment>
			<Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
				<Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
					{snackBar?.message}
				</Alert>
			</Snackbar>
			<Stack
				direction="row"
				alignItems="center"
				spacing={2}
				sx={{ margin:'15px 0px' }}
				style={{justifyContent:'space-between'}}
			> 
				<div>
					<Button variant="contained" onClick={handleClickOpen} style={{marginRight:10}}>
						Create Role
					</Button>
					
					<Button variant="contained" onClick={handleClickSync} startIcon={<SyncIcon />}>
						Sync Role Policy
					</Button>
				</div>

				<div style={{display:'flex'}}>
					<form className="d-flex import" style={{marginRight:10}}>
						<input
							className="form-control me-1 py-1"
							type="file"
							id="formFile"
							name="pricing"
							accept=".csv"
						></input>
						<Button variant="contained" onClick={handleImport} startIcon={<FileUploadIcon />}>
							Import
						</Button>
					</form>

					<Button variant="contained" onClick={handleClickExport} startIcon={<FileDownloadIcon />}>
						Export 
					</Button>
				</div>
			</Stack>
			<FormDialog open={open} handleClose={handleClose} handleSubmit={handleSubmit} dialogTitle="Create Role">
				<Grid container spacing={2}>
					<Grid xs={6}>
						<TextField
							autoFocus
							margin="dense"
							id="role"
							label="Role"
							type="text"
							size='small'
							variant="outlined"
							autoComplete='off'
							value={formData?.role || ""}
							onChange={(event) => {
								setFormData((prevFormData) => {
									return { ...prevFormData, role: event?.target?.value }
								});
							}}
						/>
					</Grid>
					<Grid xs={6}>
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<InputLabel id="service-select-label">Service</InputLabel>
							<Select
								labelId="Service"
								id="service-select"
								value={formData?.service || ""}
								label="Service"
								onChange={(event) => {
									setFormData((prevFormData) => {
										return { ...prevFormData, service: event?.target?.value }
									});
								}}
							>
								<MenuItem value="">
									<em>Select Service</em>
								</MenuItem>
								{servicesOptions.length > 0 && servicesOptions.map((serviceOption) => (
									<MenuItem key={serviceOption} value={serviceOption}>
										{serviceOption}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6}>
						<MultipleSelect
							label={"Permissions"}
							selectedOptions={selectedOptions}
							setSelectedOptions={setSelectedOptions}
							size='small'
							options={[
								'create',
								'read',
								'update',
								'delete',
							]} />
					</Grid>
				</Grid>
			</FormDialog>
			<FormDialog open={openDeleteDiaglog} handleClose={handleCloseDeleteDialog} handleSubmit={handleDelete} dialogTitle={`Delete Role Permanently`} dialogContent={"Are you sure you want to delete?"} />
			<FormDialog open={openSyncDialog} handleClose={handleCloseSyncDialog} handleSubmit={handleSync} dialogTitle={`Sync Admins with Permission in Roles`} dialogContent={"Are you sure you want to take this action, this will remove all extra permissions of admins?"} />
			<FormDialog open={openEditDiaglog} handleClose={handleCloseEditDialog} handleSubmit={handleEdit} dialogTitle="Edit Role" width={"900px"} hideButton={true}>
				<Grid container spacing={2}>
					{Array.isArray(formData?.service) && formData?.service?.map((role,index) => (
						<div key={index} style={{display:'flex'}}>
							<IconButton
								aria-label="delete"
								sx={{ marginRight: "10px",color:'#d32f2f' }}
								size='small'
								data-role={formData?.role}
								data-service={role?.service}
								onClick={handleDeleteDialog}
							>
								<DeleteIcon />
							</IconButton>
							<Grid xs={4}>
								<TextField
									autoFocus
									margin="dense"
									id="role"
									label="Role"
									type="text"
									size='small'
									style={{width:'170px'}}
									autoComplete='off'
									variant="outlined"
									value={formData?.role || ""}
									onChange={(event) => {
										setFormData((prevFormData) => {
											return { ...prevFormData, role: event?.target?.value }
										});
									}}
								/>
							</Grid>
							<Grid xs={5}>
								<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
									<InputLabel id="service-select-label">Service</InputLabel>
									<Select
										labelId="Service"
										id="service-select"
										value={role?.service || ""}
										label="Service"
										style={{minWidth:'225px'}}
										onChange={(event) => {
											setFormData((prevFormData) => {
												return { ...prevFormData, service: event?.target?.value }
											});
										}}
									>
										<MenuItem value="">
											<em>Select Service</em>
										</MenuItem>
										{servicesOptions.length > 0 && servicesOptions.map((serviceOption) => (
											<MenuItem key={serviceOption} value={serviceOption}>
												{serviceOption}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid xs={6} style={{marginTop:'8px'}}>
								<MultipleSelect
									label={"Permissions"}
									selectedOptions={role?.permissions}
									setSelectedOptions={setFormData}
									size='small'
									width={"250px"}
									service={{service:role?.service,formData}}
									style={{marginTop:'8px'}}
									options={[
										'create',
										'read',
										'update',
										'delete',
									]} />
							</Grid>
							<div  style={{marginTop:'16px'}}>
								<Button
									variant="outlined"
									style={{ textTransform: 'capitalize', fontSize: 14, padding: '5px 10px' }}
									onClick={(event) => handleEdit(event,formData?.role,role?.service,role?.permissions)}
								>UPDATE</Button>
							</div>
						</div>
					))}
				</Grid>
			</FormDialog>

			<Box
				component={Paper}
				className="shadow"
				sx={{'& .super-app-theme--header':{ backgroundColor:'#243750',color:'#ffffff',cursor:"default" }}}
			>
				<DataGrid
					slots={{ toolbar: GridToolbar }}
					filterMode="server"
					filterColumns={['role']}
					sortingMode="server"
					paginationMode="server"
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					initialState={{
						sorting: {sortModel: [{ field: 'role', sort: 'asc' }]},
					}}
					onSortModelChange={(sort) => setSort(sort[0])}
					sx={{
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						boxShadow: 2,
						borderRadius: 2,
						cursor: "pointer"
					}}
					columns={columns}
					rows={roles?.length !== 0 && roles?.[0]?.fetchedRoles?.map((role) => ({
						id: role?._id,
						createdAt: moment(role?.createdAt).format('MMM DD, YYYY h:mm:ss A'),
						role: role?.role,
						services: role?.permissions,
						permissions: 'permissions'
					}))}
					sortingOrder={['asc', 'desc']}
					autoHeight
					autoPageSize
					onFilterModelChange={(filter) => (setFilter(filter.items[0]), setSearch(filter.quickFilterValues[0]))}
				/>
			</Box>

			<NewPagination totalPage={roles?.[0]} fetchData={fetchRoles}></NewPagination>
		</Fragment>
	);
};

export default Roles;
