import { Fragment, useEffect, useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import QRCode from 'qrcode';
import { Button, Chip } from "@mui/material";
import { ImportFileComponent } from "../../../components/importFileComponent";
import { services } from "../../../utility/checkRoleBasedPermission";
import {Print} from '@mui/icons-material';
import printPageSection from "../../../utility/printPageSection";
import QrCodesDownloadPopup from '../inventoryView/qrCodesDownload';

const SkuToQrCode = () => {
    const [qrcData , setQrcData] = useState([]);
    const [reRender , setReRender] = useState(false)
    const [data , setData] = useState(null)
    const alignmentsWithUploadCsvEndpoints = {
        "skuToQrcode": '/product/skuToQRC',
        "skuToBarcode": '/product/skuToBarcode'
    }
    const [uploadCsvEndpoint , setUploadCsvEndpoint] = useState('/product/skuToQRC')
    const [alignment, setAlignment] = useState('skuToQrcode');
    const [qrCodesPopUp,setQRCodesPopUp] = useState(false);

    const getQRCode = () => {
        let opts = {
            errorCorrectionLevel: 'M',
            quality: 0.3,
            margin: 1,
        }
        data?.productIds.map( async item => {
            const URL = `https://depo24.page.link/?link=https://depo24.in/products/${item.Id}&apn=in.depo24.depo_24&ibi=in.depo24.depo24`;
            const qrCode = await QRCode.toDataURL(URL,opts);
            item.qrCode = qrCode;
        });
        setQrcData(data?.productIds)
    }

    useEffect(()=>{
        if(alignment === 'skuToQrcode'){
            getQRCode()
        }
    },[data])

    const handlePrint = (sectionId) =>{
        printPageSection(sectionId)    
    }

    const handleAlignmentChange = async (e) => {
        setQrcData([])
        setData(null)
        setAlignment(e.target.value)
        setUploadCsvEndpoint(alignmentsWithUploadCsvEndpoints[e.target.value])
    }

    const showQrCodesPopUp = () => {
        setQRCodesPopUp(true);
    }

    const hideQrCodesPopUp = () => {
        setQRCodesPopUp(false);
    }


    return (
        <Fragment>
            <div style={{marginTop:'10px'}}>
                <header style={{ display:'flex',marginTop:-5,justifyContent:'space-between',width:'99%' }}>
                    <div>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleAlignmentChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="skuToQrcode" style={{padding:"4px 10px",height:34}}>Sku To Qrcode</ToggleButton>
                            <ToggleButton value="skuToBarcode" style={{padding:"4px 10px",height:34}}>Sku To Barcode</ToggleButton>
                        </ToggleButtonGroup>
                    </div>


                    <div style={{display:'flex'}}>
                        <div>
                            <Button onClick={() => showQrCodesPopUp()} variant="contained" color="primary" style={{fontSize:12,marginRight:10}}>
                                SKU TO CATALOGUE
                            </Button>
                        </div>

                        <ImportFileComponent
                            endpoint={uploadCsvEndpoint}
                            serviceName={services?.skuToQRC}
                            type="sku"
                            templateDownloadEndpoint={"/templates/skuToQrcodeBarcodeCsv"}
                            setReRender={setReRender}
                            setData={setData}
                        />
                        {data && 
                            <Button 
                                onClick={() =>handlePrint('printContainer')} 
                                variant="outlined"  startIcon={<Print/>} 
                                sx={{marginLeft:'10px',textTransform: "capitalize","& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: '14px' }}}
                            >Print</Button>
                        }
                    </div>
                </header>
                
                <main className="container h-100">
                    {/* Invalid Records */}
                    {data?.invalidSkus && data?.invalidSkus?.length !== 0 &&  (
                        <div style={{marginTop:'10px'}}>
                            <h4>Invalid Skus</h4>
                            <div  style={{display:'flex', flexWrap:'wrap'}}>
                                {data?.invalidSkus?.map((item,index) => <Chip key={index} color="error" size="small" label={item.sku} sx={{margin : '5px'}} />) } 
                            </div>
                            <hr />
                        </div>
                    )}
                    {/* PrintContainer */}
                    <div id="printContainer" style={{marginTop: '20px', borderTop: '3px solid lightgrey'}}>
                        {/* QrCode  */}
                        {alignment === 'skuToQrcode' &&<div className="d-flex justify-content-around flex-wrap">
                            {qrcData?.map((item, index) => (
                                <div key={index} className="image-to-print m-3" style={{margin:'15px',textAlign : 'center',display:'inline-block',}}>
                                    <img className="" alt='QrCode' id='qrCode' width="66px" height="66px" src={item.qrCode} />
                                    <p style={{marginTop:'3px', fontSize:'10px' , lineHeight:'10PX', width:"66px"}}>{item.qrSKU}</p>
                                    <p style={{fontSize:'10px' , lineHeight:'10PX', margin:0,marginTop:2}}>₹{(item.mrp)?.toLocaleString('en')}</p>
                                </div>
                            ))
                            }
                        </div>}
                        {/* Barcode */}
                        {alignment === 'skuToBarcode' && <div style={{display:'grid',gridTemplateColumns:'repeat(4,1fr)',gap:'20px'}}>
                            {
                                data?.allBarCodesWithSkuDetails?.map((item, index) => (
                                    <div key={index} style={{display:'flex',flexDirection:'column',alignContent:'center',boxShadow:'1px 1px 1px 1px lightgrey',padding:'5px'}}>
                                        <img alt='Barcode' id='barcode' src={item?.barcode} style={{margin:'auto'}}/>
                                        <p style={{marginTop:'3px', fontSize:'12px'}} >{item?.product_name}</p>
                                    </div>
                                ))
                            }
                        </div>}
                    </div>
                </main>

                <QrCodesDownloadPopup 
                    open={qrCodesPopUp} 
                    handleClose={hideQrCodesPopUp} 
                    header={'Sku To Catalogue Pdf Download'}
                    buttonText={'Sku To Catalogue'}
                    endPoint={'skuToCatalogue'}
                    importFile={true}
                />
            </div>
        </Fragment>
    )
}

export default SkuToQrCode;