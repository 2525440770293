import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark,faMagnifyingGlass,faRightLeft} from "@fortawesome/free-solid-svg-icons";


const TagsPopup = (props)=> {
    const {
        tagsModal,
        hideTagsPopup,
        setSelectedTag,
        SelectedTags
    } = props;

    // const handleTarget = (event) => {
    //     if(event.target.checked) {
    //         console.log(event.target.value)
    //     }
    // }
    
    const handleSubmit = (event) =>{
        event.preventDefault();
        hideTagsPopup();
        const selected = []

        const tags = document.getElementsByClassName('checkBox');
        for (let i = 0; i < tags.length; i++) {
            if(tags[i].checked) {
                selected.push(tags[i].value)
            }
        }
        setSelectedTag([...SelectedTags, ...selected]);
    }

    return (
        <Fragment>
            {tagsModal && (
                <div className={Classes.modal} style={{bottom:'8%',top:'10%'}}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage tags</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideTagsPopup}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <form method='post' onSubmit={handleSubmit}>
                        <div style={{padding:'30px 20px',overflowY:'scroll',height:'400px'}}>
                            <div style={{display:'flex'}}>
                                <div 
                                    style={{ 
                                        width: '72%', 
                                        display: 'flex',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        padding: '2px 4px 4px 4px'
                                    }}
                                >
                                    <div>
                                        <FontAwesomeIcon 
                                            icon={faMagnifyingGlass} 
                                            style={{
                                                fontSize: '15px',
                                                padding: '11px 8px 3px',
                                                color: 'rgb(129, 138, 155)'
                                            }}
                                        />
                                    </div>

                                    <div style={{width:'100%'}}>
                                        <input
                                            type="text"
                                            id="search"
                                            className={Classes.SearchTag}
                                            style={{border:'0',marginTop:'4px',width:'100%'}}
                                            placeholder="Search to find and create tags"
                                        />
                                    </div>
                                </div>

                                <div 
                                    className={Classes.Search} 
                                    style={{marginLeft:'10px'}}
                                >
                                    <FontAwesomeIcon 
                                        icon={faRightLeft} 
                                        style={{
                                            fontSize: '15px',
                                            padding: '11px 8px 3px',
                                            rotate:'90deg',
                                            marginTop:'5px'
                                        }}
                                    />
                                        <button 
                                            type="submit" 
                                            style={{
                                                border:0,
                                                padding: 0,
                                                backgroundColor:'transparent',
                                                fontWeight:'600',
                                                fontSize:'14px'
                                            }}
                                        >
                                            Frequently used
                                        </button>
                                    </div>
                            </div>
                            
                            <div style={{padding:'20px 10px',fontSize:'13px'}}>
                                <div style={{color: 'rgb(104, 111, 123)'}}>
                                    <p style={{fontSize:'13px',fontWeight:'600'}}>
                                        AVAILABLE
                                    </p>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='RETAILER'
                                            name="checkbox"
                                            className="checkBox"
                                            // onClick={(e) => handleTarget(e)}
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>RETAILER</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='SAMPLE100'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>SAMPLE100</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='SAMPLE'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>SAMPLE</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='XYZ-4'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>XYZ-4</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='GOAFFPRO'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>GOAFFPRO</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='ARCHITECT'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>ARCHITECT</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='COD'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>COD</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:'10px'}}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='OFFER100'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>OFFER100</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            className={Classes.Button} 
                            style={{
                                display:'flex',
                                justifyContent:'flex-end',
                                borderTop: '1px solid #d2d5d8',
                                padding:'15px 20px',
                                width:'100%'
                            }}
                        >
                            <button 
                                id='btn' 
                                type="submit" 
                                // onClick={hideTagsPopup}
                                style={{
                                    backgroundColor:'#006E52', 
                                    color: '#fff',fontSize:'15px',
                                    padding: '6px 15px'
                                }}
                            >
                                Done
                            </button>
                        </div>
                    </form>

                </div>
            )}
        </Fragment>
    );
}


export default TagsPopup;