import React, { Fragment, useEffect, useState } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from "../../../../utility/axios-instance";
import { Autocomplete, TextField } from "@mui/material";
import SnackBar from "../../../../components/SnackBar";
import Spinner from "../../../../components/spinner";
import MultipleSelectChip from "../../../../components/MultiSelect/MultipleSelectChip";

const DetailsPopUp = (props) => {
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  const {
    contactModal,
    hideContactPop,
    showCustomerDetail,
    setCustomerDetail,
    setReRender,
    reRender,
    locationContactDetailId,
    setLocationContactDetailId,
    locationContactDetails,
    l1MappingsCreateUpdate,
  } = props;

  const [number, setNumber] = useState(null);
  const [selectedL1s, setSelectedL1s] = useState([]);
  const [l1s, setL1s] = useState([]);

  useEffect(() => {
    setNumber(locationContactDetails?.phoneNumber);
  }, [locationContactDetails?.phoneNumber]);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const locationContactDetailData = {
      firstName: event?.target?.firstName?.value || undefined,
      lastName: event?.target?.lastName?.value || undefined,
      phoneNumber: event?.target?.phone?.value || undefined,
      email: event?.target?.email?.value || undefined,
      role: event?.target?.role?.value || undefined,
    };

    const initialLocationContactDetailData = {
      firstName: showCustomerDetail[0]?.firstName || undefined,
      lastName: showCustomerDetail[0]?.lastName || undefined,
      phoneNumber: showCustomerDetail[0]?.phoneNumber || undefined,
      email: showCustomerDetail[0]?.email || undefined,
      role: showCustomerDetail[0]?.role || undefined,
    };

    if (
      Object?.values(initialLocationContactDetailData)?.every((value) => {
        return value === undefined;
      })
    ) {
      axiosInstance
        .post(
          `/location/locationContactDetail/${showCustomerDetail[0].locationCode}`,
          { ...locationContactDetailData }
        )
        .then((res) => {
          const successMessage = res?.data?.message
            ? res?.data?.message
            : "Location Contact Detail Added Successfully";
          setLoading(false);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: successMessage,
              type: "success",
            };
          });
          hideContactPop();
          if (setReRender) {
            setReRender(!reRender);
          }
          setLocationContactDetailId("");
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong";
          setLoading(false);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: errorMessage,
              type: "error",
            };
          });
        });
    } else {
      axiosInstance
        .put(
          `/location/locationContactDetail/${locationContactDetailId}`,
          { ...locationContactDetailData }
        )
        .then((res) => {
          const successMessage = res?.data?.message
            ? res?.data?.message
            : "Location Contact Detail Updated Successfully";
          setLoading(false);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: successMessage,
              type: "success",
            };
          });
          hideContactPop();
          if (setReRender) {
            setReRender(!reRender);
          }
          setLocationContactDetailId("");
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong";
          setLoading(false);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: errorMessage,
              type: "error",
            };
          });
        });
    }
};


  const handleMinLength = (event) => {
    if (event.target.value) {
      const phoneno = /^(?!0)\d{10}$/;
      if (event.target.value.match(phoneno)) {
        document.getElementById("phoneErrMsg").style.display = "none";
        document.getElementById("addCustomer").disabled = false;
      } else {
        document.getElementById("addCustomer").disabled = true;
        document.getElementById("phoneErrMsg").style.display = "block";
      }
    } else {
      document.getElementById("phoneErrMsg").style.display = "none";
      document.getElementById("addCustomer").disabled = false;
    }
  };

 

  useEffect(() => {
    axiosInstance
      .get(`/pincode/getAllCities`)
      .then((res) => {
        const districts = res?.data?.data;
        setDistricts(districts);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Unable to fetch districts";
        setLoading(false);
        setSnackBar((prevSnackBar) => {
          return {
            ...prevSnackBar,
            display: true,
            message: errorMessage,
            type: "error",
          };
        });
      });
  }, []);

  useEffect(() => {
    showCustomerDetail?.length > 0 &&
      l1MappingsCreateUpdate &&
      fetchL1sforLocation(showCustomerDetail[0]?.locationId);
    fetchL1s();
  }, [showCustomerDetail]);

  const fetchL1sforLocation = async (locationId) => {
    await axiosInstance
      .get(`/l1Mapping/${locationId}`)
      .then((res) => {
        const l1s = res?.data?.data;
        const l1Values = l1s?.map((l1) => {
          return l1?.title;
        });
        setSelectedL1s(l1Values);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong";
        setSnackBar((prevSnackBar) => {
          return {
            ...prevSnackBar,
            display: true,
            message: errorMessage,
            type: "error",
          };
        });
      });
  };
  const fetchL1s = async () => {
    const filter = {};
    await axiosInstance
      .get(`/others/l1s/?filter=${JSON.stringify(filter)}`)
      .then((res) => {
        const l1s = res?.data?.data;
        setL1s(l1s);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.message || "Something went wrong";
        setSnackBar((prevSnackBar) => {
          return {
            ...prevSnackBar,
            display: true,
            message: errorMessage,
            type: "error",
          };
        });
      });
  };
  const OptionValues =
    l1s &&
    l1s?.map((l1, index) => {
      return { value: l1?.title, id: index };
    });

  const handleNumber = (event) => {
    const limit = 10;
    setNumber(event.target.value.slice(0, limit));
  };

  return (
    <Fragment>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      {contactModal && (
        <div
          className={[Classes.modal, Classes.contactPopUp].join(" ")}
          style={{ bottom: "13%" }}
        >
          <div className={Classes.BoxContent}>
            <p> Edit Contact Details </p>
          </div>

          <form method="post" onSubmit={handleSubmit} id="form">
            <div style={{ padding: "8px 20px" }}>
              {/* Personal Details */}

              <div className={Classes.ContentBox}>
                <div style={{ width: "49%" }}>
                  <TextField
                    required={true}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    autoComplete="off"
                    placeholder="First Name"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={locationContactDetails?.firstName}
                    key={locationContactDetails?.firstName}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <TextField
                    type="text"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    autoComplete="off"
                    placeholder="Last Name"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={locationContactDetails?.lastName}
                    key={locationContactDetails?.lastName}
                  />
                </div>
              </div>
              <div className={Classes.ContentBox}>
                <div style={{ width: "49%" }}>
                  <TextField
                    type="number"
                    required={true}
                    id="phone"
                    name="phone"
                    label="Phone"
                    autoComplete="off"
                    placeholder="Enter Your Phone Number"
                    minLength={10}
                    maxLength={10}
                    inputProps={{ maxLength: 10 }}
                    sx={{ width: "100%" }}
                    size="small"
                    onBlur={(e) => handleMinLength(e)}
                    onChange={handleNumber}
                    value={number}
                    // defaultValue={locationContactDetails?.phoneNumber}
                    // key={locationContactDetails?.phoneNumber}
                  />
                  <div className={Classes.PhoneErrorMsg} id="phoneErrMsg">
                    <p>Please enter a valid 10 digit mobile number</p>
                  </div>
                </div>
                <div style={{ width: "49%" }}>
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    label="Email"
                    autoComplete="off"
                    placeholder="Enter Your Email"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={locationContactDetails?.email}
                    key={locationContactDetails?.email}
                  />
                </div>
              </div>

              <div className={Classes.ContentBox}>
                <div style={{ width: "49%" }}>
                  <Autocomplete
                    freeSolo
                    disablePortal
                    id="role"
                    name="role"
                    options={
                      showCustomerDetail[0]?.locationType === "Franchise"
                        ? ["Franchise Owner"]
                        : ["Warehouse Manager", "Warehouse Executive"]
                    }
                    defaultValue={
                      showCustomerDetail[0]?.locationType === "Franchise"
                        ? "Franchise Owner"
                        : locationContactDetails?.role
                    }
                    key={
                      showCustomerDetail[0]?.locationType === "Franchise"
                        ? "Franchise Owner"
                        : locationContactDetails?.role
                    }
                    getOptionLabel={(option) => option || ""}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label={"Role"} required={true} />
                    )}
                    size="small"
                  />
                </div>

                <div style={{ width: "49%" }}>
                  <MultipleSelectChip
                    label={"Select L1s"}
                    selectedOptions={selectedL1s}
                    setSelectedOptions={setSelectedL1s}
                    OptionValues={OptionValues}
                  />
                </div>
              </div>

             

            </div>

            <div className={Classes.DialogBtnBox} style={{ bottom: "13%" }}>
              <button
                type="button"
                className={Classes.UpdateSalesUserOfCustomer}
                onClick={hideContactPop}
              >
                CANCEL
              </button>

              <button
                id="addCustomer"
                type="submit"
                className={Classes.UpdateSalesUserOfCustomer}
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      )}

      {loading ? <Spinner /> : null}
    </Fragment>
  );
};

export default DetailsPopUp;
