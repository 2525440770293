import React, { Fragment, useEffect, useState } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

const ChallanDialog = (props) => {
    const {
        challanDialogOpen,
        challanRowData,
        handleChallanDialogClose,
        setReRender
    } = props;

    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [challanDate, setChallanDate] = useState(new Date);
    const [challanNumber, setChallanNumber] = useState('');
    const [challanUrls, setChallanUrls] = useState([]);
    const challanFilesRef = React.useRef(null);

    const setChallanNumberValue = (challanNumber) => {
        setChallanNumber(challanNumber)
    }

    useEffect(() => {
        setChallanDate(dayjs(challanRowData?.challanDate || new Date))
        setChallanNumber(challanRowData?.challanNumber || '')
        setChallanUrls(challanRowData?.challanUrl?.map((challanUrl) => {
            return {
                url: challanUrl,
                name: challanUrl?.split('/')?.pop()?.split('?')?.shift(),
                type: "Uploaded",
            }
        }) || [])
    }, [challanRowData])

    const handleFileInputChange = () => {
        const challanFiles = challanFilesRef?.current?.files;
        if (challanUrls?.length + challanFiles?.length > 2) {
            challanFilesRef.current.files = null;
            alert('Only 2 challans can be uploaded!');
            return;
        }
        if (challanFiles?.length > 0) {
            const urls = [];
            for (let i = 0; i < challanFiles?.length; i++) {
                urls.push({
                    url: URL.createObjectURL(challanFiles[i]),
                    name: challanFiles[i]?.name,
                    type: "To Upload",
                })
            }
            setChallanUrls((prevChallanUrls) => [...prevChallanUrls, ...urls])
        }
    }

    const handleChallanUpload = (event) => {
        event.preventDefault();
        setLoading(true);

        var formData = new FormData();
        for (let i = 0; i < challanFilesRef?.current?.files?.length; i++) {
            formData.append("challansAttached", challanFilesRef?.current?.files[i]);
        }
        formData.append("challanNumber", challanNumber);
        formData.append("challanDate", challanDate);

        axiosInstance
            .put(
                `/purchaseOrders/uploadChallan/${challanRowData?.purchaseOrderName}`,
                formData
            )
            .then((res) => {
                const successMessage = res?.data?.message || "Challan uploaded successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleChallanDialogClose();
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message || "Could not update challan!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
                setReRender((prev) => !prev);
            });
    }

    const handleChallanDelete = (challanUrlToBeDeleted) => {
        setChallanUrls((prevChallanUrls) => {
            return prevChallanUrls.filter((challanUrl, index) => challanUrl?.url !== challanUrlToBeDeleted?.url)
        })
        const challanFilesSelected = challanFilesRef?.current?.files;
        if (challanFilesSelected?.length > 0) {
            const files = [];
            for (let i = 0; i < challanFilesSelected?.length; i++) {
                if (challanFilesSelected[i]?.name === challanUrlToBeDeleted?.name) continue;
                files.push(challanFilesSelected[i])
            }
            challanFilesRef.current.files = files;
        }
    }

    return (
        <Fragment>
            {challanDialogOpen && (
                <div
                    className={Classes.confirmModal}
                    style={{ top: '24%', boxShadow: '0 0px 9px 0' }}
                >
                    <div style={{ fontSize: '1rem', padding: '10px' }}>
                        <h4>Upload Challan - {challanRowData?.purchaseOrderName}</h4>
                    </div>
                    <form method='post' onSubmit={handleChallanUpload}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                            <TextField
                                required
                                label="Challan Number"
                                name="challanNumber"
                                variant="outlined"
                                size="small"
                                style={{ marginTop: '10px' }}
                                value={challanNumber}
                                onChange={(e) => {
                                    setChallanNumberValue(e?.target?.value)
                                }}
                                fullWidth
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <DateField
                                        label="Challan Date"
                                        value={challanDate}
                                        onChange={(newChallanDate) => setChallanDate(newChallanDate)}
                                        slotProps={{ textField: { size: 'small' } }}
                                        fullWidth
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <input
                                required
                                type="file"
                                id="challan-upload"
                                name="challansAttached"
                                multiple={true}
                                disabled={challanUrls?.length >= 2}
                                className="form-control" aria-label="Upload Challan"
                                ref={challanFilesRef}
                                style={{ marginTop: '10px' }}
                                onChange={handleFileInputChange}
                            ></input>
                        </div>

                        <div>
                            <List>
                                {
                                    challanUrls?.length > 0 &&
                                    challanUrls?.map((challanUrl, index) => {
                                        return (
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleChallanDelete(challanUrl)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemButton component="a" href={challanUrl?.url}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <FolderIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={challanUrl?.name || `Challan ${index + 1}`}
                                                        secondary={challanUrl?.type || null}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>

                        <div style={{
                            bottom: '18%',
                            padding: '0px 9px',
                            marginBottom: '30px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleChallanDialogClose}
                            >
                                CANCEL
                            </button>

                            <button
                                id='addCustomer'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default ChallanDialog;