import {Fragment, useState, useEffect} from 'react';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import { BiArrowBack} from 'react-icons/bi';
import Classes from '../customers/css/customerProfile.module.css';
import Aside from '../customers/aside/aside';
import CustomerOrderDetails from '../../../components/customerOrderDetails';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import GradeIcon from '@mui/icons-material/Grade';
import TablePagination from '@mui/material/TablePagination';
import { useSelector,useDispatch  } from 'react-redux';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';

const CustomerDetails = (props) => {
    const [error, setError] = useState(null);
    const [showCustomerProfile,setCustomerProfile] = useState([]);
    const [orders, setOrders] = useState([]);
    const [totalAmount, setTotalAmount] = useState([]);
    const [totalOrders, setTotalOrders] = useState([]);
    const [avgAmount, setAvgAmount] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressArray, setAddressArray] = useState([]);
    const [toggle,setToggle] = useState(true);

    const [fieldVisitHistory,setFieldVisitHistory] = useState([]);
    const [totalFieldVisit,setTotalFieldVisit] = useState([]);
    const [pageNo,setPageNo] = useState(1);
    
    const breadCrumb = useSelector((state) => state.breadcrumb);

    const { Id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        if (toggle) {
            axiosInstance
                .get(`/user/admin/customerDetail/${Id}`)
                .then((res) => {
                    setToggle(false);
                    if (res.data.data.length !== 0) {
                        setTotalAmount(res.data.totalAmount);
                        setTotalOrders(res.data.totalOrders);
                        setAvgAmount(res.data.avgAmount);
                        if(res.data.address) {
                            let addressArr = [];
                            for (let address of res.data.address) {
                                if (address.default === true) {
                                    addressArr.unshift(address);
                                } else {
                                    addressArr.push(address);
                                    setAddressArray(addressArr);
                                }
                            }
                        }
                    }

                    dispatch(setBreadcrumb({ ...breadCrumb, myCustomerName: res.data.data.displayName }));
                    setOrders(res.data.orders);
                    setCustomerProfile(res.data.data);
                    
                    axiosInstance
                        .get(`/fieldSalesVisit/user/${Id}`)
                        .then((res) => {
                            setTotalFieldVisit(res.data.data)
                            setFieldVisitHistory(res.data.data.fieldVisit)
                        })

            })
        }
    },[Id, addressArray,setAddressArray, toggle]);

    const handleChangePage = (event,newPage) => {
        axiosInstance
            .get(`/fieldSalesVisit/user/${Id}?page=${newPage + 1}`)
            .then((res) => {
                setTotalFieldVisit(res.data.data)
                setFieldVisitHistory(res.data.data.fieldVisit)
            })
    }

    const years = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dn = new Date();

    const createdAt = (createdAt) => {
        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

	return (
		<Fragment>
			<div className={Classes.Container} style={{maxWidth: "70.375rem"}}>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div
                        className="d-flex justify-content-center flex-column"
                        style={{ gap: '1rem' }}
                    >
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ gap: '1rem' }}
                        >
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <BiArrowBack />
                            </button>
                            <div>
                                {showCustomerProfile?.length !== 0 ? (
                                    <div style={{display:'flex',marginLeft: '5px'}}>
                                        <h5>
                                            {showCustomerProfile?.displayName ? 
                                            showCustomerProfile?.displayName : 
                                            showCustomerProfile?.firstName + " " +  showCustomerProfile?.lastName && showCustomerProfile?.lastName}
                                        </h5>          
                                    </div>
                                ) : null}

                            </div>
                        </div>
                    </div>
                </div>

				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
						<Alert.Heading>{error}</Alert.Heading>
						<p>Try reloading!</p>
					</Alert>
				) : null}

                <div className={Classes.mainContainer}>
                    <div className={Classes.myCustomerDetailContainer}>
                        <CustomerOrderDetails 
                            showCustomerProfile={showCustomerProfile}
                            orders={orders}
                            totalAmount={totalAmount}
                            totalOrders={totalOrders}
                            avgAmount={avgAmount}
                        />

                        <div>
                            <p style={{fontWeight:600,margin:'20px 0px'}}>Visit History</p>
                            <div>
                                <TableContainer 
                                    component={Paper} 
                                    sx={{marginTop:2, "& .MuiTableContainer-root": {boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"}}}
                                    className={Classes.hideTable}
                                >
                                    <Table 
                                        sx={{ minWidth: 700,"& .MuiTableRow-root:hover":{cursor:'pointer'}}} 
                                        aria-label="customized table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>S.No</StyledTableCell>
                                                <StyledTableCell>Remarks</StyledTableCell>
                                                <StyledTableCell>Ratings</StyledTableCell>
                                                <StyledTableCell>Date Of Visits</StyledTableCell>
                                                <StyledTableCell>Sales User</StyledTableCell>
                                                <StyledTableCell>Mode</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {fieldVisitHistory?.length !== 0 && fieldVisitHistory?.map((customer,index) => (
                                                <StyledTableRow key={customer?._id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {index+1}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{customer?.remarks ? customer?.remarks : "N/A"}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {customer?.ratings ? 
                                                            <GradeIcon sx={{color: customer?.ratings === 'green' ? 'green': 
                                                                customer?.ratings === 'yellow'? 'yellow' : 'red',fontSize:30}}
                                                            />
                                                        : "N/A" }
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        {customer?.createdAt ? createdAt(customer?.createdAt) : "N/A"}
                                                    </StyledTableCell>
                                                    
                                                    <StyledTableCell>
                                                        {customer?.fieldSalesUser ? customer?.fieldSalesUser?.name : "N/A"}
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        {customer?.modeOfCommunication ? customer?.modeOfCommunication : "N/A"}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <div className={Classes.responsiveTable}>
                                    {fieldVisitHistory?.length !== 0 && fieldVisitHistory?.map((customer,index) => (
                                        <div 
                                            key={customer?._id}   
                                            // onClick={() => {navigate(`${customer?._id}`)}}
                                            className={Classes.responsiveTableBox}
                                            style={{marginTop: index === 0 ? 0 : 15}}
                                        >
                                            <div style={{display:'flex'}}>
                                                <p>S No.</p>
                                                <p style={{fontWeight:600}}>
                                                    {index+1}
                                                </p>
                                            </div>

                                            <div style={{display:'flex'}}>
                                                <p style={{marginRight:5,color: "#6c757d"}}>Remarks:</p>
                                                <p>{customer?.remarks ? customer?.remarks : "N/A"}</p>
                                            </div>

                                            <div style={{display:'flex'}}>
                                                <p style={{marginRight:5,color: "#6c757d"}}>Ratings:</p>
                                                <p style={{textTransform:'capitalize'}}>
                                                    {customer?.ratings ? 
                                                        <GradeIcon sx={{color: customer?.ratings === 'green' ? 'green': 
                                                            customer?.ratings === 'yellow'? 'yellow' : 'red',fontSize:25}}
                                                        />
                                                    : "N/A" }
                                                </p>
                                            </div>

                                            <div style={{display:'flex'}}>
                                                <p style={{marginRight:5,color: "#6c757d"}}>Last Remarks:</p>
                                                <p>{customer?.visitRemarks ? customer?.visitRemarks : "N/A"}</p>
                                            </div>

                                            <div style={{display:'flex',marginTop:2}}>
                                                <p style={{marginRight:5,color: "#6c757d",width:105}}>Date Of Visits:</p>
                                                <p>{customer?.createdAt ? createdAt(customer?.createdAt) : "N/A"}</p>
                                            </div>

                                            <div style={{display:'flex',marginTop:5}}>
                                                <p style={{marginRight:5,color: "#6c757d",width:105}}>Sales User:</p>
                                                <p style={{textAlign:'center'}}>
                                                    {customer?.fieldSalesUser ? customer?.fieldSalesUser?.name : "N/A"}
                                                </p>
                                            </div>

                                            <div style={{display:'flex'}}>
                                                <p style={{marginRight:5,color: "#6c757d"}}>Communication Mode:</p>
                                                <p>{customer?.modeOfCommunication ? customer?.modeOfCommunication : "N/A"}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {totalFieldVisit?.length !== 0 && (
                                    <TablePagination
                                        sx={{ 
                                            "& .MuiTablePagination-selectLabel": {display:'none'}, 
                                            "& .MuiTablePagination-select": {display:'none'},
                                            "& .MuiSelect-icon": {display:'none'}
                                        }}
                                        component="div"
                                        count={totalFieldVisit.total}
                                        rowsPerPage={10}
                                        page={totalFieldVisit.page-1}
                                        onPageChange={handleChangePage}
                                    />
                                )}  
                            </div>
                        </div>
                    </div>

                    <div className={Classes.myCustomerAside}>
                        {showCustomerProfile?.length !== 0 && (
                            <Aside 
                                showCustomerProfile={showCustomerProfile}
                                setCustomerProfile={setCustomerProfile}
                                orders={orders}
                                address={address}
                                setAddress={setAddress}
                                addressArray={addressArray}
                                setAddressArray={setAddressArray}
                            />
                        )}
                    </div> 
                </div>  
            </div>
		</Fragment>
	)				
};

export default CustomerDetails;
