import { Fragment, useState, forwardRef, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBar from '../../../components/SnackBar';
import FactCheckIcon from '@mui/icons-material/FactCheck';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const QcPopup = (props) => {
    const [users, setUsers] = useState([]);
    const [qcPerson, setQcPerson] = useState(null);
    const { open, handleClose, inboundDetails, loading, setLoading, url } = props;
    const totalQty = inboundDetails?.quantity
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [inbounds, setInbounds] = useState([]);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        setFormData([
            ...inbounds?.map((inbound) => {
                return {
                    id: inbound?.id,
                    pass: inbound?.quantity,
                    fail: 0,
                };
            }),
        ]);
    }, [inbounds]);
    const closePopup = () => {
        setQcPerson(null);
        setFormData([
            ...inbounds?.map((inbound) => {
                return {
                    id: inbound?.id,
                    pass: inbound?.quantity,
                    fail: 0,
                };
            }),
        ]);
        handleClose();
    }

    const handlePassChange = (value, inboundId, totalQty) => {
        const passValue = parseInt(value, 10);
        if (value < 0) {
            alert('Pass value cannot be less than 0');
        }
        if (value > totalQty) {
            alert('Sum of "Pass" and "Fail" values cannot exceed totalQty.');
        }
        if (value < 0 || value > totalQty) {
            setFormData((prevFormData) => {
                const newFormData = [...prevFormData];
                const index = newFormData.findIndex((row) => row?.id === inboundId);
                newFormData[index].pass = 0;
                newFormData[index].fail = totalQty;
                return newFormData;
            });
            return
        } else {
            setFormData((prevFormData) => {
                const newFormData = [...prevFormData];
                const index = newFormData.findIndex((row) => row?.id === inboundId);
                newFormData[index].pass = passValue;
                newFormData[index].fail = totalQty - passValue;
                return newFormData;
            });
            return
        }
    };

    const handleFailChange = (value, inboundId, totalQty) => {
        const row = formData?.find((row) => row?.id === inboundId);
        const passValue = parseInt(row?.pass || 0, 10) || 0;
        const failValue = parseInt(value, 10) || 0;
        const total = passValue + failValue;
        if (total > totalQty) {
            alert('Sum of "Pass" and "Fail" values cannot exceed totalQty.');
            setFormData((prevFormData) => {
                const newFormData = [...prevFormData];
                const index = newFormData.findIndex((row) => row?.id === inboundId);
                newFormData[index].fail = 0;
                return newFormData;
            });
            return
        }
        setFormData((prevFormData) => {
            const newFormData = [...prevFormData];
            const index = newFormData.findIndex((row) => row?.id === inboundId);
            newFormData[index].fail = value;
            return newFormData;
        });
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const payloads = formData?.map((row) => {
            return {
                id: row?.id,
                pass: row?.pass,
                fail: row?.fail,
                qcPerson: qcPerson,
            };
        });
        const doQcPoInward = async (payload, inboundId) => {
            delete payload?.id;
            const data = {
                ...payload
            }
            await axiosInstance
                .put(`${url}/${inboundId}`, data)
                .then((res) => {
                    const successMessage = 'Order QC Successfully'
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    closePopup();
                })
                .catch((error) => {
                    // alert('Error in QC');
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
        }
        for (let payload of payloads) {
            if (payload?.pass === 0 && payload?.fail === 0) {
                alert('Pass and Fail values cannot be 0 at the same time.');
                return
            }
            await doQcPoInward(payload, payload?.id);
        }
        setLoading(false);
    }


    useEffect(() => {
        axiosInstance
            .get(`/admin/allPiAdmin?type=qcPerson&locationCode=${inboundDetails?.locationCode}`)
            .then((res) => {
                let users = res?.data?.data?.map((user) => user.name);
                setUsers(users);
            });
    }, [inboundDetails]);

    useEffect(() => {
        const filter = JSON.stringify({
            "field": "orderName",
            "operator": "contains",
            "id": 72647,
            "value": inboundDetails?.orderName,
        })
        axiosInstance
            .get(`/pgOrder/admin/returnsInbound?type=purchaseOrder`, {
                params: {
                    "filter": filter,
                }
            })
            .then((res) => {
                const responseData = res?.data?.data
                setInbounds(responseData?.fetchedReturns)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    }, [inboundDetails]);

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <DialogTitle>
                    Quality Check <FactCheckIcon /> 
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            <DialogContentText>
                                Purchase Order Name: {inboundDetails?.orderName}
                            </DialogContentText>
                            <DialogContentText>
                                Location Code: {inboundDetails?.locationCode}
                            </DialogContentText>
                        </div>
                        <Autocomplete
                            size='small'
                            required
                            id="user"
                            options={users}
                            onChange={(event, newValue) => {
                                setQcPerson(newValue);
                            }}
                            style={{ flex: '0.2', marginTop: '10px' }}
                            value={qcPerson || ''}
                            renderInput={(params) => <TextField {...params} required label="QC Person" defaultValue="" />}
                        />
                    </div>
                    <div style={{ margin: '20px 0px 5px 0px' }} >
                        <form onSubmit={handleSubmit} >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                                <div style={{ flex: '0.6', textAlign: 'center' }}>SKU</div>
                                <div style={{ flex: '0.6', textAlign: 'center' }}>Inbound No.</div>
                                <div style={{ flex: '0.6', textAlign: 'center' }}>Total Qty.</div>
                                <div style={{ display: 'flex', gap: '10px', flex: '1.25', justifyContent: 'space-around' }}>
                                    <div>Pass</div>
                                    <div>Fail</div>
                                </div>
                            </div>
                            {inbounds?.length > 0 && inbounds?.map((inbound, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                                            <div style={{ flex: '0.5', textAlign: 'center' }}>{inbound?.sku}</div>
                                            <div style={{ flex: '0.5', textAlign: 'center' }}>{inbound?.inboundNumber}</div>
                                            <div style={{ flex: '0.5', textAlign: 'center' }}>{inbound?.quantity}</div>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <TextField
                                                    size='small'
                                                    label="Pass"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    value={formData?.find((row) => row?.id === inbound?.id)?.pass}
                                                    min={1}
                                                    onChange={(e) => handlePassChange(e.target.value, inbound?.id, inbound?.quantity)}
                                                />
                                                <TextField
                                                    size='small'
                                                    disabled={true}
                                                    label="Fail"
                                                    type="number"
                                                    fullWidth
                                                    value={formData?.find((row) => row?.id === inbound?.id)?.fail}
                                                    min={1}
                                                    onChange={(e) => handleFailChange(e.target.value, inbound?.id, inbound?.quantity)}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                            })}
                            <DialogActions>
                                <Button type="submit" id='moveInventory' variant="contained" color="primary">Done</Button>
                                <Button onClick={closePopup}>Close</Button>
                            </DialogActions>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}

export default QcPopup;