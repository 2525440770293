import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedCancels: [],
};

const cancelsSlice = createSlice({
	name: 'cancels',
	initialState: initialState,
	reducers: {
		setCancels: (state, action) => action.payload,
		reset: (state, action) => initialState,
		setCancel: (state, action) => {
			const cancel = action.payload;
			const index = state.fetchedCancels.findIndex((r) => r._id === cancel._id);
			if (index !== -1) {
				state.fetchedCancels[index] = cancel;
			}
		},
	},
});

export const { setCancels, reset, setCancel } = cancelsSlice.actions;

export default cancelsSlice.reducer;
