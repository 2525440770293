import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	allDiscounts: [],
};

const discountSettingsSlice = createSlice({
	name: 'discounts',
	initialState: initialState,
	reducers: {
		setDiscounts: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setDiscounts, reset } = discountSettingsSlice.actions;

export default discountSettingsSlice.reducer;