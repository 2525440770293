import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';
import { ImCross } from 'react-icons/im';

const DocumentUploadComponent = (props) => {
    const { 
        handleFileSelect,
        defaultFiles,
        extractFileName,
        removeFile,
        setDefaultFiles,
        files,
        setFiles
    } = props;

    return (
        <Fragment>
            <div className={Classes.Box} style={{padding:'1.1rem'}}>
                <h6 style={{ marginBottom:15 }}>Documents</h6>
                <div className="mb-3">
                    <label htmlFor="file-upload" className="form-label">
                        Document Attached
                    </label>
                    <div className="input-group">
                        <input
                            id="file-upload"
                            type="file"
                            className="form-control"
                            multiple
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={handleFileSelect}
                        />
                    </div>

                    <ul className="list-group mt-3">
                        {defaultFiles?.map((fileName, index) => (
                            <li 
                                style={{ display:'flex',justifyContent:'space-between',alignItems:'center' }} 
                                className="list-group-item" key={index}
                            >
                                {decodeURIComponent(extractFileName(fileName))}
                                <ImCross 
                                    style={{ cursor:'pointer' }} 
                                    size={13} 
                                    onClick={() => removeFile(index, defaultFiles, setDefaultFiles)}
                                >Remove</ImCross>
                            </li>
                        ))}

                        {files?.map((fileName, index) => (
                            <li 
                                style={{ display:'flex',justifyContent:'space-between',alignItems:'center' }} 
                                className="list-group-item" key={fileName}
                            >
                                {decodeURIComponent(fileName.name)}
                                <ImCross 
                                    style={{ cursor:'pointer' }} 
                                    size={13} onClick={() => 
                                    removeFile(index, files, setFiles)}
                                >Remove</ImCross>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default DocumentUploadComponent;