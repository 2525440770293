import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	draftPurchaseOrders: [],
};

const draftPurchaseOrdersSlice = createSlice({
	name: 'draftPurchaseOrders',
	initialState: initialState,
	reducers: {
		setDraftPurchaseOrders: (state, action) => action.payload,
		reset: (state, action) => initialState,
		setDraftPurchaseOrder: (state, action) => {
			const draftPurchaseOrder = action.payload;
			const index = state.data.findIndex(
				(e) => e._id === draftPurchaseOrder._id
			);
			if (index !== -1) {
				state.data[index] = draftPurchaseOrder;
			}
		},
	},
});

export const { setDraftPurchaseOrders, reset, setDraftPurchaseOrder } = draftPurchaseOrdersSlice.actions;

export default draftPurchaseOrdersSlice.reducer;
