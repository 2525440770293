import { Fragment } from 'react';
import Classes from '../css/addProduct.module.css';
import React, { useState, useEffect } from 'react';
import { FormControl, Select, MenuItem, Box, Chip, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import axiosInstance from '../../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';

const ProductStatus = (props) => {
    const [cities, setCities] = useState([]);
    const [franchises,setFranchises] = useState([]);
    const [checkedSalesChannel, setCheckedSalesChannel] = useState([]);
    const [displayedSalesChannel, setDisplayedSalesChannel] = useState([]);
    
    const {
        payloadToUpload,
        setPayloadToUpload,
    } = props;

    const handleSelectAll = (event) => {
        if (event.target.innerText === 'Select All') {
            setPayloadToUpload({ 
                ...payloadToUpload, 
                salesChannel: displayedSalesChannel, 
                removeSalesChannel: [],
            });
            setCheckedSalesChannel(displayedSalesChannel);
        } else {
            setCheckedSalesChannel([]);
            setPayloadToUpload({ 
                ...payloadToUpload,
                salesChannel: [],
                removeSalesChannel: displayedSalesChannel,
            });
        }
    }

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axiosInstance('/pincode/getAllDistricts');
                const cities = await response?.data?.data;
                setCities(cities);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities();
    }, []);

    useEffect(() => {
        const fetchFranchises = async () => {
            try {
                const response = await axiosInstance('/pos/fetchAllFranchises');
                const franchises = await response?.data?.data;
                setFranchises(franchises);
            } catch (error) {
                console.error('Error fetching franchises:', error);
            }
        };

        fetchFranchises();
    }, []);

    useEffect(() => {
        if (payloadToUpload?.salesChannel?.length > 0) {
          setCheckedSalesChannel(payloadToUpload?.salesChannel);
        }
    }, [payloadToUpload?.salesChannel]);

    useEffect(() => {
        if (payloadToUpload?.l1 === "Boards & Laminates") {
            setDisplayedSalesChannel(['Website', 'Portal', 'App', 'POS', 'Laminate'])
        } else {
            setDisplayedSalesChannel(['Website', 'Portal', 'App', 'POS'])
        }
    }, [payloadToUpload?.l1]);

    const handleProductStatusCheckbox = (event) => {
        if (event?.target?.checked && !checkedSalesChannel?.includes(event?.target?.name)) {
            setCheckedSalesChannel([...payloadToUpload?.salesChannel, event?.target?.name]);
            setPayloadToUpload({
              ...payloadToUpload,
              salesChannel: [
                ...payloadToUpload?.salesChannel,
                event?.target?.name,
              ],
              removeSalesChannel: payloadToUpload?.removeSalesChannel?.filter(
                (channel) => channel !== event?.target?.name
              ),
            });
        } else {
            setCheckedSalesChannel(payloadToUpload?.salesChannel.filter((channel) => channel !== event?.target?.name));
            setPayloadToUpload({
              ...payloadToUpload,
              salesChannel: payloadToUpload?.salesChannel?.filter(
                (channel) => channel !== event?.target?.name
              ),
              removeSalesChannel: [
                ...payloadToUpload?.removeSalesChannel,
                event?.target?.name,
              ]
            });
        }
    }


    return (
        <Fragment>
            <div className={Classes.Box}>
                <div style={{marginBottom:10}}>
                    <Autocomplete
                        freeSolo
                        forcePopupIcon={true}
                        name="isActive" 
                        id="isActive" 
                        size='small'
                        options={["Active", "Draft"]}
                        disablePortal
                        ListboxProps={{sx: { fontSize: 14 }}}
                        sx={{  width: '100%' }}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionSelected ={(option, value) => option === value}
                        renderInput={(params) => <TextField {...params} label="Product Status"  />}
                        value={payloadToUpload?.isActive ? "Active" : "Draft"}
                        onChange={(event, value) => setPayloadToUpload((prevPayloadToUpload)=>{
                            return {
                                ...prevPayloadToUpload,
                                isActive: value === "Active" ? true : false,
                            }
                        })}
                    />
                </div>
                
                <div style={{ margin: '10px 0px', borderBottom: '1px solid lightgray', paddingBottom: 10 }}>
                    <p className={Classes.Sales}>SALES CHANNELS AND APPS</p>
                    <p 
                        className={[Classes.DeselectAll, Classes.fontSize].join(' ')} 
                        onClick={(e) => handleSelectAll(e)} style={{ cursor: 'pointer' }}
                    >
                        {checkedSalesChannel?.length === displayedSalesChannel?.length ? 
                        'Deselect All' : 'Select All'}
                    </p>
                </div>

                <div>
                    {displayedSalesChannel?.map((channel,index) => {
                        return (
                            <div key={index}>  
                                <FormControlLabel
                                    label={channel}
                                    control={
                                        <Checkbox
                                            name={channel}
                                            checked={checkedSalesChannel?.includes(channel)}
                                            onChange={handleProductStatusCheckbox}
                                        />
                                    }
                                />
                            </div>
                        );
                    })}

                    <div style={{ margin: '10px 0px', borderTop: '1px solid lightgray', paddingTop: 10 }}>
                        <div className='d-flex flex-column gap-3 justify-content-between mb-2'>
                            <Tooltip title='Choose the district where the product will be visible'>
                                <p className={Classes.Sales}>
                                    Districts
                                </p>
                            </Tooltip>

                            <FormControl>
                                <Select
                                    labelId='city-select-label'
                                    displayEmpty
                                    size='small'
                                    id='city-select'
                                    multiple
                                    value={payloadToUpload?.locations || []}
                                    onChange={(event)=>{
                                        setPayloadToUpload((prevPayloadToUpload)=>{
                                            return {
                                                ...prevPayloadToUpload,
                                                locations: event?.target?.value,
                                            }
                                        })
                                    }}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <React.Fragment>Select Cities</React.Fragment>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected?.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )
                                    }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="">Select Cities</MenuItem>
                                    {cities?.map((city) => (
                                        <MenuItem key={city} value={city}>
                                            {city}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div style={{ margin: '10px 0px', borderTop: '1px solid lightgray', paddingTop: 10 }}>
                        <div className='d-flex flex-column gap-3 justify-content-between mb-2'>
                            <Tooltip title='Choose the franchise where the product will be visible'>
                                <p className={Classes.Sales}>
                                    Franchises
                                </p>
                            </Tooltip>

                            <FormControl>
                                <Select
                                    labelId='franchise-select-label'
                                    displayEmpty
                                    size='small'
                                    id='franchise-select'
                                    multiple
                                    value={payloadToUpload?.franchises || []}
                                    onChange={(event)=>{
                                        setPayloadToUpload((prevPayloadToUpload)=>{
                                            return {
                                                ...prevPayloadToUpload,
                                                franchises: event?.target?.value,
                                            }
                                        })
                                    }}
                                    renderValue={(selected) => {
                                        if (selected?.length === 0) {
                                            return <React.Fragment>Select Franchise</React.Fragment>;
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected?.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )
                                    }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="">Select Franchise</MenuItem>
                                    {franchises?.map((franchise) => (
                                        <MenuItem key={franchise} value={franchise}>
                                            {franchise}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductStatus;
