import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import Classes from './css/refund.module.css';
import axiosInstance from '../../../utility/axios-instance';
import { setRefunds } from '../../../store/refundsSlice';
import resetState from '../../../store/actions';

import Refund from './refund';
import Spinner from '../../../components/spinner';
import Search from '../../../components/search';
import RefundMobile from './refundMobile';
import NewPagination from '../../../components/newPagination';

const Refunds = (props) => {
	const refunds = useSelector((state) => state.refunds);
	const dispatch = useDispatch();
	const ref = useRef();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const [text, setText] = useState('');

	const fetchRefunds = async (page) => {
		setLoading(true);
		setError(null);
		if (!page) {
			page = refunds.page;
		}
		const params = {
			page,
		};
		await axiosInstance
			.get('/refunds', {
				params,
			})
			.then((response) => {
				dispatch(setRefunds(response?.data?.data));
			})
			.catch((error) => {
				setError(error?.response?.data?.message);
				if (error?.response?.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
				clearTimeout(ref.current);
				ref.current = setTimeout(() => {
					fetchRefunds(page);
				}, 1000000);
			});
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page');
		fetchRefunds(page ? page: 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="d-flex flex-column-reverse flex-md-row justify-content-between mb-5">
				<div className="px-3 py-1 border border-dark rounded text-center">
					<p className="mb-0 fs-5">
						<strong>Refunds</strong>
					</p>
				</div>
				<button
					type="button"
					className={`${Classes.ReloadBtn} btn btn-outline-dark`}
					onClick={() => {
						fetchRefunds();
					}}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
					{loading ? 'Loading...' : 'ReLoad'}
				</button>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			<Search func={fetchRefunds} text={text} setText={setText} />
			<div className="d-block d-sm-none">
				{refunds?.refunds?.map((refund) => (
					<RefundMobile key={refund?._id} refund={refund} />
				))}
			</div>

			<table className={`${Classes.Table} table d-none d-sm-table`}>
				<thead>
					<tr>
						<th scope="col">Order</th>
						<th scope="col">Against</th>
						<th scope="col">OutputKey</th>
						<th scope="col">LineItem</th>
						<th scope="col">SKU</th>
						<th scope="col">Created At</th>
						<th scope="col">Status</th>
						<th scope="col">Amount</th>
					</tr>
				</thead>
				<tbody
					style={{
						fontSize: '.8rem',
					}}
				>
					{refunds?.refunds?.map((refund) => (
						<Refund key={refund?._id} refund={refund} />
					))}
				</tbody>
			</table>

			{refunds?.refunds?.length > 0 ? (
				<nav aria-label="Page navigation">
					<NewPagination 
						totalPage={refunds}
						fetchData={fetchRefunds}
					/>
				</nav>
			) : null}
			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default Refunds;
