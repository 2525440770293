import React, { Fragment, useEffect, useState } from "react";
import { TextField, Autocomplete } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RiderUpdatePopup = (props) => {
    const [locationCodes, setLocationCodes] = useState([]);
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" });
    
    const {
        showPopup,
        hidePopup,
        reRender,
        setReRender,
        riderId,
        riderName,
        locationCode
    } = props;

    useEffect(() => {
        const fetchLocationCodes = async () => {
            try {
                const response = await axiosInstance.get(`/location/locations?text=`)
                
                let locations = response?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            } catch (error) {
                const errorMsg = error?.response?.data?.message;
                console.error(`Error: ${errorMsg}`);
            }
        };
        fetchLocationCodes();
    }, []);

    const handleRiderUpdate = async (event) => {
        event.preventDefault();
        setLoading(true);
        const riderData = {
            deliveryRider: event.target.name.value,
            locationCode: event.target.location.value,
        }

        await axiosInstance
            .put(`/pgOrder/admin/updateDeliveryRider/${riderId}`, riderData)
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                setReRender(!reRender)
                hidePopup();
                const successMessage = 'Rider Created Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err)
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }


    return (
        <Fragment>
            <Dialog
                open={showPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={hidePopup}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>Update Delivery Rider</DialogTitle>
                <form method="post" onSubmit={handleRiderUpdate} id='form'>
                    <div style={{display:'flex',justifyContent:'space-between',padding:20,height:300}}>
                        <TextField
                            required
                            id='name'
                            type="text"
                            label="Rider Name"
                            name='name'
                            key={riderName}
                            defaultValue={riderName}
                            placeholder='Enter  Rider Name'
                            autoComplete="off"
                            style={{ width:'49%' }}
                        />

                        <Autocomplete
                            freeSolo
                            disablePortal
                            id="location"
                            name="location"
                            options={locationCodes}
                            key={locationCode}
                            defaultValue={locationCode}
                            sx={{ width:'49%' }}
                            renderInput={(params) =>
                                <TextField {...params} label={"Locations"} required  />
                            }
                        />
                    </div>

                    <DialogActions>
                        <Button onClick={hidePopup}>CLOSE</Button>
                        <Button type='submit' id='btn'>UPDATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
             
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default RiderUpdatePopup;