import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  page: 1,
  maxPage: 1,
  laminateDetails: [],
};

const laminateSettingsSlice = createSlice({
  name: "laminateImage",
  initialState: initialState,
  reducers: {
    setBanners: (state, action) => action.payload,
    reset: (state, action) => initialState,
  },
});

export const { setBanners, reset } = laminateSettingsSlice.actions;

export default laminateSettingsSlice.reducer;
