import { Fragment, useEffect, useState } from "react"
import QRCode from 'qrcode';
import Classes from './css/addProduct.module.css';
import { Button, TextField } from "@mui/material";
import axiosInstance from "../../../../utility/axios-instance";
import SnackBar from "../../../../components/SnackBar";

const MainQrcodeBarcode = (props) => {
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const { payloadToUpload, setPayloadToUpload } = props;
    const [QRCimageData, setQRCImageData] = useState('');
    const [barcodeValue, setBarcodeValue] = useState(payloadToUpload?.sku);

    useEffect(()=>{
        getApkQRCode(payloadToUpload?.pgDbProductId);
    },[])

    const fetchGeneratedBarcode = async (barcodeValue) => {
        await axiosInstance
            .post(`/product/generateBarcode/${payloadToUpload?.docDbProductId}`,{
                barcodeValue
            })
            .then((res) => {
                setPayloadToUpload((prevPayloadToUpload) => {
                    return { 
                        ...prevPayloadToUpload,
                        barcodeDataUrl: res?.data?.data?.barcode
                    };
                });
            })
            .catch((error) => {
                console.error("error ",error)
                const errorMessage = error?.response?.data?.errorMessage ?? "Error in generating barcode";
                setSnackBar({ display: true, type: 'error', message: errorMessage });
            })
    }

    const getApkQRCode = async (Id) =>{
        var opts = {
            errorCorrectionLevel: 'M',
            quality: 0.3,
            margin: 1,
        }
        const URL = `https://depo24.page.link/?link=https://depo24.in/products/${Id}&apn=in.depo24.depo_24&ibi=in.depo24.depo24`;
        QRCode.toDataURL(URL,opts, (err, url) => {
            if(err) {
                console.error(err)
            }else{
                setQRCImageData(url);
            }
        })
    }

    const handlePrint = async (e) => {
        e.preventDefault();
        const printPage = window.open("","_blank","")
        printPage.document.write(`
            <html>
                <head><title>Print Window</title></head>
                <body>
                    <img alt="qrc" src=${QRCimageData} width="66" height="66" onload="window.print();window.close()"/>
                    <p style="margin:2px 1px auto;font-size:10px;line-height:10px">${payloadToUpload?.sku}</p>
                </body>
            </html>
        `)
    }

    const handleDownload = (e)=>{
        e.preventDefault();
        const anchor = document.createElement('a');
        anchor.href = QRCimageData;
        anchor.download = 'QrCode.png';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
    
    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            <div className="shadow p-3 rounded my-2 mx-2">
				<div id="image-to-print">
					{/* <QrCodeCompo id={Id}/>  This is stylish qrCode we will use further in future */}
					<img className="ms-3" alt='QrCode' id='qrCode' width="100px" height="100px" src={QRCimageData} />
                
					<p style={{marginTop:'3px', marginLeft:'10px', fontSize:'10px' , lineHeight:'10PX'}}>{payloadToUpload?.sku}</p>
				</div>
				<br />
				<button 
					style={{ margin:'3px' }}
					className={Classes.UpdateBtn}
					onClick={handlePrint}>Print QRC </button><br/>
				<button 
					style={{ margin:'3px' }}
					className={Classes.UpdateBtn}
					onClick={handleDownload}>Download</button>
				
			</div>
            <div className="shadow p-3 rounded my-2 mx-2">
                {payloadToUpload?.barcodeDataUrl && <img alt='barcode' src={payloadToUpload?.barcodeDataUrl} width={230} height={130}/>}
                <div>
                <TextField
                    id="barcodeValue"
                    label="Barcode value"
                    variant="standard"
                    sx={{
                        margin:'10px auto'
                    }}
                    value={barcodeValue}
                    onChange={e => setBarcodeValue(e.target.value)}
                />

                <Button variant="outlined"
                    onClick={() => fetchGeneratedBarcode(barcodeValue)}
                >
                    GENERATE BARCODE
                </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default MainQrcodeBarcode;