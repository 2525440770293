import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Spinner from '../../../../components/spinner';
import SnackBar from '../../../../components/SnackBar';
import axiosInstance from '../../../../utility/axios-instance';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SwitchComponent = (props) => {
    const {label='', checked, setChecked, width='100%'} = props
    return <>
        <div onClick={()=>setChecked(!checked)}
        style={{border:'1.5px solid lightgrey',borderRadius:'5px',display:'flex',justifyContent:'space-between', width: width,cursor:'pointer'}}>
            <label style={{alignSelf:'center',paddingLeft:'10px'}}>{label}</label>
            <Switch
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
            />
        </div>
    </>
}

export default function NotificationDialog(props) {
    const { open, action, hideCreatePopup,
        setSelectedUserRoles, selectedUserRoles, selectedRestrictedUsers, setSelectedRestrictedUsers, active, setActive,
        setFeature, setType,
        feature, type, handleSubmit} = props;
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [userRoles, setUserRoles ] = useState([]);
    const [users, setUsers ] = useState([]);


    useEffect(() => {
        axiosInstance
            .get('/roles')
            .then((response) => {
                setUserRoles(response?.data?.data)
            })

            axiosInstance
            .get('/admin/allAdmins')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data) {
                    arr.push(ele?.username)
                }
                setUsers(arr);
            })
    }, [])

    const handleClose  = () => {
        hideCreatePopup();
    }
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                sx={{'& .MuiDialog-paper': {maxWidth:'800px'}}}
            >
                <DialogTitle>Notification Setting</DialogTitle>
                <DialogContent>
                    <form method="post" 
                        onSubmit={handleSubmit}
                        id='form' >
                        <div style={{ padding: "5px 20px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TextField
                                    type="text"
                                    label={'Feature'}
                                    placeholder='Feature Name'
                                    key={'Feature'}
                                    required={true}
                                    id='feature'
                                    name={'feature'}
                                    value={feature}
                                    autoComplete='off'
                                    sx={{ width: '48%' }}
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setFeature(e.target.value)}
                                />

                                <TextField
                                    type="text"
                                    label={'Type'}
                                    placeholder='Feature type'
                                    key={'FeatureType'}
                                    required={true}
                                    id='featureType'
                                    name={'featureType'}
                                    value={type}
                                    autoComplete='off'
                                    sx={{ width: '48%' }}
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setType(e.target.value)}
                                />

                            </div>

                            <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                                <Autocomplete
                                    required={true}
                                    multiple={true}
                                    limitTags={1}
                                    disablePortal
                                    id="userRoles"
                                    name='userRoles'
                                    options={userRoles}
                                    onChange={(e,newValue) => setSelectedUserRoles(newValue)}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '48%' }}
                                    renderInput={(params) => <TextField {...params} label="User Roles" />}
                                    size="medium"
                                    value={selectedUserRoles}
                                />

                                <Autocomplete
                                    multiple={true}
                                    disablePortal
                                    limitTags={1}
                                    id="restrictedUsers"
                                    name='Restricted Users' 
                                    options={users}
                                    getOptionLabel={(option) => option || ''}   
                                    onChange={(e,newValue) => setSelectedRestrictedUsers(newValue)}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '48%' }}
                                    renderInput={(params) => <TextField {...params} label="Restricted Users" />}
                                    size="medium"
                                    value={selectedRestrictedUsers}
                                />
                            </div>

                            <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                                {/* <Autocomplete
                                    required={true}
                                    disablePortal
                                    id="location"
                                    name='location'
                                    options={cities}
                                    onChange={(e,newValue) => setLocation(newValue)}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '48%' }}
                                    renderInput={(params) => <TextField {...params} label="Cities" required />}
                                    size="small"
                                    value={location}
                                /> */}

                                {/* { action === "update"
                                    && <SwitchComponent
                                    label={"Active"}
                                    checked={active}
                                    setChecked={setActive}
                                    width={"48%"}
                                />} */}
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>CLOSE</Button>
                            {
                                action === "create"
                                ? <Button type='submit' id='btn'>CREATE</Button>
                                : <Button type='submit' id='btn'>UPDATE</Button>
                            }
                            
                        </DialogActions>
                    </form>
                    {loading ? <Spinner /> : null}
                </DialogContent>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}