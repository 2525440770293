import React, { useEffect, useState } from 'react'
import axiosInstance from "../../../../utility/axios-instance";
import Permission from '../../../auth/permissions';
import { services } from '../../../../utility/checkRoleBasedPermission';
import { Button, Chip, Switch, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Delete} from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Table from '../../../../components/table/table';
import Spinner from '../../../../components/spinner';
import SnackBar from '../../../../components/SnackBar';
import Classes from '../../customers/css/customer.module.css'
import { setNotificationSettings } from '../../../../store/notificationSettingSlice';
import NotificationDialog from './notificationDialog';


const NotificationSettings = () => {
    const [loading, setLoading] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [snackBar, setSnackBar] = useState([]);
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const user = auth?.user
    const notificationSettings = useSelector((state) => state.notificationSettings);
    const [action, setAction] = useState("create")
    const [showPopup, setShowPopup] = useState(false);

    const [id , setId ] = useState(null);
    const [feature, setFeature] = useState(null);
    const [type, setType] = useState(null);
    const [selectedUserRoles, setSelectedUserRoles] = useState([]);
    const [selectedRestrictedUsers, setSelectedRestrictedUsers] = useState([]);
    // const [location, setLocation] = useState(null);
    const [active, setActive] = useState(true);

    const handleDeleteNotificationSetting = async (event, id) => {
        console.log("iD : ", id)
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .delete(`/adminNotifications/deleteNotificationSetting/${id}`)
            .then((res) => {
                setReRender(!reRender);
                setLoading(false);
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleUpdateNotificationSetting = (data) => {
        setAction("update");
        setId(data.id);
        setFeature(data.feature);
        setType(data.type);
        setSelectedUserRoles(data.user_roles);
        setSelectedRestrictedUsers(data.restrictedUsers ?? []);
        // setLocation(data.location);
        setActive(data.deleted_at ? false : true);
        showCreatePopup();
    }

    const showCreatePopup = () => {
        setShowPopup(true)
    }

    const hideCreatePopup = () => {
        setShowPopup(false)
        setAction("create");
        setFeature(null);
        setType(null);
        setSelectedUserRoles([]);
        setSelectedRestrictedUsers([]);
        // setLocation(null);
        setActive(true);
        const form = document.getElementById('form');
        form.reset();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        hideCreatePopup();
        setLoading(true);

        let data = {
            feature, type,
            userRoles: selectedUserRoles,
            restrictedUsers: selectedRestrictedUsers,
            createdBy: user?.name,
            // location,
            active
        }
        if(action === "create"){
            axiosInstance
                .post(`/adminNotifications/createNotificationSetting`, {...data})
                .then((res) => {
                    setReRender(!reRender);
                    setLoading(false);
                    setSnackBar({ display: true, type: "success", message: res?.data?.message })
                }).catch((err) => {
                    setLoading(false);
                    setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
                })
        }
        else {
            axiosInstance
                .put(`/adminNotifications/updateNotificationSetting/${id}`, {...data})
                .then((res) => {
                    setReRender(!reRender);
                    setLoading(false);
                    setSnackBar({ display: true, type: "success", message: res?.data?.message })
                }).catch((err) => {
                    setLoading(false);
                    setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
                })
        }
    }

    const columnVisibilityModel = {
        location: false,
        last_updated_by: false,
        deleted_at: false,
        updated_at: false,
    }

    const columns = [
    {
        field: `id`,
        headerName: `ID`,
        flex: .3,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `feature`,
        headerName: `Feature`,
        flex: .8,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `type`,
        headerName: `Type`,
        flex: .6,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `user_roles`,
        headerName: `User Roles`,
        flex: 1.5,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
        renderCell : (params) => 
            (
                <div>
                    {params?.row?.user_roles?.slice(0,2)?.map((role, index) => (
                        <Chip key={index} label={role} style={{ margin: '3px' }} variant="outlined" color="primary" size='medium'/>
                    ))}
                    {params?.row?.user_roles?.length > 2 ? (
                        <Tooltip title={
                            <div>
                                {params?.row?.user_roles?.slice(2)?.map((role, index) => (
                                    <p>{role}, </p>
                                ))}
                            </div>
                        } arrow>
                        <Chip size="medium" color='primary' variant='outlined' label={`+ ${params?.row?.user_roles?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                        </Tooltip>
                    ) : null}
                </div>
            )
    },
    {
        field: `restrictedUsers`,
        headerName: `Restricted Users`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
        renderCell : (params) => (
            params?.row?.restrictedUsers?.length > 0 && <div>
                {params?.row?.restrictedUsers?.map((user, index) => (
                    user ? <Chip sx={{margin: '2px'}} key={index} label={user} variant="filled" color="primary" size='medium'/> : null
                ))}
            </div>
        )
    },
    {
        field: `location`,
        headerName: `Location`,
        flex: .7,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `created_by`,
        headerName: `Created By`,
        flex: .8,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `last_updated_by`,
        headerName: `Last Updated By`,
        flex: .8,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `created_at`,
        headerName: `Created At`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `updated_at`,
        headerName: `Updated At`,
        flex: 1.2,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `deleted_at`,
        headerName: `Deleted At`,
        flex: 1.2,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: false,
        filterable: false,
    },
    {
        field: `update`,
        headerName: `Update`,
        flex: .5,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        sortable: false,
        renderCell : (params) => <Permission service={services?.pincodeSetting} permission={"delete"}>
            <Button size='small' variant='outlined' color='success' onClick={() => handleUpdateNotificationSetting(params.row)}>UPDATE</Button>
        </Permission>
    },
    {
        field: `deleted`,
        headerName: `Delete`,
        flex: .5,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell : (params) => <Permission service={services?.notificationSetting} permission={"delete"}>
            <div style={{cursor : 'pointer'}} onClick={(e) => handleDeleteNotificationSetting(e, params?.row?.id)}><Delete color='error'/></div>
        </Permission>
    },
];


return (
    <>
        <div style={{marginTop : '20px'}}>
            <Permission service={services?.discountSetting} permission={"create"}>
                <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'5px'}}>
                    <Button startIcon={<AddCircleIcon />} 
                        sx={{
                            textTransform: "capitalize",
                            "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                        }}
                        variant="contained"
                        onClick={() => {
                            setAction("create");
                            showCreatePopup();
                        }}
                    >Add New</Button>
                </div>
            </Permission>
            <Permission service={services?.discountSetting} permission={"read"}>
            <div className={Classes.MuiTable}>
                <Table 
                    rowId={'id'} 
                    columns={columns} 
                    dataPosition={"notificationSettings"} 
                    endpoint={'/adminNotifications/getNotificationSettings'}
                    checkBoxSelection={false} 
                    reRender={reRender} 
                    service={'notificationSetting'}
                    tabName={'Notification Settings'}
                    setData={setNotificationSettings}
                    data={notificationSettings}
                    columnVisibilityModel={columnVisibilityModel}
                />
                {loading ? <Spinner /> : null}
            </div>
            </Permission>

        </div>

        <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
            <NotificationDialog 
                action={action}
                open={showPopup}
                setShowPopup={setShowPopup}
                setSnackBar={setSnackBar}
                setReRender={setReRender}
                feature={feature}
                setFeature={setFeature}
                type={type}
                setType={setType}
                selectedUserRoles={selectedUserRoles}
                setSelectedUserRoles={setSelectedUserRoles}
                selectedRestrictedUsers={selectedRestrictedUsers}
                setSelectedRestrictedUsers={setSelectedRestrictedUsers}
                // location={location}
                // setLocation={setLocation}
                active={active}
                setActive={setActive}
                hideCreatePopup={hideCreatePopup}
                handleSubmit={handleSubmit}
            />
        </div>
        {loading ? <Spinner /> : null}
    <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
)
}

export default NotificationSettings
