import { reset as ra } from './authSlice';
import { reset as rr } from './returnsSlice';
import { reset as ro } from './ordersSlice';
import { reset as rc } from './cancelsSlice';
import { reset as ru } from './usersSlice';
import { reset as rrf } from './refundsSlice';
import { reset as dor } from './draftOrdersSlice';
import { reset as dbr } from './deliveryBoySlice';
import { reset as pr } from './breadcrumbSlice';
import { reset as cBC } from './storeSelectedCheckbox';


const resetState = (dispatch) => {
	dispatch(ra());
	dispatch(rr());
	dispatch(ro());
	dispatch(rc());
	dispatch(ru());
	dispatch(rrf());
	dispatch(dor());
	dispatch(dbr());
	dispatch(pr());
	dispatch(cBC());
};

export default resetState;
