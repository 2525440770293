import React, { Fragment, useState, useEffect, forwardRef } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete, Switch, FormControlLabel, Chip, Avatar } from '@mui/material';
import AutoCompleteValueComponent from '../../../../components/autoCompleteValue';
import Spinner from '../../../../components/spinner';
import { useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { CheckRoleBasedPermission, permission, services } from "../../../../utility/checkRoleBasedPermission";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactPopUp = (props) => {
    const [loading,setLoading] = useState(false);
    const [salesTeam,setSalesTeam] = useState([]);
    const [salesUser,setSalesUser] = useState('');
    const [preSalesUser,setPreSalesUser] = useState('');
    const [states,setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const role = auth?.user?.role;
    const {
        contactModal,
        hideContactPop,
        showCustomerProfile,
        setCustomerProfile,
        displayName,
        setDisplayName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        businessName,
        setBusinessName,
    } = props;

    // const handleNumChange = event => {
    //     const limit = 10;
    //     setNumber(event.target.value.slice(0, limit));
    // };
    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => { return { ...prevSnackBar, display: false }});
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value);
    };

    useEffect(() => {
        if (businessName !== "") {
        setDisplayName(businessName);
        } else {
        setDisplayName(`${firstName} ${lastName}`);
        }
    }, [firstName, lastName, businessName]);

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setStates(states)
            })
    },[])

    useEffect(() => {
        axiosInstance
            .get('/admin/salesTeam')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data?.users) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setSalesTeam(arr);
            })
    },[]);

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();

        const roles = {
            "Retailer": "retailer",
            "Special Retailer": "special_retailer",
            "Interior": "interior",
            "Oem": "oem",
            "End User": "end_user",
            "No Role": "remove"
        }

        const data = {
            firstName: event.target.first.value,
            lastName: event.target.lastName.value ? event.target.lastName.value : undefined,
            email: event.target.email.value || undefined,
            customerRole: roles[event.target.role.value],
            customerType: event.target.customerType.value,
            businessName: event.target.businessName.value ? event.target.businessName.value : undefined,
            salesUser: salesUser ? salesUser : undefined,
            preSalesUser: preSalesUser ? preSalesUser: undefined,
            state : selectedState ? selectedState : undefined,
            phone: event.target.phone.value ? `+91${event.target.phone.value}` : undefined,
            isManuallyVerified: event?.target?.isManuallyVerified?.checked ? true : undefined,
        };

        axiosInstance
            .post(`/user/admin/${showCustomerProfile?._id}`, { ...data })
            .then((res) => {
                hideContactPop();
                setLoading(false);
                setCustomerProfile(res.data.data);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: "Customer Update Successfully", type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    };

    const capitalName = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const roles = ["Retailer","Special Retailer","Interior","Oem","End User","No Role"];

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('addCustomer').disabled = false;
            }else{
                document.getElementById('addCustomer').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('addCustomer').disabled = false;
        }
    }

    return (
        <Fragment>
            {contactModal && (
                <div className={[Classes.modal, Classes.contactPopUp].join(" ")}>
                    <div className={Classes.BoxContent}>
                        <p>Update Contact Details</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id="form">
                        <div style={{ padding: "30px 20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width:"30%" }}>
                                    <TextField 
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        placeholder='Enter First Name'
                                        key={showCustomerProfile?.firstName}
                                        defaultValue={showCustomerProfile?.firstName}
                                        autoComplete='off'
                                        sx={{ width: '100%'}}
                                        onChange={handleFirstNameChange}
                                    />
                                </div>
                                <div style={{ width: "30%" }}>
                                    <TextField 
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        placeholder='Enter Last Name'
                                        key={showCustomerProfile?.lastName}
                                        defaultValue={showCustomerProfile?.lastName}
                                        autoComplete='off'
                                        sx={{ width: '100%'}}
                                        onChange={handleLastNameChange}
                                    />
                                </div>
                                <div style={{ width: "38%" }}>
                                    <TextField
                                        id='displayName'
                                        name='displayName'
                                        label='Display Name'
                                        placeholder='Enter Display Name'
                                        key={displayName}
                                        defaultValue={displayName}
                                        disabled={true}
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <TextField
                                        id='email'
                                        name='email'
                                        label='Email'
                                        placeholder='Enter Email'
                                        key={showCustomerProfile?.email}
                                        defaultValue={showCustomerProfile?.email}
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width:"32%" }}>
                                    <TextField
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        type='number'
                                        placeholder='Enter Your Phone No.'
                                        key={showCustomerProfile?.phone?.slice(3)}
                                        defaultValue={showCustomerProfile?.phone?.slice(3)}
                                        maxLength={10}
                                        onInput={(e)=>{ e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,10)}}
                                        inputProps={{ maxLength: 10 }}
                                        onBlur={(e) => handleMinLength(e)}
                                        autoComplete='off'
                                        disabled={['super-admin', 'admin'].includes(role) ? false: true}
                                        sx={{ width: '100%'}}
                                    />

                                    <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                        <p>Please enter a valid 10 digit no.</p>
                                    </div>
                                </div>

                                <div style={{ width:"33%" }}>
                                    <TextField 
                                        id='businessName'
                                        name='businessName'
                                        label='Business Name'
                                        placeholder='Enter Business Name'
                                        key={showCustomerProfile?.businessName}
                                        defaultValue={showCustomerProfile?.businessName}
                                        autoComplete='off'
                                        sx={{ width: '100%'}}
                                        onChange={handleBusinessName}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <AutoCompleteValueComponent 
                                        fieldName={'Customer Type'}
                                        name={'customerType'}
                                        options={["B2B", "B2C"]}
                                        value={showCustomerProfile?.customerType}
                                    />
                                </div>

                                <div style={{ width: "32%" }}>
                                    <AutoCompleteValueComponent 
                                        fieldName={'Customer Role'}
                                        name={'role'}
                                        options={roles}
                                        value={showCustomerProfile?.customerRole ? capitalName(showCustomerProfile?.customerRole): ''}
                                    />
                                </div>

                                <div style={{ width: "33%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="salesUser"
                                        options={salesTeam}
                                        key={showCustomerProfile?.salesUser ? showCustomerProfile?.salesUser?.name : ''}
                                        defaultValue={showCustomerProfile?.salesUser ? showCustomerProfile?.salesUser?.name : ''}
                                        sx={{width: '100%'}}
                                        onChange={(event, newValue) => { setSalesUser(newValue.Id);}}
                                        renderInput={(params) => <TextField {...params} label="Sales User"/>}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="preSalesUser"
                                        options={salesTeam}
                                        key={showCustomerProfile?.preSalesUser ? showCustomerProfile?.preSalesUser?.name : ''}
                                        defaultValue={showCustomerProfile?.preSalesUser ? showCustomerProfile?.preSalesUser?.name : ''}
                                        sx={{width: '100%'}}
                                        onChange={(event, newValue) => { setPreSalesUser(newValue.Id);}}
                                        renderInput={(params) => <TextField {...params} label="Pre-Sales User"/>}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        // freeSolo
                                        disablePortal
                                        id="state"
                                        name='state'
                                        options={states}
                                        key={showCustomerProfile?.state ? showCustomerProfile?.state : ''}
                                        defaultValue={showCustomerProfile?.state ? showCustomerProfile?.state : ''}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{  width: '100%' }}
                                        onChange={(event, newValue) => {setSelectedState(newValue.id)}}
                                        renderInput={(params) => <TextField {...params} label="States"/>}
                                    />
                                </div>

                                <div style={{ width:"32%" }}>
                                    <TextField
                                        id='customerCode'
                                        name='customerCode'
                                        label='Customer Code'
                                        placeholder="Your code will be generate after selecting state"
                                        key={showCustomerProfile?.customerCode}
                                        defaultValue={showCustomerProfile?.customerCode}
                                        disabled={true}
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width:"32%" }}>
                                    { 
                                        showCustomerProfile?.manuallyVerifiedBy
                                        ? <div>
                                            <div>
                                                Manually Verified
                                                <VerifiedUserIcon />
                                            </div>
                                            <div>
                                                <Chip 
                                                    label={`By ${showCustomerProfile?.manuallyVerifiedByName}`} 
                                                    avatar={
                                                        <Avatar>{
                                                                showCustomerProfile?.manuallyVerifiedByName?.slice(0,1).toUpperCase()
                                                            }
                                                        </Avatar>
                                                    }
                                                    component="a" 
                                                    onClick={() => {
                                                        CheckRoleBasedPermission(auth?.user, services.users, permission.read) &&
                                                        navigate(`/users/${showCustomerProfile?.manuallyVerifiedBy}`)
                                                    }} 
                                                    clickable
                                                />                                                
                                            </div>
                                        </div>
                                        : <FormControlLabel 
                                            control={
                                                <Switch 
                                                    name='isManuallyVerified'
                                                    inputProps={{ 
                                                        'aria-label': 'Color switch demo' 
                                                    }}
                                                    onClick={(e) => {
                                                        if(
                                                            e.target.checked
                                                        ){
                                                            const isConfirmed = window.confirm('Are you sure you want to manually verify this customer?')
                                                            if(isConfirmed){
                                                                e.target.checked = true;
                                                            }else{
                                                                e.target.checked = false;
                                                            }
                                                        }else{
                                                            e.target.checked = false;
                                                        }
                                                    }}
                                                    value={showCustomerProfile?.manuallyVerifiedBy ? true : false}
                                                />
                                            } 
                                            label="Manually Verify" 
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'13%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            )}
            <Snackbar open={snackBar?.display} autoHideDuration={1500} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default ContactPopUp;
