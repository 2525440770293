import { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { BiArrowBack } from 'react-icons/bi';
import { MdOutlineCancel } from 'react-icons/md';
import Classes from './css/order.module.css';
import ConfirmPopup from './confirmPopup';
import TaxRatesPopup from '../ordersNew/TaxRates_popup';
import TaxRatesPrint from '../draftOrdersNew/TaxRatesPrint';

import axiosInstance from '../../../utility/axios-instance';
import resetState from '../../../store/actions';

import Spinner from '../../../components/spinner';
import LineItem from './lineItem';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';

const years = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const OrderId = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [totalItems, setTotalItems] = useState(0);
	const [order, setOrder] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [showConfirmPopup, setConfirmShowPopup] = useState(false);
	const [text,setText] = useState('');
	const [showCustomer,setCustomer] = useState([]);
	const [showTaxRate,setTaxRates] = useState(false);
	const [taxRates,setTaxRateArray] = useState([]);

	const auth = useSelector((state) => state.auth);

	const handleTaxRates = () => {
		setTaxRates(true);
	};

    const hideTaxPopup = () => {
        setTaxRates(false);
    }

	const fetchOrder = async () => {
		setLoading(true);
		setError(null);

		axiosInstance
			.get(`/orders/admin/${id}`)
			.then((response) => {
				let ti = 0;
				for (let i = 0;i < response?.data?.data?.lineItems?.length;i++) {
					ti += response?.data?.data?.lineItems[i]?.quantity;
				}
				axiosInstance
					.get(`/user/admin/${response?.data?.data?.user}`)
					.then((res) => {
						setCustomer([res.data.data]);
					})
					
					const taxArray = [];
					for (let ele of response?.data?.data?.lineItems) {
						if (ele?.totalTax) {
							if (taxArray.length === 0) {
								taxArray.push({
									hsnCode: ele?.hsnCode,
									tax: ele?.totalTax,
									groupName: ele?.groupName,
									gst: ele?.gst
								})
							} else {
								const index = taxArray.findIndex(variantIndex => variantIndex.hsnCode === ele.hsnCode);
								if (index >= 0) {
									taxArray[index].tax += ele?.totalTax;
								} else {
									taxArray.push({
										hsnCode: ele?.hsnCode,
										tax: ele?.totalTax,
										groupName: ele?.groupName,
										gst: ele?.gst
									})
								}
								
							}
						}
					}


					setTaxRateArray(taxArray)
					setOrder(response.data.data);
					if (response.data.data.invoiceType) {
						if (response.data.data.invoiceType === 'bill') {
							document.getElementById('bill').checked = true;
							document.getElementById('billBox').style.marginRight = '10px';
							document.getElementById('challanBox').style.display = 'none';
						} else {
							document.getElementById('challan').checked = true;
							document.getElementById('challanBox').style.marginRight = '10px';
							document.getElementById('billBox').style.display = 'none';
						}
					} else {
						document.getElementById('invoiceTypes').style.display = 'none';
					}
					setTotalItems(ti);
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// Open Window Print
	const handleInvoice = (event) => {
		const hideHeader = document.getElementById('hideHeader');
		const contact = document.getElementById('contactInfo');
		const sellerBillingAddress = document.getElementById('sellerBillingAddress');
		const contactDetail = document.getElementById('contactDetail');
		const customerShippingAddress = document.getElementById('customerShippingAddress');
		const aside = document.getElementById('aside');
		const container = document.getElementById('container');
		const mainBox = document.getElementById('orderDetails')
		const gstBox = document.getElementById('orderGstBox');
		const gstInvoice = document.getElementById('gstBoxInvoice');

		aside.style.width = '100%';
		mainBox.style.width = '100%';
		container.style.flexDirection = 'column-reverse';
		contact.style.display = 'flex'
		hideHeader.style.display = 'none';
		if (gstBox !== null) {
			gstBox.style.display = 'none';
		}

		if (sellerBillingAddress !== null) {
			onbeforeprint = sellerBillingAddress.style.display = 'none';
		}
		
		if (order.length !== 0) {
			onbeforeprint = document.title = order?.draftOrder?.order?.name;
		}

		if (gstInvoice !== null) {
			gstInvoice.style.display = 'block';
		}

		if (customerShippingAddress !== null) {
			onbeforeprint = customerShippingAddress.style.width = '35%';
		}

		if (order?.invoiceType) {
			onbeforeprint = document.getElementById('invoiceTypes').style.marginRight = '0px';
		}

		onbeforeprint = contactDetail.style.width = '35%';

		document.title = order?.draftOrder?.order ? order?.draftOrder?.order?.name : order?.draftOrder?.name;
		window.print();
		onafterprint = document.title = 'DEPO24 PORTAL';
		onafterprint = hideHeader.style.display = 'flex';
		onafterprint = aside.style.width = '28%';
		onafterprint = contact.style.display = 'block'
		onafterprint = mainBox.style.width = '';	
		onafterprint = container.style.flexDirection = '';
		if (gstBox !== null) {
			onafterprint = gstBox.style.display = 'block';
		}

		onafterprint = contactDetail.style.width = '';

		if (gstInvoice !== null) {
			onafterprint = gstInvoice.style.display = 'none';
		}

		if (sellerBillingAddress !== null) {
			onafterprint = sellerBillingAddress.style.display = 'none';
		}

		if (customerShippingAddress !== null) {
			onafterprint = customerShippingAddress.style.width = '';
		}

		if (order?.invoiceType) {
			onafterprint = document.getElementById('invoiceTypes').style.marginRight = '40px';
		}

	}

	const handleCancelOrder = (Id) => {
		const cancelButton = document.getElementById('cancelOrder')
		cancelButton.style.backgroundColor = 'black';
		cancelButton.style.color = 'white';
		cancelButton.innerText = 'Cancel Order...'
		axiosInstance
			.post(`/cancels/admin/${Id}`)
			.then((response) => {
				navigate('/Orders')
			}).catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
	}

	const handleReturnOrder = (Id) => {
		const returnButton = document.getElementById('returnOrder')
		returnButton.style.backgroundColor = 'black';
		returnButton.style.color = 'white';
		returnButton.innerText = 'Return Order...'
		axiosInstance
			.post(`/returns/admin/${Id}`)
			.then((response) => {
				navigate('/Orders')
			}).catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
	}

	const hideConfirmPopup = (event,orderId) => {
		if(event.target.innerText === 'Yes'){
			if (text === 'Cancel') {
				handleCancelOrder(orderId)
			} else {
				handleReturnOrder(orderId);
			}
		}
		setConfirmShowPopup(false);
	}

	const handleShowPopup = (event) => {
		if (event.target.innerText === 'Cancel Order') {
			setText('Cancel');
		} else {
			setText('Return');
		}
		setConfirmShowPopup(true)
	}

	useEffect(() => {
		fetchOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const capitalizeState = (text) => {
        const arr = text.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
		return str2;
	}

	const dn = new Date();
	const createdAt = new Date(order ? order.createdAt : null);
	const cancelledAt =
		order && order.cancel
			? order.cancel.cancelledAt
				? new Date(order.cancel.cancelledAt)
				: null
			: null;

	let createdAtDate =
		createdAt.getFullYear() === dn.getFullYear()
			? createdAt.getMonth() === dn.getMonth()
				? createdAt.getDate() === dn.getDate()
					? `Today at ${createdAt.toLocaleTimeString()}`
					: createdAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${createdAt.toLocaleTimeString()}`
					: years[createdAt.getMonth()] +
					  ' ' +
					  createdAt.getDate() +
					  ' at ' +
					  createdAt.toLocaleTimeString()
				: years[createdAt.getMonth()] +
				  ' ' +
				  createdAt.getDate() +
				  ' at ' +
				  createdAt.toLocaleTimeString()
			: years[createdAt.getMonth()] + ' ' + createdAt.getFullYear();

	let cancelledAtDate = cancelledAt
		? cancelledAt.getFullYear() === dn.getFullYear()
			? cancelledAt.getMonth() === dn.getMonth()
				? cancelledAt.getDate() === dn.getDate()
					? `Today at ${cancelledAt.toLocaleTimeString()}`
					: cancelledAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${cancelledAt.toLocaleTimeString()}`
					: years[cancelledAt.getMonth()] +
					  ' ' +
					  cancelledAt.getDate() +
					  ' at ' +
					  cancelledAt.toLocaleTimeString()
				: years[cancelledAt.getMonth()] +
				  ' ' +
				  cancelledAt.getDate() +
				  ' at ' +
				  cancelledAt.toLocaleTimeString()
			: years[cancelledAt.getMonth()] + ' ' + cancelledAt.getFullYear()
		: null;


	const handlePercentage = (discount,lineItems) => {
		let result = 0;
		for (let product of lineItems) {
			if (product.appliedDiscount) {
				result = result + product.appliedDiscount.value * product.quantity
			} else if (product.listingPrice) {
				result = result + product.listingPrice * product.quantity
			}
		}
		const totalAmount = (discount/100 * result)
		return Math.floor(totalAmount*100)/100
	}

	return (
		<Fragment>
			<div 
				id='hideHeader'
				className={[
					'd-flex justify-content-between align-items-center',Classes.arrowBack
				].join(' ')}
			>
				<div
					className={[
						'd-flex flex-column',Classes.OrderDetailArrow
					].join(' ')}
					style={{ gap:'1rem' }}
				>
					<div
						className="d-flex align-items-center"
						style={{ gap:'1rem' }}
					>
						<button
							type="button"
							className="btn btn-light"
							onClick={() => navigate(-1)}
						>
							<BiArrowBack />
						</button>

						<div>
							<div style={{display:'flex'}}>
								<h6 className={Classes.ResponsiveFontSize}>
									{order?.draftOrder?.order ? 
									order?.draftOrder?.order?.name : 
									order?.draftOrder?.name}
								</h6>
								{order !== null && (
									<div style={{display:'flex'}}>
										<div className={Classes.orderStatus}>
											<p className={Classes.orderStatusRound}></p>
											<p>{capitalizeState(order?.orderStatus)}</p>
										</div>

										<div className={Classes.paymentStatus}>
											<p className={Classes.paymentStatusRound}></p>
											<p>{capitalizeState(order?.paymentStatus)}</p>
										</div>
									</div>
								)}
							</div>
							
							<h6 className={Classes.ResponsiveFontSize}>
								{createdAtDate}
							</h6>
						</div>
					</div>
					
				</div>
				<div>
					<button 
						onClick={handleInvoice} 
						className={Classes.printInvoice}
					>
						Print Invoice
					</button>
				</div>

				{order !== null && (
					<div className={Classes.CancelOrderButton}>
						{
							(!order?.cancel && order?.delivered === false) &&
							<Permission service={services?.cancels} permission="delete">
								<button
									type="button"
									id='cancelOrder'
									className={Classes.printInvoice}
									onClick={(e) => handleShowPopup(e)}
								>
									Cancel Order
								</button>
							</Permission>
						}
						{order?.delivered === true && (
							<button
								type="button"
								id='returnOrder'
								className={Classes.printInvoice}
								onClick={(e) => handleShowPopup(e)}
							>
								Return Order
							</button>
						)}
					</div>
				)}
			</div>

			{order && (
				<div>
					<ConfirmPopup 
						showConfirmPopup={showConfirmPopup}
						handleShowPopup={handleShowPopup}
						hideConfirmPopup={hideConfirmPopup}
						orderId={order?._id}
						text={text}
					/>
				</div>
			)}

			{order &&  (
				<div id='TaxRatesPrint'>
					{order?.totalTax && order?.totalTax !== '0' && (
						<div>
							<TaxRatesPopup
								showTaxRate={showTaxRate}
								setTaxRates={setTaxRates}
								handleTaxRates={handleTaxRates}
								hideTaxPopup={hideTaxPopup}
								draftOrder={order}
								type={"ordersOld"}
							/>
						</div>
					)}
				</div>
			)} 

			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			{order !== null && (
				<div 
					className={[Classes.OrderNameInvoice,'OrderInvoicePrint'].join(' ')} 
					id='orderNamePrint' 
				>
					<div>
						<p style={{ textAlign:'center' }}>
							Order Summary
						</p>
					</div>
					<div style={{display:'flex'}}>
						<h6 style={{ marginRight:5 }}>Order Name:-</h6>
						<p>
							{order?.draftOrder?.order ? 
							order?.draftOrder?.order?.name : 
							order?.draftOrder?.name}
						</p>
					</div>
				</div>
			)}

			<div 
				className={Classes.invoiceTypes}
				id='invoiceTypes'
			>
				<div style={{display:'flex'}} id='billBox'>
					<input 
						type="radio" 
						name="choose" 
						id="bill" 
						value="bill"
					/>
					<p style={{marginLeft:5}}>Send on Bill</p>
				</div>

				<div 
					style={{ display:'flex',marginLeft:15 }} 
					id='challanBox'
				>
					<input 
						type="radio" 
						name="choose" 
						id="challan" 
						value="challan" 
					/>
					<p style={{marginLeft:5}}>Send on Challan</p>
				</div>
			</div>

			{order ? (
				<div 
					id='container'
					className={Classes.container}
					style={{ gap:'1.5rem',display:'flex',flexWrap:'wrap' }}
				>
					<div
						className={Classes.containBoxes}
						id='orderDetails'
						style={{ gap:'0rem'}}
					>
						{order?.cancel ? (
							<div className={[Classes.Box, Classes.CancelResponsive].join(' ')}>
								<div
									className="d-flex align-items-center"
									style={{ gap:'0.5rem' }}
								>
									<MdOutlineCancel color="red" size={32} />
									<p style={{ fontWeight:600 }}>Cancelled At: </p>
									<p>{cancelledAtDate}</p>
								</div>

								{
									<table className="table table-borderless table-sm mt-3">
										<tbody>
											<tr>
												<th col="row">Status</th>
												<td>{order?.cancel?.status}</td>
											</tr>
											<tr>
												<th col="row">Reason</th>
												<td>{order?.cancel?.reason}</td>
											</tr>

											{order?.cancel?.description ? (
												<tr>
													<th col="row">Description</th>
													<td>{order?.cancel?.description}</td>
												</tr>
											): null}

											{order?.cancel?.refund ? (
												<tr>
													<th col="row">Refund Amount</th>
													<td>{order?.cancel?.refund?.amount}</td>
												</tr>
											) : null}

											{order?.cancel?.refund ? (
												<tr>
													<th col="row">Refund Status</th>
													<td>{order?.cancel?.refund?.status}</td>
												</tr>
											) : null}
										</tbody>
									</table>
								}
							</div>
						) : null}

						{order?.returns && order?.returns?.length > 0 ? (
							<div className={[Classes.Box,Classes.returnBox].join(' ')}>
								<div
									className="d-flex align-items-center"
									style={{ gap:'0.5rem' }}
								>
									<p style={{ fontWeight: 600 }}>Returns </p>
								</div>
								{
									<Fragment>
										{order?.returns?.map((returnn) => (
											<div
												key={returnn?._id}
												className="d-flex flex-column my-3 border rounded p-3"
												style={{ gap:'0rem' }}
											>
												<p>{returnn?.productTitle}</p>
												<p>{returnn?.variantTitle}</p>

												<table className="table table-borderless table-sm mt-3">
													<tbody>
														<tr>
															<th col="row">SKU</th>
															<td>{returnn?.sku}</td>
														</tr>
														<tr>
															<th col="row">LineItem</th>
															<td>{returnn?.lineItem}</td>
														</tr>
														<tr>
															<th col="row">Status</th>
															<td>{returnn?.status}</td>
														</tr>
														<tr>
															<th col="row">Reason</th>
															<td>{returnn?.reason}</td>
														</tr>
														<tr>
															<th col="row">Type</th>
															<td>{returnn?.type}</td>
														</tr>

														{returnn?.refundAmount ? (
															<tr>
																<th col="row">Refund Amount</th>
																<td>{returnn?.refundAmount}</td>
															</tr>
														) : null}

														{returnn?.refund ? (
															<Fragment>
																<tr>
																	<th col="row">Refund Status</th>
																	<td>{returnn?.refund?.status}</td>
																</tr>
															</Fragment>
														) : null}
													</tbody>
												</table>
											</div>
										))}
									</Fragment>
								}
							</div>
						) : null}


						<div 
							className={Classes.Box}
							style={{ marginBottom:15 }}
						>
							<div className={Classes.ProductHeader}>
								<h6 className="mb-0" style={{width:'54%'}}>
									Product
								</h6>

								<div 
									className={Classes.hideResponsive}
									style={{ width:'11%' }}
								>
									<h6 className="mb-0">
										Discount
									</h6>
									<p className="mb-0" style={{fontSize:14}}>
										(Incl. GST)
									</p>
								</div>
								<h6 
									className={Classes.hideResponsive} 
									style={{ width:'12%' }}
								>	
									Quantity
								</h6>
								<div 
									style={{ width:'12%' }}
									className={Classes.hideResponsive}
								>
									<h6 className={Classes.subtotal}>
										Subtotal
									</h6>
									<p style={{fontSize:14}}>(Excl. GST)</p>
								</div>

								<div 
									style={{width:'10%'}}
									className={Classes.hideResponsive}
								>
									<h6 className={Classes.subtotal}>
										Total
									</h6>
									<p style={{fontSize:14}}>(Incl. GST)</p>
								</div>
							</div>
							{order?.lineItems?.map((item) => (
								<LineItem key={item?.variantId} e={item} />
							))}
						</div>
						<div
							id='paymentBox'
							className={[
								'd-flex flex-column',Classes.Box,Classes.PaymentBox
							].join(' ')}
						>
							<div
								className="d-flex align-items-center mb-3"
								style={{ gap:'0.5rem' }}
							>
								<div className={Classes.paymentStatusStyles}>	
									<p className={Classes.DottedBorderRadius}></p>
								</div>
								
								<h6 className="mb-0">
									{capitalizeState(order.paymentStatus)}
								</h6>
							</div>

							<div 
								className={Classes.totalBoxRow}
								style={{ padding:'5px 0px' }}
							>
								<div style={{ width:'50%',display:'flex' }}>
									<p style={{ width:'70%' }}>
										SubTotal
									</p>
									<p>{totalItems} Items</p>
								</div>
								<p>&#8377;{order?.subTotalPrice}</p>
							</div>

							{order?.appliedDiscount ? (
								<div className={Classes.totalBoxRow}>
									<div className={Classes.appliedDiscount}>
										<p style={{ width:'54%' }}>
											Applied Discount
										</p>

										<div style={{ display:'flex' }}>
											{order?.appliedDiscount?.valueType === 'PERCENTAGE' && (
												<p>({order?.appliedDiscount?.value}%)</p>
											)}
										</div>
									</div>

									<div style={{display:'flex'}}>
										<p>-</p>
										<p>&#8377;</p>
										<p>
											{order?.appliedDiscount?.valueType === 'PERCENTAGE' ? 
											handlePercentage(order?.appliedDiscount?.value,order?.lineItems):
											order?.appliedDiscount?.value}
										</p>
									</div>
								</div>
							) : (
								<>
								{order?.appliedDiscount && (
									<div className={Classes.totalBoxRow}>
										<div className={Classes.appliedDiscount}>
											<p style={{ width:'54%' }}>
												Applied Discount
											</p>

											<div style={{ display:'flex' }}>
												{order?.appliedDiscount?.valueType === 'PERCENTAGE' && (
													<p>({order?.appliedDiscount?.value}%)</p>
												)}
											</div>
										</div>

										<div style={{display:'flex'}}>
											{order?.appliedDiscount?.valueType === 'PERCENTAGE' ? (
												<p>({order?.appliedDiscount?.value}%)</p>
											) : (
												<>
													<p>-</p>
													<p>&#8377;</p>
													<p>{order?.draftOrder?.appliedDiscount?.amountV2?.amount}</p>
												</>
											)}
										</div>
									</div>
								)}
								</>
							)}

							{order?.shippingLine ? (
								<div className={Classes.totalBoxRow}>
									<div
										className="d-flex align-items-center"
										style={{padding:'5px 0px'}}
									>
										<p>Shipping Charges</p>
									</div>

									<div className={Classes.shippingAmount}>
										<p className={Classes.widthColor}>
											+&#8377;{order?.shippingLine?.price}
										</p>

										{order?.shippingLine?.taxAmount && order?.shippingLine?.taxAmount !== 0 && (
											<p className={Classes.widthColor} style={{ marginLeft:5 }}>
												(GST: 18%)
											</p>
										)}
									</div>

								</div>
							) : (
								<div className={Classes.totalBoxRow}>
									<div
										className="d-flex align-items-center"
										style={{ padding:'5px 0px' }}
									>
										<p>Shipping Charges</p>
									</div>
									<p>&#8377;0.00</p>
								</div>
							)}

							{order?.totalTax ? (
								<div className={Classes.totalBoxRow}>
									<div
										className="d-flex align-items-center"
										style={{ padding:'5px 0px',width:'14%' }}
									>
										<p>Tax</p>
									</div>
									<p
										id='orderShowTaxRates'
										className={Classes.showTaxRates}
										onClick={handleTaxRates}
									>
										Show Tax Rates
									</p>
									<p>&#8377;{order?.totalTax}</p>
								</div>
							):(
								<div className={Classes.totalBoxRow}>
									<div
										className="d-flex align-items-center"
										style={{ padding:'5px 0px' }}
									>
										<p>Tax</p>
									</div>
									<p>&#8377;{order?.draftOrder?.totalTax}</p>
								</div>
							)}

							<div className={Classes.totalBoxRow}>
								<div
									className="d-flex align-items-center"
									style={{padding:'5px 0px'}}
								>
									<p style={{ fontWeight:600 }}>
										Total
									</p>
								</div>
								<p style={{ fontWeight:600 }}>
									&#8377;{order?.totalPrice ? Math.ceil(order?.totalPrice): Math.ceil(order?.draftOrder?.totalPrice)}
								</p>
							</div>
						</div>
					</div>

					<div 
						className={Classes.asideContainer} 
						id="aside"
						style={{ gap:'1rem' }}
					>
						{showCustomer?.length !== 0 ? (
							<div
								id='contactInfo'
								className={Classes.Box}
								style={{ marginBottom:15 }}
							>
								<div 
									id='contactDetail'
									style={{ display:'flex',flexDirection:'column' }}
								>
									<div 
										className={Classes.customerName}
										id='orderCustomerNameBox'
									>
										<div className='mb-2' id='customerHeader'>
											<h6 style={{ margin:'auto 0px'}}>
												Customer
											</h6>
										</div>
										<p className={Classes.customerDetail}>
											{showCustomer[0]?.displayName ? 
											showCustomer[0]?.displayName :
											`${showCustomer[0]?.firstName} ${showCustomer[0]?.lastName && showCustomer[0]?.lastName}`}
										</p>
									</div>

									<div className={Classes.contactInformationBox}>
										<p className={Classes.fontWeightSize}>
											CONTACT INFORMATION
										</p>

										<div className={Classes.customerDetail}>
											<p>{showCustomer[0]?.email}</p>
											<p>{showCustomer[0]?.phone}</p>
										</div>
									</div>

									{order?.metafields?.length !== 0 ? (
										<div 
											id='gstBoxInvoice'
											className={Classes.GstInvoiceBox}
											style={{ padding:'10px 15px' }}
										>
											<div>
												<p className={Classes.fontWeightSize}>
													GST Number
												</p>
											</div>
											<div className={Classes.metafieldValue}>
												<p>{order?.metafields[0]?.value}</p>
											</div>
										</div>
									) : null}
								</div>

								{order?.shippingAddress ? (
									<div 
										id='customerShippingAddress'
										style={{ padding:20 }}
										className={Classes.contactInformationBox}
									>
										<p className={Classes.fontWeightSize}>
											SHIPPING ADDRESS
										</p>

										<div style={{ fontSize:'14px', marginTop:10 }}>
											<p>
												{order?.shippingAddress?.firstName}{' '}
												{order?.shippingAddress?.lastName && order?.shippingAddress?.lastName}
											</p>
											<p>{order?.shippingAddress?.address1}</p>
											<p>{order?.shippingAddress?.address2}</p>
											<p>
												{order?.shippingAddress?.zip},{' '}
												{order?.shippingAddress?.city},{' '}
											</p>
											<p>{order?.shippingAddress?.province},</p>
											<p>{order?.shippingAddress?.country}</p>
											<p>{order?.shippingAddress?.phone}</p>
										</div>
									</div>
								) : null}

								{order?.customerBillingAddress ? (
									<div
										style={{ padding:20 }}
										className={Classes.contactInformationBox}
									>
										<p className={Classes.fontWeightSize}>
											BILLING ADDRESS
										</p>

										<div style={{ fontSize:14, marginTop:15 }}>
											<p>
												{order?.customerBillingAddress?.firstName}{" "}
												{order?.customerBillingAddress?.lastName && order?.customerBillingAddress?.lastName}
											</p>
											<p>{order?.customerBillingAddress?.address1}</p>

											{order?.customerBillingAddress?.address2 !== null && (
												<p>{order?.customerBillingAddress?.address2}</p>
											)}

											<p>
												{order?.customerBillingAddress?.zip},{' '}
												{order?.customerBillingAddress?.city},{' '}
											</p>
											<p>{order?.customerBillingAddress?.province},</p>
											<p>{order?.customerBillingAddress?.country}</p>
											<p>{order?.customerBillingAddress?.phone}</p>
										</div>
									</div>
								) : null}

								{order?.orderType === 'B2C' ? (
									<div
										style={{ padding:20 }}
										id='sellerBillingAddress'
									>
										{order?.sellerBillingAddress && (
											<>
												<div className={Classes.sellerBillingAddress}>
													<p className={Classes.fontWeightSize}>
														SELLER BILLING ADDRESS
													</p>
												</div>

												<div style={{ fontSize:14, marginTop:10}}>
													<p>
														{order?.sellerBillingAddress?.firstName}{" "}
														{order?.sellerBillingAddress?.lastName && order?.sellerBillingAddress?.lastName}
													</p>
													<p>{order?.sellerBillingAddress?.address1}</p>
													<p>{order?.sellerBillingAddress?.address2}</p>

													<p>
														{order?.sellerBillingAddress?.zip},{' '}
														{order?.sellerBillingAddress?.city},{' '}
													</p>
													<p>{order?.sellerBillingAddress?.province},</p>
													<p>{order?.sellerBillingAddress?.country}</p>
													<p>{order?.sellerBillingAddress?.phone}</p>
												</div>
											</>
										)}
									</div>
								) : null}
							</div>
						) : null}

						{order?.metafields?.length !== 0 ? (
							<div 
								className={[Classes.Box,Classes.GstBox].join(' ')}
								id='orderGstBox'
							>
								<div>
									<p className={Classes.GstNumber}>
										GST NUMBER
									</p>
								</div>
								<div className={Classes.secondMetafieldValue}>
									<p>{order?.metafields[0]?.value}</p>
								</div>
							</div>
						) : null}

						{order?.paymentRemark !== undefined ? (
							<div className={[Classes.Box,Classes.GstBox].join(' ')}>
								<p className={Classes.GstNumber}>
									PAYMENT REMARK
								</p>
								<p className={Classes.secondMetafieldValue}>
									{order?.paymentRemark}
								</p>
							</div>
						) : null}

						
						{order?.deliveryRemark !== undefined ? (
							<div className={[Classes.Box,Classes.GstBox].join(' ')}>
								<p className={Classes.GstNumber}>
									DELIVERY REMARK
								</p>
								<p className={Classes.secondMetafieldValue}>
									{order?.deliveryRemark}
								</p>
							</div>
						): null}

						{order?.length !== 0 && (
							<div className={Classes.Box} style={{padding:10}} id='affiliateDetails'>
								<div style={{display:'flex'}}>
									<h6>Affiliate:</h6>
									<p style={{fontSize:14,marginLeft:5}}>
										{order?.affiliateUser?.name}
									</p>
								</div>

								<div style={{display:'flex'}}>
									<h6>Sales User:</h6>
									<p style={{fontSize:14,marginLeft:5}}>
										{order?.salesUser?.name}
									</p>
								</div>

								<div style={{display:'flex'}}>
									<h6>Pre Sales User:</h6>
									<p style={{fontSize:14,marginLeft:5}}>
										{order?.preSalesUser?.name}
									</p>
								</div>
							</div>
						)}
			
						
						{order?.tags?.length !== 0 ? (
							<div className={[Classes.Box,Classes.GstBox].join(' ')}>
								<div>
									<p className={Classes.GstNumber}>
										TAGS
									</p>
								</div>
								<div style={{ display:'flex', flexWrap:'wrap' }}>
									{order?.tags?.map((item,index) => (
										<p
											className={[Classes.Tags,"rounded py-1 px-2"].join(' ')}
											key={index}
										>
											{item}
										</p>
									))}
								</div>
							</div>
						): null}
					</div>
				</div>
			) : null}

			{order &&  (
				<div 
					id='TaxRatesPrint' 
					className={Classes.TaxRatesPrint}
					style={{marginTop:20}}
				>
					{order?.totalTax &&  order?.totalTax !== '0' && (
						<div>
							<TaxRatesPrint
								draftOrder={order}
								taxRates={taxRates}
							/>
						</div>
					)}
				</div>
			)} 
			
			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default OrderId;
