import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utility/axios-instance";
import Classes from './css/dashboard.module.css'
import Spinner from "../../../components/spinner";
import PieChartComponent from "../../../components/pieChartComponent";
import moment from 'moment';
import { Badge, Box, Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SourceIcon from '@mui/icons-material/Source';
import { useNavigate } from "react-router-dom";
import NewPagination from '../../../components/newPagination';
import { CustomToolbar } from '../../../components/products/productComponents';
const salesBaseUrl = '/analytics/salesAnalytics';

const FulfillmentAnalytics = (props) => {

    const {locCode, setSnackBar, startDate, endDate} = props;
    const [loading, setLoading] = useState(false);
    const [totalFulfilledOrders, setTotalFulfilledOrders] = useState(0);
    const [totalFulfilledOrderNames, setTotalFulfilledOrderNames] = useState([]);

    const [totalPendingOrders, setTotalPendingOrders] = useState(0);
    const [totalPendingOrderNames, setTotalPendingOrderNames] = useState([]);

    const [totalPendingOrdersDueToStock, setTotalPendingOrdersDueToStock] = useState(0);
    const [totalPendingOrderNamesDueToStock, setTotalPendingOrderNamesDueToStock] = useState([]);

    const [totalSkusWithQty, setTotalSkusWithQty] = useState([]);
    const orderProcessingLabels = ['Fulfilled Orders','Pending Orders','Pending Due To Stock Out']
    const [skuStatusLabel, setskuStatusLabel] = useState(orderProcessingLabels[0] || 'Fulfilled Orders');

    const [fulfilledOrderIn4hr, setFulfilledOrderIn4hr] = useState([]);
    const [fulfilledOrderIn4hrTo8hr, setFulfilledOrderIn4hrTo8hr] = useState([]);
    const [fulfilledOrderIn8hrTo24hr, setFulfilledOrderIn8hrTo24hr] = useState([]);
    const [fulfilledOrderIn24hrTo48hr, setFulfilledOrderIn24hrTo48hr] = useState([]);
    const [fulfilledOrderMore48hr, setFulfilledOrderInMore48hr] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [orderDetailsBasedOnStatus, setOrderDetailsBasedOnStatus] = useState([]);
    const tableRef = useRef()
    const navigate = useNavigate();
    const [searchFor, setSearchFor] = useState("");

    const fetchAllFulfilledOrders = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalFulfilledOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalFulfilledOrders(res?.totalFulfilledOrdersCount);
                setTotalFulfilledOrderNames(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllPendingOrder = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalPendingOrders(res?.totalPendingOrdersCount);
                setTotalPendingOrderNames(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllPendingOrderDueToStockOut = async () => {
        setLoading(true);
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingOrdersDueToStock?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalPendingOrdersDueToStock(res?.totalPendingOrderDueToStockCount);
                setTotalPendingOrderNamesDueToStock(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
            setLoading(false);
    }

    const fetchAllFulfilledSkus = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalFulfilledLineItems?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalFulfilledSku)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchAllPendingSkusDueToStock = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingSkusDueToStockOut?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalPendingSkus)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchAllOrdersDetailBasedOnStatus = async (type) => {
        setLoading(true);
        let queryPrams = `startDate=${startDate}&endDate=${endDate}&status=${skuStatusLabel}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/fetchOrdersDetailBasedOnStatus?${queryPrams}&page=${type}&searchFor=${searchFor}`)
            .then((response) => {
                setOrderDetailsBasedOnStatus(response?.data?.data)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
            setLoading(false);
    }

    const fetchFulfilledOrderTime = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }

        await axiosInstance
            .get(`${salesBaseUrl}/avgFulfillmentTime?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setFulfilledOrderIn4hr(res?.fulfilledOrdersUnder4hr)
                setFulfilledOrderIn4hrTo8hr(res?.fulfilledOrdersUnder4to8hr)
                setFulfilledOrderIn8hrTo24hr(res?.fulfilledOrdersUnder8to24hr)
                setFulfilledOrderIn24hrTo48hr(res?.fulfilledOrdersUnder24to48hr)
                setFulfilledOrderInMore48hr(res?.fulfilledOrdersMore48hr)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const orderDetailsColumn = [
        {
            field: 'orderName',
            headerName: 'OrderName',
            flex:0.5,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex:1,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            valueFormatter : (params) => {
                if(params?.row?.skus?.length !== 0){
                    let data = params?.row?.skus?.map(item => item?.sku)
                    return data?.join(',')
                }
                else return ''
            },
            renderCell : (params) => (
                params?.row?.skus?.length !== 0 && <div style={{display : 'flex',width : '100%', flexWrap : 'wrap'}}>{
                    params?.row?.skus?.map((item, index) => 
                    <Badge key={index} max={99} overlap="rectangular" badgeContent={`${item?.inventoryQty}`} color={item?.inventoryQty && item?.inventoryQty > 0 ? "success" : "error"}>
                        <Chip key={item?.sku} label={item?.sku} style={{margin : '4px 10px',padding : '3px 6px'}}/>
                    </Badge> )
                }</div>
            )
        },
        {
            field: 'createdAt',
            headerName: 'CreatedAt',
            flex:.7,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            renderCell : (params) => moment(params?.row?.createdAt).format('MMM DD, YYYY h:mm:ss A')
        }
    ]

    const fetchNumberOfDays = (startDate, endDate) => {
        const numOfDays = moment(endDate).diff(startDate,'days')
        setNumberOfDays(numOfDays);
    }

    const services = async () => {
        if(startDate && endDate){
            fetchNumberOfDays(startDate, endDate);
            setLoading(true)
            await fetchAllFulfilledOrders();
            await fetchAllPendingOrder();
            await fetchAllPendingOrderDueToStockOut();
            await fetchAllFulfilledSkus();
            await fetchFulfilledOrderTime();
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchAllOrdersDetailBasedOnStatus();
    }, [startDate, endDate, locCode, searchFor])

    useEffect(() => {
        services();
    }, [startDate, endDate, locCode]);

    const fetchSkuBasedOnLabel = async () => {
        if(skuStatusLabel){
            await fetchAllOrdersDetailBasedOnStatus()
        }
        if(skuStatusLabel === orderProcessingLabels[0]){
            setLoading(true)
            await fetchAllFulfilledSkus()
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
            setLoading(false)
        }
        else if(skuStatusLabel === orderProcessingLabels[2]){
            setLoading(true)
            await fetchAllPendingSkusDueToStock();
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
            setLoading(false)
        }
        setReRender(!reRender)
    }

    useEffect(() => {
        startDate && endDate && fetchSkuBasedOnLabel();
    },[skuStatusLabel])

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    return (
        <>
            <div className={Classes.Container}>
                {/* Doughnut Chart */}
                {<div className={Classes.GraphContainer}>
                    <div className={Classes.Graph}>
                        <PieChartComponent 
                            label='Order Process Detail'
                            labels={orderProcessingLabels}
                            data={[totalFulfilledOrders, totalPendingOrders, totalPendingOrdersDueToStock]}
                            backgroundColor={ ['rgba(255, 98, 0,1)','rgba(42, 102, 100,1)','rgba(29, 171, 166, 1)']}
                            hoverBackgroundColor= {['rgba(255, 98, 0,.7)','rgba(42, 102, 100,.7)','rgba(29, 171, 166,.7)']}
                            setskuStatusLabel={setskuStatusLabel}
                            clickEvent={true}
                            tableRef={tableRef}
                        />
                    </div>

                    {totalSkusWithQty.length ? <div className={Classes.Graph}>
                        <PieChartComponent 
                            label={skuStatusLabel === orderProcessingLabels[1] ? 'Pending Skus' : (skuStatusLabel === orderProcessingLabels[2] ? 'Pending Skus Due To Stock Out'  : 'Fulfilled Skus')}
                            labels={totalSkusWithQty.map(item => item.sku)}
                            data={totalSkusWithQty.map(item => item.skuQty)}
                            backgroundColor={totalSkusWithQty.map((item , index )=> `rgba(${20}, ${index*5+24}, ${index*6+30}, 1)`)}
                            hoverBackgroundColor={totalSkusWithQty.map((item , index )=> `rgba(${20}, ${index*5+23}, ${index*6+30}, .7)`)}
                            setskuStatusLabel={setskuStatusLabel}
                        />
                    </div> : null}

                    <div className={Classes.Graph}>
                        <PieChartComponent 
                            label="Total Orders Count With Fulfillment Time"
                            labels={["Fulfilled Orders in 4hr", "Fulfilled Orders in 4hr to 8hr",
                                "Fulfilled Orders in 8hr to 24hr", "Fulfilled Orders in 24hr to 48", 
                                "Fulfilled Orders in 48 hr or more"
                            ]}
                            data={[fulfilledOrderIn4hr.length, fulfilledOrderIn4hrTo8hr.length,
                                fulfilledOrderIn8hrTo24hr.length, fulfilledOrderIn24hrTo48hr.length,
                                fulfilledOrderMore48hr.length
                            ]}
                            backgroundColor={['rgba(14, 76, 176,1)', 'rgba(98, 119, 153,1)', 'rgba(46, 65, 94,1)', 'rgba(7, 36, 82,1)', 'rgba(10, 24, 46,1)']}
                            hoverBackgroundColor={['rgba(14, 76, 176,.6)', 'rgba(98, 119, 153,.6)', 'rgba(46, 65, 94,.6)', 'rgba(7, 36, 82,.6)', 'rgba(10, 24, 46,.6)']}
                        />
                    </div>
                </div>}

                {/* Table orderName with Skus */}
                {skuStatusLabel && <div ref={tableRef}>
                    <div>   
                        <div style={{marginTop : '20px'}}>
                        <h3 style={{textAlign : 'center', color : 'grey', marginTop: '15px'}}><SourceIcon color='grey' fontSize='large'/>{`${skuStatusLabel} Order Wise`}</h3>
                            <div>
                                <Box 
                                    sx={{ 
                                        height: 400, width: '100%' ,marginTop : '15px',
                                        '& .super-app-theme--header': {
                                            backgroundColor: '#243750',
                                            color: '#ffffff',
                                            cursor: "default"
                                        }}}
                                >
                                    <DataGrid 
                                        disableColumnFilter
                                        // disableColumnSelector
                                        // disableDensitySelector
                                        rows={orderDetailsBasedOnStatus?.ordersBasedOnStatus ?? []}
                                        getRowId={(row) => row['orderName']}
                                        columns={orderDetailsColumn}
                                        getRowHeight={() => "auto"}
                                        sx={{
                                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                                outline: "none !important",
                                            },
                                            "& .MuiTablePagination-displayedRows": { display: 'none' },
                                            "& .MuiTablePagination-actions": { display: 'none' },
                                            ".PrivateSwitchBase-input": {
                                                height: "23px",
                                                margin: "10px 13px",
                                                // width: "20px"
                                            },
                                            cursor: "pointer",
                                            '& .MuiDataGrid-cell': {
                                                py: '10px',
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                display: 'none'
                                            }
                                        }}
                                        // onRowClick={(params) => navigate(`/ordersNew/${params?.id}`)}
                                        slots={{ toolbar: CustomToolbar }}
                                        slotProps={{
                                            toolbar: {
                                                searchFor: searchFor,
                                                handleSearch: handleSearch,
                                                showQuickFilter: true,
                                                csvOptions: { allColumns: true, fileName : 'Order details based on status' }
                                            }
                                        }}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                    {/* <h5></h5>
                    <Table
                        columns={columns}
                        dataPosition={"skusWithOrderName"}
                        endpoint={`/analytics/salesAnalytics/totalSkusWithOrderNameBasedOnStatus?startDate=${startDate}&endDate=${endDate}&status=${skuStatusLabel}${locCode && (`&locationCode=${locCode}`)}`}
                        rowId={'orderName'}
                        checkBoxSelection={false}
                        version="version-1"
                        reRender={reRender}
                        navigateOnRowClickEndpoint={'/ordersNew'}
                        setData={setSkusWithOrderName}
                        data={skusWithOrderName}
                    /> */}
                </div>}
            </div>

            {skuStatusLabel && orderDetailsBasedOnStatus?.ordersBasedOnStatus?.length > 0 && (
                <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                    <NewPagination totalPage={orderDetailsBasedOnStatus} fetchData={fetchAllOrdersDetailBasedOnStatus} />
                </div>
            )}
            {loading && <Spinner />}
        </>
    )
}

export default FulfillmentAnalytics;