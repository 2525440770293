import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import '../src/utility/prototypes';

import store from './store';

import ReactGA from 'react-ga';

let gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  // Configuring Google Analytics
if (gaMeasurementId && window?.location?.pathname) {
  ReactGA.initialize(gaMeasurementId);
  ReactGA.pageview(window?.location?.pathname + window?.location?.search);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
