import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	users: [],
};

const managerSlice = createSlice({
	name: 'myTasks',
	initialState: initialState,
	reducers: {
		setManagerDashboardData : (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setManagerDashboardData, reset } = managerSlice.actions;

export default managerSlice.reducer;