import FilterListIcon from '@mui/icons-material/FilterList';
import {Tooltip, Button} from '@mui/material';

export default function FiltersButton ({setFiltersPanel, filtersPanel, multiFilters, applyFilter}) {
    return (
        <Tooltip title="Add" placement="top-end" onClick={() => setFiltersPanel(!filtersPanel)}>
            <Button>
                {applyFilter && (
                    <span 
                        style={{ 
                            display:'flex', position:'absolute', height:20, zIndex:1,
                            backgroundColor: "rgb(25, 118, 210)", color: "rgb(255, 255, 255)",
                            top: 8, right: 0, transform: "scale(1) translate(50%, -50%)",
                            transformOrigin: "100% 0%", padding: "0px 6px",
                            borderRadius: 10, transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                            fontSize: '12px', marginRight: 75
                        }}
                    >{multiFilters?.length}</span>
                )}
                <FilterListIcon style={{ marginRight:'8px'}}/>FILTERS
            </Button>
        </Tooltip>
    )
}