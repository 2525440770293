import React, { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axiosInstance from "../utility/axios-instance";
import Spinner from "./spinner";

const UploadDocumentDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const { showPopup, hideClosePopup, url, setReRender } =
    props;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const file = event?.target?.file?.files[0];
    const formData = new FormData();
    formData.append("documentAttached", file);

    await axiosInstance
      .post(`${url}`, formData,  {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
      .then((res) => {
        setReRender((prev) => !prev);
        hideClosePopup();
        setLoading(false);
        const successMessage = `Document Successfully Uploaded`;
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        hideClosePopup();
        setLoading(false);
        setReRender((prev) => !prev);
      });
  };

  return (
    <Fragment>
      <Dialog
        open={showPopup}
        onChange={(e, newVal) => {
          if (newVal === false) {
            hideClosePopup();
          }
        }}
      >
        <DialogContent className="sm:max-w-[455px]">
            <DialogTitle>Upload Document</DialogTitle>
          <form method="post" onSubmit={handleSubmit}>
            <div className="input-group" style={{ padding: 20 }}>
              <input
                required
                className="form-control"
                type="file"
                id="formFile"
                name="file"
              ></input>
            </div>
            <DialogActions className="flex w-full">
            <Button onClick={hideClosePopup}>CLOSE</Button>

              <Button type="submit" disabled={loading}>
                Upload
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
        {loading && (<Spinner />)}
      </Dialog>
    </Fragment>
  );
};

export default UploadDocumentDialog;
