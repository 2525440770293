
import { Fragment, useEffect, useState } from 'react';
import Classes from './css/addProduct.module.css';
import { TextField, Autocomplete } from '@mui/material';
import axiosInstance from '../../../../utility/axios-instance';

const Variants = (props) => {
    const { setPayloadToUpload, payloadToUpload }= props;
	const [countries, setCountries] = useState([]);

    useEffect(() => {
        axiosInstance
            .get('/others/countryOfOrigin')
            .then((res) => {
                const country = res.data.data?.map((countryName) => countryName?.country);
                const uniqueCountry = country?.filter((item, pos) => { return country.indexOf(item) ===  pos })
                uniqueCountry.push('India');
                setCountries(uniqueCountry);
            })
    }, []);

	return (
		<Fragment>
            <div className={[Classes.Pricing,Classes.Boxes].join(' ')}>
                <h6>Variants</h6>

                <div style={{padding:'1rem 0px'}}>
                    <div className={Classes.variantField} style={{ marginTop:15 }}>
                        <div style={{width:'49%'}}>
                            <TextField
                                type="text"
                                name='option1Name'
                                id="option1Name"
                                size='small'
                                required={true}
                                autoComplete='off'
                                key={ payloadToUpload?.variable1Type || "" }
                                defaultValue={ payloadToUpload?.variable1Type || "" }
                                sx={{width:'100%'}}
                                placeholder='Size'
                                label='Option Name 1'
                                onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {...prevPayloadToUpload, variable1Type: e?.target?.value}
                                }) }
                            />
                        </div>

                        <div style={{width:'49%'}}>
                            <TextField
                                type="text"
                                name='option1Value'
                                id="option1Value"
                                required={true}
                                autoComplete='off'
                                size='small'
                                key={ payloadToUpload?.variable1Value || "" }
                                defaultValue={ payloadToUpload?.variable1Value || "" }
                                sx={{width:'100%'}}
                                placeholder='Medium'
                                label='Option Value 1'
                                onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {...prevPayloadToUpload, variable1Value: e?.target?.value}
                                }) }
                            />
                        </div>
                    </div>

                    <div className={Classes.variantField} style={{ marginTop:25 }}>
                        <div style={{width:'49%'}}>
                            <TextField
                                type="text"
                                name='option2Name'
                                id="option2Name"
                                autoComplete='off'
                                size='small'
                                key={ payloadToUpload?.variable2Type || "" }
                                defaultValue={ payloadToUpload?.variable2Type || "" }
                                className={[Classes.searchField, Classes.InputField].join(' ')}
                                placeholder='Color'
                                label='Option Name 2'
                                onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {...prevPayloadToUpload, variable2Type: e?.target?.value}
                                }) }
                            />
                        </div>

                        <div style={{width:'49%'}}>
                            <TextField
                                type="text"
                                name="option2Value"
                                id="option2Value"
                                size='small'
                                key={ payloadToUpload?.variable2Value || "" }
                                defaultValue={ payloadToUpload?.variable2Value || "" }
                                autoComplete='off'
                                className={[Classes.searchField, Classes.InputField].join(' ')}
                                placeholder='Black'
                                label='Option Value 2'
                                onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {...prevPayloadToUpload, variable2Value: e?.target?.value}
                                }) }
                            />
                        </div>
                    </div>
                    
                    <div className={Classes.variantField} style={{ marginTop:25 }}>
                        <div style={{ width:'49%' }}>
                            <TextField
                                type="text"
                                name="option3Name"
                                id="option3Name"
                                autoComplete='off'
                                size='small'
                                key={ payloadToUpload?.variable3Type || "" }
                                defaultValue={ payloadToUpload?.variable3Type || "" }
                                className={[Classes.searchField, Classes.InputField].join(' ')}
                                label='Option Name 3'
                                onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {...prevPayloadToUpload, variable3Type: e?.target?.value}
                                }) }
                            />
                        </div>

                        <div style={{width:'49%'}}>
                            <TextField
                                type="text"
                                id="option3Value"
                                name="option3Value"
                                size='small'
                                key={ payloadToUpload?.variable3Value || "" }
                                defaultValue={ payloadToUpload?.variable3Value || "" }
                                autoComplete='off'
                                className={[
                                    Classes.searchField, 
                                    Classes.InputField
                                ].join(' ')}
                                label='Option Value 3'
                                onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {...prevPayloadToUpload, variable3Value: e?.target?.value}
                                }) }
                            />
                        </div>
                    </div>

                    {/* {productDetails?.length !== 0 && ( */}
                        <div style={{ display:'flex', justifyContent:'space-between', flexWrap:'wrap' }}>
                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    type="text"
                                    id="size"
                                    name='size'
                                    size='small'
                                    key={ payloadToUpload?.size || "" }
                                    defaultValue={ payloadToUpload?.size || "" }
                                    autoComplete='off'
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    placeholder='Size'
                                    label='Size'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, size: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='finish'
                                    type="text"
                                    id="finish"
                                    size='small'
                                    key={ payloadToUpload?.finish || "" }
                                    defaultValue={ payloadToUpload?.finish || "" }
                                    autoComplete='off'
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    placeholder='Finish'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, finish: e?.target?.value}
                                    }) }
                                    label='Finish'
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>                                 
                                <TextField
                                    type="text"
                                    name="material"
                                    size='small'
                                    key={ payloadToUpload?.materialType || "" }
                                    defaultValue={ payloadToUpload?.materialType || "" }
                                    id='materialType'
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, materialType: e?.target?.value}
                                    }) }
                                    label='Material'
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    type="text"
                                    name="color"
                                    size='small'
                                    key={ payloadToUpload?.color || "" }
                                    defaultValue={ payloadToUpload?.color || "" }
                                    id='color'
                                    autoComplete='off'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, color: e?.target?.value}
                                    }) }
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    label='Color'
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='includedComponents'
                                    type="text"
                                    autoComplete='off'
                                    size='small'
                                    key={ payloadToUpload?.includedComponents || "" }
                                    defaultValue={ payloadToUpload?.includedComponents || "" }
                                    id="includedComponents"
                                    className={[Classes.searchField,Classes.InputField].join(' ')}
                                    label='Included Components'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, includedComponents: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='loadCapacity'
                                    type="text"
                                    id="loadCapacity"
                                    size='small'
                                    key={ payloadToUpload?.loadCapacity || "" }
                                    defaultValue={ payloadToUpload?.loadCapacity || "" }
                                    autoComplete='off'
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    label='Load Capacity'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, loadCapacity: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='weight'
                                    type="number"
                                    id="weight"
                                    step="0.01"
                                    size='small'
                                    onKeyDown={e => ( e.keyCode === 69) && e.preventDefault()}
                                    key={ payloadToUpload?.weight || "" }
                                    defaultValue={ payloadToUpload?.weight || "" }
                                    autoComplete='off'
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    label='Weight (kg)'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, weight: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='warranty'
                                    type="text"
                                    id="warranty"
                                    step="0.01"
                                    size='small'
                                    key={ payloadToUpload?.warranty || "" }
                                    defaultValue={ payloadToUpload?.warranty || "" }
                                    autoComplete='off'
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    label='Warranty (Year/s)'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, warranty: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='marketedBy'
                                    type="text"
                                    size='small'
                                    key={ payloadToUpload?.marketedBy || "" }
                                    defaultValue={ payloadToUpload?.marketedBy || "" }
                                    autoComplete='off'
                                    id="marketedBy"
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    label='Marketed By'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, marketedBy: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <TextField
                                    name='customerCare'
                                    type="text"
                                    size='small'
                                    key={ payloadToUpload?.customerCareAddress || "" }
                                    defaultValue={ payloadToUpload?.customerCareAddress || "" }
                                    autoComplete='off'
                                    id="customerCare"
                                    className={[Classes.searchField, Classes.InputField].join(' ')}
                                    label='Customer Care'
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, customerCareAddress: e?.target?.value}
                                    }) }
                                />
                            </div>

                            <div style={{width:'49%', marginTop:25}}>
                                <Autocomplete
                                    name="country" 
                                    id="country"
                                    key={ payloadToUpload?.countryOfOrigin || "" }
                                    defaultValue={ payloadToUpload?.countryOfOrigin || "India" }
                                    size='small'
                                    options={countries}
                                    disablePortal
                                    ListboxProps={{sx: { fontSize: 14 }}}
                                    sx={{  width: '100%' }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionSelected ={(option, value) => option === value}
                                    renderInput={(params) => <TextField {...params} label="Country" required />}
                                    onBlur={ (e)=>setPayloadToUpload((prevPayloadToUpload)=>{
                                        return {...prevPayloadToUpload, countryOfOrigin: e?.target?.value}
                                    }) }
                                />
                            </div>  
                        </div>
                    {/* )} */}
                </div>
            </div>
		</Fragment>
	);
};


export default Variants;
