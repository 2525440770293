import React, { Fragment, useEffect, useState } from "react";
import Classes from "../pages/home/createOrders/css/Modal.module.css";
import axiosInstance from "../utility/axios-instance";
import Spinner from '../components/spinner';
import SnackBar from '../components/SnackBar';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

const DialogWithInputs = (props) => {
    const {
        dialogWithInputsOpen,
        handleDialogWithInputsClose,
        setReRender,
        children,
        // fileCountLimit = 2,
        dialogFormUploadData,
        setDialogFormUploadData,
        documentFieldName,
        endpoint,
        documentName,
        dialogTitle,
    } = props;

    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [docUrls, setDocUrls] = useState([]);
    const docFilesRef = React.useRef(null);

    useEffect(() => {
        setDocUrls(dialogFormUploadData?.docUrls?.map((docUrl) => {
            return {
                url: docUrl,
                name: decodeURIComponent(docUrl?.split('/')?.pop()?.split('?')?.shift()),
                type: "Uploaded",
            }
        }) || [])
    }, [dialogFormUploadData])

    const handleFileInputChange = () => {
        const docFiles = docFilesRef?.current?.files;
        // if (docUrls?.length + docFiles?.length > fileCountLimit) {
        //     docFilesRef.current.files = null;
        //     alert(`Only ${fileCountLimit} ${documentName} can be uploaded!`);
        //     return;
        // }
        if (docFiles?.length > 0) {
            const urls = [];
            for (let i = 0; i < docFiles?.length; i++) {
                urls.push({
                    url: URL.createObjectURL(docFiles[i]),
                    name: docFiles[i]?.name,
                    type: "To Upload",
                })
            }
            setDocUrls((prevDocUrls) => [...prevDocUrls, ...urls])
        }
    }

    const handleDocumentUpload = (event) => {
        event.preventDefault();
        setLoading(true);

        var formData = new FormData();

        for (let i = 0; i < docFilesRef?.current?.files?.length; i++) {
            formData.append(documentFieldName, docFilesRef?.current?.files[i]);
        }

        formData.append("data", JSON.stringify(dialogFormUploadData));
    
        axiosInstance
            .post(
                `${endpoint}`,
                formData
            )
            .then((res) => {
                const successMessage = res?.data?.message || `${documentName} uploaded successfully!`
                setDialogFormUploadData((prevData) => {
                    // nullify all the keys of prevData
                    for (let key in prevData) {
                        prevData[key] = null;
                    }
                    return { ...prevData, docUrls: res?.data?.docUrls }
                });
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleDialogWithInputsClose();
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message || `Could not update ${documentName}!`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
                setReRender((prev) => !prev);
            });
    }

    const handleDocDelete = (docUrlToBeDeleted) => {
        setDocUrls((prevInvoiceUrls) => {
            return prevInvoiceUrls.filter((invoiceUrl, index) => invoiceUrl?.url !== docUrlToBeDeleted?.url)
        })
        const docFilesSelected = docFilesRef?.current?.files;
        if (docFilesSelected?.length > 0) {
            const files = [];
            for (let i = 0; i < docFilesSelected?.length; i++) {
                if (docFilesSelected[i]?.name === docUrlToBeDeleted?.name) continue;
                files.push(docFilesSelected[i])
            }
            docFilesRef.current.files = files;
        }
    }

    return (
      <Fragment>
        {dialogWithInputsOpen && (
          <div
            className={Classes.confirmModal}
            style={{ top: "24%", boxShadow: "0 0px 9px 0" }}
          >
            <div style={{ padding: "20px 10px 0px 10px" }}>
              <h4 style={{ fontSize: "1.2rem" }}>{dialogTitle}</h4>
            </div>
            <form method="post" onSubmit={handleDocumentUpload}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "10px",
                }}
              >
                {children}
                <input
                  required
                  type="file"
                  id="docs"
                  name="docs"
                  // multiple={true}
                  // disabled={docUrls?.length >= fileCountLimit}
                  className="form-control"
                  aria-label="Upload Documents"
                  ref={docFilesRef}
                  style={{ marginTop: "10px" }}
                  onChange={handleFileInputChange}
                ></input>
              </div>

              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                <List>
                  {docUrls?.length > 0 &&
                    docUrls?.map((docUrl, index) => {
                      return (
                        <ListItem
                          // secondaryAction={
                          //   <IconButton
                          //     edge="end"
                          //     aria-label="delete"
                          //     onClick={() => handleDocDelete(docUrl)}
                          //   >
                          //     <DeleteIcon />
                          //   </IconButton>
                          // }
                        >
                          <ListItemButton component="a" href={docUrl?.url}>
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={docUrl?.name || `Invoice ${index + 1}`}
                              secondary={docUrl?.type || null}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </List>
              </div>

              <div
                style={{
                  bottom: "18%",
                  padding: "0px 9px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  type="button"
                  className={Classes.UpdateSalesUserOfCustomer}
                  onClick={(event)=>{
                    event.preventDefault();
                    setDialogFormUploadData((prevData) => {
                      // nullify all the keys of prevData
                      for (let key in prevData) {
                        prevData[key] = null;
                      }
                      return prevData;
                    });
                    handleDialogWithInputsClose();
                  }}
                >
                  CANCEL
                </button>

                <button
                  id="addCustomer"
                  type="submit"
                  className={Classes.UpdateSalesUserOfCustomer}
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        )}

        {loading ? <Spinner /> : null}

        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      </Fragment>
    );
}


export default DialogWithInputs;