import React, { useState, useRef, useEffect } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Modal } from "@mui/material";
import styles from "./store.module.css";
import axiosInstance from "../../../../utility/axios-instance";
import { useSelector } from "react-redux";
SwiperCore.use([Navigation, Pagination, Autoplay]);
const StorePreview = ({ previewState, handlePreviewState, image }) => {
  const swiper = useRef(null);
  const stores = useSelector((state) => state.storeLocation.stores);
  const [storeItems, setStoreItems] = useState([]);
  const [activeLocation, setActiveLocation] = useState(null);
  const [mapAddress, setMapAddress] = useState("");
  const [address, setAddress] = useState("");

  const [device, setDevice] = useState("Default");
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      image.title = "";
      image.titleColor = "";
      image.image = "";
      handlePreviewState(false);
    }
  };
  const getStoreImages = async () => {
    axiosInstance.get("/stores/images").then((res) => {
      setStoreItems(res.data.data);
    });
  };
  const devices = [
    { name: "iPhone X", width: 375, height: "auto", slidesPerView: 2 },
    { name: "iPad", width: 768, height: "auto", slidesPerView: 3 },
    { name: "Default", width: "100%", height: "auto", slidesPerView: 4 },
  ];

  const handleChange = (event) => {
    setDevice(event.target.value);
  };

  const selectedDevice = devices.find((d) => d.name === device);

  const fetchStoreAddress = async (activeLocation) => {
    const activeStore = stores.find((store) => store.city === activeLocation);
    axiosInstance
      .get(`/adminRoutes/stores/locations/${activeStore.id}`)
      .then((res) => {
        let formattedAddress = "";
        let address = "";
        if (res.data.data.landmark) {
          formattedAddress = `Depo24, ${res.data.data.streetAddress.trim()},${res.data.data.landmark.trim()},${res.data.data.city.trim()},${res.data.data.state.trim()},${res.data.data.country.trim()},${
            res.data.data.postalCode
          }`;
          address = `${res.data.data.streetAddress}, ${res.data.data.landmark}, ${res.data.data.city}, ${res.data.data.state}, ${res.data.data.country}-${res.data.data.postalCode}`;
        } else {
          formattedAddress = `Depo24,${res.data.data.streetAddress.trim()},${res.data.data.city.trim()},${res.data.data.state.trim()},${res.data.data.country.trim()},${
            res.data.data.postalCode
          }`;
          address = `${res.data.data.streetAddress}, ${res.data.data.city}, ${res.data.data.state}, ${res.data.data.country}, ${res.data.data.postalCode}`;
        }
        setMapAddress(formattedAddress);
        setAddress(address);
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
        // setError(err.response.data.message);
      });
  };
  const handleClick = (location) => {
    setActiveLocation((prevLocation) =>
      prevLocation === location ? null : location
    );
  };
  useEffect(() => {
    if (activeLocation) {
      fetchStoreAddress(activeLocation);
    }
  }, [activeLocation]);
  useEffect(() => {
    getStoreImages();
  }, []);

  return (
    <>
      <Modal
        open={previewState}
        onClose={(event, reason) => handleClose(event, reason)}
        className={`${styles.modalContainer}`}
      >
        <div className={`${styles.container}`}>
          <div className={`${styles.childContainer}`}>
            <div className={`${styles.selectContainer}`}>
              <select
                className={`${styles.btn}`}
                value={device}
                onChange={handleChange}
              >
                {devices.map((device) => (
                  <option
                    key={device.name}
                    value={device.name}
                    className={`${styles.options}`}
                  >
                    {device.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${styles.btnContainer}`}>
              <button className={`${styles.btn}`} onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width:
                  selectedDevice.name === "Default"
                    ? selectedDevice.width
                    : `${selectedDevice.width}px`,
                height: selectedDevice.height,

                backgroundColor: "#e2e8f0",
                border: "2px solid black",
                padding: "1rem 1rem",
              }}
            >
              <div
                className={`${
                  selectedDevice.name === "iPhone X"
                    ? styles.storeHeaderiPhoneX
                    : styles.storeHeader
                }`}
              >
                <h1
                  style={{
                    textAlign: "center",
                    fontSize:
                      selectedDevice.name === "iPhone X" ? "1rem" : "2rem",
                  }}
                >
                  VISIT OUR STORES
                </h1>
                <div
                  className={`${
                    selectedDevice.name === "iPhone X"
                      ? styles.storeHeaderLine1iPhoneX
                      : styles.storeHeaderLine1
                  }`}
                ></div>
                <div
                  className={`${
                    selectedDevice.name === "iPhone X"
                      ? styles.storeHeaderLine2iPhoneX
                      : styles.storeHeaderLine2
                  }`}
                ></div>
              </div>
              <div className={styles.storeGridContainer}>
                {["Delhi", "Kolkata", "Rajkot", "Siliguri"].map(
                  (location, index) => (
                    <div
                      key={index}
                      className={`${styles.storeGridItem} ${
                        activeLocation === location
                          ? styles.storeGridItemActive
                          : ""
                      }`}
                      onClick={() => handleClick(location)}
                    >
                      <h1
                        style={{
                          fontSize:
                            selectedDevice.name === "iPhone X"
                              ? "0.875rem"
                              : "1.5rem",
                        }}
                      >
                        {location}
                      </h1>
                      <img
                        src="/assets/location.png"
                        alt="location"
                        className={`${styles.storeGridItemImage}`}
                        style={{
                          height:
                            selectedDevice.name === "iPhone X"
                              ? "1.5rem"
                              : "3rem",
                        }}
                        loading="lazy"
                      />
                    </div>
                  )
                )}
              </div>
              {activeLocation ? (
                <div
                  className={
                    selectedDevice.name === "iPhone X"
                      ? styles.storeDetailContaineriPhoneX
                      : styles.storeDetailContainer
                  }
                >
                  <div
                    className={`${
                      selectedDevice.name === "iPhone X"
                        ? styles.storeDetailImageContaineriPhoneX
                        : styles.storeDetailImageContainer
                    }`}
                  >
                    <iframe
                      title="google map"
                      width="100%"
                      height="100%"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowFullScreen
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${mapAddress}&zoom=15&maptype=roadmap`}
                    ></iframe>
                  </div>
                  <div
                    className={
                      selectedDevice.name === "iPhone X"
                        ? styles.storeDetailContaineriPhoneX
                        : styles.storeDetailTextContainer
                    }
                  >
                    <div
                      className={`${
                        selectedDevice.name === "iPhone X"
                          ? styles.storeDetailTextiPhoneX
                          : styles.storeDetailText
                      }`}
                    >
                      <h2
                        className={`${
                          selectedDevice.name === "iPhone X"
                            ? styles.storeDetailTitleiPhoneX
                            : styles.storeDetailTitle
                        }`}
                      >
                        {activeLocation}
                      </h2>
                      <p
                        className={`${
                          selectedDevice.name === "iPhone X"
                            ? styles.storeDetailAddressiPhoneX
                            : styles.storeDetailAddress
                        }`}
                      >
                        {address}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

                <div className={`swiper-container ${styles.swiperParent}}`}>
                  <Swiper
                    slidesPerView={selectedDevice.slidesPerView}
                    spaceBetween={15}
                    loop={true}
                    lazyPreloadPrevNext={selectedDevice.slidesPerView}
                    simulateTouch={true}
                    mousewheel={true}
                    navigation={true}
                    ref={swiper}
                  >
                    <SwiperSlide>
                      <div className="relative">
                        <img
                          src={image.image}
                          alt={`${image.title}`}
                          loading="lazy"
                          className={`${styles.sliderImg}`}
                        />
                      </div>
                      <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </SwiperSlide>
                    {storeItems?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className={styles.relative}>
                          <img
                            src={item?.image}
                            alt={item?.title}
                            loading="lazy"
                            className={`${styles.sliderImg}`}
                          />
                          {/* <div
                            className={`${styles.sliderTextContainer}`}
                            style={{ color: `${item.titleColor}` }}
                          >
                            {item.title}
                          </div> */}
                        </div>
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StorePreview;
