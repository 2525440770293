import React, { Fragment } from 'react';
import Classes from './css/addProduct.module.css';
import { IconButton, TextField,} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const UdfSection = ({ removeUdfSection, sequence_no, setPayloadToUpload, payloadToUpload }) => {
    return (
        <Fragment>
            <div className={Classes.Pricing}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: '15px', fontWeight: 500, marginLeft: '1em' }}>Sequence Number: {sequence_no}</span>
                    <IconButton onClick={removeUdfSection}><ClearIcon /></IconButton>
                </div>
                <div className={Classes.Boxes}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '5px' }}>
                            <TextField
                                label="Field Name"
                                name='field_name'
                                id="field_name"
                                value={
                                    payloadToUpload?.userDefinedFields
                                    ?.find((prevUdf)=>+prevUdf.sequence_no === +sequence_no)
                                    ?.field_name
                                }
                                onChange={(e) => setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {
                                        ...prevPayloadToUpload,
                                        userDefinedFields: prevPayloadToUpload?.userDefinedFields?.map((prevUdf)=>{
                                            return (+prevUdf.sequence_no === +sequence_no)
                                                ? { ...prevUdf, field_name: e?.target?.value }
                                                : prevUdf;
                                        })
                                    }
                                })}
                                required={true}
                                autoComplete='off'
                                size='small'
                                placeholder='Size'
                            />
                        </div>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '5px' }}>
                            <TextField
                                label="Field Value"
                                name='field_value'
                                id="field_value"
                                value={
                                    payloadToUpload?.userDefinedFields
                                    ?.find((prevUdf)=>+prevUdf.sequence_no === +sequence_no)
                                    ?.field_value
                                }
                                onChange={(e) => setPayloadToUpload((prevPayloadToUpload)=>{
                                    return {
                                        ...prevPayloadToUpload,
                                        userDefinedFields: prevPayloadToUpload?.userDefinedFields?.map((prevUdf)=>{
                                            return (+prevUdf.sequence_no === +sequence_no)
                                                ? { ...prevUdf, field_value: e?.target?.value }
                                                : prevUdf;
                                        })
                                    }
                                })}
                                required={true}
                                autoComplete='off'
                                size='small'
                                placeholder='Large'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UdfSection;
