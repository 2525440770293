import { MenuItem } from "@mui/material";

const FilteredExportMenuItem = ({ handleFilteredExport, label, ...props }) => {

    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={(event) => {
                handleFilteredExport(event)
                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            {label}
        </MenuItem>
    );
}

export default FilteredExportMenuItem;