import { Fragment, useState } from 'react';
import Classes from '../customers/css/customer.module.css';
import Button from '@mui/material/Button';
import * as React from 'react';
import UpdateSalesUserDialog from './salesUserDialog';
import SalesTargetDialog from './salesTarget';
import { useSelector } from 'react-redux';
import { setManagerDashboardData } from '../../../store/managerSlice';
import Table from "../../../components/table/table";

const ManagerDashboard = (props) => {
    const [salesUser,setSalesUser] = useState([]);
    const [customerReassign,setCustomerReassign] = useState('');

    // salesUser Reassign 
    const [open, setOpen] = useState(false);

    // salesUserTarget
    const [targetOpen, setTargetOpen] = useState(false);
    const [target,setTarget] = useState('');

    const [reRender,setReRender] = useState(false);

    const managerDashboardData = useSelector((state) => state.managerDashboardData);


    // salesUserTarget
    const handleTargetClose = (event) => {
        setSalesUser('');
        setTarget('');
        setTargetOpen(false);
    };

    // salesUser Reassign 
    const handleOpen = (customerId) => {
        setCustomerReassign(customerId)
        setOpen(true);
    };

    // salesUser Reassign
    const handleClose = (event) => {
        setSalesUser('');
        setOpen(false);
    };

    const columns = [
        {
            field: 'display_name',
            headerName: 'Customer',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            filterable: false
        },
        {
            field: 'area',
            headerName: 'Area',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'users',
            columnName: 'area',
            renderCell: (params) => (
                <div>{params?.row?.area ? params?.row?.area : 'N/A'}</div>
            )
        },
        {
            field: 'salesUser',
            headerName: 'Sales User',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'admins',
            columnName: 'name',
            renderCell: (params) => (
                <div>{params?.row?.salesUser ? params?.row?.salesUser : 'N/A'}</div>
            )
        },
        {
            field: 'Update',
            headerName: 'Update',
            filterable: false,
            flex: 0.4,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Button 
                    variant="contained"
                    sx={{
                        textTransform: "capitalize", padding:'3px 10px',
                        minWidth:'unset', fontSize:'12px'
                    }}
                    onClick={(event) => handleOpen(params?.row?.doc_id)}
                >
                    Update
                </Button>
            )
        }
    ];

	return (
		<Fragment>
			<div  id="color">
                <div className={Classes.Container}>
                    <Table
                        columns={columns}
                        dataPosition={"users"}
                        endpoint={`/fieldSalesVisit/customersWithSalesUser`}
                        rowId={'doc_id'}
                        checkBoxSelection={false}
                        version="version-1"
                        reRender={reRender}
                        service={'fieldSales'}
                        tabName={'Report Dashboard'}
                        setData={setManagerDashboardData}
                        data={managerDashboardData}
                    />
                </div>
            </div>

            <div className={open && Classes.UpdateSalesDialog}>
                <UpdateSalesUserDialog 
                    open={open}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    customer={customerReassign}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={targetOpen && Classes.UpdateSalesDialog}>
                <SalesTargetDialog
                    open={targetOpen}
                    handleClose={handleTargetClose}
                    // customer={customerReassign}
                    target={target}
                    setTarget={setTarget}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                />
            </div>
        </Fragment>
    );
};

export default ManagerDashboard;
