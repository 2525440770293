import React, { useState } from "react";
import axiosInstance from "../../../../utility/axios-instance";
import Table from "../../../../components/table/table";
import Button from "@mui/material/Button";
import Permission from "../../../auth/permissions";
import FormDialog from "../../../../components/FormDialog/FormDialog";
import Classes from "../../customers/css/customer.module.css";
import { useSelector } from "react-redux";
import { setCompanies } from "../../../../store/companySettingsSlice";
import CreateCompany from "./createCompany";
import UpdateCompany from "./editCompany";
import { Tooltip, Chip } from "@mui/material";
import moment from "moment";
import { Typography } from "@mui/material";
const CompanyHome = () => {
  const companies = useSelector((state) => state.companySettings);
  const columnVisibilityModel = {
    company_name_in_tally: false,
    address_line1: false,
    address_line2: false,
    city: false,
    postal_code: false,
    state_province: false,
    country: false,
    bank_account_name: false,
    bank_account_number: false,
    bank_name: false,
    bank_account_ifsc: false,
    pan: false,
    invoice_prefix: false,
    invoice_infix: false,
    invoice_number: false,
    cin: false,
  };
  let columns = [
    {
      flex: 1,
      field: "company_name",
      filterable: true,
      headerName: "Company Name",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.company_name} placement="top">
          <p>{params?.row?.company_name}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "company_name_in_tally",
      filterable: true,
      headerName: "Company Name In Tally",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.company_name} placement="top">
          <p>{params?.row?.company_name}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "gst_number",
      filterable: true,
      headerName: "GST Number",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.gst_number} placement="top">
          <p>{params?.row?.gst_number}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "address_line1",
      filterable: true,
      headerName: "Address Line 1",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.address_line1} placement="top">
          <p>{params?.row?.address_line1}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "address_line2",
      filterable: true,
      headerName: "Address Line 2",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.address_line2} placement="top">
          <p>{params?.row?.address_line2}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "city",
      filterable: true,
      headerName: "City",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.city} placement="top">
          <p>{params?.row?.city}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "postal_code",
      filterable: true,
      headerName: "Postal Code",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.postal_code} placement="top">
          <p>{params?.row?.postal_code}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "state",
      filterable: true,
      headerName: "State",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.state} placement="top">
          <p>{params?.row?.state}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "country",
      filterable: true,
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.country} placement="top">
          <p>{params?.row?.country}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "phone_number",
      filterable: true,
      headerName: "Phone Number",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.phone_number} placement="top">
          <p>{params?.row?.phone_number}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "email",
      filterable: true,
      headerName: "Email Id",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.email} placement="top">
          <p>{params?.row?.email}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "bank_account_number",
      filterable: true,
      headerName: "Bank Account Number",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.bank_account_number} placement="top">
          <p>{params?.row?.bank_account_number}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "bank_name",
      filterable: true,
      headerName: "Bank Name",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.bank_name} placement="top">
          <p>{params?.row?.bank_name}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "bank_account_ifsc",
      filterable: true,
      headerName: "Bank Account IFSC",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.bank_account_ifsc} placement="top">
          <p>{params?.row?.bank_account_ifsc}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "pan",
      filterable: true,
      headerName: "PAN",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.pan} placement="top">
          <p>{params?.row?.pan}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "invoice_prefix",
      filterable: true,
      headerName: "Invoice Prefix",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.invoice_prefix} placement="top">
          <p>{params?.row?.invoice_prefix}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "invoice_infix",
      filterable: true,
      headerName: "Invoice Infix",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.invoice_infix} placement="top">
          <p>{params?.row?.invoice_infix}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "invoice_number",
      filterable: true,
      headerName: "Invoice Number",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.invoice_number} placement="top">
          <p>{params?.row?.invoice_number}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "cin",
      filterable: true,
      headerName: "CIN",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.cin} placement="top">
          <p>{params?.row?.cin}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "created_at",
      filterable: true,
      headerName: "Created At",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip
          title={moment(params?.row?.created_at).format("MMMM Do YYYY, h:mm a")}
          placement="top"
        >
          <p>{moment(params?.row?.created_at).format("Do MMMM YYYY")}</p>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "updated_at",
      filterable: true,
      headerName: "Updated At",
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <Tooltip
          title={moment(params?.row?.updated_at).format("MMMM Do YYYY, h:mm a")}
          placement="top"
        >
          <p>{moment(params?.row?.updated_at).format("Do MMMM YYYY")}</p>
        </Tooltip>
      ),
    },
    {
      field: "update",
      headerName: "Update",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service={serviceName} permission={"update"}>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={(e) => openUpdateDialog(params?.row?.id)}
          >
            Update
          </Button>
        </Permission>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Permission service={serviceName} permission={"delete"}>
          <FormDialog
            dialogTitle="Confirm Delete Company Details"
            dialogActionType="confirmation"
            openDialogButtonName="Delete"
            submitDialogButtonName="Confirm"
            onConfirmation={() => {
              handleDelete(params?.row?.id);
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              This action would permanently Delete '{params?.row?.id}' Company ?
            </Typography>
          </FormDialog>
        </Permission>
      ),
    },
  ];
  const [showPopup, setShowPopup] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState(null);
  const [companiesDetails, setCompaniesDetails] = useState(null);
  const serviceName = "company";

  const showCreatePopup = () => {
    setShowPopup(true);
  };

  const hideCreatePopup = () => {
    setShowPopup(false);
  };

  const openUpdateDialog = (id) => {
    const details = companies?.companiesDetails?.find(
      (company) => company?.id === id
    );
    setCompaniesDetails(details);
    setId(details?.id);
    setUpdate(true);
  };

  const closeUpdateDialog = () => {
    setId(null);
    setCompaniesDetails(null);
    setUpdate(false);
  };

  const handleDelete = (companyId) => {
    axiosInstance
      .delete(`/adminRoutes/companies/${companyId}`)
      .then((result) => {
        setReRender(!reRender);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: 15,
          flexWrap: "wrap",
          marginTop: -24,
        }}
      >
        <Permission service={serviceName} permission={"create"}>
          <Button
            variant="contained"
            onClick={showCreatePopup}
            style={{ textTransform: "capitalize", marginRight: 10 }}
          >
            Create
          </Button>
        </Permission>
      </div>

        <Permission service={serviceName} permission={"read"}>
          <Table
            columns={columns}
            dataPosition={"companiesDetails"}
            endpoint={`/adminRoutes/companies`}
            rowId={"id"}
            checkBoxSelection={false}
            reRender={reRender}
            service={"company"}
            tabName={"Company"}
            setData={setCompanies}
            data={companies}
            columnVisibilityModel={columnVisibilityModel}
            version={"version-2"}
            mobileResponsive={true}
          />
        </Permission>

      <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
        <CreateCompany
          open={showPopup}
          closeDialog={hideCreatePopup}
          setReRender={setReRender}
          reRender={reRender}
        />
      </div>

      <div className={update ? Classes.UpdateSalesDialog : undefined}>
        <UpdateCompany
          companyId={id}
          open={update}
          companiesDetails={companiesDetails}
          setCompaniesDetails={setCompaniesDetails}
          closeDialog={closeUpdateDialog}
          setReRender={setReRender}
          reRender={reRender}
        />
      </div>
    </div>
  );
};

export default CompanyHome;
