import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BrandWisePopup({ open, handleClose, details}) {

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                // maxWidth={maxWidth}
            >
                <DialogTitle>{"BrandWise Breakdown"}</DialogTitle>
                <DialogContent>
                    {details.length > 0 ? details.map((item, index) => (
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="row">
                                <div className="col-6" style={{textAlign:"center"}}>
                                    <strong>{item.brand_name}</strong>
                                </div>
                                <div className="col-6"  style={{textAlign:"center"}}>
                                    <strong>₹{item.totalOrderAmount}</strong>
                                </div>
                            </div>
                        </DialogContentText>
                    )) : null}  
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}