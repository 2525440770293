import React, {Fragment,useState} from "react";
import Classes from "./css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import { TextField } from '@mui/material';

const ShippingPopUp = (props)=> {
    const [rateName,setRateName] = useState('')

    const {
        shippingModal,
        hideShippingPopup,
        productDetails, 
        overAllDiscount, 
        setProduct,
        setTaxLines,
        setTotalTaxAmount,
        shippingPrice,
        setShippingPrice,
        previousTarget,
        setPreviousTarget,
        shippingPriceType,
        shippingAmount,
        setShippingAmount,
        setShippingPriceType,
        setLoadingSalesOrderCalculationServiceState,
    } = props;

    const handleChange = (event) => {
        if(shippingPriceType.length !== 0){
            const radio =  document.getElementById(shippingPriceType[0].fieldName);
            radio.checked = false;
            if(event.target.checked){
                if(event.target.id === 'custom'){
                    setShippingPriceType([{fieldName: 'custom'}]);
                    setPreviousTarget(['custom']);
                    setShippingAmount(true);
                }else{
                    setShippingPriceType([{fieldName: 'freeShipping'}])
                    setPreviousTarget(['freeShipping']);
                    setShippingAmount(false);
                }
            }
        }else{
            if(event.target.checked){
                if(event.target.id === 'custom'){
                    setShippingPriceType([{fieldName: 'custom'}])
                    setPreviousTarget(['custom']);
                    setShippingAmount(true);
                }else{
                    setShippingPriceType([{fieldName: 'freeShipping'}])
                    setPreviousTarget(['freeShipping']);
                    setShippingAmount(false);
                }
            }
        }
    } 

    const handleSubmit = (event) => {
        event.preventDefault();
        setPreviousTarget([])

        hideShippingPopup();

        let shipping= {}
        if(previousTarget[0] === 'freeShipping'){
            shipping = {
                price: '0',
                title: 'Free-Shipping'
            }
            setShippingAmount(false);
        }else{
            if(event.target.price.value){
                if (event.target.price.value >= 0) {
                    shipping = {
                        price: parseFloat(event.target.price.value),
                        title: event.target.rateName.value,
                        taxRate: 18,
                        taxAmount: event.target.price.value * 18/100
                    }
                    setShippingAmount(true);
                } else {
                    
                }
            }else{
                if(event.target.rateName.value){
                    shipping = {
                        price: "0.0",
                        title: event.target.rateName.value
                    }
                }else{
                    shipping = {
                        price: '0.0',
                        title: 'Free-Shipping'
                    }
                }
            }
        }

        const data ={
            productDetails: productDetails,
            discount: overAllDiscount.length !== 0 ? overAllDiscount : undefined,
            shippingLine : previousTarget[0] !== 'freeShipping' ? shipping : undefined
        }

        if (previousTarget[0] === 'freeShipping') {
            setShippingPrice([])   
        } else {
            setShippingPrice([shipping]);
        }
        setLoadingSalesOrderCalculationServiceState(true)
        const salesOrderCalculationServiceEndpoint = '/product/fetchProductDetails/bulk';
        axiosInstance
            .post(salesOrderCalculationServiceEndpoint,{...data})
            .then((res) => {
                const calculatedResponse = res?.data?.product
                if (calculatedResponse?.TaxLines) {
                    setTaxLines(calculatedResponse?.TaxLines?.structuredTaxLines)
                    setTotalTaxAmount(calculatedResponse?.TaxLines?.totalTaxAmount)
                }
                setProduct(res.data.product);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                let calculateSalesOrderRoutePendingCount = localStorage.getItem(salesOrderCalculationServiceEndpoint);
                calculateSalesOrderRoutePendingCount = calculateSalesOrderRoutePendingCount ? parseInt(calculateSalesOrderRoutePendingCount) : 0;
                if (calculateSalesOrderRoutePendingCount === 0) {
                    setLoadingSalesOrderCalculationServiceState(false)
                }
            });
    }


    const handleShippingCharges = (event) => {
        if (event.target.value && event.target.value >= 0) {
            document.getElementById('RestrictShipping').style.display = 'none';
            document.getElementById('btn').disabled = false;
        } else {
            document.getElementById('RestrictShipping').style.display = 'block';
            document.getElementById('btn').disabled = true;
        }
    }


    return (
        <Fragment>
            {shippingModal && (
                <div className={Classes.modal} style={{bottom:'34%',top:'16%'}}>
                    <div className={Classes.BoxContent}>
                        <p>Add shipping</p>
                    </div>
                    
                    <form onSubmit={handleSubmit} method='post'>
                        <div style={{padding:'0px 20px',height:'170px'}}>
                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input
                                        type='radio'
                                        id='freeShipping'
                                        name='freeShipping'
                                        onChange={(e) => handleChange(e)}
                                        defaultChecked={
                                            shippingPriceType.length !== 0 && 
                                            shippingPriceType[0].fieldName === 'freeShipping'? true : 
                                            false
                                        }
                                    />
                                </div>

                                <div style={{marginLeft:'5px'}}>
                                    <p>Free shipping</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input
                                        type='radio'
                                        id='custom'
                                        name='custom'
                                        onChange={(e) => handleChange(e)}
                                        defaultChecked={
                                            shippingPriceType.length !== 0  &&
                                            shippingPriceType[0].fieldName === 'custom' ? true 
                                            : false
                                        }
                                    />
                                </div>

                                <div style={{marginLeft:'5px'}}>
                                    <p>Custom</p>
                                </div>
                            </div>
                            
                            {shippingAmount && (
                                <div className={Classes.RateBox} id='rate' style={{marginTop:20}}>
                                    <div style={{width:'50%'}}>
                                        <TextField
                                            id='rateName'
                                            name="rateName"
                                            label="Rate Name"
                                            placeholder='Rate Name'
                                            autoComplete='off'
                                            key={shippingPrice.length !== 0 ? shippingPrice[0].title :''}
                                            defaultValue={shippingPrice.length !== 0 ? shippingPrice[0].title :''}
                                            onChange={(event) => setRateName(event.target.value)}
                                            sx={{ width: '100%',"& label": {top:rateName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>

                                    <div style={{marginLeft:'5px',width:'50%'}}>
                                        <TextField
                                            id='price'
                                            name="price"
                                            label="Rate Value"
                                            placeholder='Rate Value'
                                            autoComplete='off'
                                            key={shippingPrice.length !== 0 ? shippingPrice[0].price :'0.00'}
                                            defaultValue={shippingPrice.length !== 0 ? shippingPrice[0].price :'0.00'}
                                            onChange={handleChange}
                                            onBlur={handleShippingCharges}
                                            sx={{ width: '100%',"& label": {top:shippingPrice ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />

                                        <div 
                                            id='RestrictShipping' 
                                            className={Classes.DiscountRestrictMsg}
                                        >
                                            <p>Shipping Charges Can't be Negative</p>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'34%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideShippingPopup}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                APPLY
                            </button>
                        </div>
                    </form>

                </div>
            )}
        </Fragment>
    );
}


export default ShippingPopUp;