import React, { useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Typography } from '@mui/material';
import Table from '../../../components/table/table';
import Button from '@mui/material/Button';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import FormDialog from '../../../components/FormDialog/FormDialog';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setFranchises } from '../../../store/franchise';
import CreateFranchise from './createFranchise';
import UpdateFranchise from './updateFranchise';
import { Tooltip, Chip } from '@mui/material';
import UpdateParchiPeople from './updateParchiPeople';

const Franchise = () => {
    const franchises = useSelector((state) => state.franchises);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 0.4,
            // headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
            // headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'company_name',
            headerName: 'Company Name',
            flex: 1.5,
            // headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'franchiseCode',
            headerName: 'F Code',
            flex: 0.7,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'pincode',
            headerName: 'ZIP',
            type: 'number',
            flex: 0.7,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'state',
            headerName: 'State',
            flex: 1,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            // headerAlign: 'center',
            // align: 'center'
        },
        {
            field: 'locations',
            headerName: 'Locations',
            flex: 1.3,
            // filterable : false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{display:'flex',fontSize:14}}>
                    {params?.value?.slice(0,2)?.map((location, index) => (
                        <Chip key={index} label={location} 
                            style={{ margin: '3px', height:'24px' }} 
                            sx={{'& .MuiChip-label': {paddingRight:'8px',paddingLeft:'8px'}}} 
                        />
                    ))}
                    {params?.value?.length > 2 ? (
                        <Tooltip title={
                            <div>
                                {params?.value?.slice(2)?.map((location, index) => (
                                    <p>{location}, </p>
                                ))}
                            </div>
                        } arrow>
                        <Chip size="small" label={`+ ${params?.value?.length - 2} `} 
                            style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} 
                            sx={{'& .MuiChip-label': {paddingRight:'8px',paddingLeft:'8px'}}}
                        />
                        </Tooltip>
                    ) : null}
                </div>
            )
            // headerAlign: 'center',
            // align: 'center',
        },
        // {
        //     field: 'default',
        //     headerName: 'Default',
        //     flex: 1,
        //     filterable: false,
        //     headerClassName: 'super-app-theme--header',
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (params) => (
        //         params.value ? (
        //             <BsCheckCircleFill color="green" />
        //         ) : (
        //             <BsXCircleFill color="red" />
        //         )
        //     )
        // },
        {
            field: 'createdBy',
            headerName: 'Created By',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={serviceName} permission={"update"}>
                    <Button size='small' color='success' variant="contained" onClick={(e) => openUpdateDialog(params?.row?.id)}>Update</Button>
                </Permission>
            )
        },
        {
            field: 'updateParchiPeople',
            headerName: 'Update Parchi People',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            flex: 2,
            renderCell: (params) => (
                <Permission service={serviceName} permission={"update"}>
                    <Button size='small' color='success' variant="contained" onClick={(e) => openUpdateParchiPeopleDialog(params?.row?.id)}>Update Parchi People</Button>
                </Permission>
            )
        },
        {
            field: 'delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={serviceName} permission={"delete"}>
                    <FormDialog
                        dialogTitle="Confirm Delete Bin"
                        dialogActionType="confirmation"
                        openDialogButtonName="Delete"
                        submitDialogButtonName="Confirm"
                        onConfirmation={() => { handleDelete(params?.row?.id) }}
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            This action would permanently Delete '{params?.row?.franchiseCode}' Franchise ?
                        </Typography>
                    </FormDialog>
                    {/* <Button size='small' color='error' variant="contained" onClick={(e) => handleDelete(params?.row.bin_id, params?.row?.locationCode)}>Delete</Button> */}
                </Permission>
            )
        },
    ];
    const [showPopup, setShowPopup] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [update, setUpdate] = useState(false);
    const [id, setId] = useState(null);
    const [franchiseDetails,setFranchiseDetails] = useState(null);
    const serviceName = services?.franchise;

    const [updateParchiPeople, setUpdateParchiPeople] = useState(false);

    const showCreatePopup = () => {
        setShowPopup(true)
    }

    const hideCreatePopup = () => {
        setShowPopup(false)
    }

    const openUpdateDialog = (id) => {
        const details = franchises?.fetchedFranchises?.find((franchise) => franchise?.id === id);
        setFranchiseDetails(details);
        setId(details?.id);
        setUpdate(true);
    }

    const closeUpdateDialog = () => {
        setId(null);
        setFranchiseDetails(null);
        setUpdate(false);
    }

    const handleDelete = (id) => {
        axiosInstance
            .delete(`/pos/deleteFranchise/${id}`)
            .then(result => {
                setReRender(!reRender)
            })
            .catch(error => console.log('error', error))
    }

    const openUpdateParchiPeopleDialog = (id) => {
        const details = franchises?.fetchedFranchises?.find((franchise) => franchise?.id === id);
        setFranchiseDetails(details);
        setId(details?.id);
        setUpdateParchiPeople(true);
    }

    const closeUpdateParchiPeopleDialog = () => {
        setId(null);
        setFranchiseDetails(null);
        setUpdateParchiPeople(false);
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 15, flexWrap: 'wrap',marginTop:-24 }}>
                <Permission service={serviceName} permission={"create"}>
                    <Button
                        variant="contained"
                        onClick={showCreatePopup}
                        style={{ textTransform: 'capitalize', marginRight: 10 }}
                    >
                        Create
                    </Button>
                </Permission>
            </div>

            <div className={Classes.MuiTable}>
                <Permission service={serviceName} permission={"read"}>
                    <Table
                        columns={columns}
                        dataPosition={"fetchedFranchises"}
                        endpoint={`/pos/fetchFranchises`}
                        rowId={'id'}
                        checkBoxSelection={false}
                        reRender={reRender}
                        service={'franchise'}
                        tabName={'Franchise'}
                        setData={setFranchises}
                        data={franchises}
                    />
                </Permission>
            </div>

            <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                <CreateFranchise 
                    open={showPopup}
                    closeDialog={hideCreatePopup}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={update ? Classes.UpdateSalesDialog : undefined}>
                <UpdateFranchise 
                    Id={id}
                    open={update}
                    franchiseDetails={franchiseDetails}
                    setFranchiseDetails={setFranchiseDetails}
                    closeDialog={closeUpdateDialog}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={updateParchiPeople ? Classes.UpdateSalesDialog : undefined}>
                <UpdateParchiPeople 
                    Id={id}
                    open={updateParchiPeople}
                    franchiseDetails={franchiseDetails}
                    closeDialog={closeUpdateParchiPeopleDialog}
                />
            </div>
        </div>
    )
}

export default Franchise