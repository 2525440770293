import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Classes from '../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import Permission from '../../auth/permissions';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { services } from "../../../utility/checkRoleBasedPermission";
import { Button, Chip, Link, Tooltip } from "@mui/material";
import FilePresentIcon from '@mui/icons-material/FilePresent';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const { dataPosition, endpoint, rowId, columns, showPopup, showClosePopup, reRender } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor, reRender]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                            <p>
                                {column?.field === "createdAt" || column?.field === 'updatedAt'  ? 
                                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                                column?.field === 'amount' ? 
                                    <div style={{display: 'flex', justifyContent : 'space-between'}}>
                                        &#8377;{row?.amount ? row?.amount : "N/A"}
                                        <Permission service={services?.refunds} permission="update">
                                            <BorderColorIcon sx={{cursor : 'pointer', color : 'gray'}}
                                                fontSize='small' 
                                                onClick={(e) => showPopup(e, row?.refundId, row?.amount)}
                                            />
                                        </Permission>
                                    </div>
                                : column?.field === 'documentsAttached' ? 
                                <>
                                    {row?.documentsAttached?.length > 0 && (
                                        <div style={{ display: 'flex', gap: "5px" }}>
                                            {row?.documentsAttached?.slice(0, 2)?.map((doc) => (
                                                <Chip size="small" icon={<FilePresentIcon />} clickable label={decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 6) + "..."} component="a" href={doc} />
                                            ))}
                                            {row?.documentsAttached?.length > 2 ? (
                                                <Tooltip title={
                                                    <div style={{ gap: "20px" }}>
                                                        {row?.documentsAttached?.slice(2)?.map((doc, index) => (
                                                            <Link color="inherit" href={doc}>{decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 8)} , </Link>
                                                        ))}
                                                    </div>
                                                } arrow>
                                                    <Chip size="small" label={`+ ${row?.documentsAttached?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                    )}
                                    {row?.status === 'Approved' ? (
                                        <div>
                                            <Permission service={services?.refunds} permission="update">
                                                <Button size="small" onClick={(e) => showClosePopup(e, row?.refundId)} type='submit' variant="contained">
                                                    Mark Close
                                                </Button>
                                            </Permission>
                                        </div>
                                    ) : null}
                                </>
                                : row[column?.field]}
                            </p>
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;