import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from './SnackBar';
import axiosInstance from '../utility/axios-instance';
import { downloadCSV } from "../utility/downloadCSV";
import Spinner from './spinner';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExportDatas = (props)  => {
    const { open, handleClose,endPoint,dataName } = props;

    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });

    const handleExport = () => {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
		const currentPage = page? page : 1;
        const data = {
            currentPage: currentPage,
            AllData: "AllData"
        }

        axiosInstance
            .get(`${endPoint}?type=${data[value]}`)
            .then((res) => {
                setLoading(false);
                const arrayOfRowObjects = res?.data?.data;
                downloadCSV(arrayOfRowObjects, `${dataName} export - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `${dataName} Exported Successfully`, type: "success" }
                });
                handleClose();
                setValue('currentPage');
            })
            .catch((error) => {
                setLoading(false);
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const [value, setValue] = useState('currentPage');

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                {loading ? <Spinner /> : null}
                <DialogTitle 
                    style={{backgroundColor:"#9e9e9e1f",fontSize:"15px",fontWeight:600,padding:'10px 20px'}}
                >Export {dataName}</DialogTitle>
                    <FormControl style={{padding:'20px'}}>
                        <FormLabel id="demo-controlled-radio-buttons-group">Export</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="currentPage" control={<Radio />} label="Current Page" />
                            <FormControlLabel value="AllData" control={<Radio />} label={`All ${dataName}`} />
                        </RadioGroup>
                    </FormControl>

                    <DialogActions style={{padding:"16px 20px"}}>
                        <Button 
                            onClick={handleClose} variant="contained" 
                            style={{backgroundColor:'#627d98',textTransform:'capitalize',padding: "4px 10px"}}
                        >Close</Button>
                        <Button 
                            type='submit' 
                            id='btn' 
                            variant="contained" 
                            style={{backgroundColor:'#2e7d32',textTransform:'capitalize',padding: "4px 10px"}}
                            onClick={handleExport}
                        >
                            Export
                        </Button>
                    </DialogActions>
                {/* </form> */}
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}

export default ExportDatas;