import React, { Fragment, useMemo } from 'react';
import Classes from './css/addProduct.module.css';
import { IconButton } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import UdfSection from './UdfSection';

const UserDefinedFields = ({ setPayloadToUpload, payloadToUpload }) => {
    const [udfSections, setUdfSections] = React.useState([]);

    const removeUdfSection = (sequence_no) => {
        setUdfSections((prevUdfSection) => prevUdfSection.filter((udfSection) => +udfSection?.props?.sequence_no !== +sequence_no));
        setPayloadToUpload((prevPayloadToUpload) => {
          return {
            ...prevPayloadToUpload,
            userDefinedFields: prevPayloadToUpload?.userDefinedFields?.filter(
              (udf) => +udf?.sequence_no !== +sequence_no
            ),
          };
        });
    }

    const addUserDefinedFields = () => {
        // Create Section
        const sequence_no = +(udfSections
            ?.find((udfSection, index) =>
                +udfSection?.props?.sequence_no + 1 !== udfSections[index + 1]?.props?.sequence_no
            ))
            ?.props?.sequence_no + 1
            || 1;

        if(udfSections && udfSections?.length !== 0) {
            setUdfSections((prevUdfSection) => [
                ...prevUdfSection,
                <UdfSection
                    key={sequence_no}
                    sequence_no={sequence_no}
                    removeUdfSection={() => removeUdfSection(sequence_no)}
                    setPayloadToUpload={setPayloadToUpload}
                    payloadToUpload={payloadToUpload}
                />
            ]);
        } else {
            setUdfSections([
                <UdfSection
                    key={sequence_no}
                    sequence_no={sequence_no}
                    removeUdfSection={() => removeUdfSection(sequence_no)}
                    setPayloadToUpload={setPayloadToUpload}
                    payloadToUpload={payloadToUpload}
                />
            ]);
        }
       
        setPayloadToUpload((prevPayloadToUpload) => {
            return {
                ...prevPayloadToUpload,
                userDefinedFields: [
                ...(prevPayloadToUpload?.userDefinedFields || []),
                {
                    sequence_no: sequence_no,
                    field_name: "",
                    field_value: "",
                },
                ],
            };
        });

    }

    useMemo(() => {
        if (payloadToUpload?.userDefinedFields?.length !== 0) {
            // Create Sections
            const udfSections = payloadToUpload?.userDefinedFields?.map((udf) => {
                return (
                    <UdfSection
                        key={udf?.sequence_no}
                        sequence_no={udf?.sequence_no}
                        removeUdfSection={() => removeUdfSection(udf?.sequence_no)}
                        setPayloadToUpload={setPayloadToUpload}
                        payloadToUpload={payloadToUpload}
                    />
                );
            });
            setUdfSections(udfSections);
        }
    }, [payloadToUpload?.userDefinedFields]);

    return (
        <Fragment>
            <div className={Classes.Box} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 12px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: 500 }}>Add User Defined Fields</div>
                <IconButton size='large' onClick={(e) => addUserDefinedFields(e)}><ControlPointIcon /></IconButton>
            </div>
            {udfSections}
        </Fragment>
    );
}

export default UserDefinedFields;
