import React from 'react';
import { TextField, Autocomplete, } from "@mui/material";
import axiosInstance from '../../utility/axios-instance';

const EnumInputValue = (props) => {
    const { item, applyValue, focusElementRef, enumValues, tableName, columnName } = props;
    const [fetchedEnumValues, setFetchedEnumValues] = React.useState([]);

    const fetchEnumValues = async () => {
        if (!tableName || !columnName) return;
        const query = {
            tableName: tableName,
            columnName: columnName,
        }
        axiosInstance
            .get(`/product/filtersForUI`, {
                params: query,
            })
            .then((res) => {
                let enumValues = res?.data?.data?.enumValues;
                enumValues = enumValues?.filter((value) => value !== null && value !== undefined && value !== '' && value !== 'null');
                setFetchedEnumValues(enumValues);
            });
    };

    React.useEffect(() => {
        if (enumValues?.length > 0) {
            setFetchedEnumValues(enumValues);
        }
        else if (!enumValues || enumValues?.length === 0) {
            fetchEnumValues();
        }
    }, [enumValues]);

    const attributeRef = React.useRef(null);
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            attributeRef.current.focus();
        },
    }));

    const handleFilterChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <Autocomplete
            id="attributeName"
            value={item?.value || ''}
            onChange={handleFilterChange}
            options={fetchedEnumValues || []}
            ref={attributeRef}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="standard"
                    label="Attribute Name"
                    InputLabelProps={{ shrink: true }} // Add this line
                />
            }
        />
    );
}

export default EnumInputValue;