import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark,faMagnifyingGlass,faRightLeft} from "@fortawesome/free-solid-svg-icons";


const TagsPopup = (props)=> {
    const {tagsModal,hideTagsPopup} = props;

    return (
        <Fragment>
            {tagsModal && (
                <div className={Classes.modal} style={{bottom:'8%',top:'10%'}}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage tags</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideTagsPopup}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div style={{padding:'30px 20px',overflowY:'scroll',height:'400px'}}>
                        <div style={{display:'flex'}}>
                            <div className={Classes.TagsSearchBox}>
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faMagnifyingGlass} 
                                        className={Classes.TagsSearchIcon}
                                    />
                                </div>

                                <div style={{width:'100%'}}>
                                    <input
                                        type="text"
                                        id="search"
                                        className={Classes.SearchTag}
                                        style={{border:'0',marginTop:'4px',width:'100%'}}
                                        placeholder="Search to find and create tags"
                                    />
                                </div>
                            </div>

                            <div className={Classes.Search} style={{marginLeft:'10px'}}>
                                <FontAwesomeIcon 
                                    icon={faRightLeft} 
                                    className={Classes.TagsFrequentlyUsedIcon}
                                    style={{rotate:'90deg'}}
                                />
                                    <button 
                                        type="submit" 
                                        className={Classes.TagsFrequentlyUsedBtn}
                                    >
                                        Frequently used
                                    </button>
                                </div>
                        </div>
                        
                        <div style={{padding:'20px 10px',fontSize:'13px'}}>
                            <div style={{color: 'rgb(104, 111, 123)'}}>
                                <p style={{fontSize:'13px',fontWeight:'600'}}>AVAILABLE</p>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>RETAILER</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>SAMPLE100</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>SAMPLE</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>XYZ-4</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>GOAFFPRO</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>ARCHITECT</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>COD</p>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:'10px'}}>
                                <div>
                                    <input type='checkbox'/>
                                </div>
                                <div style={{marginLeft:'8px'}}>
                                    <p>OFFER100</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div 
                        className={[
                            Classes.Button,
                            Classes.TagsFooterBox
                        ].join(' ')} 
                    >
                        <button 
                            id='btn' 
                            type="submit" 
                            onClick={hideTagsPopup}
                            className={Classes.TagsFooterBtn}
                        >
                            Done
                        </button>
                    </div>

                </div>
            )}
        </Fragment>
    );
}


export default TagsPopup;