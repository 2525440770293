import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	fetchedPincodes: [],
};

const companySettingsSlice = createSlice({
	name: 'companies',
	initialState: initialState,
	reducers: {
		setCompanies: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setCompanies, reset } = companySettingsSlice.actions;

export default companySettingsSlice.reducer;