import React, { useEffect, useState } from "react";
import { red } from "@mui/material/colors";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  Autocomplete,
  Slide,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { Add } from "@mui/icons-material";
import axiosInstance from "../../../../../utility/axios-instance";
import SnackBar from "../../../../../components/SnackBar";
import { styled } from "@mui/system";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});
const SectionBox = styled(Box)({
  marginBottom: "2rem",
});

const CreateStoreLoc = ({ open, handleClose, setReRender = () => {} }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showImageField, setShowImageField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [imageInputType, setImageInputType] = useState("URL");
  const [imageURL, setImageURL] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [titleColor, setTitleColor] = useState("#000000");
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    let data = {
      name: event.target.name.value,
      streetAddress: event.target.streetAddress.value,
      landmark: event.target.landmark.value,
      city: event.target.city.value,
      state: event.target.state.value,
      postalCode: event.target.postalCode.value,
      country: event.target.country.value,
      title,
      titleColor,
    };
    if (imageInputType === "URL") {
      data = { ...data, imageURL };
    } else {
      formData.append("imageFile", imageFile);
    }
    formData.append("data", JSON.stringify(data));
    setLoading(true);
    axiosInstance
      .post(`/adminRoutes/stores/locations`, formData)
      .then((res) => {
        handleClose();
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
        setReRender((prev) => !prev);
      })
      .catch((err) => {
        setSnackBar({
          display: true,
          type: "error",
          message:
            err?.response?.data?.message ||
            "An error occurred during submission",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    axiosInstance.get("/others/AllStates").then((response) => {
      const states = [];
      for (let stateName of response.data.data) {
        states.push({ id: stateName?._id, label: stateName?.state });
      }
      setStates(states);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get("/pincode/getAllCities").then((response) => {
      setCities(response.data.data);
    });
  }, []);
  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="upload-dialog-title"
      >
        <DialogTitle id="upload-dialog-title">
          Create Store
          <span
            style={{
              marginLeft: "0.5rem",
              fontSize: "0.75rem",
              color: "red",
              fontWeight: "bold",
              animation: "fade-in 0.5s ease",
              display: "inline-block",
            }}
          >
            (Image required to create store location)
          </span>
        </DialogTitle>

        <StyledForm onSubmit={handleSubmit}>
          <StyledDialogContent>
            <SectionBox>
              <Divider
                component="div"
                role="presentation"
                style={{ marginBottom: "1rem" }}
              >
                <Typography variant="subtitle1">
                  Add Store Location Details
                </Typography>
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    type="text"
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    id="streetAddress"
                    name="streetAddress"
                    label="Street Address"
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label="Landmark"
                    id="landmark"
                    name="landmark"
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="dense"
                    id="postalCode"
                    name="postalCode"
                    type="number"
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {" "}
                  <Autocomplete
                    disablePortal
                    id={"city"}
                    name={"city"}
                    options={cities}
                    sx={{ width: "100%", marginTop: 1 }}
                    renderInput={(params) => (
                      <TextField {...params} label="City" required={true} />
                    )}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // freeSolo
                    required={true}
                    disablePortal
                    id="state"
                    name="state"
                    options={states}
                    ListboxProps={{ sx: { fontSize: 14 } }}
                    size="small"
                    sx={{ width: "100%", marginTop: 1 }}
                    renderInput={(params) => (
                      <TextField {...params} label="States" required />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    required
                    id="country"
                    name="country"
                    type="text"
                    label="Country"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    defaultValue="India"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {!showImageField ? (
                    <Button
                      margin="dense"
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Add />}
                      onClick={() => {
                        setShowImageField(true);
                      }}
                    >
                      Add Image
                    </Button>
                  ) : (
                    <Button
                      margin="dense"
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<RemoveIcon />}
                      sx={{
                        backgroundColor: "red",
                        "&:hover": { backgroundColor: red[600] },
                      }}
                      onClick={() => {
                        setShowImageField(false);
                      }}
                    >
                      Close Image Section
                    </Button>
                  )}
                </Grid>
              </Grid>
            </SectionBox>
            {showImageField ? (
              <SectionBox>
                <Divider
                  component="div"
                  role="presentation"
                  style={{ marginBottom: "1rem" }}
                >
                  <Typography variant="subtitle1">Upload Image</Typography>
                </Divider>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => setTitle(e.target.value)}
                      margin="dense"
                      required
                      label="Title"
                      type="text"
                      variant="outlined"
                      value={title}
                      autoComplete="off"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          margin="dense"
                          required
                          label="Title Color"
                          type="text"
                          variant="outlined"
                          value={titleColor}
                          autoComplete="off"
                          onChange={(e) => setTitleColor(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          margin="dense"
                          required
                          label="Title Color"
                          type="color"
                          value={titleColor}
                          onChange={(e) => setTitleColor(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={imageInputType}
                    onChange={(e) => setImageInputType(e.target.value)}
                  >
                    <FormControlLabel
                      value="URL"
                      control={<Radio />}
                      label="URL"
                    />
                    <FormControlLabel
                      value="UPLOAD"
                      control={<Radio />}
                      label="File Upload"
                    />
                  </RadioGroup>
                </FormControl>
                {imageInputType === "URL" ? (
                  <TextField
                    required
                    label="Image URL"
                    type="url"
                    value={imageURL}
                    onChange={(e) => setImageURL(e.target.value)}
                    fullWidth
                  />
                ) : (
                  <TextField
                    required
                    type="file"
                    onChange={(e) => setImageFile(e.target.files[0])}
                    inputProps={{ accept: "image/*" }}
                    fullWidth
                  />
                )}
              </SectionBox>
            ) : null}
          </StyledDialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!title || loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </DialogActions>
        </StyledForm>
      </StyledDialog>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  );
};

export default CreateStoreLoc;
