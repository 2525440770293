import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';

const ConfirmDialog = (props) => {
    const [loading,setLoading] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })

    const {
        confirmDialog,
        customer,
        hideConfirmDialog,
        setReRender,
        reRender
    } = props;

    const handleUpdateCustomer = async () => {
        setLoading(true);

        await axiosInstance
			.put(`/user/admin/affiliateCustomer/${customer?._id}`, {
                active: customer?.active === true ? false: true
            })
			.then((res) => {
                setLoading(false);
                const successMessage = 'Customer Update Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender(!reRender);
                hideConfirmDialog();
			})
            .catch((error) => {
                console.log(error,'error')
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            {confirmDialog && (
                <div
                    className={Classes.confirmModal}
                    style={{ bottom: '28%',top:'16%',boxShadow:'0 0px 9px 0' }}
                >
                    <div style={{ textAlign:'center',margin:'30px 0px'}}>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            className={Classes.XMark}
                            style={{color:'#E12D39'}}
                        />
                    </div>

                    <div
                        style={{ fontSize:30,color:'black' }}
                        className={Classes.confirmPopUpCommonStyles}
                    >
                        <p>Are You Sure?</p>
                    </div>

                    <div
                        style={{ padding:"10px 20px",color:'black' }}
                        className={Classes.confirmPopUpCommonStyles}
                    >
                        <p style={{textTransform:'capitalize'}}>
                            Do you really want to <b>{customer?.active ? "In Active": "Active"}</b> 
                            &nbsp;this customer &nbsp;{customer?.displayName ? customer?.displayName :
                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}?</p>
                    </div>


                    <div
                        className={Classes.Button}
                        style={{ display:'flex',justifyContent:'space-between',padding:15,margin:'0px auto',width:290}}
                    >
                        <div>
                            <button
                                id='btn'
                                type="submit"
                                onClick={(e) => handleUpdateCustomer(e)}
                                style={{ backgroundColor:'#E12D39',color:'white',borderRadius:30,border:0 }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button
                                id='btn'
                                type="submit"
                                style={{borderRadius:30,backgroundColor:"#627D98",color:'#fff'}}
                                onClick={(e) => hideConfirmDialog(e)}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {loading ? <Spinner /> : null}
            
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default ConfirmDialog;