import { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { BiArrowBack, BiCheckCircle } from 'react-icons/bi';
import { MdOutlineCancel } from 'react-icons/md';

import axiosInstance from '../../../utility/axios-instance';
import resetState from '../../../store/actions';

import Spinner from '../../../components/spinner';

const years = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

// const days = [
// 	'Sunday',
// 	'Monday',
// 	'Tuesday',
// 	'Wednesday',
// 	'Thursday',
// 	'Friday',
// 	'Saturday',
// ];

const DeliveryBoyOrderId = () => {
	const { id } = useParams();
	const [searchParams] = useSearchParams();

	const awbno = searchParams.get('awbno');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [order, setOrder] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchOrder = async () => {
		setLoading(true);
		setError(null);

		axiosInstance
			.get(`/orders/admin/${id}`)
			.then((response) => {
				setOrder(response.data.data);
			})
			.catch((error) => {
				setError(error.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dn = new Date();
	const cancelledAt =
		order && order?.cancel
			? order?.cancel?.cancelledAt
				? new Date(order?.cancel?.cancelledAt)
				: null
			: null;

	let cancelledAtDate = cancelledAt
		? cancelledAt.getFullYear() === dn.getFullYear()
			? cancelledAt.getMonth() === dn.getMonth()
				? cancelledAt.getDate() === dn.getDate()
					? `Today at ${cancelledAt.toLocaleTimeString()}`
					: cancelledAt.getDate() === dn.getDate() - 1
					? `Yesterday at ${cancelledAt.toLocaleTimeString()}`
					: years[cancelledAt.getMonth()] +
					  ' ' +
					  cancelledAt.getDate() +
					  ' at ' +
					  cancelledAt.toLocaleTimeString()
				: years[cancelledAt.getMonth()] +
				  ' ' +
				  cancelledAt.getDate() +
				  ' at ' +
				  cancelledAt.toLocaleTimeString()
			: years[cancelledAt.getMonth()] + ' ' + cancelledAt.getFullYear()
		: null;

	const [loading2, setLoading2] = useState(false);
	const [error2, setError2] = useState(null);

	const [loading3, setLoading3] = useState(false);
	const [error3, setError3] = useState(null);

	const func1 = async () => {
		if (order.cancel) {
			return;
		}
		setLoading2(true);
		setError2(null);

		axiosInstance
			.post(`/deliveryBoy/markOrderAsPaid/${id}`)
			.then((response) => {
				setOrder(response?.data?.data);
			})
			.catch((error) => {
				setError2(error?.response?.data?.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading2(false);
			});
	};

	const func2 = async () => {
		if (order.cancel) {
			return;
		}
		setLoading3(true);
		setError3(null);

		axiosInstance
			.post(`/deliveryBoy/updateOrder/${id}`)
			.then((response) => {
				setOrder(response?.data?.data);
			})
			.catch((error) => {
				setError3(error?.response?.data?.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})
			.finally(() => {
				setLoading3(false);
			});
	};

	return (
		<Fragment>
			<div className="d-flex justify-content-between align-items-center mb-5">
				<div
					className="d-flex justify-content-center flex-column"
					style={{ gap: '1rem' }}
				>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ gap: '1rem' }}
					>
						<button
							type="button"
							className="btn btn-light"
							onClick={() => {
								navigate(-1);
							}}
						>
							<BiArrowBack />
						</button>
						<div>
							<h6>Order: {order ? order?.draftOrder?.order?.name : ''}</h6>
							<h6>AWB No: {awbno ? awbno : ''}</h6>
						</div>
					</div>
				</div>
				<button
					type="button"
					className="btn btn-outline-dark"
					onClick={() => {
						fetchOrder();
					}}
				>
					{loading ? (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
					{loading ? 'Loading...' : 'ReLoad'}
				</button>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}
			{order ? (
				<div className="d-flex flex-column" style={{ gap: '2rem' }}>
					<div className="d-flex flex-column" style={{ gap: '2rem' }}>
						{order.cancel ? (
							<div className="p-3 rounded shadow-sm position-relative">
								<div
									className="d-flex align-items-center"
									style={{ gap: '0.5rem' }}
								>
									<MdOutlineCancel color="red" size={32} />
									<p style={{ fontWeight: 600 }}>Cancelled At: </p>
									<p>{cancelledAtDate}</p>
								</div>
							</div>
						) : null}

						<div className="d-flex flex-column p-3 rounded shadow-sm">
							<div
								className="d-flex align-items-center mb-3"
								style={{ gap: '0.5rem' }}
							>
								<BiCheckCircle
									color={order?.delivered ? 'green' : 'grey'}
									size={32}
								/>
								<h6 className="mb-0">{order?.orderStatus}</h6>
							</div>
						</div>
						<div
							className="d-flex flex-column p-3 rounded shadow-sm"
							style={{ gap: '.5rem' }}
						>
							<div
								className="d-flex align-items-center mb-3"
								style={{ gap: '0.5rem' }}
							>
								<BiCheckCircle
									color={order?.paymentStatus === 'paid' ? 'green' : 'grey'}
									size={32}
								/>
								<h6 className="mb-0">{order?.paymentStatus}</h6>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div
									className="d-flex align-items-center"
									style={{ gap: '5rem' }}
								>
									<p>Quantity</p>
									<p>{}</p>
								</div>
								<p>{order?.totalQuantity}</p>
							</div>

							<div className="d-flex align-items-center justify-content-between">
								<div
									className="d-flex align-items-center"
									style={{ gap: '5rem' }}
								>
									<p style={{ fontWeight: 600 }}>Total Amount</p>
								</div>
								<p style={{ fontWeight: 600 }}>
									₹{order?.draftOrder?.totalPrice}
								</p>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column" style={{ gap: '2rem' }}>
						<div className="d-flex flex-column p-3 rounded shadow-sm">
							<div className="d-flex align-items-center justify-content-between mb-1">
								<p style={{ fontWeight: 600 }}>Customer</p>
							</div>
							<p>
								{order?.draftOrder?.customer?.firstName +
									' ' +
									order?.draftOrder?.customer?.lastName}
							</p>
							<p>{order?.draftOrder?.customer?.phone}</p>
						</div>
						<div className="d-flex flex-column p-3 rounded shadow-sm">
							<div className="d-flex align-items-center justify-content-between mb-1">
								<p style={{ fontWeight: 600 }}>Shipping Address</p>
							</div>
							<p>
								{order?.draftOrder?.shippingAddress?.firstName +
									' ' +
									order?.draftOrder?.shippingAddress?.lastName}
							</p>
							<p>{order?.draftOrder?.shippingAddress?.city}</p>
							<p>
								{order?.draftOrder?.shippingAddress?.zip},{' '}
								{order?.draftOrder?.shippingAddress?.province}
							</p>
							<p>{order?.draftOrder?.shippingAddress?.phone}</p>
							<p>{order?.draftOrder?.shippingAddress?.country}</p>
						</div>
					</div>

					{error2 || error3 ? (
						<Alert variant="danger" onClose={() => setError2(null)} dismissible>
							<Alert.Heading>{error2 || error3}</Alert.Heading>
							<p>Try Again!</p>
						</Alert>
					) : null}

					<div
						className="d-flex align-items-center justify-content-between"
						style={{ gap: '2rem' }}
					>
						<button
							className="btn btn-light"
							type="button"
							style={{
								backgroundColor:
									order.paymentStatus === 'paid' ? 'green' : '#ff6200',
								color: '#fff',
								border: 'none',
								width: '100%',
								minWidth: '200px',
							}}
							onClick={order?.paymentStatus === 'paid' ? () => {} : func1}
						>
							{loading2 ? (
								<span
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
								></span>
							) : (
								order?.paymentStatus?.toUpperCase()
							)}
						</button>

						<button
							className="btn btn-light"
							type="button"
							style={{
								backgroundColor: order?.delivered ? 'green' : '#ff6200',
								color: '#fff',
								border: 'none',
								width: '100%',
								minWidth: '200px',
							}}
							onClick={order?.delivered ? () => {} : func2}
						>
							{loading3 ? (
								<span
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
								></span>
							) : (
								order?.orderStatus?.toUpperCase()
							)}
						</button>
					</div>
				</div>
			) : null}
			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default DeliveryBoyOrderId;
